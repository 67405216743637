/* Tasks */
/* IF */
const IF_DASHBOARD = 1;
const IF_SEND = 2;
const IF_HISTORY = 3;
const IF_GRAPHS = 4;
const IF_RECORDS = 5;
const IF_HELP = 6;

/* IPA */
const IPA_DASHBOARD = 7;
const IPA_SEND_TASKS = 8;
const IPA_TASKS_HISTORY = 9;
const IPA_MANAGE_TASKS = 10;
const IPA_MANAGE_CATEGORIES = 11;
const IPA_GRAPHS = 12;
const IPA_RECORDS = 13;
const IPA_HELP = 14;
const IPA_SEND_ALIGNMENT = 16;
const IPA_ALIGNMENT_HISTORY = 17;
const IPA_MANAGE_ALIGNMENTS = 15;
const NPS_GRAPHS = 23;

/* SYSTEM */
const SYSTEM_CONFIGURATIONS = 18;
const SYSTEM_COMPANIES = 19;
const SYSTEM_USERS = 20;

/* Default route to redirect user */
const IF_DEFAULT_ROUTE = "/dashboard";
const IPA_DEFAULT_ROUTE = "/ipa/dashboard";


/* Submodules */
const SYSTEM_GLOBAL_COMPANY_FILTER = 1;
const EMPLOYEES_VISUALIZATION_FILTER = 2;
const DEPARTMENTS_VISUALIZATION_FILTER = 3;
const COMPANIES_VISUALIZATION_FILTER = 4;
const DATA = 5;
const EMPLOYEES_VISUALIZATION_FILTER_DEPARTMENT = 7;
const SUBORDINATES_DEPARTMENTS_VIZUALIZATION_FILTER = 8;

const permissions = {
    IF_DASHBOARD: IF_DASHBOARD,
    IF_SEND: IF_SEND,
    IF_HISTORY: IF_HISTORY,
    IF_GRAPHS: IF_GRAPHS,
    IF_RECORDS: IF_RECORDS,
    IF_HELP: IF_HELP,
    IPA_DASHBOARD: IPA_DASHBOARD,
    IPA_SEND_TASKS: IPA_SEND_TASKS,
    IPA_TASKS_HISTORY: IPA_TASKS_HISTORY,
    IPA_MANAGE_TASKS: IPA_MANAGE_TASKS,
    IPA_MANAGE_CATEGORIES: IPA_MANAGE_CATEGORIES,
    IPA_GRAPHS: IPA_GRAPHS,
    IPA_RECORDS: IPA_RECORDS,
    IPA_HELP: IPA_HELP,
    IPA_SEND_ALIGNMENT: IPA_SEND_ALIGNMENT,
    IPA_ALIGNMENT_HISTORY: IPA_ALIGNMENT_HISTORY,
    IPA_MANAGE_ALIGNMENTS: IPA_MANAGE_ALIGNMENTS,
    SYSTEM_CONFIGURATIONS: SYSTEM_CONFIGURATIONS,
    SYSTEM_COMPANIES: SYSTEM_COMPANIES,
    SYSTEM_USERS: SYSTEM_USERS,
    SYSTEM_GLOBAL_COMPANY_FILTER: SYSTEM_GLOBAL_COMPANY_FILTER,
    IF_DEFAULT_ROUTE: IF_DEFAULT_ROUTE,
    IPA_DEFAULT_ROUTE: IPA_DEFAULT_ROUTE,
    EMPLOYEES_VISUALIZATION_FILTER: EMPLOYEES_VISUALIZATION_FILTER,
    DEPARTMENTS_VISUALIZATION_FILTER: DEPARTMENTS_VISUALIZATION_FILTER,
    COMPANIES_VISUALIZATION_FILTER: COMPANIES_VISUALIZATION_FILTER,
    DATA: DATA,
    NPS_GRAPHS: NPS_GRAPHS,
    EMPLOYEES_VISUALIZATION_FILTER_DEPARTMENT: EMPLOYEES_VISUALIZATION_FILTER_DEPARTMENT,
    SUBORDINATES_DEPARTMENTS_VIZUALIZATION_FILTER: SUBORDINATES_DEPARTMENTS_VIZUALIZATION_FILTER
};

export default permissions;

export const getPermission = (userPermissions, permission) => {
    return userPermissions.find(elem => elem.moduleId === permission).active;
};

export const getSubmodulePermission = (userPermissions, submodulePermission) => {
    const hasSubmodules = userPermissions.length;
    if(hasSubmodules <= 0) {
        return false;
    } else {
        return userPermissions.find(element => element.submoduleId === submodulePermission);
    }
};

export const setTabsOrder = (tempTabs) => {
    let count = 0;
    let startCount = false;
    let first;

    for (let key in tempTabs) {
        if (tempTabs.hasOwnProperty(key)) {
            if (tempTabs[key] === true) {
                first = key
                break
            }
        }
    }

    for (let key in tempTabs) {
        if (tempTabs.hasOwnProperty(key)) {
            if (key === first) {
                startCount = true
            }
            if(startCount && tempTabs[key]) {
                tempTabs[key] = count++;
            }
        }
    }
    return tempTabs;
};