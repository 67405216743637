import styled from 'styled-components';

export const TableContainer = styled.div`
    background-color: white;
    border: 1px solid #cfd8dc;
    padding-bottom: 4vw;
    padding-right: 0vw;
    padding-top: 0vw;
    padding-left: 2vw;
    @media screen and (max-width: 600px) , screen and (max-height: 660px) {
        width: 100%; 
    }
    @media screen and (max-height: 1000px) {
        height: 68%;
    }
    @media screen and (max-height: 800px) {
        width: 95%;
        height: 85%;
        margin-bottom: 20px;
    }
`;

export const Spacer = styled.div`
    height: 12vh;
    @media screen and (max-height: 600px) {
        height: 70px;
    }
`;

export const DrawerAdjustment = styled.div`
    padding-left: 10%;
    @media screen and (max-width: 800px) {
        padding-left: 0;
    }
`;

export const StyledContainerTitle = styled.div`
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 830px) {
        width: 0;
        height: 0;
        visibility: hidden; 
    }
`;

export const ContainerTitle = styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 830px) {
        width: 0;
        height: 0;
        visibility: hidden; 
    }
`;

export const Title = styled.h2`
    margin-bottom: 8px;
    @media screen and (max-width: 830px) {
        width: 0;
        height: 0;
        visibility: hidden; 
    }
`;

export const Stripe = styled.div`
    margin-top: 13px;
    margin-right: 13px;
    border-left:5px solid #4f4f4f;
    height: 65px;
    @media screen and (max-width: 830px){
        width: 0;
        height: 0;
        visibility: hidden;  
    }
`;

export const Filters = styled.div`
    display: flex;
    flex-direction: row;
    margin: 5px;
    justify-content: center;
    @media screen and (max-width: 362px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const ContainerColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const MainComponents = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 2vw;
    @media screen and (max-width: 650px) {
        justify-content: center;
    }
`;

export const GlobalFilterContainer = styled.div`
    padding-top: 12px;
`;

export const MainFilter = styled.div`
    padding-top: 12;
`;