import styled from 'styled-components/macro';
export const Container = styled.div`
    margin: 0px auto;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    max-width: 80vw;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
`;

export const ContainerRow = styled.div`
    border-bottom: 1px solid #e0e0e0;
    font-family: 'Roboto', 'sans-serif';
    padding: 2rem 5rem;
    display: grid;
    grid-template-areas:
        'column1 column1'
        'column2 column2'
        'column3 column3'
        'column4 column4';
`;

export const StyledContainerText = styled.h3`
    font-family: 'Roboto', 'sans-serif';
    padding-top: 2rem; 
    color: #444446;
    line-height: 1.43;
    text-align: center;
`;

export const StyledContainerTitle = styled.div`
    display: flex;
    flex-direction: row;
    width: 40%;
    @media screen and (max-width: 620px) {
        width: 0;
        height: 0;
        visibility: hidden;
    }
`;

export const Title = styled.h2`
    margin-bottom: 32px;
    display: inline;
    @media screen and (max-width: 620px) {
        width: 0;
        height: 0;
        visibility: hidden;
    }
`;

export const Column1 = styled.div`
    font-family: 'Roboto', 'sans-serif';
    display: grid;
    grid-area: column1;
    grid-template-columns: 1fr 1fr;
    font-family: 'Roboto';
    @media (max-width: 920px) {
        text-align: center;
    }
`;

export const Column2 = styled.div`
    display: inline;
    margin: auto;
    grid-area: column2;
    font-family: 'Roboto', 'sans-serif';
    @media (max-width: 920px) {
        justify-items: center;
    }
`;

export const Column3 = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12rem, auto));
    grid-area: column3;
`;

export const Column4 = styled.div`
    display: grid;
    overflow: hidden;
    justify-content: start;
    grid-area: column4;
    @media (max-width: 1440px) {
        overflow: auto;
    }
`;

export const SpacerFilter = styled.div`
    display: none;
`;

export const GlobalFilterContainer = styled.div`
    margin: auto;
`;
