import styled from 'styled-components/macro';

export const Loader = styled.div`
   width:50px;
  height:50px;
  border-radius:50%;
  border:8px solid;
  border-color:#E4E4ED;
  border-right-color: #FF7517;
  animation:s2 1s infinite linear;
  @keyframes s2 {to{transform: rotate(1turn)}}
`;
