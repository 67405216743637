import React, {useContext, useEffect, useState} from "react";

/* Styles */
import {
    AddTaskModal,
    AddName,
    AddCategory,
    AddDescription,
    ButtonContainer,
} from './edit-task.styles';


/* Components */
import PopUp from "../../../../../components/popup/popup.component";
import CustomButton from '../../../../../components/./custom-button/custom-button.component';

import {
    WarningTitleContainer,
    WarningWord,
} from '../../../../.././components/form-list/form-list.styles';

/* Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

/* api */
import {
    updateTaskStatus, 
    editTask, 
    findAllTasks,
    findDisabledTasks
} from "../../../../../api/ApiTasks";
import {getOneCompany} from "../../../../../api/ApiCompany";
import {findAllCategories} from "../../../../../api/ApiCategory";

/* Context */
import {authContext} from "../../../../../contexts/AuthContext";

const useStyles = makeStyles((theme) => ({
    paperModal: {
        marginTop: '10%',
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        width: 950,
        maxHeight: 900,
        borderRadius: '10px',
        backgroundColor: '#fff',
        padding: '10px',
        fontFamily: 'Roboto',
        textAlign: 'center',
        '@media (max-width: 750px)': {
            height: 620,
            marginTop: '80%',
            transform: 'translate(-50%, -50%) scale(0.7)',
        },
    },
    category: {
        width: '100%',
    },
}));

export default function EditTask(props) {
    const {auth} = useContext(authContext);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState(null);
    const [categoriesOptions, setCategoriesOptions] = useState([]);

    const [warningTitle, setWarningTitle] = useState({});
    const [popup, setPopup] = React.useState(false);
    const [popupText, setPopupText] = useState('');
    const [popupTitle, setPopupTitle] = useState('');
    const [buttonPopUp, setButtonPopUp] = React.useState({
        popUp: false,
        popUpTitle: "",
        popUpText: "",
        success: 1,
        acceptable: true
    });

    const [success, setSuccess] = useState(1);
    const [fetchOpen, setFetchOpen] = useState(true);
    
    const classes = useStyles();

    const handleClose = () => {
        setButtonPopUp({
            popUp: false,
            popUpTitle: "",
            popUpText: "",
            success: 0,
            acceptable: false
        });
    };

    const handleEdit = async event => {
        setPopup(false);
        event.preventDefault();
        setFetchOpen(true);

        if(!category) {
            setPopupTitle('Erro');
            setPopupText('Insira a categoria');
            setPopup(true);
            setSuccess(0);
            return
        }

        let data = {
            taskId: props.task.taskId,
            name: name,
            description: description,
            categoryId: category.categoryId,
        };

        try {   
            let response = await editTask(data, auth, props.companyId);
            setFetchOpen(false);
            if(response.status === 200){ //TODO change this to 201
                setPopupTitle('Sucesso');
                setPopupText('Tarefa editada.');
                setPopup(true);
                setSuccess(3);
            } else if (response.status === 400) {
                setPopupTitle('Erro');
                setPopupText('Tarefa já existe.');
                setPopup(true);
                setSuccess(0);
            } else {
                setPopupTitle('Erro');
                setPopupText('Tarefa não pode ser editada.');
                setPopup(true);
                setSuccess(0);
            }

        } catch (error) {
            console.log(error);
        }
    };

    const actionUpdateTaskButton = () => {
        handleClose()
        setButtonPopUp({
            popUp: true,
            popUpTitle: props.task.active === 'Ativa'
                ? "Cuidado! Você está desativando uma tarefa." 
                : "Cuidado! Você está ativando uma tarefa.",
            popUpText: props.task.active === 'Ativa' 
                ? "Ao realizar esse procedimento, você estará desativando também o vínculo de todos os usuários com esta tarefa." 
                : "Você tem certeza que deseja ativar essa tarefa?",
            success: 0,
            acceptable: true,
            first: false,
            second: true
        });
    };

    const updateStatus = async event => {
        handleClose();

        setPopup(false);
        event.preventDefault();
        setFetchOpen(true);

        try {
            const taskStatus = props.task.active === 'Ativa' ? 0 : 1;
            let response = await updateTaskStatus(auth, props.companyId, props.task.taskId, taskStatus);
            setFetchOpen(false);
            if(response.status === 200){ //TODO change this to 201
                setPopupTitle('Sucesso');
                if(taskStatus) 
                    setPopupText('Tarefa ativada.');
                else
                    setPopupText('Tarefa desativada.');
                setPopup(true);
                setSuccess(3);
            } else {
                setPopupTitle('Erro');
                if(taskStatus) 
                    setPopupText('Tarefa não pode ser ativada.');
                else
                    setPopupText('Tarefa não pode ser desativada.');
                setPopup(true);
                setSuccess(0);
            }

        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = event => {
        const {value, name} = event.target;
        if (name === 'name')
            setName(value);
        else
            setDescription(value);
    };

    const setNewFatherContext = () => {
        props.setReloadTable(!props.reloadTable);
        props.handleOpenEdit();
    };

    useEffect(() => {
        const callApiFindOneCompany = async () => {
            const response = await getOneCompany(auth, props.companyId);
            const body = await response.json();

            return body.payload[0]
        };

        const callApiFindAllCategories = async () => {
            const response = await findAllCategories(auth);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body.payload
        };

        const callApiFindAllTasks = async (globalCompany) => {
            const response = await findAllTasks(auth, globalCompany);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body.payload
        };

        const callApiFindDisabledTasks = async (globalCompany) => {
            const response = await findDisabledTasks(auth, globalCompany);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            const categories = await callApiFindAllCategories();
            
            const actualTask = body.payload.filter(obj => obj.taskId === parseInt(props.task.taskId))[0];
            const actualCategory = categories.filter(obj => obj.categoryId === parseInt(actualTask.categoryId))[0];
            setName(actualTask ? actualTask.name : '');
            setDescription(actualTask ? actualTask.description : '');
            setCategory(actualCategory ?
                {categoryId: actualCategory.categoryId,
                name: actualCategory.name}
                : null)
            setCategoriesOptions(body.payload);
        };

        if(props.toggleTasksButton === 'enabledTasks') {
            callApiFindAllTasks(props.companyId)
                .then(tasksRes => {
                    callApiFindAllCategories()
                        .then(categoriesRes => {
                            setFetchOpen(false);
                            const actualTask = tasksRes.filter(obj => obj.taskId === parseInt(props.task.taskId))[0];
                            const actualCategory = categoriesRes.filter(obj => obj.categoryId === parseInt(actualTask.categoryId))[0];
                            setName(actualTask ? actualTask.name : '');
                            setDescription(actualTask ? actualTask.description : '');
                            setCategory(actualCategory ?
                                {categoryId: actualCategory.categoryId,
                                name: actualCategory.name}
                                : null)
                            setCategoriesOptions(categoriesRes);
                        })
                        .catch(err => console.log(err));
                })
                .catch(err => console.log(err));
        } else {
            callApiFindDisabledTasks(props.companyId);
        }

        callApiFindOneCompany()
            .then(companyRes => {
                setWarningTitle({
                    phrase: `Editando tarefa em `,
                    object: `${companyRes.name}`,
                    warning: parseInt(props.companyId) !== parseInt(auth.data.user.companyId) ? `(Cuidado! Você está editando uma tarefa de outra empresa)` : ''
                });
            })
            .catch(err => console.log(err));

    }, []);

    return (
        <div className={classes.paperModal}>
            {warningTitle ? (
                <WarningTitleContainer>
                    <Typography variant="h5">
                        {warningTitle.phrase}
                        <WarningWord>{warningTitle.object}</WarningWord>
                    </Typography>
                    <Typography variant="body2">
                        <WarningWord>{warningTitle.warning}</WarningWord>
                    </Typography>
                </WarningTitleContainer>
            ) : null}

            <AddTaskModal>
                <AddName>
                    <TextField
                        id="outlined-basic"
                        label="Nome"
                        variant="outlined"
                        name="name"
                        className={classes.name}
                        value={name}
                        type="text"
                        onChange={handleChange}
                        placeholder=""
                        maxLength="80"
                    />
                </AddName>
                <AddCategory>
                    <Autocomplete
                        onChange={(event, newValue) => setCategory(newValue)}
                        getOptionSelected={(option, value) =>
                            option.name === value.name
                        }
                        className={classes.category}
                        id="categories"
                        options={categoriesOptions}
                        getOptionLabel={(options) => options.name}
                        value={category}
                        autoSelect
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={'Categoria'}
                                variant="outlined"
                            />
                        )}
                    />
                </AddCategory>
                <AddDescription>
                    <TextField
                        id="outlined-basic"
                        label="Descrição"
                        variant="outlined"
                        name="description"
                        value={description}
                        type="text"
                        className={classes.description}
                        onChange={handleChange}
                        placeholder=""
                        maxLength="80"
                    />
                </AddDescription>
                <ButtonContainer
                    style={{
                        display: 'flex',
                    }}  
                >
                    {props.task.active === 'Ativa' ? (
                        <CustomButton
                            type="submit"
                            deleteButton="true"
                            onClick={actionUpdateTaskButton}
                        >
                            Desativar tarefa
                        </CustomButton>
                    ) : (
                        <CustomButton
                            type="submit"
                            addButton="true"
                            onClick={actionUpdateTaskButton}
                        >
                            Ativar tarefa
                        </CustomButton>
                    )}
                    <CustomButton
                        type="submit"
                        editButton="false"
                        onClick={handleEdit}
                        style={{ marginLeft: '10px' }}
                    >
                        Editar
                    </CustomButton>
                </ButtonContainer>
            </AddTaskModal>
            {popup ? (
                <PopUp
                    title={popupTitle}
                    string={popupText}
                    success={success}
                    setNewFatherContext={setNewFatherContext}
                />
            ) : null}
            {buttonPopUp.popUp && (
                <PopUp 
                    title={buttonPopUp.popUpTitle} 
                    string={buttonPopUp.popUpText}
                    success={buttonPopUp.success} 
                    acceptable={buttonPopUp.acceptable}
                    acceptFunction={updateStatus} 
                    handleClose={handleClose}
                />
            )}
        </div>
    );
}