import { apiUrl } from './Api';

export function findAllAlignmentsPaged(auth, user, company, page) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };
    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/nps/findAllAlignmentsPaged?page=${page}`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getNpsAvgFromDepartmentAttribute(
    auth,
    departmentId,
    companyId,
    nps_id,
    attribute,
    year,
    active
) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    };

    if (nps_id) {
        return fetch(
            `${apiUrl}/companies/${companyId}/departments/${departmentId}/nps/getNpsAttributeAvgFromDepartment?nps_id=${nps_id}&attribute=${attribute}&active=${active}`,
            requestOptions
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    } else {
        return fetch(
            `${apiUrl}/companies/${companyId}/departments/${departmentId}/nps/getNpsAttributeAvgFromDepartment?year=${year}&attribute=${attribute}&active=${active}`,
            requestOptions
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

export function getNpsAvgFromCompanyAttribute(
    auth,
    departmentId,
    companyId,
    nps_id,
    attribute,
    year,
    active
) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    };

    if (nps_id) {
        return fetch(
            `${apiUrl}/companies/${companyId}/departments/${departmentId}/nps/getNpsAttributeAvgFromCompany?nps_id=${nps_id}&attribute=${attribute}&active=${active}`,
            requestOptions
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    } else {
        return fetch(
            `${apiUrl}/companies/${companyId}/departments/${departmentId}/nps/getNpsAttributeAvgFromCompany?year=${year}&attribute=${attribute}&active=${active}`,
            requestOptions
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

export function getNpsAvgFromUser(
    auth,
    user,
    department,
    company,
    nps_id,
    year
) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: `GET`,
        headers: {
            Authorization: authorization,
        },
    };

    if (nps_id) {
        return fetch(
            `${apiUrl}/companies/${company}/departments/${department}/users/${user}/nps/getNpsAvgFromUser?nps_id=${nps_id}`,
            requestOptions
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    } else {
        return fetch(
            `${apiUrl}/companies/${company}/departments/${department}/users/${user}/nps/getNpsAvgFromUser?year=${year}`,
            requestOptions
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

export function findNpsStatus(auth, user, company) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };
    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/nps/findNpsStatus`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function listNpsPrevious(auth, company) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(`${apiUrl}/companies/${company}/nps/listNpsPrevious`, requestOptions)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getAllNpsAverage(auth, npsId) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    };
    return fetch(`${apiUrl}/nps/${npsId}/getNpsAvgFromNps`, requestOptions)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function findAllYears(auth, companyId) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    };
    return fetch(
        `${apiUrl}/companies/${companyId}/nps/findAllYears`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function findAllNps(auth, companyId, year) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    };
    return fetch(
        `${apiUrl}/companies/${companyId}/nps/findAllNps?year=${year}`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function createNps(auth, company, startDate, endDate, semester) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
        body: JSON.stringify({ startDate, endDate, semester }),
    };

    return fetch(`${apiUrl}/companies/${company}/nps`, requestOptions)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function createNpsAnswers(auth, user, company, npsId, npsAnswer) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
        body: JSON.stringify(npsAnswer),
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/nps/${npsId}/npsAnswer`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function updateNps(
    auth,
    company,
    npsId,
    startDate,
    endDate,
    semester,
    active
) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
        body: JSON.stringify({ startDate, endDate, active, semester }),
    };

    return fetch(
        `${apiUrl}/companies/${company}/nps/${npsId}/editNps`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getNpsAvgFromDepartment(
    auth,
    departmentId,
    companyId,
    nps_id,
    year
) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    };

    if (nps_id) {
        return fetch(
            `${apiUrl}/companies/${companyId}/departments/${departmentId}/nps/getNpsAvgFromDepartment?nps_id=${nps_id}`,
            requestOptions
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    } else {
        return fetch(
            `${apiUrl}/companies/${companyId}/departments/${departmentId}/nps/getNpsAvgFromDepartment?year=${year}`,
            requestOptions
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

export function getAllNpsAverageFromCompany(auth, nps_id, year) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    };

    if (nps_id) {
        return fetch(
            `${apiUrl}/nps/getNpsAvgFromCompany?nps_id=${nps_id}`,
            requestOptions
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    } else {
        return fetch(
            `${apiUrl}/nps/getNpsAvgFromCompany?year=${year}`,
            requestOptions
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

export function getNpsAnswerers(auth, nps_id, department_id, company_id, year){
    const authorization = `Bearer ${auth.data.token}`;  

    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    };
    
    if(department_id){
        if(nps_id){
            return fetch(
                `${apiUrl}/companies/${company_id}/departments/${department_id}/nps/getNpsNumberOfUsersFromDepartment?nps_id=${nps_id}`,
                requestOptions,
            ).then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
        }
        else{
            return fetch(
                `${apiUrl}/companies/${company_id}/departments/${department_id}/nps/getNpsNumberOfUsersFromDepartment?year=${year}`,
                requestOptions,
            ).then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
        }
        
    }else{
        if(nps_id){
            
            return fetch(
                `${apiUrl}/companies/${company_id}/nps/getNpsNumberOfUsersFromCompany?nps_id=${nps_id}`,
                requestOptions,
            ).then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
        }
        else{
            
            return fetch(
                `${apiUrl}/companies/${company_id}/nps/getNpsNumberOfUsersFromCompany?year=${year}`,
                requestOptions,
            ).then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }
}

export function getNpsEvolutionFromCompany(auth, year) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    };

    if (year) {
        return fetch(
            `${apiUrl}/nps/getNpsEvolutionFromCompany?year=${year}`,
            requestOptions
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    } else {
        return fetch(`${apiUrl}/nps/getNpsEvolutionFromCompany`, requestOptions)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

export function getNpsEvolutionFromDepartment(auth, company, department, year) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    };

    if (year) {
        return fetch(
            `${apiUrl}/companies/${company}/departments/${department}/nps/getNpsEvolutionFromDepartment?year=${year}`,
            requestOptions
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    } else {
        return fetch(
            `${apiUrl}/companies/${company}/departments/${department}/nps/getNpsEvolutionFromDepartment`,
            requestOptions
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

export function getNpsEvolutionFromUser(auth, company, department, user, year) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    };

    if (year) {
        return fetch(
            `${apiUrl}/companies/${company}/departments/${department}/users/${user}/nps/getNpsEvolutionFromUser?year=${year}`,
            requestOptions
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    } else {
        return fetch(
            `${apiUrl}/companies/${company}/departments/${department}/users/${user}/nps/getNpsEvolutionFromUser`,
            requestOptions
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

export function getLastNpsAvgFromUser(auth, globalCompany, userId){
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/companies/${company}/users/${userId}/nps/getLastNpsAvgFromUser`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}