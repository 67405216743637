import styled from 'styled-components/macro'
//TODO ver como fica usando esse import em produção

export const AlignContainer = styled.div`
    @media screen and (max-width: 830px) {
        margin-top: 15px;
    }
    @media screen and (max-width: 600px) , screen and (max-height: 600px) {
        left: 0%;
        top: 0%;
        position: static;
        transform: none;
        width: 100%;
    }
`;

export const StyledFilter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 20px;
    margin: 5px;
    justify-content: center;
    @media screen and (max-width: 830px) {
        justify-content: space-between;
    }
    @media screen and (max-width: 500px) {
        align-items: center;
        padding-bottom: 5px;
        flex-direction: column;
    }
`;

export const Filters = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    @media screen and (max-width: 362px) {
        flex-direction: column;
    }
`;
