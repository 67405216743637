import React from 'react';

/* Material Ui */
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        left: 73,
        top: 64,
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        '@media (max-width: 800px)': {
            left: 0,
            top: 0,
        },
    },
}));

export default function RenderPage(props) {
    const classes = useStyles();
    return (
        <>
            <Backdrop className={classes.backdrop} open={props.open}>
                <CircularProgress
                    style={{ width: 100, height: 100 }}
                    color="inherit"
                />
            </Backdrop>
        </>
    );
}
