import styled from "styled-components";

export const StyledInput = styled.input`
    border: none;
    border: 1px solid #8492A6;
    font-size: 16px;
    height: 40px;
    max-width: 600px;
    margin: 5px;
    text-align: center;
    color: #757575;
    border-radius: 10px;
    font-family: 'Arial', sans-serif;
    &:focus {
        border: 2px solid #5c9089;
        outline: none;
    }
`;

export const ContainerButtons = styled.div`
    margin: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
   @media screen and (max-width: 650px) {
        flex-direction: column;
    }
`;

export const SecondContainerButtons = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
`;