import React, {
    useState,
    useContext,
    useEffect,
} from "react";
import { Redirect, useHistory } from "react-router-dom";
import { isUndefined } from "svgsaver/src/utils";

/* Material UI */
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

/* Components */
import GlobalCompanyFilter from "../../../../../components/globalFilter/globalFilter.component";

/* Styles */
import {
    Title,
    ContainerTitle,
    StyledFilter,
    AlignContainer,
    Filters,
    Stripe,
    StyledContainerTitle,
    GlobalFilterContainer,
    MainComponents,
    SpacerFilter,
} from "./ipa-graphs-general.styles";

/* Permissions */
import permissions, {
    getPermission,
    getSubmodulePermission,
} from "../../../../../utils/permission";
import { findIfSubmoduleIsActive } from "../../../../../api/ApiPemissions";
import { getInternetError } from "../../../../../utils/genericFunctions";

/* Context */
import { authContext } from "../../../../../contexts/AuthContext";

/* Api */
import { getOneCompany } from "../../../../../api/ApiCompany";

/* Pages */
import GeneralGraphFilter from "./general-graph-filters/general-graphs-filters";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 140,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    container: {
        "@media (max-width: 600px)": {
            padding: 0,
        },
    },
}));

const GeneralGraphChecks = () => {
    const { auth } = useContext(authContext);

    const [globalCompany, setGlobalCompany] = useState({ name: "" });
    const [submodules, setSubmodules] = useState({ graphs: [] });

    const [updateIfItChange, setUpdateIfItChange] = useState(false);
    const [jumpFirstRender, setJumpFirstRender] = useState(false);

    const [checksSelectedCounter, setChecksSelectedCounter] = useState(1);
    const [selectedDepartmentCounter, setSelectedDeparmentCounter] =
        useState(0);

    let history = useHistory();

    const [checksStatus] = useState([
        { name: "Colaborador", activated: true },
        { name: "Setor", activated: false },
        { name: "Empresa", activated: false },
        { name: "Outro ano", activated: false },
    ]);

    const changeActualGlobalCompany = (company) => {
        if (company !== null) {
            setGlobalCompany(company);
        }
    };

    useEffect(() => {
        //update Global Company
        let actualGlobalCompany;

        if (!isUndefined(globalCompany)) {
            if (globalCompany.hasOwnProperty("companyId"))
                actualGlobalCompany = globalCompany.companyId;
            else actualGlobalCompany = auth.data.user.companyId;
        } else {
            actualGlobalCompany = auth.data.user.companyId;
        }

        const callFindIfSubmoduleIsActive = async (
            permissionRole,
            permissionModule,
        ) => {
            const response = await findIfSubmoduleIsActive(
                auth,
                permissionRole,
                permissionModule,
            );
            const body = await response.json();

            return body.payload;
        };

        callFindIfSubmoduleIsActive(
            auth.data.user.permissionRoleId,
            permissions.IPA_GRAPHS,
        )
            .then((resGraphs) => {
                setSubmodules({
                    graphs: resGraphs,
                });
                if (submodules.graphs.length === 0) {
                    setUpdateIfItChange(!updateIfItChange);
                }
            })
            .catch((err) => console.log(err));

        let internetError;

        internetError = getInternetError();
        if (internetError) {
            history.replace("/error/500");
            return;
        }
    }, [updateIfItChange, globalCompany]);

    useEffect(() => {
        //get all the companies
        const callGetOneCompany = async (company) => {
            const response = await getOneCompany(auth, company);
            const body = await response.json();
            return body.payload[0];
        };

        callGetOneCompany(auth.data.user.companyId).then((res) => {
            setGlobalCompany(res);
            setJumpFirstRender(true);
        });
    }, []);

    const updateCounter = (e, num) => {
        if (e.target.checked) {
            setChecksSelectedCounter(checksSelectedCounter + 1);
        } else {
            setChecksSelectedCounter(checksSelectedCounter - 1);
        }
        checksStatus[num].activated = !checksStatus[num].activated;
    };

    return (
        <>
            {!getPermission(auth.data.permissions, permissions.IPA_GRAPHS) ? (
                <Redirect to={permissions.IPA_DEFAULT_ROUTE} />
            ) : null}

            {jumpFirstRender ? (
                <>
                    <MainComponents>
                        <StyledContainerTitle>
                            <Stripe />
                            <ContainerTitle>
                                <Title>Resumo - Gráficos</Title>
                                <span>
                                    Índice de Performance de Alinhamento
                                </span>
                            </ContainerTitle>
                        </StyledContainerTitle>
                        {getSubmodulePermission(
                            submodules.graphs,
                            permissions.SYSTEM_GLOBAL_COMPANY_FILTER,
                        ) ? (
                            <GlobalFilterContainer>
                                <GlobalCompanyFilter
                                    changeActualGlobalCompany={
                                        changeActualGlobalCompany
                                    }
                                    globalCompany={globalCompany}
                                />
                            </GlobalFilterContainer>
                        ) : null}
                    </MainComponents>

                    <AlignContainer>
                        <StyledFilter>
                            <Filters
                                around={auth.data.user.permissionLevel !== 1}
                            >
                                {getSubmodulePermission(
                                    submodules.graphs,
                                    permissions.EMPLOYEES_VISUALIZATION_FILTER,
                                ) ? (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                style={{ color: "#424242" }}
                                                onChange={(e) => {
                                                    updateCounter(e, 0);
                                                }}
                                                checked={
                                                    checksStatus[0].activated
                                                }
                                                disabled={
                                                    (checksSelectedCounter ===
                                                        2 &&
                                                        !checksStatus[0]
                                                            .activated) ||
                                                    (checksStatus[1]
                                                        .activated &&
                                                        selectedDepartmentCounter ===
                                                            2)
                                                }
                                            />
                                        }
                                        label={"Colaborador"}
                                    />
                                ) : auth.data.user.permissionRoleId === 10 ? (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                style={{ color: "#424242" }}
                                                onChange={(e) => {
                                                    updateCounter(e, 0);
                                                }}
                                                checked={
                                                    checksStatus[0].activated
                                                }
                                                disabled={
                                                    (checksSelectedCounter ===
                                                        2 &&
                                                        !checksStatus[0]
                                                            .activated) ||
                                                    (checksStatus[1]
                                                        .activated &&
                                                        selectedDepartmentCounter ===
                                                            2)
                                                }
                                            />
                                        }
                                        label={"Colaborador"}
                                    />
                                ) : (
                                    <SpacerFilter />
                                )}

                                {getSubmodulePermission(
                                    submodules.graphs,
                                    permissions.DEPARTMENTS_VISUALIZATION_FILTER
                                ) ||
                                getSubmodulePermission(
                                    submodules.graphs,
                                    permissions.EMPLOYEES_VISUALIZATION_FILTER_DEPARTMENT
                                ) ? (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                style={{ color: "#424242" }}
                                                onChange={(e) => {
                                                    updateCounter(e, 1);
                                                }}
                                                checked={
                                                    checksStatus[1].activated
                                                }
                                                disabled={
                                                    (checksSelectedCounter ===
                                                        2 &&
                                                        !checksStatus[1]
                                                            .activated) ||
                                                    (checksStatus[1]
                                                        .activated &&
                                                        selectedDepartmentCounter ===
                                                            2 &&
                                                        !checksStatus[1]
                                                            .activated)
                                                }
                                            />
                                        }
                                        label={"Setor"}
                                    />
                                ) : ( 
                                    <SpacerFilter />
                                )}

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            style={{ color: "#424242" }}
                                            onChange={(e) => {
                                                updateCounter(e, 2);
                                            }}
                                            checked={checksStatus[2].activated}
                                            disabled={
                                                (checksSelectedCounter === 2 &&
                                                    !checksStatus[2]
                                                        .activated) ||
                                                (checksStatus[1].activated &&
                                                    selectedDepartmentCounter ===
                                                        2)
                                            }
                                        />
                                    }
                                    label={"Empresa"}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            style={{ color: "#424242" }}
                                            onChange={(e) => {
                                                updateCounter(e, 3);
                                            }}
                                            checked={checksStatus[3].activated}
                                            disabled={
                                                (checksSelectedCounter === 2 &&
                                                    !checksStatus[3]
                                                        .activated) ||
                                                (checksStatus[1].activated &&
                                                    selectedDepartmentCounter ===
                                                        2)
                                            }
                                        />
                                    }
                                    label={"Outro Ano"}
                                />
                            </Filters>
                        </StyledFilter>
                    </AlignContainer>

                    <GeneralGraphFilter
                        globalCompany={globalCompany}
                        checksStatus={checksStatus}
                        permissions={permissions}
                        submodules={submodules}
                        /* Counters */
                        setChecksSelectedCounter={setChecksSelectedCounter}
                        checksSelectedCounter={checksSelectedCounter}
                        setSelectedDeparmentCounter={
                            setSelectedDeparmentCounter
                        }
                        selectedDepartmentCounter={selectedDepartmentCounter}
                    />
                </>
            ) : null}
        </>
    );
};

export default GeneralGraphChecks;