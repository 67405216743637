import React, {useContext, useEffect, useState} from "react";

/* Styles */
import {Filters} from "../../ipa-alignment/send-alignment-tab/send-alignment-tab.styles";

/* Components */
import TableToSendData from "../../../../components/table-to-send-data/table-to-send-data.component";
import PopUp from "../../../../components/popup/popup.component";
import RenderPage from "../../../../components/render-page/render-page.component";

/* Material UI */
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {makeStyles} from "@material-ui/core/styles";

/* Context */
import {authContext} from "../../../../contexts/AuthContext";

/* Api*/
import {findAllActiveUserTasks, getMatchMonthTasks, sendMonthlyTask} from "../../../../api/ApiTasks";
import {getAllUsersInCompany} from "../../../../api/ApiUser";
import {findIfSubmoduleIsActive} from "../../../../api/ApiPemissions";

/* Utils */
import {getColor} from "../../../../utils/genericFunctions";
import permissions, {getSubmodulePermission} from "../../../../utils/permission";
import {yearsUntilNow} from "../../../../utils/initialData";

const today = new Date();

const optionsMonths = [{name: "Janeiro", monthNumber: "01"}, {name: "Fevereiro", monthNumber: "02"}, {name: "Março", monthNumber: "03"}, {name: "Abril", monthNumber: "04"}, {name: "Maio", monthNumber: "05"}, {name: "Junho", monthNumber: "06"}, {name: "Julho", monthNumber: "07"}, {name: "Agosto", monthNumber: "08"}, {name: "Setembro", monthNumber: "09"}, {name: "Outubro", monthNumber: "10"}, {name: "Novembro", monthNumber: "11"}, {name: "Dezembro", monthNumber: "12"}];
const optionsYear = yearsUntilNow();

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    userFormControl: {
        backgroundColor: "#fff3e0",
        margin: theme.spacing(1),
        minWidth: 180,
    },
    formControlYear: {
        margin: theme.spacing(1),
        minWidth: 90,
        width: 100,
        borderRadius: 10,
    },
}));

export default function SendTasks() {
    const classes = useStyles();
    const {auth} = useContext(authContext);
    const [rows, setRows] = useState([]);

    const [year, setYear] = React.useState(today.getFullYear());
    const [month, setMonth] = React.useState(optionsMonths[(today.getMonth())]);

    const [optionsUsers, setOptionsUsers] = useState([]);
    const [user, setUser] = useState({
        userId: auth.data.user.userId,
        name: auth.data.user.name,
        companyId: auth.data.user.companyId
    });
    const [fillOthers, setFillOthers] = useState(false);

    const [submodules, setSubmodules] = useState({
        tasks: []
    });


    const [popUp, setPopUp] = React.useState({
        popUp: false,
        popUpTitle: "",
        popUpText: "",
        success: 1,
    });

    const [fetchOpen, setFetchOpen] = useState(true);

    let textData = {
        taskId: "Código",
        tableTitle: "Tarefas",
        textButton: "Salvar"
    };

    useEffect(() => {
        const callApiFindUserTasks = async (globalCompany, user) => {
            const response = await findAllActiveUserTasks(auth, globalCompany, user);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body.payload
        };

        const callApiMatchDate = async (globalCompany, data) => {
            const response = await getMatchMonthTasks(auth, data, globalCompany);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body.payload
        };

        const callApiUsersInCompany = async (globalCompany) => {
            const response = await getAllUsersInCompany(auth, globalCompany);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body.payload;
        };

        const callFindIfSubmoduleIsActive = async (permissionRole, permissionModule) => {
            const response = await findIfSubmoduleIsActive(auth, permissionRole, permissionModule);
            const body = await response.json();

            return body.payload
        };

        let isToday = false;
        if(!fillOthers) {
            isToday = true;
        }

        let date = new Date().toISOString();
        date = date.split("-");

        if(!user || !month || !year){
            setPopUp({
                popUp: true,
                popUpTitle: "Erro",
                popUpText: "Por favor, insira os dados no filtro.",
                success: 1,
            })
            return;
        }

        let data = {
            user: user.userId,
            month: isToday ? date[1] : month.monthNumber,
            year: isToday ? date[0] : year
        }

        callApiUsersInCompany(auth.data.user.companyId)
            .then(findAllUsersInRes => {
                findAllUsersInRes = findAllUsersInRes.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

                setOptionsUsers(findAllUsersInRes);
            })
            .catch(err => console.log(err));
        callFindIfSubmoduleIsActive(auth.data.user.permissionRoleId, permissions.IPA_SEND_ALIGNMENT)
            .then(resSend => {
                setSubmodules({tasks: resSend});
            })
            .catch(err => console.log(err));

        callApiFindUserTasks(auth.data.user.companyId, user)
            .then(tasksRes => {
                callApiMatchDate(auth.data.user.companyId, data)
                    .then(matchTaskRes => {
                        let tempRows = [];
                        let tempValue;

                        for(let i=0; i < tasksRes.length; i++) {
                            tempValue = matchTaskRes.filter(key => key.taskId === tasksRes[i].taskId);

                            if(tempValue.length === 0) {
                                tempRows[i] = {taskId: tasksRes[i].taskId, name: tasksRes[i].name, value: '',
                                    color: getColor(null), description: tasksRes[i].description}
                            } else {
                                tempRows[i] = {taskId: tasksRes[i].taskId, name: tasksRes[i].name, value: tempValue[0].grade,
                                    color: getColor(tempValue[0].grade), description: tasksRes[i].description}
                            }
                        }
                        setFetchOpen(false);
                        setRows(tempRows);
                    })
                    .catch(err => console.log(err));
            })
            .catch(err => console.log(err));

    }, [auth, month, year, user]);

    function handleChange(e, row, index) {
        let newArr = [...rows]; // copying the old datas array
        let color;

        color = getColor(e.target.value);

        let allow;

        if (e.target.value.match(/-/gi) && (e.target.value.length === 1))
            allow = true;
        else if (e.target.value.match(/-/gi) && (['-1', '-2', '-3'].indexOf(e.target.value) > -1))
            allow = true;
        else if (e.target.value.length === 1 && (['1', '2', '3'].indexOf(e.target.value) > -1))
            allow = true;
        else
            allow = false;

        if (allow) {
            newArr[index].value = e.target.value;
            newArr[index].color = color;
        } else {
            newArr[index].value = '';
            newArr[index].color = '';
        }
        
        setRows(newArr);

    }

    const handleSubmit = async event => {
        event.preventDefault();

        if(!user || !month || !year){
            setPopUp({
                popUp: true,
                popUpTitle: "Erro",
                popUpText: "Por favor, insira os dados no filtro.",
                success: 1,
            })
            return;
        }
        for(let i=0; i < rows.length ; i++) {
            if(rows[i].value === '') {
                setPopUp({
                    popUp: true,
                    popUpTitle: "Erro",
                    popUpText: "Por favor, preencha todos os campos.",
                    success: 1,
                })
                return;
            }
        }

        let res = [];
        let isToday = false;
        if(!fillOthers) {
            isToday = true;
        }

        let date = new Date().toISOString();
        date = date.split("-")[2].split("T");
        date = date[1].split(".");
        const time = date[0];

        for(let i=0; i<rows.length ; i++) {
            if(isToday) {
                res[i] = {grade: parseInt(rows[i].value),
                    taskId: rows[i].taskId}
            } else {
                res[i] = {grade: parseInt(rows[i].value),
                    taskId: rows[i].taskId,
                    createdAt: `${year}-${month.monthNumber}-01 ${time}`}
            }
        }

        let data = {
            user: user.userId,
            company: auth.data.user.companyId,
            tasks: [...res]
        };

        try {
            let response = await sendMonthlyTask(data, auth);

            if (response.status === 201) {
                setPopUp({
                    popUp: true,
                    popUpTitle: "Sucesso",
                    popUpText: `Tarefas do IPA em ${month.name}/${year} ${fillOthers ? `de ${user ? user.name : ""}` : ""} enviadas.`,
                    success: fillOthers ? 0 : 1,
                })

            } else {
                setPopUp({
                    popUp: true,
                    popUpTitle: "Erro",
                    popUpText: "Tarefas do IPA não puderam ser enviadas.",
                    success: 1,
                })
            }

        } catch (error) {
            console.log(error);
        }

    };

    const defaultPropsMonths = {
        options: optionsMonths,
        defaultValue: optionsMonths[(today.getMonth())],
        getOptionLabel: (option) => {
            return option.name
        },
        getOptionSelected: (option) => {
            return option.name
        },
        onChange: (event, newValue) => {
            setFetchOpen(true);
            setMonth(newValue);
        },
    };

    const defaultPropsUsers = {
        options: optionsUsers,
        value: user,
        getOptionLabel: (option) => {
            return option.name
        },
        getOptionSelected: (option) => {
            return option.name
        },
        onChange: (event, newValue) => {
            setFetchOpen(true);
            setUser(newValue);
        },
    };

    const handleChangeSystem = () => {
        setFillOthers(!fillOthers);
    };

    return (
        <>
            <Filters style={{justifyContent: fillOthers ? "space-between" : "flex-end"}}>
                {getSubmodulePermission(submodules.tasks, permissions.EMPLOYEES_VISUALIZATION_FILTER) && fillOthers ?
                    <Autocomplete
                        {...defaultPropsUsers}
                        id="users"
                        autoSelect
                        className={classes.userFormControl}
                        style={{width: 200}}
                        renderInput={(params) => <TextField {...params} label="Colaborador"
                                                            variant="outlined"/>}
                    />
                    : null}
                {getSubmodulePermission(submodules.tasks, permissions.DATA) && fillOthers ?
                    <Filters>
                        <Autocomplete
                            {...defaultPropsMonths}
                            id="departments"
                            autoSelect
                            className={classes.formControl}
                            style={{width: 150}}
                            renderInput={(params) => <TextField {...params} label="Mês"
                                                                variant="outlined"/>}
                        />
                        <FormControl variant="outlined" className={classes.formControlYear}>
                            <InputLabel id="demo-simple-select-outlined-label">Ano</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={year}
                                onChange={e => setYear(e.target.value)}
                                label="Ano">
                                {optionsYear.map((year, index) => {
                                    return (
                                        <MenuItem key={index} value={year}>{year}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Filters>
                    : null}
                {getSubmodulePermission(submodules.tasks, permissions.EMPLOYEES_VISUALIZATION_FILTER) && getSubmodulePermission(submodules.tasks, permissions.DATA)  ?
                    <FormControlLabel
                        style={{marginTop: 10, display: 'flex', justifyContent: 'center'}}
                        control={<Switch checked={fillOthers === true} name="checkedA"/>}
                        label={"Preencher outros"}
                        onChange={handleChangeSystem}
                    />
                    : null}
            </Filters>
            <TableToSendData handleSubmit={handleSubmit} handleChange={handleChange} textData={textData} getColor={getColor} year={year} value={month ? month.name : ""} rows={rows} sendTasks={true}/>
            {popUp.popUp ?
                <PopUp title={popUp.popUpTitle} string={popUp.popUpText} success={popUp.success} route={popUp.route ? popUp.route : null}/> : null}
            {fetchOpen ?
                <RenderPage open={fetchOpen}/> : null}
        </>

    );
}
