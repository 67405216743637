import React from 'react';
import { PolarAngleAxis, RadialBar, RadialBarChart, ResponsiveContainer, Tooltip } from "recharts";
import { TooltipChart } from './radial-bar-chat.styles';

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <TooltipChart className="TooltipChart">
          <p className="label">{`${payload[0].payload.name} : ${payload[0].value}`}</p>
        </TooltipChart>
      );
    }
  
    return null;
};

export default function CustomRadialBarChart({ data, width, height, innerRadius, outerRadius, barSize, barRadius }) {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <RadialBarChart
                width={width}
                height={height}
                cx="50%"
                cy="50%"
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                barSize={barSize}
                data={data}
                startAngle={90}
                endAngle={-269}
            >
            <PolarAngleAxis
                type="number"
                domain={[0, 100]}
                allowDataOverflow={true}
                angleAxisId={0}
                tick={false}
                // tick={true}
                // tickSize={-8}
                // ticks={[25, 50, 75, 100]}
            />
            <RadialBar
                background
                clockWise
                dataKey="value"
                cornerRadius={barRadius}
            />
            <Tooltip content={<CustomTooltip />} cursor={false}/>
            </RadialBarChart>
        </ResponsiveContainer>
    );
}