import React, {useContext} from "react";
import { Link } from 'react-router-dom';


/* Styles */
import { Disclaimer } from './ipa.styles';

/* Material UI */
import Button from '@material-ui/core/Button';

import {authContext} from "../../contexts/AuthContext";


export default function Ipa() {
    const {auth, setAuthData} = useContext(authContext);

    const handleChangeSystem = () => {
        setAuthData({
            token: auth.data.token,
            user: auth.data.user,
            system: false
        });
    };

    return (
        <div>
            <Disclaimer>
                <h1>Em breve, o Índice de Performance de Alinhamento (IPA) poderá ser acessado através deste switch</h1>
                <Link to='/dashboard'>
                    <Button variant="contained" color="primary" onClick={handleChangeSystem}>Retornar ao IF</Button>
                </Link>
            </Disclaimer>
        </div>
    );
}

