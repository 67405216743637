import styled from 'styled-components';

export const Spacer = styled.div`
    height: 12vh;
    @media screen and (max-height: 600px) {
        height: 70px;
    }
`;

export const DrawerAdjustment = styled.div`
    padding-left: 72px;
    @media screen and (max-width: 800px) {
        padding-left: 0;
    }
`;
