import React, {useCallback, useContext, useEffect, useState} from 'react';
import {isUndefined} from "svgsaver/src/utils";

/* Graph */
import {HorizontalGridLines, LineSeries, makeVisFlexible, XAxis, XYPlot, YAxis, LineMarkSeries, Hint} from 'react-vis';
import DiscreteColorLegend from "react-vis/es/legends/discrete-color-legend";

/* Style */
import {GraphicStyle, Legend, TableData, GraphicConfigContainer} from './if-graph.styles';
import "react-vis/dist/style.css";

/* Material UI */
import {Checkbox, FormControlLabel} from '@material-ui/core';
import Switch from "@material-ui/core/Switch";

/* icons */

/* Components*/
import GenPDF from '../../../../components/make-pdf/make-pdf';

/* api */
import {getCompanyAnnualIF, getDepartmentAnnualIF, getUserAnnualIF} from "../../../../api/ApiIF";
import {getOneCompany} from "../../../../api/ApiCompany";

/* Context */
import {authContext} from "../../../../contexts/AuthContext";

/* Functions */
import {getAverageInMonth} from "../../../../utils/clearDataFromApi/clearDataFromIF";

const FlexibleXYPlot = makeVisFlexible(XYPlot);

function removeBlankPoints(array) {
    let fixedArray,
        i, n;
    const keys = ['environment', 'financial', 'health', 'motivational', 'personal', 'professional'];

    if (typeof array[0] === 'object') {
        fixedArray = JSON.parse(JSON.stringify(array));

        fixedArray = fixedArray.map(obj => {
            for (i = 0, n = keys.length; i < n; i++) {
                if (isNaN(obj[keys[i]]))
                    obj[keys[i]] = null;
            }

            return obj;
        });
    } else {
        fixedArray = array.map(value => {
            if (isNaN(value))
                value = null;

            return value;
        });
    }

    return fixedArray;
}

/* Component did update */
function useForceUpdate() {
    const [, setTick] = useState(0);
    return useCallback(() => {
        setTick(tick => tick + 1);
    }, []);
}

window.addEventListener("orientationchange", function () {
    window.location.reload()
}, false);

const EMPLOYEE = 0;
const DEPARTMENT = 1;
const COMPANY = 2;
const OTHERYEAR = 3;

const months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];


export default function Graphic(props) {
    const [series] = useState([
        {
            name: "Profissional",
            nameCompany: "ProfissionalOther",
            activated: props.seriesAtt[0].activated,
            color: "#4384F4",
            stroke: "solid"
        },
        {
            name: "Pessoal",
            nameCompany: "PessoalOther",
            activated: props.seriesAtt[1].activated,
            color: "#EA4335",
            stroke: "solid"
        },
        {
            name: "Financeiro",
            nameCompany: "FinanceiroOther",
            activated: props.seriesAtt[2].activated,
            color: "#FBBC04",
            stroke: "solid"
        },
        {
            name: "Motivacional",
            nameCompany: "MotivacionalOther",
            activated: props.seriesAtt[3].activated,
            color: "#41AE5F",
            stroke: "solid"
        },
        {
            name: "Saude",
            nameCompany: "SaudeOther",
            activated: props.seriesAtt[4].activated,
            color: "#F86D03",
            stroke: "solid"
        },
        {
            name: "Ambiente",
            nameCompany: "AmbienteOther",
            activated: props.seriesAtt[5].activated,
            color: "#56C3CB",
            stroke: "solid"
        },
        {
            name: "Média Anual",
            nameCompany: "MediaOther",
            activated: props.seriesAtt[6].activated,
            color: "#980800",
            stroke: "solid"
        },
    ]);

    const [data, setData] = React.useState([]);

    const [hoverData, setHoverData] = React.useState(null);

    const [graphLines1, setGraphLines1] = React.useState({});

    const [graphLines2, setGraphLines2] = React.useState({});

    const [discreteColorLegend, setDiscreteColorLegend] = React.useState([]);

    const [verifyValues, setVerifyValues] = React.useState(false);

    const {auth} = useContext(authContext);

    const [actualUserData, setActualUserData] = useState(`${auth.data.user.name}`);

    const [actualDepartmentData, setActualDepartmentData] = useState('Seu setor');

    const [yDomain, setYDomain] = React.useState([]);

    const forceUpdate = useForceUpdate();

    const callApiIFUser = async (user, year, company) => {
        const response = await getUserAnnualIF(auth, year, user, company);
        const body = await response.json();

        if (response.status !== 200) throw Error(body.message);

        let aux = body.payload;
        let averagePerMonth = [];
        let auxDate;
        let empty;
        let generalAverage = [NaN];

        empty = aux.length === 0;

        /**
         * All the fields start with NaN values as placeholders. If the API sends
         * valid data, then the operations are done correctly. Otherwise all the
         * math will end up making more NaN values. They are replaced by null
         * values before being sent to the graphics.
         */
        for (let i = 0; i < 12; i++) {
            averagePerMonth[i] = {
                environment: NaN,
                financial: NaN,
                health: NaN,
                motivational: NaN,
                personal: NaN,
                professional: NaN
            };
        }

        if (empty) {
            return {averagePerMonth, generalAverage}
        }

        for (let i = 0; i < 12; i++) {
            for (let j = 0; j < aux.length; j++) {
                auxDate = aux[j].date.split("-");
                auxDate = auxDate[1];

                if (parseInt(auxDate) === 1 && i === 0) {
                    averagePerMonth[i] = aux[j];
                } else if (parseInt(auxDate) === (i+1)) {
                    averagePerMonth[i] = aux[j];
                }
            }
        }

        for (let i = 0; i < 12; i++) {
            for (let key in averagePerMonth[i]) {
                if (averagePerMonth[i].hasOwnProperty(key)) {
                    averagePerMonth[i][key] = averagePerMonth[i][key] * 33.33;
                }
            }
        }

        for (let i = 0; i < 12; i++) {
            // to each month that is the "i" we sum all the fields and divide for the total of elements to get the average per month
            generalAverage[i] = 0;
            generalAverage[i] += averagePerMonth[i].environment;
            generalAverage[i] += averagePerMonth[i].professional;
            generalAverage[i] += averagePerMonth[i].personal;
            generalAverage[i] += averagePerMonth[i].financial;
            generalAverage[i] += averagePerMonth[i].motivational;
            generalAverage[i] += averagePerMonth[i].health;
            generalAverage[i] = generalAverage[i] / 6;
        }

        averagePerMonth = removeBlankPoints(averagePerMonth);
        generalAverage = removeBlankPoints(generalAverage);

        return {averagePerMonth, generalAverage};
    };


    const callApiIFCompany = async (year, company) => {
        const response = await getCompanyAnnualIF(auth, year, company);
        const body = await response.json();

        if (response.status !== 200) throw Error(body.message);

        let averagePerMonth = await body.payload[0][year];
        let size = 0, key;
        let generalAverage = {};
        let average = 0;


        for (key in averagePerMonth) {
            if (averagePerMonth.hasOwnProperty(key)) {
                size++;

                for (let keyIFField in averagePerMonth[key]) {
                    if (averagePerMonth[key].hasOwnProperty(keyIFField) && (averagePerMonth[key][keyIFField] !== null)) {
                        average += averagePerMonth[key][keyIFField];
                        averagePerMonth[key][keyIFField] = (parseFloat(averagePerMonth[key][keyIFField]) * 33.33).toFixed(0);
                    }
                }
                if (generalAverage[key] !== null) {
                    generalAverage[key] = (average / 6) * 33.33;
                    parseFloat(generalAverage[key].toFixed(2));
                }
                average = 0;
            }

        }

        generalAverage = getAverageInMonth(generalAverage, 0);

        averagePerMonth = getAverageInMonth(averagePerMonth, 0);

        for (let i = 0; i < 12; i++) {
            if (averagePerMonth[i] === 0) {
                averagePerMonth[i] = {
                    environment: 0,
                    financial: 0,
                    health: 0,
                    motivational: 0,
                    personal: 0,
                    professional: 0
                }
            }
        }

        for (let i = size; i < 12; i++) {
            averagePerMonth[i] = {
                environment: NaN,
                financial: NaN,
                health: NaN,
                motivational: NaN,
                personal: NaN,
                professional: NaN
            };
            generalAverage[i] = NaN;
        }

        averagePerMonth = removeBlankPoints(averagePerMonth);
        generalAverage = removeBlankPoints(generalAverage);

        return {generalAverage, averagePerMonth};
    };

    const callApiIFDepartment = async (department, year, company) => {
        const response = await getDepartmentAnnualIF(auth, year, department, company);
        const body = await response.json();

        if (response.status !== 200) throw Error(body.message);

        let averagePerMonth = await body.payload[0][year];
        let size = 0, key;
        let generalAverage = {};
        let average = 0;

        for (key in averagePerMonth) {
            if (averagePerMonth.hasOwnProperty(key)) {
                size++;
                average += averagePerMonth[key].environment;
                averagePerMonth[key].environment = parseFloat(averagePerMonth[key].environment) * 33.33;
                average += averagePerMonth[key].professional;
                averagePerMonth[key].professional = parseFloat(averagePerMonth[key].professional) * 33.33;
                average += averagePerMonth[key].personal;
                averagePerMonth[key].personal = parseFloat(averagePerMonth[key].personal) * 33.33;
                average += averagePerMonth[key].financial;
                averagePerMonth[key].financial = parseFloat(averagePerMonth[key].financial) * 33.33;
                average += averagePerMonth[key].motivational;
                averagePerMonth[key].motivational = parseFloat(averagePerMonth[key].motivational) * 33.33;
                average += averagePerMonth[key].health;
                averagePerMonth[key].health = parseFloat(averagePerMonth[key].health) * 33.33;

                generalAverage[key] = (average / 6) * 33.33;
                generalAverage[key].toFixed(0);
                average = 0;
            }
        }

        generalAverage = getAverageInMonth(generalAverage, 0);

        averagePerMonth = getAverageInMonth(averagePerMonth, 0);

        for (let i = 0; i < 12; i++) {
            if (averagePerMonth[i] === 0) {
                averagePerMonth[i] = {
                    environment: 0,
                    financial: 0,
                    health: 0,
                    motivational: 0,
                    personal: 0,
                    professional: 0
                }
            }
        }

        for (let i = size; i < 12; i++) {
            averagePerMonth[i] = {
                environment: NaN,
                financial: NaN,
                health: NaN,
                motivational: NaN,
                personal: NaN,
                professional: NaN
            };
            generalAverage[i] = NaN;
        }

        averagePerMonth = removeBlankPoints(averagePerMonth);
        generalAverage = removeBlankPoints(generalAverage);

        return {generalAverage, averagePerMonth};
    };


    const handleChange = (name) => {
        let numberOfCheckedBoxes = series.reduce((total, checkBox) => total += checkBox.activated, 0);
        let indexToUpdate = series.findIndex(element => element.name === name);
        if (numberOfCheckedBoxes === 1 && series[indexToUpdate].activated)
            return;
        series[indexToUpdate].activated = !series[indexToUpdate].activated;

        props.updateSeriesChild(series);
        forceUpdate();
    };

    const getData = (res, OtherRes, tempData) => {
        if (tempData.length !== 0) {
            for (let i = 0; i < 12; i++) {
                tempData["Profissional"].push({
                    x: months[i],
                    y:
                        res.averagePerMonth[i].professional !== null && !isNaN(res.averagePerMonth[i].professional)
                            ? Math.round(res.averagePerMonth[i].professional)
                            : null,
                });
                tempData["Pessoal"].push({
                    x: months[i],
                    y:
                        res.averagePerMonth[i].personal !== null && !isNaN(res.averagePerMonth[i].personal)
                            ? Math.round(res.averagePerMonth[i].personal)
                            : null,
                });
                tempData["Financeiro"].push({
                    x: months[i],
                    y:
                        res.averagePerMonth[i].financial !== null && !isNaN(res.averagePerMonth[i].financial)
                            ? Math.round(res.averagePerMonth[i].financial)
                            : null,
                });
                tempData["Motivacional"].push({
                    x: months[i],
                    y:
                        res.averagePerMonth[i].motivational !== null && !isNaN(res.averagePerMonth[i].motivational)
                            ? Math.round(res.averagePerMonth[i].motivational)
                            : null,
                });
                tempData["Saude"].push({
                    x: months[i],
                    y:
                        res.averagePerMonth[i].health !== null && !isNaN(res.averagePerMonth[i].health)
                            ? Math.round(res.averagePerMonth[i].health)
                            : null,
                });
                tempData["Ambiente"].push({
                    x: months[i],
                    y:
                        res.averagePerMonth[i].environment !== null && !isNaN(res.averagePerMonth[i].environment)
                            ? Math.round(res.averagePerMonth[i].environment)
                            : null,
                });
                tempData["Média Anual"].push({
                    x: months[i],
                    y:
                        res.generalAverage[i] !== null && !isNaN(res.generalAverage[i])
                            ? Math.round(res.generalAverage[i])
                            : null,
                });
                if (OtherRes) {
                    tempData["ProfissionalOther"].push({
                        x: months[i],
                        y:
                            OtherRes.averagePerMonth[i].professional !== null && !isNaN(OtherRes.averagePerMonth[i].professional)
                                ? Math.round(
                                      OtherRes.averagePerMonth[i].professional,
                                  )
                                : null,
                    });
                    tempData["PessoalOther"].push({
                        x: months[i],
                        y:
                            OtherRes.averagePerMonth[i].personal !== null && !isNaN(OtherRes.averagePerMonth[i].personal)
                                ? Math.round(
                                      OtherRes.averagePerMonth[i].personal,
                                  )
                                : null,
                    });
                    tempData["FinanceiroOther"].push({
                        x: months[i],
                        y:
                            OtherRes.averagePerMonth[i].financial !== null && !isNaN(OtherRes.averagePerMonth[i].financial)
                                ? Math.round(
                                      OtherRes.averagePerMonth[i].financial,
                                  )
                                : null,
                    });
                    tempData["MotivacionalOther"].push({
                        x: months[i],
                        y:
                            OtherRes.averagePerMonth[i].motivational !== null && !isNaN(OtherRes.averagePerMonth[i].motivational)
                                ? Math.round(
                                      OtherRes.averagePerMonth[i].motivational,
                                  )
                                : null,
                    });
                    tempData["SaudeOther"].push({
                        x: months[i],
                        y:
                            OtherRes.averagePerMonth[i].health !== null && !isNaN(OtherRes.averagePerMonth[i].health)
                                ? Math.round(OtherRes.averagePerMonth[i].health)
                                : null,
                    });
                    tempData["AmbienteOther"].push({
                        x: months[i],
                        y:
                            OtherRes.averagePerMonth[i].environment !== null && !isNaN(OtherRes.averagePerMonth[i].environment)
                                ? Math.round(
                                      OtherRes.averagePerMonth[i].environment,
                                  )
                                : null,
                    });
                    tempData["MediaOther"].push({
                        x: months[i],
                        y:
                            OtherRes.generalAverage[i] !== null && !isNaN(OtherRes.generalAverage[i])
                                ? Math.round(OtherRes.generalAverage[i])
                                : null,
                    });
                    tempData["Média Outro"].push({x: months[i], y: 45});
                }
            }
        }

        getDomain(tempData);
        return tempData;
    };

    const getDomain = (tempData) => {
        let minValue = 0;
        let maxValue = 0;
        let aux;
        let d;
        for (let key in tempData) {
            if (tempData.hasOwnProperty(key) && (tempData[key].length > 0)) {
                d = tempData[key];

                aux = Math.min(...d.map(d => d.y)); //min value
                if (aux < minValue) {
                    minValue = aux;
                }

                aux = Math.max(...d.map(d => d.y)); //max value
                if (aux > maxValue) {
                    maxValue = aux;
                }
            }
        }

        const yDomainTemp = [1.02 * minValue, 1.02 * maxValue];

        if ((yDomain.length === 0) || (yDomainTemp !== yDomain)) {
            setYDomain(yDomainTemp);
        }

    };

    const callAPICombinations = (res, tempGraphLines1, tempGraphLines2, tempData, firstDepartment, secondDepartment, company) => {
        if (tempGraphLines1)
            setGraphLines1(tempGraphLines1);
        else
            setGraphLines1(null);
        if (tempGraphLines2)
            setGraphLines2(tempGraphLines2);
        else
            setGraphLines2(null);

        if (props.user)
            setActualUserData(props.user.name);
        else
            setActualUserData(auth.data.user.name);

        if (!actualDepartmentData)
            setActualDepartmentData(props.tempDepartment ? props.tempDepartment.name : 'Seu setor');

        if (!tempGraphLines2) {
            let temp = getData(res, null, tempData);
            setData(temp);
        } else if (tempGraphLines2.id === COMPANY) {
            callApiIFCompany(props.year, company)
                .then(OtherRes => {
                    let temp = getData(res, OtherRes, tempData);
                    setData(temp);
                })
        } else if (tempGraphLines2.id === DEPARTMENT) {
            callApiIFDepartment(firstDepartment && !secondDepartment ? firstDepartment : secondDepartment ? secondDepartment : props.tempDepartment, props.year, company)
                .then(OtherRes => {
                    let temp = getData(res, OtherRes, tempData);
                    setData(temp);
                })
        } else if (tempGraphLines2.id === EMPLOYEE) {
            callApiIFUser(props.user, props.year, company)
                .then(OtherRes => {
                    let temp = getData(res, OtherRes, tempData);
                    setData(temp);
                })
        } else if (tempGraphLines2.id === OTHERYEAR) {
            if (tempGraphLines1.id === COMPANY) {
                callApiIFCompany(props.otherYear, company)
                    .then(OtherRes => {
                        let temp = getData(res, OtherRes, tempData);
                        setData(temp);
                    })
            } else if (tempGraphLines1.id === DEPARTMENT) {
                callApiIFDepartment(firstDepartment ? firstDepartment : secondDepartment ? secondDepartment : props.tempDepartment, props.otherYear, company)
                    .then(OtherRes => {
                        let temp = getData(res, OtherRes, tempData);
                        setData(temp);
                    })
            } else if (tempGraphLines1.id === EMPLOYEE) {
                callApiIFUser(props.user, props.otherYear, company)
                    .then(OtherRes => {
                        let temp = getData(res, OtherRes, tempData);
                        setData(temp);
                    })
            }
        }
    };

    useEffect(() => {
        const callApiFindOneCompany = async () => {
            const response = await getOneCompany(auth, auth.data.user.companyId);

            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body.payload[0];
        };


        let actualGlobalCompany;

        /* The graph filters will correspond to the selected global company */
        if (!isUndefined(props.globalCompany) && (props.globalCompany !== null)) {
            if (props.globalCompany.hasOwnProperty("companyId")) {
                actualGlobalCompany = props.globalCompany.companyId;
            } else {
                /* this logic had to be different from the others global filter because
                if I called this part in Graphics it updated every time I changed a filter
                 and doing this the information of the current company was lost and replaced
                 by the standard company (the standard user)*/
                callApiFindOneCompany()
                    .then(actualCompany => {
                        props.changeActualGlobalCompany(actualCompany);
                    })
                    .catch(err => console.log(err))
                actualGlobalCompany = auth.data.user.companyId;
            }
        } else {
            callApiFindOneCompany()
                .then(actualCompany => {
                    props.changeActualGlobalCompany(actualCompany);
                })
                .catch(err => console.log(err))
            actualGlobalCompany = auth.data.user.companyId;
        }

        let tempGraphLines1 = null;
        let tempGraphLines2 = null;
        /* Here we will separate each element
        that will show in graphic, but the maximum is 2 elements,
        the tempGraphLines1 and tempGraphLines2*/

        /* Checking if the first information is employee, company or other year*/
        for (let i = 0; i < 4; i++) { //first graph lines
            if (props.allowedGraphInfo[i].activated && i !== DEPARTMENT) {
                tempGraphLines1 = props.allowedGraphInfo[i];
                break;
            }
        }

        /* Checking if the second information is employee, company or other year*/
        if (tempGraphLines1) {
            for (let i = 0; i < 4; i++) { //second graph lines
                if (props.allowedGraphInfo[i].activated && (props.allowedGraphInfo[i].id !== tempGraphLines1.id) && i !== DEPARTMENT) {
                    tempGraphLines2 = props.allowedGraphInfo[i];
                    break;
                } else if ((i === 2) && (!tempGraphLines2)) {
                    tempGraphLines2 = null;
                }
            }
        } else if (!tempGraphLines1 && props.allowedGraphInfoDepartments.length === 0) {
            tempGraphLines1 = props.allowedGraphInfo[DEPARTMENT];
        }

        let firstDepartment = null;
        let secondDepartment = null;

        /* Checking the specific case with the departments because of the list of checkboxes*/
        if (props.allowedGraphInfoDepartments.length !== 0) {
            /* Checking if the first information on the chart is the first department*/
            if (props.allowedGraphInfoDepartments.length === 1 && !tempGraphLines1) {
                tempGraphLines1 = {
                    id: 1,
                    name: `Setor - ${props.allowedGraphInfoDepartments[0].name}`,
                    activated: true,
                    color: "#424242",
                    stroke: "solid"
                };
                firstDepartment = props.allowedGraphInfoDepartments[0];
                setActualDepartmentData(props.allowedGraphInfoDepartments[0].name);
                /* Checking if the second information on the chart is the first department*/
            } else if (props.allowedGraphInfoDepartments.length === 1 && tempGraphLines1) {
                tempGraphLines2 = {
                    id: 1,
                    name: `Setor - ${props.allowedGraphInfoDepartments[0].name}`,
                    activated: true,
                    color: "#424242",
                    stroke: "solid"
                };
                firstDepartment = props.allowedGraphInfoDepartments[0];
                setActualDepartmentData(props.allowedGraphInfoDepartments[0].name);
            }

            /* Checking if the second information on the chart is the second department*/
            if (props.allowedGraphInfoDepartments.length === 2 && !tempGraphLines1 && !tempGraphLines2) {
                tempGraphLines1 = {
                    id: 1,
                    name: `Setor - ${props.allowedGraphInfoDepartments[0].name}`,
                    activated: true,
                    color: "#424242",
                    stroke: "solid"
                };
                tempGraphLines2 = {
                    id: 1,
                    name: `Setor - ${props.allowedGraphInfoDepartments[1].name}`,
                    activated: true,
                    color: "#424242",
                    stroke: "solid"
                };
                firstDepartment = props.allowedGraphInfoDepartments[0];
                secondDepartment = props.allowedGraphInfoDepartments[1];
            }
        }

        /* Checking if the comparison is between department and employee
        if is it true, just show the employees that are inside the department*/
        if (tempGraphLines1 && tempGraphLines2) {
            if ((tempGraphLines1.id === EMPLOYEE && tempGraphLines2.id === DEPARTMENT) || (tempGraphLines1.id === DEPARTMENT && tempGraphLines2.id === EMPLOYEE)) {
                props.comparisonBetweenDepartmentAndEmployee(true, firstDepartment);
            } else {
                props.comparisonBetweenDepartmentAndEmployee(false);
            }
        } else {
            props.comparisonBetweenDepartmentAndEmployee(false);
        }

        /*This is to show the legend of the chart*/
        if (tempGraphLines2) {
            if (tempGraphLines2.id === OTHERYEAR) {
                if (tempGraphLines1.id === EMPLOYEE) {
                    setDiscreteColorLegend([{
                        title: `${tempGraphLines1.name} (${props.year}) `,
                        color: '#424242'
                    }, {
                        title: `${tempGraphLines2.name} (${props.otherYear}) - Colaborador`,
                        color: '#424242',
                        strokeDasharray: "2 2"
                    }])
                } else if (tempGraphLines1.id === COMPANY) {
                    setDiscreteColorLegend([{
                        title: `${tempGraphLines1.name} (${props.year}) `,
                        color: '#424242'
                    }, {
                        title: `${tempGraphLines2.name} (${props.otherYear}) - Empresa`,
                        color: '#424242',
                        strokeDasharray: "2 2"
                    }])
                } else {
                    setDiscreteColorLegend([{
                        title: `${tempGraphLines1.name} (${props.year}) `,
                        color: '#424242'
                    }, {
                        title: `${tempGraphLines2.name} (${props.otherYear}) - Setor`,
                        color: '#424242',
                        strokeDasharray: "2 2"
                    }])
                }
            } else {
                setDiscreteColorLegend([{
                    title: tempGraphLines1.id === OTHERYEAR ? ` ${tempGraphLines1.name} (${props.otherYear}) ` : ` ${tempGraphLines1.name} (${props.year}) `,
                    color: '#424242'
                }, {title: `${tempGraphLines2.name} (${props.year}) `, color: '#424242', strokeDasharray: "2 2"}])
            }
        } else {
            if (tempGraphLines1.id === OTHERYEAR) {
                setDiscreteColorLegend([{
                    title: `${tempGraphLines1.name} (${props.otherYear}) - Colaborador`,
                    color: '#424242'
                }])
            } else {
                setDiscreteColorLegend([{title: `${tempGraphLines1.name} (${props.year}) `, color: '#424242'}])
            }
        }


        /* This is for apply the simplify button logic,
        just let one aspect selected or mark all as selected*/
        if (!props.checkboxControl) {
            if (props.simplify) {
                for (let i = 0; i < series.length; i++) {
                    if (series[i].name !== "Média Anual")
                        series[i].activated = false;
                    else
                        series[i].activated = true;
                }

            } else {
                for (let i = 0; i < series.length; i++) {
                    if (series[i].name !== "Média Anual")
                        series[i].activated = true;
                    else
                        series[i].activated = true;
                }

            }
        }

        let tempData = {
            "Profissional": [],
            "Pessoal": [],
            "Financeiro": [],
            "Motivacional": [],
            "Saude": [],
            "Ambiente": [],
            "Média Anual": [],
            "ProfissionalOther": [],
            "PessoalOther": [],
            "FinanceiroOther": [],
            "MotivacionalOther": [],
            "SaudeOther": [],
            "AmbienteOther": [],
            "MediaOther": [],
            "Média Outro": []
        };


        /* This is for we call the api of the information that we have in tempGraphLines1 and tempGraphLines2*/
        if (tempGraphLines1.id === EMPLOYEE) {
            callApiIFUser(props.user, props.year, actualGlobalCompany)
                .then(res => {
                    callAPICombinations(res, tempGraphLines1, tempGraphLines2, tempData, firstDepartment, null, actualGlobalCompany);
                })
                .catch(err => console.log(err));
        } else if (tempGraphLines1.id === DEPARTMENT) {
            callApiIFDepartment(firstDepartment ? firstDepartment : props.tempDepartment, props.year, actualGlobalCompany)
                .then(res => {
                    callAPICombinations(res, tempGraphLines1, tempGraphLines2, tempData, firstDepartment, secondDepartment, actualGlobalCompany);
                })
                .catch(err => console.log(err));
        } else if (tempGraphLines1.id === COMPANY) {
            callApiIFCompany(props.year, actualGlobalCompany)
                .then(res => {
                    callAPICombinations(res, tempGraphLines1, tempGraphLines2, tempData, firstDepartment, null, actualGlobalCompany);
                })
                .catch(err => console.log(err));
        } else if (tempGraphLines1.id === OTHERYEAR) {
            if (!tempGraphLines2) {
                callApiIFUser(props.user, props.otherYear, actualGlobalCompany)
                    .then(res => {
                        callAPICombinations(res, tempGraphLines1, tempGraphLines2, tempData, firstDepartment, null, actualGlobalCompany);
                    })
                    .catch(err => console.log(err));
            } else if (tempGraphLines2.id === EMPLOYEE) {
                callApiIFUser(props.user, props.otherYear, actualGlobalCompany)
                    .then(res => {
                        callAPICombinations(res, tempGraphLines1, tempGraphLines2, tempData, firstDepartment, null, actualGlobalCompany);
                    })
                    .catch(err => console.log(err));
            } else if (tempGraphLines2.id === DEPARTMENT) {
                callApiIFDepartment(firstDepartment ? firstDepartment : props.tempDepartment, props.otherYear, actualGlobalCompany)
                    .then(res => {
                        callAPICombinations(res, tempGraphLines1, tempGraphLines2, tempData, firstDepartment, null, actualGlobalCompany);
                    })
                    .catch(err => console.log(err));
            } else if (tempGraphLines2.id === COMPANY) {
                callApiIFCompany(props.otherYear, actualGlobalCompany)
                    .then(res => {
                        callAPICombinations(res, tempGraphLines1, tempGraphLines2, tempData, firstDepartment, null, actualGlobalCompany);
                    })
                    .catch(err => console.log(err));
            }
        }
    }, [props.year, props.otherYear, props.user, props.department, props.simplify, props.allowedGraphInfo, props.countCheckedFields, props.checkboxControl, props.tempDepartment, props.allowedGraphInfoDepartments, props.globalCompany]);

    const handleChangeVerify = () => {
        setVerifyValues(!verifyValues);
    };

    return (
        <GraphicStyle id="canvas">
            <TableData>
                <p>
                    Mostrando dados de
                    <b>{graphLines1 ? (graphLines1.id === EMPLOYEE) ? ` ${actualUserData} ` : (graphLines1.id === COMPANY) ? ` ${props.globalCompany.name}` : (graphLines1.id === OTHERYEAR) ? ` Outro ano ` : (graphLines1.id === DEPARTMENT) ? ` ${actualDepartmentData} ` : null : null}</b>
                    <b>{graphLines2 ? (graphLines2.id === EMPLOYEE) ? ` e ${actualUserData} ` : (graphLines2.id === COMPANY) ? ` e ${props.globalCompany.name}` : (graphLines2.id === OTHERYEAR) ? ` e Outro ano ` : (graphLines2.id === DEPARTMENT) && props.allowedGraphInfoDepartments.length === 2 ? ` e ${props.allowedGraphInfoDepartments[1].name} ` : (graphLines2.id === DEPARTMENT) ? ` e ${actualDepartmentData} ` : null : null}</b>
                    {graphLines1.id !== OTHERYEAR ? " em" : null}
                    <b>{graphLines1.id !== OTHERYEAR ? ` ${props.year}` : null}</b>
                </p>
                <GraphicConfigContainer>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={verifyValues}
                                onChange={handleChangeVerify}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        style={{margin: 0}}
                        labelPlacement="top"
                        label="Valores no gráfico"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={props.simplify}
                                onChange={props.handleChangeSimplify}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        style={{margin: 0}}
                        labelPlacement="top"
                        label="Desmarcar campos"
                    />
                </GraphicConfigContainer>
                <GenPDF node={document.getElementById("graphic-container-0")} name={"if"} coords={[5, 50, 200, 135]}/>
            </TableData>
            <FlexibleXYPlot id={"grafico"} xType={"ordinal"} yDomain={/*yDomain*/ [-100, 100]} onMouseLeave={() => setHoverData(null)}>
                <HorizontalGridLines/>
                {!verifyValues && series.map((tipo, index) => (
                    tipo.activated &&
                    <LineSeries getNull={(d) => d.y !== null}
                                data={data[tipo.name]}
                                key={index}
                                color={tipo.color}
                                strokeStyle={tipo.stroke}/>
                ))}
                {!verifyValues && series.map((tipo, index) => (
                    tipo.activated && graphLines2 &&
                    <LineSeries getNull={(d) => d.y !== null}
                                data={data[tipo.nameCompany]}
                                key={index}
                                color={tipo.color}
                                style={{strokeDasharray: "2 2"}}/>
                ))}
                {verifyValues && series.map((tipo) => (
                    tipo.activated &&
                    <LineMarkSeries
                        onValueMouseOver={(d) => setHoverData(d)}
                        getNull={(d) => d.y !== null}
                        data={data[tipo.name]}
                        key={tipo.name}
                        color={tipo.color}
                        strokeStyle={tipo.stroke}/>
                ))}
                {verifyValues && series.map((tipo, index) => (
                    tipo.activated && graphLines2 &&
                    <LineMarkSeries onValueMouseOver={(d) => setHoverData(d)}
                                    getNull={(d) => d.y !== null}
                                    data={data[tipo.nameCompany]}
                                    key={index}
                                    color={tipo.color}
                                    style={{strokeDasharray: "2 2"}}/>
                ))}
                <XAxis tickFormat={v => v.substring(0, 3)}/>
                <YAxis/>
                {hoverData && <Hint value={hoverData}/>}
            </FlexibleXYPlot>
            <Legend style={{justifyContent: 'center', paddingBottom: 20}}>
                <DiscreteColorLegend items={discreteColorLegend} orientation='horizontal'/>
            </Legend>
            <Legend>
                {series.map((tipo, index) => (
                    <FormControlLabel key={index} control={<Checkbox key={index}
                                                                     style={{
                                                                         color: tipo.color
                                                                     }}
                                                                     onChange={(e) => handleChange(tipo.name, e)}
                                                                     checked={tipo.activated}/>}
                                      label={tipo.name}
                    />
                ))}
                {/*{props.simplify && series.map((tipo, index) => (*/}
                {/*    tipo.name == "Média Anual" &&*/}
                {/*    <FormControlLabel key={index} disabled control={<Checkbox key={index}*/}
                {/*                                                              style={{*/}
                {/*                                                                  color: tipo.color*/}
                {/*                                                              }}*/}
                {/*                                                              onChange={(e) => handleChange(tipo.name, e)}*/}
                {/*                                                              checked={true}/>}*/}
                {/*                      label={tipo.name}*/}
                {/*    />))}*/}

            </Legend>
        </GraphicStyle>)
}
