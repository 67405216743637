import styled from "styled-components";

export const StyledInput = styled.input`
    border: none;
    border: 1px solid #8492A6;
    font-size: 16px;
    height: 40px;
    max-width: 600px;
    margin: 5px;
    text-align: center;
    color: #757575;
    border-radius: 10px;
    font-family: 'Arial', sans-serif;
    &:focus {
        border: 2px solid #5c9089;
        outline: none;
    }
`;

export const ContainerButtons = styled.div`
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
   @media screen and (max-width: 650px) {
        flex-direction: column;
    }
`;

export const DetailModal = styled.div`
    display: grid;
    grid-template-areas:
        'column1 column2'
        'column3 column4'
        'column5 column5'
        'column6 column6';
    justify-items: center;
    padding: 0 120px;
`;

export const DetailGender = styled.div`
    display: grid;
    grid-area: column1;
    width: 100%;
    padding: 10px;
`;

export const DetailBirthDate = styled.div`
    display: grid;
    grid-area: column2;
    width: 100%;
    padding: 10px;
`;

export const DetailFortress = styled.div`
    display: grid;
    grid-area: column3;
    width: 100%;
    padding: 10px;
`;

export const DetailStartDate = styled.div`
    display: grid;
    grid-area: column4;
    width: 100%;
    padding: 10px;
`;

export const DetailDream = styled.div`
    display: grid;
    grid-area: column5;
    width: 100%;
    padding: 10px;
`;

export const DetailNote = styled.div`
    display: grid;
    grid-area: column6;
    width: 100%;
    padding: 10px;
    margin: 12px auto;
`;
