import React, { useContext, useState, useEffect } from 'react';

/* Context */
import { authContext } from '../../../../contexts/AuthContext';

/* API */
import { findUserAlignments } from '../../../../api/ApiAlignment';
import { getOneDepartment } from '../../../../api/ApiDepartment';

/* Material UI */
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import { makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

/* Styles */
import {
    Department,
    TitleInfo,
    Alignment,
    TitleInfoDepartment,
    DepartmentIcon,
    DepartmentName,
    ContainerTextBar,
    Bar,
    TextBar,
    Name,
    FortressName,
    OtherDepartmentTitle,
    OtherDepartments,
    UserAlignments,
    DepartmentAlignment,
} from './user-department.styles';
import RenderComponent from '../../../../components/render-component/render-component.component';

/* styles */
const useStyles = makeStyles((theme) => ({
    departmentIcon: {
        width: 80,
        height: 80,
        transform: 'translateY(-30px)',
    },
    image: {
        width: '65%',
        margin: 'auto',
    },
    backgroundImage: {
        backgroundColor: '#000',
        borderRadius: '50%',
        margin: 'auto',
        width: '100%',
        height: '100%',
        display: 'flex',
        marginTop: '20px',
    },
}));

export default function UserDepartment({ selectedUser }) {
    const classes = useStyles();
    const { auth } = useContext(authContext);
    const [loadingData, setLoadingData] = useState(true);
    const [userAlignments, setUserAlignments] = useState([]);
    const [department, setDepartment] = useState('');
    const [userAlignmentsSameDepartment, setUserAlignmentsSameDepartment] =
        useState([]);

    const alignmentBarColor = (givenGrade, receivedGrade) => {
        const differenceBetweenGrade = givenGrade - receivedGrade;

        if (givenGrade === null || receivedGrade === null) {
            return '#3E3939';
        }

        if (Math.abs(differenceBetweenGrade) === 0) {
            return '#00FF00';
        } else if (Math.abs(differenceBetweenGrade) === 1) {
            return '#FFFF00';
        } else if (Math.abs(differenceBetweenGrade) >= 2) {
            return '#FF0000';
        }
    };

    const BlinkingWarningIcon = (props) => {
        const [isVisible, setIsVisible] = useState(true);
        const differenceBetweenGrade = props.givenGrade - props.receivedGrade;

        useEffect(() => {
            const interval = setInterval(() => {
                setIsVisible((prevVisible) => !prevVisible);
            }, 1200);

            return () => {
                clearInterval(interval);
            };
        }, []);

        const iconStyle = {
            color: isVisible ? 'red' : 'transparent',
            transition: 'color 0.3s ease-in-out',
            marginLeft: '10px',
            transform: 'translateY(4px)',
        };

        if (Math.abs(differenceBetweenGrade) === 0 && props.givenGrade < 0) {
            return (
                <Tooltip title="Alinhados, porém com nota negativa" arrow>
                    <WarningIcon style={iconStyle} />
                </Tooltip>
            );
        } else if (
            Math.abs(differenceBetweenGrade) === 1 &&
            props.givenGrade < 0
        ) {
            return (
                <Tooltip
                    title="Parcialmente alinhados, porém com nota negativa"
                    arrow
                >
                    <WarningIcon style={iconStyle} />
                </Tooltip>
            );
        } else {
            return null;
        }
    };

    useEffect(() => {
        setLoadingData(true);
        const callApiFindUserAlignmentsSameDepartment = async () => {
            const response = await findUserAlignments(
                auth,
                auth.data.user.companyId,
                selectedUser.userId,
                1
            );

            const { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);
            setUserAlignmentsSameDepartment(payload);
        };

        const callApiFindUserAlignments = async () => {
            const response = await findUserAlignments(
                auth,
                auth.data.user.companyId,
                selectedUser.userId
            );

            const { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);
            setUserAlignments(payload);
        };

        const callApiGetDepartment = async () => {
            const response = await getOneDepartment(
                auth,
                selectedUser.allocation.departmentId,
                auth.data.user.companyId
            );

            const { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);
            setDepartment(payload[0]);
        };

        Promise.all([
            callApiFindUserAlignmentsSameDepartment(),
            callApiFindUserAlignments(),
            callApiGetDepartment()
        ])
        .then(() => {
            setLoadingData(false);
        })
        .catch((error) => {
            console.log(error);
            setLoadingData(false);
        });
    }, [auth, selectedUser]);

    return (
        <>
            {loadingData ? (
                <RenderComponent />
            ) : (
                <Department>
                    <TitleInfo>
                        <TitleInfoDepartment>Setor</TitleInfoDepartment>

                        {department.imageUrl ? (
                            <DepartmentIcon
                                style={{
                                    border: '1px solid #ff7517',
                                }}
                                src={department.imageUrl}
                            /> 
                        ) : (
                            null                      
                        )}
                        <DepartmentName>
                            {selectedUser.allocation.departmentName}{' '}
                        </DepartmentName>
                    </TitleInfo>
                    <Alignment>
                        <UserAlignments>
                            {userAlignmentsSameDepartment.map((alignment) => (
                                <>
                                    <ContainerTextBar>
                                        <Bar
                                            style={{
                                                backgroundColor:
                                                    alignmentBarColor(
                                                        alignment.givenGrade,
                                                        alignment.receivedGrade
                                                    ),
                                            }}
                                        />
                                        <TextBar>
                                            <Name>
                                                {alignment.shortenedName}
                                            </Name>
                                            <FortressName>
                                                {alignment.fortress}
                                                <BlinkingWarningIcon
                                                    givenGrade={
                                                        alignment.givenGrade
                                                    }
                                                    receivedGrade={
                                                        alignment.receivedGrade
                                                    }
                                                />
                                            </FortressName>
                                        </TextBar>
                                    </ContainerTextBar>
                                </>
                            ))}
                        </UserAlignments>
                        <OtherDepartments>
                            <OtherDepartmentTitle>
                                Outros Setores:
                            </OtherDepartmentTitle>
                            <DepartmentAlignment>
                                {userAlignments.map((alignment) => (
                                    <>
                                        <ContainerTextBar>
                                            <Bar
                                                style={{
                                                    backgroundColor:
                                                        alignmentBarColor(
                                                            alignment.givenGrade,
                                                            alignment.receivedGrade
                                                        ),
                                                }}
                                            />
                                            <TextBar>
                                                <Name>
                                                    {alignment.shortenedName}
                                                </Name>
                                                <FortressName>
                                                    {alignment.fortress}
                                                    <BlinkingWarningIcon
                                                        givenGrade={
                                                            alignment.givenGrade
                                                        }
                                                        receivedGrade={
                                                            alignment.receivedGrade
                                                        }
                                                    />
                                                </FortressName>
                                            </TextBar>
                                        </ContainerTextBar>
                                    </>
                                ))}
                            </DepartmentAlignment>
                        </OtherDepartments>
                    </Alignment>
                </Department>
            )}
        </>
    );
}
