import styled from 'styled-components/macro';

export const IntroContainer = styled.div`
    display: grid;
    grid-template-columns: 80% 20%;
    background: #f6f4f4;
    border-radius: 12px;
    padding: 12px;
    margin: 20px;
    height: 300px;
    @media screen and (max-width: 610px) {
        grid-template-columns: 100%;
        height: 90%;
    }
   
`;

export const TextContainer = styled.div`
    display: grid;
    grid-template-rows: 20% 40% 40%;
    padding: 12px;
    @media screen and (max-width: 610px) {
        grid-template-rows: 20% 60% 20%;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
   
`

export const TitleText = styled.div`
    font-family: Abril Fatface;
    font-size: 46px;
    font-weight: 700; 
`;

export const TitleText2 = styled.div`
    font-family: Abril Fatface;
    font-size: 46px;
    font-weight: 700;
    margin-left:12px ;
    width: 50%;
`;

export const ImportantText = styled.div`
    font-family: 'Inter', sans-serif;
    font-size: 0.9vw;
    font-weight: 100;
    padding-right: 20px;
    padding-top: 6px;
    @media screen and (max-width: 1400px) {
        font-size: 16px;
        padding-right: 80px;
        padding-top: 16px;
    }
    @media screen and (max-width: 1020px) {
        font-size: 16px;
        padding-right: 0px;
        padding-top: 4px;
    }
    @media screen and (max-width: 830px) {
        font-size: 0.9rem;
        padding-right: 0px;
        padding-top: 4px;
    }
    @media screen and (max-width: 610px) {
        font-size: 1rem;
        padding-right: 10px;
        padding-top: 4px;
    }
`;

export const ButtonUserIntro = styled.div`
    display: grid;
    align-self: end;
    margin-top: 10px;
    @media screen and (max-width: 440px) {
        margin-top: 20px;
    }
`;

export const CharacterImage = styled.img`
    position: 'absolute';
    object-fit: 'cover';
    width: 120;
    z-index: 999;
    transform: 'translateY(-25px)';
    @media screen and (max-width: 610px) {
        display: none;
    }
`;
