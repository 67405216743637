import { apiUrl, handleResponse } from './Api';

let jwt = require('jsonwebtoken');

export function login(email, password) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
    };

    return fetch(`${apiUrl}/auth/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return {
                token: user.payload.token,
                data: jwt.decode(user.payload.token)
            }
        }).catch(error => {
            return {
                error: true,
                message: error
            }
        });
}

export function getAllUsersInCompany(auth, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany ? globalCompany : auth.data.user.companyId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(`${apiUrl}/companies/${company}/users`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}

export function getAllActiveUsersInCompany(auth, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany ? globalCompany : auth.data.user.companyId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(`${apiUrl}/companies/${company}/users/findAllActiveUsers`, requestOptions)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getAllOlderUsersInCompany(auth, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany ? globalCompany : auth.data.user.companyId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/findAllOlderUsers`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getAllUsersInDepartment(auth, department, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany ? globalCompany : auth.data.user.companyId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/departments/${department}/users`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getAllActiveUsersInDepartment(auth, department, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany ? globalCompany : auth.data.user.companyId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/departments/${department}/users/findAllActiveUsers`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getAllOlderUsersInDepartment(auth, department, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany ? globalCompany : auth.data.user.companyId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/departments/${department}/users/findAllOlderUsers`,
        requestOptions
    )
        .then((response) => {
            return response;
        }).catch(error => {
            console.log(error)
        });
}

//users in department by user ID
export function getUsersInDepartment(auth, userId, department, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany ? globalCompany : auth.data.user.companyId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/departments/${department}/users${userId}/getUsersFromDepartment`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getOneUser(auth, user, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany ? globalCompany : auth.data.user.companyId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(`${apiUrl}/companies/${company}/users/${user}`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}

export function addUser(data, auth, department, company) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },

        body: JSON.stringify({
            name: data.name,
            gender: data.gender,
            birthDate: data.birthDate,
            email: data.email,
            password: data.password,
            permissionRoleId: parseInt(data.permissionRole),
            dream: data.dream,
            fortress: data.fortress,
            purpose: data.purpose,
            note: data.note,
            schooling: data.schooling,
            ethnicity: data.ethnicity,
            driverLicense: data.driverLicense,
            directManagerId: data.directManager,
            yearGraduated: data.yearGraduated,
            secondSchooling: data.secondSchooling,
            yearGraduatedSecond: data.yearGraduatedSecond,
            allocation: {
                role: data.allocation.role,
                startDate: data.allocation.startDate,
                actingCity: data.allocation.actingCity
            },
            terminationDate: data.terminationDate,
            terminationReason: data.terminationReason,
            terminationCategory: data.terminationCategory,
            userStatus: data.userStatus.id,
            lifeSummary: data.lifeSummary,
        })
    };

    return fetch(
        `${apiUrl}/companies/${company}/departments/${department}/users`,
        requestOptions
    )
        .then((response) => {
            return response;
        }).catch(error => {
            console.log(error)
        })
}

export function editUser(data, auth, user, company) {
    const authorization = `Bearer ${auth.data.token}`;
    let jsonData;

    if(data.password === '') {
        jsonData = JSON.stringify({
            name:data?.name,
            gender: data?.gender,
            birthDate: data?.birthDate,
            email: data?.email,
            permissionRoleId: parseInt(data?.permissionRole),
            dream: data?.dream,
            fortress: data?.fortress,
            purpose: data?.purpose,
            note:data?.note,
            schooling: data?.schooling,
            ethnicity: data.ethnicity,
            driverLicense: data.driverLicense,
            directManagerId: data.directManager,
            yearGraduated: data.yearGraduated,
            secondSchooling: data.secondSchooling,
            yearGraduatedSecond: data.yearGraduatedSecond,
            allocation: {
                role: data.allocation.role,
                startDate: data.allocation.startDate,
                actingCity: data.allocation.actingCity
            },
            terminationDate: data.terminationDate,
            terminationReason: data.terminationReason,
            terminationCategory: data.terminationCategory,
            userStatus: data.userStatus?.id,
            lifeSummary: data.lifeSummary,
        })
    } else {
        jsonData = JSON.stringify({
            name: data?.name,
            gender: data?.gender,
            birthDate: data?.birthDate,
            email: data?.email,
            password: data?.password,
            permissionRoleId: parseInt(data?.permissionRole),
            dream: data?.dream,
            fortress: data?.fortress,
            purpose: data?.purpose,
            note:data?.note,
            schooling: data?.schooling,
            ethnicity: data.ethnicity,
            driverLicense: data.driverLicense,
            directManagerId: data.directManager,
            yearGraduated: data.yearGraduated,
            secondSchooling: data.secondSchooling,
            yearGraduatedSecond: data.yearGraduatedSecond,
            allocation: {
                active: data.allocation?.active,
                role: data.allocation?.role,
                startDate: data.allocation?.startDate,
                departmentId: data.allocation?.departmentId,
                actingCity: data.allocation?.actingCity
            },
            terminationDate: data.terminationDate,
            terminationReason: data.terminationReason,
            terminationCategory: data.terminationCategory,
            userStatus: data.userStatus?.id,
            lifeSummary: data.lifeSummary
        })
    }

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
        body: jsonData
    };

    return fetch(`${apiUrl}/companies/${company}/users/${user}`, requestOptions)
        .then(response => {
            return response;

        }).catch(error => {
            console.log(error)
        })
}

export function uploadImage(auth, file, userId, companyId) {
    const authorization = `Bearer ${auth.data.token}`;

    const formData = new FormData();

    formData.append('file', file);

    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: authorization,
        },
        body: formData
    };

    return fetch(`${apiUrl}/companies/${companyId}/users/${userId}/upload`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error);
        })
}

export function deleteUser(auth, user, company) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(`${apiUrl}/companies/${company}/users/${user}`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        })
}

export function freezeUser(auth, user, company) {
    const authorization = `Bearer ${auth.data.token}`;
    let jsonData;
    
    jsonData = JSON.stringify({
        allocation: {
            active: false,

        }
    })

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
        body: jsonData
    };
    
    return fetch(`${apiUrl}/companies/${company}/users/${user}`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        })
}

export function activeUser(auth, user, company) {
    const authorization = `Bearer ${auth.data.token}`;
    let jsonData;
    
    jsonData = JSON.stringify({
        allocation: {
            active: true,

        }
    })

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
        body: jsonData
    };
    
    return fetch(`${apiUrl}/companies/${company}/users/${user}`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        })
}

export function changePassword(data, auth) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = auth.data.user.companyId;
    const user = auth.data.user.userId;

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },

        body: JSON.stringify({
            password: data.password
        })
    };

    return fetch(`${apiUrl}/companies/${company}/users/${user}`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        })
}
export function forgotPassword(data) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },

        body: JSON.stringify({
            email: data.email,
        }),
    };

    return fetch(`${apiUrl}/auth/forgotPassword`, requestOptions)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function auth(token) {
    const requestOptions = {
        method: 'GET',
        headers: { 'x-access-token': token },
    };
    return fetch(String(`${apiUrl}/verify_auth`), requestOptions)
        .then(handleResponse)
        .then(result => {
            // search successful if there's a result in the response
            if (result) {
                // Do something useful with it
                return result;
            }
        });

}

export function signup(nome, email, senha, estado, cidade, data_nascimento) {
    const tipo = 'F';
    const login = email;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            nome,
            email,
            senha,
            estado,
            login,
            cidade,
            data_nascimento,
            tipo,
        }),
    };

    return fetch(`${apiUrl}/cadastro`, requestOptions)
        .then(handleResponse);
}

export function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    return 0;
}

export function getUserNotifications(auth, user, company) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/notifications`,
        requestOptions
    )
        .then((response) => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}

export function markNotificationsAsRead(auth, user, company, data) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
        body: JSON.stringify({
            notifications: [...data]
        })

    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/notifications/read`,
        requestOptions
    )
        .then((response) => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}

export function getTaskScoresFromUser(auth,year,company,department,user) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/departments/${department}/users/${user}/getTaskScoresFromUser?startDate=${year}-01-01&endDate=${year+1}-01-01`,
        requestOptions
    )
        .then((response) => {
            return response;
        }).catch(error => {
            console.log(error)
        });
}

export function getAlignmentScoresFromUser(auth,year,company,department,user) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/departments/${department}/users/${user}/getAlignmentScoresFromUser?startDate=${year}-01-01&endDate=${year+1}-01-01`,
        requestOptions
    )
        .then((response) => {
            return response;
        }).catch(error => {
            console.log(error)
        });
}

export function getUserMonthAvg(auth,year,company,user) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/alignments/getUserMonthAvg?year=${year}`,
        requestOptions
    )
        .then((response) => {
            return response;
        }).catch(error => {
            console.log(error)
        });
}