import styled from 'styled-components';

export const AddUserContainer = styled.div`
    background-color: white;
    border: 1px solid #cfd8dc;
    margin-top: 5vw;
    padding-top: 2vw;
    padding-bottom: 2vw;
    padding-right: 4vw;
    padding-left: 4vw;
    @media screen and (max-width: 600px) , screen and (max-height: 660px) {
        width: 100%;
        background-color: #fff;
        margin-bottom: 20px;
    }
    @media screen and (max-height: 1000px) {
        height: 68%;
    }
    @media screen and (max-height: 800px) {
        width: 100%;
        height: 85%;
    }
    @media screen and (max-width: 650px) {
        padding: 0;
    }
`;

export const ButtonContainer = styled.div`
    display: grid;
    grid-area: button;
`;

export const TableContainer = styled.div`
    height: 70%;
    background-color: white;
    border: 1px solid #cfd8dc;
    padding: 70px;
    @media screen and (max-height: 950px) {
        height: 75%;
    }
   @media screen and (max-height: 830px) {
        height: 85%;
        margin-bottom: 20px;
        padding: 0;
    }
    margin-bottom: 80px;
   @media screen and (max-width: 650px) {
        margin-bottom: 20px;
    }
`;

export const Spacer = styled.div`
    height: 10vh;
    @media screen and (max-height: 600px) {
        height: 70px;
    }
`;

export const DrawerAdjustment = styled.div`
    padding-left: 72px;
    @media screen and (max-width: 800px) {
        padding-left: 0;
    }
`;

export const StyledContainerTitle = styled.div`
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 780px) {
        width: 0;
        height: 0;
        visibility: hidden; 
    }
`;

export const ContainerTitle = styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 780px) {
        width: 0;
        height: 0;
        visibility: hidden; 
    }
`;
export const Title = styled.h2`
    margin-bottom: 8px;
    @media screen and (max-width: 780px) {
        width: 0;
        height: 0;
        visibility: hidden; 
    }
`;

export const Stripe = styled.div`
    margin-top: 13px;
    margin-right: 13px;
    border-left:5px solid #4f4f4f;
    height: 65px;
    @media screen and (max-width: 780px){
        width: 0;
        height: 0;
        visibility: hidden;  
    }
`;
