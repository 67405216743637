import React, { useState } from 'react';
import {
    HorizontalGridLines,
    LineSeries,
    XAxis,
    YAxis,
    LineMarkSeries,
    Hint,
    DiscreteColorLegend,
    FlexibleWidthXYPlot,
} from 'react-vis';

/* Material UI */
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';

const Graph = (props) => {
    const [showBlueItens, setShowBlueItens] = useState(true);
    const [showRedItens, setShowRedItens] = useState(false);

    const [showValues, setShowValues] = useState(true);
    const [hoverData, setHoverData] = useState(null);

    const translateMonths = (state, field) => {
        const translatedMonths = [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro',
        ];

        return state?.map((curr, index) => {
            curr[field] = translatedMonths[index];
            return { ...curr };
        });
    };

    const data1Alignment = translateMonths(props?.data1Alignment, 'x');
    const data2Alignment = translateMonths(props?.data2Alignment, 'x');
    const data1Task = translateMonths(props?.data1Task, 'x');
    const data2Task = translateMonths(props?.data2Task, 'x');

    const clearedGraphData = [
        { x: 'Janeiro', y: null },
        { x: 'Fevereiro', y: null },
        { x: 'Março', y: null },
        { x: 'Abril', y: null },
        { x: 'Maio', y: null },
        { x: 'Junho', y: null },
        { x: 'Julho', y: null },
        { x: 'Agosto', y: null },
        { x: 'Setembro', y: null },
        { x: 'Outubro', y: null },
        { x: 'Novembro', y: null },
        { x: 'Dezembro', y: null },
    ];

    const clearLine = (
        <LineSeries
            getNull={(d) => d.y !== null}
            color="#FFFFFF"
            data={clearedGraphData}
        />
    );

    const handleSwithChange = () => {
        const opositeValue = !showValues;
        setShowValues(opositeValue);
    };

    const graphItem = (data, color, stroke) => {
        if (showValues) {
            return (
                <LineMarkSeries
                    onValueMouseOver={(d) => setHoverData(d)}
                    getNull={(d) => d.y !== null}
                    color={color}
                    strokeStyle={stroke}
                    data={data}
                />
            );
        } else {
            return (
                <LineSeries
                    getNull={(d) => d.y !== null}
                    color={color}
                    strokeStyle={stroke}
                    data={data}
                />
            );
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                {' '}
                {/* Swith */}
                <FormControlLabel
                    style={{ margin: 0 }}
                    labelPlacement="top"
                    label="Marcar Pontos"
                    control={
                        <Switch
                            checked={showValues}
                            onChange={handleSwithChange}
                            name="checkedB"
                            color="primary"
                        />
                    }
                />
            </div>

            <FlexibleWidthXYPlot
                height={400}
                id={'grafico'}
                xType={'ordinal'}
                yDomain={[-100, 100]}
                onMouseLeave={() => setHoverData(null)}
            >
                <HorizontalGridLines />
                <XAxis />
                <YAxis />

                {data1Task && showBlueItens
                    ? graphItem(data1Task, '#0000FF', 'solid')
                    : clearLine}

                {data1Alignment && showRedItens
                    ? graphItem(data1Alignment, '#FF0000', 'solid')
                    : clearLine}

                {data2Task && showBlueItens
                    ? graphItem(data2Task, '#0000FF', 'dashed')
                    : clearLine}

                {data2Alignment && showRedItens
                    ? graphItem(data2Alignment, '#FF0000', 'dashed')
                    : clearLine}

                {hoverData && <Hint value={hoverData} />}
            </FlexibleWidthXYPlot>

            <div style={{ display: 'flex' }}>
                {' '}
                {/* Legend */}
                {props.sendName1 !== '' ? (
                    <DiscreteColorLegend
                        items={[
                            {
                                title: props.sendName1,
                                color: '#000',
                                strokeWidth: 2,
                            },
                        ]}
                        orientation="horizontal"
                    />
                ) : null}
                {props.sendName2 !== '' ? (
                    <DiscreteColorLegend
                        items={[
                            {
                                title: props.sendName2,
                                color: '#000',
                                strokeWidth: 2,
                                strokeStyle: 'dashed',
                            },
                        ]}
                        orientation="horizontal"
                    />
                ) : null}
            </div>

            {!props.notShowLegendOption ? (
                <div style={{ display: 'flex' }}>
                    {' '}
                    {/* Checks */}
                    <FormControlLabel
                        key={'checkCtr1'}
                        control={
                            <Checkbox
                                key={'check1'}
                                style={{ color: '#00F' }}
                                checked={showBlueItens}
                                onChange={(e) => {
                                    setShowBlueItens(!showBlueItens);
                                }}
                            />
                        }
                        label={'Média Tarefas'}
                    />
                    <FormControlLabel
                        key={'checkCtr2'}
                        control={
                            <Checkbox
                                key={'check2'}
                                style={{ color: '#F00' }}
                                checked={showRedItens}
                                onChange={(e) => {
                                    setShowRedItens(!showRedItens);
                                }}
                            />
                        }
                        label={'Média Alinhamentos'}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default Graph;
