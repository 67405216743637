import React, { useContext, useEffect, useState } from 'react';
import { isUndefined } from 'svgsaver/src/utils';

/* Excel */
import ReactExport from 'react-data-export';

/* Styles */
import {
    StyledInput,
    Filters,
    StyledContainerButtonXlsx,
    ButtonStyles,
} from './manage-records-ipa.styles';

/* Components */
import EnhancedTable from '../../../../components/table-to-show-data/table-to-show-data.component';
import SwitchTwoLabels from '../../../../components/switch/switch.component';
import RenderPage from '../../../../components/render-page/render-page.component';
import Help from '../../../../components/help/help.component';
import { helpMatchesAndEpisodesTable } from '../../../../components/help/help.texts';

/* Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

/* Context */
import { authContext } from '../../../../contexts/AuthContext';

/* Api*/
import { getCurrentUnfilledIPA, getUnfilledIPA } from '../../../../api/ApiCompany';
import {
    getActiveUnfilledAssingnmentUsers,
    getAllUsersMonthAvgTask,
} from '../../../../api/ApiTasks';
import {
    getActiveUnfilledAlignmentUsers,
    getAlignmentAllMatchesAndEpisodes,
    getAllUsersMonthAvg,
    findAlignmentYears,
} from '../../../../api/ApiAlignment';
import { getOneDepartment } from '../../../../api/ApiDepartment';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

/*Excel*/
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const optionsMonths = [
    { name: 'Janeiro', monthNumber: '01' },
    { name: 'Fevereiro', monthNumber: '02' },
    { name: 'Março', monthNumber: '03' },
    { name: 'Abril', monthNumber: '04' },
    { name: 'Maio', monthNumber: '05' },
    { name: 'Junho', monthNumber: '06' },
    { name: 'Julho', monthNumber: '07' },
    { name: 'Agosto', monthNumber: '08' },
    { name: 'Setembro', monthNumber: '09' },
    { name: 'Outubro', monthNumber: '10' },
    { name: 'Novembro', monthNumber: '11' },
    { name: 'Dezembro', monthNumber: '12' },
];

export default function UsersRecordsIPA(props) {
    const classes = useStyles();

    const { auth } = useContext(authContext);

    const [title, setTitle] = React.useState({});
    const [titleMatchEpisode, setTitleMatchEpisode] = React.useState({});

    const [rows, setRows] = React.useState([]);
    const [rowsMatchEpisode, setRowsMatchEpisode] = React.useState([]);
    const [rowsMatchEpisodeActualMonth, setRowsMatchEpisodeActualMonth] =
        React.useState([]);
    const [rowsMatchEpisodeFutureMonth, setRowsMatchEpisodeFutureMonth] =
        React.useState([]);
    const [data, setData] = React.useState([]);
    const [dataMatchEpisode, setDataMatchEpisode] = React.useState([]);
    const [dataMatchEpisodeActualMonth, setDataMatchEpisodeActualMonth] =
        React.useState([]);
    const [dataMatchEpisodeFutureMonth, setDataMatchEpisodeFutureMonth] =
        React.useState([]);
    const [spreadsheetCells, setSpreadsheetCells] = React.useState([]);

    const [searchText, setSearchText] = useState('');
    const [searchTextMatchEpisode, setSearchTextMatchEpisode] = useState('');

    const [isTasksShowed, setIsTasksShowed] = useState(false);

    const [fetchOpen, setFetchOpen] = useState(true);

    let actualYear = new Date().toISOString();
    actualYear = actualYear.split('-');
    let actualMonth = parseInt(actualYear[1]);

    const [month, setMonth] = useState(optionsMonths[actualMonth - 1]);
    const [userMonthAverage, setUserMonthAverage] = useState(null);
    const [userAverageTask, setUserAverageTask] = useState([]);
    
    const [optionsYears, setOptionsYears] = useState([]);
    const [yearSelected, setYearSelected] = useState(actualYear[0]);

    const headCells = [
        {
            id: 'userId',
            numeric: false,
            disablePadding: false,
            label: 'Código',
        },
        {
            id: 'userName',
            numeric: false,
            disablePadding: false,
            label: 'Nome',
        },
        {
            id: 'departmentName',
            numeric: false,
            disablePadding: false,
            label: 'Setor',
        },
        {
            id: 'startedAt',
            numeric: false,
            disablePadding: false,
            label: 'Data de Admissão',
        }
    ];

    const headCellsMatchEpisode = [
        {
            id: 'alignedUserId',
            numeric: false,
            disablePadding: false,
            label: 'Código',
        },
        {
            id: 'alignedUserName',
            numeric: false,
            disablePadding: false,
            label: 'Nome',
        },
        {
            id: 'episodes',
            numeric: false,
            disablePadding: false,
            label: 'Episódios',
        },
        {
            id: 'matches',
            numeric: false,
            disablePadding: false,
            label: 'Matches',
        },
        {
            id: 'percentege',
            numeric: false,
            disablePadding: false,
            label: 'Porcentagem',
        },
    ];

    const headCellsMatchEpisodeActualMonth = [
        {
            id: 'alignedUserId',
            numeric: false,
            disablePadding: false,
            label: 'Código',
        },
        {
            id: 'alignedUserName',
            numeric: false,
            disablePadding: false,
            label: 'Nome',
        },
        {
            id: 'episodes',
            numeric: false,
            disablePadding: false,
            label: 'Episódios',
        },
        {
            id: 'matches',
            numeric: false,
            disablePadding: false,
            label: 'Matches',
        },
        {
            id: 'alignments',
            numeric: false,
            disablePadding: false,
            label: 'Alinhamentos Ativos',
        },
        {
            id: 'percentege',
            numeric: false,
            disablePadding: false,
            label: 'Porcentagem',
        },
    ];

    const headCellsMatchEpisodeFutureMonth = [
        {
            id: 'alignedUserId',
            numeric: false,
            disablePadding: false,
            label: 'Código',
        },
        {
            id: 'alignedUserName',
            numeric: false,
            disablePadding: false,
            label: 'Nome',
        },
        {
            id: 'episodes',
            numeric: false,
            disablePadding: false,
            label: 'Episódios',
        },
        {
            id: 'matches',
            numeric: false,
            disablePadding: false,
            label: 'Matches',
        },
        {
            id: 'percentege',
            numeric: false,
            disablePadding: false,
            label: 'Porcentagem',
        },
    ];

    // handle change event of search input
    const handleChange = (value) => {
        setSearchText(value);
        filterData(value);
    };

    // handle change event of search input
    const handleChangeMatchEpisode = (value) => {
        setSearchTextMatchEpisode(value);
        filterDataMatchEpisode(value);
        filterDataMatchEpisodeActualMonth(value);
    };

    // exclude column list from filter
    let excludeColumns = [];

    const filterDataMatchEpisodeActualMonth = (value) => {
        let aux = '';
        const lowercasedValue = value.toLowerCase().trim();

        if (lowercasedValue === '')
            setRowsMatchEpisodeActualMonth(dataMatchEpisodeActualMonth);
        else {
            const filteredData = dataMatchEpisodeActualMonth.filter((item) => {
                return Object.keys(item).some((key) =>
                    excludeColumns.includes(key)
                        ? false
                        : item[key]
                        ? item[key]
                              .toString()
                              .toLowerCase()
                              .includes(lowercasedValue)
                        : aux.toString().toLowerCase().includes(lowercasedValue)
                );
            });
            setRowsMatchEpisodeActualMonth(filteredData);
        }
    };

    const filterDataMatchEpisode = (value) => {
        let aux = '';
        const lowercasedValue = value.toLowerCase().trim();

        if (lowercasedValue === '') setRowsMatchEpisode(dataMatchEpisode);
        else {
            const filteredData = dataMatchEpisode.filter((item) => {
                return Object.keys(item).some((key) =>
                    excludeColumns.includes(key)
                        ? false
                        : item[key]
                        ? item[key]
                              .toString()
                              .toLowerCase()
                              .includes(lowercasedValue)
                        : aux.toString().toLowerCase().includes(lowercasedValue)
                );
            });
            setRowsMatchEpisode(filteredData);
        }
    };

    const filterData = (value) => {
        let aux = '';
        const lowercasedValue = value.toLowerCase().trim();

        if (lowercasedValue === '') setRows(data);
        else {
            const filteredData = data.filter((item) => {
                return Object.keys(item).some((key) =>
                    excludeColumns.includes(key)
                        ? false
                        : item[key]
                        ? item[key]
                              .toString()
                              .toLowerCase()
                              .includes(lowercasedValue)
                        : aux.toString().toLowerCase().includes(lowercasedValue)
                );
            });
            setRows(filteredData);
        }
    };

    useEffect(() => {
        const callFindAlignmentYears = async () => {
            const response = await findAlignmentYears(
                auth,
                props.globalCompany
            );
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            setOptionsYears(body.payload.map((curr) => String(curr.year)))
        };

        try {
            callFindAlignmentYears();
        } catch (error) {
            console.log(error)
        }
    },[auth, props.globalCompany])

    useEffect(() => {
        let actualGlobalCompany;

        if (props.theGlobalCompanyHasChange) {
            setFetchOpen(true);
        }

        if (!isUndefined(props.globalCompany) && props.globalCompany !== null) {
            if (props.globalCompany.hasOwnProperty('companyId'))
                actualGlobalCompany = props.globalCompany.companyId;
            else actualGlobalCompany = auth.data.user.companyId;
        } else {
            actualGlobalCompany = auth.data.user.companyId;
        }

        const callGetUnfilledIPA = async (globalCompany) => {
            let currentDate = new Date();
            let response;

            if(currentDate.getFullYear() === (+yearSelected) && (currentDate.getMonth()+1) === (+month.monthNumber)) {
                response = await getCurrentUnfilledIPA(
                    auth,
                    globalCompany
                );
            } else {
                let date = {
                    year: yearSelected,
                    month: month.monthNumber,
                };

                response = await getUnfilledIPA(
                    auth,
                    date,
                    globalCompany
                );
            }

            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body.payload;
        };

        const callGetMonthAvgTasks = async (globalCompany) => {
            let date = new Date().toISOString();

            date = {
                year: yearSelected,
                month: month.monthNumber,
            };
            const response = await getAllUsersMonthAvgTask(
                auth,
                date,
                globalCompany
            );
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            setUserAverageTask(body.payload);
        };

        const callGetAllUsersMonthAvg = async (globalCompany) => {
            let findUnfilledTasksUsers;
            let date = new Date().toISOString();

            date = {
                year: yearSelected,
                month: month.monthNumber,
            };

            findUnfilledTasksUsers = await getAllUsersMonthAvg(
                auth,
                date,
                globalCompany
            );

            const response = findUnfilledTasksUsers;
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            setUserMonthAverage(body.payload);
        };

        callGetUnfilledIPA(actualGlobalCompany)
            .then((res) => {
                props.resetChangeActualGlobalCompany(false);
                setSpreadsheetCells(res);
            })
            .catch((err) => console.log(err));

        callGetAllUsersMonthAvg(actualGlobalCompany);
        callGetMonthAvgTasks(actualGlobalCompany);
    }, [month, props.globalCompany, yearSelected]);

    useEffect(() => {
        let actualGlobalCompany;

        if (props.theGlobalCompanyHasChange) {
            setFetchOpen(true);
        }

        if (!isUndefined(props.globalCompany) && props.globalCompany !== null) {
            if (props.globalCompany.hasOwnProperty('companyId'))
                actualGlobalCompany = props.globalCompany.companyId;
            else actualGlobalCompany = auth.data.user.companyId;
        } else {
            actualGlobalCompany = auth.data.user.companyId;
        }

        const callGetOneDepartment = async (departmentId, globalCompany) => {
            const response = await getOneDepartment(
                auth,
                departmentId,
                globalCompany
            );
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body.payload;
        };

        const callGetActiveUnfilledUsers = async (globalCompany) => {
            let date = new Date().toISOString();
            let tasksOrAlignments = isTasksShowed
                ? 'as tarefas'
                : 'os alinhamentos';

            setTitle({
                firstText: `Usuários que não enviaram ${tasksOrAlignments} do IPA em `,
                warningText: `${month.name}`,
            });

            date = {
                year: yearSelected,
                month: month.monthNumber,
            };

            let tasksOrAlignmentResponse;
            if (!isTasksShowed)
                tasksOrAlignmentResponse =
                    await getActiveUnfilledAlignmentUsers(
                        auth,
                        date,
                        globalCompany
                    );
            else
                tasksOrAlignmentResponse =
                    await getActiveUnfilledAssingnmentUsers(
                        auth,
                        date,
                        globalCompany
                    );

            const response = tasksOrAlignmentResponse;
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);
            return body.payload;
        };

        callGetActiveUnfilledUsers(actualGlobalCompany)
            .then((res) => {
                console.log(res);
                if (auth.data.user.permissionRoleId === 10) {
                    callGetOneDepartment(
                        auth.data.user.departmentId,
                        auth.data.user.companyId
                    ).then((resDept) => {
                        const resManager = res.filter((resItem) => {
                            if (resItem.sector === resDept[0].name) {
                                return resItem;
                            }
                        });
                        setRows(resManager);
                        setData(resManager);
                    });
                } else {
                    setRows(res);
                    setData(res);
                }
            }).catch((err) => console.log(err));
    }, [props.globalCompany, isTasksShowed, month, yearSelected])

    useEffect(() => {
        let actualGlobalCompany;

        if (!isUndefined(props.globalCompany) && props.globalCompany !== null) {
            if (props.globalCompany.hasOwnProperty('companyId'))
                actualGlobalCompany = props.globalCompany.companyId;
            else actualGlobalCompany = auth.data.user.companyId;
        } else {
            actualGlobalCompany = auth.data.user.companyId;
        }

        const callGetAlignmentAllMatchesAndEpisodes = async (globalCompany) => {
            const response = await getAlignmentAllMatchesAndEpisodes(
                auth,
                globalCompany,
                yearSelected,
                month.monthNumber
            );
            const body = await response.json();

            setTitleMatchEpisode({
                firstText: `Relatório de episodios e  matches no mês de `,
                warningText: `${month.name}`,
            });

            if (response.status !== 200) throw Error(body.message);

            return body.payload;
        };

        callGetAlignmentAllMatchesAndEpisodes(actualGlobalCompany)
            .then((res) => {
                setFetchOpen(false);
                props.resetChangeActualGlobalCompany(false);
                const resFirstObj = Object.keys(res);
                const resYear = !!resFirstObj
                    ? Object.keys(res[resFirstObj])
                    : actualYear;
                const resMonth = !!resYear
                    ? Object.keys(res[resFirstObj][resYear])
                    : actualMonth;
                const futureMonthHasNoData = 0;

                const resOrdened = [];
                const resOrdenedActualMonth = [];
                const resOrdenedFutureMonth = [];

                if (
                    auth.data.user.permissionRoleId === 10 &&
                    Object.keys(res).length !== 0
                ) {
                    res[resFirstObj][resYear][resMonth] = res[resFirstObj][
                        resYear
                    ][resMonth].filter(
                        (val) =>
                            val.departmentId === auth.data.user.departmentId
                    );
                }

                res[resFirstObj][resYear][resMonth].forEach((resObj) => {
                    resOrdened.push({
                        alignedUserId: resObj.alignedUserId,
                        alignedUserName: resObj.alignedUserName,
                        episodes: resObj.episodes,
                        matches: resObj.matches,
                        percentege:
                            parseInt(resObj.episodes) === 0
                                ? 0
                                : (resObj.matches / resObj.episodes) * 100,
                    });
                    resOrdenedActualMonth.push({
                        alignedUserId: resObj.alignedUserId,
                        alignedUserName: resObj.alignedUserName,
                        episodes: resObj.episodes,
                        matches: resObj.matches,
                        alignments: resObj.activeAlignments,
                        percentege:
                            parseInt(resObj.episodes) === 0
                                ? 0
                                : (resObj.matches / resObj.activeAlignments) *
                                  100,
                    });
                    resOrdenedFutureMonth.push({
                        alignedUserId: resObj.alignedUserId,
                        alignedUserName: resObj.alignedUserName,
                        episodes: futureMonthHasNoData,
                        matches: futureMonthHasNoData,
                        percentege: futureMonthHasNoData,
                    });
                });

                setRowsMatchEpisode(resOrdened);
                setDataMatchEpisode(resOrdened);

                setRowsMatchEpisodeActualMonth(resOrdenedActualMonth);
                setDataMatchEpisodeActualMonth(resOrdenedActualMonth);

                setRowsMatchEpisodeFutureMonth(resOrdenedFutureMonth);
                setDataMatchEpisodeFutureMonth(resOrdenedFutureMonth);
            })
            .catch((err) => console.log(err));
    }, [month, props.globalCompany, yearSelected]);

    const defaultPropsMonths = {
        options: optionsMonths,
        defaultValue: optionsMonths[actualMonth - 1],
        getOptionLabel: (option) => {
            return option.name;
        },
        getOptionSelected: (option) => {
            return option.name;
        },
        onChange: (event, newValue) => {
            setFetchOpen(true);
            if (newValue) setMonth(newValue);
            else {
                const getActualDate = new Date();
                const getActualMonthNumber =
                    getActualDate.getMonth() + 1 < 9
                        ? '0' + (getActualDate.getMonth() + 1).toString()
                        : (getActualDate.getMonth() + 1).toString();
                const actualMonthFormated = {
                    monthNumber: getActualMonthNumber,
                    name: optionsMonths.find(
                        (elm) => elm.monthNumber === getActualMonthNumber
                    ).name,
                };
                setMonth(actualMonthFormated);
            }
        },
    };

    const defaultPropsYears = {
        options: optionsYears,
        value: yearSelected,
        getOptionLabel: (option) => {
            return option || '';
        },
        getOptionSelected: (option) => {
            return option || '';
        },
        onChange: (event, newValue) => {
            setYearSelected(newValue);
        },
    };

    const [help, setHelp] = useState(false);
    const onChangeHelp = () => {
        setHelp(!help);
    };

    return (
        <div>
            {auth.data.user.permissionRoleId === 1 ? (
                <StyledContainerButtonXlsx>
                    <ExcelFile
                        filename={"Preenchimento IPA - " + month.name + " de " +yearSelected}
                        element={
                            <ButtonStyles pdfButton>
                                Gerar Relatório IPA
                            </ButtonStyles>
                        }
                    >
                        <ExcelSheet
                            data={spreadsheetCells}
                            name="Usuários que não enviaram as tarefas do IPA"
                        >
                            <ExcelColumn value="userId" label="Código" />
                            <ExcelColumn value="name" label="Nome" />
                            <ExcelColumn value="sector" label="Setor" />
                            <ExcelColumn value="startedAt" label="Data de Admissão" />
                            <ExcelColumn value="not_answered" label="Não Respondido" />
                        </ExcelSheet>
                    </ExcelFile>
                    <ExcelFile
                        filename={"Média de alinhamentos - " + month.name + " de " + yearSelected}
                        element={
                            <ButtonStyles pdfButton>
                                Gerar média de alinhamentos
                            </ButtonStyles>
                        }
                    >
                        <ExcelSheet
                            data={userMonthAverage}
                            name="Média mensal com alinhamentos"
                        >
                            <ExcelColumn value="name" label="Nome" />
                            <ExcelColumn value="average" label="Média" />
                            <ExcelColumn value="sector" label="Setor" />
                        </ExcelSheet>
                    </ExcelFile>
                    <ExcelFile
                        filename={"Média de tarefas - " + month.name + " de " + yearSelected}
                        element={
                            <ButtonStyles pdfButton>
                                Gerar média de tarefas
                            </ButtonStyles>
                        }
                    >
                        <ExcelSheet
                            data={userAverageTask}
                            name="Média mensal com tarefas"
                        >
                            <ExcelColumn value="name" label="Nome" />
                            <ExcelColumn value="average" label="Média" />
                            <ExcelColumn value="sector" label="Setor" />
                        </ExcelSheet>
                    </ExcelFile>
                </StyledContainerButtonXlsx>
            ) : null}
            <Filters>
                <StyledInput
                    type="text"
                    placeholder="Digite para pesquisar..."
                    value={searchText}
                    onChange={(e) => handleChange(e.target.value)}
                />
                <Autocomplete
                    {...defaultPropsYears}
                    id="departments"
                    disableClearable
                    autoSelect
                    className={classes.formControl}
                    style={{ width: 150 }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Anos"
                            variant="outlined"
                        />
                    )}
                />
                <Autocomplete
                    {...defaultPropsMonths}
                    id="departments"
                    disableClearable
                    autoSelect
                    className={classes.formControl}
                    style={{ width: 150 }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Meses"
                            variant="outlined"
                        />
                    )}
                />
            </Filters>

            <SwitchTwoLabels
                checkAction={setIsTasksShowed}
                leftWord="Alinhamentos"
                rightWord="Tarefas"
            />

            <EnhancedTable rows={rows} headCells={headCells} title={title} />

            <Filters>
                <StyledInput
                    type="text"
                    placeholder="Digite para pesquisar..."
                    value={searchTextMatchEpisode}
                    onChange={(e) => handleChangeMatchEpisode(e.target.value)}
                />
            </Filters>
            <Help
                onClick={onChangeHelp}
                helpPopUp={helpMatchesAndEpisodesTable}
                help={help}
                style={{ padding: 200 }}
            />
            <EnhancedTable
                rows={
                    actualMonth === parseInt(month.monthNumber)
                        ? rowsMatchEpisodeActualMonth
                        : actualMonth < parseInt(month.monthNumber)
                        ? rowsMatchEpisodeFutureMonth
                        : rowsMatchEpisode
                }
                headCells={
                    actualMonth === parseInt(month.monthNumber)
                        ? headCellsMatchEpisodeActualMonth
                        : actualMonth < parseInt(month.monthNumber)
                        ? headCellsMatchEpisodeFutureMonth
                        : headCellsMatchEpisode
                }
                title={titleMatchEpisode}
            />

            {fetchOpen ? <RenderPage open={fetchOpen} /> : null}
        </div>
    );
}