import styled from 'styled-components';

export const Spacer = styled.div`
    height: 12vh;
    @media screen and (max-height: 600px) {
        height: 70px;
    }
`;

export const DrawerAdjustment = styled.div`
    padding-left: 72px;
    @media screen and (max-width: 800px) {
        padding-left: 0;
    }
`;

export const UserImage = styled.img`
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
`;

export const ContainerSettings = styled.div`
    display: grid;
    margin: 100px auto;
    border-radius: 12px;
    padding: 12px;
    width: 95%;
    grid-template-columns: 1fr 3fr;
    height: 85vh;
`;

export const LeftSettings = styled.div`
    display: grid;
    background: #f6f4f4;
    border-radius: 12px;
    grid-template-rows: 3fr 3fr 1fr;
    border: 1px solid #3e3939;
`;

export const Profile = styled.div`
    display: grid;
    grid-template-rows: 3fr 1fr 0.8fr;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #3e3939;
`;

export const ProfileInformation = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
`;

export const Department = styled.div`
    display: flex;
    font-family: Abril Fatface;
    font-size: 24px;
    padding: 20px;
    font-weight: 700;
`;

export const CompanyTime = styled.div`
    display: flex;
    font-family: Abril Fatface;
    font-size: 24px;
    padding: 20px;
    font-weight: 700;
`;

export const City = styled.div`
    display: flex;
    font-family: Abril Fatface;
    font-size: 24px;
    padding: 20px;
    font-weight: 700;
`;

export const Permission = styled.div`
    display: flex;
    font-family: Abril Fatface;
    font-size: 24px;
    padding: 20px;
    font-weight: 700;
`;

export const DrakkarAcademy = styled.div`
    display: grid;
    border-top: 1px solid #3e3939;
    align-items: center;
`;

export const RightSettings = styled.div`
    display: grid;
    background: #f6f4f4;
    border-radius: 12px;
    margin-left: 20px;
    grid-template-rows: 0.7fr 5fr 0.95fr;
    border: 1px solid #3e3939;
`;

export const SettingTitle = styled.div`
    display: flex;
    font-family: Abril Fatface;
    font-size: 30px;
    padding-left: 20px;
    align-items: center;
    font-weight: 700;
    border-bottom: 1px solid #3e3939;
`;

export const ProfileEdit = styled.div`
    display: grid;
    grid-template-rows: 1.5fr 1fr;
    padding: 20px;
    grid-template-areas:
        'topCont'
        'botCont';
`;

export const TopCont = styled.div`
    display: grid;
    grid-area: topCont;
    gap: 40px;
    grid-template-areas: 'leftCont rightCont';
`;

export const LeftCont = styled.div`
    display: grid;
    grid-area: leftCont;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
`;

export const RightCont = styled.div`
    display: grid;
    grid-area: rightCont;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
`;

export const BotCont = styled.div`
    display: grid;
    grid-area: botCont;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
`;

export const LabelTitle = styled.div`
    display: flex;
    font-family: Abril Fatface;
    font-size: 26px;
    align-items: center;
    font-weight: 700;
`;

export const LabelCont = styled.div`
    display: grid;
    grid-template-rows: 0.8fr 1fr;
`;

export const ProfileEditButton = styled.div`
    display: grid;
    border-top: 1px solid #3e3939;
    justify-content: start;
    align-items: center;
    padding-left: 32px;
`;

export const ButtonSetting = styled.div`
    display: grid;
    width: 180px;
    height: 60px;
    font-size: 26px;
    font-weight: 700;
    color: white;
    border-radius: 12px;
    background-color: #ff7517;
    align-items: center;
    justify-items: center;
    margin: auto;
    cursor: pointer;
`;

export const ButtonSetting2 = styled.div`
    display: grid;
    width: 180px;
    height: 60px;
    font-size: 26px;
    font-weight: 700;
    color: white;
    border-radius: 12px;
    background-color: #ff7517;
    align-items: center;
    justify-items: center;
    margin: auto;
    cursor: pointer;
`;

export const PhotoEdit = styled.div`
    display: grid;
    width: 180px;
    height: 180px;
    margin: auto;
    background-color: #3e393950;
    border-radius: 50%;
`;

export const UserName = styled.div`
    display: grid;
    font-size: 24px;
    text-align: center;
    font-weight: 700;
`;

export const UserFunction = styled.div`
    display: grid;
    font-size: 18px;
    text-align: center;
    font-weight: 700;
`;
