import React, {useContext, useEffect, useState} from "react";
import { isUndefined } from 'svgsaver/src/utils';

/* Components */
import EnhancedTable from "../../../components/table-to-show-data/table-to-show-data.component";
import RenderPage from "../../../components/render-page/render-page.component";
import EditDepartment from "./edit-department/edit-department.component";
import CreateDepartment from "./add-department/add-department.component";

/* Material UI */
import Modal from '@material-ui/core/Modal';

/* Styles */
import {
    Actions,
} from "./manage-companies-tab.styles";

/* api */
import {getAllCompanies, getOneCompany} from "../../../api/ApiCompany";

/* Context */
import {authContext} from "../../../contexts/AuthContext";
import {getAllDepartments} from "../../../api/ApiDepartment";
import CustomButton from "../../../components/custom-button/custom-button.component";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200
    },
}));

export default function UsersCompaniesTab(props) {
    const {auth} = useContext(authContext);

    let history = useHistory();

    const [companyRows, setCompanyRows] = React.useState([]);
    const [departmentRows, setDepartmentRows] = React.useState([]);
    const [company, setCompany] = useState({companyId: props.company.companyId, name: props.company.name});
    const [departmentTitle, setDepartmentTitle] = useState({firstText: `Setores da `, warningText: `${props.company.name}`});
    const [fetchOpen, setFetchOpen] = useState(true);
    const [openEdit, setOpenEdit] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);

    const [selectedDepartment, setSelectedDepartment] = useState({});
    const [globalCompany, setGlobalCompany] = useState({});
    const [reloadTable, setReloadTable] = useState(false);

    const classes = useStyles();

    const companyHeadCells = [
        // {id: 'companyId', numeric: false, disablePadding: false, label: 'Código'},
        {id: 'name', numeric: false, disablePadding: false, label: 'Nome'},
        {id: 'cnpj', numeric: false, disablePadding: false, label: 'CNPJ'},
        {id: 'userAmount', numeric: false, disablePadding: false, label: 'Quantidade de colaboradores'},
        {id: 'action', numeric: true, disablePadding: false, label: 'Ação'}
    ];

    let editCompanyButtonInTable = {
        buttonLabel: "Editar empresa",
        start: `/management/companies/`,
        end: "/edit",
        firstInformation: "companyId"
    };

    const handleOpenCreate = () => {
        setOpenCreate(!openCreate);
    };

    const handleSingleClick = (event, row) => {
        setSelectedDepartment(row);
    };

    const handleOpenEdit = () => {
        setOpenEdit(!openEdit);
    };

    let editDepartmentButtonInTable = {
        buttonLabel: 'Editar setor',
        modal: true,
        modalHandle: handleOpenEdit,
    };

    const departmentHeadCells = [
        // {id: 'departmentId', numeric: false, disablePadding: false, label: 'Código'},
        {id: 'name', numeric: false, disablePadding: false, label: 'Nome'},
        // {id: 'purpose', numeric: false, disablePadding: false, label: 'Propósito'},
        {id: 'active', numeric: false, disablePadding: false, label: 'Status'},
        {id: 'icon', numeric: false, disablePadding: false, label: 'Ícone'},
        {id: 'managerName', numeric: false, disablePadding: false, label: 'Gestor'},   
        {id: 'parentName', numeric: false, disablePadding: false, label: 'Responsável'},
        {id: 'action', numeric: true, disablePadding: false, label: 'Ação'}
    ];

    const companyTitle = {firstText: "",
                            warningText: "Empresas"};

    useEffect(() => {
        const callFindAllCompanies = async () => {
            const response = await getAllCompanies(auth);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            let res = body.payload;

            for (let i = 0; i < res.length; i++) {
                res[i]["action"] = 1;
            }

            let keys = res.map((item) => {
                return {
                    name: item.name,
                    cnpj: item.cnpj,
                    userAmount: item.userAmount,
                    action: 1
                }
            });
            return keys
        };

        const callFindAllDepartments = async () => {
            const response = await getAllDepartments(auth, company.companyId, true);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            let res = body.payload;
            let keys = res.map((item) => {
                return {
                    // departmentId: item.departmentId,
                    name: item.name,
                    // purpose: item.purpose,
                    active: item.active ? "Ativo" : "Inativo",
                    icon: item.imageUrl ? "Sim" : "Não",
                    managerName: item.shortenedManagerName,
                    parentName: item.parentName,
                    action: 1
                }
            });
            return keys
        };

        callFindAllCompanies()
            .then(res => {
                setCompanyRows(res);
                callFindAllDepartments()
                    .then(res => {
                        setDepartmentRows(res);
                        setFetchOpen(false);
                    })
                    .catch(err => console.log(err));
            })
            .catch(err => console.log(err));


    }, [company,reloadTable]);

    useEffect(() => {
        let actualGlobalCompany;

        if (props.theGlobalCompanyHasChange) {
            setFetchOpen(true);
        }

        if (!isUndefined(props.globalCompany) && props.globalCompany !== null) {
            if (props.globalCompany.hasOwnProperty('companyId')) {
                actualGlobalCompany = props.globalCompany.companyId;
                setGlobalCompany(props.globalCompany.companyId);
            } else {
                actualGlobalCompany = auth.data.user.companyId;
                setGlobalCompany(auth.data.user.companyId);
            }
        } else {
            actualGlobalCompany = auth.data.user.companyId;
            setGlobalCompany(auth.data.user.companyId);
        }

        const callApiFindOneCompany = async (company) => {
            const response = await getOneCompany(auth, company);
            const { payload } = await response.json();

            setFetchOpen(false);
        };

        try {
            callApiFindOneCompany(actualGlobalCompany);
        } catch (error) {
            console.log(error);
        }
    }, [props, auth]);

    const addCompany = () => {
        history.replace(`/management/companies/${company.companyId}/add`);
    };

    const defaultPropsCompanies = {
        options: companyRows,
        defaultValue: {companyId: props.company.companyId, name: props.company.name},
        getOptionLabel: (option) => {
            return option.name
        },
        getOptionSelected: (option) => {
            return option.name
        },
        onChange: (event, newValue) => {
            setFetchOpen(true);
            setCompany(newValue);
            setDepartmentTitle(newValue ? {firstText: `Setores da `, warningText: `${newValue.name}`} : {firstText: `Selecione uma empresa`, warningText: ""})
        },
    };

    return (
        <>
            <>
                <Actions>
                    <CustomButton type='submit' onClick={addCompany}
                                  addButton> Nova empresa </CustomButton>
                </Actions>
                <EnhancedTable rows={companyRows} headCells={companyHeadCells} title={companyTitle} edit={editCompanyButtonInTable}/>
            </>

            <>
                <Actions>
                    <Autocomplete
                        {...defaultPropsCompanies}
                        id="companies"
                        disableClearable
                        autoSelect
                        className={classes.formControl}
                        style={{width: 150}}
                        renderInput={(params) => <TextField {...params} label="Empresa"
                                                            variant="outlined"/>}
                    />
                    <CustomButton type='button' onClick={handleOpenCreate}
                                  addButton> Novo setor </CustomButton>
                </Actions>
                <EnhancedTable 
                    rows={departmentRows} 
                    headCells={departmentHeadCells} 
                    title={departmentTitle} 
                    edit={editDepartmentButtonInTable}
                    handleClick={handleSingleClick}
                />
                <Modal
                    open={openCreate}
                    onClose={handleOpenCreate}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <CreateDepartment
                        company={company}
                        setReloadTable={setReloadTable}
                        reloadTable={reloadTable}
                        handleOpenCreate={handleOpenCreate}
                    />
                </Modal>
                <Modal
                    open={openEdit}
                    onClose={handleOpenEdit}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <EditDepartment
                        company={company}
                        setReloadTable={setReloadTable}
                        reloadTable={reloadTable}
                        department={selectedDepartment}
                        handleOpenEdit={handleOpenEdit}
                    />
                </Modal>
                {fetchOpen ?
                    <RenderPage open={fetchOpen}/> : null}
            </>
        </>
    );
}
