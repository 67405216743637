import styled from 'styled-components';

export const LoginContainer = styled.div`
    display: grid;
    width: 950px;
    height: 650px;
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-box-shadow: 12px 12px 12px 5px rgba(48, 56, 65, 1);
    -moz-box-shadow: 12px 12px 12px 5px rgba(48, 56, 65, 1);
    box-shadow: 12px 12px 12px 5px rgba(48, 56, 65, 1);
    grid-template-columns: 60% 40%;
    @media screen and (max-width: 1050px) {
        grid-template-columns: 100%;
        width: 450px;
        height: 500px;
    }
    @media screen and (max-width: 500px) {
        grid-template-columns: 100%;
        width: 300px;
        height: 450px;
    }
`;

export const LeftContainer = styled.div`
    display: grid;
    grid-template-rows: 20% 80%;
    background-color: #f6f4f4;
    border-radius: 12px 0px 0px 12px;
    @media screen and (max-width: 1050px) {
        display: none;
    }
`;

export const RightContainer = styled.div`
    display: grid;
    grid-template-rows: 30% 30% 40%;
    border-radius: 0px 12px 12px 0px;
    background: rgb(255, 117, 23);
    background: linear-gradient(
        90deg,
        rgba(255, 117, 23, 0.4) 1%,
        rgba(255, 242, 0, 0.4) 100%
    );
    @media screen and (max-width: 1050px) {
        justify-items: center;
        border-radius: 12px;
    }
`;

export const CharacterImage = styled.img`
    width: 435px;
    display: grid;
    align-self: center;
    margin: auto;
`;

export const LogoDrakkar = styled.img`
    width: 50px;
    display: grid;
    align-self: center;
    justify-self: flex-end;
    background-color: white;
    border-radius: 12px;
    margin: 12px;
    padding: 6px;
    border: 1px solid #d73b30;
`;

export const CompanyTitle = styled.div`
    font-family: Abril Fatface;
    font-size: 20px;
    font-weight: 700;
    margin-left: 12px;
    display: grid;
    grid-template-columns: 20% 80%;
    align-items: center;
`;
