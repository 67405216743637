import React, { useContext, useRef, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {
    TitleLogin,
    ForgetPasswordForm,
    SendButton,
    BackButton,
    ForgetPasswordButton,
    ForgetTitle,
} from './forget-password.styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

/* Api */
import { login, forgotPassword } from '../../../api/ApiUser';
import PopUp from '../../../components/popup/popup.component';

const useStyles = makeStyles((theme) => ({
    backButton: {
        boxShadow: 'none',
        textTransform: 'none',
        fontFamily: 'Abril Fatface',
        fontSize: '20px',
        fontWeight: '700px',
        borderRadius: '12px',
        width: '80px',
        backgroundColor: 'white',
    },
    resetButton: {
        boxShadow: 'none',
        textTransform: 'none',
        fontFamily: 'Abril Fatface',
        fontSize: '20px',
        fontWeight: '700px',
        borderRadius: '12px',
        width: '220px',
        backgroundColor: 'white',
    },
    textFieldEmail: {
        width: '220px',
        borderRadius: '6px',
        backgroundColor: 'white',
        marginTop: '12px',
    },
}));

export default function ForgetPasswordPage({ onBack }) {
    const classes = useStyles();
    const [popup, setPopup] = useState(false);
    const [popupText, setPopupText] = useState('');
    const [popupTitle, setPopupTitle] = useState('');
    const [popupClose, setPopupClose] = useState(null);
    const [success, setSuccess] = useState(1);
    const [recuperationEmail, setRecuperationEmail] = useState('');

    const handleSubmitForgotPassword = async (event) => {
        event.preventDefault();
        let data = {
            email: recuperationEmail,
        };

        try {
            let response = await forgotPassword(data);
            console.log(response.status);
            if (response.status === 200) {
                setPopupTitle('Sucesso');
                setPopupText('Verifique seu e-mail');
                setPopup(true);
                setSuccess(3);
                setPopupClose(() => handleBack);
            } else {
                setPopupTitle('Erro');
                setPopupText('E-mail não encontrado');
                setPopup(true);
                setSuccess(3);
                setPopupClose(() => handleClose);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleBack = () => {
        setPopup(false);
        onBack();
    };

    const handleClose = () => {
        setPopup(false);
    };

    return (
        <>
            <TitleLogin>
                Bem Vindo
                <br />
                <span style={{ fontSize: '24px' }}>Drakkariano</span>
                <span style={{ color: '#D73B30' }}>.</span>
            </TitleLogin>
            <ForgetPasswordForm>
                <ForgetTitle>Recuperação de senha</ForgetTitle>
                <TextField
                    className={classes.textFieldEmail}
                    label="Email"
                    type="email"
                    id="outlined-size-normal"
                    defaultValue=""
                    onChange={(event) =>
                        setRecuperationEmail(event.target.value)
                    }
                    variant="outlined"
                />
            </ForgetPasswordForm>
            <ForgetPasswordButton>
                <SendButton>
                    <Button
                        className={classes.resetButton}
                        onClick={handleSubmitForgotPassword}
                        variant="contained"
                    >
                        Enviar
                    </Button>
                </SendButton>
                <BackButton>
                    <Button
                        className={classes.backButton}
                        variant="contained"
                        onClick={handleBack}
                    >
                        <ArrowBackIcon />
                    </Button>
                </BackButton>
            </ForgetPasswordButton>
            {popup ? (
                <PopUp
                    title={popupTitle}
                    string={popupText}
                    success={success}
                    setNewFatherContext={popupClose}
                />
            ) : null}
        </>
    );
}
