import React, {useContext, useState} from "react";
import {Redirect} from "react-router-dom";

/* Styles */
import {DrawerAdjustment, Spacer} from "./add-category.styles";

/* Components */
import Header from "../../../../../components/header/header.component";
import FormList from "../../../../../components/form-list/form-list.component";
import PopUp from "../../../../../components/popup/popup.component";
import RenderPage from "../../../../../components/render-page/render-page.component";

/* Material UI */
import Container from "@material-ui/core/Container";

/* api */
import {addCategory} from "../../../../../api/ApiCategory";

/* Context */
import {authContext} from "../../../../../contexts/AuthContext";

/* Permission */
import permissions, {getPermission} from "../../../../../utils/permission";

export default function AddCategory() {
    const {auth} = useContext(authContext);

    const [name, setName] = React.useState('');

    const [popup, setPopup] = React.useState(false);
    const [popupText, setPopupText] = React.useState('');
    const [popupTitle, setPopupTitle] = React.useState('');
    const [success, setSuccess] = React.useState(1);

    const [fetchOpen, setFetchOpen] = useState(false);

    const warningTitle = {
        phrase: "Adicionando uma nova categoria no ",
        object: "sistema"
    };

    let rowsInput = [{name: "name", type: "text", value: name, label: "Nome da categoria", placeholder: "", mask: false, required: true, maxLength: 40}];

    let buttonInfo = {
        name: "Adicionar",
        addButton: true,
        editButton: false,
    };

    const handleSubmit = async event => {
        event.preventDefault();
        setFetchOpen(true);

        let data = {
            name: name,
        };

        try {
            let response = await addCategory(data, auth);
            setFetchOpen(false);
            if(response.status === 201){
                setPopupTitle('Sucesso');
                setPopupText('Categoria criada.');
                setPopup(true);
                setSuccess(1);
            } else {
                setPopupTitle('Erro');
                setPopupText('Categoria não pode ser criada.');
                setPopup(true);
                setSuccess(1);
            }

        } catch (error) {
            console.log(error);
        }
    };


    const handleChange = event => {
        const {value, name} = event.target;
        if (name === 'name')
            setName(value);
    };

    return (
        <div>
            {!getPermission(auth.data.permissions, permissions.IPA_MANAGE_CATEGORIES) ?
                <Redirect to={permissions.IPA_DEFAULT_ROUTE}/>
                : null}
            <Header/>
            <DrawerAdjustment>
                <Container>
                    <Spacer/>
                    <FormList rowsInput={rowsInput} handleSubmit={handleSubmit} handleChange={handleChange}
                              warningTitle={warningTitle} buttonInfo={buttonInfo}/>
                </Container>
                <Spacer/>
            </DrawerAdjustment>
            { popup ?
                <PopUp title={popupTitle} string={popupText} success={success}/>: null}
            {fetchOpen ?
                <RenderPage open={fetchOpen}/> : null}
        </div>
    );
}