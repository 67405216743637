/* Generic component to global filter, that is used to the master user change the company context */
import React, { useContext, useEffect } from 'react';

/* Styles */
import { Box } from './globalFilter.styles';

/* Material UI */
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

/* Api */
import { getAllCompanies } from '../../api/ApiCompany';

/* Context */

import { authContext } from '../../contexts/AuthContext';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

export default function GlobalCompanyFilter(props) {
  const { auth } = useContext(authContext);

    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 220,
            width: 260,
            backgroundColor: '#e0f2f1',
            borderRadius: 10,
            padding: 3,
        },
    }));

  const [companyRows, setCompanyRows] = React.useState([]);

  const classes = useStyles();

    const outerTheme = createTheme({
        palette: {
            primary: {
                main: '#26a69a',
                dark: '#26a69a',
                contrastText: '#212121',
            },
        },
    });

  useEffect(() => {
    const callFindAllCompanies = async () => {
      const response = await getAllCompanies(auth);
      const body = await response.json();

      if (response.status !== 200) throw Error(body.message);

            return body.payload;
        };

        callFindAllCompanies()
            .then((res) => {
                setCompanyRows(res);
            })
            .catch((err) => console.log(err));
  }, [auth, props.globalCompany]);

    const defaultPropsCompanies = {
        options: companyRows,
        value: props.globalCompany,
        getOptionLabel: (option) => {
            return option.name;
        },
        getOptionSelected: (option) => {
            return option.name;
        },
        onChange: (event, newValue) => {
            props.changeActualGlobalCompany(newValue);
        },
    };

  return (
    <MuiThemeProvider theme={outerTheme}>
      {props.globalCompany ? (
        <Box>
          <Autocomplete
            {...defaultPropsCompanies}
            id="companies"
            autoSelect
            disableClearable
            className={classes.formControl}
            renderInput={(params) => (
              <TextField {...params} label="Empresa" variant="outlined" />
            )}
          />
        </Box>
      ) : null}
    </MuiThemeProvider>
  );
}
