import styled from "styled-components/macro";

export const DrawerAdjustment = styled.div`
    height: 70%;
    margin-bottom: 80px;
    margin-left: 100px;
    margin-right: 100px;
    padding-left: 72px;
    @media screen and (max-width: 800px) {
        padding-left: 0;
    }
   @media screen and (max-width: 650px) {
        margin-left: 24px;
        margin-right: 24px;
        margin-bottom: 20px;
        padding: 0px;
    }
`;

export const Actions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    @media screen and (max-width: 650px) {
        padding-top: 10px;
        flex-direction: column;
        justify-content: center;
    }
`;
