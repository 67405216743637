import styled from 'styled-components/macro';

export const AlignContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 422px) {
        width: 100%;
        display: block;
    }
    @media screen and (max-width: 640px) {
        width: 100%;
    }
    @media screen and (max-height: 780px) {
        width: 100%;
    }
`;

export const StyledFilter = styled.div`
    width: 25vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 20px;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 1450px) {
        width: 40vw;
        margin-left: auto;
        margin-right: auto;
    }
    @media screen and (max-width: 1000px) {
        width: 50vw;
        margin-left: auto;
        margin-right: auto;
    }
    @media screen and (max-width: 830px) {
        justify-content: space-around;
        margin-left: auto;
        margin-right: auto;
    }
    @media screen and (max-width: 780px) {
        align-items: center;
        padding-bottom: 5px;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
    }
`;

export const StyledSpan = styled.div`
    align-self: center;
    margin-right: 10px;
    @media screen and (max-width: 450px) {
        margin-top: 10px;
    }
`;

export const StyledButton = styled.div`
    display: flex;
    justify-content: center;
    padding: 20px 0px 10px 0px;
`;

export const StyledInput = styled.input`
    border: none;
    border-bottom: 1px solid #aaaaaa;
    font-size: 16px;
    height: 30px;
    width: 80px;
    text-align: center;
    background-color: ${({color}) => color===3 ? '#38761D' :
    color===2 ? '#3d9a15':
        color===1 ? '#00FF00':
            color===-1 ? '#FFFF00':
                color===-2 ? '#FF7800':
                    color===-3 ? '#FF0000':
                        null
};
    &:focus {
        border: 1px solid #aaaaaa;
        outline: none;
    }
`;

export const Legend = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 15px;
`;

export const StyledContainerTitle = styled.div`
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 780px) {
        width: 0;
        height: 0;
        visibility: hidden; 
    }
`;

export const TableCellData = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;
