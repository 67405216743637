import React from 'react';

/* Material Ui */
import CircularProgress from '@material-ui/core/CircularProgress';

/* Styles */
import { RenderContainer } from './render-componenet.styles';
import { Loader } from '../loader/loader.styles';

export default function RenderComponent(props) {
    return (
        <RenderContainer>
            <Loader/>
        </RenderContainer>
    );
}
