import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useHistory } from 'react-router-dom';

/* Components */
import Header from '../../../components/header/header.component';
import ManageIpaTasks from './manage-tasks-tab/manage-tasks-tab.component';
import ManageIpaCategories from './manage-categories-tab/manage-categories-tab.component';
import SendTasks from './send-tab/send-tab.component';
import HistoryIpaTasksTab from './history-tab/history-tab.components';
import {
    helpSendTasks,
    helpHistoryTasks,
} from '../../../components/help/help.texts';
import Help from '../../../components/help/help.component';

/* Styles */
import {
    DrawerAdjustment,
    Spacer,
    TableContainer,
    MainComponents,
    CenteredElement,
    StyledContainer,
} from './ipa-tasks.styles';

/* Material UI */
import Container from '@material-ui/core/Container';
import { createTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

/* Context */
import { authContext } from '../../../contexts/AuthContext';

/* API */
import GlobalCompanyFilter from '../../../components/globalFilter/globalFilter.component';
import { getOneCompany } from '../../../api/ApiCompany';
import { findIfSubmoduleIsActive } from '../../../api/ApiPemissions';

/* Permission */
import permissions, {
    setTabsOrder,
    getSubmodulePermission,
} from '../../../utils/permission';
import { getInternetError } from '../../../utils/genericFunctions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box style={{ padding: 0 }} p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const SendTab = (props) => {
    const [help, setHelp] = useState(false);
    const onChangeHelp = () => {
        setHelp(!help);
    };
    return (
        <TabPanel value={props.value} index={props.SEND}>
            <Help
                onClick={onChangeHelp}
                helpPopUp={helpSendTasks}
                help={help}
            />
            <CenteredElement>
                <Typography variant="h5">Tarefas mensais</Typography>
            </CenteredElement>
            <SendTasks globalCompany={props.globalCompany} />
        </TabPanel>
    );
};

const HistoryTab = (props) => {
    const [help, setHelp] = useState(false);
    const onChangeHelp = () => {
        setHelp(!help);
    };
    return (
        <TabPanel value={props.value} index={props.HISTORY}>
            <Help
                onClick={onChangeHelp}
                helpPopUp={helpHistoryTasks}
                help={help}
            />
            <StyledContainer>
                <MainComponents>
                    <Typography variant="h5">Histórico de tarefas</Typography>
                    {getSubmodulePermission(
                        props.submodules.history,
                        permissions.SYSTEM_GLOBAL_COMPANY_FILTER
                    ) ? (
                        <GlobalCompanyFilter
                            changeActualGlobalCompany={
                                props.changeActualGlobalCompany
                            }
                            globalCompany={props.globalCompany}
                        />
                    ) : null}
                </MainComponents>
                <HistoryIpaTasksTab
                    submodules={props.submodules.history}
                    globalCompany={props.globalCompany}
                    resetChangeActualGlobalCompany={
                        props.resetChangeActualGlobalCompany
                    }
                    theGlobalCompanyHasChange={props.theGlobalCompanyHasChange}
                />
            </StyledContainer>
        </TabPanel>
    );
};

const ManageTasksTab = (props) => {
    return (
        <TabPanel
            value={props.value}
            index={props.TASKS}
            style={{ padding: 35 }}
        >
            <MainComponents>
                <Typography variant="h5">Gerenciamento de tarefas</Typography>
                {getSubmodulePermission(
                    props.submodules.tasks,
                    permissions.SYSTEM_GLOBAL_COMPANY_FILTER
                ) ? (
                    <GlobalCompanyFilter
                        changeActualGlobalCompany={
                            props.changeActualGlobalCompany
                        }
                        globalCompany={props.globalCompany}
                    />
                ) : null}
            </MainComponents>
            <ManageIpaTasks
                globalCompany={props.globalCompany}
                resetChangeActualGlobalCompany={
                    props.resetChangeActualGlobalCompany
                }
                theGlobalCompanyHasChange={props.theGlobalCompanyHasChange}
            />
        </TabPanel>
    );
};

const CategoriesTab = (props) => {
    return (
        <TabPanel
            value={props.value}
            index={props.CATEGORIES}
            style={{ padding: 35 }}
        >
            <MainComponents>
                <Typography variant="h5">
                    Gerenciamento de categorias
                </Typography>
            </MainComponents>
            <ManageIpaCategories />
        </TabPanel>
    );
};

const getModulePermissionsAndTabs = (userPermissions, permissions) => {
    let resSend = userPermissions.find(
        (elem) => elem.moduleId === permissions.IPA_SEND_TASKS
    );
    let resHistory = userPermissions.find(
        (elem) => elem.moduleId === permissions.IPA_TASKS_HISTORY
    );
    let resManage = userPermissions.find(
        (elem) => elem.moduleId === permissions.IPA_MANAGE_TASKS
    );
    let resCategories = userPermissions.find(
        (elem) => elem.moduleId === permissions.IPA_MANAGE_CATEGORIES
    );

    let tempTabs = {
        SEND: !!resSend.active /* !! convert 1 to true or 0 to false*/,
        HISTORY: !!resHistory.active,
        TASKS: !!resManage.active,
        CATEGORIES: !!resCategories.active,
    };

    setTabsOrder(tempTabs);

    return {
        tabs: tempTabs,
        modules: {
            send: resSend.active,
            history: resHistory.active,
            tasks: resManage.active,
            categories: resCategories.active,
        },
    };
};

export default function IpaTasksPage() {
    const { auth } = useContext(authContext);
    const [globalCompany, setGlobalCompany] = useState(
        auth.data.user.permissionLevel !== 3 ? undefined : { name: '' }
    );
    const userPermissions = getModulePermissionsAndTabs(
        auth.data.permissions,
        permissions
    );
    const [tabs] = useState(userPermissions.tabs);
    const [isEnabledToAccess] = useState(userPermissions.modules);
    const [theGlobalCompanyHasChange, setTheGlobalCompanyHasChange] =
        useState(false);

    const [submodules, setSubmodules] = useState({
        send: [],
        history: [],
        tasks: [],
        categories: [],
    });

    const outerTheme = createTheme({
        palette: {
            primary: {
                main: auth.data.system ? '#ff6922' : '#ffeb3b',
                dark: auth.data.system ? '#ff5722' : '#ffc107',
                contrastText: '#212121',
            },
        },
    });

    let history = useHistory();

    const classes = useStyles();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const changeActualGlobalCompany = (company) => {
        if (company !== null) {
            setTheGlobalCompanyHasChange(true);
            setGlobalCompany(company);
        }
    };

    const resetChangeActualGlobalCompany = (value) => {
        setTheGlobalCompanyHasChange(value);
    };

    useEffect(() => {
        const callFindOneCompany = async () => {
            const response = await getOneCompany(
                auth,
                auth.data.user.companyId
            );
            const body = await response.json();

            return body.payload[0];
        };

        const callFindIfSubmoduleIsActive = async (
            permissionRole,
            permissionModule
        ) => {
            const response = await findIfSubmoduleIsActive(
                auth,
                permissionRole,
                permissionModule
            );
            const body = await response.json();

            return body.payload;
        };

        let internetError;

        internetError = getInternetError();
        if (internetError) {
            history.replace('/error/500');
            return;
        }

        callFindOneCompany()
            .then((res) => {
                setGlobalCompany(res);
            })
            .catch((err) => console.log(err));

        callFindIfSubmoduleIsActive(
            auth.data.user.permissionRoleId,
            permissions.IPA_SEND_TASKS
        )
            .then((resSend) => {
                callFindIfSubmoduleIsActive(
                    auth.data.user.permissionRoleId,
                    permissions.IPA_TASKS_HISTORY
                )
                    .then((resHistory) => {
                        callFindIfSubmoduleIsActive(
                            auth.data.user.permissionRoleId,
                            permissions.IPA_MANAGE_TASKS
                        )
                            .then((resManageTasks) => {
                                callFindIfSubmoduleIsActive(
                                    auth.data.user.permissionRoleId,
                                    permissions.IPA_MANAGE_CATEGORIES
                                )
                                    .then((resManageCategories) => {
                                        setSubmodules({
                                            send: resSend,
                                            history: resHistory,
                                            tasks: resManageTasks,
                                            categories: resManageCategories,
                                        });
                                    })
                                    .catch((err) => console.log(err));
                            })
                            .catch((err) => console.log(err));
                    })
                    .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
    }, [auth, history]);

    return (
        <div>
            {!isEnabledToAccess.send &&
            !isEnabledToAccess.history &&
            !isEnabledToAccess.tasks &&
            !isEnabledToAccess.categories ? (
                <Redirect to={permissions.IPA_DEFAULT_ROUTE} />
            ) : null}
            <Header />
            <DrawerAdjustment>
                <Spacer />
                <Container>
                    <div className={classes.root}>
                        <MuiThemeProvider theme={outerTheme}>
                            <AppBar
                                position="static"
                                color="default"
                                style={{
                                    backgroundColor: auth.data.system
                                        ? '#fff3e0'
                                        : '#fffde7',
                                    paddingBottom: 0.2,
                                    borderLeft: '1px solid #cfd8dc',
                                    borderRight: '1px solid #cfd8dc',
                                    borderTop: '1px solid #cfd8dc',
                                }}
                            >
                                <Tabs
                                    TabIndicatorProps={{
                                        style: {
                                            height: '4px',
                                        },
                                    }}
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                >
                                    {isEnabledToAccess.send ? (
                                        <Tab
                                            style={{ fontWeight: 'bold' }}
                                            label="Enviar"
                                            {...a11yProps(tabs.SEND)}
                                        />
                                    ) : null}
                                    {isEnabledToAccess.history ? (
                                        <Tab
                                            style={{ fontWeight: 'bold' }}
                                            label="Histórico"
                                            {...a11yProps(tabs.HISTORY)}
                                        />
                                    ) : null}
                                    {isEnabledToAccess.tasks ? (
                                        <Tab
                                            style={{ fontWeight: 'bold' }}
                                            label="Tarefas"
                                            {...a11yProps(tabs.TASKS)}
                                        />
                                    ) : null}
                                    {isEnabledToAccess.categories ? (
                                        <Tab
                                            style={{ fontWeight: 'bold' }}
                                            label="Categorias"
                                            {...a11yProps(tabs.CATEGORIES)}
                                        />
                                    ) : null}
                                </Tabs>
                            </AppBar>
                        </MuiThemeProvider>
                    </div>
                    <TableContainer>
                        {isEnabledToAccess.send ? (
                            <SendTab
                                value={value}
                                SEND={tabs.SEND}
                                globalCompany={globalCompany}
                            />
                        ) : null}
                        {isEnabledToAccess.history ? (
                            <HistoryTab
                                value={value}
                                HISTORY={tabs.HISTORY}
                                submodules={submodules}
                                globalCompany={globalCompany}
                                resetChangeActualGlobalCompany={
                                    resetChangeActualGlobalCompany
                                }
                                theGlobalCompanyHasChange={
                                    theGlobalCompanyHasChange
                                }
                                changeActualGlobalCompany={
                                    changeActualGlobalCompany
                                }
                            />
                        ) : null}
                        {isEnabledToAccess.tasks ? (
                            <ManageTasksTab
                                value={value}
                                TASKS={tabs.TASKS}
                                submodules={submodules}
                                globalCompany={globalCompany}
                                resetChangeActualGlobalCompany={
                                    resetChangeActualGlobalCompany
                                }
                                theGlobalCompanyHasChange={
                                    theGlobalCompanyHasChange
                                }
                                changeActualGlobalCompany={
                                    changeActualGlobalCompany
                                }
                                userPermission={auth.data.user.permissionLevel}
                            />
                        ) : null}{' '}
                        {/*submodules={submodules}*/}
                        {isEnabledToAccess.categories ? (
                            <CategoriesTab
                                value={value}
                                globalCompany={globalCompany}
                                CATEGORIES={tabs.CATEGORIES}
                                changeActualGlobalCompany={
                                    changeActualGlobalCompany
                                }
                                userPermission={auth.data.user.permissionLevel}
                            />
                        ) : null}
                    </TableContainer>
                </Container>
            </DrawerAdjustment>
        </div>
    );
}
