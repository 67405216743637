import styled from "styled-components/macro";

export const TableContainer = styled.div`
    height: 70%;
    background-color: white;
    border: 1px solid #cfd8dc;
    padding: 70px;
    @media screen and (max-height: 950px) {
        height: 75%;
    }
   @media screen and (max-height: 830px) {
        height: 85%;
        margin-bottom: 20px;
        padding: 0;
    }
    margin-bottom: 80px;
   @media screen and (max-width: 650px) {
        margin-bottom: 20px;
    }
`;

export const Spacer = styled.div`
    height: 12vh;
    @media screen and (max-height: 600px) {
        height: 70px;
    }
`;

export const DrawerAdjustment = styled.div`
    padding-left: 72px;
    @media screen and (max-width: 800px) {
        padding-left: 0;
    }
`;

export const StyledContainerTitle = styled.div`
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 780px) {
        width: 0;
        height: 0;
        visibility: hidden; 
    }
`;

export const ContainerTitle = styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 780px) {
        width: 0;
        height: 0;
        visibility: hidden; 
    }
`;
export const Title = styled.h2`
    margin-bottom: 8px;
    @media screen and (max-width: 780px) {
        width: 0;
        height: 0;
        visibility: hidden; 
    }
`;

export const Stripe = styled.div`
    margin-top: 13px;
    margin-right: 13px;
    border-left:5px solid #4f4f4f;
    height: 65px;
    @media screen and (max-width: 780px){
        width: 0;
        height: 0;
        visibility: hidden;  
    }
`;

export const AddTaskModal = styled.div`
    display: grid;
    grid-template-areas:
        'column1 column2'
        'column3 column3'
        'button button';
    justify-items: center;
    padding: 0 120px;
`;

export const AddName = styled.div`
    display: grid;
    grid-area: column1;
    width: 100%;
    padding: 10px;
`;

export const AddCategory = styled.div`
    display: grid;
    grid-area: column2;
    width: 100%;
    padding: 10px;
`;

export const AddDescription = styled.div`
    display: grid;
    grid-area: column3;
    width: 100%;
    padding: 10px;
    margin: 12px auto;
`;

export const ButtonContainer = styled.div`
    display: grid;
    grid-area: button;
`;
