import styled from 'styled-components';

export const Spacer = styled.div`
    height: 12vh;
    @media screen and (max-height: 600px) {
        height: 70px;
    }
`;

export const NotificationList = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-stretch;
    justify-content: space-around;
`;

export const ContainerTitle = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 620px){
        width: 0;
        height: 0;
        visibility: hidden; 
    }
`;

export const StyledContainerTitle = styled.div`
    display: flex;
    flex-direction: row;  
    @media screen and (max-width: 620px){
        width: 0;
        height: 0;
        visibility: hidden; 
    }
`;

export const Stripe = styled.div`
    margin-top: 13px;
    margin-right: 13px;
    border-left:5px solid #01579b;
    height: 35px;
    position: relative;
    background-color: #26a69a;
    @media screen and (max-width: 620px) {
        width: 0;
        height: 0;
        visibility: hidden; 
    }
`;

export const NotificationsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const DrawerAdjustment = styled.div`
    padding-left: 72px;
    @media screen and (max-width: 800px) {
        padding-left: 0;
    }
`;
