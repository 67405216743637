import styled from 'styled-components';

export const DashboardStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;


export const LeftRightSelect = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
`;

export const SpanMonth = styled.div`
    box-shadow: 1px 1px 3px 4px #ccc;
    border-radius: 5%;
    text-align: center;
    padding: 5px;
`;

export const LegendColor = styled.div`
    height: ${({isBig}) => isBig ? '62px' : '44px'};
    width: ${({isBig}) => isBig ? '62px' : '44px'};
    font-size: ${({isBig}) => isBig ? '30px' : '20px'};
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 480px) {
        height: ${({isBig}) => isBig ? '52px' : '42px'};
        width: ${({isBig}) => isBig ? '52px' : '42px'};
        font-size: ${({isBig}) => isBig ? '25px' : '20px'};
    }
    @media screen and (max-width: 322px){
        height: ${({isBig}) => isBig ? '46px' : '38px'};
        width: ${({isBig}) => isBig ? '46px' : '38px'};
        font-size: ${({isBig}) => isBig ? '22px' : '18px'};
    }
`;

export const StyledSpan = styled.span`
    text-align: center;
    align-self: center;
    font-size: ${({isBig}) => isBig ? '30px' : '20px'};
    @media screen and (max-width: 480px) {
        font-size: ${({isBig}) => isBig ? '20px' : '15px'};
    }
    @media screen and (max-width: 322px){
        font-size: ${({isBig}) => isBig ? '20px' : '15px'};
    }
`;

export const MyPaper = styled.div`
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    @media screen and (max-width: 322px){
        margin: 0;
    }
`;

export const GraphDiv = styled.div`
    height: 466px;
    width: 782px;
    min-height: 400px;
    min-width: 400px; 
    // Solve error: (Unable to preventDefault inside passive event listener due to 
    // target being treated as passive)
    // The error was present while 
    // touching the graph in mobile mode
    touch-action: pan-y;
      
    @media screen and (max-width: 800px) {
        height: 450px;
        width: 450px; 
    }
    @media screen and (max-width: 450px) {
        height: 70vh;
        width: 105vw;
        min-height: 70vh;
        min-width: 100vw;
        font-size: x-small;
        padding-right: 13px;      
    }
    @media screen and (max-height: 420px) {
        height: 466px;
    }
`;

export const ListElement = styled.div`
    position: absolute;
    font-size: large;
    left: 49%;
    top: 54%;
    transform: translate(-50%, -50%);
    color: black;
    @media screen and (max-width: 480px) {
        top: 54%;
        left: 50%;
    }
    @media screen and (max-width: 360px){
        top: 53%;
        left: 51%;
    }
    @media screen and (max-height: 420px) {
        top: 52%;
        left: 52%;
    }
    @media only screen and (orientation: portrait) {
        left: 52%;
    }
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
`;
