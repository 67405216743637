import styled from 'styled-components/macro'

export const Filters = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
    @media screen and (max-width: 362px) {
        flex-direction: column;
    }
`;

export const Label = styled.label`
   display: flex;
   justify-content: center;
   color: #424242;
   font-size: 16px;
   font-weight: bold;
`;

export const StyledSwitch = styled.div`
   display: flex;
   justify-content: center;
`;
