import styled from 'styled-components';

export const StyledFlexAdjust = styled.div`
    display: flex;
    flex-direction:row;
    align-items: center;

    @media screen and (max-width: 750px) {
        flex:1;
        justify-content: center;
    }
`;

export const StyledLabel = styled.div`
    margin-right: 20px;
`;

export const StyledSelectedLabel = styled.div`
    margin-right: 20px;
    color: #ff6922;
    font-weight: bold;
`;