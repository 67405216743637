import React, {useContext, useEffect, useState} from "react";
import {Redirect} from "react-router-dom";

/* Components */
import Header from "../../../../components/header/header.component";
import FormList from "../../../../components/form-list/form-list.component";
import PopUp from "../../../../components/popup/popup.component";
import RenderPage from "../../../../components/render-page/render-page.component";
import CustomButton from '../../../../components/./custom-button/custom-button.component';
import ImageEditor from "../../../.././components/./image-editor/image-editor";

/* Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Container from "@material-ui/core/Container";

/* Styles */
import {
    DrawerAdjustment, 
    Spacer,
    AddDepartmentModal,
    AddImage,
    AddName,
    AddPurpose,
    AddDeparment,
    AddDeparmentManager,
    ButtonContainer,
} from "./edit-department.styles";
import {
    WarningTitleContainer,
    WarningWord,
} from '../../../../components/form-list/form-list.styles';

/* api */
import {
    editDepartment,
    getOneDepartment,
    getAllDepartments,
    uploadDepartmentImage
} from "../../../../api/ApiDepartment";

import {getAllActiveUsersInDepartment} from "../../../../api/ApiUser";

/* Context */
import {authContext} from "../../../../contexts/AuthContext";

/* Permissions */
import permissions, {getPermission} from "../../../../utils/permission";
import { Add } from "@material-ui/icons";

/* Utils */

const useStyles = makeStyles((theme) => ({
    paperModal: {
        marginTop: '10%',
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        width: 950,
        maxHeight: 900,
        borderRadius: '10px',
        backgroundColor: '#fff',
        padding: '10px',
        fontFamily: 'Roboto',
        textAlign: 'center',
        '@media (max-width: 750px)': {
            height: 620,
            marginTop: '80%',
            transform: 'translate(-50%, -50%) scale(0.7)',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200
    },
}));

export default function EditDepartment(props) {
    const {auth} = useContext(authContext);
    const [name, setName] = React.useState('');
    const [purpose, setPurpose] = React.useState('');
    const [optionsDepartments, setOptionsDepartments] = React.useState([]);
    const [optionsDepartmentUsers, setOptionsDepartmentUsers] = React.useState([]);
    const [department, setDepartment] = React.useState({});
    const [departmentManager, setDepartmentManager] = React.useState({}); 

    const [warningTitle, setWarningTitle] = useState({
        phrase: `Editando o setor `,
        object: `${props.department.name}`,
        warning: parseInt(props.company.companyId) !== parseInt(auth.data.user.companyId) ? `(Cuidado! Você está editando um setor de outra empresa)` : ''
    });

    const [popup, setPopup] = React.useState(false);
    const [popupText, setPopupText] = React.useState('');
    const [popupTitle, setPopupTitle] = React.useState('');
    const [buttonPopUp, setButtonPopUp] = React.useState({
        popUp: false,
        popUpTitle: "",
        popUpText: "",
        success: 1,
        acceptable: true
    });
    const [acceptFunction, setAcceptFunction] = React.useState();

    const [success, setSuccess] = React.useState(1);
    const [route, setRoute] = React.useState('');
    const [fetchOpen, setFetchOpen] = React.useState(true);

    //image
    const [imageUrl, setImageUrl] = useState();
    const [preview, setPreview] = useState();
    const [imageFile, setImageFile] = useState();
    const [openEditor, setOpenEditor] = useState(false);

    let imageUpload = {
        title: 'Adicionar Imagem',
        preview: preview,
        setPreview: setPreview,
        openEditor: openEditor,
        setOpenEditor: setOpenEditor,
        src: imageUrl,

        imageFile: imageFile,
        setImageFile: setImageFile,
    };

    const classes = useStyles();

    const handleClose = () => {
        setButtonPopUp({
            popUp: false,
            popUpTitle: "",
            popUpText: "",
            success: 0,
            acceptable: false
        });
    };

    const setNewFatherContext = () => {
        props.setReloadTable(!props.reloadTable);
        props.handleOpenEdit();
    };

    // Handle Edit das Tarefas *mudar para department*
    const handleEdit = async event => {
        setPopup(false);
        event.preventDefault();
        setFetchOpen(true);
        let data = {
            name: name,
            purpose: purpose,
            company: props.company.companyId,
            department: props.department.departmentId,
            managerId: departmentManager.userId ? departmentManager.userId : null,
            parentId: department.departmentId ? department.departmentId : null 
        }
        
        try {   
            const response = await editDepartment(
                auth,
                data
            );
            setFetchOpen(false);
            if (response.status === 201) {
                if(imageFile) {
                    await uploadDepartmentImage(auth, imageFile, data.department, data.company);
                }
                setPopupTitle('Sucesso');
                setPopupText('Setor editado.');
                setPopup(true);
                setSuccess(3);
            } else {
                setPopupTitle('Erro');
                setPopupText('Setor não pode ser editado.');
                setPopup(true);
                setSuccess(3);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const callApiFindOneDepartment = async () => {
            const response = await getOneDepartment(auth, props.department.departmentId, props.company.companyId);

            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);
            
            return body.payload[0];
        };

        const callFindAllActiveUsersInDepartment = async () => {
            const response = await getAllActiveUsersInDepartment(auth, props.department.departmentId, props.company.companyId);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            let res = body.payload;
            setOptionsDepartmentUsers(res);
        };

        const callFindAllDepartments = async () => {
            const response = await getAllDepartments(auth, props.company.companyId, true);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            let res = body.payload;
            const nenhumOption = {
                departmentId: null,
                name: 'Nenhum'
            }
            let filteredDepartments = res.filter(department => department.departmentId !== props.department.departmentId);
            filteredDepartments.unshift(nenhumOption);
            setOptionsDepartments(filteredDepartments);
        };

        callApiFindOneDepartment()
            .then(res => {
                setFetchOpen(false);
                setName(res.name ? res.name : '');
                setPurpose(res.purpose ? res.purpose : '');
                setDepartment({
                    departmentId: res.parentId,
                    name: res.parentName
                })
                if (res.imageUrl) {
                    fetch(res.imageUrl)
                        .then((response) => response.blob())
                        .then((blob) => {
                            const url =
                                URL.createObjectURL(blob);
                            setImageUrl(url);
                        });
                    setPreview(res.imageUrl);
                }
            })
            .catch(err => console.log(err));
        callFindAllActiveUsersInDepartment();
        callFindAllDepartments();
    }, [ auth, props.department.departmentId, props.company.companyId ]);

    const updateStatus = async event => {
        handleClose();

        setPopup(false);
        event.preventDefault();
        setFetchOpen(true);

        let data = {
            active: props.department.active === 'Ativo' ? false : true,
            company: props.company.companyId,
            department: props.department.departmentId
        }

        try {
            const departmentStatus = props.department.active === 'Ativo' ? false : true;
            let response = await editDepartment(auth, data);
            setFetchOpen(false);
            if(response.status === 201){ //TODO change this to 201
                setPopupTitle('Sucesso');
                if(departmentStatus) {
                    setPopupText('Setor ativado.');
                } else {
                    setPopupText('Setor desativado.');
                }
            } else {
                setPopupTitle('Erro');
                setPopupText('Ocorreu um erro ao ativar/desativar o setor.');
            }
            setPopup(true);
            setSuccess(3);

        } catch (error) {
            console.log(error);
        }
    };

    const actionUpdateTaskButton = () => {
        handleClose()
        setButtonPopUp({
            popUp: true,
            popUpTitle: props.department.active === 'Ativo'
                ? "Cuidado! Você está desativando um setor." 
                : "Cuidado! Você está ativando um setor.",
            popUpText: props.department.active === 'Ativo'
                ? "Você só pode desativar setores que não possuem usuários vinculados." 
                : "Você tem certeza que deseja ativar esse setor?",
            success: 3,
            acceptable: true,
            first: false,
            second: true
        });
    };

    const handleChange = event => {
        const {value, name, files} = event.target;
        if (name === 'name') {
            setName(value);
        } else if (name === 'purpose') {
            setPurpose(value);
        } else if (name === 'file') {    
            if(!files[0]) return;
        
            const maxSize = 2097152 // 2 MB
            
            if(files[0].type.split("/")[0] !== "image") {
                setPopupTitle('Erro');
                setPopupText('Tipo de arquivo inválido');
                setPopup(true);
                setSuccess(0);
                return;
            }
            
            if(files[0].size > maxSize) {
                setPopupTitle('Erro');
                setPopupText(`Arquivo muito grande. Tamanho máximo: ${maxSize / 1024 / 1024} MB`);
                setPopup(true);
                setSuccess(0);
                return;
            };
            
            if(preview) URL.revokeObjectURL(preview);
            if(imageUrl) URL.revokeObjectURL(imageUrl);
            
            const objectUrl = URL.createObjectURL(files[0])
            
            setImageUrl(objectUrl);
            setImageFile(files[0]);
            setOpenEditor(true);
        }
    };

    const defaultPropsDepartmentUsers = {
        options: optionsDepartmentUsers,
        getOptionLabel: (option) => {
            return option.name || '';
        },
        getOptionSelected: (option) => {
            return option.name || '';
        },
        onChange: (event, newValue) => {
            setFetchOpen(true);
            setDepartmentManager(newValue);
        },
    };

    const defaultPropsDepartments = {
        options: optionsDepartments,
        value: department,
        getOptionLabel: (option) => {
            return option.name || '';
        },
        getOptionSelected: (option) => {
            return option.name || '';
        },
        onChange: (event, newValue) => {
            setFetchOpen(true);
            setDepartment(newValue);
        },
    };

    return (
        <div className={classes.paperModal}>
            {warningTitle ? (
                <WarningTitleContainer>
                    <Typography variant="h5">
                        {warningTitle.phrase}
                        <WarningWord>{warningTitle.object}</WarningWord>
                    </Typography>
                    <Typography variant="body2">
                        <WarningWord>{warningTitle.warning}</WarningWord>
                    </Typography>
                </WarningTitleContainer>
            ) : null}

            <AddDepartmentModal>
                <AddImage>
                    <ImageEditor
                        title={imageUpload.title}
                        src={imageUpload.src}
                        preview={imageUpload.preview}
                        setPreview={imageUpload.setPreview}
                        file={imageUpload.imageFile}
                        setFile={imageUpload.setImageFile}
                        openEditor={imageUpload.openEditor}
                        setOpenEditor={imageUpload.setOpenEditor}
                        handleChange={handleChange}
                        scale={15}
                    />
                </AddImage>
                <AddName>
                    <TextField
                        id="outlined-basic"
                        label="Nome"
                        variant="outlined"
                        name="name"
                        className={classes.name}
                        value={name}
                        type="text"
                        onChange={handleChange}
                        placeholder=""
                        maxLength="80"
                    />
                </AddName>
                <AddDeparmentManager>
                    <Autocomplete
                        {...defaultPropsDepartmentUsers}
                        id="departmetUsers"
                        autoSelect
                        renderInput={(params) => <TextField {...params} label="Gestor"
                                                            variant="outlined"/>}
                    />
                </AddDeparmentManager>
                <AddDeparment>
                    <Autocomplete
                        {...defaultPropsDepartments}
                        id="departments"
                        autoSelect
                        renderInput={(params) => <TextField {...params} label="Responsável"
                                                            variant="outlined"/>}
                    />
                </AddDeparment>
                <AddPurpose>
                    <TextField
                        id="outlined-basic" 
                        label="Propósito"
                        variant="outlined"
                        name="purpose"
                        multiline
                        className={classes.purpose}
                        value={purpose}
                        type="text"
                        onChange={handleChange}
                        placeholder=""
                        inputProps={{ maxLength: 448 }}
                    />
                </AddPurpose>
                <ButtonContainer
                    style={{
                        display: 'flex',
                    }}  
                >
                    {props.department.active === "Ativo" ? (
                        <CustomButton
                            type="submit"
                            deleteButton="true"
                            onClick={actionUpdateTaskButton}
                        >
                            Desativar setor
                        </CustomButton>
                    ) : (
                        <CustomButton
                            type="submit"
                            addButton="true"
                            onClick={actionUpdateTaskButton}
                        >
                            Ativar setor
                        </CustomButton>
                    )}
                    <CustomButton
                        type="submit"
                        editButton="false"
                        onClick={handleEdit}
                        style={{ marginLeft: '10px' }}
                    >
                        Editar
                    </CustomButton>
                </ButtonContainer>
            </AddDepartmentModal>
            {popup ? (
                <PopUp
                    title={popupTitle}
                    string={popupText}
                    success={success}
                    setNewFatherContext={setNewFatherContext}
                />
            ) : null}
            {buttonPopUp.popUp && (
                <PopUp 
                    title={buttonPopUp.popUpTitle} 
                    string={buttonPopUp.popUpText}
                    success={buttonPopUp.success} 
                    acceptable={buttonPopUp.acceptable}
                    acceptFunction={updateStatus} 
                    handleClose={handleClose}
                />
            )}
        </div>
    );
}