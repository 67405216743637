import React, { useContext, useEffect, useState } from 'react';

/* Styles */
import { 
    AlignmentAverage1, 
    AlignmentAverage2, 
    AlignmentAverage3, 
    AlignmentTitle, 
    AlingmentData, 
    Data, 
    DateStyle,
    GraphAlignment, 
    GraphNPS, 
    GraphTask, 
    Informations, 
    NPSAverage1, 
    NPSAverage2, 
    NPSAverage3, 
    NpsData, 
    NPSTitle, 
    TaskAverage1, 
    TaskAverage2, 
    TaskAverage3, 
    TaskData, 
    TaskTitle, 
    TitleData
} from "./data-tab.styles";

/* API */
import {
    getLastNpsAvgFromUser,
} from '../../../../api/ApiNps';
import { getTaskScoresFromUser, getUserMonthAvg } from '../../../../api/ApiUser';
import { getAnnualIpaCompanyAlignments, getAnnualIpaCompanyTasks, getLastNpsAvgFromCompany } from '../../../../api/ApiCompany';
import { getAnnualIpaDepartmentsAlignments, getAnnualIpaDepartmentsTasks, getLastNpsAvgFromDepartment } from '../../../../api/ApiDepartment';

/* Material-UI */
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

/* Contexts */
import { authContext } from '../../../../contexts/AuthContext';

/* Components */
import CustomRadialBarChart from '../../../../components/radial-bar-chart/radial-bar-chart.component';
import RenderComponent from '../../../../components/render-component/render-component.component';

export default function DataTab({ selectedUser }) {
    const { auth } = useContext(authContext);
    
    const [loadingData1, setLoadingData1] = useState(true);
    const [loadingData2, setLoadingData2] = useState(true);
    const [loadingData3, setLoadingData3] = useState(true);
    

    const [userNpsAvg, setUserNpsAvg] = useState(0);
    const [departmentNpsAvg, setDepartmentNpsAvg] = useState(0);
    const [companyNpsAvg, setCompanyNpsAvg] = useState(0);
    const [dataChart, setDataChart] = useState({});
    const [monthlyData, setMonthlyData] = useState([
        {name: 'Janeiro', englishName: 'January'},
        {name: 'Fevereiro', englishName: 'February'},
        {name: 'Março', englishName: 'March'},
        {name: 'Abril', englishName: 'April'},
        {name: 'Maio', englishName: 'May'},
        {name: 'Junho', englishName: 'June'},
        {name: 'Julho', englishName: 'July'},
        {name: 'Agosto', englishName: 'August'},
        {name: 'Setembro', englishName: 'September'},
        {name: 'Outubro', englishName: 'October'},
        {name: 'Novembro', englishName: 'November'},
        {name: 'Dezembro', englishName: 'December'}
    ]);
    const [selectedMonth, setSelectedMonth] = useState({name:monthlyData[new Date().getMonth()].name,position:new Date().getMonth()});
    const currYear = new Date().getFullYear();

    useEffect(() => {
        setLoadingData1(true);
        const callApiGetTaskScoresFromUser = async () => {
            const response = await getTaskScoresFromUser(
                auth,
                currYear,
                auth.data.user.companyId,
                selectedUser.allocation.departmentId,
                selectedUser.userId,
            );
    
            const { message, payload } = await response.json();
    
            if (response.status !== 200) throw Error(message);
            if(Object.keys(payload[0].data).length > 0) {
                setMonthlyData(
                    monthlyData.map((curr) => {
                        if(payload[0].data[currYear][curr.englishName]) {
                            curr.avgUserTasks = payload[0].data[currYear][curr.englishName][0].grade;
                        } else {
                            curr.avgUserTasks = null;
                        }
                        return curr;
                    })
                );
            }
        };

        const callApiGetUserMonthAvg = async () => {
            const response = await getUserMonthAvg(
                auth,
                currYear,
                auth.data.user.companyId,
                selectedUser.userId,
            );
    
            const { message, payload } = await response.json();
    
            if (response.status !== 200) throw Error(message);
            
            if(Object.keys(payload).length > 0) {
                setMonthlyData(
                    monthlyData.map((curr) => {
                        const monthData = payload.find(currPayload => curr.englishName === currPayload.month);
                        if(monthData) {
                            curr.avgUserAlignments = monthData.longScale
                        } else {
                            curr.avgUserAlignments = null;
                        }
                        return curr;
                    })
                )
            };
        }

        const callApiGetLastNpsAvgFromUser = async () => {
            const response = await getLastNpsAvgFromUser(
                auth,
                auth.data.user.companyId,
                selectedUser.userId,
            );
    
            const { message, payload } = await response.json();
    
            if (response.status !== 200) throw Error(message);
            setUserNpsAvg(payload[0]?.avg);
        };

        Promise.all([
            callApiGetTaskScoresFromUser(),
            callApiGetUserMonthAvg(),
            callApiGetLastNpsAvgFromUser()
        ])
        .then(() => {
            setLoadingData1(false);
        })
        .catch((error) => {
            console.log(error);
            setLoadingData1(false);
        });
    }, [auth, selectedUser]);

    useEffect(() => {
        setLoadingData2(true);
        const callApiGetAnnualIpaDepartmentsTasks = async () => {
            const response = await getAnnualIpaDepartmentsTasks(
                auth,
                currYear,
                auth.data.user.companyId,
                selectedUser.allocation.departmentId,
            );
    
            const { message, payload } = await response.json();
    
            if (response.status !== 200) throw Error(message);
            setMonthlyData(
                monthlyData.map((curr) => {
                    if(payload[0][currYear][curr.englishName])
                    {
                        curr.avgDepartmentTasks = payload[0][currYear][curr.englishName][0].grade
                    } else {
                        curr.avgDepartmentTasks = null;
                    }
                    return curr;
                })
            );
        };

        const callApiGetAnnualIpaDepartmentsAlignments = async () => {
            const response = await getAnnualIpaDepartmentsAlignments(
                auth,
                currYear,
                auth.data.user.companyId,
                selectedUser.allocation.departmentId,
            );
    
            const { message, payload } = await response.json();
    
            if (response.status !== 200) throw Error(message);
            setMonthlyData(
                monthlyData.map((curr) => {
                    if(payload[0][currYear][curr.englishName])
                    {
                        curr.avgDepartmentAlignments = payload[0][currYear][curr.englishName][0].grade
                    } else {
                        curr.avgDepartmentAlignments = null;
                    }
                    return curr;
                })
            );
        };

        const callApiGetLastNpsAvgFromDepartment = async () => {
            const response = await getLastNpsAvgFromDepartment(
                auth,
                currYear,
                auth.data.user.companyId,
                selectedUser.allocation.departmentId,
            );
            
            const { message, payload } = await response.json();
            if (response.status !== 200) throw Error(message);
            setDepartmentNpsAvg(payload[0]?.avg);
        };
    
        Promise.all([
            callApiGetAnnualIpaDepartmentsTasks(),
            callApiGetAnnualIpaDepartmentsAlignments(),
            callApiGetLastNpsAvgFromDepartment(),
        ])
        .then(() => {
            setLoadingData2(false);
        })
        .catch((error) => {
            console.log(error);
            setLoadingData2(false);
        });
    },[auth, selectedUser.allocation.departmentId])

    useEffect(() => {
        setLoadingData3(true);
        const callApiGetAnnualIpaCompanyTasks = async () => {
            const response = await getAnnualIpaCompanyTasks(
                auth,
                currYear,
                auth.data.user.companyId,
            );
    
            const { message, payload } = await response.json();
    
            if (response.status !== 200) throw Error(message);
            setMonthlyData(
                monthlyData.map((curr) => {
                    if(payload[0].data[currYear][curr.englishName])
                    {
                        curr.avgCompanyTasks = payload[0].data[currYear][curr.englishName][0].grade
                    }
                    return curr;
                })
            );
        };

        const callApiGetAnnualIpaCompanyAlignments = async () => {
            const response = await getAnnualIpaCompanyAlignments(
                auth,
                currYear,
                auth.data.user.companyId,
            );
    
            const { message, payload } = await response.json();
    
            if (response.status !== 200) throw Error(message);
            setMonthlyData(
                monthlyData.map((curr) => {
                    if(payload[0].data[currYear][curr.englishName])
                    {
                        curr.avgCompanyAlignments = payload[0].data[currYear][curr.englishName][0].grade
                    }
                    return curr;
                })
            );
        };

        const callApiGetLastNpsAvgFromCompany = async () => {
            const response = await getLastNpsAvgFromCompany(
                auth,
                currYear,
                auth.data.user.companyId,
            );
    
            const { message, payload } = await response.json();
    
            if (response.status !== 200) throw Error(message);
            setCompanyNpsAvg(payload[0]?.avg);
        };
    
        Promise.all([
            callApiGetAnnualIpaCompanyTasks(),
            callApiGetAnnualIpaCompanyAlignments(),
            callApiGetLastNpsAvgFromCompany(),
        ])
        .then(() => {
            setLoadingData3(false);
        })
        .catch((error) => {
            console.log(error);
            setLoadingData3(false);
        });
    },[auth])

    const changeMonth = (pace) => {
        let pos
        let mes = new Date().getMonth()
        if(pace > 0){
            if(selectedMonth.position<mes)
            {
                pos = selectedMonth.position += 1
                setSelectedMonth({name:monthlyData[pos].name,position:pos});
            }
        }else if(pace < 0){
            if(selectedMonth.position>0)
            {
                pos = selectedMonth.position -= 1
                setSelectedMonth({name:monthlyData[pos].name,position:pos});
            }
        }
    }

    useEffect(() => {
            setDataChart({
                tasks: [
                    { 
                        name: 'Pessoal', 
                        value: monthlyData[selectedMonth?.position].avgUserTasks || 0, 
                        fill: Math.sign(monthlyData[selectedMonth?.position].avgUserTasks) === 1 ? '#ff7517' : '#ff3a17'
                    },
                    { 
                        name: 'Empresa', 
                        value: monthlyData[selectedMonth?.position].avgCompanyTasks || 0, 
                        fill: Math.sign(monthlyData[selectedMonth?.position].avgCompanyTasks) === 1 ? '#3E3939': '#ff3a17'
                    },
                ],
                alignments: [
                    { 
                        name: 'Pessoal', 
                        value: monthlyData[selectedMonth?.position].avgUserAlignments || 0, 
                        fill: Math.sign(monthlyData[selectedMonth?.position].avgUserAlignments) === 1 ? '#ff7517': '#ff3a17'
                    },
                    { 
                        name: 'Empresa', 
                        value: monthlyData[selectedMonth?.position].avgCompanyAlignments || 0, 
                        fill: Math.sign(monthlyData[selectedMonth?.position].avgCompanyAlignments) === 1 ? '#3E3939' : '#ff3a17'
                    },
                ],
                nps: [
                    { 
                        name: 'Pessoal', 
                        value: userNpsAvg || 0, 
                        fill: Math.sign(userNpsAvg) === 1 ? '#ff7517': '#ff3a17' 
                    },
                    { 
                        name: 'Empresa', 
                        value: companyNpsAvg || 0, 
                        fill: Math.sign(companyNpsAvg) === 1 ? '#3E3939' : '#ff3a17'
                    },
                ]
            });
    }, [monthlyData, selectedMonth]);

    const loadingData = loadingData1 || loadingData2 || loadingData3;
    
    return (
        <>
            {loadingData ? <RenderComponent/> : (
                <Data>
                    <TitleData>Dados</TitleData>
                    <DateStyle>
                        <ArrowBackIcon
                            onClick={() => changeMonth(-1)}
                            style={{
                                marginRight: '20px',
                                fontSize: '26px',
                                cursor: 'pointer',
                            }}
                            />

                            <span
                                style={{
                                    display:'grid',
                                    fontFamily: 'Inter',
                                    justifyContent:'center',
                                    fontSize: '16px',
                                    fontWeight: 100,
                                    width:'110px',                                  
                                    alignSelf: 'center',
                                }}
                                >
                                {selectedMonth.name + ' ' + currYear}
                            </span>

                        <ArrowForwardIcon
                            onClick={() => changeMonth(1)}
                            style={{
                                marginLeft: '20px',
                                fontSize: '26px',
                                cursor: 'pointer',
                            }}
                            />
                    </DateStyle>
                    <GraphTask>
                        <CustomRadialBarChart
                            data={dataChart.tasks}
                            width={400}
                            height={400}
                            innerRadius={50}
                            outerRadius={110}
                            barSize={20}
                            barRadius="40%"
                            />
                    </GraphTask>
                    <GraphAlignment>
                        {' '}
                        <CustomRadialBarChart
                            data={dataChart.alignments}
                            width={400}
                            height={400}
                            innerRadius={50}
                            outerRadius={110}
                            barSize={20}
                            barRadius="40%"
                            />
                    </GraphAlignment>
                    <GraphNPS>
                        {' '}
                        <CustomRadialBarChart
                            data={dataChart.nps}
                            width={400}
                            height={400}
                            innerRadius={50}
                            outerRadius={110}
                            barSize={20}
                            barRadius="40%"
                            />
                    </GraphNPS>
                    <Informations>
                        <TaskData>
                            <TaskTitle>Tarefas</TaskTitle>
                            <TaskAverage1>
                                Pessoal{' '}
                                <span
                                    style={{
                                        fontFamily: 'Abril Fatface',
                                        fontSize: '46px',
                                        fontWeight: 700,
                                    }}
                                    >
                                    {monthlyData[selectedMonth.position].avgUserTasks ? monthlyData[selectedMonth.position].avgUserTasks: '-'}
                                </span>
                            </TaskAverage1>
                            <TaskAverage2>
                                Setor
                                <span
                                    style={{
                                        fontFamily: 'Abril Fatface',
                                        fontSize: '46px',
                                        fontWeight: 700,
                                    }}
                                    >
                                    {monthlyData[selectedMonth.position].avgDepartmentTasks ? monthlyData[selectedMonth.position].avgDepartmentTasks: '-'}
                                </span>
                            </TaskAverage2>
                            <TaskAverage3>
                                Empresa
                                <span
                                    style={{
                                        fontFamily: 'Abril Fatface',
                                        fontSize: '46px',
                                        fontWeight: 700,
                                    }}
                                    >
                                    {monthlyData[selectedMonth.position].avgCompanyTasks ? monthlyData[selectedMonth.position].avgCompanyTasks: '-'}
                                </span>
                            </TaskAverage3>
                        </TaskData>
                        <AlingmentData>
                            <AlignmentTitle>
                                Alinhamentos
                            </AlignmentTitle>
                            <AlignmentAverage1>
                                Pessoal
                                <span
                                    style={{
                                        fontFamily: 'Abril Fatface',
                                        fontSize: '46px',
                                        fontWeight: 700,
                                    }}
                                    >
                                    {monthlyData[selectedMonth.position].avgUserAlignments ? monthlyData[selectedMonth.position].avgUserAlignments: '-'}
                                </span>
                            </AlignmentAverage1>
                            <AlignmentAverage2>
                                Setor
                                <span
                                    style={{
                                        display:'grid',
                                        width:'80%',
                                        fontFamily: 'Abril Fatface',
                                        fontSize: '46px',
                                        fontWeight: 700,
                                    }}
                                    >
                                    {monthlyData[selectedMonth.position].avgDepartmentAlignments ? monthlyData[selectedMonth.position].avgDepartmentAlignments: '-'}
                                </span>
                            </AlignmentAverage2>
                            <AlignmentAverage3>
                                Empresa
                                <span
                                    style={{
                                        fontFamily: 'Abril Fatface',
                                        fontSize: '46px',
                                        fontWeight: 700,
                                    }}
                                    >
                                    {monthlyData[selectedMonth.position].avgCompanyAlignments ? monthlyData[selectedMonth.position].avgCompanyAlignments: '-'}
                                </span>
                            </AlignmentAverage3>
                        </AlingmentData>
                        <NpsData>
                            <NPSTitle>NPS</NPSTitle>
                            <NPSAverage1>
                                Pessoal
                                <span
                                    style={{
                                        fontFamily: 'Abril Fatface',
                                        fontSize: '46px',
                                        fontWeight: 700,
                                    }}
                                    >
                                {userNpsAvg ? userNpsAvg : '-'}
                                </span>
                            </NPSAverage1>
                            <NPSAverage2>
                                Setor
                                <span
                                    style={{
                                        fontFamily: 'Abril Fatface',
                                        fontSize: '46px',
                                        fontWeight: 700,
                                    }}
                                    >
                                {departmentNpsAvg ? departmentNpsAvg : '-'}
                                </span>
                            </NPSAverage2>
                            <NPSAverage3>
                                Empresa
                                <span
                                    style={{
                                        fontFamily: 'Abril Fatface',
                                        fontSize: '46px',
                                        fontWeight: 700,
                                    }}
                                    >
                                {companyNpsAvg ? companyNpsAvg : '-'}
                                </span>
                            </NPSAverage3>
                        </NpsData>
                    </Informations>
                </Data>
            )}            
        </>
    );
}