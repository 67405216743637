import styled from 'styled-components/macro';

export const DashboardContainer = styled.div`
    display: grid;
    margin: 120px auto;
    border-radius: 12px;
    padding: 12px;
    width: 95%;
    grid-template-areas:
        'topContainer'
        'bottomContainer';
    max-height: 300px;
`;

export const DashboardContainerInside1 = styled.div`
    display: grid;
    grid-area: topContainer;
    grid-template-columns: 50% 50%;
    @media screen and (max-width: 1400px) {
        grid-template-columns: 100%;
    }
`;

export const DashboardContainerInside2 = styled.div`
    display: grid;
    grid-area: bottomContainer;
    grid-template-areas: 'leftContainer rightContainer';
    grid-template-columns: 22% 78%;
    @media screen and (max-width: 1530px) {
        grid-template-columns: 100%;
        grid-template-areas:
            'leftContainer'
            'rightContainer';
    }
`;

export const DepartmentContainer = styled.div`
    display: grid;
    grid-area: leftContainer;
`;

export const DataContainer = styled.div`
    display: grid;
    grid-area: rightContainer;
`;

export const TextButton = styled.div`
    display: grid;
    grid-area: button;
`;

export const DrawerAdjustment = styled.div`
    padding-left: 72px;
    @media screen and (max-width: 899px) {
        padding-left: 0px;
    }
`;

//Esse Spacer é utilizado no error-page.component

export const Spacer = styled.div`
    height: 12vh;
    width: 12vh;
    @media screen and (max-height: 600px) {
        height: 70px;
    }
`;
