import { Box, Button, Modal, Slider, makeStyles } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';

/* Icons */
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import LogoPerfil from '../../assets/logo-perfil.png';
import { ContainerImage, UserImage } from './image-editor.styles';

const useStyles = makeStyles((theme) => ({
    input: {
        display: 'none',
    },
    modal: {
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
    },
    boxFather: {
        display: 'flex',
        width: '300px',
        height: '300px',
        padding: '20px',
        borderRadius: '8px',
        border: '1px solid rgba(255, 117, 23, 100%)',
        backgroundColor: '#fff',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    boxButtons: {},

    buttonSave: {
        padding: '12px',
        margin: '12px',
        borderRadius: '12px',
        color: '#fff',
        fontWeight: 'bold',
        backgroundColor: 'rgba(255, 117, 23, 100%)',
        transition: '0.5s',
        '&:hover': {
            backgroundColor: '#fff',
            color: 'rgba(255, 117, 23, 100%)',
            border: '1px solid rgba(255, 117, 23, 100%)',
            filter: 'drop-shadow(2px 2px 6px #3E3939)',
        },
    },
    buttonCancel: {
        padding: '12px',
        margin: '12px',
        borderRadius: '12px',
        color: '#fff',
        fontWeight: 'bold',
        backgroundColor: 'rgba(255, 117, 23, 100%)',
        transition: '0.5s',
        '&:hover': {
            backgroundColor: '#fff',
            color: 'rgba(255, 117, 23, 100%)',
            border: '1px solid rgba(255, 117, 23, 100%)',
            filter: 'drop-shadow(2px 2px 6px #3E3939)',
        },
    },
    slider: {
        color: 'rgba(255, 117, 23, 100%)',
        fontSize: '50px',
    },
}));

const EditorModal = ({
    src,
    openEditor,
    setOpenEditor,
    setPreview,
    file,
    setFile,
    scale
}) => {
    const classes = useStyles();
    const editor = useRef(null);
    const [slideValue, setSlideValue] = useState(10);

    const handleSave = async () => {
        if (editor) {
            const dataUrl = editor.current.getImage().toDataURL();
            const result = await fetch(dataUrl);
            const blob = await result.blob();
            let newFile = new File([blob], file?.name || 'edit-image', {
                type: file?.type || blob.type,
            });
            setFile(newFile);
            setPreview(URL.createObjectURL(blob));
            setOpenEditor(false);
        }
    };

    const handleChange = (event, newValue) => {
        setSlideValue(newValue);
    };
    
    return (
        <Modal className={classes.modal} open={openEditor}>
            <Box className={classes.boxFather}>
                <AvatarEditor
                    ref={editor}
                    image={src}
                    width={150}
                    height={150}
                    border={10}
                    borderRadius={100}
                    color={[255, 255, 255, 0.6]}
                    scale={scale ? slideValue / scale : slideValue / 10}
                    rotate={0}
                />
                <Slider
                    min={10}
                    max={80}
                    size="medium"
                    defaultValue={slideValue}
                    value={slideValue}
                    onChange={handleChange}
                    className={classes.slider}
                />
                <Box className={classes.boxButtons}>
                    <Button
                        size="small"
                        className={classes.buttonCancel}
                        onClick={(e) => setOpenEditor(false)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        className={classes.buttonSave}
                        size="small"
                        variant="contained"
                        onClick={handleSave}
                    >
                        Salvar
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default function ImageEditor({
    title,
    src,
    preview,
    setPreview,
    file,
    setFile,
    openEditor,
    setOpenEditor,
    handleChange,
    scale
}) {
    const classes = useStyles();

    const handleOpenEditor = (e) => {
        e.preventDefault();

        if (preview) {
            setOpenEditor(true);
        }
    };

    return (
        <ContainerImage>
            <div style={{ marginRight: '50px' }}>
                <UserImage
                    src={preview || LogoPerfil}
                    onClick={handleOpenEditor}
                />
                <EditorModal
                    src={src}
                    openEditor={openEditor}
                    setOpenEditor={setOpenEditor}
                    setPreview={setPreview}
                    file={file}
                    setFile={setFile}
                    scale={scale}
                />
            </div>
            <div style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
                <input
                    name="file"
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    onChange={handleChange}
                    multiple
                    type="file"
                />
                <label htmlFor="contained-button-file">
                    <Button
                        style={{ backgroundColor: '#FF7517' }}
                        variant="contained"
                        color="primary"
                        component="span"
                    >
                        <PhotoCamera style={{ marginRight: '10px' }} />
                        {title}
                    </Button>
                </label>
            </div>
        </ContainerImage>
    );
}
