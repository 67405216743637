import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
    ForgetPassword,
    TitleLogin,
    LoginForm,
    LoginButton,
} from './sign-in-form.styles';
import ForgetPasswordPage from '../forgetPassword/forget-password.component';
import React, { useContext, useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

/* Components */
import PopUp from '../../../components/popup/popup.component';
import RenderPage from '../../../components/render-page/render-page.component';

/* Api */
import { login, forgotPassword, getOneUser } from '../../../api/ApiUser';
import { authContext } from '../../../contexts/AuthContext';
import { findAllModules } from '../../../api/ApiPemissions';

const useStyles = makeStyles((theme) => ({
    loginButton: {
        boxShadow: 'none',
        textTransform: 'none',
        fontFamily: 'Abril Fatface',
        fontSize: '20px',
        fontWeight: '700px',
        borderRadius: '12px',
        width: '220px',
        backgroundColor: 'white',
    },

    textFieldLogin: {
        width: '220px',
        borderRadius: '6px',
        backgroundColor: 'white',
    },

    textFieldPassword: {
        width: '220px',
        borderRadius: '6px',
        backgroundColor: 'white',
        marginTop: '30px',
    },
}));

export default function SignInForm(props) {
    const { setAuthData, auth } = useContext(authContext);
    const [showForgetPassword, setShowForgetPassword] = useState(false);
    const [logged, setLogged] = React.useState(true);
    const [fetchOpen, setFetchOpen] = React.useState(true);
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [popup, setPopup] = React.useState(false);
    const [popupText, setPopupText] = React.useState('');
    const [popupTitle, setPopupTitle] = React.useState('');
    const [success, setSuccess] = React.useState(1);
    const [value, setValue] = React.useState({
        password: '',
    });

    let history = useHistory();

    const [values, setValues] = useState({
        showPassword: false,
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleForgetPasswordClick = () => {
        setShowForgetPassword(true);
    };

    const handleBackToSignIn = () => {
        setShowForgetPassword(false);
    };

    const classes = useStyles();

    useEffect(() => {
        const callApiVerifyToken = async () => {   
            let auth = { loading: false, data: JSON.parse(window.localStorage.getItem('authData'))};

            if(!auth.data) {
                setLogged(false)
                setFetchOpen(false);
                return;
            } 

            const response = await getOneUser(auth, auth.data.user.userId, auth.data.user.companyId);
            
            if(response.status === 401) {
                setLogged(false)
                setFetchOpen(false);
                return;
            }

            if(auth.data.system) {
                history.replace('/ipa/dashboard');
            } else {
                history.replace('/dashboard');
            }
        }

        callApiVerifyToken();       
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            let user = await login(email, password);

            if (user.error) {
                setPopupTitle('Erro');
                if (user.message === 'Esse usuário está desativado!')
                    setPopupText(
                        'Não é possível acessar o sistema com este usuário, ele está desativado.'
                    );
                else setPopupText('Verifique seu email e senha');
                setPopup(true);
                setSuccess(1);
            } else if (user.token) {
                let auxUser = {
                    permissionRoleId: user.data.data.permissionRole,
                    ...user.data.data,
                };
                delete auxUser.permissionRole;

                let auxData = {
                    token: user.token,
                    user: auxUser,
                    system: props.systemType,
                };

                const callFindModulePermissions = async () => {
                    const response = await findAllModules(
                        auxData,
                        auxData.user.permissionRoleId
                    );
                    const body = await response.json();

                    return body.payload;
                };

                callFindModulePermissions()
                    .then((res) => {
                        setAuthData({
                            token: auxData.token,
                            user: auxData.user,
                            system: auxData.system,
                            permissions: res,
                        });

                        history.replace('/ipa/dashboard');
                    })
                    .catch((err) => console.log(err));
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <RenderPage open={fetchOpen} />
            {!logged ? (
                <>
                    {showForgetPassword ? (
                        <ForgetPasswordPage onBack={handleBackToSignIn} />
                    ) : (
                        <>
                            <TitleLogin>
                                Bem Vindo
                                <br />
                                <span style={{ fontSize: '24px' }}>
                                    Drakkariano
                                </span>
                                <span style={{ color: '#D73B30' }}>.</span>
                            </TitleLogin>
                            <form onSubmit={handleSubmit}>
                                <LoginForm>
                                    <TextField
                                        className={classes.textFieldLogin}
                                        id="email"
                                        type="email"
                                        variant="outlined"
                                        placeholder="E-mail"
                                        onChange={(event) =>
                                            setEmail(event.target.value)
                                        }
                                        required
                                    />
                                    <FormControl
                                        className={clsx(
                                            classes.textFieldPassword
                                        )}
                                        variant="outlined"
                                    >
                                        <InputLabel>Senha</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={
                                                values.showPassword
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            value={values.password}
                                            defaultValue=""
                                            onChange={(event) => {
                                                setPassword(event.target.value);
                                                setValue({
                                                    ...value,
                                                    [props]: event.target.value,
                                                });
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                            handleClickShowPassword
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPassword
                                                        }
                                                        edge="end"
                                                    >
                                                        {values.showPassword ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            labelWidth={70}
                                        />
                                    </FormControl>
                                    <ForgetPassword
                                        onClick={handleForgetPasswordClick}
                                    >
                                        Esqueceu a senha?
                                    </ForgetPassword>
                                </LoginForm>
                                <LoginButton>
                                    <Button
                                        className={classes.loginButton}
                                        type="submit"
                                        submitButton
                                        variant="contained"
                                    >
                                        Entrar
                                    </Button>
                                </LoginButton>
                            </form>
                            {popup ? (
                                <PopUp
                                    title={popupTitle}
                                    string={popupText}
                                    success={success}
                                />
                            ) : null}
                        </>
                    )}
                </>
            ) : null}
        </>
    );
}
