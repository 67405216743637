import React, {useContext, useEffect, useState} from 'react';

/* Components */
import CategoryBarComponent from '../../../../components/category-bar/category-bar.component';
import RenderPage from "../../../../components/render-page/render-page.component";

/* Graph */
import {XYPlot, ArcSeries, makeVisFlexible} from 'react-vis';

/* Icons */
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';

/* Style */
import {
    LeftRightSelect,
    SpanMonth,
    GraphDiv,
    LegendColor,
    MyPaper,
    DashboardStyle,
    ListElement,
    StyledSpan
} from './if-dashboard-graph.styles';

/* API */
import {getCompanyAnnualIF, getUserAnnualIF} from "../../../../api/ApiIF";
import {getOneCompany} from "../../../../api/ApiCompany";

/* Context */
import {authContext} from "../../../../contexts/AuthContext";

/* Functions */
import {getColor, getSmoothColor, getCategory, circularBarBase, circularBarValue} from "../../../../services/dashboardFunctions";

/* Utils */
import {ClearDataFromApiCompanyIF, ClearDataFromApiUserIF} from "../../../../utils/clearDataFromApi/clearDataFromIF";

const FlexibleXYPlot = makeVisFlexible(XYPlot);

const MIN_VAL = -100, MAX_VAL = 100;

const specificData = [
    {id: 0,type: "Geral"},
    {id: 1, type: "Profissional"},
    {id: 2, type: "Pessoal"},
    {id: 3, type: "Financeiro"},
    {id: 4, type: "Motivacional"},
    {id: 5, type: "Saúde"},
    {id: 6, type: "Ambiente"}
];

export default function DashboardPage() {
    const [employee, setEmployee] = useState(0);
    const [company, setCompany] = useState(0);
    const ptMonths = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

    let actualYear = new Date().toISOString();
    let actualTempMonth;
    actualYear = actualYear.split("-");

    if(actualYear[1] === "01") {
        actualTempMonth = 11; //last month
        actualYear=(parseInt(actualYear[0]) - 1);
    } else {
        actualTempMonth = parseInt(actualYear[1]) - 2; //last month
        actualYear = actualYear[0];
    }


    const [year, setYear] = useState(actualYear);
    const [month, setMonth] = useState(ptMonths[actualTempMonth]);

    const [category, setCategory] = useState("Geral");
    const [monthData, setMonthData] = useState([]);

    const [actualCompanyName, setActualCompanyName] = useState({});

    const {auth} = useContext(authContext);

    const [age] = useState(auth.data.user.age);
    let nameTemp = auth.data.user.name.split(" ");
    const [name] = useState(nameTemp[0]);

    const [fetchOpen, setFetchOpen] = useState(true);

    useEffect(() => {
        const callApiIFUser = async (year) => {
            const response = await getUserAnnualIF(auth, year, null);

            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            let aux = body.payload;
            let temp = ClearDataFromApiUserIF(aux, 1)
            let newData = temp.averagePerMonth;
            let userAverage = temp.userAverage;

            return {newData, userAverage}
        };

        const callApiIFCompany = async (year) => {
            const response = await getCompanyAnnualIF(auth, year);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            let averagePerMonth = await body.payload[0][year];

            return ClearDataFromApiCompanyIF(averagePerMonth);
        };

        const getData = (res, companyRes) => {
            let tempData =  [];
            let circularTempData =  [];
            let actualYear = new Date().toISOString();
            let actualMonth;
            actualYear = actualYear.split("-");

            if(actualYear[1] === "01") {
                actualMonth = 11; //last month
                actualYear=(parseInt(actualYear[0]) - 1);
            } else {
                actualMonth = parseInt(actualYear[1]) - 2; //last month
                actualYear = actualYear[0];
            }

            for(let i=0;  i < 12; i++){
                tempData.push({month: ptMonths[i],
                    employee: {
                        "Geral": res.userAverage[i],
                        "Profissional": res.newData[i].professional,
                        "Pessoal": res.newData[i].personal,
                        "Financeiro": res.newData[i].financial,
                        "Motivacional": res.newData[i].motivational,
                        "Saude": res.newData[i].health,
                        "Ambiente": res.newData[i].environment
                    },
                    company: {
                        "Geral": parseInt(companyRes.generalAverage[i]),
                        "Profissional": parseInt(companyRes.averagePerMonth[i].professional),
                        "Pessoal": parseInt(companyRes.averagePerMonth[i].personal),
                        "Financeiro": parseInt(companyRes.averagePerMonth[i].financial),
                        "Motivacional": parseInt(companyRes.averagePerMonth[i].motivational),
                        "Saude": parseInt(companyRes.averagePerMonth[i].health),
                        "Ambiente": parseInt(companyRes.averagePerMonth[i].environment)
                    },
                    year: actualYear})
            }

            for(let i=0;  i < 12; i++){
                circularTempData[i] = tempData[actualMonth];
                if(actualMonth === 11)
                    actualMonth = 0;
                else
                    actualMonth++;
            }

            setEmployee(circularTempData[0]["employee"]["Geral"]);
            setCompany(parseInt(circularTempData[0]["company"]["Geral"]));

            return circularTempData;
        };

        const callApiFindOneCompany = async () => {
            const response = await getOneCompany(auth, auth.data.user.companyId);

            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body.payload[0];
        };

        callApiIFUser(year)
            .then(res => {
                callApiIFCompany(year)
                    .then(companyRes => {
                        let temp = getData(res, companyRes);
                        setMonthData(temp);
                        callApiFindOneCompany(year)
                            .then(actualCompany => {
                                let tempName = actualCompany.name.split(" ");

                                setActualCompanyName({string1: tempName[0], string2: tempName[1] ? tempName[1] : ""});
                                setFetchOpen(false);
                            })
                            .catch(err => console.log(err))

                    })
                    .catch(err => console.log(err))
            })
            .catch(err => console.log(err));

    }, []);

    const nextMonthData = () => {
        if (monthData.length > 1) {
            monthData.push(monthData.shift());
            let categoryTemp = getCategory(category);
            let obj = monthData[0];

            setMonth(obj.month);
            setYear(obj.year);
            setEmployee(obj.employee[categoryTemp]);
            setCompany(obj.company[categoryTemp]);
        }
    };

    const previousMonthData = () => {
        if (monthData.length > 1) {
            monthData.unshift(monthData.pop());
            let categoryTemp = getCategory(category);
            let obj = monthData[0];

            setMonth(obj.month);
            setYear(obj.year);
            setEmployee(obj.employee[categoryTemp]);
            setCompany(obj.company[categoryTemp]);
        }
    };

    const nextData = () => {
        if (specificData.length > 1) {
            specificData.push(specificData.shift());
            let obj = specificData[0];

            setCategory(obj.type);

            let categoryTemp = getCategory(obj.type);
            obj = monthData[0];

            setEmployee(obj.employee[categoryTemp]);
            setCompany(obj.company[categoryTemp]);
        }
    };

    const previousData = () => {
        if (specificData.length > 1) {
            specificData.unshift(specificData.pop());
            let obj = specificData[0];
            setCategory(obj.type);

            let categoryTemp = getCategory(obj.type);
            obj = monthData[0];

            setEmployee(obj.employee[categoryTemp]);
            setCompany(obj.company[categoryTemp]);

        }
    };

    return (
        <DashboardStyle>
            <LeftRightSelect>
                <IconButton aria-label="back-month" onClick={previousMonthData}>
                    <ArrowBackIcon/>
                </IconButton>
                <SpanMonth>{month + ' ' + year}</SpanMonth>
                <IconButton aria-label="forward-month" onClick={nextMonthData}>
                    <ArrowForwardIcon/>
                </IconButton>
            </LeftRightSelect>
            <GraphDiv>
                <FlexibleXYPlot
                    xDomain={[-2.7, 3]}
                    yDomain={[-3, 4]}
                    getAngle={d => d.if}
                    getAngle0={d => 0}
                    colorType="literal"
                    style={{textAlign: "center"}}
                >

                    <ArcSeries
                        animation={{
                            damping: 9,
                            stiffness: 300
                        }}
                        radiusDomain={[0, 1.5]}
                        data={[circularBarBase(1, employee, MIN_VAL, MAX_VAL),
                            circularBarValue("Individual", 1, employee, MIN_VAL, MAX_VAL),
                            circularBarBase(2, company, MIN_VAL, MAX_VAL),
                            circularBarValue("Empresa", 2, company, MIN_VAL, MAX_VAL),
                        ]}
                    >
                    </ArcSeries>
                    <ListElement>
                        <MyPaper style={{position: 'relative'}}>
                            <LegendColor isBig={true}
                                         style={{backgroundColor: getColor(employee, 0)}}>
                                <StyledSpan isBig={true}>{employee}</StyledSpan>
                            </LegendColor>
                            <StyledSpan isBig={true}>{name}</StyledSpan>
                            {age ? <StyledSpan isBig={false}>{age} anos</StyledSpan> : null}
                        </MyPaper>
                        <MyPaper style={{position: 'relative'}}>
                            <LegendColor isBig={false}
                                         style={{backgroundColor: getColor(company, 1)}}>
                                <StyledSpan isBig={false}>{isNaN(company) ? null : company} </StyledSpan>
                            </LegendColor>
                            <StyledSpan isBig={false}>{actualCompanyName.string1}</StyledSpan>
                            <StyledSpan isBig={false}>{actualCompanyName.string2}</StyledSpan>
                        </MyPaper>
                    </ListElement>
                </FlexibleXYPlot>
            </GraphDiv>

            {/*Select the the type of data (General, Professional, Health ...)*/}
            <CategoryBarComponent onClickPrevious={previousData} onClickNext={nextData}
                                  name={category} categoryList={specificData}
                                  employee={employee} company={company} getColor={getSmoothColor}/>
            {fetchOpen ?
                <RenderPage open={fetchOpen}/> : null}

        </DashboardStyle>)
}
