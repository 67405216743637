import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

/*SubPages*/
// import GraphGeneral from './ipa-graphs-general/ipa-graphs-general.component'
// import GraphTasks from './ipa-graphs-tasks/ipa-graphs-tasks.component'
import GeneralGraph from './newGeneralGraph/general-graphs-checks/general-graphs-checks';
import TaskGraph from './newTaskGraph/task-graphs-checks/task-graphs-checks';

/* Context */
import { authContext } from '../../../contexts/AuthContext';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Header from '../../../components/header/header.component';

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { findIfSubmoduleIsActive } from '../../../api/ApiPemissions';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import {
    GraphicContainer,
    Spacer,
    DrawerAdjustment,
} from './ipa-graphs.styles';

import permissions, {
    getPermission,
    setTabsOrder,
} from '../../../utils/permission';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box style={{ padding: 0 }} p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 140,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    container: {
        '@media (max-width: 600px)': {
            padding: 0,
        },
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
  })
);

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

const getModulePermissionsAndTabs = (userPermissions, permissions) => {
    let resSend = userPermissions.find(
        (elem) => elem.moduleId === permissions.IPA_SEND_ALIGNMENT
    );
    let resHistory = userPermissions.find(
        (elem) => elem.moduleId === permissions.IPA_ALIGNMENT_HISTORY
    );
    let resManage = userPermissions.find(
        (elem) => elem.moduleId === permissions.IPA_MANAGE_ALIGNMENTS
    );

  let tempTabs = {
    SEND: !!resSend.active /* !! convert 1 to true or 0 to false*/,
    HISTORY: !!resHistory.active,
    MANAGE: !!resManage.active,
  };

  setTabsOrder(tempTabs);

  return {
    tabs: tempTabs,
    modules: {
      send: resSend.active,
      history: resHistory.active,
      manage: resManage.active,
    },
  };
};

const GeneralTab = (props) => {
    return (
        <TabPanel
            value={props.value}
            index={props.SEND}
            style={{ width: '90%' }}
        >
            <GeneralGraph />
            {/* <TaskGraph /> */}
        </TabPanel>
        
    );
};

const TasksTab = (props) => {
    return (
        <TabPanel
            value={props.value}
            index={props.HISTORY}
            style={{ width: '90%' }}
        >
            <TaskGraph />
        </TabPanel>
    );
};

export default function UsersRecordsTab(props) {
  const { auth } = useContext(authContext);
  const classes = useStyles();

    const [submodules, setSubmodules] = useState({
        graphs: [],
    });

    const userPermissions = getModulePermissionsAndTabs(
        auth.data.permissions,
        permissions
    );
    const [tabs] = useState(userPermissions.tabs);
    const [isEnabledToAccess] = useState(userPermissions.modules);

  const [value, setValue] = React.useState(0);

    const outerTheme = createTheme({
        palette: {
            primary: {
                main: auth.data.system ? '#ff6922' : '#ffeb3b',
                dark: auth.data.system ? '#ff5722' : '#ffc107',
                contrastText: '#212121',
            },
        },
    });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const callFindIfSubmoduleIsActive = async (
      permissionRole,
      permissionModule,
    ) => {
      const response = await findIfSubmoduleIsActive(
        auth,
        permissionRole,
        permissionModule,
      );
      const body = await response.json();

      return body.payload;
    };

    callFindIfSubmoduleIsActive(
      auth.data.user.permissionRoleId,
      permissions.IPA_GRAPHS,
    )
      .then((resGraph) => {
        setSubmodules({
          send: resGraph,
          history: resGraph,
          manage: resGraph,
        });
      })
      .catch((err) => console.log(err));
  }, [auth]);

    return (
        <div>
            {!getPermission(auth.data.permissions, permissions.IPA_GRAPHS) ? (
                <Redirect to={permissions.IPA_DEFAULT_ROUTE} />
            ) : null}
            <Header />
            <DrawerAdjustment>
                <Container className={classes.container}>
                    <div>
                        <Spacer />
                        <MuiThemeProvider theme={outerTheme}>
                            <AppBar
                                position="static"
                                color="default"
                                style={{
                                    backgroundColor: auth.data.system
                                        ? '#fff3e0'
                                        : '#fffde7',
                                    paddingBottom: 0.2,
                                    borderLeft: '1px solid #cfd8dc',
                                    borderRight: '1px solid #cfd8dc',
                                    borderTop: '1px solid #cfd8dc',
                                }}
                            >
                                <Tabs
                                    TabIndicatorProps={{
                                        style: {
                                            height: '4px',
                                        },
                                    }}
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                >
                                    {isEnabledToAccess.send ? (
                                        <Tab
                                            style={{ fontWeight: 'bold' }}
                                            label="Geral"
                                            {...a11yProps(tabs.SEND)}
                                        />
                                    ) : null}
                                    {isEnabledToAccess.history ? (
                                        <Tab
                                            style={{ fontWeight: 'bold' }}
                                            label="Tarefas"
                                            {...a11yProps(tabs.HISTORY)}
                                        />
                                    ) : null}
                                </Tabs>
                            </AppBar>
                        </MuiThemeProvider>
                        <GraphicContainer>
                            {isEnabledToAccess.send ? (
                                <GeneralTab value={value} SEND={tabs.SEND} />
                            ) : null}

                            {isEnabledToAccess.history ? (
                                <TasksTab
                                    value={value}
                                    HISTORY={tabs.HISTORY}
                                />
                            ) : null}
                        </GraphicContainer>
                    </div>
                </Container>
            </DrawerAdjustment>
        </div>
    );
}
