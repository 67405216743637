import React, { useContext, useEffect, useState } from 'react';
import { isUndefined } from 'svgsaver/src/utils';

/* Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

/* Components */
import TableToShowValue from '../../../../../components/table-to-show-value/table-to-show-value.component';
import RenderPage from '../../../../../components/render-page/render-page.component';

/* Style */
import { StyledFilter, AlignContainer, Filters } from './history-table.styles';

/* icons */
// import {ReactComponent as PDF} from "../../assets/pdf.svg";

/* api */
import {
    getCompanyAnnualIF,
    getDepartmentAnnualIF,
    getUserAnnualIF,
} from '../../../../../api/ApiIF';
import {
    getAllActiveUsersInCompany,
    getAllActiveUsersInDepartment,
} from '../../../../../api/ApiUser';
import { getAllDepartments } from '../../../../../api/ApiDepartment';

/* Context */
import { authContext } from '../../../../../contexts/AuthContext';

/* Utils */
import { yearsUntilNow } from '../../../../../utils/initialData';

/* Functions */
import { ClearDataFromApiCompanyIF } from '../../../../../utils/clearDataFromApi/clearDataFromIF';
import { ClearDataFromApiUserIF } from '../../../../../utils/clearDataFromApi/clearDataFromIF';
import { ClearDataFromApiDepartmentIF } from '../../../../../utils/clearDataFromApi/clearDataFromIF';
import permissions, {
    getSubmodulePermission,
} from '../../../../../utils/permission';

let rowsName = [
    { name: 'Profissional' },
    { name: 'Pessoal' },
    { name: 'Financeiro' },
    { name: 'Motivacional' },
    { name: 'Saúde' },
    { name: 'Ambiente' },
    { name: 'Média Individual' },
    { name: 'Média Individual (-3 à 3)' },
    { name: 'Média da Empresa' },
    { name: 'Média da Empresa (-3 à 3)' },
];

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const today = new Date();

const optionsYear = yearsUntilNow();

const yy = today.getFullYear();

export default function HistoryTable(props) {
    const classes = useStyles();

    const { auth } = useContext(authContext);

    let stop = null;
    for (let i = 0; i < optionsYear.length; i++)
        if (optionsYear[i] === yy.toString()) {
            stop = i;
            break;
        }
    const defaultDepartment = {
        departmentId: props.department.departmentId,
        name: props.department.name,
        companyId: props.department.companyId,
    };
    const [department, setDepartment] = useState(null);
    const [year, setYear] = React.useState(optionsYear[stop]);
    const [rows, setRows] = React.useState(null);
    const [optionsUsers, setOptionsUsers] = useState([]);
    const [optionsDepartments, setOptionsDepartments] = useState([]);
    const [actualData, setActualData] = useState(`${auth.data.user.name}`);
    const [user, setUser] = React.useState({
        userId: auth.data.user.userId,
        name: auth.data.user.name,
        departmentId: auth.data.user.departmentId,
        companyId: auth.data.user.companyId,
        permissionRoleId: auth.data.user.permissionRoleId,
        active: 1,
    });
    const [loggedUser, ] = React.useState({
        userId: auth.data.user.userId,
        name: auth.data.user.name,
        departmentId: auth.data.user.departmentId,
        companyId: auth.data.user.companyId,
        permissionRoleId: auth.data.user.permissionRoleId,
        active: 1,
    });

    let tableData = {
        componentTitleStart: `Mostrando dados de `,
        componentTitleFirstInfo: `${actualData}`,
        componentTitleMiddle: ` em `,
        componentTitleSecondInfo: `${year}`,
        componentTitleSecondMiddle: '',
        componentTitleThirdInfo: '',
        tableTitle: `Aspecto ${year}`,
    };

    const createTableMonths = (data, company) => {
        const names = [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Out',
            'Nov',
            'Dez',
        ];
        let months = [];
        let len = names.length;
        let average = [
            { name: 'Profissional', value: null },
            { name: 'Pessoal', value: null },
            { name: 'Financeiro', value: null },
            { name: 'Motivacional', value: null },
            { name: 'Saúde', value: null },
            { name: 'Ambiente', value: null },
            {
                name:
                    props.radioValue === 'department'
                        ? 'Média do Setor'
                        : props.radioValue === 'company'
                        ? 'Média da Empresa'
                        : 'Média Individual',
                value: null,
            },
            { name: 'Média da Empresa', value: null },
        ];
        let monthsQt = [0, 0, 0, 0, 0, 0, 0, 0];

        for (let i = 0; i < len; i++) {
            months[i] = {
                name: `${names[i]}`,
                data: [
                    {
                        name: 'Profissional',
                        value:
                            data.averagePerMonth[i]?.professional !== null &&
                            !isNaN(data.averagePerMonth[i]?.professional)
                                ? `${data.averagePerMonth[i]?.professional}`
                                : null,
                    },
                    {
                        name: 'Pessoal',
                        value:
                            data.averagePerMonth[i]?.personal !== null &&
                            !isNaN(data.averagePerMonth[i]?.personal)
                                ? `${data.averagePerMonth[i]?.personal}`
                                : null,
                    },
                    {
                        name: 'Financeiro',
                        value:
                            data.averagePerMonth[i]?.financial !== null &&
                            !isNaN(data.averagePerMonth[i]?.financial)
                                ? `${data.averagePerMonth[i]?.financial}`
                                : null,
                    },
                    {
                        name: 'Motivacional',
                        value:
                            data.averagePerMonth[i]?.motivational !== null &&
                            !isNaN(data.averagePerMonth[i]?.motivational)
                                ? `${data.averagePerMonth[i]?.motivational}`
                                : null,
                    },
                    {
                        name: 'Saúde',
                        value:
                            data.averagePerMonth[i]?.health !== null &&
                            !isNaN(data.averagePerMonth[i]?.health)
                                ? `${data.averagePerMonth[i]?.health}`
                                : null,
                    },
                    {
                        name: 'Ambiente',
                        value:
                            data.averagePerMonth[i]?.motivational !== null &&
                            !isNaN(data.averagePerMonth[i]?.environment)
                                ? `${data.averagePerMonth[i]?.environment}`
                                : null,
                    },
                    {
                        name:
                            props.radioValue === 'department'
                                ? 'Média do Setor'
                                : props.radioValue === 'company'
                                ? 'Média da Empresa'
                                : 'Média Individual',
                        value:
                            data.generalAverage[i] !== null &&
                            !isNaN(data.generalAverage[i])
                                ? `${data.generalAverage[i].toFixed(0)}`
                                : null,
                    },
                    {
                        name:
                            props.radioValue === 'department'
                                ? 'Média do Setor'
                                : props.radioValue === 'company'
                                ? 'Média da Empresa'
                                : 'Média Individual (-3 à 3)',
                        value:
                            data.generalAverage[i] !== null &&
                            !isNaN(data.generalAverage[i])
                                ? (data.generalAverage[i] / 33.33).toFixed(0)
                                : null,
                    },

                    {
                        name: 'Média da Empresa',
                        value:
                            company.generalAverage[i] !== null &&
                            !isNaN(company.generalAverage[i])
                                ? `${company.generalAverage[i]}`
                                : null,
                    },
                    {
                        name: 'Média da Empresa (-3 à 3)',
                        value:
                            company.generalAverage[i] !== null &&
                            !isNaN(company.generalAverage[i])
                                ? (company.generalAverage[i] / 33.33).toFixed(0)
                                : null,
                    },
                ],
            };

            for (let j = 0; j < months[i].data.length; j++) {
                //preparing the data to calculate the average of the year
                if (months[i].data[j].value) {
                    monthsQt[j] = monthsQt[j] + 1;
                    if (average[j]) {
                        average[j].value =
                            average[j].value +
                            parseInt(months[i].data[j].value);
                    } else {
                        average[j] = {
                            name: months[i].data[j].name,
                            value: parseInt(months[i].data[j].value),
                        };
                    }
                }
            }
        }

        for (let j = 0; j < average.length; j++) {
            //logic to calculate the average of the year
            if (props.radioValue === 'employee' && j >= 0 && j <= 5) {
                average[j].value = Math.round(
                    ((average[j].value * 33.33) / (monthsQt[j] * 100)) * 100
                );
            } else {
                average[j].value = Math.round(
                    (average[j].value / (monthsQt[j] * 100)) * 100
                );
            }
        }

        months[12] = {
            name: `Média`,
            data: average,
        };

        if (props.radioValue === 'department')
            rowsName[6].name = 'Média do Setor';
        else if (props.radioValue === 'company')
            rowsName[6].name = 'Média da Empresa';
        else if (props.radioValue === 'employee')
            rowsName[6].name = 'Média Individual';

        return months;
    };

    useEffect(() => {
        let company;
        let actualGlobalCompany;

        setRows(null);
        if (!isUndefined(props.globalCompany) && props.globalCompany !== null) {
            if (props.globalCompany.hasOwnProperty('companyId'))
                actualGlobalCompany = props.globalCompany.companyId;
            else actualGlobalCompany = auth.data.user.companyId;
        } else {
            actualGlobalCompany = auth.data.user.companyId;
        }

        const callApiUsers = async (globalCompany) => {
            const response = await getAllActiveUsersInCompany(auth, globalCompany);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            let users = body.payload.map(({ userId, name }) => ({
                userId,
                name,
            }));

            let temp;
            for (let i = 0; i < users.length; i++) {
                //logic to left just the 2 first names but iF has preposition left 3
                temp = users[i].name.split(' ');
                if (temp[1] !== undefined && temp[2] !== undefined) {
                    if (temp[1].length <= 2) {
                        users[i].name = temp[0] + ' ' + temp[1] + ' ' + temp[2];
                    } else {
                        users[i].name = temp[0] + ' ' + temp[1];
                    }
                } else if (temp[1] !== undefined) {
                    users[i].name = temp[0] + ' ' + temp[1];
                } else {
                    users[i].name = temp[0];
                }
            }

            return users;
        };
        const callGetAllUsersInCompany = async (company) => {
            const response = await getAllActiveUsersInCompany(auth, company);
            const body = await response.json();
            return body.payload;
        };
        const callGetAllUsersInDepartmentForManagers = async (
            departmentId,
            company
        ) => {
            const response = await getAllActiveUsersInDepartment(
                auth,
                departmentId,
                company
            );
            const body = await response.json();
            return body.payload;
        };
        if (loggedUser.permissionRoleId === 10) {
            callGetAllUsersInDepartmentForManagers(
                loggedUser.departmentId,
                loggedUser.companyId
            ).then((res) => {
                setOptionsUsers(res);
            });
        } else {
            callGetAllUsersInCompany(actualGlobalCompany).then((res) => {
                setOptionsUsers(res);
            });
            callApiUsers(actualGlobalCompany)
                .then((res) => {
                    setOptionsUsers(
                        res.sort((a, b) =>
                            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                        )
                    );
                })
                .catch((err) => console.log(err));
        }
        const callApiIFUser = async (user, year, globalCompany) => {
            const response = await getUserAnnualIF(
                auth,
                year,
                user,
                globalCompany
            );
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            let aux = body.payload;
            aux = ClearDataFromApiUserIF(aux, 0);
            let averagePerMonth = aux.averagePerMonth;
            let generalAverage = aux.userAverage;

            return { averagePerMonth, generalAverage };
        };

        const callApiIFCompany = async (year, globalCompany) => {
            const response = await getCompanyAnnualIF(
                auth,
                year,
                globalCompany
            );
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            let averagePerMonth = {};

            let generalAverage = {};
            let average = 0;

            if (
                Object.keys(body.payload[0]).length === 0 &&
                body.payload[0].constructor === Object
            ) {
                for (let i = 0; i < 12; i++) {
                    averagePerMonth[i] = null;
                    generalAverage[i] = null;
                }
                average = 0;
                return { averagePerMonth, average, generalAverage };
            }

            averagePerMonth = await body.payload[0][year];

            return ClearDataFromApiCompanyIF(averagePerMonth);
        };

        const callApiIFDepartment = async (department, year, globalCompany) => {
            const response = await getDepartmentAnnualIF(
                auth,
                year,
                department,
                globalCompany
            );
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            let averagePerMonth = await body.payload[0][year];

            return ClearDataFromApiDepartmentIF(averagePerMonth);
        };

        const callApiDepartments = async (globalCompany) => {
            const response = await getAllDepartments(auth, globalCompany);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body.payload;
        };

        callApiDepartments(actualGlobalCompany)
            .then((res) => {
                setOptionsDepartments(
                    res.sort((a, b) =>
                        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                    )
                );
            })
            .catch((err) => console.log(err));

        if (props.radioValue === 'employee') {
            if (loggedUser.permissionRoleId === 10) {
                callApiIFUser(user, year, actualGlobalCompany)
                    .then((res) => {
                        callApiIFCompany(year, actualGlobalCompany)
                            .then((companyRes) => {
                                company = companyRes;
                                if (user) setActualData(user.name);
                                else setActualData(auth.data.user.name);
                                setRows(createTableMonths(res, company));
                            })
                            .catch((err) => console.log(err));
                    })
                    .catch((err) => console.log(err));
            } else {
                callApiIFUser(user, year, actualGlobalCompany)
                    .then((res) => {
                        callApiIFCompany(year, actualGlobalCompany)
                            .then((companyRes) => {
                                company = companyRes;
                                if (user) setActualData(user.name);
                                else setActualData(auth.data.user.name);
                                setRows(createTableMonths(res, company));
                            })
                            .catch((err) => console.log(err));
                    })
                    .catch((err) => console.log(err));
            }
        } else if (props.radioValue === 'department') {
            callApiIFDepartment(department, year, actualGlobalCompany)
                .then((res) => {
                    callApiIFCompany(year, actualGlobalCompany)
                        .then((companyRes) => {
                            if (department) setActualData(department.name);
                            else if (defaultDepartment) {
                                setActualData(defaultDepartment.name);
                                setDepartment(defaultDepartment);
                            } else setActualData('Seu Setor');
                            company = companyRes;
                            setRows(createTableMonths(res, company));
                        })
                        .catch((err) => console.log(err));
                })
                .catch((err) => console.log(err));
        } else if (props.radioValue === 'company') {
            callApiIFCompany(year, actualGlobalCompany)
                .then((res) => {
                    callApiIFCompany(year, actualGlobalCompany)
                        .then((companyRes) => {
                            company = companyRes;
                            if (
                                company &&
                                props.globalCompany.hasOwnProperty('companyId')
                            )
                                setActualData(props.globalCompany.name);
                            else setActualData('Sua empresa');
                            setRows(createTableMonths(res, company));
                        })
                        .catch((err) => console.log(err));
                })
                .catch((err) => console.log(err));
        }
    }, [year, department, user, props.radioValue, props.globalCompany]);

    const defaultPropsUsers = {
        options: optionsUsers,
        defaultValue: user,
        getOptionLabel: (option) => {
            return option.name;
        },
        getOptionSelected: (option) => {
            return option.name;
        },
        onChange: (event, newValue) => {
            setUser(newValue);
        },
    };

    const defaultPropsDepartments = {
        options: optionsDepartments,
        defaultValue: department,
        getOptionLabel: (option) => {
            return option.name;
        },
        getOptionSelected: (option) => {
            return option.name;
        },
        onChange: (event, newValue) => {
            setDepartment(newValue);
        },
    };

    if (rows === null) {
        return <RenderPage open={true} />;
    }

    return (
        <Container maxWidth="lg">
            <AlignContainer>
                <StyledFilter>
                    <Filters>
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                        >
                            <InputLabel id="demo-simple-select-outlined-label">
                                Ano
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                                label="Ano"
                            >
                                {optionsYear.map((year, index) => {
                                    return (
                                        <MenuItem key={index} value={year}>
                                            {year}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        {props.radioValue === 'department' &&
                        getSubmodulePermission(
                            props.submodules,
                            permissions.DEPARTMENTS_VISUALIZATION_FILTER
                        ) ? (
                            <Autocomplete
                                {...defaultPropsDepartments}
                                id="departments"
                                autoSelect
                                className={classes.formControl}
                                style={{ width: 150 }}
                                disabled={
                                    getSubmodulePermission(
                                        props.submodules,
                                        permissions.EMPLOYEES_VISUALIZATION_FILTER_DEPARTMENT
                                    )
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Setor"
                                        variant="outlined"
                                    />
                                )}
                            />
                        ) : null}
                        {props.radioValue === 'employee' &&
                        loggedUser.permissionRoleId !== 10 &&
                        getSubmodulePermission(
                            props.submodules,
                            permissions.EMPLOYEES_VISUALIZATION_FILTER
                        ) ? (
                            <Autocomplete
                                {...defaultPropsUsers}
                                id="users"
                                autoSelect
                                className={classes.formControl}
                                style={{ width: 170 }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Colaborador"
                                        variant="outlined"
                                    />
                                )}
                            />
                        ) : props.radioValue === 'employee' &&
                          loggedUser.permissionRoleId === 10 ? (
                            <Autocomplete
                                {...defaultPropsUsers}
                                id="users"
                                autoSelect
                                options={optionsUsers}
                                className={classes.formControl}
                                style={{ width: 170 }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Colaborador"
                                        variant="outlined"
                                    />
                                )}
                            />
                        ) : null}
                    </Filters>
                </StyledFilter>
                <TableToShowValue
                    rowsName={rowsName}
                    rows={rows}
                    year={year}
                    tableData={tableData}
                />
            </AlignContainer>
        </Container>
    );
}