import React, {useContext, useEffect, useState} from 'react';
import {Redirect, useHistory} from "react-router-dom";

/* Material UI */
import Container from "@material-ui/core/Container";

/* Styles */
import {DashboardContainer, Spacer, DrawerAdjustment, MainTitle} from './if-dashboard.styles';

/* Components */
import Dashboard from "./if-dashboard-graph/if-dashboard-graph.component";
import Header from "../../../components/header/header.component";
import Help from "../../../components/help/help.component";
import { helpDashboardIF } from '../../../components/help/help.texts';

/* api */
import {authContext} from "../../../contexts/AuthContext";
import {getUserAnnualIF} from "../../../api/ApiIF";

/* Permissions */
import permissions, {getPermission} from "../../../utils/permission";
import {getInternetError} from "../../../utils/genericFunctions";

export default function DashboardPage() {
    const [data, setData] = React.useState([]);

    const { auth } = useContext(authContext);

    let history = useHistory();

    const [help, setHelp] = useState(false);

    useEffect(() => {
        let internetError;

        internetError = getInternetError();
        if(internetError) {
            history.replace('/error/500');
            return;
        }

    }, []);

    const onChangeHelp = () => {
        setHelp(!help);
    }

    return (
        <div>
            {!getPermission(auth.data.permissions, permissions.IF_DASHBOARD) ?
                <Redirect to={permissions.IF_DEFAULT_ROUTE}/>
                : null}
            <Header/>
            <DrawerAdjustment>
                <Container>
                    <Spacer/>
                    <DashboardContainer>
                        <Help onClick={onChangeHelp} helpPopUp={helpDashboardIF} help={help}/>
                        <MainTitle> Índice de Felicidade </MainTitle>
                        <Dashboard auth={auth}/>
                    </DashboardContainer>
                </Container>
            </DrawerAdjustment>
        </div>
    );
}
