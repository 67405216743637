import {apiUrl} from './Api';

export function getAnnualIpaDepartmentsTasks(auth, year, globalCompany, department) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany;
    year = parseInt(year);

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/companies/${company}/departments/${department}/ipa/tasksScore?startDate=${year}-01-01&endDate=${year+1}-01-01`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}

export function getAnnualIpaDepartmentsAlignments(auth, year, globalCompany, department) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany;
    year = parseInt(year);
    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/companies/${company}/departments/${department}/ipa/alignmentsScore?startDate=${year}-01-01&endDate=${year+1}-01-01`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}

export function getByAttributeAndDepartmentUserAnnual(auth, year, attribute, department, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany ? globalCompany : auth.data.user.companyId;
    year = parseInt(year);

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/companies/${company}/departments/${department}/ifAttribute?attribute=${attribute}&startDate=${year}-01-01&endDate=${year+1}-01-01`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}

export function getAllDepartments(auth, filterCompany, showInactive) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = filterCompany ? filterCompany : auth.data.user.companyId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/companies/${company}/departments?inactive=${showInactive}`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}

export function getSubordinatesDepartments(auth, department, company) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/companies/${company}/departments/${department}/hierarchy/getSubordinates`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });
}

export function getSubordinatesUsersByDepartment(auth, department, company) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/companies/${company}/departments/${department}/hierarchy/getUsersSubordinates`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });
}

export function getOneDepartment(auth, department, company) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/companies/${company}/departments/${department}`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}

export function addDepartment(auth, data) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = data.company;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        },

        body: JSON.stringify({
            name: data.name,
            purpose: data.purpose,
            parentId: data.parentId ? data.parentId : null
        })
    };

    return fetch(`${apiUrl}/companies/${company}/departments`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        })
}

export function editDepartment(auth, data) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = data.company;
    const department = data.department;

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        },

        body: JSON.stringify({
            name: data.name,
            purpose: data.purpose,
            active: data.active,
            parentId: data.parentId ? data.parentId : null,
            managerId: data.managerId ? data.managerId : null,
        })
    };

    return fetch(`${apiUrl}/companies/${company}/departments/${department}`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        })
}

export function uploadDepartmentImage(auth, file, departmentId, companyId) {
    const authorization = `Bearer ${auth.data.token}`;

    const formData = new FormData();

    formData.append('file', file);

    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: authorization,
        },
        body: formData
    };

    return fetch(`${apiUrl}/companies/${companyId}/departments/${departmentId}/upload`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error);
        })
}

export function getLastNpsAvgFromDepartment(auth, year, globalCompany, department) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany;
    year = parseInt(year);
    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/companies/${company}/departments/${department}/nps/getLastNpsAvgFromDepartment?startDate=${year}-01-01&endDate=${year+1}-01-01`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}

export function getManagerDepartment(auth, company, departmentId) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/companies/${company}/departments/${departmentId}/management`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });
}