import styled from 'styled-components/macro'

export const Filters = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
    @media screen and (max-width: 362px) {
        flex-direction: column;
    }
`;

export const TableContainer = styled.div`
    padding-top: 1vw;
    padding-bottom: 3vw;
`;

export const FiltersAndSwitch = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 750px) {
        flex-direction: column;
    }
`;