import React, { useContext, useEffect, useState } from 'react';
import { isUndefined } from 'svgsaver/src/utils';

/* Styles */
import {
    Filters,
    TableContainer,
    Legend,
    LegendItem,
    LegendColor,
    FiltersAndSwitch,
} from './history-alignment-tab.styles';

/* Components */
import TableToShowValue from '../../../../components/table-to-show-value/table-to-show-value.component';
import RenderPage from '../../../../components/render-page/render-page.component';

/* Material UI */
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import SwitchTwoLabels from '../../../../components/switch/switch.component';

/* Context */
import { authContext } from '../../../../contexts/AuthContext';

/* Api*/
import { getOneCompany } from '../../../../api/ApiCompany';
import {
    findAllUserAlignments,
    getAlignmentMatches,
    getUserAnnualAlignmentsGrade,
    findMonthAverageFromUser,
    findYearsAverageFromUser,
    findYearAverageFromUser,
    findUserAlignmentYears
} from '../../../../api/ApiAlignment';
import {
    getAllActiveUsersInCompany,
    getAllActiveUsersInDepartment,
    getUserMonthAvg
} from '../../../../api/ApiUser';

/* Utils */

/* Permission */
import permissions, {
    getSubmodulePermission,
} from '../../../../utils/permission';
import { getAllDepartments, getOneDepartment, getSubordinatesDepartments } from '../../../../api/ApiDepartment';

const today = new Date();

const yy = today.getFullYear();

const GERENTE = 10;

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
        width: 240,
        borderRadius: 10,
        padding: 3,
    },
    formControlYear: {
        margin: theme.spacing(1),
        minWidth: 100,
        width: 140,
        borderRadius: 10,
        padding: 3,
    },
}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #9E9E9E',
    },
}))(Tooltip);

export default function HistoryIpaAlignmentTab(props) {
    const { auth } = useContext(authContext);

    const [globalCompany, setGlobalCompany] = useState({});

    const [year, setYear] = useState(yy);
    const [user, setUser] = useState({
        userId: auth.data.user.userId,
        name: auth.data.user.name,
        departmentId: auth.data.user.departmentId,
        companyId: auth.data.user.companyId,
        active: 1,
    });
    const [optionsYears, setOptionsYears] = useState([]);
    const [optionsUsers, setOptionsUsers] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [departmentValue, setDepartmentValue] = useState({
        departmentId: auth?.data.user.departmentId,
        name: auth?.data.user.departmentName,
        companyId: auth?.data.user.companyId,
    });

    const [rowsName, setRowsName] = useState([]);
    const [rows, setRows] = useState([]);
    const [differenceRows, setDifferenceRows] = useState([]);
    const [rowsNameId, setRowsNameId] = useState([]);

    const [monthAverage, setMonthAverage] = useState([
        { name: 'Jan', average: null, position: 0 },
        { name: 'Fev', average: null, position: 1 },
        { name: 'Mar', average: null, position: 2 },
        { name: 'Abr', average: null, position: 3 },
        { name: 'Mai', average: null, position: 4 },
        { name: 'Jun', average: null, position: 5 },
        { name: 'Jul', average: null, position: 6 },
        { name: 'Ago', average: null, position: 7 },
        { name: 'Set', average: null, position: 8 },
        { name: 'Out', average: null, position: 9 },
        { name: 'Nov', average: null, position: 10 },
        { name: 'Dez', average: null, position: 11 },
    ]);

    const names = [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
    ];
    const monthKeys = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const [allYearAverage, setAllYearAverage] = useState([]);
    const [allYearsAverage, setAllYearsAverage] = useState([]);
    const [ready, setReady] = useState(false);

    const [showNotActivedAlignments, setShowNotActivedAlignments] =
        useState(false);
    const [fetchOpen, setFetchOpen] = useState(true);

    const classes = useStyles();

    let tableData = {
        componentTitleStart: `Notas de alinhamento que foram dadas por `,
        componentTitleFirstInfo: `${user ? user.name : ''}`,
        componentTitleMiddle: ` em `,
        componentTitleSecondInfo: `${year}`,
        componentTitleSecondMiddle: '',
        componentTitleThirdInfo: '',
        tableTitle: `Alinhamento ${year}`,
    };

    let tableDataSecondTable = {
        componentTitleStart: `Grau de alinhamento de `,
        componentTitleFirstInfo: `${user ? user.name : ''}`,
        componentTitleMiddle: `com outros colaboradores em `,
        componentTitleSecondInfo: `${year}`,
        componentTitleSecondMiddle: '',
        componentTitleThirdInfo: '',
        tableTitle: `Grau de alinhamento ${year}`,
    };

    const justTheColor = (differenceBetweenGrade, type, active) => {
        let color;
        if (active !== undefined) {
            if (active === 0) {
                color = 'red';
                if (type === 2) color = '2px solid #00e600';
            }
        } else if (parseInt(differenceBetweenGrade) === 0) {
            color = '#00FF00';
            if (type === 2) color = '2px solid #00e600';
        } else if (parseInt(differenceBetweenGrade) === 1) {
            color = '#FFFF00';
            if (type === 2) color = '2px solid #e6e600';
        } else if (parseInt(differenceBetweenGrade) >= 2) {
            color = '#FF0000';
            if (type === 2) color = '2px solid #e60000';
        } else {
            if (type === 2) color = '1px solid #E0E0E0';
        }

        return color;
    };

    useEffect(() => {
        const callApiFindUserAlignmentYears = async () => {
            const response = await findUserAlignmentYears(auth, user?.userId, user?.companyId);
            
            const { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);
            setOptionsYears(payload)
            setYear(payload[payload.length - 1]?.year)
        }

        try {
            callApiFindUserAlignmentYears();
        } catch (error) {
            console.log(error);
        }

    }, [auth, user]);

    useEffect(() => {
        const callApiFindAllDepartment = async () => {
            const response = await getAllDepartments(
                auth,
                auth.data.user.companyId
            );

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);
            setDepartmentList(payload);
        };

        const callApiFindSubordinatesDepartments = async () => {
            const response = await getSubordinatesDepartments(
                auth,
                auth.data.user.departmentId,
                auth.data.user.companyId
            );

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            setDepartmentList(payload);
        }

        const callApiFindOneDepartment = async () => {
            const response = await getOneDepartment(
                auth,
                auth.data.user.departmentId,
                auth.data.user.companyId
            );

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            setDepartmentValue(payload[0]);
        };
        try {
            if(props.submodules && getSubmodulePermission(props.submodules, permissions.SUBORDINATES_DEPARTMENTS_VIZUALIZATION_FILTER)) {
                callApiFindSubordinatesDepartments();
            } else if (getSubmodulePermission(props.submodules, permissions.DEPARTMENTS_VISUALIZATION_FILTER)) {
                callApiFindAllDepartment();
            }
            callApiFindOneDepartment();
        } catch (err) {
            console.log(err);
        }
    }, [auth, props.submodules]);

    useEffect(() => {
        let actualGlobalCompany;

        if (props.theGlobalCompanyHasChange) {
            setFetchOpen(true);
        }

        if (!isUndefined(props.globalCompany) && props.globalCompany !== null) {
            if (props.globalCompany.hasOwnProperty('companyId')) {
                actualGlobalCompany = props.globalCompany.companyId;
                setGlobalCompany(props.globalCompany.companyId);
            } else {
                actualGlobalCompany = auth.data.user.companyId;
                setGlobalCompany(auth.data.user.companyId);
            }
        } else {
            actualGlobalCompany = auth.data.user.companyId;
            setGlobalCompany(auth.data.user.companyId);
        }
         
        const callApiFindAllUsers = async (globalCompany) => {
            const response = await getAllActiveUsersInCompany(auth, globalCompany);
            const body = await response.json();
            
            if (response.status !== 200) throw Error(body.message);

            if (props.theGlobalCompanyHasChange) {
                setUser(body.payload[0]);
                setYear(yy);
                props.resetChangeActualGlobalCompany(false);
            }
            setOptionsUsers(body.payload);  
        };

        const callApiFindAllUserInDepartment = async (globalCompany) => {
            const response = await getAllActiveUsersInDepartment(
                auth,
                departmentValue.departmentId,
                globalCompany
            );
            const body = await response.json();
            if (response.status !== 200) throw Error(body.message);

            if (props.theGlobalCompanyHasChange) {
                setUser(body.payload[0]);
                setYear(yy);
                props.resetChangeActualGlobalCompany(false);
            }

            setOptionsUsers(body.payload);
        };

        try {
            if(!departmentValue && getSubmodulePermission(props.submodules, permissions.DEPARTMENTS_VISUALIZATION_FILTER)) {
                callApiFindAllUsers(actualGlobalCompany);
            } else if(departmentValue) {
                callApiFindAllUserInDepartment(actualGlobalCompany);
            }
        } catch (error) {
            console.log(error);
        }
    }, [departmentValue, auth, props.globalCompany, props.submodules]);

    useEffect(() => {
        if (year === undefined || year === null) return;
        let actualGlobalCompany;

        if (props.theGlobalCompanyHasChange) {
            setFetchOpen(true);
        }

        if (!isUndefined(props.globalCompany) && props.globalCompany !== null) {
            if (props.globalCompany.hasOwnProperty('companyId')) {
                actualGlobalCompany = props.globalCompany.companyId;
                setGlobalCompany(props.globalCompany.companyId);
            } else {
                actualGlobalCompany = auth.data.user.companyId;
                setGlobalCompany(auth.data.user.companyId);
            }
        } else {
            actualGlobalCompany = auth.data.user.companyId;
            setGlobalCompany(auth.data.user.companyId);
        }

        const cleanDataFromApi = (data, arrayOfUserIds, year, type) => {
            //TODO fix this logic/function

            let findIt = false;
            let response;

            let newData = [];

            if (data === undefined) {
                for (let i = 0; i < monthKeys.length; i++) {
                    if (newData[i] === undefined) {
                        newData[i] = {
                            name: names[i],
                            data: [],
                        };
                        for (let j = 0; j < arrayOfUserIds.length; j++) {
                            if (type) {
                                newData[i].data.push({
                                    alignedUserId: arrayOfUserIds[j].userId,
                                    name: arrayOfUserIds[j].name,
                                    active: arrayOfUserIds[j].active,
                                    alignedUserGrade: null,
                                    grade: null,
                                    grades: [],
                                });
                            } else {
                                newData[i].data.push({
                                    ratedUserId: arrayOfUserIds[j].userId,
                                    name: arrayOfUserIds[j].name,
                                    active: arrayOfUserIds[j].active,
                                    alignedUserGrade: null,
                                    grade: null,
                                    grades: [],
                                });
                            }
                        }
                    }
                }
                return newData;
            }

            /* This is because may not have an grade in alignment so dont show this alignment inside the month, but we need to now that this alignment doesnt exists so we added it with null fields in the array */
            if (Object.entries(data).length !== 0) {
                /* If the user has data */
                Object.keys(data[year]).forEach(function (item) {
                    for (let i = 0; i < arrayOfUserIds.length; i++) {
                        if (type)
                            response = data[year][item].filter(
                                (obj) =>
                                    obj.alignedUserId ===
                                    arrayOfUserIds[i].userId
                            );
                        else
                            response = data[year][item].filter(
                                (obj) =>
                                    obj.ratedUserId === arrayOfUserIds[i].userId
                            );

                        findIt = response.length !== 0;
                        if (!findIt) {
                            if (type) {
                                data[year][item].push({
                                    alignedUserId: arrayOfUserIds[i].userId,
                                    name: arrayOfUserIds[i].name,
                                    active: arrayOfUserIds[i].active,
                                    alignedUserGrade: null,
                                    grade: null,
                                    grades: [],
                                });
                            } else {
                                data[year][item].push({
                                    ratedUserId: arrayOfUserIds[i].userId,
                                    name: arrayOfUserIds[i].name,
                                    active: arrayOfUserIds[i].active,
                                    alignedUserGrade: null,
                                    grade: null,
                                    grades: [],
                                });
                            }
                        }
                    }
                });
            }

            /* Adding the values of months that doesnt exist as null */
            for (let i = 0; i < monthKeys.length; i++) {
                if (Object.entries(data).length === 0) {
                    /* If the user has no data */
                    if (newData[i] === undefined) {
                        newData[i] = {
                            name: names[i],
                            data: [],
                        };
                        for (let j = 0; j < arrayOfUserIds.length; j++) {
                            if (type) {
                                newData[i].data.push({
                                    alignedUserId: arrayOfUserIds[j].userId,
                                    name: arrayOfUserIds[j].name,
                                    active: arrayOfUserIds[j].active,
                                    alignedUserGrade: null,
                                    grade: null,
                                    grades: [],
                                });
                            } else {
                                newData[i].data.push({
                                    ratedUserId: arrayOfUserIds[j].userId,
                                    name: arrayOfUserIds[j].name,
                                    active: arrayOfUserIds[j].active,
                                    alignedUserGrade: null,
                                    grade: null,
                                    grades: [],
                                });
                            }
                        }
                    }
                } else {
                    Object.keys(data[year]).forEach(function (item) {
                        if (data[year].hasOwnProperty(item)) {
                            if (monthKeys[i] === item) {
                                newData[i] = {
                                    name: names[i],
                                    data: data[year][item],
                                };
                            } else {
                                if (newData[i] === undefined) {
                                    /* this if is to not replace with a null value if is created with the last condition*/
                                    newData[i] = {
                                        name: names[i],
                                        data: [],
                                    };
                                    for (
                                        let j = 0;
                                        j < arrayOfUserIds.length;
                                        j++
                                    ) {
                                        if (type) {
                                            newData[i].data.push({
                                                alignedUserId:
                                                    arrayOfUserIds[j].userId,
                                                name: arrayOfUserIds[j].name,
                                                active: arrayOfUserIds[j]
                                                    .active,
                                                alignedUserGrade: null,
                                                grade: null,
                                                grades: [],
                                            });
                                        } else {
                                            newData[i].data.push({
                                                ratedUserId:
                                                    arrayOfUserIds[j].userId,
                                                name: arrayOfUserIds[j].name,
                                                active: arrayOfUserIds[j]
                                                    .active,
                                                alignedUserGrade: null,
                                                grade: null,
                                                grades: [],
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    });
                }
            }
            return newData;
        };
        const cleanAverageDataFromApi = (data, year) => {
            let newData = [];

            if (data === undefined) {
                for (let i = 0; i < monthKeys.length; i++) {
                    if (newData[i] === undefined) {
                        newData[i] = {
                            name: names[i],
                            data: [],
                        };
                    }
                }
                return newData;
            }

            /* Adding the values of months that doesnt exist as null */
            for (let i = 0; i < monthKeys.length; i++) {
                if (Object.entries(data).length === 0) {
                    /* If the user has no data */
                    if (newData[i] === undefined) {
                        newData[i] = {
                            name: names[i],
                            data: [],
                        };
                    }
                } else {
                    Object.keys(data[year]).forEach(function (item) {
                        if (data[year].hasOwnProperty(item)) {
                            if (monthKeys[i] === item) {
                                newData[i] = {
                                    name: names[i],
                                    data: data[year][item],
                                };
                            } else {
                                if (newData[i] === undefined) {
                                    /* this if is to not replace with a null value if is created with the last condition*/
                                    newData[i] = {
                                        name: names[i],
                                        data: [],
                                    };
                                }
                            }
                        }
                    });
                }
            }
            return newData;
        };

        const createTableMonths = (matchesData, data, arrayOfUserIds, year) => {
            /* 1 is matches and 0 is history */
            let valueToTheSecondTable = cleanDataFromApi(
                matchesData,
                arrayOfUserIds,
                year,
                1
            );

            let newData = cleanDataFromApi(data, arrayOfUserIds, year, 0);

            let index;

            const matchesCalc = (grade1, grade2) => {
                let zeroDisconsider = 0;
                if ((grade1 < 0 && grade2 > 0) || (grade1 > 0 && grade2 < 0))
                    zeroDisconsider = -1;

                const result = Math.abs(grade1 - grade2) + zeroDisconsider;

                return result;
            };

            /* Changing the key name to value and name because the generic component need key names */
            for (let i = 0; i < valueToTheSecondTable.length; i++) {
                index = 0;
                while (index !== -1) {
                    index = valueToTheSecondTable[i].data.findIndex(
                        (element) => element.alignedUserId === null
                    );
                    if (index !== -1) {
                        valueToTheSecondTable[i].data.splice(index, 1);
                    }
                }

                for (let j = 0; j < valueToTheSecondTable[i].data.length; j++) {
                    /* Second Table */
                    valueToTheSecondTable[i].data[j] = {
                        ...valueToTheSecondTable[i].data[j],
                        name: newData[i].data[j].name,
                        value:
                            valueToTheSecondTable[i].data[j].grades.length <= 1
                                ? null
                                : matchesCalc(
                                      valueToTheSecondTable[i].data[j]
                                          .grades[0],
                                      valueToTheSecondTable[i].data[j].grades[1]
                                  ),
                    };
                }
            }

            for (let i = 0; i < newData.length; i++) {
                for (let j = 0; j < newData[i].data.length; j++) {
                    /* First Table */
                    newData[i].data[j] = {
                        ...newData[i].data[j],
                        name: newData[i].data[j].name,
                        value: newData[i].data[j].grade,
                    };
                }
            }

            /* This and the other sort is to prevent that the table has the right order, even if has pushed elements */
            for (let i = 0; i < newData.length; i++) {
                newData[i].data = newData[i].data.sort((a, b) =>
                    a.ratedUserId > b.ratedUserId
                        ? 1
                        : b.ratedUserId > a.ratedUserId
                        ? -1
                        : 0
                );
                valueToTheSecondTable[i].data = valueToTheSecondTable[
                    i
                ].data.sort((a, b) =>
                    a.alignedUserId > b.alignedUserId
                        ? 1
                        : b.alignedUserId > a.alignedUserId
                        ? -1
                        : 0
                );
            }

            return {
                newData: newData,
                valueToTheSecondTable: valueToTheSecondTable,
            };
        };

        const callApiFindOneCompany = async (company) => {
            const response = await getOneCompany(auth, company);
            const body = await response.json();

            return body.payload[0];
        };

        const callApiFindAllUserAlignments = async (company, user) => {
            const response = await findAllUserAlignments(auth, company, user);
            const body = await response.json();

            return body.payload;
        };

        const callApiFindUserAlignmentsHistory = async (
            company,
            user,
            year
        ) => {
            const response = await getUserAnnualAlignmentsGrade(
                auth,
                company,
                user,
                year
            );
            const body = await response.json();

            return body.payload[0];
        };

        const callApiFindMatches = async (globalCompany, user, year) => {
            const response = await getAlignmentMatches(
                auth,
                globalCompany,
                user,
                year
            );
            const body = await response.json();
            if (response.status !== 200) throw Error(body.message);

            return body.payload[0];
        };

        const callAPiFindMonthAverageFromUser = async (company, user) => {
            const response = await findMonthAverageFromUser(
                auth,
                company,
                user
            );
            const body = await response.json();

            return body.payload[0];
        };
        const callApiFindAlignmentYearAverage = async (company, user) => {
            const response = await findYearAverageFromUser(auth, company, user);
            const body = await response.json();

            return body.payload[0];
        };

        const callApiFindAlignmentYearsAverage = async (company, user) => {
            const response = await findYearsAverageFromUser(
                auth,
                company,
                user
            );
            const body = await response.json();

            return body.payload[0];
        };

        callApiFindAlignmentYearAverage(actualGlobalCompany, user).then(
            (res) => {
                let array = [];

                if (res[year] != null) {
                    if (Object.entries(res[year]).length !== 0) {
                        Object.keys(res[year]).forEach(function (item) {
                            array.push({
                                name: `${item}`,
                                average: res[year][item][0].average,
                            });
                        });
                    }
                }

                setAllYearAverage(array);
            }
        );
        callApiFindAlignmentYearsAverage(actualGlobalCompany, user).then(
            (res) => {
                if (res[year] != null) {
                    setAllYearsAverage(res[year].average);
                } else setAllYearsAverage(null);
            }
        );

        callApiFindOneCompany(actualGlobalCompany)
            .then((companyRes) => {
                setGlobalCompany({
                    companyId: companyRes.companyId,
                    name: companyRes.name,
                });
            })
            .catch((err) => console.log(err));

        callApiFindAllUserAlignments(actualGlobalCompany, user)
            .then((alignmentsRes) => {
                callApiFindUserAlignmentsHistory(
                    actualGlobalCompany,
                    user,
                    year
                )
                    .then((alignmentsHistoryRes) => {
                        callApiFindMatches(actualGlobalCompany, user, year)
                            .then((matchesRes) => {
                                let names = [];
                                let userInTheAlignment = [];

                                for (let i = 0; i < alignmentsRes.length; i++) {
                                    userInTheAlignment[i] = {
                                        userId: alignmentsRes[i].userId,
                                        name: alignmentsRes[i].name,
                                        active: alignmentsRes[i].active,
                                    };
                                }
                                /* This and the other sort is to prevent that the table has the right order, even if has pushed elements */
                                userInTheAlignment = userInTheAlignment.sort(
                                    (a, b) =>
                                        a.userId > b.userId
                                            ? 1
                                            : b.userId > a.userId
                                            ? -1
                                            : 0
                                );

                                for (let i = 0; i < alignmentsRes.length; i++) {
                                    names[i] = {
                                        name: userInTheAlignment[i].name,
                                        active: userInTheAlignment[i].active,
                                    };
                                }

                                let result = createTableMonths(
                                    matchesRes,
                                    alignmentsHistoryRes,
                                    userInTheAlignment,
                                    year
                                );
                                setRowsName(names);
                                setRowsNameId(userInTheAlignment);

                                setRows(result.newData);

                                setDifferenceRows(result.valueToTheSecondTable);
                                setFetchOpen(false);
                            })
                            .catch((err) => console.log(err));
                    })
                    .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
    }, [auth, year, user, props.globalCompany]);


    useEffect(() => {
        const callApiGetUserMonthAvg = async (year,company,user) => {
            const response = await getUserMonthAvg(
                auth,
                year,
                company,
                user,
            );
            const body = await response.json();
            const ret = body.payload
            for (const [key, value] of Object.entries(ret)) {
                monthAverage[key].average = value.shortScale
            }
            return body.payload
        }


        
        try{
            callApiGetUserMonthAvg(year,auth.data.user.companyId,user.userId)
        }
        catch(error)
        {
            console.log(error)
        }

    },[year,auth.data.user.companyId,user]);

    const defaultPropsUsers = {
        options: optionsUsers,
        value: user,
        getOptionLabel: (option) => {
            return option.name;
        },
        getOptionSelected: (option) => {
            return option.name;
        },
        onChange: (event, newValue) => {
            setFetchOpen(true);
            setUser(newValue);
            setYear(yy);
        },
    };

    const defaultPropsDepartment = {
        options: departmentList,
        value: departmentValue,
        getOptionLabel: (option) => {
            return option.name || '';
        },
        getOptionSelected: (option) => {
            return option.name || '';
        },
        onChange: (event, newValue) => {
            setDepartmentValue(newValue);
        },
    };

    return (
        <div>
            <FiltersAndSwitch>
                <Filters>
                    {getSubmodulePermission(
                        props.submodules,
                        permissions.EMPLOYEES_VISUALIZATION_FILTER
                    ) ? (
                        <Autocomplete
                            {...defaultPropsUsers}
                            id="users"
                            autoSelect
                            className={classes.formControl}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Usuário"
                                    variant="outlined"
                                />
                            )}
                        />
                    ) : null}
                    {getSubmodulePermission(
                        props.submodules,
                        permissions.DEPARTMENTS_VISUALIZATION_FILTER
                    ) ||
                    getSubmodulePermission(
                        props.submodules,
                        permissions.SUBORDINATES_DEPARTMENTS_VIZUALIZATION_FILTER
                    ) ? (
                        <Autocomplete
                            {...defaultPropsDepartment}
                            id="department-selector"
                            autoSelect
                            className={classes.formControl}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Setor"
                                    variant="outlined"
                                />
                            )}
                        />
                    ):null}
                    
                    <FormControl
                        variant="outlined"
                        className={classes.formControlYear}
                    >
                        <InputLabel id="demo-simple-select-outlined-label">
                            Ano
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={year}
                            onChange={(e) => {
                                setFetchOpen(true);
                                setYear(e.target.value);
                            }}
                            label="Ano"
                        >
                            {optionsYears.map((years, index) => {
                                return (
                                    <MenuItem key={index} value={years.year}>
                                        {years.year}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Filters>

                <SwitchTwoLabels
                    checkAction={setShowNotActivedAlignments}
                    leftWord=""
                    rightWord="Mostrar Alinhamentos Desativados"
                />
            </FiltersAndSwitch>

            <TableContainer>
                <TableToShowValue
                    rowsName={rowsName}
                    rows={differenceRows}
                    year={year}
                    tableData={tableDataSecondTable}
                    justTheColor={justTheColor}
                    showNotActived={showNotActivedAlignments}
                    alignHistory={true}
                    monthAverage={monthAverage}
                    allYearAverage={allYearAverage}
                    allYearsAverage={allYearsAverage}
                    rowsNameId={rowsNameId}
                    userPermission={auth.data.user.permissionRoleId}
                    ready={ready}
                />
                <Legend>
                    <HtmlTooltip
                        enterTouchDelay={1}
                        title={
                            <Typography color="inherit">
                                {' '}
                                Ambas as notas são iguais{' '}
                            </Typography>
                        }
                    >
                        <LegendItem>
                            <LegendColor color={'#0F0'} />
                            <span>Match!</span>
                        </LegendItem>
                    </HtmlTooltip>

                    <HtmlTooltip
                        enterTouchDelay={1}
                        title={
                            <Typography color="inherit">
                                {' '}
                                A diferença é de 1 ponto{' '}
                            </Typography>
                        }
                    >
                        <LegendItem>
                            <LegendColor color={'#FF0'} />
                            <span>Atenção</span>
                        </LegendItem>
                    </HtmlTooltip>

                    <HtmlTooltip
                        enterTouchDelay={1}
                        title={
                            <Typography color="inherit">
                                {' '}
                                A diferença é mais de 1 ponto{' '}
                            </Typography>
                        }
                    >
                        <LegendItem>
                            <LegendColor color={'#F00'} />
                            <span>Procure seu Colega</span>
                        </LegendItem>
                    </HtmlTooltip>
                </Legend>
            </TableContainer>
            <TableToShowValue
                rowsName={rowsName}
                rows={rows}
                year={year}
                tableData={tableData}
                showNotActived={showNotActivedAlignments}
            />
            {fetchOpen ? <RenderPage open={fetchOpen} /> : null}
        </div>
    );
}
