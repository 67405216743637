import React, { useState, useEffect } from 'react';

import Graph from '../../../../../../../../components/newGraph/graph';

/*component*/

/* util */
import GenPDF from '../../../../../../../../components/make-pdf/make-pdf';

/* styles */
import {GraphicStyle} from '../../../../../ipa-graphs-general/ipa-graphs-general.styles';

/* icon */

const TaskGraphDataTreatment = (props) => {
    const [treatedUserTasksData, setTreatedUserTasksData] = useState();
    const [treatedOtherUserTasksData, setTreatedOtherUserTasksData] =
        useState();
    const [treatedOtherTasksData, setTreatedOtherTasksData] = useState();

    const [
        treatedFirstDepartmentTasksData,
        setTreatedFirstDepartmentTasksData,
    ] = useState();
    const [
        treatedFirstDepartmentOtherTasksData,
        setTreatedFirstDepartmentOtherTasksData,
    ] = useState();
    const [
        treatedSecondDepartmentTasksData,
        setTreatedSecondDepartmentTasksData,
    ] = useState();

    const [treatedCompanyTasksData, setTreatedCompanyTasksData] = useState();
    const [treatedCompanyOtherTasksData, setTreatedCompanyOtherTasksData] =
        useState();

    const [treatedUserOtherYearTasksData, setTreatedUserOtherYearTasksData] =
        useState();
    const [
        treatedDepartmentOtherYearTasksData,
        setTreatedDepartmentOtherYearTasksData,
    ] = useState();
    const [
        treatedCompanyOtherYearTasksData,
        setTreatedCompanyOtherYearTasksData,
    ] = useState();

    const [sendData1Tasks, setSendData1Tasks] = useState();
    const [sendData2Tasks, setSendData2Tasks] = useState();

    const [sendName1, setSendName1] = useState('');
    const [sendName2, setSendName2] = useState('');

    const monthKeys = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const emptyTreatedData = [
        { y: null, x: 'January' },
        { y: null, x: 'February' },
        { y: null, x: 'March' },
        { y: null, x: 'April' },
        { y: null, x: 'May' },
        { y: null, x: 'June' },
        { y: null, x: 'July' },
        { y: null, x: 'August' },
        { y: null, x: 'September' },
        { y: null, x: 'October' },
        { y: null, x: 'November' },
        { y: null, x: 'December' },
    ];

    const [jumpFirstRender, setJumpFirstRender] = useState(false);

    useEffect(() => {
        //userDataTreatment
        if (props.userTasksData) {
            if (Object.keys(props.userTasksData).length !== 0) {
                const treatUTD = () => {
                    if (props.userTasksData) {
                        // UTD -> User Alignment Data
                        const [yearUTD] = Object.keys(props.userTasksData);

                        const allNotesInMonthUTD = [];

                        // allNotesInMonthUTD = [{month: "January", grades: null},{month: "February", grades: [2,3,4]}...]
                        monthKeys.forEach((month) => {
                            if (props.userTasksData[yearUTD][month]) {
                                const gradesInMonth = props.userTasksData[
                                    yearUTD
                                ][month].map((gradeData) => {
                                    return gradeData.grade;
                                });
                                allNotesInMonthUTD.push({
                                    month: month,
                                    grades: gradesInMonth,
                                });
                            } else {
                                allNotesInMonthUTD.push({
                                    month: month,
                                    grades: null,
                                });
                            }
                        });

                        const treatedUTD = allNotesInMonthUTD.map(
                            (monthAndItsNotes) => {
                                if (monthAndItsNotes.grades) {
                                    const gradeAverage =
                                        monthAndItsNotes.grades.reduce(
                                            (acc, val) => acc + val
                                        ) / monthAndItsNotes.grades.length;
                                    return {
                                        x: monthAndItsNotes.month,
                                        y: (gradeAverage * 33.33).toFixed(0),
                                    };
                                } else {
                                    return {
                                        x: monthAndItsNotes.month,
                                        y: null,
                                    };
                                }
                            }
                        );

                        setTreatedUserTasksData(treatedUTD);
                    }
                };

                const treatUserData = async () => {
                    (await Object.keys(props.userTasksData).length) !== 0
                        ? treatUTD()
                        : setTreatedUserTasksData(emptyTreatedData);

                    await setJumpFirstRender(true);
                };

                treatUserData();
            }
        }
    }, [props.checksStatus, props.userTasksData]);

    useEffect(() => {
        //userOtherTaskDataTreatment
        if (props.userOtherTasksData) {
            if (Object.keys(props.userOtherTasksData).length !== 0) {
                const treatUTD = () => {
                    if (props.userOtherTasksData) {
                        // UTD -> User Alignment Data
                        const [yearUTD] = Object.keys(props.userOtherTasksData);

                        const allNotesInMonthUTD = [];

                        // allNotesInMonthUTD = [{month: "January", grades: null},{month: "February", grades: [2,3,4]}...]
                        monthKeys.forEach((month) => {
                            if (props.userOtherTasksData[yearUTD][month]) {
                                const gradesInMonth = props.userOtherTasksData[
                                    yearUTD
                                ][month].map((gradeData) => {
                                    return gradeData.grade;
                                });
                                allNotesInMonthUTD.push({
                                    month: month,
                                    grades: gradesInMonth,
                                });
                            } else {
                                allNotesInMonthUTD.push({
                                    month: month,
                                    grades: null,
                                });
                            }
                        });

                        const treatedUTD = allNotesInMonthUTD.map(
                            (monthAndItsNotes) => {
                                if (monthAndItsNotes.grades) {
                                    const gradeAverage =
                                        monthAndItsNotes.grades.reduce(
                                            (acc, val) => acc + val
                                        ) / monthAndItsNotes.grades.length;
                                    return {
                                        x: monthAndItsNotes.month,
                                        y: (gradeAverage * 33.33).toFixed(0),
                                    };
                                } else {
                                    return {
                                        x: monthAndItsNotes.month,
                                        y: null,
                                    };
                                }
                            }
                        );

                        setTreatedOtherTasksData(treatedUTD);
                    }
                };

                const treatUserData = async () => {
                    (await Object.keys(props.userOtherTasksData).length) !== 0
                        ? treatUTD()
                        : setTreatedOtherTasksData(emptyTreatedData);

                    await setJumpFirstRender(true);
                };

                treatUserData();
            }
        }
    }, [props.checksStatus, props.userOtherTasksData]);

    useEffect(() => {
        //otherUserDataTreatment
        if (props.otherUserTasksData) {
            if (Object.keys(props.otherUserTasksData).length !== 0) {
                const treatUTD = () => {
                    if (props.otherUserTasksData) {
                        // UTD -> User Alignment Data
                        const [yearUTD] = Object.keys(props.otherUserTasksData);

                        const allNotesInMonthUTD = [];

                        // allNotesInMonthUTD = [{month: "January", grades: null},{month: "February", grades: [2,3,4]}...]
                        monthKeys.forEach((month) => {
                            if (props.otherUserTasksData[yearUTD][month]) {
                                const gradesInMonth = props.otherUserTasksData[
                                    yearUTD
                                ][month].map((gradeData) => {
                                    return gradeData.grade;
                                });
                                allNotesInMonthUTD.push({
                                    month: month,
                                    grades: gradesInMonth,
                                });
                            } else {
                                allNotesInMonthUTD.push({
                                    month: month,
                                    grades: null,
                                });
                            }
                        });

                        const treatedUTD = allNotesInMonthUTD.map(
                            (monthAndItsNotes) => {
                                if (monthAndItsNotes.grades) {
                                    const gradeAverage =
                                        monthAndItsNotes.grades.reduce(
                                            (acc, val) => acc + val
                                        ) / monthAndItsNotes.grades.length;
                                    return {
                                        x: monthAndItsNotes.month,
                                        y: (gradeAverage * 33.33).toFixed(0),
                                    };
                                } else {
                                    return {
                                        x: monthAndItsNotes.month,
                                        y: null,
                                    };
                                }
                            }
                        );

                        setTreatedOtherUserTasksData(treatedUTD);
                    }
                };

                const treatUserData = async () => {
                    (await Object.keys(props.userTasksData).length) !== 0
                        ? treatUTD()
                        : setTreatedOtherUserTasksData(emptyTreatedData);

                    await setJumpFirstRender(true);
                };

                treatUserData();
            }
        }
    }, [props.checksStatus, props.otherUserTasksData]);

    useEffect(() => {
        //departmentOtherDataTreatment
        if (props.departmentOtherTasksData) {
            if (Object.keys(props.departmentOtherTasksData).length !== 0) {
                const treatUTD = () => {
                    if (props.departmentOtherTasksData) {
                        // UTD -> User Alignment Data
                        const [yearUTD] = Object.keys(
                            props.departmentOtherTasksData
                        );

                        const allNotesInMonthUTD = [];

                        // allNotesInMonthUTD = [{month: "January", grades: null},{month: "February", grades: [2,3,4]}...]
                        monthKeys.forEach((month) => {
                            if (
                                props.departmentOtherTasksData[yearUTD][month]
                            ) {
                                const gradesInMonth =
                                    props.departmentOtherTasksData[yearUTD][
                                        month
                                    ].map((gradeData) => {
                                        return gradeData.avg;
                                    });
                                allNotesInMonthUTD.push({
                                    month: month,
                                    grades: gradesInMonth,
                                });
                            } else {
                                allNotesInMonthUTD.push({
                                    month: month,
                                    grades: null,
                                });
                            }
                        });

                        const treatedUTD = allNotesInMonthUTD.map(
                            (monthAndItsNotes) => {
                                if (monthAndItsNotes.grades) {
                                    const gradeAverage =
                                        monthAndItsNotes.grades.reduce(
                                            (acc, val) => acc + val
                                        ) / monthAndItsNotes.grades.length;
                                    return {
                                        x: monthAndItsNotes.month,
                                        y: (gradeAverage * 33.33).toFixed(0),
                                    };
                                } else {
                                    return {
                                        x: monthAndItsNotes.month,
                                        y: null,
                                    };
                                }
                            }
                        );
                        setTreatedFirstDepartmentOtherTasksData(treatedUTD);
                    }
                };

                const treatUserData = async () => {
                    (await Object.keys(props.departmentOtherTasksData)
                        .length) !== 0
                        ? treatUTD()
                        : setTreatedFirstDepartmentOtherTasksData(
                              emptyTreatedData
                          );

                    await setJumpFirstRender(true);
                };

                treatUserData();
            }
        }
    }, [props.checksStatus, props.departmentOtherTasksData]);

    useEffect(() => {
        //companyOtherDataTreatment
        if (props.companyOtherTasksData) {
            if (Object.keys(props.companyOtherTasksData).length !== 0) {
                const treatUTD = () => {
                    if (props.companyOtherTasksData) {
                        // UTD -> User Alignment Data
                        const [yearUTD] = Object.keys(
                            props.companyOtherTasksData
                        );

                        const allNotesInMonthUTD = [];

                        // allNotesInMonthUTD = [{month: "January", grades: null},{month: "February", grades: [2,3,4]}...]
                        monthKeys.forEach((month) => {
                            if (props.companyOtherTasksData[yearUTD][month]) {
                                const gradesInMonth =
                                    props.companyOtherTasksData[yearUTD][
                                        month
                                    ].map((gradeData) => {
                                        return gradeData.avg;
                                    });
                                allNotesInMonthUTD.push({
                                    month: month,
                                    grades: gradesInMonth,
                                });
                            } else {
                                allNotesInMonthUTD.push({
                                    month: month,
                                    grades: null,
                                });
                            }
                        });

                        const treatedUTD = allNotesInMonthUTD.map(
                            (monthAndItsNotes) => {
                                if (monthAndItsNotes.grades) {
                                    const gradeAverage =
                                        monthAndItsNotes.grades.reduce(
                                            (acc, val) => acc + val
                                        ) / monthAndItsNotes.grades.length;
                                    return {
                                        x: monthAndItsNotes.month,
                                        y: (gradeAverage * 33.33).toFixed(0),
                                    };
                                } else {
                                    return {
                                        x: monthAndItsNotes.month,
                                        y: null,
                                    };
                                }
                            }
                        );
                        setTreatedCompanyOtherTasksData(treatedUTD);
                    }
                };

                const treatUserData = async () => {
                    (await Object.keys(props.companyOtherTasksData).length) !==
                    0
                        ? treatUTD()
                        : setTreatedCompanyOtherTasksData(emptyTreatedData);

                    await setJumpFirstRender(true);
                };

                treatUserData();
            }
        }
    }, [props.checksStatus, props.companyOtherTasksData]);

    useEffect(() => {
        //bounthDepartmentDataTreatment
        if (props.firstDepartmentTasksData) {
            if (Object.keys(props.firstDepartmentTasksData).length !== 0) {
                const treatFDTD = () => {
                    // FDTD -> First Department Tasks Data
                    const [yearFDTD] = Object.keys(
                        props.firstDepartmentTasksData
                    );
                    
                    const allNotesInMonthFDTD = [];

                    // allNotesInMonthFDTD = [{month: "January", grades: null},{month: "February", grades: [2,3,4]}...]
                    monthKeys.forEach((month) => {
                        if (props.firstDepartmentTasksData[yearFDTD][month]) {
                            const averageMonth = props.firstDepartmentTasksData[yearFDTD][month][0].avg;
                            allNotesInMonthFDTD.push({
                                x: month,
                                y: averageMonth,
                            });
                        } else {
                            allNotesInMonthFDTD.push({
                                x: month,
                                y: null,
                            });
                        }
                    });
                    
                    setTreatedFirstDepartmentTasksData(allNotesInMonthFDTD);
                };
                const treatFirstDepartmentData = () => {
                    (Object.keys(props.firstDepartmentTasksData)
                        .length) !== 0
                        ? treatFDTD()
                        : setTreatedFirstDepartmentTasksData(emptyTreatedData);

                    setJumpFirstRender(true);
                };
                treatFirstDepartmentData();
            }
        }
        if (props.secondDepartmentTasksData) {
            if (Object.keys(props.secondDepartmentTasksData).length !== 0) {
                const treatSDTD = () => {
                    // SDTD -> Second Department Tasks Data
                    const [yearSDTD] = Object.keys(
                        props.secondDepartmentTasksData
                    );

                    const allNotesInMonthSDTD = [];

                    // allNotesInMonthSDTD = [{month: "January", grades: null},{month: "February", grades: [2,3,4]}...]
                    monthKeys.forEach((month) => {
                        if (props.secondDepartmentTasksData[yearSDTD][month]) {
                            const averageMonth = props.secondDepartmentTasksData[yearSDTD][month][0].avg;
                            allNotesInMonthSDTD.push({
                                x: month,
                                y: averageMonth,
                            });
                        } else {
                            allNotesInMonthSDTD.push({
                                x: month,
                                y: null,
                            });
                        }
                    });

                    setTreatedSecondDepartmentTasksData(allNotesInMonthSDTD);
                };

                const treatSecondDepartmentData = () => {
                    (Object.keys(props.secondDepartmentTasksData)
                        .length) !== 0
                        ? treatSDTD()
                        : setTreatedSecondDepartmentTasksData(emptyTreatedData);

                    setJumpFirstRender(true);
                };

                treatSecondDepartmentData();
            }
        }
    }, [
        props.checksStatus[1].activated,
        props.firstDepartmentTasksData,
        props.secondDepartmentTasksData,
    ]);

    useEffect(() => {
        //companyDataTreatment
        if (props.companyTasksData) {
            if (Object.keys(props.companyTasksData).length !== 0) {
                const treatCTD = () => {
                    // CTD -> Company Alignment Data
                    const [yearCTD] = Object.keys(props.companyTasksData);

                    const allNotesInMonthCTD = [];

                    // allNotesInMonthCTD = [{month: "January", grades: null},{month: "February", grades: [2,3,4]}...]
                    monthKeys.forEach((month) => {
                        if (props.companyTasksData[yearCTD][month]) {
                            const averageMonth = props.companyTasksData[yearCTD][month][0].avg;
                            allNotesInMonthCTD.push({
                                x: month,
                                y: averageMonth,
                            });
                        } else {
                            allNotesInMonthCTD.push({
                                x: month,
                                y: null,
                            });
                        }
                    });

                    setTreatedCompanyTasksData(allNotesInMonthCTD);
                };

                const treatCompanyData = async () => {
                    (Object.keys(props.companyTasksData).length) !== 0
                        ? treatCTD()
                        : setTreatedCompanyTasksData(emptyTreatedData);

                    setJumpFirstRender(true);
                };

                treatCompanyData();
            }
        }
    }, [props.checksStatus[3].activated, props.companyTasksData]);

    useEffect(() => {
        //otherYearsDataTreatment

        if (
            props.checksStatus[0].activated ||
            props.checksStatus[3].activated ||
            props.checksStatus[2].activated
        ) {
            // user other year
            if (
                props.checksStatus[0].activated &&
                props.userOtherYearTasksData
            ) {
                if (Object.keys(props.userOtherYearTasksData).length !== 0) {
                    const treatUOYTD = () => {
                        if (props.userOtherYearTasksData) {
                            // UOYTD -> User Other Year Alignment Data
                            const [yearUOYTD] = Object.keys(
                                props.userOtherYearTasksData
                            );

                            const allNotesInMonthUOYTD = [];

                            // allNotesInMonthUOYTD = [{month: "January", grades: null},{month: "February", grades: [2,3,4]}...]
                            monthKeys.forEach((month) => {
                                if (
                                    props.userOtherYearTasksData[yearUOYTD][
                                        month
                                    ]
                                ) {
                                    const gradesInMonth =
                                        props.userOtherYearTasksData[yearUOYTD][
                                            month
                                        ].map((gradeData) => {
                                            return gradeData.grade;
                                        });
                                    allNotesInMonthUOYTD.push({
                                        month: month,
                                        grades: gradesInMonth,
                                    });
                                } else {
                                    allNotesInMonthUOYTD.push({
                                        month: month,
                                        grades: null,
                                    });
                                }
                            });

                            const treatedUOYTD = allNotesInMonthUOYTD.map(
                                (monthAndItsNotes) => {
                                    if (monthAndItsNotes.grades) {
                                        const gradeAverage =
                                            monthAndItsNotes.grades.reduce(
                                                (acc, val) => acc + val
                                            ) / monthAndItsNotes.grades.length;
                                        return {
                                            x: monthAndItsNotes.month,
                                            y: (gradeAverage * 33.33).toFixed(
                                                0
                                            ),
                                        };
                                    } else {
                                        return {
                                            x: monthAndItsNotes.month,
                                            y: null,
                                        };
                                    }
                                }
                            );
                            setTreatedUserOtherYearTasksData(treatedUOYTD);
                        }
                    };

                    const treatUserOtherYearData = async () => {
                        (await Object.keys(props.userOtherYearTasksData)
                            .length) !== 0
                            ? treatUOYTD()
                            : setTreatedUserOtherYearTasksData(
                                  emptyTreatedData
                              );

                        await setJumpFirstRender(true);
                    };

                    treatUserOtherYearData();
                }
            }
            // department other year
            else if (
                props.checksStatus[2].activated &&
                props.departmentOtherYearTasksData
            ) {
                if (
                    Object.keys(props.departmentOtherYearTasksData).length !== 0
                ) {
                    const treatDOYTD = () => {
                        if (props.departmentOtherYearTasksData) {
                            // DOYTD -> Department Other Year Alignment Data
                            const [yearDOYTD] = Object.keys(
                                props.departmentOtherYearTasksData
                            );

                            const allNotesInMonthDOYTD = [];

                            // allNotesInMonthDOYTD = [{month: "January", grades: null},{month: "February", grades: [2,3,4]}...]
                            monthKeys.forEach((month) => {
                                if (
                                    props.departmentOtherYearTasksData[
                                        yearDOYTD
                                    ][month]
                                ) {
                                    const gradesInMonth =
                                        props.departmentOtherYearTasksData[
                                            yearDOYTD
                                        ][month].map((gradeData) => {
                                            return gradeData.avg;
                                        });
                                    allNotesInMonthDOYTD.push({
                                        month: month,
                                        grades: gradesInMonth,
                                    });
                                } else {
                                    allNotesInMonthDOYTD.push({
                                        month: month,
                                        grades: null,
                                    });
                                }
                            });

                            const treatedDOYTD = allNotesInMonthDOYTD.map(
                                (monthAndItsNotes) => {
                                    if (monthAndItsNotes.grades) {
                                        const gradeAverage =
                                            monthAndItsNotes.grades.reduce(
                                                (acc, val) => acc + val
                                            ) / monthAndItsNotes.grades.length;
                                        return {
                                            x: monthAndItsNotes.month,
                                            y: (gradeAverage).toFixed(
                                                0
                                            ),
                                        };
                                    } else {
                                        return {
                                            x: monthAndItsNotes.month,
                                            y: null,
                                        };
                                    }
                                }
                            );
                            setTreatedDepartmentOtherYearTasksData(
                                treatedDOYTD
                            );
                        }
                    };

                    const treatDepartmentOtherYearData = async () => {
                        (await Object.keys(props.departmentOtherYearTasksData)
                            .length) !== 0
                            ? treatDOYTD()
                            : setTreatedDepartmentOtherYearTasksData(
                                  emptyTreatedData
                              );

                        await setJumpFirstRender(true);
                    };

                    treatDepartmentOtherYearData();
                }
            }
            // company other year
            else if (
                props.checksStatus[3].activated &&
                props.companyOtherYearTasksData
            ) {
                if (Object.keys(props.companyOtherYearTasksData).length !== 0) {
                    const treatCOYTD = () => {
                        if (props.companyOtherYearTasksData) {
                            // COYTD -> Company Other Year Alignment Data
                            const [yearCOYTD] = Object.keys(
                                props.companyOtherYearTasksData
                            );

                            const allNotesInMonthCOYTD = [];

                            // allNotesInMonthCOYTD = [{month: "January", grades: null},{month: "February", grades: [2,3,4]}...]
                            monthKeys.forEach((month) => {
                                if (
                                    props.companyOtherYearTasksData[yearCOYTD][
                                        month
                                    ]
                                ) {
                                    const gradesInMonth =
                                        props.companyOtherYearTasksData[
                                            yearCOYTD
                                        ][month].map((gradeData) => {
                                            return gradeData.avg;
                                        });
                                    allNotesInMonthCOYTD.push({
                                        month: month,
                                        grades: gradesInMonth,
                                    });
                                } else {
                                    allNotesInMonthCOYTD.push({
                                        month: month,
                                        grades: null,
                                    });
                                }
                            });

                            const treatedCOYTD = allNotesInMonthCOYTD.map(
                                (monthAndItsNotes) => {
                                    if (monthAndItsNotes.grades) {
                                        const gradeAverage =
                                            monthAndItsNotes.grades.reduce(
                                                (acc, val) => acc + val
                                            ) / monthAndItsNotes.grades.length;
                                        return {
                                            x: monthAndItsNotes.month,
                                            y: (gradeAverage).toFixed(
                                                0
                                            ),
                                        };
                                    } else {
                                        return {
                                            x: monthAndItsNotes.month,
                                            y: null,
                                        };
                                    }
                                }
                            );
                            setTreatedCompanyOtherYearTasksData(treatedCOYTD);
                        }
                    };

                    const treatCompanyOtherYearData = async () => {
                        (await Object.keys(props.companyOtherYearTasksData)
                            .length) !== 0
                            ? treatCOYTD()
                            : setTreatedCompanyOtherYearTasksData(
                                  emptyTreatedData
                              );

                        await setJumpFirstRender(true);
                    };

                    treatCompanyOtherYearData();
                }
            }
        }
    }, [
        props.userOtherYearTasksData,
        props.departmentOtherYearTasksData,
        props.companyOtherYearTasksData,
    ]);

    useEffect(() => {
        //SelectData sended to the graph

        let otherYear = ''
        
        if(props.actualOtherYear !== undefined)
        {
            otherYear = props.actualOtherYear
            
        }
        if (
            props.checksStatus[0].activated &&
            props.checksStatus[1].activated &&
            !props.checksStatus[2].activated &&
            !props.checksStatus[3].activated &&
            !props.checksStatus[4].activated &&
            !props.checksStatus[5].activated
        ) {
            //one user task and same user other task
            setSendData1Tasks(treatedUserTasksData);
            setSendData2Tasks(treatedOtherTasksData);
            setSendName1('Colaborador');
            setSendName2('Colaborador - Outra Tarefa');
        } else if (
            props.checksStatus[5].activated &&
            props.checksStatus[0].activated &&
            !props.checksStatus[1].activated &&
            !props.checksStatus[2].activated &&
            !props.checksStatus[3].activated &&
            !props.checksStatus[4].activated
        ) {
            // one user task and other user same task
            setSendData1Tasks(treatedUserTasksData);
            setSendData2Tasks(treatedOtherUserTasksData);
            setSendName1('Colaborador');
            setSendName2('Outro Colaborador');
        } else if (
            props.checksStatus[4].activated &&
            props.checksStatus[0].activated &&
            !props.checksStatus[1].activated &&
            !props.checksStatus[2].activated &&
            !props.checksStatus[3].activated &&
            !props.checksStatus[5].activated
        ) {
            // user and other year
            setSendData1Tasks(treatedUserTasksData);
            setSendData2Tasks(treatedUserOtherYearTasksData);
            setSendName1('Colaborador');
            setSendName2('Colaborador - Outro Ano (' + otherYear + ')');
        } else if (
            props.checksStatus[0].activated &&
            !props.checksStatus[1].activated &&
            !props.checksStatus[2].activated &&
            !props.checksStatus[3].activated &&
            !props.checksStatus[4].activated &&
            !props.checksStatus[5].activated
        ) {
            // only user selected
            setSendData1Tasks(treatedUserTasksData);
            setSendData2Tasks(emptyTreatedData);
            setSendName1('Colaborador');
            setSendName2('');
        } else if (
            !props.checksStatus[0].activated &&
            !props.checksStatus[1].activated &&
            !props.checksStatus[2].activated &&
            !props.checksStatus[3].activated &&
            !props.checksStatus[4].activated &&
            !props.checksStatus[5].activated
        ) {
            //empty graph
            setSendData1Tasks(emptyTreatedData);
            setSendData2Tasks(emptyTreatedData);
            setSendName1('');
            setSendName2('');
        } else if (
            props.checksStatus[2].activated &&
            !props.actualDepartment[1] &&
            !props.checksStatus[0].activated &&
            !props.checksStatus[1].activated &&
            !props.checksStatus[3].activated &&
            !props.checksStatus[4].activated &&
            !props.checksStatus[5].activated
        ) {
            //one department
            setSendData1Tasks(treatedFirstDepartmentTasksData);
            setSendData2Tasks(emptyTreatedData);
            setSendName1('Setor');
            setSendName2('');
        } else if (
            props.checksStatus[2].activated &&
            props.actualDepartment[1] &&
            !props.checksStatus[0].activated &&
            !props.checksStatus[1].activated &&
            !props.checksStatus[3].activated &&
            !props.checksStatus[4].activated &&
            !props.checksStatus[5].activated
        ) {
            //two departments
            setSendData1Tasks(treatedFirstDepartmentTasksData);
            setSendData2Tasks(treatedSecondDepartmentTasksData);
            setSendName1('Setor');
            setSendName2('2º Setor');
        } else if (
            props.checksStatus[3].activated &&
            props.checksStatus[0].activated &&
            !props.checksStatus[1].activated &&
            !props.checksStatus[2].activated &&
            !props.checksStatus[4].activated &&
            !props.checksStatus[5].activated
        ) {
            // employee and company
            setSendData1Tasks(treatedUserTasksData);
            setSendData2Tasks(treatedCompanyTasksData);
            setSendName1('Colaborador');
            setSendName2('Empresa');
        } else if (
            !props.checksStatus[3].activated &&
            props.checksStatus[0].activated &&
            !props.checksStatus[1].activated &&
            props.checksStatus[2].activated &&
            !props.checksStatus[4].activated &&
            !props.checksStatus[5].activated
        ) {
            // employee and department
            setSendData1Tasks(treatedUserTasksData);
            setSendData2Tasks(treatedFirstDepartmentTasksData);
            setSendName1('Colaborador');
            setSendName2('Setor');
        } else if (
            !props.checksStatus[0].activated &&
            !props.checksStatus[1].activated &&
            !props.checksStatus[2].activated &&
            props.checksStatus[3].activated &&
            !props.checksStatus[4].activated &&
            !props.checksStatus[5].activated
        ) {
            //one company
            setSendData1Tasks(treatedCompanyTasksData);
            setSendData2Tasks(emptyTreatedData);
            setSendName1('Empresa');
            setSendName2('');
        } else if (
            !props.checksStatus[0].activated &&
            props.checksStatus[1].activated &&
            props.checksStatus[2].activated &&
            !props.checksStatus[3].activated &&
            !props.checksStatus[4].activated &&
            !props.checksStatus[5].activated
        ) {
            // department and other task
            setSendData1Tasks(treatedFirstDepartmentTasksData);
            setSendData2Tasks(treatedFirstDepartmentOtherTasksData);
            setSendName1('Setor');
            setSendName2('Setor - Outra Tarefa');
        } else if (
            !props.checksStatus[0].activated &&
            !props.checksStatus[1].activated &&
            !props.checksStatus[2].activated &&
            props.checksStatus[3].activated &&
            props.checksStatus[4].activated &&
            !props.checksStatus[5].activated
        ) {
            //company task and other year company
            setSendData1Tasks(treatedCompanyTasksData);
            setSendData2Tasks(treatedCompanyOtherYearTasksData);
            setSendName1('Empresa');
            setSendName2('Empresa - Outro Ano (' + otherYear + ')');
        } else if (
            !props.checksStatus[0].activated &&
            !props.checksStatus[1].activated &&
            props.checksStatus[2].activated &&
            !props.checksStatus[3].activated &&
            props.checksStatus[4].activated &&
            !props.checksStatus[5].activated
        ) {
            //department task and other year department
            setSendData1Tasks(treatedFirstDepartmentTasksData);
            setSendData2Tasks(treatedDepartmentOtherYearTasksData);
            setSendName1('Setor');
            setSendName2('Setor - Outro Ano (' + otherYear + ')');
        } else if (
            !props.checksStatus[0].activated &&
            props.checksStatus[1].activated &&
            !props.checksStatus[2].activated &&
            props.checksStatus[3].activated &&
            !props.checksStatus[4].activated &&
            !props.checksStatus[5].activated
        ) {
            //company task and other task company
            setSendData1Tasks(treatedCompanyTasksData);
            setSendData2Tasks(treatedCompanyOtherTasksData);
            setSendName1('Empresa');
            setSendName2('Empresa - Outra Tarefa');
        } else if (
            !props.checksStatus[0].activated &&
            !props.checksStatus[1].activated &&
            props.checksStatus[2].activated &&
            props.checksStatus[3].activated &&
            !props.checksStatus[4].activated &&
            !props.checksStatus[5].activated
        ) {
            //company task and other task company
            setSendData1Tasks(treatedCompanyTasksData);
            setSendData2Tasks(treatedFirstDepartmentTasksData);
            setSendName1('Empresa');
            setSendName2('Setor ');
        }
    }, [
        treatedUserTasksData,
        treatedFirstDepartmentTasksData,
        treatedSecondDepartmentTasksData,
        treatedCompanyTasksData,
        treatedUserOtherYearTasksData,
        treatedFirstDepartmentOtherTasksData,
        treatedCompanyOtherTasksData,
        treatedDepartmentOtherYearTasksData,
        treatedCompanyOtherYearTasksData,
        treatedOtherUserTasksData,
        treatedOtherTasksData,
        props.checksStatus[0].activated,
        props.checksStatus[1].activated,
        props.checksStatus[2].activated,
        props.checksStatus[5].activated,
        props.checksStatus[3].activated,
        props.checksStatus[4].activated,
        props.actualDepartment.length,
        props.setSelectedDeparmentCounter,
    ]);

    return (
        <>
            {jumpFirstRender ? (
                <>
                    <GraphicStyle id="canvas" isPDF={false}>
                        <Graph
                            data1Task={sendData1Tasks}
                            data2Task={sendData2Tasks}
                            sendName1={sendName1}
                            sendName2={sendName2}
                            notShowLegendOption={true}
                        />
                    </GraphicStyle>
                </>
            ) : null}
            <GenPDF node={document.getElementById("wrapped-tabpanel-1")} name={"ipa-tarefas"} coords={[5, 50, 215, 235]}/>
        </>
    );
};

export default TaskGraphDataTreatment;
