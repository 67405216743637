import React, {useContext, useEffect, useState} from "react";
import {Redirect, useHistory} from "react-router-dom";
import {authContext} from "../../../contexts/AuthContext";

/* Styles */
import {
    TableContainer,
    Spacer,
    DrawerAdjustment,
    ContainerTitle,
    Filters,
    Stripe,
    StyledContainerTitle,
    Title,
    ContainerColumn,
    MainComponents,
    GlobalFilterContainer,
    StyledHelp
} from './if-history.styles';
import {Label} from "./if-history-table/history-attribute-table/history-attribute-table.styles";

/* Components */
import HistoryTable from './if-history-table/history-table/history-table.component';
import Header from "../../../components/header/header.component";
import HistoryAttributeTable from "./if-history-table/history-attribute-table/history-attribute-table.component";
import GlobalCompanyFilter from "../../../components/globalFilter/globalFilter.component";
import { helpHistoryIF } from "../../../components/help/help.texts";
import Help from "../../../components/help/help.component";

/* Material UI */
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

/* Api */
import {getOneDepartment} from "../../../api/ApiDepartment";
import {getOneCompany} from "../../../api/ApiCompany";

/* Permissions */
import permissions, {getPermission, getSubmodulePermission} from "../../../utils/permission";
import {findIfSubmoduleIsActive} from "../../../api/ApiPemissions";
import {getInternetError} from "../../../utils/genericFunctions";

export default function HistoryIf() {
    const {auth} = useContext(authContext);
    const [radioValue, setRadioValue] = useState("employee");
    const [myDepartment, setMyDepartment] = useState({});
    const [globalCompany, setGlobalCompany] = useState(auth.data.user.permissionLevel !== 3 ? undefined : {name: ""});

    const [submodules, setSubmodules] = useState({
        history: []});

    let history = useHistory();

    const handleChange = (event) => {
        const {value, name} = event.target;
        if (name === "type")
            setRadioValue(value);
    };

    const changeActualGlobalCompany = (company) => {
        if(company !== null) {
            setGlobalCompany(company);
        }
    };

    useEffect(() => {

        const callApiFindDepartment = async (department, company) => {
            const response = await getOneDepartment(auth, department, company);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body.payload
        };

        const callApiFindOneCompany = async () => {
            const response = await getOneCompany(auth, auth.data.user.companyId);

            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body.payload[0];
        };

        const callFindIfSubmoduleIsActive = async (permissionRole, permissionModule) => {
            const response = await findIfSubmoduleIsActive(auth, permissionRole, permissionModule);
            const body = await response.json();

            return body.payload
        };

        let internetError = getInternetError();
        if(internetError) {
            history.replace('/error/500');
            return;
        }

        callApiFindDepartment(auth.data.user.departmentId, auth.data.user.companyId)
            .then(departmentRes => {
                setMyDepartment(departmentRes[0]);
            })
            .catch(err => console.log(err));

        callApiFindOneCompany()
            .then(actualCompany => {
                setGlobalCompany(actualCompany)
            })
            .catch(err => console.log(err))

        callFindIfSubmoduleIsActive(auth.data.user.permissionRoleId, permissions.IF_HISTORY)
            .then(resHistory => {
                setSubmodules({
                    history: resHistory})
            })
            .catch(err => console.log(err));
    }, []);

    const [help, setHelp] = useState(false);
    const onChangeHelp = () => {
        setHelp(!help);
    }

    return (
        <>
            {!getPermission(auth.data.permissions, permissions.IF_HISTORY) ?
                <Redirect to={permissions.IF_DEFAULT_ROUTE}/>
                : null}
            <Header/>
            <DrawerAdjustment>
                <Container>
                    <Spacer/>
                    <TableContainer>
                        <Help onClick={onChangeHelp} helpPopUp={helpHistoryIF} help={help} style={{padding: 200}}/> 
                        <MainComponents>
                            <div>
                                <StyledContainerTitle>
                                    <Stripe/>
                                    <ContainerTitle>
                                        <Title>
                                            Índice de Felicidade
                                        </Title>
                                        <span>Histórico Anual</span>
                                    </ContainerTitle>
                                </StyledContainerTitle>
                            </div>
                            {getSubmodulePermission(submodules.history, permissions.SYSTEM_GLOBAL_COMPANY_FILTER) ?
                                <GlobalFilterContainer>
                                    <GlobalCompanyFilter changeActualGlobalCompany={changeActualGlobalCompany}
                                                         globalCompany={globalCompany}/>
                                </GlobalFilterContainer> : null}
                        </MainComponents>

                        {getSubmodulePermission(submodules.history, permissions.EMPLOYEES_VISUALIZATION_FILTER) ?
                            <ContainerColumn>
                                <Filters>
                                    <FormControl component="fieldset">
                                        <Label>Filtrar tabelas por</Label>
                                        <RadioGroup aria-label="type" name="type" value={radioValue}
                                                    onChange={handleChange}
                                                    style={{display: "flex", flexDirection: "row"}}>
                                            <FormControlLabel value="employee" control={<Radio/>} label="Colaborador"/>
                                            <FormControlLabel value="department" control={<Radio/>} label="Setor"/> 
                                            { getSubmodulePermission(submodules.history, permissions.COMPANIES_VISUALIZATION_FILTER) ?
                                            <FormControlLabel value="company" control={<Radio/>} label="Empresa"/> : null}
                                        </RadioGroup>
                                    </FormControl>
                                </Filters>
                            </ContainerColumn> : null}
                        <HistoryTable radioValue={radioValue} submodules={submodules.history} department={myDepartment}
                                      globalCompany={globalCompany}/>
                        {(radioValue !== 'department' &&
                        getSubmodulePermission(
                            submodules.history,
                            permissions.EMPLOYEES_VISUALIZATION_FILTER_DEPARTMENT
                        ) || getSubmodulePermission(
                            submodules.history,
                            permissions.DEPARTMENTS_VISUALIZATION_FILTER
                        )) && radioValue !== "company" ? (
                            <HistoryAttributeTable 
                                radioValue={radioValue} 
                                department={myDepartment}
                                submodules={submodules.history} 
                                globalCompany={globalCompany}
                            />
                        ) : null}
                    </TableContainer>
                </Container>
                <Spacer/>
            </DrawerAdjustment>
        </>
    );
}