/* returns an array of strings of all years */
export function yearsUntilNow() {
    let date = new Date().toISOString();
    date = date.split("-");

    let years = [];
    const firstYear = "2018";
    let year = date[0];

    let total = parseInt(year) - parseInt(firstYear) + 1;

    for(let i=0; i < total; i++) {
        years[i] = parseInt(firstYear) + i;
        years[i] = years[i] + "";
    }

    return years;
}