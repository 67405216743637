import React, {useContext, useEffect, useState} from "react";
import {Redirect} from "react-router-dom";

/* Styles */
import {
    DrawerAdjustment, 
    Spacer,
    AddDepartmentModal,
    AddImage,
    AddName,
    AddPurpose,
    AddDeparment,
    AddDeparmentManager,
    ButtonContainer
} from "./add-department.styles";

import {
    WarningTitleContainer,
    WarningWord,
} from '../../../../components/form-list/form-list.styles';

/* Components */
import Header from "../../../../components/header/header.component";
import FormList from "../../../../components/form-list/form-list.component";
import PopUp from "../../../../components/popup/popup.component";
import RenderPage from "../../../../components/render-page/render-page.component";
import CustomButton from '../../../../components/./custom-button/custom-button.component';
import ImageEditor from "../../../.././components/./image-editor/image-editor";

/* Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

/* Context */
import {authContext} from "../../../../contexts/AuthContext";

/* api */
import {getOneCompany} from "../../../../api/ApiCompany";
import {
    addDepartment,
    getAllDepartments,
    uploadDepartmentImage
} from "../../../../api/ApiDepartment";

import { getAllActiveUsersInCompany } from "../../../../api/ApiUser";


/* Permissions */
import permissions, {getPermission} from "../../../../utils/permission";

/* utils */
const useStyles = makeStyles((theme) => ({
    paperModal: {
        marginTop: '10%',
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        width: 950,
        maxHeight: 900,
        borderRadius: '10px',
        backgroundColor: '#fff',
        padding: '10px',
        fontFamily: 'Roboto',
        textAlign: 'center',
        '@media (max-width: 750px)': {
            height: 620,
            marginTop: '80%',
            transform: 'translate(-50%, -50%) scale(0.7)',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200
    }
}));

export default function CreateDepartment(props) {
    const [name, setName] = React.useState('');
    const [purpose, setPurpose] = React.useState('');
    const [mainTitle, setMainTitle] = React.useState({});
    const [companyName, setCompanyName] = React.useState('');
    const [optionsDepartments, setOptionsDepartments] = React.useState([]);
    const [optionsDepartmentUsers, setOptionsDepartmentUsers] = React.useState([]);
    const [department, setDepartment] = React.useState({}); 
    const [departmentManager, setDepartmentManager] = React.useState({});   

    const {auth} = useContext(authContext);
    const classes = useStyles();

    const [popup, setPopup] = React.useState(false);
    const [popupText, setPopupText] = React.useState('');
    const [popupTitle, setPopupTitle] = React.useState('');
    const [success, setSuccess] = React.useState(1);
    const [fetchOpen, setFetchOpen] = useState(false);
    
    const [buttonPopUp, setButtonPopUp] = React.useState({
        popUp: false,
        popUpTitle: "",
        popUpText: "",
        success: 1,
        acceptable: true
    });

    const [warningTitle, setWarningTitle] = useState({
        phrase: `Criando setor na empresa `,
        object: `${props.company?.name}`,
        warning: parseInt(props.company.companyId) !== parseInt(auth.data.user.companyId) ? `(Cuidado! Você está criando um setor em outra empresa)` : ''
    });

    //image
    const [imageUrl, setImageUrl] = useState();
    const [preview, setPreview] = useState();
    const [imageFile, setImageFile] = useState();
    const [openEditor, setOpenEditor] = useState(false);

    let imageUpload = {
        title: 'Adicionar Imagem',
        preview: preview,
        setPreview: setPreview,
        openEditor: openEditor,
        setOpenEditor: setOpenEditor,
        src: imageUrl,

        imageFile: imageFile,
        setImageFile: setImageFile,
    };

    const handleClose = () => {
        setButtonPopUp({
            popUp: false,
            popUpTitle: "",
            popUpText: "",
            success: 0,
            acceptable: false
        });
    };

    const setNewFatherContext = () => {
        props.setReloadTable(!props.reloadTable);
        props.handleOpenCreate();
    };

    useEffect(() => {
        const callFindOneCompany = async () => {
            const response = await getOneCompany(auth, props.company.companyId);
            const body = await response.json();

            return body.payload
        };

        const callFindAllActiveUsersInCompany = async () => {
            const response = await getAllActiveUsersInCompany(auth, props.company.companyId);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            let res = body.payload;
            setOptionsDepartmentUsers(res);
        };

        const callFindAllDepartments = async () => {
            const response = await getAllDepartments(auth, props.company.companyId, true);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            let res = body.payload;
            const nenhumOption = {
                departmentId: null,
                name: 'Nenhum'
            };
            res.unshift(nenhumOption);
            setOptionsDepartments(res);
        };

        callFindOneCompany()
            .then(res => {
                setCompanyName(res[0].name);
                setMainTitle({
                    title: `Adicionar Setor na ${res[0].name}`,
                    subtitle: "Preencha os campos"
                });
            })
            .catch(err => console.log(err));

        callFindAllActiveUsersInCompany();
        callFindAllDepartments();
    }, []);

    const defaultPropsDepartmentUsers = {
        options: optionsDepartmentUsers,
        getOptionLabel: (option) => {
            return option.name || '';
        },
        getOptionSelected: (option) => {
            return option.name || '';
        },
        onChange: (event, newValue) => {
            setFetchOpen(true);
            setDepartmentManager(newValue);
        },
    };

    const defaultPropsDepartments = {
        options: optionsDepartments,
        getOptionLabel: (option) => {
            return option.name || '';
        },
        getOptionSelected: (option) => {
            return option.name || '';
        },
        onChange: (event, newValue) => {
            setFetchOpen(true);
            setDepartment(newValue);
        },
    };

    const handleSubmit = async event => {
        setPopup(false);
        event.preventDefault();
        setFetchOpen(true);
        let data = {
            name: name,
            purpose: purpose,
            company: props.company.companyId,
            managerId: departmentManager.userId ? departmentManager.userId : null,
            parentId: department.departmentId ? department.departmentId : null
        }

        try {   
            const response = await addDepartment(
                auth,
                data
            );
            setFetchOpen(false);
            if (response.status === 201) {
                if(imageFile) {
                    const { payload } = await response.json();
                    const imageResponse = await uploadDepartmentImage(auth, imageFile, payload[0].departmentId, data.company);
                    if(imageResponse.status === 500 || imageResponse.status === 422) {
                        setPopupTitle('Aviso');
                        setPopupText('Setor criado, mas não foi possivel fazer upload da imagem.');
                        setPopup(true);
                        setSuccess(3);
                    } else {
                        setPopupTitle('Sucesso');
                        setPopupText('Setor criado.');
                        setPopup(true);
                        setSuccess(3);
                    }
                } else {
                    setPopupTitle('Sucesso');
                    setPopupText('Setor criado.');
                    setPopup(true);
                    setSuccess(3);
                }
            } else {
                setPopupTitle('Erro');
                setPopupText('Setor não pode ser criado.');
                setPopup(true);
                setSuccess(3);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = event => {
        const {value, name, files} = event.target;
        if (name === 'name') {
            setName(value);
        } else if (name === 'purpose') {
            setPurpose(value);
        } else if (name === 'file') {    
            if(!files[0]) return;
        
            const maxSize = 2097152 // 2 MB
            
            if(files[0].type.split("/")[0] !== "image") {
                setPopupTitle('Erro');
                setPopupText('Tipo de arquivo inválido');
                setPopup(true);
                setSuccess(0);
                return;
            }
            
            if(files[0].size > maxSize) {
                setPopupTitle('Erro');
                setPopupText(`Arquivo muito grande. Tamanho máximo: ${maxSize / 1024 / 1024} MB`);
                setPopup(true);
                setSuccess(0);
                return;
            };
            
            if(preview) URL.revokeObjectURL(preview);
            if(imageUrl) URL.revokeObjectURL(imageUrl);
            
            const objectUrl = URL.createObjectURL(files[0])
            
            setImageUrl(objectUrl);
            setImageFile(files[0]);
            setOpenEditor(true);
        }
    };

    return (
        <div className={classes.paperModal}>
            {warningTitle ? (
                <WarningTitleContainer>
                    <Typography variant="h5">
                        {warningTitle.phrase}
                        <WarningWord>{warningTitle.object}</WarningWord>
                    </Typography>
                    <Typography variant="body2">
                        <WarningWord>{warningTitle.warning}</WarningWord>
                    </Typography>
                </WarningTitleContainer>
            ) : null}

            <AddDepartmentModal>
                <AddImage>
                    <ImageEditor
                        title={imageUpload.title}
                        src={imageUpload.src}
                        preview={imageUpload.preview}
                        setPreview={imageUpload.setPreview}
                        file={imageUpload.imageFile}
                        setFile={imageUpload.setImageFile}
                        openEditor={imageUpload.openEditor}
                        setOpenEditor={imageUpload.setOpenEditor}
                        handleChange={handleChange}
                        scale={15}
                    />
                </AddImage>
                <AddName>
                    <TextField
                        id="outlined-basic"
                        label="Nome"
                        variant="outlined"
                        name="name"
                        className={classes.name}
                        value={name}
                        type="text"
                        onChange={handleChange}
                        placeholder=""
                        maxLength="80"
                    />
                </AddName>
                <AddDeparmentManager>
                    <Autocomplete
                        {...defaultPropsDepartmentUsers}
                        id="departmentUsers"
                        autoSelect
                        renderInput={(params) => <TextField {...params} label="Gestor"
                                                            variant="outlined"/>}
                    />
                </AddDeparmentManager>
                <AddDeparment>
                    <Autocomplete
                        {...defaultPropsDepartments}
                        id="departments"
                        autoSelect
                        renderInput={(params) => <TextField {...params} label="Responsável"
                                                            variant="outlined"/>}
                    />
                </AddDeparment>
                <AddPurpose>
                    <TextField
                        id="outlined-basic" 
                        label="Propósito"
                        variant="outlined"
                        name="purpose"
                        multiline
                        className={classes.purpose}
                        value={purpose}
                        type="text"
                        onChange={handleChange}
                        placeholder=""
                        inputProps={{ maxLength: 448 }}
                    />
                </AddPurpose>
                <ButtonContainer
                    style={{
                        display: 'flex',
                    }}  
                >
                    <CustomButton
                        type="submit"
                        addButton="false"
                        onClick={handleSubmit}
                        style={{ marginLeft: '10px' }}
                    >
                        Adicionar
                    </CustomButton>
                </ButtonContainer>
            </AddDepartmentModal>
            {popup ? (
                <PopUp
                    title={popupTitle}
                    string={popupText}
                    success={success}
                    setNewFatherContext={setNewFatherContext}
                />
            ) : null}
            {buttonPopUp.popUp && (
                <PopUp 
                    title={buttonPopUp.popUpTitle} 
                    string={buttonPopUp.popUpText}
                    success={buttonPopUp.success} 
                    acceptable={buttonPopUp.acceptable}
                    acceptFunction={handleSubmit} 
                    handleClose={handleClose}
                />
            )}
        </div>
    );
}
