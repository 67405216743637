import React, { useCallback, useContext, useEffect, useState } from "react";
import { isUndefined } from "svgsaver/src/utils";
import { Redirect, useHistory } from "react-router-dom";

/* Material UI */
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";

/* Styles */
import {
    GraphicContainer,
    Spacer,
    Title,
    ContainerTitle,
    StyledFilter,
    AlignContainer,
    Filters,
    Stripe,
    StyledContainerTitle,
    DrawerAdjustment,
    SpacerFilter,
    GlobalFilterContainer,
    MainComponents,
} from "./if-graphs.styles";

/* Components */
import Graphic from "./if-graph/if-graph.component";
import Header from "../../../components/header/header.component";
import GlobalCompanyFilter from "../../../components/globalFilter/globalFilter.component";
import RenderPage from "../../../components/render-page/render-page.component";

/* icons */
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

/* api */
import {
    getAllActiveUsersInCompany,
    getAllActiveUsersInDepartment,
    getOneUser,
} from "../../../api/ApiUser";
import {
    getAllDepartments,
    getOneDepartment,
} from "../../../api/ApiDepartment";

/* Context */
import { authContext } from "../../../contexts/AuthContext";

/* Utils */
import { yearsUntilNow } from "../../../utils/initialData";

/* Permissions */
import permissions, {
    getPermission,
    getSubmodulePermission,
} from "../../../utils/permission";
import { findIfSubmoduleIsActive } from "../../../api/ApiPemissions";
import { getInternetError } from "../../../utils/genericFunctions";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 140,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    container: {
        "@media (max-width: 650px)": {
            padding: 0,
            minWidth: 320,
            maxWidth: 650,
            margin: 0,
        },
    },
}));

const optionsYear = yearsUntilNow();
const optionsOtherYears = yearsUntilNow();

function useForceUpdate() {
    const [, setTick] = useState(0);
    return useCallback(() => {
        setTick((tick) => tick + 1);
    }, []);
}

export default function GraphicsPage() {
    const classes = useStyles();

    const { auth } = useContext(authContext);

    const [year, setYear] = useState(new Date().getFullYear().toString());
    const [otherYear, setOtherYear] = useState('');
    const [department, setDepartment] = useState({ name: "" });
    const [selectedDepartments, setSelectedDepartments] = useState([]);
    const [isDepartmentDisable, setIsDepartmentDisable] = useState(false);
    const [tempDepartment, setTempDepartment] = useState(null);
    const [myDepartment, setMyDepartment] = useState(null); //I dont know if tempDepartment can be changed, but is the same concept but in an array
    const [user, setUser] = useState({
        userId: auth.data.user.userId,
        name: auth.data.user.name,
        companyId: auth.data.user.companyId,
    });
    const [simplifyGraph, setSimplifyGraph] = useState(true);
    const [checkboxControl, setCheckboxControl] = useState(true);
    const [optionsUsers, setOptionsUsers] = useState([]);
    const [optionsDepartments, setOptionsDepartments] = useState([]);
    const [departmentAndEmployee, setDepartmentAndEmployee] = useState(false); //verify if comparison is between department and employee to show just the employees inside the department
    const [userOptions, setUserOptions] = useState([]);
    //const [jumpFirstRender, setJumpFirstRender] = useState(false);
    const [series] = useState([
        {
            id: 0,
            name: "Colaborador",
            activated: true,
            color: "#424242",
            stroke: "solid",
        },
        {
            id: 1,
            name: "Setor",
            activated: false,
            color: "#424242",
            stroke: "solid",
        },
        {
            id: 2,
            name: "Empresa",
            activated: false,
            color: "#424242",
            stroke: "solid",
        },
        {
            id: 3,
            name: "Outro ano",
            activated: false,
            color: "#424242",
            stroke: "solid",
        },
    ]);

    const [seriesAux, setSeriesAux] = useState([
        { name: "Profissional", activated: false },
        { name: "Pessoal", activated: false },
        { name: "Financeiro", activated: false },
        { name: "Motivacional", activated: false },
        { name: "Saude", activated: false },
        { name: "Ambiente", activated: false },
        { name: "Média Anual", activated: true },
    ]);

    const [countCheckedFields, setCountCheckedFields] = useState(0);
    const [countCheckedDepartments, setCountCheckedDepartments] = useState(0);
    const [globalCompany, setGlobalCompany] = useState(
        auth.data.user.permissionLevel !== 3 ? undefined : { name: "" },
    );

    const [submodules, setSubmodules] = useState({
        graphs: [],
    });
    const [fetchOpen, setFetchOpen] = useState(true);
    let history = useHistory();

    const forceUpdate = useForceUpdate();

    const updateSeriesChild = (series) => {
        let aux = seriesAux;

        for (let i = 0; i < series.length; i++)
            aux[i].activated = series[i].activated;

        setSeriesAux(aux);
    };

    const callApiFindDepartment = async (department, globalCompany) => {
        const response = await getOneDepartment(
            auth,
            department,
            globalCompany,
        );
        const body = await response.json();

        if (response.status !== 200) throw Error(body.message);

        return body.payload;
    };

    const callApiFindOneUser = async (actualUser, globalCompany) => {
        const response = await getOneUser(auth, actualUser, globalCompany);
        const body = await response.json();

        if (response.status !== 200) throw Error(body.message);

        return body.payload;
    };
    const callGetAllUsersInCompany = async (company) => {
        const response = await getAllActiveUsersInCompany(auth, company);
        const body = await response.json();
        return body.payload;
    };

    const changeActualGlobalCompany = (company) => {
        setFetchOpen(true);
        if (company !== null) {
            setGlobalCompany(company);
        }
    };

    useEffect(() => {
        let tempDepartment;

        let actualGlobalCompany;

        /* The actualGlobalCompany is here and in graphic because both components have
        information that was related to the specific company defined in the global filter */
        if (!isUndefined(globalCompany)) {
            if (globalCompany.hasOwnProperty("companyId"))
                actualGlobalCompany = globalCompany.companyId;
            else actualGlobalCompany = auth.data.user.companyId;
        } else {
            actualGlobalCompany = auth.data.user.companyId;
        }

        const callFindIfSubmoduleIsActive = async (
            permissionRole,
            permissionModule,
        ) => {
            const response = await findIfSubmoduleIsActive(
                auth,
                permissionRole,
                permissionModule,
            );
            const body = await response.json();

            return body.payload;
        };

        callFindIfSubmoduleIsActive(
            auth.data.user.permissionRoleId,
            permissions.IF_GRAPHS,
        )
            .then((resGraphs) => {
                setSubmodules({
                    graphs: resGraphs,
                });
            })
            .catch((err) => console.log(err));

        const compressUsersName = (users) => {
            users = users.map(({ userId, name }) => ({
                userId,
                name,
            }));

            let temp;
            for (let i = 0; i < users.length; i++) {
                //logic to left just the 2 first names but iF has preposition left 3
                temp = users[i].name.split(" ");
                if (temp[1] !== undefined && temp[2] !== undefined) {
                    if (temp[1].length <= 2) {
                        users[i].name = temp[0] + " " + temp[1] + " " + temp[2];
                    } else {
                        users[i].name = temp[0] + " " + temp[1];
                    }
                } else if (temp[1] !== undefined) {
                    users[i].name = temp[0] + " " + temp[1];
                } else {
                    users[i].name = temp[0];
                }
            }

            return users;
        };

        const callApiUsersInDepartment = async (department, globalCompany) => {
            const response = await getAllActiveUsersInDepartment(
                auth,
                department,
                globalCompany,
            );
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return compressUsersName(body.payload);
        };
        const callGetAllUsersInDepartmentForManagers = async (
            departmentId,
            company,
        ) => {
            const response = await getAllActiveUsersInDepartment(
                auth,
                departmentId,
                company,
            );
            const body = await response.json();
            return body.payload;
        };

        if (auth.data.user.permissionRoleId === 10) {
            callGetAllUsersInDepartmentForManagers(
                auth.data.user.departmentId,
                user.companyId,
            ).then((res) => {
                setUserOptions(res);
            });
        } else {
            callGetAllUsersInCompany(actualGlobalCompany).then((res) => {
                setUserOptions(res);
            });
        }

        const callApiUsersInCompany = async (globalCompany) => {
            const response = await getAllActiveUsersInCompany(auth, globalCompany);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return compressUsersName(body.payload);
        };

        const callApiDepartments = async (globalCompany) => {
            const response = await getAllDepartments(auth, globalCompany);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body.payload;
        };

        let internetError;

        internetError = getInternetError();
        if (internetError) {
            history.replace("/error/500");
            return;
        }

        callApiUsersInCompany(actualGlobalCompany)
            .then((findAllUsersInRes) => {
                findAllUsersInRes = findAllUsersInRes.sort((a, b) =>
                    a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
                );
                let actualUser;

                /* In case the selected company is not the company of user who is logged in the code select the
                first user from the company list instead of the currently user*/
                if (user.companyId !== undefined &&user.companyId !== actualGlobalCompany) {
                    actualUser = findAllUsersInRes[0].userId;
                } else {
                    actualUser = user.userId;
                }

                callApiFindOneUser(actualUser, actualGlobalCompany)
                    .then((findUserRes) => {
                        callApiFindDepartment(
                            findUserRes[0].allocation.departmentId,
                            actualGlobalCompany,
                        )
                            .then((findDepartmentRes) => {
                                if (
                                    !department.hasOwnProperty("departmentId")
                                ) {
                                    setTempDepartment({
                                        departmentId:
                                            findDepartmentRes[0].departmentId,
                                        name: findDepartmentRes[0].name,
                                        companyId:
                                            findDepartmentRes[0].companyId,
                                    });
                                    setMyDepartment([
                                        {
                                            departmentId:
                                                findDepartmentRes[0]
                                                    .departmentId,
                                            name: findDepartmentRes[0].name,
                                            companyId:
                                                findDepartmentRes[0].companyId,
                                        },
                                    ]);
                                    tempDepartment = {
                                        departmentId:
                                            findDepartmentRes[0].departmentId,
                                        name: findDepartmentRes[0].name,
                                    };
                                }

                                if (
                                    departmentAndEmployee &&
                                    auth.data.user.permissionRoleId !== 10
                                ) {
                                    callApiUsersInDepartment(
                                        department.hasOwnProperty(
                                            "departmentId",
                                        )
                                            ? department.departmentId
                                            : tempDepartment.departmentId,
                                        actualGlobalCompany,
                                    )
                                        .then((findAllUsersInRes) => {
                                            setOptionsUsers(
                                                findAllUsersInRes.sort((a, b) =>
                                                    a.name > b.name
                                                        ? 1
                                                        : b.name > a.name
                                                        ? -1
                                                        : 0,
                                                ),
                                            );
                                        })
                                        .catch((err) => console.log(err));
                                } else {
                                    callApiUsersInCompany(actualGlobalCompany)
                                        .then((findAllUsersInRes) => {
                                            setOptionsUsers(
                                                findAllUsersInRes.sort((a, b) =>
                                                    a.name > b.name
                                                        ? 1
                                                        : b.name > a.name
                                                        ? -1
                                                        : 0,
                                                ),
                                            );
                                        })
                                        .catch((err) => console.log(err));
                                }
                                setFetchOpen(false);
                            })
                            .catch((err) => console.log(err));
                    })
                    .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));

        callApiDepartments(actualGlobalCompany)
            .then((res) => {
                setOptionsDepartments(
                    res.sort((a, b) =>
                        a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
                    ),
                );
            })
            .catch((err) => console.log(err));
    }, [
        department,
        user,
        departmentAndEmployee,
        globalCompany,
        year,
        otherYear,
        selectedDepartments,
    ]);

    const defaultPropsUsers = {
        options: optionsUsers,
        getOptionLabel: (option) => {
            return option.name;
        },
        getOptionSelected: (option) => {
            return option.name;
        },
        onChange: (event, newValue) => {
            setFetchOpen(true);
            setUser(newValue);
        },
    };

    const handleChange = (name) => {
        let count = 0;
        let numberOfCheckedBoxes = series.reduce(
            (total, checkBox) => (total += checkBox.activated),
            0,
        );
        let indexToUpdate = series.findIndex((element) => element.name === name);
        if (numberOfCheckedBoxes === 1 && series[indexToUpdate].activated)
            return;
        series[indexToUpdate].activated = !series[indexToUpdate].activated;

        if (series[indexToUpdate].activated) {
            series[indexToUpdate].color = "#424242";
        } else {
            series[indexToUpdate].color = "#9e9e9e";
        }

        for (let i = 0; i < 4; i++) {
            if (series[i].activated && series[i].id !== 1) count++;
        }

        if (!series[1].activated && series[1].id === 1) {
            //if department is disable, we clean the department array
            setSelectedDepartments([]);
            setIsDepartmentDisable(false);
        } else {
            count = count + selectedDepartments.length; //the count is different to department because you can select more than one department
        }

        setCountCheckedFields(count);
        setCheckboxControl(true);

        forceUpdate();
    };

    const handleChangeSimplify = () => {
        setSimplifyGraph(!simplifyGraph);
        setCheckboxControl(false);
    };

    const comparisonBetweenDepartmentAndEmployee = (value, department) => {
        if (department) {
            setDepartment(department);
        }
        setDepartmentAndEmployee(value);
    };

    return (
        <div>
            {!getPermission(auth.data.permissions, permissions.IF_GRAPHS) ? (
                <Redirect to={permissions.IF_DEFAULT_ROUTE} />
            ) : null}
            <Header />
            <DrawerAdjustment>
                <Container>
                    <div>
                        <Spacer />
                        <GraphicContainer id="graphic-container-0">
                            <MainComponents>
                                <StyledContainerTitle>
                                    <Stripe />
                                    <ContainerTitle>
                                        <Title>Resumo - Gráficos</Title>
                                        <span>Índice de Felicidade</span>
                                    </ContainerTitle>
                                </StyledContainerTitle>
                                {getSubmodulePermission(
                                    submodules.graphs,
                                    permissions.SYSTEM_GLOBAL_COMPANY_FILTER,
                                ) ? (
                                    <GlobalFilterContainer>
                                        <GlobalCompanyFilter
                                            changeActualGlobalCompany={
                                                changeActualGlobalCompany
                                            }
                                            globalCompany={globalCompany}
                                        />
                                    </GlobalFilterContainer>
                                ) : null}
                            </MainComponents>

                            <AlignContainer>
                                <StyledFilter>
                                    {/*Tags of checkbox that apea=*/}
                                    <Filters
                                        around={
                                            auth.data.user.permissionLevel !== 1
                                        }
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    style={{
                                                        color: series[0].color,
                                                    }}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            series[0].name,
                                                            e,
                                                        )
                                                    }
                                                    checked={
                                                        series[0].activated
                                                    }
                                                    disabled={
                                                        countCheckedFields >=
                                                            2 &&
                                                        !series[0].activated
                                                    }
                                                />
                                            }
                                            label={series[0].name}
                                        />

                                        {getSubmodulePermission(
                                            submodules.graphs,
                                            permissions.DEPARTMENTS_VISUALIZATION_FILTER,
                                        ) ||
                                        auth.data.user.permissionRoleId ===
                                            10 ? (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        style={{
                                                            color: series[1]
                                                                .color,
                                                        }}
                                                        onChange={(e) =>
                                                            handleChange(
                                                                series[1].name,
                                                                e,
                                                            )
                                                        }
                                                        checked={
                                                            series[1].activated
                                                        }
                                                        disabled={
                                                            countCheckedFields >=
                                                                2 &&
                                                            !series[1].activated
                                                        }
                                                    />
                                                }
                                                label={series[1].name}
                                            />
                                        ) : (
                                            <SpacerFilter />
                                        )}

                                        {/*submodules.graphs.length === 0 ?
                                            <span style={{alignSelf: 'center', marginRight: 10}}>Comparar com: </span> : null*/}
                                        {/*{getSubmodulePermission(submodules.graphs, permissions.COMPANIES_VISUALIZATION_FILTER) ?//TODO when the system get the company filter*/}
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    style={{
                                                        color: series[2].color,
                                                    }}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            series[2].name,
                                                            e,
                                                        )
                                                    }
                                                    checked={
                                                        series[2].activated
                                                    }
                                                    disabled={
                                                        countCheckedFields >=
                                                            2 &&
                                                        !series[2].activated
                                                    }
                                                />
                                            }
                                            label={series[2].name}
                                        />

                                        {/*: <SpacerFilter/>}*/}

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    style={{
                                                        color: series[3].color,
                                                    }}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            series[3].name,
                                                            e,
                                                        )
                                                    }
                                                    checked={
                                                        series[3].activated
                                                    }
                                                    disabled={
                                                        countCheckedFields >=
                                                            2 &&
                                                        !series[3].activated
                                                    }
                                                />
                                            }
                                            label={series[3].name}
                                        />
                                    </Filters>
                                    <Filters>
                                        {getSubmodulePermission(
                                            submodules.graphs,
                                            permissions.EMPLOYEES_VISUALIZATION_FILTER,
                                        ) && series[0].activated ? (
                                            <Autocomplete
                                                {...defaultPropsUsers}
                                                id="users"
                                                autoSelect
                                                options={userOptions}
                                                className={classes.formControl}
                                                style={{ width: 170 }}
                                                disableClearable
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Colaborador"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        ) : series[0].activated &&
                                          auth.data.user.permissionRoleId ===
                                              10 ? (
                                            <Autocomplete
                                                {...defaultPropsUsers}
                                                id="users"
                                                autoSelect
                                                options={userOptions}
                                                className={classes.formControl}
                                                style={{ width: 170 }}
                                                disableClearable
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Colaborador"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        ) : (
                                            <SpacerFilter />
                                        )}
                                        {getSubmodulePermission(
                                            submodules.graphs,
                                            permissions.DEPARTMENTS_VISUALIZATION_FILTER,
                                        ) && series[1].activated ? (
                                            <div style={{ paddingTop: 8 }}>
                                                <Autocomplete
                                                    multiple
                                                    value={selectedDepartments}
                                                    id="checkboxes-tags-demo"
                                                    getOptionSelected={(
                                                        option,
                                                        value,
                                                    ) =>
                                                        option.name ===
                                                        value.name
                                                    }
                                                    options={
                                                        countCheckedFields >= 2
                                                            ? selectedDepartments
                                                            : optionsDepartments
                                                    }
                                                    disableCloseOnSelect={
                                                        !isDepartmentDisable
                                                    }
                                                    getOptionLabel={(option) =>
                                                        option.name
                                                    }
                                                    renderOption={(
                                                        option,
                                                        { selected },
                                                    ) => (
                                                        <React.Fragment>
                                                            <Checkbox
                                                                icon={icon}
                                                                checkedIcon={
                                                                    checkedIcon
                                                                }
                                                                style={{
                                                                    marginRight: 8,
                                                                }}
                                                                checked={
                                                                    selected
                                                                }
                                                            />
                                                            {option.name}
                                                        </React.Fragment>
                                                    )}
                                                    onChange={(
                                                        event,
                                                        newValue,
                                                    ) => {
                                                        setCountCheckedDepartments(
                                                            newValue.length,
                                                        );

                                                        let count = 0;

                                                        for (
                                                            let i = 0;
                                                            i < 4;
                                                            i++
                                                        ) {
                                                            if (
                                                                series[i]
                                                                    .activated &&
                                                                series[i].id !==
                                                                    1
                                                            )
                                                                count++;
                                                        }

                                                        count =
                                                            count +
                                                            newValue.length; //the count is different to department because you can select more than one department

                                                        setCountCheckedFields(
                                                            count,
                                                        );
                                                        setSelectedDepartments(
                                                            newValue,
                                                        );

                                                        if (count >= 2) {
                                                            setIsDepartmentDisable(
                                                                true,
                                                            );
                                                        } else {
                                                            setIsDepartmentDisable(
                                                                false,
                                                            );
                                                        }
                                                        setFetchOpen(true);
                                                    }}
                                                    style={{ width: 200 }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="Setores"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        ) : (
                                            <SpacerFilter />
                                        )}
                                        <FormControl
                                            variant="outlined"
                                            className={classes.formControl}
                                        >
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                Ano
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={year}
                                                onChange={(e) => {
                                                    setFetchOpen(true);
                                                    setYear(e.target.value);
                                                }}
                                                label="Ano"
                                            >
                                                {optionsYear.map(
                                                    (year, index) => {
                                                        return (
                                                            <MenuItem
                                                                key={index}
                                                                value={year}
                                                            >
                                                                {year}
                                                            </MenuItem>
                                                        );
                                                    },
                                                )}
                                            </Select>
                                        </FormControl>
                                        {series[3].activated ? (
                                            <FormControl
                                                variant="outlined"
                                                className={classes.formControl}
                                            >
                                                <InputLabel id="demo-simple-select-outlined-label">
                                                    Outros anos
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={otherYear}
                                                    onChange={(e) => {
                                                        setFetchOpen(true);
                                                        setOtherYear(
                                                            e.target.value,
                                                        );
                                                    }}
                                                    label="Outros anos"
                                                >
                                                    {optionsOtherYears.map(
                                                        (otherYear, index) => {
                                                            return (
                                                                <MenuItem
                                                                    key={index}
                                                                    value={
                                                                        otherYear
                                                                    }
                                                                >
                                                                    {otherYear}
                                                                </MenuItem>
                                                            );
                                                        },
                                                    )}
                                                </Select>
                                            </FormControl>
                                        ) : (
                                            <SpacerFilter />
                                        )}
                                    </Filters>
                                </StyledFilter>
                            </AlignContainer>

                            <Graphic
                                otherYear={otherYear}
                                simplify={simplifyGraph}
                                year={year}
                                user={user}
                                department={department}
                                allowedGraphInfo={series}
                                allowedGraphInfoDepartments={
                                    auth.data.user.permissionRoleId === 10 &&
                                    myDepartment &&
                                    series[1].activated
                                        ? myDepartment
                                        : selectedDepartments
                                }
                                updateSeriesChild={updateSeriesChild}
                                seriesAtt={seriesAux}
                                countCheckedFields={countCheckedFields}
                                checkboxControl={checkboxControl}
                                comparisonBetweenDepartmentAndEmployee={
                                    comparisonBetweenDepartmentAndEmployee
                                }
                                tempDepartment={tempDepartment}
                                globalCompany={globalCompany}
                                handleChangeSimplify={handleChangeSimplify}
                                changeActualGlobalCompany={
                                    changeActualGlobalCompany
                                }
                            />
                        </GraphicContainer>
                    </div>
                </Container>
                {fetchOpen ? <RenderPage open={fetchOpen} /> : null}
            </DrawerAdjustment>
        </div>
    );
}