import styled, { css } from 'styled-components';

const buttonStyles = css`
    background-color: #424242;
    color: white;
    border: 1px solid #969faa;
    border-radius: 10px;
    text-transform: none;
    width: 50px;

    &:hover {
        background-color: #404040;
        border: none;
    }
`;

// #539086 #4a8077 #00695c #005046
const submitButtonStyles = css`
    background-color: #ff5722;
    color: white;
    border: none;
    border-radius: 4px;
    width: 340px;

    &:hover {
        background-color: #ff480e;
        border: none;
    }
`;

const submitButtonStylesIF = css`
    background-color: #ffc107;
    color: white;
    border: none;
    border-radius: 4px;
    width: 340px;

    &:hover {
        background-color: #edb100;
        border: none;
    }
`;

const pdfButtonStyles = css`
    background-color: rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0.87);
    border: 1px solid rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    padding: 5px 15px;
    font-size: 0.875rem;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 600;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }

    @media screen and (max-width: 500px) {
        margin-top: 10px;
        width: 160px;
        align-self: center;
    }
`;

const addButtonStyles = css`
    width: 185px;
    background-color: #87cebf;
    color: rgba(0, 0, 0, 0.87);
    border: 1.5px solid #109180;
    border-radius: 4px;
    text-transform: uppercase;
    text-align: center;
    font-size: 0.875rem;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 600;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;

    &:hover {
        background-color: #badad3;
        border: none;
    }
`;

const editButtonStyles = css`
    width: 185px;
    background-color: #77b5fe;
    color: rgba(0, 0, 0, 0.87);
    border: 1.5px solid #1976d2;
    border-radius: 4px;
    text-transform: uppercase;
    text-align: center;
    font-size: 0.875rem;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 600;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;

    &:hover {
        background-color: #abcdff;
        border: none;
    }
`;

const deleteButtonStyles = css`
    width: 185px;
    background-color: #f16669;
    color: rgba(0, 0, 0, 0.87);
    border: 1.5px solid #d32f2f;
    border-radius: 4px;
    text-transform: uppercase;
    text-align: center;
    font-size: 0.875rem;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 600;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;

    &:hover {
        background-color: #fd9c98;
        border: none;
    }
`;

const imageUploadButtonStyles = css`
    width: 185px;
    background-color: #808080;
    color: rgba(0, 0, 0, 0.87);
    border: 1.5px solid #d32f2f;
    border-radius: 4px;
    text-transform: uppercase;
    text-align: center;
    font-size: 0.875rem;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 600;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;

    &:hover {
        background-color: #b2beb5;
        border: none;
    }
`;

const freezeButtonStyles = css`
    width: 185px;
    background-color: #faa200;
    color: rgba(0, 0, 0, 0.87);
    border: 1.5px solid #e19100;
    border-radius: 4px;
    text-transform: uppercase;
    text-align: center;
    font-size: 0.875rem;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 600;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;

    &:hover {
        background-color: #fcc766;
        border: none;
    }
`;

const getButtonStyles = (props) => {
    if (props.submitButton) {
        if (props.systemType) return submitButtonStyles;
        else return submitButtonStylesIF;
    } else if (props.pdfButton) {
        return pdfButtonStyles;
    } else if (props.addButton) {
        return addButtonStyles;
    } else if (props.editButton) {
        return editButtonStyles;
    } else if (props.deleteButton) {
        return deleteButtonStyles;
    } else if (props.freezeButton) {
        return freezeButtonStyles;
    } else if (props.imageUploadButton) {
        return imageUploadButtonStyles;
    } else {
        return buttonStyles;
    }
};

export const CustomButtonContainer = styled.button`
    min-width: 110px;
    width: auto;
    height: 50px;
    letter-spacing: 0.5px;
    line-height: 50px;
    padding: 0 12px 0 12px;
    font-size: 15px;
    text-transform: uppercase;
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: bolder;
    cursor: pointer;
    outline: none;
    margin: 10px 0;

    ${getButtonStyles}
`;
