import styled from "styled-components";

export const Spacer = styled.div`
    height: 12vh;
    @media screen and (max-height: 600px) {
        height: 70px;
    }
`;

export const DrawerAdjustment = styled.div`
    padding-left: 72px;
    @media screen and (max-width: 800px) {
        padding-left: 0;
    }
`;

export const AddDepartmentModal = styled.div`
    display: grid;
    grid-template-areas:
        'column1 column1'
        'column2 column2'
        'column3 column4'
        'column5 column5'
        'button button';
    justify-items: center;
    padding: 0 120px;
`;

export const AddImage = styled.div`
    display: grid;
    grid-area: column1;
`;

export const AddName = styled.div`
    display: grid;
    grid-area: column2;
    width: 100%;
    padding: 10px;
`;

export const AddDeparmentManager = styled.div`
    display: grid;
    grid-area: column3;
    width: 100%;
    padding: 10px;  
`;

export const AddDeparment = styled.div`
    display: grid;  
    grid-area: column4;
    width: 100%;
    padding: 10px;
`;

export const AddPurpose = styled.div`
    display: grid;  
    grid-area: column5;
    width: 100%;
    padding: 10px;
`;

export const ButtonContainer = styled.div`
    display: grid;
    grid-area: button;
`;