import {apiUrl} from './Api';

export function getAllPermissions(auth) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/permissions`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}

export function findAllModules(auth, permission) {
    const authorization = `Bearer ${auth.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/permissions/${permission}/modules`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}

export function findIfSubmoduleIsActive(auth, permission, module) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/permissions/${permission}/modules/${module}/submodules`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}