import React, {Component} from 'react';
import {Switch, Route, BrowserRouter} from 'react-router-dom';

import PrivateRoute from "./PrivateRoute";

/* Styles */
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import MyTheme from './MyTheme';

/* IF */
import DashboardPage from "./pages/if/if-dashboard/if-dashboard.component";
import GraphicsPage from "./pages/if/if-graphs/if-graphs.component";
import MonthlyIf from "./pages/if/if-monthly/if-monthly.component";
import HistoryIf from "./pages/if/if-history/if-history.component";
import HelpPage from "./pages/if/if-help/if-help.component";

/* IPA */
import IpaDashboardPage from "./pages/ipa/ipa-dashboard/ipa-dashboard.component";
import AddTask from "./pages/ipa/ipa-tasks/manage-tasks-tab/add-task/add-task.component";
import EditTask from "./pages/ipa/ipa-tasks/manage-tasks-tab/edit-task/edit-task.component";
import AddCategory from "./pages/ipa/ipa-tasks/manage-categories-tab/add-category/add-category.component";
import EditCategory from "./pages/ipa/ipa-tasks/manage-categories-tab/edit-category/edit-category.component";
import AddTaskToUser from "./pages/ipa/ipa-tasks/manage-tasks-tab/add-task-to-user/add-task-to-user.component";
import IpaAlignmentPage from "./pages/ipa/ipa-alignment/ipa-alignment.component";
import DeleteTaskFromUser from "./pages/ipa/ipa-tasks/manage-tasks-tab/delete-task-from-user/delete-task-from-user.component";
import IpaTasksPage from "./pages/ipa/ipa-tasks/ipa-tasks.component";

/* System */
import Notifications from "./pages/notifications/notifications.component";
import Users from "./pages/management/management.component";
import UsersAddPage from "./pages/management/manage-users-tab/add-user/add-user.component";
import UsersEditPage from "./pages/management/manage-users-tab/edit-user/edit-user.component";
import CompaniesAdd from "./pages/management/manage-companies-tab/add-company/add-company.component";
import CompaniesEdit from "./pages/management/manage-companies-tab/edit-company/edit-company.component";
import CompaniesDepartmentAdd from "./pages/management/manage-companies-tab/add-department/add-department.component";
import DepartmentEdit from "./pages/management/manage-companies-tab/edit-department/edit-department.component";
import Ipa from './pages/ipa-component/ipa.component';
import SettingsPage from "./pages/settings/settings.component";
import NpsPage from "./pages/ipa/ipa-nps/ipa-nps.component";
import ErrorPage from "./components/error-page/error-page.component";
import IpaGraphPage from "./pages/ipa/ipa-graphs/ipa-graphs";
import NewSignInPage from './pages/sign-in/sign-in.component';

class App extends Component {
    constructor() {
        super();

        this.state = {
                    width:  500,
                    height: 800,
                    authUser: false
        }

    }

    render() {
        return (
            <MuiThemeProvider theme={MyTheme}>
                <BrowserRouter>
                    <Switch>
                        <Route exact path='/' component={NewSignInPage}/>
                        <Route exact path='/login' component={NewSignInPage}/>
                        {/* IPA */}
                        <PrivateRoute exact path='/ipa/dashboard' component={IpaDashboardPage}/>
                        <PrivateRoute exact path='/ipa/alignment' component={IpaAlignmentPage}/>
                        <PrivateRoute exact path='/ipa/tasks' component={IpaTasksPage}/>
                        <PrivateRoute exact path='/ipa/graphs' component={IpaGraphPage}/>
                        <PrivateRoute exact path='/management/company/:companyId/tasks/add' component={AddTask}/>
                        <PrivateRoute exact path='/management/company/:companyId/tasks/:taskId/edit' component={EditTask}/>
                        <PrivateRoute exact path='/management/company/:companyId/users/:userId/tasks' component={AddTaskToUser}/>
                        <PrivateRoute exact path='/management/company/:companyId/users/:userId/tasks/remove' component={DeleteTaskFromUser}/>
                        {/* IF */}
                        <PrivateRoute exact path='/dashboard' component={DashboardPage}/>
                        <PrivateRoute exact path='/graphics' component={GraphicsPage}/>
                        <PrivateRoute exact path='/monthly' component={MonthlyIf}/>
                        <PrivateRoute exact path='/history' component={HistoryIf}/>
                        {/* System */}
                        <PrivateRoute exact path='/management/users' component={Users}/>
                        <PrivateRoute exact path='/management/company/:companyId/users/add' component={UsersAddPage}/>
                        <PrivateRoute exact path='/management/company/:companyId/users/:userId/edit' component={UsersEditPage}/>
                        <PrivateRoute exact path='/management/companies/:id/add' component={CompaniesAdd}/>
                        <PrivateRoute exact path='/management/companies/:companyId/edit' component={CompaniesEdit}/>
                        <PrivateRoute exact path='/management/companies/:id/department/add' component={CompaniesDepartmentAdd}/>
                        <PrivateRoute exact path='/management/companies/:companyId/departments/:departmentId/edit' component={DepartmentEdit}/>

                        <PrivateRoute exact path='/management/categories' component={AddCategory}/> {/* IPA */}
                        <PrivateRoute exact path='/management/categories/:categoryId/edit' component={EditCategory}/> {/* IPA */}
                        <PrivateRoute exact path='/help' component={HelpPage}/>
                        <PrivateRoute exact path='/settings' component={SettingsPage}/>
                        <PrivateRoute exact path='/nps' component={NpsPage}/>
                        <PrivateRoute exact path='/ipa' component={Ipa}/>
                        <PrivateRoute exact path='/notifications' component={Notifications}/>

                        <PrivateRoute exact path='/error/:id' component={ErrorPage}/>
                        <PrivateRoute component={ErrorPage}/>
                    </Switch>
                </BrowserRouter>
            </MuiThemeProvider>
        );
    }
}

export default App;
