import React from "react";

/* Styles */
import {InsideContainer, TextContainer} from "./error-page.styles";

/* Components */
import Header from "../../components/header/header.component";

/* Material Ui */
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core/styles";

/* Icons */
import {ReactComponent as Error} from "../../assets/error.svg";
import {DashboardContainer, DrawerAdjustment, Spacer} from "../../pages/ipa/ipa-dashboard/ipa-dashboard.styles";


const TextTypography = withStyles({
    root: {
        color: "#424242",
        marginTop: "2vw"
    }
})(Typography);

export default function ErrorPage(props) {

    return (
        <>
            <Header/>
            <DrawerAdjustment>
                <Spacer/>
                <Container>
                    <DashboardContainer>
                        <InsideContainer>
                            <Error style={{width: 150, height: 150}}/>
                            <TextContainer>
                                <Typography variant="h2">
                                    Oops!
                                </Typography>
                                <TextTypography variant="h5">
                                    {parseInt(props.match.params.id) === 500 ? "Por favor, verifique sua conexão." :
                                            parseInt(props.match.params.id) === 1 ? "Algo deu errado." :
                                                "Erro 404, página não encontrada"}
                                </TextTypography>
                            </TextContainer>
                        </InsideContainer>
                    </DashboardContainer>
                </Container>
            </DrawerAdjustment>
        </>)
}