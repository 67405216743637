import React, {useContext, useEffect, useState} from "react";

/* CSS */
import {
    Spacer,
    NotificationsContainer,
    DrawerAdjustment
} from './notifications.styles';

/* Components */
import Header from "../../components/header/header.component";
import CardsList from "../../components/cards-list/cards-list.component";
import RenderPage from "../../components/render-page/render-page.component";

/* Context */
import {authContext} from "../../contexts/AuthContext";

/* Api */
import {getUserNotifications, markNotificationsAsRead} from "../../api/ApiUser";

/* Material UI */
import PopUp from "../../components/popup/popup.component";
import {useHistory} from "react-router-dom";
import {getInternetError} from "../../utils/genericFunctions";

export default function Notifications() {
    const {auth} = useContext(authContext);
    const [notifications, setNotifications] = useState([]);
    const [mainTitle, setMainTitle] = useState({
        title: "Suas notificações",
        subtitle: "",
        empty: false
    });
    const [checkboxInfo, setCheckboxInfo] = useState({
        title: "Marcar como lido",
        state: true,
        name: "checkboxInfo"});

    const [sentNotifications, setSentNotifications] = useState([]);

    const [popUp, setPopUp] = React.useState({
        popUp: false,
        popUpTitle: "",
        popUpText: "",
        success: 1,
    });
    let history = useHistory();
    const [fetchOpen, setFetchOpen] = useState(true);

    const callMarkNotificationAsRead = async (user, company, notifications) => {
        const response = await markNotificationsAsRead(auth, user, company, notifications);
        const body = await response.json();

        if (response.status !== 200) throw Error(body.message);

        return body.payload
    };

    const sendMarkNotificationAsRead = async (user, company, notifications) => {
        let resMarkNotificationsAsRead;
        try {
            resMarkNotificationsAsRead = await callMarkNotificationAsRead(user, company, notifications);
            setFetchOpen(false);
        } catch (e) {
            history.replace('/error/1');
            console.log(e);
        }
    };


    const handleChange = () => {
        if(!checkboxInfo.state) {
            setCheckboxInfo({
                title: "Notificações lidas",
                state: true,
                name: "checkboxInfo"});
            sendMarkNotificationAsRead(auth.data.user.userId, auth.data.user.companyId, sentNotifications).catch(err => console.log(err));
            window.location.reload(false);
        } else {
            setPopUp({
                popUp: true,
                popUpTitle: "Aviso",
                popUpText: "Você já leu todas as notificações!",
                success: 0,
            })
        }
    };

    useEffect(() => {
        const callFindUserNotifications = async (user, company) => {
            const response = await getUserNotifications(auth, user, company);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body.payload
        };

        const internetError = getInternetError();
        if(internetError) {
            history.replace('/error/500');
            return;
        }

        callFindUserNotifications(auth.data.user.userId, auth.data.user.companyId)
            .then(resNotifications => {
                resNotifications = resNotifications.sort((a, b) => (a.notificationId < b.notificationId) ? 1 : ((b.notificationId < a.notificationId) ? -1 : 0))
                if(resNotifications.length === 0) {
                    setMainTitle({
                        title: "Suas notificações",
                        subtitle: "",
                        empty: true
                    })
                }

                let sentNotifications = [], count=0;

                for(let i=0; i<resNotifications.length;i++) {
                    let tempData = resNotifications[i].createdAt.split("T");
                    resNotifications[i].createdAt  = `${tempData[0].split("-")[2]}/${tempData[0].split("-")[1]}/${tempData[0].split("-")[0]} ${tempData[1].split(":")[0]}:${tempData[1].split(":")[1]}`
                    if(resNotifications[i].situation === "sent") {
                        sentNotifications.push(resNotifications[i].notificationId);
                        count++;
                    }
                }

                if(sentNotifications.length === 0) {
                    setCheckboxInfo({
                        title: "Notificações lidas",
                        state: true,
                        name: "checkboxInfo"});
                } else {
                    setCheckboxInfo({
                        title: "Marcar como lido",
                        state: false,
                        name: "checkboxInfo"});
                }
                setSentNotifications(sentNotifications);
                setNotifications(resNotifications);

                if(sentNotifications.length !== 0) {
                    setFetchOpen(true);
                    sendMarkNotificationAsRead(auth.data.user.userId, auth.data.user.companyId, sentNotifications).catch(err => console.log(err));
                }
                setFetchOpen(false);
            })
            .catch(err => console.log(err));

    }, []);

    const handleClose = () => {
        setPopUp({
            popUp: false,
            popUpTitle: "",
            popUpText: "",
            success: 1,
        })
    };

    const notificationColors1 = {
        "read": "#fff",
        "sent": auth.data.system ? '#fff3e0' : '#fffde7'};

    return (
        <>
            <Header/>
            <DrawerAdjustment>
                <NotificationsContainer>
                    <Spacer/>
                    <CardsList rows={notifications} notificationColors1={notificationColors1} mainTitle={mainTitle}
                               icons={true} newsIcon={true} checkboxInfo={checkboxInfo} handleChange={handleChange}/>
                    {popUp.popUp ?
                        <PopUp title={popUp.popUpTitle} string={popUp.popUpText} success={popUp.success} route={popUp.route} handleClose={handleClose}/> : null}
                </NotificationsContainer>
            </DrawerAdjustment>
            {fetchOpen ?
                <RenderPage open={fetchOpen}/> : null}
        </>
    );
}
