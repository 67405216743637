import styled from 'styled-components';

export const TitleLogin = styled.div`
    align-self: center;
    padding-left: 72px;
    font-family: Abril Fatface;
    font-size: 38px;
    font-weight: 700;
    line-height: 32px;
    @media screen and (max-width: 1050px) {
        padding-left: 0px;
        font-size: 38px;
    }
`;

export const LoginForm = styled.div`
    align-self: start;
    padding-left: 72px;
    @media screen and (max-width: 1050px) {
        padding-left: 0px;
        display: grid;
    }
`;

export const LoginButton = styled.div`
    align-self: start;
    padding-left: 72px;
    font-family: Abril Fatface;
    @media screen and (max-width: 1050px) {
        padding-left: 0px;
    }
`;

export const ForgetPassword = styled.p`
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 14px;
    margin-top: 12px;
    margin-left: 4px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #fff;
        transform-origin: bottom right;
        transition: transform 0.4s ease-out;
    }
    &:hover:after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
`;
