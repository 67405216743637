import React, {useContext} from "react";
import {Redirect} from "react-router-dom";

/* Styles */
import { Spacer, DrawerAdjustment} from './if-help.styles';

/* Components */
import CardsList from "../../../components/cards-list/cards-list.component";
import Header from "../../../components/header/header.component";

/* Material UI */
import Container from "@material-ui/core/Container";
import {authContext} from "../../../contexts/AuthContext";

/* Permissions */
import permissions, {getPermission} from "../../../utils/permission";

let rows = [{header: "Profissional",
    content: "O aspecto profissional leva em conta sua vida e trajetória profissional de maneira geral, sobre sua formação e demais coisas que permeiam o aspecto profissional e não apenas o seu trabalho e função na Empresa."},
    {header: "Pessoal",
        content: "É uma avaliação de como está o seu status quo, relações interpessoais, família, amigos, relacionamento com colegas, entre outras coisas que permeiam o aspecto pessoal. Não é vinculado propriamente aos relacionamentos profissionais."},
    {header: "Financeiro",
        content: "O aspecto financeiro, vale ressaltar, que como os demais não condiz apenas com seu salário propriamente dito, ele diz respeito a sua vida financeira como um todo. "},
    {header: "Ambiente",
        content: "Este aspecto está relacionado a tudo que concerne o seu ambiente de trabalho."},
    {header: "Motivacional",
        content: "O aspecto motivação, busca medir como está sua auto motivação para conquistar seus objetivos."},
    {header: "Saúde",
        content: "Este aspecto busca entender se você tem desprendido de seu tempo e recurso para cuidar de sua saúde de maneira geral. Exemplos, exames rotineiros, ida ao dentista, check up geral, prática de exercícios físicos regularmente, etc."},
];

let mainTitle = {
    title: "Ajuda",
    subtitle: "Descrição dos campos IF"
};

export default function MonthlyIf() {
    const { auth } = useContext(authContext);

    return (
        <div>
            {!getPermission(auth.data.permissions, permissions.IF_HELP)?
                <Redirect to={permissions.IF_DEFAULT_ROUTE}/>
                : null}
                <Header/>
                <DrawerAdjustment>
                    <Container>
                        <Spacer/>
                        <CardsList rows={rows} notificationColors1={false} mainTitle={mainTitle} icons={true}/>
                    </Container>
                </DrawerAdjustment>
            </div>
    );
}


