/* Generic form component */
import React, { useRef } from 'react';

/* Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

/* Styles */
import {
    ModalContainer,
    StyledInput,
    StyledLabel,
    EachField,
    ContainerTitle,
    StyledContainerTitle,
    Title,
    Wrap,
    ContainerButton,
    FieldsRadio,
    InputRadios,
    Stripe,
    WarningTitleContainer,
    WarningWord,
    ContainerFilter,
    ContainerImage,
    UserImage,
} from './form-list.styles';

/* Components */
import InputMask from 'react-input-mask';
import CustomButton from '../custom-button/custom-button.component';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import AvatarEditor from 'react-avatar-editor';
import ImageEditor from '../image-editor/image-editor';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    input: {
        display: 'none',
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

export default function FormList(props) {
    const classes = useStyles();

    return (
        <ModalContainer>
            {props.mainTitle ? (
                <StyledContainerTitle>
                    <Stripe />
                    <ContainerTitle>
                        <Title>{props.mainTitle.title}</Title>
                        <span>{props.mainTitle.subtitle}</span>
                    </ContainerTitle>
                </StyledContainerTitle>
            ) : null}

            {props.warningTitle ? (
                <WarningTitleContainer>
                    <Typography variant="h5">
                        {' '}
                        {props.warningTitle.phrase}{' '}
                        <WarningWord>{props.warningTitle.object}</WarningWord>
                    </Typography>
                    <Typography variant="body2">
                        {' '}
                        <WarningWord>{props.warningTitle.warning}</WarningWord>
                    </Typography>
                </WarningTitleContainer>
            ) : null}
            {props.image && (
                <ImageEditor
                    title={props.image.title}
                    src={props.image.src}
                    preview={props.image.preview}
                    setPreview={props.image.setPreview}
                    file={props.image.imageFile}
                    setFile={props.image.setImageFile}
                    openEditor={props.image.openEditor}
                    setOpenEditor={props.image.setOpenEditor}
                    handleChange={props.handleChange}
                />
            )}
            <form onSubmit={props.handleSubmit}>
                <Wrap>
                    {props.radio1 ? (
                        <InputRadios>
                            <h3>{props.radio1.title}</h3>
                            {props.radio1.data.map((radio, index) => (
                                <FieldsRadio key={index}>
                                    <input
                                        type="radio"
                                        id={radio.id}
                                        name={radio.name}
                                        onChange={props.handleChange}
                                        value={radio.value}
                                        checked={
                                            props.radio1.checkValue ===
                                            radio.value
                                        }
                                    />
                                    <label htmlFor={radio.name}>
                                        {' '}
                                        {radio.label}{' '}
                                    </label>
                                </FieldsRadio>
                            ))}
                        </InputRadios>
                    ) : null}

                    {props.radio2 ? (
                        <InputRadios>
                            <h3>{props.radio2.title}</h3>
                            {props.radio2.data.map((radio, index) =>
                                !radio.limitedPermission ? (
                                    <FieldsRadio key={index}>
                                        <input
                                            type="radio"
                                            id={radio.id}
                                            name={radio.name}
                                            onChange={props.handleChange}
                                            value={radio.value}
                                            checked={
                                                props.radio2.checkValue ===
                                                radio.value
                                            }
                                        />
                                        <label htmlFor={radio.name}>
                                            {' '}
                                            {radio.label}{' '}
                                        </label>
                                    </FieldsRadio>
                                ) : null
                            )}
                        </InputRadios>
                    ) : null}

                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        {props.category ? (
                            <ContainerFilter>
                                <Autocomplete
                                    onChange={(event, newValue) =>
                                        props.setActualCategory(newValue)
                                    }
                                    getOptionSelected={(option, value) =>
                                        option.name === value.name
                                    }
                                    id="categories"
                                    options={props.category}
                                    getOptionLabel={(options) => options.name}
                                    autoSelect
                                    className={classes.formControl}
                                    style={{ width: 200 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={'categoria'}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </ContainerFilter>
                        ) : null}

                        {props.multipleFilters ? (
                            <ContainerFilter>
                                <Autocomplete
                                    multiple
                                    id="size-small-outlined-multi"
                                    size="small"
                                    options={props.defaultOptionsMultipleFilter}
                                    getOptionSelected={(option, value) =>
                                        option.name === value.name
                                    }
                                    style={{ minWidth: 250 }}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => {
                                        props.onChangeMultipleFilter(
                                            event,
                                            newValue
                                        );
                                    }}
                                    defaultValue={[
                                        props.defaultOptionsMultipleFilter[0],
                                    ]}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label={props.multipleFilters.label}
                                            placeholder={
                                                props.multipleFilters
                                                    .placeholder
                                            }
                                        />
                                    )}
                                />
                            </ContainerFilter>
                        ) : null}
                    </div>
                    {props.filter ? (
                        <ContainerFilter>
                            <StyledLabel>{props.filter.label}</StyledLabel>
                            <Autocomplete
                                {...props.defaultProps}
                                id="departments"
                                autoSelect
                                className={classes.formControl}
                                style={{
                                    width: 325,
                                    marginTop: 20,
                                    marginLeft: 20,
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" />
                                )}
                            />
                        </ContainerFilter>
                    ) : null}
                    {props.filter2 ? (
                        <ContainerFilter>
                            <StyledLabel>{props.filter2.label}</StyledLabel>
                            <Autocomplete
                                {...props.defaultProps2}
                                id="fortress"
                                className={classes.formControl}
                                style={{
                                    width: 325,
                                    marginTop: 20,
                                    marginLeft: 20,
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" />
                                )}
                            />
                        </ContainerFilter>
                    ) : null}
                    {props.rowsInput
                        ? props.rowsInput.map((row, index) => (
                              <EachField key={index}>
                                  <StyledLabel>{row.label}</StyledLabel>
                                  {!row.mask || row.name === 'cnpj/cpf' ? (
                                      <StyledInput
                                          name={row.name}
                                          type={row.type}
                                          onChange={props.handleChange}
                                          value={row.value}
                                          placeholder={row.placeholder}
                                          required={row.required}
                                          maxLength={row.maxLength}
                                      />
                                  ) : (
                                      <InputMask
                                          mask={row.mask}
                                          value={row.value}
                                          onChange={props.handleChange}
                                      >
                                          {(inputProps) => (
                                              <StyledInput
                                                  {...inputProps}
                                                  type={row.type}
                                                  name={row.name}
                                                  required={row.required}
                                              />
                                          )}
                                      </InputMask>
                                  )}
                              </EachField>
                          ))
                        : null}
                    {props.noOptions ? (
                        <Typography> {props.noOptions.title} </Typography>
                    ) : null}
                </Wrap>
                <ContainerButton>
                    {!props.noOptions && props.buttonInfo ? (
                        <CustomButton
                            type="submit"
                            addButton={props.buttonInfo.addButton}
                            editButton={props.buttonInfo.editButton}
                        >
                            {' '}
                            {props.buttonInfo.name}{' '}
                        </CustomButton>
                    ) : null}
                    {props.remove ? (
                        <CustomButton
                            type="button"
                            onClick={props.deleteUserModal}
                            deleteButton
                        >
                            {' '}
                            {props.remove.title}{' '}
                        </CustomButton>
                    ) : null}
                    {props.freeze && props.isUserActive ? (
                        <CustomButton
                            type="button"
                            onClick={props.freezeUserModal}
                            freezeButton
                        >
                            {' '}
                            {props.freeze.title}{' '}
                        </CustomButton>
                    ) : null}
                </ContainerButton>
            </form>
        </ModalContainer>
    );
}
