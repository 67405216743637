export const helpDashboarIPA = {
    title: 'Dashboard - IPA',
    paragraph1: 'O dashboard é responsável por mostrar informações referentes aos seus alinhamentos e tarefas, você pode escolher ver sua comparação com a empresa em qualquer mês.',
    paragraph2: 'O "E" se refere ao número de episódios, ou seja, número de vezes que você e um alinhamento se avaliaram mutualmente.',
    paragraph3: 'O "A" se refere ao número de pessoas com quem você tem alinhamento.',
    paragraph4: 'Já os "Matches" se referem a porcentagem de alinhamentos que lhe deu a mesma nota que você deu a eles. ',
    img: 'averageDashboard'

}

export const helpDashboardIF = {
    title: 'Dashboard - IF',
    paragraph1: 'O dashboard é responsável por mostrar informações referentes aos seus aspectos, você pode escolher ver sua comparação com a empresa em qualquer mês.',
    paragraph2: 'Para ver sobre aspectos específicos, altere onde está sendo mostrado atualmente o aspecto geral.',
    img: 'averageDashboard'
}

export const helpSendIf = {
    title: 'Enviar IF',
    paragraph1: 'Os valores do IF são preenchidos uma vez ao mês e as respostas são sempre referentes ao mês anterior.',
    paragraph2: 'Você deve dar uma nota entre -3 e 3 para os aspectos solicitados, informando a empresa e a si mesmo como você está referente a eles.',
    paragraph3: 'O IF deve ser preenchido até o dia 05 do mês atual.',
    img: 'sendIF'
}

export const helpSendAlignments = {
    title: 'Enviar Alinhamentos',
    paragraph1: 'Os alinhamentos são preenchidos referente ao mês atual e podem ser preenchidos quantas vezes você julgar necessário.',
    paragraph2: 'Você deve dar uma nota entre -3 e 3 para os seus alinhamentos, referente ao seu relacionamento com o mesmos.',
    img: 'sendAlignments'
}

export const helpSendTasks = {
    title: 'Enviar Tarefas',
    paragraph1: 'As tarefas são preenchidas referente ao mês atual e podem ser preenchidas quantas vezes você julgar necessário.',
    paragraph2: 'Você deve dar uma nota entre -3 e 3 para as suas tarefas, de acordo com o seu desempenho nas mesmas.',
    img: 'sendTasks'
}

export const helpHistoryIF = {
    title: 'Histório - IF',
    paragraph1: 'Na tabela referente ao histórico do IF você pode acompanhar os valores preenchidos em cada aspecto.',
    paragraph2: 'Na coluna mais a direita, está a média anual com base nos valores obtidos.',
    paragraph3: 'Na penúltima linha, está a sua média de valores em cada mês. Na última linha, está a média da empresa em cada mês.',
    paragraph4: 'A média tem valores de 0 à 100 pois se refere a porcentagem dos dados, e não a média simples entre os valores.'
}

export const helpHistoryAlignments = {
    title: 'Histório - Alinhamentos',
    paragraph1: 'Na primeira tabela você pode acompanhar o grau de alinhamento entre você e os seus colegas com base nas notas dadas por ambos. Já na segunda tabela você tem as notas que deu aos seus alinhamentos.',
    paragraph2: '"Match!", você e o seu colega se avaliaram com a mesma nota.',
    paragraph3: '"Atenção", você e o seu colega se avaliaram com notas com diferença de 1 ponto.',
    paragraph4: '"Procure seu colega", você e o seu colega se avaliaram com notas com diferença maior que 1 ponto.'
}

export const helpHistoryTasks = {
    title: 'Histório - Tarefas',
    paragraph1: 'Na tabela referente ao histórico das tarefas você pode acompanhar os valores preenchidos em cada tarefa.',
    paragraph2: 'Na coluna mais a direita, está a média anual com base nos valores obtidos e na última linha, está a sua média de valores mensais em cada tarefa.',
    paragraph3: 'Caso deseje ver suas notas em tarefas que foram desativadas, basta selecionar "mostrar tarefas desativadas".',
    paragraph4: 'A média tem valores de 0 à 100 pois se refere a porcentagem dos dados, e não a média simples entre os valores.',
}

export const helpMatchesAndEpisodesTable = {
    title: 'Tabela de Matches e Episódios.',
    paragraph1: 'Episódios: número de vezes em que você e o seu alinhamento se avaliaram mutualmente.',
    paragraph2: 'Matches: quando a nota dada por você e o seu alinhamento são a mesma.',
    paragraph3: 'Alinhamentos Ativos: o número de alinhamentos que você tem no momento.',
    paragraph4: 'Porcentagem: cálculo feito sobre o número de matches e alinhamentos ativos.',
}