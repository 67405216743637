import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

/* Styles */
import {} from "./manage-categories-tab.styles";

/* Components */
import CustomButton from "../../../../components/custom-button/custom-button.component";
import EnhancedTable from "../../../../components/table-to-show-data/table-to-show-data.component";
import RenderPage from "../../../../components/render-page/render-page.component";

/* Material UI */

/* Context */
import {authContext} from "../../../../contexts/AuthContext";

/* Api*/
import {findAllCategories} from "../../../../api/ApiCategory";
import {ContainerButtons, StyledInput} from "../manage-tasks-tab/manage-tasks-tab.styles";


export default function ManageIpaCategories() {
    const {auth} = useContext(authContext);
    const [searchText, setSearchText] = useState('');

    const [categoriesRows, setCategoriesRows] = useState([]);
    const [categoriesData, setCategoriesData] = useState(categoriesRows);
    const [fetchOpen, setFetchOpen] = useState(true);

    const categoryTitle = {
        firstText: "Todas as categorias do IPA",
        warningText: ""
    };

    let history = useHistory();

    const categoriesHeadCells = [
        {id: 'id', numeric: false, disablePadding: false, label: 'Código'},
        {id: 'name', numeric: false, disablePadding: false, label: 'Nome'},
        {id: 'action', numeric: true, disablePadding: false, label: 'Ação'}
    ];

    let edit = {
        buttonLabel: "Editar",
        start: `/management/categories/`,
        end: `/edit`,
        firstInformation: "categoryId"
    };

    useEffect(() => {
        const callApiFindAllCategories = async () => {
            const response = await findAllCategories(auth);
            const body = await response.json();
            if (response.status !== 200) throw Error(body.message);

            return body.payload
        };

        callApiFindAllCategories()
            .then(categoriesRes => {
                let newDataSort = [];

                for (let i = 0; i < categoriesRes.length; i++) {
                    newDataSort[i] = {
                        categoryId: categoriesRes[i]["categoryId"],
                        name: categoriesRes[i]["name"],
                        action: 1
                    };
                }
                setCategoriesData(newDataSort);
                setCategoriesRows(newDataSort);
                setFetchOpen(false);
            })
            .catch(err => console.log(err));


    }, []);

    // handle change event of search input
    const handleChange = value => {
        setSearchText(value);
        filterData(value);
    };

    // exclude column list from filter
    let excludeColumns = ["email", "permission", "role"];

    const filterData = (value) => {
        let aux = '';
        const lowercasedValue = value.toLowerCase().trim();

        if (lowercasedValue === '') setCategoriesRows(categoriesData);
        else {
            const filteredData = categoriesData.filter(item => {
                return Object.keys(item).some(key =>
                    excludeColumns.includes(key) ? false : item[key] ? item[key].toString().toLowerCase().includes(lowercasedValue) : aux.toString().toLowerCase().includes(lowercasedValue)
                );
            });
            setCategoriesRows(filteredData);
        }
    };

    const addCategory = () => {
        history.replace(`/management/categories`);
    };

    return (
        <>
            <ContainerButtons>
                <div>
                    <StyledInput
                        type="text"
                        placeholder="Digite para pesquisar..."
                        value={searchText}
                        onChange={e => handleChange(e.target.value)}
                    />
                </div>
                <CustomButton type='button' onClick={addCategory} addButton> Nova Categoria </CustomButton>
            </ContainerButtons>
            <EnhancedTable rows={categoriesRows} headCells={categoriesHeadCells} title={categoryTitle}
                           edit={edit}/>
            {fetchOpen ?
                <RenderPage open={fetchOpen}/> : null}
        </>
    );
}
