import React, {useContext, useState} from "react";
import {Redirect} from "react-router-dom";

import {formatCpfAndCnpj} from "../../../../utils/format";

/* Styles */
import {DrawerAdjustment, Spacer} from './add-company.styles';

/* Component */
import Header from "../../../../components/header/header.component";
import FormList from "../../../../components/form-list/form-list.component";
import PopUp from "../../../../components/popup/popup.component";
import RenderPage from "../../../../components/render-page/render-page.component";

/* Context */
import {authContext} from "../../../../contexts/AuthContext";

/* Material UI */
import Container from "@material-ui/core/Container";

/* API */
import {addCompany} from "../../../../api/ApiCompany";

/* Permissions */
import permissions, {getPermission} from "../../../../utils/permission";

export default function CompaniesAdd() {
    const [name, setName] = React.useState('');
    const [cnpj, setCnpj] = React.useState('');

    const [popup, setPopup] = React.useState(false);
    const [popupText, setPopupText] = React.useState('');
    const [popupTitle, setPopupTitle] = React.useState('');
    const [success, setSuccess] = React.useState(1);
    const [route, setRoute] = React.useState('');
    const [fetchOpen, setFetchOpen] = useState(false);

    const {auth} = useContext(authContext);

    let rowsInput = [{name: "name", type: "text", value: name, label: "Nome da empresa", placeholder: "", mask: false, required: true, maxLength: 40},
        {name: "cnpj/cpf", type: "text", value: cnpj, label: "CNPJ ou CPF", placeholder: "", mask: "99.999.999/9999-99", required: true, maxLength: 18}];

    let mainTitle = {
        title: "Adicionar Empresa",
        subtitle: "Preencha os campos"
    };

    let buttonInfo = {
        name: "Adicionar",
        addButton: true,
        editButton: false,
    };

    const handleSubmit = async event => {
        event.preventDefault();
        setFetchOpen(true);

        let data = {
            name: name,
            cnpj: cnpj
        };

        if((cnpj.length !== 18) && (cnpj.length !== 14)) {
            setPopupTitle('Erro');
            setPopupText('Digite um CPF ou um CNPJ.');
            setPopup(true);
            setSuccess(1);
            return
        }

        try {
            let response = await addCompany(auth, data);
            setFetchOpen(false);
            if(response.status === 201){
                setPopupTitle('Sucesso');
                setPopupText('Empresa criada.');
                setPopup(true);
                setSuccess(2);
                setRoute('/management/users');
            } else {
                setPopupTitle('Erro');
                setPopupText('Empresa não pode ser criada.');
                setPopup(true);
                setSuccess(1);
            }

        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = event => {
        const {value, name} = event.target;
        if (name === 'name') {
            setName(value);
        } else {
            setCnpj(formatCpfAndCnpj(value));
        }
    };

    return (
        <div>
            {!getPermission(auth.data.permissions, permissions.SYSTEM_COMPANIES) ?
                <Redirect to={auth.data.system ? permissions.IPA_DEFAULT_ROUTE : permissions.IF_DEFAULT_ROUTE}/>
                : null}
            <Header/>
            <DrawerAdjustment>
                <Container>
                    <Spacer/>
                    <FormList rowsInput={rowsInput} handleSubmit={handleSubmit} handleChange={handleChange}
                              buttonInfo={buttonInfo} mainTitle={mainTitle}/>
                </Container>
                <Spacer/>
            </DrawerAdjustment>
            { popup ?
                <PopUp title={popupTitle} string={popupText} success={success} route={route}/> : null}
            {fetchOpen ?
                <RenderPage open={fetchOpen}/> : null}
        </div>
    );
}
