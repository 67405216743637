import {makeStyles} from "@material-ui/core/styles";
import deepOrange from "@material-ui/core/colors/deepOrange";

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
    active: {
        display: 'block',
        backgroundColor: 'rgba(0, 0, 0, 0.3)'
    },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
    },
    colorText: {
        color: '#fff',
    },
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        background: '#4f4f4f',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        background: '#4f4f4f',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    drawerPaper: {
        background: '#424242',
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    grow: {
        flexGrow: 1,
    },
    iconExtraSm: {
        width: 25,
        height: 25,
        fill: '#fff'
    },
    iconSm: {
        width: 27,
        height: 27,
        fill: '#fff'
    },
    iconMd: {
        width: 31,
        height: 31,
        fill: '#fff'
    },
    iconLg: {
        width: 35,
        height: 35,
        fill: '#fff'
    },
    dot: {
        width: 35,
        height: 35,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: '#fff'
        }
    },
    dotButton: {
        width: 35,
        height: 35,
        borderRadius: '50%',
        paddingTop: 6,
        paddingLeft: 5,
        '&:hover': {
            backgroundColor: '#fff'
        }
    },
}));
