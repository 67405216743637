import styled from "styled-components";

export const CategoryBar = styled.div`
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    @media screen and (max-width: 700px) {
        margin-top: 0;
    }
`;

export const StyledCard = styled.div`
    background: ${({color}) => color === 0 ? '#ffa733' : //all
    color === 1 ? '#ffab91' : //Professional
        color === 2 ? '#ffc3ee' : //Personal
            color === 3 ? '#FFD44F' : //Financial
                color === 4 ? '#94FA92' : //Motivational
                    color === 5 ? '#80cbc4' : //Health
                        color === 6 ? '#769BF2' : //Environment
                            color === 7 ? '#ffa733' : //Tasks
                                color === 8 ? '#FFD44F' : //Alignments
                                    null
};
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    @media screen and (max-width: 600px) {
        padding: 5px;
    }
`;

export const TextCategoryBar = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
`;

export const Dot = styled.div`
    height: 75px;
    width: 75px;
    background-color: #bbb;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #424242;
    @media screen and (max-width: 500px) {
        height: 70px;
        width: 70px;
        padding: 4px;
    }
    background-color: ${({color}) => color ? color : null
    };
`;

export const StyledSpan = styled.div`
    color: #212121;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    width: 110px;
`;

