import styled from 'styled-components';

export const TableContainer = styled.div`
    padding: 40px;
    background-color: white;
    border-bottom: 1px solid #cfd8dc;
    border-right: 1px solid #cfd8dc;
    border-left: 1px solid #cfd8dc;
   @media screen and (max-width: 650px) {
        padding: 0px;
    }
`;

export const MainComponents = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 60px;
    @media screen and (max-width: 650px) {
        justify-content: center;
    }
`;

export const Spacer = styled.div`
    height: 12vh;
    @media screen and (max-height: 600px) {
        height: 70px;
    }
`;

export const DrawerAdjustment = styled.div`
    height: 70%;
    margin-bottom: 80px;
    margin-left: 100px;
    margin-right: 100px;
    padding-left: 72px;
    @media screen and (max-width: 800px) {
        padding-left: 0;
    }
   @media screen and (max-width: 650px) {
        margin-left: 24px;
        margin-right: 24px;
        margin-bottom: 20px;
        padding: 0px;
    }
`;

export const StyledContainerTitle = styled.div`
    width: 40%;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 650px) {
        width: 0;
        height: 0;
        visibility: hidden; 
    }
`;

export const ContainerTitle = styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 650px) {
        width: 0;
        height: 0;
        visibility: hidden; 
    }
`;
export const Title = styled.h2`
    margin-bottom: 8px;
    margin-top: 8px;
    @media screen and (max-width: 650px) {
        width: 0;
        height: 0;
        visibility: hidden; 
    }
`;

export const Stripe = styled.div`
    margin-top: 6px;
    margin-right: 13px;
    border-left:5px solid #4f4f4f;
    height: 70px;
    @media screen and (max-width: 650px){
        width: 0;
        height: 0;
        visibility: hidden;  
    }
`;

export const AlignTable = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;

export const GlobalFilterContainer = styled.div`
    padding-top: 12px;
    width: ${({tab}) => tab === 0 ? '60%' : 'auto'};
    @media screen and (max-width: 650px){
        width: auto;
    }
`;

export const TableWidth = styled.div`
    width: ${({width}) => width ? width : 'auto'};
    @media screen and (max-width: 1500px){
        width: 70%;
    }
    @media screen and (max-width: 1100px){
        width: 80%;
    }
    @media screen and (max-width: 835px){
        width: 90%
    }
    @media screen and (max-width: 770px){
        width: 100%
    }
`;
