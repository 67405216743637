import React, {useContext, useEffect, useState} from "react";

/* Components */
import PopUp from "../../../../../components/popup/popup.component";
import CustomButton from "../../../../../components/custom-button/custom-button.component";
import EnhancedTable from "../../../../../components/table-to-show-data/table-to-show-data.component";

/* Context */
import {authContext} from "../../../../../contexts/AuthContext";

/* Api*/
import {addTaskToUser, findAllAvailableTasks} from "../../../../../api/ApiTasks";

export default function AddTaskToUser({
    user,
    companyId,
    category,
    availableTasks,
    setAvailableTasks,
    setTasksByUserData,
    toggleButton,
    selectedTasks,
    setSelectedTasks,
    handleClick,
    isSelected
}) {
    const {auth} = useContext(authContext);

    const [reloadTable, setReloadTable] = useState(false);
    const [popup, setPopup] = useState(false);
    const [popupText, setPopupText] = useState('');
    const [popupTitle, setPopupTitle] = useState('');
    const [success, setSuccess] = useState(1);
    const [tableTitle, setTableTitle] = useState({});

    const availableTasksHeadCells = [
        {
            id: 'taskId',
            numeric: false,
            disablePadding: false,
            label: 'Código',
        },
        { id: 'name', numeric: false, disablePadding: false, label: 'Nome' },
        {
            id: 'description',
            numeric: false,
            disablePadding: false,
            label: 'Descrição',
        },
        {
            id: 'categoryName',
            numeric: false,
            disablePadding: false,
            label: 'Categoria',
        },
        { id: 'action', numeric: true, disablePadding: false, label: 'Ação' },
    ];

    const setNewFatherContext = () => {
        setReloadTable(!reloadTable)
    };

    const handleSubmit = async event => {
        event.preventDefault();

        let data = {
            user: user.userId,
            company: companyId,
            tasks: selectedTasks
        };

        try {
            let response = await addTaskToUser(data, auth);
            
            setSelectedTasks([]);

            if(response.status === 201){
                setPopupTitle('Sucesso');
                setPopupText(`As tarefas foram adicionadas à ${user.name}.`);
                setPopup(true);
                setSuccess(3);
            } else {
                setPopupTitle('Erro');
                setPopupText(`As tarefas não puderam ser adicionadas à ${user.name}.`);
                setPopup(true);
                setSuccess(3);
            }

        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const callApiFindAllAvailableTasks = async () => {
            const response = await findAllAvailableTasks(auth, companyId, user.userId);

            let { payload } = await response.json();

            if(category) {
                payload = payload.filter(task => task.categoryId === category.categoryId);
            }
            
            payload.forEach(task => {
                delete task.active;
                delete task.companyId;
                delete task.categoryId;
                task.action = 1;
            });

            setAvailableTasks(payload);
            setTasksByUserData(payload);
        }

        try {
            if(user)
                callApiFindAllAvailableTasks();
        } catch (error) {
            console.log(error);
        }
    }, [user, companyId, category, reloadTable, toggleButton]);

    useEffect(() => {
        setTableTitle({
            firstText: 'Adicionando tarefas para ',
            warningText: user ? user.name : '',
        })
    }, [user]);

    return (
        <>
            <div>
                <EnhancedTable
                    rows={availableTasks}
                    headCells={availableTasksHeadCells}
                    title={tableTitle}
                    checkbox={true}
                    handleClick={handleClick}
                    isSelected={isSelected}
                />
            </div>
            <div style={{display: 'flex', justifyContent: 'right'}}>
                <CustomButton
                    type="button"
                    onClick={handleSubmit}
                    style={
                        selectedTasks.length === 0 || 
                        !user ? 
                        { opacity: 0.2 }:
                        { opacity: 1 }
                    }
                    disabled={
                        selectedTasks.length === 0 ||
                        !user ? 
                        true: 
                        false
                    }
                    addButton
                >
                    {' '}
                    Adicionar Tarefas{' '}
                </CustomButton>
            </div>
            { popup ?
                <PopUp 
                    title={popupTitle} 
                    string={popupText} 
                    success={success} 
                    setNewFatherContext={setNewFatherContext} 
                />
            : null}
        </>
    );
}