import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

/* Styles */
import {
    TableContainer,
    Spacer,
    DrawerAdjustment,
    ContainerTitle,
    Stripe,
    StyledContainerTitle,
    Title,
    AlignTable,
    TableWidth,
    MainComponents,
    GlobalFilterContainer,
} from './management.styles';

/* Components */
import Header from '../../components/header/header.component';
import UsersCompaniesTab from './manage-companies-tab/manage-companies-tab.component';
import UsersRecordsTab from './manage-records-tab/manage-records-tab.component';
import UsersTab from './manage-users-tab/manage-users-tab.component';
import GlobalCompanyFilter from '../../components/globalFilter/globalFilter.component';

/* Material UI*/
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

/* api */
import { getOneCompany } from '../../api/ApiCompany';

/* Context */
import { authContext } from '../../contexts/AuthContext';

/* Permissions */
import permissions, {
    getSubmodulePermission,
    setTabsOrder,
} from '../../utils/permission';
import { findIfSubmoduleIsActive } from '../../api/ApiPemissions';
import { getInternetError } from '../../utils/genericFunctions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box style={{ padding: 0 }} p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const UserTab = (props) => {
    return (
        <TabPanel value={props.value} index={props.USERS}>
            <MainComponents>
                <StyledContainerTitle>
                    <Stripe />
                    <ContainerTitle>
                        <Title>Colaboradores</Title>
                        <span>Gerencie os colaboradores</span>
                    </ContainerTitle>
                </StyledContainerTitle>
                {getSubmodulePermission(
                    props.submodules.users,
                    permissions.SYSTEM_GLOBAL_COMPANY_FILTER
                ) ? (
                    <GlobalFilterContainer tab={props.USERS}>
                        <GlobalCompanyFilter
                            changeActualGlobalCompany={
                                props.changeActualGlobalCompany
                            }
                            globalCompany={props.globalCompany}
                        />
                    </GlobalFilterContainer>
                ) : null}
            </MainComponents>
            <UsersTab
                resetChangeActualGlobalCompany={
                    props.resetChangeActualGlobalCompany
                }
                theGlobalCompanyHasChange={props.theGlobalCompanyHasChange}
                globalCompany={props.globalCompany}
            />
        </TabPanel>
    );
};

const RecordsTab = (props) => {
    return (
        <TabPanel value={props.value} index={props.RECORDS}>
            <AlignTable>
                <TableWidth width="60%">
                    <MainComponents>
                        <StyledContainerTitle>
                            <Stripe />
                            <ContainerTitle>
                                <Title>Resumo</Title>
                                <span>
                                    {props.currentSystem ? 'IPA' : 'IF'} mensal
                                    não enviado
                                </span>
                            </ContainerTitle>
                        </StyledContainerTitle>
                        {getSubmodulePermission(
                            props.submodules.records,
                            permissions.SYSTEM_GLOBAL_COMPANY_FILTER
                        ) ? (
                            <GlobalFilterContainer tab={props.RECORDS}>
                                <GlobalCompanyFilter
                                    changeActualGlobalCompany={
                                        props.changeActualGlobalCompany
                                    }
                                    globalCompany={props.globalCompany}
                                />
                            </GlobalFilterContainer>
                        ) : null}
                    </MainComponents>
                    <UsersRecordsTab
                        globalCompany={props.globalCompany}
                        resetChangeActualGlobalCompany={
                            props.resetChangeActualGlobalCompany
                        }
                        theGlobalCompanyHasChange={
                            props.theGlobalCompanyHasChange
                        }
                    />
                </TableWidth>
            </AlignTable>
        </TabPanel>
    );
};

const CompaniesTab = (props) => {
    return (
        <TabPanel value={props.value} index={props.COMPANIES}>
            <AlignTable>
                <TableWidth width="75%">
                    <StyledContainerTitle>
                        <Stripe />
                        <ContainerTitle>
                            <Title>Empresas</Title>
                            <span>Gerencie as empresas</span>
                        </ContainerTitle>
                    </StyledContainerTitle>
                    <UsersCompaniesTab company={props.company} />
                </TableWidth>
            </AlignTable>
        </TabPanel>
    );
};

const getModulePermissionsAndTabs = (userPermissions, permissions, auth) => {
    let resUsers = userPermissions.find(
        (elem) => elem.moduleId === permissions.SYSTEM_USERS
    );
    let resRecords = userPermissions.find(
        (elem) => elem.moduleId === permissions.IPA_RECORDS
    );
    let resCompanies = userPermissions.find(
        (elem) => elem.moduleId === permissions.SYSTEM_COMPANIES
    );

    //auth.data.system ? resRecords.active = false : resRecords.active = true;

    let tempTabs = {
        USERS: !!resUsers.active /* !! convert 1 to true or 0 to false*/,
        RECORDS: !!resRecords.active,
        COMPANIES: !!resCompanies.active,
    };

    setTabsOrder(tempTabs);

    return {
        tabs: tempTabs,
        modules: {
            users: resUsers.active,
            records: resRecords.active,
            companies: resCompanies.active,
        },
    };
};

export default function Users() {
    const { auth } = useContext(authContext);
    const [globalCompany, setGlobalCompany] = useState(
        auth.data.user.permissionLevel !== 3 ? undefined : { name: '' }
    );

    const userPermissions = getModulePermissionsAndTabs(
        auth.data.permissions,
        permissions,
        auth
    );
    const [tabs] = useState(userPermissions.tabs);
    const [isEnabledToAccess] = useState(userPermissions.modules);

    const [submodules, setSubmodules] = useState({
        users: [],
        records: [],
        companies: [],
    });

    const outerTheme = createTheme({
        palette: {
            primary: {
                main: auth.data.system ? '#ff6922' : '#ffeb3b',
                dark: auth.data.system ? '#ff5722' : '#ffc107',
                contrastText: '#212121',
            },
        },
    });

    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [company, setCompany] = React.useState(0);
    const [theGlobalCompanyHasChange, setTheGlobalCompanyHasChange] =
        useState(false);

    let history = useHistory();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const changeActualGlobalCompany = (company) => {
        if (company !== null) {
            setTheGlobalCompanyHasChange(true);
            setGlobalCompany(company);
        }
    };

    const resetChangeActualGlobalCompany = (value) => {
        setTheGlobalCompanyHasChange(value);
    };

    const callFindIfSubmoduleIsActive = async (
        permissionRole,
        permissionModule
    ) => {
        const response = await findIfSubmoduleIsActive(
            auth,
            permissionRole,
            permissionModule
        );
        const body = await response.json();

        return body.payload;
    };

    useEffect(() => {
        const callFindOneCompany = async () => {
            const response = await getOneCompany(
                auth,
                auth.data.user.companyId
            );
            const body = await response.json();

            return body.payload[0];
        };

        const callFindIfSubmoduleIsActive = async (
            permissionRole,
            permissionModule
        ) => {
            const response = await findIfSubmoduleIsActive(
                auth,
                permissionRole,
                permissionModule
            );
            const body = await response.json();

            return body.payload;
        };

        let internetError;

        internetError = getInternetError();
        if (internetError) {
            history.replace('/error/500');
            return;
        }

        callFindOneCompany()
            .then((res) => {
                setGlobalCompany(res);
                setCompany(res);
            })
            .catch((err) => console.log(err));

        callFindIfSubmoduleIsActive(
            auth.data.user.permissionRoleId,
            permissions.SYSTEM_USERS
        )
            .then((resUsers) => {
                callFindIfSubmoduleIsActive(
                    auth.data.user.permissionRoleId,
                    auth.data.system
                        ? permissions.IPA_RECORDS
                        : permissions.IF_RECORDS
                )
                    .then((resRecords) => {
                        callFindIfSubmoduleIsActive(
                            auth.data.user.permissionRoleId,
                            permissions.SYSTEM_COMPANIES
                        )
                            .then((resCompanies) => {
                                setSubmodules({
                                    users: resUsers,
                                    records: resRecords,
                                    companies: resCompanies,
                                });
                            })
                            .catch((err) => console.log(err));
                    })
                    .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
    }, [auth]);

    return (
        <div>
            {!isEnabledToAccess.users &&
            !isEnabledToAccess.records &&
            !isEnabledToAccess.companies ? (
                <Redirect
                    to={
                        auth.data.system
                            ? permissions.IPA_DEFAULT_ROUTE
                            : permissions.IF_DEFAULT_ROUTE
                    }
                />
            ) : null}
            <Header />
            <DrawerAdjustment>
                <Spacer />
                <div className={classes.root}>
                    <MuiThemeProvider theme={outerTheme}>
                        <AppBar
                            position="static"
                            color="default"
                            style={{
                                backgroundColor: auth.data.system
                                    ? '#fff3e0'
                                    : '#fffde7',
                                paddingBottom: 0.2,
                                borderLeft: '1px solid #cfd8dc',
                                borderRight: '1px solid #cfd8dc',
                                borderTop: '1px solid #cfd8dc',
                            }}
                        >
                            <Tabs
                                TabIndicatorProps={{
                                    style: {
                                        height: '4px',
                                    },
                                }}
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                            >
                                {isEnabledToAccess.users ? (
                                    <Tab
                                        style={{ fontWeight: 'bold' }}
                                        label="Usuários"
                                        {...a11yProps(tabs.USERS)}
                                    />
                                ) : null}
                                {isEnabledToAccess.records ? (
                                    <Tab
                                        style={{ fontWeight: 'bold' }}
                                        label="Relatórios"
                                        {...a11yProps(tabs.RECORDS)}
                                    />
                                ) : null}
                                {isEnabledToAccess.companies ? (
                                    <Tab
                                        style={{ fontWeight: 'bold' }}
                                        label="Empresas"
                                        {...a11yProps(tabs.COMPANIES)}
                                    />
                                ) : null}
                            </Tabs>
                        </AppBar>
                    </MuiThemeProvider>
                </div>
                <TableContainer>
                    {isEnabledToAccess.users ? (
                        <UserTab
                            value={value}
                            submodules={submodules}
                            globalCompany={globalCompany}
                            USERS={tabs.USERS}
                            resetChangeActualGlobalCompany={
                                resetChangeActualGlobalCompany
                            }
                            theGlobalCompanyHasChange={
                                theGlobalCompanyHasChange
                            }
                            changeActualGlobalCompany={
                                changeActualGlobalCompany
                            }
                            userPermission={auth.data.user.permissionLevel}
                        />
                    ) : null}
                    {isEnabledToAccess.records ? (
                        <RecordsTab
                            value={value}
                            submodules={submodules}
                            globalCompany={globalCompany}
                            RECORDS={tabs.RECORDS}
                            currentSystem={auth.data.system}
                            resetChangeActualGlobalCompany={
                                resetChangeActualGlobalCompany
                            }
                            theGlobalCompanyHasChange={
                                theGlobalCompanyHasChange
                            }
                            changeActualGlobalCompany={
                                changeActualGlobalCompany
                            }
                            userPermission={auth.data.user.permissionLevel}
                        />
                    ) : null}
                    {isEnabledToAccess.companies ? (
                        <CompaniesTab
                            value={value}
                            company={company}
                            COMPANIES={tabs.COMPANIES}
                        />
                    ) : null}
                </TableContainer>
            </DrawerAdjustment>
        </div>
    );
}
