import React, { useContext, useEffect, useState } from 'react';

/* Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

/* Styles */
import {
    StyledFilter,
    AlignContainer,
    Filters,
} from './ipa-graphs-task.styles';

/* Permissions */
import { getSubmodulePermission } from '../../../../../../utils/permission';

/* auth */
import { authContext } from '../../../../../../contexts/AuthContext';

/* api */
import {
    getAllActiveUsersInCompany,
    getAllActiveUsersInDepartment,
} from '../../../../../../api/ApiUser';
import { 
    getAllDepartments,
    getOneDepartment,
    getSubordinatesDepartments
} from '../../../../../../api/ApiDepartment';
import {
    findAllUserTasks,
    findAllCompanyTasks,
    getNumberOfUsersFromTask,
    getNumberOfUsersFromTaskDepartment
} from '../../../../../../api/ApiTasks';

/* utils*/
import { yearsUntilNow } from '../../../../../../utils/initialData';

/* Graph */
import TaskGraphDataCall from './task-graphs-dataCall/task-graphs-dataCall';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 180,
    },
    formControlName: {
        margin: theme.spacing(1),
        minWidth: 250,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    container: {
        '@media (max-width: 600px)': {
            padding: 0,
        },
    },
}));

const TaskGraphFilters = (props) => {
    const classes = useStyles();
    const { auth } = useContext(authContext);

    const [actualTask, setActualTask] = useState({ name: '' });
    const [actualUser, setActualUser] = useState(auth.data.user);
    const [actualOtherTask, setActualOtherTask] = useState({ name: '' });
    const [actualDepartment, setActualDepartment] = useState([]);
    const [actualYear, setActualYear] = useState(
        yearsUntilNow()[yearsUntilNow().length - 1]
    );
    const [actualOtherYear, setActualOtherYear] = useState(
        yearsUntilNow()[yearsUntilNow().length - 2]
    );

    const [userOptions, setUserOptions] = useState([]);
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [departmentOption, setDepartmentOption] = useState([]);
    const [otherTasksOptions, setOtherTasksOptions] = useState([]);
    const [taskOptions, setTaskOptions] = useState([]);
    const [otherUserOptions, setOtherUserOptions] = useState([]);
    const [actualOtherUser, setActualOtherUser] = useState({ name: '' });

    const [numberOfUsers, setNumberOfUsers] = useState(0)

    const [jumpFirstRender, setJumpFirstRender] = useState(false);
    
    useEffect(() => {
        //reset actual values if checkbox turned off

        if (!props.checksStatus[0].activated) {
            setActualUser(auth.data.user);
            setOtherTasksOptions({ name: '' });
        }
        if (!props.checksStatus[2].activated) {
            setActualDepartment([]);
            props.setSelectedDeparmentCounter(0);
        }
        if (!props.checksStatus[4].activated) {
            setActualOtherYear('');
        }
    }, [
        auth.data.user,
        props
    ]);

    useEffect(() => {
        const callApiFindAllDepartment = async () => {
            const response = await getAllDepartments(
                auth,
                auth.data.user.companyId
            );

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);
            setDepartmentOptions(payload);
            setJumpFirstRender(true);
        };

        const callApiFindSubordinatesDepartments = async () => {
            const response = await getSubordinatesDepartments(
                auth,
                auth.data.user.departmentId,
                auth.data.user.companyId
            );

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);
            setDepartmentOptions(payload);
            setJumpFirstRender(true);
        }

        try {
            if(props.submodules && getSubmodulePermission(props.submodules.graphs, props.permissions.SUBORDINATES_DEPARTMENTS_VIZUALIZATION_FILTER)) {
                callApiFindSubordinatesDepartments();
            } else if (getSubmodulePermission(props.submodules.graphs, props.permissions.DEPARTMENTS_VISUALIZATION_FILTER)) {
                callApiFindAllDepartment();
            }
        } catch (err) {
            console.log(err);
        }
    }, [auth, props.submodules, props.globalCompany]);
    
    useEffect(() => {
        const callGetOneDepartment = async (company, departmentId) => {
            const response = await getOneDepartment(
                auth,
                departmentId,
                company
            );
            const body = await response.json();
            return body.payload;
        };

        callGetOneDepartment(
            props.globalCompany.companyId,
            auth.data.user.departmentId
        ).then((res) => {
            setDepartmentOption(res);
            setJumpFirstRender(true);
        });
    }, [props.globalCompany]);

    useEffect(() => {
        //user Filter
        const callGetAllActiveUsersInCompany = async (company) => {
            const response = await getAllActiveUsersInCompany(auth, company);
            const body = await response.json();
            return body.payload;
        };
        const callGetAllActiveUsersInDepartment = async (department, company) => {
            const response = await getAllActiveUsersInDepartment(
                auth,
                department.departmentId,
                company
            );
            const body = await response.json();
            return body.payload;
        };

        const callGetAllActiveUsersInDepartmentForManagers = async (
            departmentId,
            company
        ) => {
            const response = await getAllActiveUsersInDepartment(
                auth,
                departmentId,
                company
            );
            const body = await response.json();
            return body.payload;
        };

        if (auth.data.user.permissionRoleId === 10) {
            callGetAllActiveUsersInDepartmentForManagers(
                auth.data.user.departmentId,
                props.globalCompany.companyId
            ).then((res) => {
                setUserOptions(res);
                setOtherUserOptions(res);
                setJumpFirstRender(true);
            });
        } else {
            if (
                props.checksStatus[1].activated &&
                actualDepartment.length !== 0
            ) {
                callGetAllActiveUsersInDepartment(
                    actualDepartment[0],
                    props.globalCompany.companyId
                ).then((res) => {
                    setUserOptions(res);
                    setOtherUserOptions(res);
                    setJumpFirstRender(true);
                });
            } else {
                callGetAllActiveUsersInCompany(props.globalCompany.companyId).then(
                    (res) => {
                        setUserOptions(res);
                        setOtherUserOptions(res);
                        setJumpFirstRender(true);
                    }
                );
            }
        }
    }, [props.globalCompany]);

    useEffect(() => {
        //tasks and other tasks Filter

        setTaskOptions([]);
        setOtherTasksOptions([]);

        if (
            props.checksStatus[0].activated &&
            !props.checksStatus[3].activated
        ) {
            //Only User Tasks
            const callFindAllUserTasks = async (company) => {
                const response = await findAllUserTasks(
                    auth,
                    company,
                    actualUser
                );
                const body = await response.json();
                return body.payload;
            };

            callFindAllUserTasks(props.globalCompany.companyId).then((res) => {
                setTaskOptions(res);
                setOtherTasksOptions(res);
                setJumpFirstRender(true);
            });
        } else if (
            props.checksStatus[3].activated ||
            props.checksStatus[2].activated
        ) {
            //Tasks from all company

            const callFindAllCompanyTasks = async (company) => {
                const response = await findAllCompanyTasks(auth, company);
                const body = await response.json();
                return body.payload;
            };

            callFindAllCompanyTasks(props.globalCompany.companyId).then(
                (res1) => {
                    setTaskOptions(res1);
                    setOtherTasksOptions(res1);
                    setJumpFirstRender(true);
                }
            );
        } else {
            //no tasks to be showed
            setTaskOptions([]);
            setActualTask({ name: '' });
            setOtherTasksOptions([]);
            setActualOtherTask({ name: '' });
        }
    }, [
        props.checksStatus[0].activated,
        props.checksStatus[1].activated,
        props.checksStatus[3].activated,
        props.checksStatus[2].activated,
        props.globalCompany,
        actualUser
    ]);

    useEffect(() => {
        const callGetNumberOfUsersFromTask = async () => {
            let response = []
            if(actualDepartment[0]?.departmentId){
                response = await getNumberOfUsersFromTaskDepartment(
                    auth,
                    props.globalCompany.companyId,
                    actualDepartment[0]?.departmentId,
                    actualTask.taskId ? actualTask.taskId : null
                );
            }else{
                response = await getNumberOfUsersFromTask(
                    auth,
                    props.globalCompany.companyId,
                    actualTask.taskId ? actualTask.taskId : null
                );
            }
            
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            setNumberOfUsers(body.payload[0].numberOfUsers)
            
        };

        try {
            callGetNumberOfUsersFromTask();
        } catch (error) {
            console.log(error)
        }
    },[auth, props.globalCompany, actualDepartment, actualTask])

    const defaultPropsTasks = {
        options: taskOptions,
        value: actualTask,
        style: { width: '330px' },
        getOptionLabel: (option) => {
            return option.name;
        },
        getOptionSelected: (option) => {
            return option.name;
        },
        onChange: (event, newValue) => {
            setActualTask(newValue);
        },
    };

    const defaultPropsOtherTasks = {
        options: otherTasksOptions,
        value: actualOtherTask,
        style: { width: '310px' },
        getOptionLabel: (option) => {
            return option.name;
        },
        getOptionSelected: (option) => {
            return option.name;
        },
        onChange: (event, newValue) => {
            setActualOtherTask(newValue);
        },
    };

    const defaultOtherUserProps = {
        options: otherUserOptions,
        value: actualOtherUser,
        getOptionLabel: (option) => {
            return option.name;
        },
        getOptionSelected: (option) => {
            return option.name;
        },
        onChange: (event, newValue) => {
            setActualOtherUser(newValue);
        },
    };

    return (
        <>
            {jumpFirstRender ? (
                <>
                    <AlignContainer>
                        <StyledFilter>
                            <Filters>
                                {props.checksStatus[0].activated ||
                                props.checksStatus[2].activated ||
                                props.checksStatus[3].activated ? (
                                    <Autocomplete
                                        {...defaultPropsTasks}
                                        disableClearable
                                        key="autocompleteTaskGraph-1"
                                        className={classes.formControl}
                                        value={actualTask}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Tarefa"
                                                variant="outlined"
                                            />
                                        )}
                                    ></Autocomplete>
                                ) : null}

                                {(getSubmodulePermission(
                                    props.submodules.graphs,
                                    props.permissions.EMPLOYEES_VISUALIZATION_FILTER) ||
                                getSubmodulePermission(
                                    props.submodules.graphs,
                                    props.permissions.EMPLOYEES_VISUALIZATION_FILTER_DEPARTMENT)) && 
                                props.checksStatus[0].activated ? (
                                    <Autocomplete
                                        disableClearable
                                        key="autocompleteTaskGraph0"
                                        className={classes.formControlName}
                                        //defaultValue={userOptions.find((user)=>user.userId === auth.data.user.userId)}
                                        value={actualUser}
                                        options={ userOptions }
                                        getOptionLabel={(option) => option.name}
                                        getOptionSelected={(option) =>
                                            option.name
                                        }
                                        onChange={(e, newValue) =>
                                            setActualUser(newValue)
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Colaborador"
                                                variant="outlined"
                                            />
                                        )}
                                    ></Autocomplete>
                                ) : (
                                    <div className={classes.formControl} />
                                )}

                                {props.checksStatus[1].activated ? (
                                    <Autocomplete
                                        {...defaultPropsOtherTasks}
                                        disableClearable
                                        key="autocompleteTaskGraph1"
                                        className={classes.formControl}
                                        value={actualOtherTask}
                                        options={otherTasksOptions}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Outra Tarefa"
                                                variant="outlined"
                                            />
                                        )}
                                    ></Autocomplete>
                                ) : (
                                    <div className={classes.formControl} />
                                )}

                                {props.checksStatus[2].activated ? (
                                    <Autocomplete
                                        multiple
                                        key="autocompleteTaskGraph2"
                                        disableClearable
                                        className={classes.formControl}
                                        options={
                                            actualDepartment.length === 2 ||
                                            (props.checksSelectedCounter ===
                                                2 &&
                                                actualDepartment.length === 1)
                                                ? actualDepartment
                                                : getSubmodulePermission(
                                                    props.submodules.graphs,
                                                    props.permissions
                                                        .DEPARTMENTS_VISUALIZATION_FILTER ||
                                                  getSubmodulePermission(
                                                    props.submodules.graphs, 
                                                    props.permissions.SUBORDINATES_DEPARTMENTS_VIZUALIZATION_FILTER
                                                  )
                                                ) ? departmentOptions : departmentOption
                                        }
                                        getOptionLabel={(option) => option.name}
                                        filterSelectedOptions
                                        onChange={(e, newValue) => {
                                            newValue.length <
                                            actualDepartment.length
                                                ? props.setSelectedDeparmentCounter(
                                                      props.selectedDepartmentCounter -
                                                          1
                                                  )
                                                : props.setSelectedDeparmentCounter(
                                                      props.selectedDepartmentCounter +
                                                          1
                                                  );
                                            setActualDepartment(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Setor"
                                                variant="outlined"
                                            />
                                        )}
                                    ></Autocomplete>
                                ) : (
                                    <div className={classes.formControl} />
                                )}

                                <Autocomplete
                                    autoSelect
                                    key="autocompleteTaskGraph3"
                                    disableClearable
                                    defaultValue={
                                        yearsUntilNow()[
                                            yearsUntilNow().length - 1
                                        ]
                                    }
                                    className={classes.formControl}
                                    options={yearsUntilNow()}
                                    getOptionLabel={(option) => option}
                                    getOptionSelected={(option) => option}
                                    onChange={(e, newValue) =>
                                        setActualYear(newValue)
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Ano"
                                            variant="outlined"
                                        />
                                    )}
                                ></Autocomplete>
                                {props.checksStatus[4].activated ? (
                                    <Autocomplete
                                        autoSelect
                                        key="autocompleteTaskGraph4"
                                        disableClearable
                                        defaultValue={
                                            yearsUntilNow()[
                                                yearsUntilNow().length - 2
                                            ]
                                        }
                                        className={classes.formControl}
                                        options={yearsUntilNow()}
                                        getOptionLabel={(option) => option}
                                        getOptionSelected={(option) => option}
                                        onChange={(e, newValue) =>
                                            setActualOtherYear(newValue)
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Ano"
                                                variant="outlined"
                                            />
                                        )}
                                    ></Autocomplete>
                                ) : (
                                    <div className={classes.formControl} />
                                )}

                                {props.checksStatus[0].activated &&
                                props.checksStatus[5].activated ? (
                                    <Autocomplete
                                        {...defaultOtherUserProps}
                                        disableClearable
                                        key="autocompleteTaskGraph5"
                                        className={classes.formControl}
                                        //defaultValue={userOptions.find((user)=>user.userId === auth.data.user.userId)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Outro"
                                                variant="outlined"
                                            />
                                        )}
                                    ></Autocomplete>
                                ) : (
                                    <div className={classes.formControl} />
                                )}
                            </Filters>
                        </StyledFilter>
                    </AlignContainer>
                    {(props.checksStatus[2].activated || props.checksStatus[3].activated) && 'Número de Respondentes da Tarefa: ' + numberOfUsers.toString()}

                    {
                        <TaskGraphDataCall
                            actualUser={actualUser}
                            actualDepartment={actualDepartment}
                            actualYear={actualYear}
                            actualOtherUser={actualOtherUser}
                            actualOtherYear={actualOtherYear}
                            checksStatus={props.checksStatus}
                            globalCompany={props.globalCompany}
                            setSelectedDeparmentCounter={
                                props.setSelectedDeparmentCounter
                            }
                            actualOtherTask={actualOtherTask}
                            actualTask={actualTask}
                        />
                    }
                </>
            ) : null}
        </>
    );
};

export default TaskGraphFilters;