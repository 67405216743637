import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

/* Styles */
import { AddUserContainer, Spacer } from './add-user.styles';
import { WarningTitleContainer, WarningWord } from '../../../../components/form-list/form-list.styles';

/* Components */
import Header from '../../../../components/header/header.component';
import PopUp from '../../../../components/popup/popup.component';
import RenderPage from '../../../../components/render-page/render-page.component';
import ImageEditor from '../../../../components/image-editor/image-editor';
import DividerWithText from '../../../../components/divider-with-text/divider-with-text.component';
import CustomButton from '../../../../components/custom-button/custom-button.component';

/* API */
import { addUser, uploadImage, getAllActiveUsersInCompany } from '../../../../api/ApiUser';
import { getAllDepartments } from '../../../../api/ApiDepartment';
import { getOneCompany } from '../../../../api/ApiCompany';

/* Context */
import { authContext } from '../../../../contexts/AuthContext';

/* Material-UI */
import { Container, Grid, IconButton, InputAdornment, TextField, ThemeProvider, Typography, createTheme, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Spa, Visibility, VisibilityOff } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

/* Permissions */
import { getAllPermissions } from '../../../../api/ApiPemissions';
import permissions, { getPermission } from '../../../../utils/permission';

/* Utils */
import { ptBR } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';

const theme = createTheme({
    palette: {
        primary: {
            main: '#ff7517',
        },
    },
});

const fortressOptions = [
    'Amor',
    'Amor ao Aprendizado',
    'Apreciação da Beleza e Excelência',
    'Autocontrole',
    'Bravura',
    'Criatividade',
    'Critério',
    'Curiosidade',
    'Esperança',
    'Espiritualidade',
    'Generosidade',  
    'Gratidão',
    'Humildade',
    'Humor',
    'Integridade',
    'Inteligência Social',
    'Justiça',
    'Liderança',
    'Perdão',
    'Perseverança',
    'Perspectiva',
    'Prudência',
    'Trabalho em Equipe',
    'Vitalidade'
];

const genderOptions = [
    'Masculino',
    'Feminino',
    'Outro'
];

const driverLicenseOptions = [
    'A',
    'B',
    'AB',
    'AC',
    'AD',
    'AE',
    'Não possui',
    'Em andamento',
]

const ethnicityOptions = [
    'Amarelo(a)',
    'Branco(a)',
    'Indígena',
    'Preto(a)',
    'Pardo(a)',
    'Outros',
    'Não declarada',
]

const schoolingOptions = [
    'Ensino fundamental',
    'Ensino médio',
    'Técnico',
    'Tecnólogo',
    'Ensino superior',
]

const terminationReasonOptions = [
    'Outro emprego',
    'Motivo pessoal',
    'Performance',
    'Falecimento',
    'Falta de demanda',
]

const terminationCategoryOptions = [
    'Sem justa causa',
    'Com justa causa',
    'Acordo CLT',
    'Fim do periodo de experiência',
    'Antes do término de experiência',
]

export default function UsersAddPage(props) {
    const { auth } = useContext(authContext);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [birthDate, setBirthDate] = useState(null);
    const [permission, setPermission] = useState(2);
    const [gender, setGender] = useState('');
    const [role, setRole] = useState('');
    const [allocationDate, setAllocationDate] = useState(null);
    const [dream, setDream] = useState('');
    const [purpose, setPurpose] = useState('');
    const [fortress, setFortress] = useState('');
    const [note, setNote] = useState('');
    const [schooling, setSchooling] = useState('');
    const [actingCity, setActingCity] = useState('');
    const [ethnicity, setEthnicity] = useState('');
    const [driverLicense, setDriverLicense] = useState('');
    const [directManager, setDirectManager] = useState('');
    const [managerOptions, setManagerOptions] = useState('');
    const [homeTime, setHomeTime] = useState('');
    const [yearGraduated, setYearGraduated] = useState(''); //Ano que terminou a formação
    const [age, setAge] = useState('');
    
    const [terminationDate, setTerminationDate] = useState(null);
    const [terminationReason, setTerminationReason] = useState('');
    const [terminationCategory, setTerminationCategory] = useState('');
    const [userStatus, setUserStatus] = useState({});
    const [lifeSummary, setLifeSummary] = useState('');

    const [optionsDepartments, setOptionsDepartments] = useState([]);
    const [optionsPermissions, setOptionsPermissions] = useState([]);
    const [department, setDepartment] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    
    const [warningTitle, setWarningTitle] = useState({});

    const [imageUrl, setImageUrl] = useState();
    const [preview, setPreview] = useState();
    const [imageFile, setImageFile] = useState();
    const [openEditor, setOpenEditor] = useState(false);

    const [popup, setPopup] = useState(false);
    const [popupText, setPopupText] = useState('');
    const [popupTitle, setPopupTitle] = useState('');
    const [success, setSuccess] = useState(1);
    const [popupRoute, setPopupRoute] = useState(null);
    
    const [fetchOpen, setFetchOpen] = useState(false);

    useEffect(() => {
        const callAllPermissions = async () => {
            const response = await getAllPermissions(auth);
            const { payload } = await response.json();

            setOptionsPermissions(payload);
        };

        const callApiDepartments = async () => {
            const response = await getAllDepartments(
                auth,
                props.match.params.companyId
            );
            const { payload } = await response.json();

            setOptionsDepartments(payload);
        };

        const callFindOneCompany = async () => {
            const response = await getOneCompany(
                auth,
                props.match.params.companyId
            );
            const { payload } = await response.json();

            setWarningTitle({
                phrase: `Adicionando usuário em `,
                object: `${payload[0].name}`,
                warning:
                    parseInt(props.match.params.companyId) !==
                    parseInt(auth.data.user.companyId)
                        ? `(Cuidado! Você está adicionando um usuário em outra empresa)`
                        : '',
            });
        };

        try {
            callApiDepartments();
            callFindOneCompany();
            callAllPermissions();
        } catch (error) {
            console.log(error);
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if(!birthDate && !allocationDate) {
            setPopupTitle('Erro');
            setPopupText(
                'Data de Nascimento e Data de Admissão inválidas.'
            );
            setPopup(true);
            setSuccess(0);
            return;
        } else if (!birthDate) {
            setPopupTitle('Erro');
            setPopupText('Data de Nascimento inválida.');
            setPopup(true);
            setSuccess(0);
            return;
        } else if (!allocationDate) {
            setPopupTitle('Erro');
            setPopupText('Data de Admissão inválida.');
            setPopup(true);
            setSuccess(0);
            return;
        } else if (!department) {
            setPopupTitle('Erro');
            setPopupText('Insira o Setor.');
            setPopup(true);
            setSuccess(0);
            return;
        } else if (!name) {
            setPopupTitle('Erro');
            setPopupText('Insira o Nome.');
            setPopup(true);
            setSuccess(0);
            return;
        } else if (!email) {
            setPopupTitle('Erro');
            setPopupText('Insira o E-mail.');
            setPopup(true);
            setSuccess(0);
            return;
        } else if (!password || (password.length > 16)) {
            setPopupTitle('Erro');
            setPopupText('Senha Inválida! Máximo 16 caracteres.');
            setPopup(true);
            setSuccess(0);
            return;
        }

        let s = birthDate.split('/');
        let formattedBirthDate =
            s[2] + '-' + s[0] + '-' + s[1] + 'T02:00:00.000Z';

        let formattedTerminationDate
        if(terminationDate) {
            s = terminationDate.split('/');
            formattedTerminationDate =
                s[2] + '-' + s[0] + '-' + s[1] + 'T02:00:00.000Z';
        }

        s = allocationDate.split('/');
        let formattedAllocationDate =
            s[2] + '-' + s[0] + '-' + s[1] + 'T02:00:00.000Z';

        let data = {
            name: name,
            gender: gender,
            birthDate: formattedBirthDate,
            email: email,
            password: password,
            permissionRole: permission.permissionRoleId,
            dream: dream,
            fortress: fortress,
            purpose: purpose,
            note: note,
            schooling: schooling,
            ethnicity: ethnicity,
            driverLicense: driverLicense,
            directManager: directManager.userId,
            yearGraduated: yearGraduated,
            terminationDate: formattedTerminationDate,
            terminationReason: terminationReason,
            terminationCategory: terminationCategory,
            userStatus: userStatus,
            lifeSummary: lifeSummary,
            allocation: {
                role: role,
                startDate: formattedAllocationDate,
                actingCity: actingCity
            },
        };

        try {
            setFetchOpen(true);
            const response = await addUser(
                data,
                auth,
                department.departmentId,
                props.match.params.companyId
            );
            setFetchOpen(false);
            if (response.status === 201) {
                if(imageFile) {
                    const { payload } = await response.json();
                    const imageResponse = await uploadImage(auth, imageFile, payload[0].userId, props.match.params.companyId);
                    if(imageResponse.status === 500 || imageResponse.status === 422) {
                        setPopupTitle('Aviso');
                        setPopupText('Usuário criado, mas não foi possivel fazer upload da imagem.');
                        setPopupRoute('/management/users');
                        setPopup(true);
                        setSuccess(2);
                    } else {
                        setPopupTitle('Sucesso');
                        setPopupText('Usuário criado.');
                        setPopupRoute('/management/users');
                        setPopup(true);
                        setSuccess(2);
                    }
                } else {
                    setPopupTitle('Sucesso');
                    setPopupText('Usuário criado.');
                    setPopupRoute('/management/users');
                    setPopup(true);
                    setSuccess(2);
                }
            } else {
                setPopupTitle('Erro');
                setPopupText('Erro desconhecido ao criar usuário.');
                setPopup(true);
                setSuccess(0);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = (event) => {
        let { value, name } = event.target;
        if (name === 'name') setName(value);
        else if (name === 'email') setEmail(value);
        else if (name === 'password') setPassword(value);
        else if (name === 'role') setRole(value);
        else if (name === 'dream') setDream(value);
        else if (name === 'purpose') setPurpose(value);
        else if (name === 'fortress') setFortress(value);
        else if (name === 'note') setNote(value);
        else if (name === 'schooling') setSchooling(value);
        else if (name === 'actingCity') setActingCity(value);
        else if (name === 'yearGraduated') setYearGraduated(value);
        else if (name === 'lifeSummary') setLifeSummary(value);

    };

    const handleChangeFile = (event) => {
        let { files } = event.target;

        if(!files[0]) return;
        
        const maxSize = 2097152 // 2 MB
        
        if(files[0].type.split("/")[0] !== "image") {
            setPopupTitle('Erro');
            setPopupText('Tipo de arquivo inválido');
            setPopup(true);
            setSuccess(0);
            return;
        }
        
        if(files[0].size > maxSize) {
            setPopupTitle('Erro');
            setPopupText(`Arquivo muito grande. Tamanho máximo: ${maxSize / 1024 / 1024} MB`);
            setPopup(true);
            setSuccess(0);
            return;
        };
        
        if(preview) URL.revokeObjectURL(preview);
        if(imageUrl) URL.revokeObjectURL(imageUrl);
        
        const objectUrl = URL.createObjectURL(files[0])
        
        setImageUrl(objectUrl);
        setImageFile(files[0]);
        setOpenEditor(true);
    }

    const closePopUp = () => {
        setPopup(false);
    }

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const handleBirthDate = (date) => {
        const dateChanged = new Date(date);
        if (!isNaN(dateChanged.getTime()))
        {
            setBirthDate(format(dateChanged, 'MM/dd/yyyy'));
        }
        else setBirthDate(null);
    };

    const handleTerminationDate = (date) => {
        const dateChanged = new Date(date);
        if (!isNaN(dateChanged.getTime()))
        {
            setTerminationDate(format(dateChanged, 'MM/dd/yyyy'));
        }
        else setTerminationDate(null);
    };

    const handleAllocationDate = (date) => {
        const dateChanged = new Date(date);
        if (!isNaN(dateChanged.getTime()))
            setAllocationDate(format(dateChanged, 'MM/dd/yyyy'));
        else setAllocationDate(null);
    };

    const defaultPropsDepartments = {
        options: optionsDepartments,

        getOptionLabel: (option) => {
            return option.name;
        },
        getOptionSelected: (option) => {
            return option.name;
        },
        onChange: (event, newValue) => {
            setDepartment(newValue);
        },
    };

    const defaultPropsFortress = {
        options: fortressOptions,

        getOptionLabel: (option) => {
            return option;
        },
        getOptionSelected: (option) => {
            return option;
        },
        onChange: (event, newValue) => {
            setFortress(newValue);
        },
    };

    const defaultPropsPermissions = {
        options: optionsPermissions,
        getOptionLabel: (option) => {
            return option.name;
        },
        getOptionSelected: (option) => {
            return option.name;
        },
        onChange: (event, newValue) => {
            setPermission(newValue);
        },
    };

    const defaultPropsGender = {
        options: genderOptions,
        getOptionLabel: (option) => {
            return option;
        },
        getOptionSelected: (option) => {
            return option;
        },
        onChange: (event, newValue) => {
            setGender(newValue);
        },
    };

    const defaultPropsDriverLicense = {
        options: driverLicenseOptions,
        getOptionLabel: (option) => {
            return option;
        },
        getOptionSelected: (option) => {
            return option;
        },
        onChange: (event, newValue) => {
            setDriverLicense(newValue);
        },
    }

    const defaultPropsSchooling = {
        options: schoolingOptions,
        getOptionLabel: (option) => {
            return option;
        },
        getOptionSelected: (option) => {
            return option;
        },
        onChange: (event, newValue) => {
            setSchooling(newValue);
        },
    }

    const defaultPropsEthnicity = {
        options: ethnicityOptions,
        getOptionLabel: (option) => {
            return option;
        },
        getOptionSelected: (option) => {
            return option;
        },
        onChange: (event, newValue) => {
            setEthnicity(newValue);
        },
    }

    const defaultPropsManager = {
        options: managerOptions,
        value: directManager,
        getOptionLabel: (option) => {
            return option.name || '';
        },
        getOptionSelected: (option) => {
            return option.name || '';
        },
        onChange: (event, newValue) => {
            setDirectManager(newValue);
        },
    };

    const defaultPropsTerminationReason = {
        options: terminationReasonOptions,

        getOptionLabel: (option) => {
            return option;
        },
        getOptionSelected: (option) => {
            return option;
        },
        onChange: (event, newValue) => {
            setTerminationReason(newValue);
        },
    };

    const defaultPropsTerminationCategory = {
        options: terminationCategoryOptions,

        getOptionLabel: (option) => {
            return option;
        },
        getOptionSelected: (option) => {
            return option;
        },
        onChange: (event, newValue) => {
            setTerminationCategory(newValue);
        },
    };

    useEffect(() => {
        const callApiGetAllActiveUsersInCompany = async () => {
            const response = await getAllActiveUsersInCompany(auth,props.match.params.companyId);
            const { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);
            setManagerOptions(payload);
        }

        try {
            callApiGetAllActiveUsersInCompany();
        } catch (error) {
            console.log(error);
        }

    },[])

    const yearsSince = (date1, date2) => {
        var offset = 0
        if(parseInt(date2.slice(0,2))-parseInt(date1.slice(0,2))<0)
        {
            
            if(parseInt(date2.slice(3,5))-parseInt(date1.slice(3,5))<=0)
            {
                offset = 1
            }    
        }
        return parseInt(date2.slice(-4))-parseInt(date1.slice(-4))-offset

    }

    useEffect(() => {
        if(birthDate !== null)
        {
            var today = format(new Date().getTime(), 'MM/dd/yyyy')
            setAge(yearsSince(birthDate,today))
        }
    },[birthDate])

    useEffect(() => {
        if(allocationDate !== null)
        {
            var today = format(new Date().getTime(), 'MM/dd/yyyy')
            setHomeTime(yearsSince(allocationDate,today))
        }
    },[allocationDate])

    return (
        <div>
            {!getPermission(auth.data.permissions, permissions.SYSTEM_USERS) ? (
                <Redirect
                    to={
                        auth.data.system
                            ? permissions.IPA_DEFAULT_ROUTE
                            : permissions.IF_DEFAULT_ROUTE
                    }
                />
            ) : null}
            <Header />
            <Container>
                <AddUserContainer>

                    <WarningTitleContainer>
                        <Typography variant="h5">
                            {' '}
                            {warningTitle.phrase}{' '}
                            <WarningWord>{warningTitle.object}</WarningWord>
                        </Typography>
                        <Typography variant="body2">
                            {' '}
                            <WarningWord>{warningTitle.warning}</WarningWord>
                        </Typography>
                    </WarningTitleContainer>
                    <ImageEditor
                        title={"Adicionar Imagem"}
                        src={imageUrl}
                        preview={preview}
                        setPreview={setPreview}
                        file={imageFile}
                        setFile={setImageFile}
                        openEditor={openEditor}
                        setOpenEditor={setOpenEditor}
                        handleChange={handleChangeFile}
                        scale={10}
                    />
                    <form onSubmit={handleSubmit}>
                        <ThemeProvider theme={theme}>
                            <MuiPickersUtilsProvider
                                locale={ptBR}
                                utils={DateFnsUtils}
                            >   
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <DividerWithText children={"Dados de Acesso"}/>        
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            id="outlined-basic"
                                            label="E-mail"
                                            variant="outlined"
                                            name="email"
                                            value={email}
                                            type="text"
                                            onChange={handleChange}
                                            maxLength="80"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth
                                            id="outlined-basic"
                                            label="Senha"
                                            variant="outlined"
                                            name="password"
                                            value={password}
                                            type={showPassword ? "text" : "password"}
                                            InputProps={{
                                                endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleClickShowPassword}
                                                    >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                                )
                                            }}
                                            onChange={handleChange}
                                            maxLength="16"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Autocomplete
                                            {...defaultPropsPermissions}
                                            id="permissions"
                                            disableClearable
                                            autoSelect
                                            renderInput={(params) => 
                                                <TextField {...params} label="Permissão" variant="outlined"/>}
                                        />
                                    </Grid>
                                    <Spacer/>
                                    <Grid item xs={12}>
                                            <DividerWithText children={"Dados de Pessoais"}/>        
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="outlined-basic"
                                            fullWidth
                                            label="Nome"
                                            variant="outlined"
                                            name="name"
                                            value={name}
                                            type="text"
                                            onChange={handleChange}
                                            placeholder=""
                                            maxLength="80"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Autocomplete
                                            {...defaultPropsGender}
                                            id="genders"
                                            disableClearable
                                            autoSelect
                                            renderInput={(params) => 
                                                <TextField {...params} label="Gênero" variant="outlined"/>}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Autocomplete
                                            {...defaultPropsEthnicity}
                                            id="ethnicity"
                                            disableClearable
                                            autoSelect
                                            renderInput={(params) => 
                                                <TextField {...params} label="Cor*" variant="outlined"/>}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <KeyboardDatePicker
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            label="Data de Nascimento"
                                            value={birthDate}
                                            onChange={handleBirthDate}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="age"
                                            fullWidth
                                            label="Idade"
                                            variant="outlined"
                                            name="age"
                                            value={age}
                                            type="text"
                                            placeholder=""
                                            disabled="true"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            {...defaultPropsFortress}
                                            id="fortress"
                                            disableClearable
                                            autoSelect
                                            renderInput={(params) => 
                                                <TextField {...params} label="Força" variant="outlined"/>}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="outlined-basic" 
                                            label="Sonho"
                                            variant="outlined"
                                            name="dream"
                                            multiline
                                            fullWidth
                                            value={dream}
                                            type="text"
                                            onChange={handleChange}
                                            placeholder=""
                                            inputProps={{ maxLength: 448 }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="outlined-basic" 
                                            label="Propósito"
                                            variant="outlined"
                                            name="purpose"
                                            multiline
                                            fullWidth
                                            value={purpose}
                                            type="text"
                                            onChange={handleChange}
                                            placeholder=""
                                            inputProps={{ maxLength: 448 }}
                                        />
                                    </Grid>
                                    <Spacer></Spacer>
                                    <Grid item xs={7}>
                                        <Autocomplete
                                            {...defaultPropsSchooling}
                                            id="schooling"
                                            disableClearable
                                            autoSelect
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Escolaridade"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            id="outlined-basic" 
                                            label="Ano que terminou a graduação"
                                            variant="outlined"
                                            name="yearGraduated"
                                            multiline
                                            fullWidth
                                            value={yearGraduated}
                                            type="text"
                                            onChange={handleChange}
                                            placeholder=""
                                            inputProps={{ maxLength: 448 }}
                                        />
                                    </Grid>
                                    <Spacer></Spacer>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            {...defaultPropsManager}
                                            id="Manager"
                                            disableClearable
                                            autoSelect
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Gestor direto"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            {...defaultPropsDriverLicense}
                                            id="driverLicense"
                                            disableClearable
                                            autoSelect
                                            renderInput={(params) => 
                                                <TextField {...params} label="CNH*" variant="outlined"/>}
                                        />
                                    </Grid>
                                    <Spacer />
                                    <Grid item xs={12}>
                                        <DividerWithText children={"Dados de Coorporativos"}/>        
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Autocomplete
                                            {...defaultPropsDepartments}
                                            id="departments"
                                            disableClearable
                                            autoSelect
                                            renderInput={(params) => 
                                                <TextField {...params} label="Setor" variant="outlined"/>}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            id="outlined-basic"
                                            label="Cidade de Atuação"
                                            variant="outlined"
                                            name="actingCity"
                                            value={actingCity}
                                            type="text"
                                            onChange={handleChange}
                                            maxLength="30"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            id="outlined-basic"
                                            label="Cargo"
                                            variant="outlined"
                                            name="role"
                                            value={role}
                                            type="text"
                                            onChange={handleChange}
                                            maxLength="40"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <KeyboardDatePicker
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            label="Data de Admissão"
                                            value={allocationDate}
                                            onChange={handleAllocationDate}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="homeTime" 
                                            label="Tempo de casa"
                                            variant="outlined"
                                            name="homeTime"
                                            multiline
                                            fullWidth
                                            value={homeTime}
                                            type="text"
                                            placeholder=""
                                            disabled="true"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            id="outlined-basic"
                                            label="Observações"
                                            variant="outlined"
                                            name="note"
                                            value={note}
                                            type="text"
                                            onChange={handleChange}
                                            maxLength="60"
                                        />
                                    </Grid>
                                    <Spacer></Spacer>
                                    <Grid item xs={3}>
                                        <KeyboardDatePicker
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            label="Data de Desligamento"
                                            value={terminationDate}
                                            onChange={handleTerminationDate}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Autocomplete
                                            {...defaultPropsTerminationReason}
                                            id="terminationReason"
                                            disableClearable
                                            autoSelect
                                            renderInput={(params) => 
                                                <TextField {...params} label="Motivo do desligamento" variant="outlined"/>}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Autocomplete
                                            {...defaultPropsTerminationCategory}
                                            id="terminationCategory"
                                            disableClearable
                                            autoSelect
                                            renderInput={(params) => 
                                                <TextField {...params} label="Categoria do desligamento" variant="outlined"/>}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="lifeSummary"
                                            fullWidth
                                            label="Resumo da Vida"
                                            variant="outlined"
                                            name="lifeSummary"
                                            value={lifeSummary}
                                            onChange={handleChange}
                                            type="text"
                                            placeholder=""
                                            multiline
                                        />
                                    </Grid>
                                </Grid>
                                <Grid 
                                    container 
                                    justifyContent='flex-end'
                                    style={{marginTop: '1rem'}}
                                    spacing={2}
                                >
                                    <Grid item>
                                        <CustomButton disabled={!(ethnicity && driverLicense)} type="submit" addButton={true}>Criar Usuário</CustomButton>
                                    </Grid>
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </ThemeProvider> 
                    </form>
                </AddUserContainer>
            </Container>
            {popup ? (
                <PopUp
                    title={popupTitle}
                    string={popupText}
                    success={success}
                    route={popupRoute}
                    handleClose={closePopUp}
                />
            ) : null}
            {fetchOpen ? <RenderPage open={fetchOpen} /> : null}
        </div>
    );
}
