import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useHistory } from 'react-router-dom';

/* Components */
import Header from '../../../components/header/header.component';
import ManageIpaAlignmentTab from './manage-alignment-tab/manage-alignment-tab.component';
import HistoryIpaAlignmentTab from './history-alignment-tab/history-alignment-tab.component';
import SendAlignment from './send-alignment-tab/send-alignment-tab.component';
import { helpSendAlignments } from '../../../components/help/help.texts';
import { helpHistoryAlignments } from '../../../components/help/help.texts';
import Help from '../../../components/help/help.component';
/* Styles */
import {
    DrawerAdjustment,
    Spacer,
    TableContainer,
    MainComponents,
    CenteredElement,
    StyledContainer,
} from './ipa-alignment.styles';

/* Material UI */
import Container from '@material-ui/core/Container';
import { createTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

/* Context */
import { authContext } from '../../../contexts/AuthContext';

/* API */
import GlobalCompanyFilter from '../../../components/globalFilter/globalFilter.component';
import { getOneCompany } from '../../../api/ApiCompany';

/* Permission */
import permissions, {
    setTabsOrder,
    getSubmodulePermission,
} from '../../../utils/permission';
import { findIfSubmoduleIsActive } from '../../../api/ApiPemissions';
import { getInternetError } from '../../../utils/genericFunctions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box style={{ padding: 0 }} p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const SendTab = (props) => {
    const [help, setHelp] = useState(false);
    const onChangeHelp = () => {
        setHelp(!help);
    };

    return (
        <TabPanel value={props.value} index={props.SEND}>
            <Help
                onClick={onChangeHelp}
                helpPopUp={helpSendAlignments}
                help={help}
            />
            <CenteredElement>
                <Typography variant="h5">Alinhamento mensal</Typography>
            </CenteredElement>
            <SendAlignment />
        </TabPanel>
    );
};

const HistoryTab = (props) => {
    const [help, setHelp] = useState(false);
    const onChangeHelp = () => {
        setHelp(!help);
    };
    return (
        <TabPanel value={props.value} index={props.HISTORY}>
            <Help
                onClick={onChangeHelp}
                helpPopUp={helpHistoryAlignments}
                help={help}
            />
            <StyledContainer>
                <MainComponents>
                    <Typography variant="h5">
                        Histórico de alinhamentos
                    </Typography>
                    {getSubmodulePermission(
                        props.submodules.history,
                        permissions.SYSTEM_GLOBAL_COMPANY_FILTER
                    ) ? (
                        <GlobalCompanyFilter
                            changeActualGlobalCompany={
                                props.changeActualGlobalCompany
                            }
                            globalCompany={props.globalCompany}
                        />
                    ) : null}
                </MainComponents>
                <HistoryIpaAlignmentTab
                    submodules={props.submodules.history}
                    globalCompany={props.globalCompany}
                    resetChangeActualGlobalCompany={
                        props.resetChangeActualGlobalCompany
                    }
                    theGlobalCompanyHasChange={props.theGlobalCompanyHasChange}
                />
            </StyledContainer>
        </TabPanel>
    );
};

const ManageTab = (props) => {
    return (
        <TabPanel
            value={props.value}
            index={props.MANAGE}
            style={{ padding: 35 }}
        >
            <MainComponents>
                <Typography variant="h5">
                    Gerenciamento de alinhamentos
                </Typography>
                {getSubmodulePermission(
                    props.submodules.manage,
                    permissions.SYSTEM_GLOBAL_COMPANY_FILTER
                ) ? (
                    <GlobalCompanyFilter
                        changeActualGlobalCompany={
                            props.changeActualGlobalCompany
                        }
                        globalCompany={props.globalCompany}
                    />
                ) : null}
            </MainComponents>
            <ManageIpaAlignmentTab
                globalCompany={props.globalCompany}
                resetChangeActualGlobalCompany={
                    props.resetChangeActualGlobalCompany
                }
                theGlobalCompanyHasChange={props.theGlobalCompanyHasChange}
            />
        </TabPanel>
    );
};

const getModulePermissionsAndTabs = (userPermissions, permissions) => {
    let resSend = userPermissions.find(
        (elem) => elem.moduleId === permissions.IPA_SEND_ALIGNMENT
    );
    let resHistory = userPermissions.find(
        (elem) => elem.moduleId === permissions.IPA_ALIGNMENT_HISTORY
    );
    let resManage = userPermissions.find(
        (elem) => elem.moduleId === permissions.IPA_MANAGE_ALIGNMENTS
    );

    let tempTabs = {
        SEND: !!resSend.active /* !! convert 1 to true or 0 to false*/,
        HISTORY: !!resHistory.active,
        MANAGE: !!resManage.active,
    };

    setTabsOrder(tempTabs);

    return {
        tabs: tempTabs,
        modules: {
            send: resSend.active,
            history: resHistory.active,
            manage: resManage.active,
        },
    };
};

export default function IpaAlignmentPage() {
    const { auth } = useContext(authContext);

    const [theGlobalCompanyHasChange, setTheGlobalCompanyHasChange] =
        useState(false);
    const [globalCompany, setGlobalCompany] = useState(
        auth.data.user.permissionLevel !== 3 ? undefined : { name: '' }
    );
    const userPermissions = getModulePermissionsAndTabs(
        auth.data.permissions,
        permissions
    );
    const [tabs] = useState(userPermissions.tabs);
    const [isEnabledToAccess] = useState(userPermissions.modules);

    const [submodules, setSubmodules] = useState({
        send: [],
        history: [],
        manage: [],
    });

    let history = useHistory();

    const outerTheme = createTheme({
        palette: {
            primary: {
                main: auth.data.system ? '#ff6922' : '#ffeb3b',
                dark: auth.data.system ? '#ff5722' : '#ffc107',
                contrastText: '#212121',
            },
        },
    });

    const classes = useStyles();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const changeActualGlobalCompany = (company) => {
        if (company !== null) {
            setTheGlobalCompanyHasChange(true);
            setGlobalCompany(company);
        }
    };

    const resetChangeActualGlobalCompany = (value) => {
        setTheGlobalCompanyHasChange(value);
    };

    useEffect(() => {
        const callFindOneCompany = async () => {
            const response = await getOneCompany(
                auth,
                auth.data.user.companyId
            );
            const body = await response.json();

            return body.payload[0];
        };

        const callFindIfSubmoduleIsActive = async (
            permissionRole,
            permissionModule
        ) => {
            const response = await findIfSubmoduleIsActive(
                auth,
                permissionRole,
                permissionModule
            );
            const body = await response.json();

            return body.payload;
        };

        let internetError;

        internetError = getInternetError();
        if (internetError) {
            history.replace('/error/500');
            return;
        }

        callFindIfSubmoduleIsActive(
            auth.data.user.permissionRoleId,
            permissions.IPA_SEND_ALIGNMENT
        )
            .then((resSend) => {
                callFindIfSubmoduleIsActive(
                    auth.data.user.permissionRoleId,
                    permissions.IPA_ALIGNMENT_HISTORY
                )
                    .then((resHistory) => {
                        callFindIfSubmoduleIsActive(
                            auth.data.user.permissionRoleId,
                            permissions.IPA_MANAGE_ALIGNMENTS
                        )
                            .then((resManageAlignments) => {
                                setSubmodules({
                                    send: resSend,
                                    history: resHistory,
                                    manage: resManageAlignments,
                                });
                            })
                            .catch((err) => console.log(err));
                    })
                    .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));

        callFindOneCompany()
            .then((res) => {
                setGlobalCompany(res);
            })
            .catch((err) => console.log(err));
    }, [auth, history]);

    return (
        <>
            {!isEnabledToAccess.send &&
            !isEnabledToAccess.history &&
            !isEnabledToAccess.manage ? (
                <Redirect to={permissions.IPA_DEFAULT_ROUTE} />
            ) : null}
            <Header />
            <DrawerAdjustment>
                <Spacer />
                <Container>
                    <div className={classes.root}>
                        <MuiThemeProvider theme={outerTheme}>
                            <AppBar
                                position="static"
                                color="default"
                                style={{
                                    backgroundColor: auth.data.system
                                        ? '#fff3e0'
                                        : '#fffde7',
                                    paddingBottom: 0.2,
                                    borderLeft: '1px solid #cfd8dc',
                                    borderRight: '1px solid #cfd8dc',
                                    borderTop: '1px solid #cfd8dc',
                                }}
                            >
                                <Tabs
                                    TabIndicatorProps={{
                                        style: {
                                            height: '4px',
                                        },
                                    }}
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                >
                                    {isEnabledToAccess.send ? (
                                        <Tab
                                            style={{ fontWeight: 'bold' }}
                                            label="Enviar"
                                            {...a11yProps(tabs.SEND)}
                                        />
                                    ) : null}
                                    {isEnabledToAccess.history ? (
                                        <Tab
                                            style={{ fontWeight: 'bold' }}
                                            label="Histórico"
                                            {...a11yProps(tabs.HISTORY)}
                                        />
                                    ) : null}
                                    {isEnabledToAccess.manage ? (
                                        <Tab
                                            style={{ fontWeight: 'bold' }}
                                            label="Alinhamentos"
                                            {...a11yProps(tabs.MANAGE)}
                                        />
                                    ) : null}
                                </Tabs>
                            </AppBar>
                        </MuiThemeProvider>
                    </div>

                    <TableContainer>
                        {isEnabledToAccess.send ? (
                            <SendTab value={value} SEND={tabs.SEND} />
                        ) : null}
                        {isEnabledToAccess.history ? (
                            <HistoryTab
                                value={value}
                                HISTORY={tabs.HISTORY}
                                submodules={submodules}
                                globalCompany={globalCompany}
                                changeActualGlobalCompany={
                                    changeActualGlobalCompany
                                }
                                resetChangeActualGlobalCompany={
                                    resetChangeActualGlobalCompany
                                }
                                theGlobalCompanyHasChange={
                                    theGlobalCompanyHasChange
                                }
                            />
                        ) : null}
                        {isEnabledToAccess.manage ? (
                            <ManageTab
                                value={value}
                                MANAGE={tabs.MANAGE}
                                submodules={submodules}
                                globalCompany={globalCompany}
                                changeActualGlobalCompany={
                                    changeActualGlobalCompany
                                }
                                resetChangeActualGlobalCompany={
                                    resetChangeActualGlobalCompany
                                }
                                theGlobalCompanyHasChange={
                                    theGlobalCompanyHasChange
                                }
                            />
                        ) : null}
                    </TableContainer>
                </Container>
            </DrawerAdjustment>
        </>
    );
}
