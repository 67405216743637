/* Generic card list component, used in the help page*/
import React from 'react';
import Parser from 'html-react-parser';

/* Material UI*/
import {Card} from "@material-ui/core";
import CardActions from '@material-ui/core/CardActions';
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {withStyles} from "@material-ui/core/styles";

/* Style */
import {
    IconContainer,
    CardSeparator,
    Dot,
    ContainerTitle,
    DateAlign,
    ContainerHeaderPage
} from './cards-list.styles';

/* Icons */
import {ReactComponent as Professional} from "../../assets/portfolio.svg";
import {ReactComponent as Personal} from "../../assets/personal.svg";
import {ReactComponent as Financial} from "../../assets/money.svg";
import {ReactComponent as Motivational} from "../../assets/motivational.svg";
import {ReactComponent as Health} from "../../assets/health.svg";
import {ReactComponent as Environment} from "../../assets/team.svg";
import {ReactComponent as News} from "../../assets/newspaper.svg";
import {ReactComponent as Goal} from "../../assets/goal-colorful.svg";
import {ReactComponent as Misalignment} from "../../assets/misalignment.svg";
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';

const GreenCheckbox = withStyles({
    root: {
        color: "#44bfa4",
        '&$checked': {
            color: "#44bfa4",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function CardsList(props) {
    const isMobile = useMediaQuery('(max-width:650px)');

    return (
        <div>
            <ContainerHeaderPage>
                <ContainerTitle>
                    <Typography variant={isMobile ? "h5" : "h4"}>
                        {props.mainTitle.title}
                    </Typography>
                    <span style={{marginTop: 20, fontSize: 16}}>{props.mainTitle.subtitle}</span>
                </ContainerTitle>
                { props.checkboxInfo ?
                <FormControlLabel control={<GreenCheckbox name={props.checkboxInfo.name}
                                                     checked={props.checkboxInfo.state}
                                                     onChange={props.handleChange} />} label={props.checkboxInfo.title} /> : null}
            </ContainerHeaderPage>
            {props.mainTitle.empty ?
                <IconContainer hasCard={false}>
                    <Typography variant="body1" component="p" gutterBottom>
                        Não há novas notificações
                    </Typography>
                </IconContainer> : null}
            {props.rows.map((row, index) => (
                <CardSeparator key={index} hasCard={true}>
                    <Card variant="outlined"
                          style={{backgroundColor: props.notificationColors1 ? props.notificationColors1[props.rows[index].situation] : 'white'}}>
                        <DateAlign>
                            {row.createdAt ?
                                <Typography variant="subtitle2" style={{marginLeft: 10}}>
                                    {row.createdAt}
                                </Typography>
                                : null}
                        </DateAlign>
                        <CardActions style={{padding: 0}}>
                            {props.newsIcon ?
                                row.header.includes("Match!") ?
                                    <IconContainer>
                                        <Dot index={row.color === "read" ? 10 : 2}>
                                            <Goal style={{
                                                width: isMobile ? 48 : 55,
                                                height: isMobile ? 48 : 55,
                                                fill: '#424242'
                                            }}/>
                                        </Dot>
                                    </IconContainer>
                                    :
                                    row.header.includes("Procure seu colega!") ?
                                    <IconContainer>
                                        <Dot index={row.color === "read" ? 10 : 7}>
                                            <Misalignment style={{
                                                width: isMobile ? 48 : 55,
                                                height: isMobile ? 48 : 55,
                                                fill: '#424242'
                                            }}/>
                                        </Dot>
                                    </IconContainer>
                                    :
                                    row.header.includes("Atenção!") ?
                                    <IconContainer>
                                        <Dot index={row.color === "read" ? 10 : 8}>
                                            <WarningIcon style={{
                                                width: isMobile ? 40 : 40,
                                                height: isMobile ? 40 : 40,
                                                color: '#424242' 
                                            }}/>
                                        </Dot>
                                    </IconContainer>
                                    :
                                    <IconContainer>
                                        <Dot index={row.color === "read" ? 10 : 5}>
                                            <News style={{
                                                width: isMobile ? 40 : 47,
                                                height: isMobile ? 40 : 47,
                                                fill: '#424242'
                                            }}/>
                                        </Dot>
                                    </IconContainer>
                                :
                                props.icons ?
                                    <IconContainer>
                                        <Dot index={index}>
                                            {index === 0 ?
                                                <Professional style={{width: 47, height: 47, fill: '#424242'}}/> :
                                                index === 1 ?
                                                    <Personal style={{width: 52, height: 52, fill: '#424242'}}/> :
                                                    index === 2 ?
                                                        <Financial style={{width: 42, height: 42, fill: '#424242'}}/> :
                                                        index === 3 ?
                                                            <Environment
                                                                style={{width: 47, height: 47, fill: '#424242'}}/> :
                                                            index === 4 ?
                                                                <Motivational
                                                                    style={{width: 52, height: 52, fill: '#424242'}}/> :
                                                                index === 5 ?
                                                                    <Health style={{
                                                                        width: 47,
                                                                        height: 47,
                                                                        fill: '#424242'
                                                                    }}/> :
                                                                    null
                                            }
                                        </Dot>
                                    </IconContainer> : null}
                            <div style={{margin: 10}}>
                                {row.header.includes("Match!") ?
                                    <Typography style={{fontWeight: "bold"}} variant="h5" component="h2">
                                        {row.header}
                                    </Typography> :
                                    row.header.includes("Procure seu colega!") ?
                                    <Typography style={{fontWeight: "bold"}} variant="h5" component="h2">
                                        {row.header}
                                    </Typography> :
                                    row.header.includes("Atenção!") ?
                                    <Typography style={{fontWeight: "bold"}} variant="h5" component="h2">
                                        {row.header}
                                    </Typography> :
                                    <Typography variant="h5" component="h2">
                                        {row.header}
                                    </Typography>
                                }
                                <Typography variant="body1" component="p" gutterBottom>
                                    {Parser(row.content)}
                                </Typography>
                            </div>
                        </CardActions>
                    </Card>
                </CardSeparator>
            ))}
        </div>
    );
}
