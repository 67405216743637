import styled from "styled-components/macro";

export const IconContainer = styled.div`
    padding: 10px 20px 20px 20px;
    display:  inline-block;
    @media screen and (max-width: 780px) {
        padding: 0 0 0 8px;
    }
`;

export const CardSeparator = styled.div`
    padding: 10px;
`;

export const StyledContainerTitle = styled.div`
    display: flex;
    flex-direction: row;
    padding: 11px;
`;

export const ContainerTitle = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2vw ${({hasCard}) => hasCard ? '0px' : '130px'} 2vw 0;
    @media screen and (max-width: 600px){
        justify-content: center;
        align-Items: center;
        padding: 2vw 0 2vw 0;
    }
`;
export const Title = styled.h2`
    margin-bottom: 8px;
`;

export const Stripe = styled.div`
    margin-top: 13px;
    margin-right: 13px;
    border-left:5px solid #4f4f4f;
    height: 65px;
    @media screen and (max-width: 780px){
        width: 0;
        height: 0;
        visibility: hidden;  
    }
`;
export const DateAlign = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 10px 10px 0 0;
`;

export const ContainerHeaderPage = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const Dot = styled.div`
    height: 75px;
    width: 75px;
    background-color: #bbb;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 500px) {
        height: 70px;
        width: 70px;
        padding: 4px;
    }
    background-color: ${({index}) => index === 0 ? '#ffab91' :
    index === 1 ? '#ffc3ee' :
        index === 2 ? '#FFD44F' :
            index === 3 ? '#769BF2' :
                index === 4 ? '#94FA92' :
                    index === 5 ? '#80cbc4' :
                        index === 6 ? '#769BF2' :
                            index === 7 ? '#fff900' :
                                index === 8 ? '#FFA500' :   
                            null
    };
`;


