import styled from 'styled-components/macro';

export const ModalContainer = styled.div`
    background-color: white;
    border: 1px solid #cfd8dc;
    padding-top: 4vw;
    padding-bottom: 4vw;
    padding-right: 13vw;
    padding-left: 13vw;
    @media screen and (max-width: 600px) , screen and (max-height: 660px) {
        width: 100%;
        background-color: #fff;
        margin-bottom: 20px;
    }
    @media screen and (max-height: 1000px) {
        height: 68%;
    }
    @media screen and (max-height: 800px) {
        width: 100%;
        height: 85%;
    }
    @media screen and (max-width: 650px) {
        padding: 0;
    }
`;

export const StyledInput = styled.input`
    border: none;
    border: 1px solid #bdbdbd;
    font-size: 16px;
    height: 40px;
    margin: 20px;
    text-align: center;
    color: #757575;
    border-radius: 3px;
    max-width: 350px;
    @media screen and (max-width: 650px) {
        min-width: 0;
    }
    font-family: 'Arial', sans-serif;
    &:focus {
        border: 2px solid #4285f4;
        outline: none;
    }
`;

export const StyledLabel = styled.label`
    font-size: 16px;
    margin-right: 20px;
    margin-left: 20px;
    color: #424242;
`;

export const WarningTitleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: center;
`;

export const WarningWord = styled.span`
    color: #ff5722;
`;

export const EachField = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;
`;

export const ContainerFilter = styled.span`
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;
    width: 50%;
    padding-bottom: 20px;
    padding-top: 20px;
`;

export const ContainerRadio = styled.span`
    display: flex;
    flex-direction: column;
    
`;

export const StyledContainerTitle = styled.div`
    display: flex;
    flex-direction: row;
    padding-bottom: 1vw;
    justify-content: flex-start;
    margin-left: 20px;
`;

export const ContainerTitle = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;
export const Title = styled.h2`
    margin-bottom: 8px;
`;

export const Stripe = styled.div`
    margin-top: 13px;
    margin-right: 13px;
    border-left:5px solid #4f4f4f;
    height: 65px;
`;

export const Wrap = styled.div`
    margin: 10px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`;

export const ContainerButton = styled.div`
    //width: 25vw;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-self: center;
    flex-wrap: wrap;
    //margin: 0 auto;
    @media screen and (max-width: 650px) {
        flex-direction: column;
    }
`;

export const InputRadios = styled.div`
    margin-bottom: 20px;
    flex: 0 0 33.333333%;
`;

export const FieldsRadio = styled.div`
    display: flex;
    flex-direction: row;
`;
