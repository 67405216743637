/* Generic pop up component */
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useHistory} from "react-router-dom";

export default function PopUp(props) {
    const [open, setOpen] = React.useState(true);
    let history = useHistory();

    const handleClose = () => {
        /*Success = 0 -> Nothing (stay in the same page)
        Success = 1 -> Reload
        Success = 2 -> Specific route
        Success = 3 -> Reload the child of an tab component*/
        setOpen(false);
        if(props.success === 1){
            window.location.reload()
        } else if(props.success === 2){
            history.replace(props.route)
        } else if(props.success === 3) {
            props.setNewFatherContext();
        }

        /* Close the popUp in the father component,
        to be able to call a new popUp*/
        if(props.handleClose !== undefined) {
            props.handleClose();
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {(props.string)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {props.acceptable && !props.agree ? 
                        <Button onClick={props.acceptFunction} color="primary">
                            Sim
                        </Button>
                        : null}
                    {props.acceptable && !props.agree?
                        <Button onClick={() => props.handleClose()} color="primary">
                            Não
                        </Button>
                        :
                        <Button onClick={handleClose} color="primary">
                            Ok
                        </Button>}
                </DialogActions>
            </Dialog>
        </div>
    );
}
