import React, { useContext, useEffect, useState } from 'react';
import { isUndefined } from 'svgsaver/src/utils';
import { useHistory } from 'react-router-dom';

/* Styles */
import {
    ContainerButtons,
    StyledInput,
    SecondContainerButtons,
} from './manage-tasks-tab.styles';

/* Components */
import CustomButton from '../../../../components/custom-button/custom-button.component';
import EnhancedTable from '../../../../components/table-to-show-data/table-to-show-data.component';
import RenderPage from '../../../../components/render-page/render-page.component';
import AddTaskToUser from './add-task-to-user/add-task-to-user.component';
import AddTask from './add-task/add-task.component';
import EditTask from './edit-task/edit-task.component';
import DeleteTaskFromUser from './delete-task-from-user/delete-task-from-user.component';

/* Material UI */
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';

/* Context */
import { authContext } from '../../../../contexts/AuthContext';

/* Api*/
import { getAllActiveUsersInCompany } from '../../../../api/ApiUser';
import {
    findAllTasks,
    findDisabledTasks,
} from '../../../../api/ApiTasks';
import { getOneCompany } from '../../../../api/ApiCompany';
import { findCompanyCategories } from '../../../../api/ApiCategory';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
        width: 240,
        borderRadius: 10,
        padding: 3,
    },
}));

export default function ManageIpaTasks(props) {
    const { auth } = useContext(authContext);
    const [toggleButton, setToggleButton] = useState('add');
    const [toggleTasksButton, setToggleTasksButton] = useState('enabledTasks');
    const [selectedTasks, setSelectedTasks] = useState([]);
    const [selectedTask, setSelectedTask] = useState({});
    const [globalCompany, setGlobalCompany] = useState({});
    const [reloadTable, setReloadTable] = useState(false);

    const [users, setUsers] = useState([]);
    const [user, setUser] = useState(auth.data.user);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState(null);
    const [fetchOpen, setFetchOpen] = useState(true);

    const [tasksTitle, setTasksTitle] = useState({
        firstText: 'Lista das tarefas cadastradas',
        warningText: '',
    });

    const [tasksByUserRows, setTaskByUserRows] = useState([]);

    const [taskRows, setTaskRows] = useState([]);
   
    const [taskData, setTaskData] = useState(taskRows);
    const [tasksByUserData, setTasksByUserData] = useState(tasksByUserRows);
    const [searchTasksText, setSearchTasksText] = useState('');
    const [searchTasksByUserText, setSearchTasksByUserText] = useState('');

    const [openCreate, setOpenCreate] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    let history = useHistory();

    const classes = useStyles();

    const tasksHeadCells = [
        {
            id: 'taskId',
            numeric: false,
            disablePadding: false,
            label: 'Código',
        },
        { id: 'name', numeric: false, disablePadding: false, label: 'Nome' },
        {
            id: 'description',
            numeric: false,
            disablePadding: false,
            label: 'Descrição',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Status',
        },
        {
            id: 'category',
            numeric: false,
            disablePadding: false,
            label: 'Categoria',
        },
        { id: 'action', numeric: true, disablePadding: false, label: 'Ação' },
    ];

    const handleOpenEdit = () => {
        setOpenEdit(!openEdit);
    };

    let editTasksButtonInTable = {
        buttonLabel: 'Editar tarefa',
        modal: true,
        modalHandle: handleOpenEdit,
        firstInformation: 'taskId',
    };

    useEffect(() => {
        let actualGlobalCompany;

        if (props.theGlobalCompanyHasChange) {
            setFetchOpen(true);
        }

        if (!isUndefined(props.globalCompany) && props.globalCompany !== null) {
            if (props.globalCompany.hasOwnProperty('companyId')) {
                actualGlobalCompany = props.globalCompany.companyId;
                setGlobalCompany(props.globalCompany.companyId);
            } else {
                actualGlobalCompany = auth.data.user.companyId;
                setGlobalCompany(auth.data.user.companyId);
            }
        } else {
            actualGlobalCompany = auth.data.user.companyId;
            setGlobalCompany(auth.data.user.companyId);
        }

        const callApiFindOneCompany = async (company) => {
            const response = await getOneCompany(auth, company);
            const { payload } = await response.json();

            setTasksTitle({
                firstText: 'Lista das tarefas cadastradas na ',
                warningText: `${payload[0].name}`,
            });
            setFetchOpen(false);
        };

        try {
            callApiFindOneCompany(actualGlobalCompany);
        } catch (error) {
            console.log(error);
        }
    }, [props, auth]);

    useEffect(() => {
        const callApiFindAllActiveUsers = async () => {
            const response = await getAllActiveUsersInCompany(auth, globalCompany);
            const body = await response.json();
            if (response.status !== 200) throw Error(body.message);

            body.payload.forEach(user => {
                delete user.allocation;
                delete user.birthDate;
                delete user.email;
                delete user.gender;
                delete user.dream;
                delete user.note;
                delete user.permissionRoleId;
                delete user.permissionRoleName;
            });
            setUsers(body.payload);
            setUser(globalCompany === auth.data.user.companyId ? auth.data.user : body.payload[0]);
        };

        const callApiFindAllTasks = async () => {
            const response = await findAllTasks(auth, globalCompany);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            body.payload.forEach(task => {
                delete task.companyId;
                delete task.categoryId;
                task.action = 1;
                if(task.active === 1) {
                    task.active = 'Ativa';
                } else {
                    task.active = 'Inativa';
                }
            });
            setTaskRows(body.payload);
            setTaskData(body.payload);
        };

        const callApiFindDisabledTasks = async () => {
            const response = await findDisabledTasks(auth, globalCompany);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            body.payload.forEach(task => {
                delete task.companyId;
                delete task.categoryId;
                task.action = 1;
                task.active = 'Inativa';
            });
            setTaskRows(body.payload);
            setTaskData(body.payload);
        };

        const callApiFindCompanyCategories = async () => {
            const response = await findCompanyCategories(auth, globalCompany);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            setCategories(body.payload);
        }

        try {
            callApiFindAllActiveUsers();
            if(toggleTasksButton === 'enabledTasks') {
                callApiFindAllTasks();
            } else {
                callApiFindDisabledTasks();
            }
            callApiFindCompanyCategories();
        } catch (error) {
            console.log(error);
        }
    }, [ globalCompany, reloadTable, toggleTasksButton]);

    useEffect(() => {
        setTasksByUserData(tasksByUserRows);
    }, [user, globalCompany, toggleButton]);

    useEffect(() => {
        setSelectedTasks([]);
    }, [toggleButton, user]);

    // exclude column list from filter
    let excludeColumns = ['email', 'permission', 'role'];

    const handleClick = (event, row) => {
        const selectedIndex = selectedTasks.indexOf(row.taskId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedTasks, row.taskId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedTasks.slice(1));
        } else if (selectedIndex === selectedTasks.length - 1) {
            newSelected = newSelected.concat(selectedTasks.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedTasks.slice(0, selectedIndex),
                selectedTasks.slice(selectedIndex + 1)
            );
        }

        setSelectedTasks(newSelected);
    };

    const handleSingleClick = (event, row) => {
        setSelectedTask(row);
    };

    const isSelected = ({ taskId }) => selectedTasks.indexOf(taskId) !== -1;

    const handleChangeTasks = (value) => {
        setSearchTasksText(value);
        filterDataTasks(value);
    };

    const handleChangeTasksByUser = (value) => {
        setSearchTasksByUserText(value);
        filterDataTasksByUser(value);
    };

    const handleChangeToggleButton = (event, newValue) => {
        setToggleButton(newValue);
    };

    const handleChangeToggleTasksButton = (event, newValue) => {
        setToggleTasksButton(newValue);
    };

    const handleOpenCreate = () => {
        setOpenCreate(!openCreate);
    };

    const filterDataTasks = (value) => {
        let aux = '';
        const lowercasedValue = value.toLowerCase().trim();

        if (lowercasedValue === '') setTaskRows(taskData);
        else {
            const filteredData = taskData.filter((item) => {
                return Object.keys(item).some((key) =>
                    excludeColumns.includes(key)
                        ? false
                        : item[key]
                        ? item[key]
                              .toString()
                              .toLowerCase()
                              .includes(lowercasedValue)
                        : aux.toString().toLowerCase().includes(lowercasedValue)
                );
            });
            setTaskRows(filteredData);
        }
    };

    const filterDataTasksByUser = (value) => {
        let aux = '';
        const lowercasedValue = value.toLowerCase().trim();

        if (lowercasedValue === '') setTaskByUserRows(tasksByUserData);
        else {
            const filteredData = tasksByUserData.filter((item) => {
                return Object.keys(item).some((key) =>
                    excludeColumns.includes(key)
                        ? false
                        : item[key]
                        ? item[key]
                              .toString()
                              .toLowerCase()
                              .includes(lowercasedValue)
                        : aux.toString().toLowerCase().includes(lowercasedValue)
                );
            });
            setTaskByUserRows(filteredData);
        }
    };

    const addTask = () => {
        history.replace(`/management/company/${globalCompany}/tasks/add`);
    };

    const defaultPropsUsers = {
        options: users,
        value: user,
        getOptionLabel: (option) => {
            return option.name;
        },
        getOptionSelected: (option) => {
            return option.name;
        },
        onChange: (event, newValue) => {
            setUser(newValue);
        },
    };

    const defaultPropsCategories = {
        options: categories,
        value: category,
        getOptionLabel: (option) => {
            return option && option.name;
        },
        getOptionSelected: (option) => {
            return option && option.name;
        },
        onChange: (event, newValue) => {
            setCategory(newValue);
        },
    };

    return (
        <>  
            <div>
                <ContainerButtons>
                    <div>
                        <StyledInput
                            type="text"
                            placeholder="Digite para pesquisar..."
                            value={searchTasksText}
                            onChange={(e) => handleChangeTasks(e.target.value)}
                        />
                    </div>
                    <div>
                    <CustomButton type="button" onClick={handleOpenCreate} addButton>
                        {' '}
                        Nova Tarefa{' '}
                    </CustomButton>
                    <ToggleButtonGroup 
                        size="medium" 
                        style={{ height: 55, marginLeft: 10 }}
                        value={toggleTasksButton} 
                        exclusive 
                        onChange={handleChangeToggleTasksButton}
                    >
                        <ToggleButton 
                            value="enabledTasks" 
                            style={{ fontFamily: 'Roboto', color:'#87cebf'}}
                        >
                            Ativadas
                        </ToggleButton>
                        <ToggleButton 
                            value="disabledTasks" 
                            style={{ fontFamily: 'Roboto', color:'#f16669'}}
                        >
                            Desativadas
                        </ToggleButton>
                    </ToggleButtonGroup>
                    </div>
                </ContainerButtons>
                <EnhancedTable
                    rows={taskRows}
                    headCells={tasksHeadCells}
                    title={tasksTitle}
                    edit={editTasksButtonInTable}
                    handleClick={handleSingleClick}
                />
                <Modal
                    open={openEdit}
                    onClose={handleOpenEdit}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <EditTask
                        companyId={globalCompany}
                        setReloadTable={setReloadTable}
                        reloadTable={reloadTable}
                        task={selectedTask}
                        handleOpenEdit={handleOpenEdit}
                        toggleTasksButton={toggleTasksButton}
                    />
                </Modal>
                <Modal
                    open={openCreate}
                    onClose={handleOpenCreate}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <AddTask 
                        companyId={globalCompany}
                        setReloadTable={setReloadTable}
                        reloadTable={reloadTable}
                    />
                </Modal>
            </div>

            <Typography variant="h5" style={{ paddingTop: 20 }}>Gerenciamento de tarefas de usuários</Typography>

            <div>
                <ContainerButtons style={{ paddingTop: 20 }}>
                    <div>
                        <StyledInput
                            type="text"
                            placeholder="Digite para pesquisar..."
                            value={searchTasksByUserText}
                            onChange={(e) =>
                                handleChangeTasksByUser(e.target.value)
                            }
                        />
                    </div>

                    <SecondContainerButtons>
                        <ContainerButtons>
                            <div>
                                <Autocomplete
                                    {...defaultPropsCategories}
                                    id="categories"
                                    options={categories}
                                    getOptionLabel={(options) => options.name}
                                    className={classes.formControl}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Categoria"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <Autocomplete
                                    {...defaultPropsUsers}
                                    id="users"
                                    options={users}
                                    getOptionLabel={(options) => options.name}
                                    autoSelect
                                    className={classes.formControl}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Usuário"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <Grid container spacing={2} direction="column" alignItems="center">
                                    <Grid item>
                                        <ToggleButtonGroup 
                                            size="medium" 
                                            style={{ height: 55 }}
                                            value={toggleButton} 
                                            exclusive 
                                            onChange={handleChangeToggleButton}
                                        >
                                            <ToggleButton 
                                                value="add" 
                                                style={{ fontFamily: 'Roboto', color:'#87cebf'}}
                                            >
                                                Adicionar
                                            </ToggleButton>
                                            <ToggleButton 
                                                value="delete" 
                                                style={{ fontFamily: 'Roboto', color:'#f16669'}}
                                            >
                                                Deletar
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>
                                </Grid>
                            </div>
                        </ContainerButtons>
                    </SecondContainerButtons>
                </ContainerButtons>
                {toggleButton === 'add' ? (
                    <AddTaskToUser 
                        user={user} 
                        companyId={globalCompany}
                        category={category}
                        availableTasks={tasksByUserRows}
                        setAvailableTasks={setTaskByUserRows}
                        setTasksByUserData={setTasksByUserData}
                        toggleButton={toggleButton}
                        selectedTasks={selectedTasks}
                        setSelectedTasks={setSelectedTasks}
                        handleClick={handleClick}
                        isSelected={isSelected}
                    />
                ) : toggleButton === 'delete' ? (
                    <DeleteTaskFromUser
                        user={user} 
                        companyId={globalCompany}
                        category={category}
                        tasksByUserRows={tasksByUserRows}
                        setTaskByUserRows={setTaskByUserRows}
                        setTasksByUserData={setTasksByUserData}
                        toggleButton={toggleButton}
                        selectedTasks={selectedTasks}
                        setSelectedTasks={setSelectedTasks}
                        handleClick={handleClick}
                        isSelected={isSelected}
                    />
                ): null}
            </div>
            {fetchOpen ? <RenderPage open={fetchOpen} /> : null}
        </>
    );
}
