import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

/* Styles */
import {
    DrawerAdjustment,
    ContainerSettings,
    LeftSettings,
    RightSettings,
    Profile,
    ProfileInformation,
    DrakkarAcademy,
    SettingTitle,
    ProfileEdit,
    ProfileEditButton,
    ButtonSetting,
    ButtonSetting2,
    PhotoEdit,
    UserName,
    UserFunction,
    Department,
    CompanyTime,
    City,
    Permission,
    TopCont,
    BotCont,
    LeftCont,
    RightCont,
    LabelTitle,
    LabelCont,
    UserImage,
} from './settings.styles';

/* Components */
import Header from '../../components/header/header.component';
import PopUp from '../../components/popup/popup.component';
import RenderPage from '../../components/render-page/render-page.component';

/* Material UI */
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton, InputAdornment } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';

/* API */
import { authContext } from '../../contexts/AuthContext';
import { changePassword } from '../../api/ApiUser';
import { editUser, uploadImage } from '../../api/ApiUser';
import { getOneCompany } from '../../api/ApiCompany';
import { getAllPermissions } from '../../api/ApiPemissions';
import { getAllDepartments } from '../../api/ApiDepartment';
import { getOneUser } from '../../api/ApiUser';

/* Permissions */
import permissions, { getPermission } from '../../utils/permission';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));

export default function SettingsPage(props) {
    const [verificationPassword, setVerificationPassword] = useState('');
    const [popup, setPopup] = useState(false);
    const [popupText, setPopupText] = useState('');
    const [popupTitle, setPopupTitle] = useState('');
    const [success, setSuccess] = useState(1);
    const { auth } = useContext(authContext);
    const classes = useStyles();
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [gender, setGender] = useState('');
    const [fortress, setFortress] = useState(auth.data.user.fortress);
    const [schooling, setSchooling] = useState(auth.data.user.schooling);
    const [actingCity, setActingCity] = useState(auth.data.user.actingCity);
    const [department, setDepartment] = useState(null);
    const [dream, setDream] = useState(auth.data.user.dream);
    const [purpose, setPurpose] = useState(auth.data.user.purpose);
    const [role, setRole] = useState(auth.data.user.role);
    const [permission, setPermission] = useState(auth.data.user.permissionName);
    const [name, setName] = useState(auth.data.user.name);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [allocationDate, setAllocationDate] = useState(null);

    const [imageUrl, setImageUrl] = useState();
    const [preview, setPreview] = useState();
    const [imageFile, setImageFile] = useState();
    const [openEditor, setOpenEditor] = useState(false);
    const [image, setImage] = useState(auth.data.user.fileUrl);

    const [popupRoute, setPopupRoute] = useState(null);

    const [deleting, setDeleting] = useState(false);
    const [activatingUser, setActivatingUser] = useState(false);
    const [freezingUser, setFreezingUser] = useState(false);
    const [warningTitle, setWarningTitle] = useState({});
    const [agree, setAgree] = useState(false);
    const [note, setNote] = useState('');
    const [fetchOpen, setFetchOpen] = useState(true);

    const fortressOptions = [
        'Amor',
        'Amor ao Aprendizado',
        'Apreciação da Beleza e Excelência',
        'Autocontrole',
        'Bravura',
        'Criatividade',
        'Critério',
        'Curiosidade',
        'Esperança',
        'Espiritualidade',
        'Generosidade',
        'Gratidão',
        'Humildade',
        'Humor',
        'Integridade',
        'Inteligência Social',
        'Justiça',
        'Liderança',
        'Perdão',
        'Perseverança',
        'Perspectiva',
        'Prudência',
        'Trabalho em Equipe',
        'Vitalidade',
    ];

    const genderOptions = ['Masculino', 'Feminino', 'Outro'];

    const handleChange1 = (event) => {
        const { value, name } = event.target;
        if (name === 'verificationPassword') setVerificationPassword(value);
        else setPassword(value);
    };

    useEffect(() => {
        const callApiFindOneUser = async () => {
            const response = await getOneUser(
                auth,
                auth.data.user.userId,
                auth.data.user.companyId
            );

            const { payload } = await response.json();

            setName(payload[0].name);
            setEmail(payload[0].email);
            setGender(payload[0].gender);
            setDream(payload[0].dream);
            setPurpose(payload[0].purpose);
            setFortress(payload[0].fortress);
            setNote(payload[0].note);
            setSchooling(payload[0].schooling);
            setActingCity(payload[0].allocation.actingCity);
            setRole(payload[0].allocation.role);

            setFetchOpen(false);
        };

        try {
            callApiFindOneUser();
        } catch (error) {
            console.log(error);
        }
    }, []);

    const defaultPropsGender = {
        options: genderOptions,
        value: gender,
        getOptionLabel: (option) => {
            return option;
        },
        getOptionSelected: (option) => {
            return option;
        },
        onChange: (event, newValue) => {
            setGender(newValue);
        },
    };

    const defaultPropsFortress = {
        options: fortressOptions,
        value: fortress,
        getOptionLabel: (option) => {
            return option;
        },
        getOptionSelected: (option) => {
            return option;
        },
        onChange: (event, newValue) => {
            setFortress(newValue);
        },
    };

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const handleClickShowConfirmPassword = () =>
        setShowConfirmPassword(!showConfirmPassword);

    const handleSubmit = async (event) => {
        event.preventDefault();
        handleClose();
        setFetchOpen(true);

        console.log(auth.data);

        let data = {
            name: name, 
            gender: gender, 
            email: email,
            dream: dream,
            fortress: auth.data.user.fortress,
            purpose: purpose,
            schooling: schooling,
        };

        try {
            let response = await editUser(
                data,
                auth,
                auth.data.user.userId,
                auth.data.user.companyId
            );
            if (response.status === 201) {
                if (imageFile) {
                    const imageResponse = await uploadImage(
                        auth,
                        imageFile,
                        props.match.params.userId,
                        props.match.params.companyId
                    );

                    if (
                        imageResponse.status === 500 ||
                        imageResponse.status === 422
                    ) {
                        setPopupTitle('Aviso');
                        setPopupText(
                            'Usuário editado, mas não foi possivel fazer upload da imagem.'
                        );
                        // setPopupRoute('/management/users');
                        setPopup(true);
                        setSuccess(1);
                    } else {
                        setPopupTitle('Sucesso');
                        setPopupText('Usuário editado.');
                        // setPopupRoute('/management/users');
                        setPopup(true);
                        setSuccess(1);
                    }
                } else {
                    setPopupTitle('Sucesso');
                    setPopupText('Usuário editado.');
                    // setPopupRoute('/management/users');
                    setPopup(true);
                    setSuccess(1);
                }
            } else {
                setPopupTitle('Erro');
                setPopupText('Usuário não pode ser editado.');
                setPopup(true);
                setSuccess(0);
            }
            setFetchOpen(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleClose = () => {
        setPopup(false);
        setSuccess(2);
    };

    const handleChange = (event) => {
        let { value, name } = event.target;
        if (name === 'name') setName(value);
        else if (name === 'password') setPassword(value);
        else if (name === 'confirmPassword') setConfirmPassword(value);
        else if (name === 'dream') setDream(value);
        else if (name === 'purpose') setPurpose(value);
        else if (name === 'fortress') setFortress(value);
        else if (name === 'schooling') setSchooling(value);
    };

    const handleChangeFile = (event) => {
        let { files } = event.target;

        if (!files[0]) return;

        const maxSize = 2097152; // 2 MB

        if (files[0].type.split('/')[0] !== 'image') {
            setPopupTitle('Erro');
            setPopupText('Tipo de arquivo inválido');
            setPopup(true);
            setSuccess(0);
            return;
        }

        if (files[0].size > maxSize) {
            setPopupTitle('Erro');
            setPopupText(
                `Arquivo muito grande. Tamanho máximo: ${
                    maxSize / 1024 / 1024
                } MB`
            );
            setPopup(true);
            setSuccess(0);
            return;
        }

        if (preview) URL.revokeObjectURL(preview);
        if (imageUrl) URL.revokeObjectURL(imageUrl);

        const objectUrl = URL.createObjectURL(files[0]);

        setImageUrl(objectUrl);
        setImageFile(files[0]);
        setOpenEditor(true);
    };

    return (
        <div>
            {!getPermission(
                auth.data.permissions,
                permissions.SYSTEM_CONFIGURATIONS
            ) ? (
                <Redirect
                    to={
                        auth.data.system
                            ? permissions.IPA_DEFAULT_ROUTE
                            : permissions.IF_DEFAULT_ROUTE
                    }
                />
            ) : null}
            <Header />
            <DrawerAdjustment>
                <ContainerSettings>
                    <LeftSettings>
                        <Profile>
                            <PhotoEdit>
                                {' '}
                                <div>
                                    {image ? (
                                        <UserImage
                                            style={{
                                                display: 'grid',
                                                alignSelf: 'center',
                                                border: '1px solid #ff7517',
                                                width: '180px',
                                                height: '180px',
                                                margin: 'auto',
                                            }}
                                            src={image}
                                        />
                                    ) : (
                                        <FaceIcon
                                            style={{
                                                fontSize: '46px',
                                            }}
                                        />
                                    )}
                                </div>
                            </PhotoEdit>
                            <UserName>{name}</UserName>
                            <UserFunction>{auth.data.user.role}</UserFunction>
                        </Profile>
                        <ProfileInformation>
                            <Department>
                                {auth.data.user.departmentName}
                            </Department>
                            <CompanyTime>2 anos</CompanyTime>
                            <City>{auth.data.user.actingCity}</City>
                            <Permission>
                                {auth.data.user.permissionName}
                            </Permission>
                        </ProfileInformation>
                        <DrakkarAcademy>
                            <ButtonSetting
                                onClick={() =>
                                    window.open(
                                        'https://painel.umentor.com.br/cadastro_treinamento/?con_cod=aca52776&pla=5',
                                        '_blank'
                                    )
                                }
                            >
                                Academia
                            </ButtonSetting>
                        </DrakkarAcademy>
                    </LeftSettings>
                    <RightSettings>
                        <SettingTitle>Configurações</SettingTitle>
                        <ProfileEdit>
                            <TopCont>
                                <LeftCont>
                                    <LabelCont>
                                        <LabelTitle>Nome</LabelTitle>
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            name="name"
                                            value={name}
                                            type="text"
                                            onChange={handleChange}
                                        />
                                    </LabelCont>
                                    <LabelCont>
                                        <LabelTitle>Gênero</LabelTitle>
                                        <Autocomplete
                                            {...defaultPropsGender}
                                            id="genders"
                                            disableClearable
                                            autoSelect
                                            onChange={handleChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </LabelCont>
                                    <LabelCont>
                                        <LabelTitle>Escolaridade</LabelTitle>
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            name="schooling"
                                            value={schooling}
                                            type="text"
                                            onChange={handleChange}
                                        />
                                    </LabelCont>
                                </LeftCont>

                                <RightCont>
                                    <LabelCont>
                                        <LabelTitle>Senha</LabelTitle>
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            name="password"
                                            value={password}
                                            onChange={handleChange}
                                            type={
                                                showPassword
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={
                                                                handleClickShowPassword
                                                            }
                                                            onMouseDown={
                                                                handleClickShowPassword
                                                            }
                                                        >
                                                            {showPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </LabelCont>
                                    <LabelCont>
                                        <LabelTitle>Confirmar Senha</LabelTitle>
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            name="confirmPassword"
                                            onChange={handleChange}
                                            value={confirmPassword}
                                            type={
                                                showConfirmPassword
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle confirmPassword visibility"
                                                            onClick={
                                                                handleClickShowConfirmPassword
                                                            }
                                                            onMouseDown={
                                                                handleClickShowConfirmPassword
                                                            }
                                                        >
                                                            {showConfirmPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </LabelCont>
                                    <LabelCont>
                                        <LabelTitle>Força</LabelTitle>
                                        <Autocomplete
                                            {...defaultPropsFortress}
                                            id="fortress"
                                            disabled
                                            onChange={handleChange}
                                            autoSelect
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    value={fortress}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </LabelCont>
                                </RightCont>
                            </TopCont>
                            <BotCont>
                                {' '}
                                <LabelCont>
                                    <LabelTitle>Propósito</LabelTitle>
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        name="purpose"
                                        onChange={handleChange}
                                        value={purpose}
                                    />
                                </LabelCont>
                                <LabelCont>
                                    <LabelTitle>Sonho</LabelTitle>
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        name="dream"
                                        onChange={handleChange}
                                        value={dream}
                                    />
                                </LabelCont>
                            </BotCont>
                        </ProfileEdit>

                        <ProfileEditButton>
                            <ButtonSetting2
                                type="submit"
                                onClick={handleSubmit}
                                editButton={true}
                            >
                                Editar
                            </ButtonSetting2>
                        </ProfileEditButton>
                    </RightSettings>
                </ContainerSettings>
            </DrawerAdjustment>
            {popup ? (
                <PopUp
                    title={popupTitle}
                    string={popupText}
                    success={success}
                    route={popupRoute}
                    agree={agree}
                    handleClose={handleClose}
                />
            ) : null}
            {/* {fetchOpen ? <RenderPage open={fetchOpen} /> : null} */}
        </div>
    );
}
