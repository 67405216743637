import React, {useState,useEffect,useContext} from 'react'

import GeneralGraphDataTreatment from './general-graphs-dataTreatment/general-graphs-dataTreatment'

/* auth */
import {authContext} from "../../../../../../../contexts/AuthContext";

/* api */
import {getUserAnnualAlignmentsGrade} from '../../../../../../../api/ApiAlignment'
import {getUserAnnualTasksGrade} from "../../../../../../../api/ApiTasks";
import {getAnnualIpaDepartmentsAlignments, getAnnualIpaDepartmentsTasks} from "../../../../../../../api/ApiDepartment"
import {getAnnualIpaCompanyAlignments, getAnnualIpaCompanyTasks} from "../../../../../../../api/ApiCompany"
import {getUserMonthAvg} from '../../../../../../../api/ApiUser';

const GeneralGraphDataCall = (props) => {

    const {auth} = useContext(authContext);
    const [year, setYear] = React.useState(new Date().getFullYear());

    const [userAlignmentsData, setUserAlignmentsData] = useState([
        {x:'Janeiro',y:null},
        {x:'Fevereiro',y:null},
        {x:'Março',y:null},
        {x:'Abril',y:null},
        {x:'Maio',y:null},
        {x:'Junho',y:null},
        {x:'Julho',y:null},
        {x:'Agosto',y:null},
        {x:'Setembro',y:null},
        {x:'Outubro',y:null},
        {x:'Novembro',y:null},
        {x:'Dezembro',y:null},
    ])
    const [userTasksData, setUserTasksData] = useState()

    const [firstDepartmentAlignmentsData, setFirstDepartmentAlignmentsData] = useState()
    const [firstDepartmentTasksData, setFirstDepartmentTasksData] = useState()
    const [secondDepartmentAlignmentsData, setSecondDepartmentAlignmentsData] = useState()
    const [secondDepartmentTasksData, setSecondDepartmentTasksData] = useState()
 
    const [companyAlignmentsData, setCompanyAlignmentsData] = useState()
    const [companyTasksData, setCompanyTasksData] = useState()

    const [userOtherYearAlignmentsData, setUserOtherYearAlignmentsData] = useState()
    const [userOtherYearTasksData, setUserOtherYearTasksData] = useState()
    const [departmentOtherYearAlignmentsData, setDepartmentOtherYearAlignmentsData] = useState()
    const [departmentOtherYearTasksData, setDepartmentOtherYearTasksData] = useState()
    const [companyOtherYearAlignmentsData, setCompanyOtherYearAlignmentsData] = useState()
    const [companyOtherYearTasksData, setCompanyOtherYearTasksData] = useState()

    const [departmentsNames, setDepartmentsNames] = useState([])

    const [jumpFirstRender, setJumpFirstRender] = useState(false);

    useEffect(()=>{ //user calls

        if(props.checksStatus[0].activated && Object.keys(props.actualUser).length !== 0){
            
            const callGetUserAnnualAlignmentsGrade = async (company,user,year) => {
                const response = await getUserAnnualAlignmentsGrade(auth, company.companyId, user,year);
                const body = await response.json();
                
                return body.payload[0]
            };

            const callGetUserAnnualTasksGrade = async (company,user,year) => {
                const response = await getUserAnnualTasksGrade(auth, company.companyId, user,year);
                const body = await response.json();
                
                return body.payload[0]
            };

            const callUserData = async () =>{

                const objIfEmpty = {};
                objIfEmpty[props.actualYear] = {};

                const resUserTasks = await callGetUserAnnualTasksGrade(props.globalCompany, props.actualUser,props.actualYear);

                if(Object.keys(resUserTasks).length !== 0) await setUserTasksData(resUserTasks);
                else await setUserTasksData(objIfEmpty)
                

                await setJumpFirstRender(true);
            }
        
            callUserData();
                
            const callApiGetUserMonthAvg = async (year,company,user) => {
                const response = await getUserMonthAvg(
                    auth,
                    year,
                    company,
                    user,
                );
                const body = await response.json();
                const ret = body.payload
                setUserAlignmentsData(userAlignmentsData.map((element,index)=> {
                    if(ret[index]){
                        element.y = String(ret[index].longScale)
                    } 
                    return element
                }))

            }
            callApiGetUserMonthAvg(year,auth.data.user.companyId,auth.data.user.userId)

        }

    },[props.actualUser,props.checksStatus, props.actualYear])

    useEffect(()=>{ //department calls

        if(props.actualDepartment.length === 0){
            setDepartmentsNames([]);
        }

        if(props.checksStatus[1].activated && props.actualDepartment.length !== 0){
            
            setDepartmentsNames(props.actualDepartment.map((dept)=>dept.name))

            const callGetAnnualIpaDepartmentsAlignments = async (year, companyId, deparmentId) => {
                const response = await getAnnualIpaDepartmentsAlignments(auth, year, companyId, deparmentId);
                const body = await response.json();
                return body.payload[0]
            };

            const callGetAnnualIpaDepartmentsTasks = async (year, companyId, deparmentId) => {
                const response = await getAnnualIpaDepartmentsTasks(auth, year, companyId, deparmentId);
                const body = await response.json();
                return body.payload[0]
            };


            const callFirstDepartmentData = async () =>{

                const objIfEmpty = {};
                objIfEmpty[props.actualYear] = {};


                const resFirstDepartmentAlignments = await callGetAnnualIpaDepartmentsAlignments(props.actualYear, props.globalCompany.companyId, props.actualDepartment[0].departmentId)
                
                if(Object.keys(resFirstDepartmentAlignments).length !== 0) {
                    setFirstDepartmentAlignmentsData(resFirstDepartmentAlignments);
                } 
                else setFirstDepartmentAlignmentsData(objIfEmpty)
                


                const resFirstDepartmentTasks = await callGetAnnualIpaDepartmentsTasks(props.actualYear, props.globalCompany.companyId, props.actualDepartment[0].departmentId)

                if(Object.keys(resFirstDepartmentTasks).length !== 0) setFirstDepartmentTasksData(resFirstDepartmentTasks);
                else setFirstDepartmentTasksData(objIfEmpty)
                

               setJumpFirstRender(true);
            }
        
            callFirstDepartmentData();


            if(props.actualDepartment.length === 2){

                const callSecondDepartmentData = async () =>{

                    const objIfEmpty = {};
                    objIfEmpty[props.actualYear] = {};
    
    
                    const resSecondDepartmentAlignments = await callGetAnnualIpaDepartmentsAlignments(props.actualYear, props.globalCompany.companyId, props.actualDepartment[1].departmentId)
                    
                    if(Object.keys(resSecondDepartmentAlignments).length !== 0) await setSecondDepartmentAlignmentsData(resSecondDepartmentAlignments);
                    else await setSecondDepartmentAlignmentsData(objIfEmpty)
                    
    
                    const resSecondDepartmentTasks = await callGetAnnualIpaDepartmentsTasks(props.actualYear, props.globalCompany.companyId, props.actualDepartment[1].departmentId)
    
                    if(Object.keys(resSecondDepartmentTasks).length !== 0) await setSecondDepartmentTasksData(resSecondDepartmentTasks);
                    else await setSecondDepartmentTasksData(objIfEmpty)
                    
    
                    await setJumpFirstRender(true);
                }
            
                callSecondDepartmentData();

            }
            

        }

    },[props.actualDepartment,props.checksStatus, props.actualYear])

    useEffect(()=>{ //company calls

        if(props.checksStatus[2].activated){
            
            const callGetAnnualIpaCompanyAlignments = async (year, companyId) => {
                const response = await getAnnualIpaCompanyAlignments(auth, year, companyId);
                const body = await response.json();
                return body.payload[0].data
            };

            const callGetAnnualIpaCompanyTasks = async (year, companyId) => {
                const response = await getAnnualIpaCompanyTasks(auth, year, companyId);
                const body = await response.json();
                return body.payload[0].data
            };

            const callCompanyData = async () =>{

                const objIfEmpty = {};
                objIfEmpty[props.actualYear] = {};


                const resCompanyAlignments = await callGetAnnualIpaCompanyAlignments(props.actualYear, props.globalCompany.companyId)
                
                if(Object.keys(resCompanyAlignments).length !== 0) await setCompanyAlignmentsData(resCompanyAlignments);
                else await setCompanyAlignmentsData(objIfEmpty)
                
                

                const resCompanyTasks = await callGetAnnualIpaCompanyTasks(props.actualYear, props.globalCompany.companyId)

                if(Object.keys(resCompanyTasks).length !== 0) await setCompanyTasksData(resCompanyTasks);
                else await setCompanyTasksData(objIfEmpty)
                

                await setJumpFirstRender(true);
            }
        
            callCompanyData();

            

        }

    },[props.globalCompany ,props.checksStatus[2].activated, props.actualYear])

    useEffect(()=>{ //other year calls

        if(props.checksStatus[3].activated && props.actualOtherYear !== ""){
         
            if(props.checksStatus[0].activated && Object.keys(props.actualUser).length !== 0){ //user other year
                const callGetUserAnnualAlignmentsGrade = async (company,user,year) => {
                    const response = await getUserAnnualAlignmentsGrade(auth, company.companyId, user,year);
                    const body = await response.json();
                    return body.payload[0]
                };
    
                const callGetUserAnnualTasksGrade = async (company,user,year) => {
                    const response = await getUserAnnualTasksGrade(auth, company.companyId, user,year);
                    const body = await response.json();
                    return body.payload[0]
                };
                

                const callUserOtherYearData = async () =>{

                    const objIfEmpty = {};
                    objIfEmpty[props.actualOtherYear] = {};
    
                    const resUserOtherYearAlignments = await callGetUserAnnualAlignmentsGrade(props.globalCompany, props.actualUser,props.actualOtherYear);
                    
                    if(Object.keys(resUserOtherYearAlignments).length !== 0) await setUserOtherYearAlignmentsData(resUserOtherYearAlignments);
                    else await setUserOtherYearAlignmentsData(objIfEmpty);
                    
                    const resUserOtherYearTasks = await callGetUserAnnualTasksGrade(props.globalCompany, props.actualUser,props.actualOtherYear);
    
                    if(Object.keys(resUserOtherYearTasks).length !== 0) await setUserOtherYearTasksData(resUserOtherYearTasks);
                    else await setUserOtherYearTasksData(objIfEmpty);
                    
    
                    await setJumpFirstRender(true);
                }
            
                callUserOtherYearData();
                
            }
            if(props.checksStatus[1].activated && props.actualDepartment.length !== 0){ //department other year
                
                
                const callGetAnnualIpaDepartmentsAlignments = async (year, companyId, deparmentId) => {
                    const response = await getAnnualIpaDepartmentsAlignments(auth, year, companyId, deparmentId);
                    const body = await response.json();
                    return body.payload[0]
                };
    
                const callGetAnnualIpaDepartmentsTasks = async (year, companyId, deparmentId) => {
                    const response = await getAnnualIpaDepartmentsTasks(auth, year, companyId, deparmentId);
                    const body = await response.json();
                    return body.payload[0]
                };

                const callDepartmentOtherYearData = async () =>{

                    const objIfEmpty = {};
                    objIfEmpty[props.actualOtherYear] = {};
    
    
                    const resDepartmentOtherYearAlignments = await callGetAnnualIpaDepartmentsAlignments(props.actualOtherYear, props.globalCompany.companyId, props.actualDepartment[0].departmentId)
                    
                    if(Object.keys(resDepartmentOtherYearAlignments).length !== 0) await setDepartmentOtherYearAlignmentsData(resDepartmentOtherYearAlignments);
                    else await setDepartmentOtherYearAlignmentsData(objIfEmpty)
                    
    
                    const resDepartmentOtherYearTasks = await callGetAnnualIpaDepartmentsTasks(props.actualOtherYear, props.globalCompany.companyId, props.actualDepartment[0].departmentId)
    
                    if(Object.keys(resDepartmentOtherYearTasks).length !== 0) await setDepartmentOtherYearTasksData(resDepartmentOtherYearTasks);
                    else await setDepartmentOtherYearTasksData(objIfEmpty)
                    
    
                    await setJumpFirstRender(true);
                }
            
                callDepartmentOtherYearData();

    
            }
            if(props.checksStatus[2].activated){ //company other year
                const callGetAnnualIpaCompanyAlignments = async (year, companyId) => {
                    const response = await getAnnualIpaCompanyAlignments(auth, year, companyId);
                    const body = await response.json();
                    return body.payload[0].data;
                };
    
                const callGetAnnualIpaCompanyTasks = async (year, companyId) => {
                    const response = await getAnnualIpaCompanyTasks(auth, year, companyId);
                    const body = await response.json();
                    return body.payload[0].data;
                };

                const callCompanyOtherYearData = async () =>{

                    const objIfEmpty = {};
                    objIfEmpty[props.actualOtherYear] = {};
    
    
                    const resCompanyOtherYearAlignments = await callGetAnnualIpaCompanyAlignments(props.actualOtherYear, props.globalCompany.companyId)
                    
                    if(Object.keys(resCompanyOtherYearAlignments).length !== 0) await setCompanyOtherYearAlignmentsData(resCompanyOtherYearAlignments);
                    else await setCompanyOtherYearAlignmentsData(objIfEmpty)
                    
    
                    const resCompanyOtherYearTasks = await callGetAnnualIpaCompanyTasks(props.actualOtherYear, props.globalCompany.companyId)
    
                    if(Object.keys(resCompanyOtherYearTasks).length !== 0) await setCompanyOtherYearTasksData(resCompanyOtherYearTasks);
                    else await setCompanyOtherYearTasksData(objIfEmpty)
                    
    
                    await setJumpFirstRender(true);
                }
            
                callCompanyOtherYearData();

            }
            

        }

    },[props.actualOtherYear , props.actualDepartment,props.checksStatus[3].activated,
        props.actualUser, props.globalCompany, props.actualOtherYear])


    return(
        <>
            {jumpFirstRender ? 
                <>
                    <GeneralGraphDataTreatment
                        /* Alignments and Tasks Data */
                        userAlignmentsData={userAlignmentsData} userTasksData={userTasksData}
                        firstDepartmentAlignmentsData={firstDepartmentAlignmentsData} firstDepartmentTasksData={firstDepartmentTasksData}
                        secondDepartmentAlignmentsData={secondDepartmentAlignmentsData} secondDepartmentTasksData={secondDepartmentTasksData}
                        companyAlignmentsData={companyAlignmentsData} companyTasksData={companyTasksData}

                        /* Other Years Alignments and Tasks Data */
                        userOtherYearAlignmentsData={userOtherYearAlignmentsData} userOtherYearTasksData={userOtherYearTasksData}
                        departmentOtherYearAlignmentsData={departmentOtherYearAlignmentsData} departmentOtherYearTasksData={departmentOtherYearTasksData}
                        companyOtherYearAlignmentsData={companyOtherYearAlignmentsData} companyOtherYearTasksData={companyOtherYearTasksData}

                        /* Actual - Filters Information */
                        actualDepartment={props.actualDepartment}
                        departmentsNames={departmentsNames}
                        actualOtherYear={props.actualOtherYear}
                        

                        /* Status */
                        checksStatus={props.checksStatus}
                        setSelectedDeparmentCounter={props.setSelectedDeparmentCounter}
                    />


                </>
            :null}
        </>
    )



}

export default GeneralGraphDataCall;