import styled from 'styled-components/macro'

export const Filters = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
    @media screen and (max-width: 362px) {
        flex-direction: column;
    }
`;

export const FiltersAndSwitch = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 750px) {
        flex-direction: column;
    }
`;

export const TableContainer = styled.div`
    padding-top: 1vw;
    padding-bottom: 3vw;
`;

export const LegendColor = styled.div`
    width: 25px;
    height: 25px;
    margin-right: 5px;
    background-color: ${({color}) => color};
`;

export const LegendItem = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0px 20px 5px 20px;
`;

export const Legend = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 20px; 

    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
`;