import React, { createContext, useState, useEffect } from 'react';
import { getOneUser } from '../api/ApiUser';
import { verify } from 'crypto';

export const authContext = createContext({});

const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({ loading: true, data: null, valid: true });

    const setAuthData = (data) => {
        setAuth({data: data, valid: true});
    };

    useEffect(() => {
        const verifyToken = async () => {
            let data = JSON.parse(window.localStorage.getItem('authData'));
            if(!data) {
                setAuth({ loading: false, data: null, valid: false });
                return;
            };

            const response = await getOneUser({data}, data?.user.userId, data?.user.companyId);

            setAuth({ loading: false, data, valid: response.status === 200});
        }

        verifyToken();
    }, []);
    //2. if object with key 'authData' exists in localStorage, we are putting its value in auth.data and we set loading to false.
    //This function will be executed every time component is mounted (every time the user refresh the page);
    
    useEffect(() => {
        window.localStorage.setItem('authData', JSON.stringify(auth.data));
    }, [auth.data]);
// 1. when **auth.data** changes we are setting **auth.data** in localStorage with the key 'authData'.

    return (
        <authContext.Provider value={{ auth, setAuthData }}>
            {children}
        </authContext.Provider>
    );
};

export default AuthProvider;
