import React, { useContext, useEffect, useState } from 'react';
import { isUndefined } from 'svgsaver/src/utils';

/* Styles */
import {
    StyledInput,
    Filters,
    StyledContainerButtonXlsx,
    ButtonStyles,
} from './manage-records-if.styles';

/* Components */
import EnhancedTable from '../../../../components/table-to-show-data/table-to-show-data.component';
import RenderPage from '../../../../components/render-page/render-page.component';

/* Material UI */
import { makeStyles } from '@material-ui/core/styles';

/* Context */
import { authContext } from '../../../../contexts/AuthContext';

/* Api*/
import { getActiveUnfilledIFUsers, findIfYears, getActiveUnfilledIFUsersFromDepartment } from '../../../../api/ApiIF';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { getExcelDataAverageIF } from '../../../../api/ApiCompany';

/* Excel */
import ReactExport from 'react-data-export';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
}));

const optionsMonths = [
    { name: 'Janeiro', monthNumber: '01' },
    { name: 'Fevereiro', monthNumber: '02' },
    { name: 'Março', monthNumber: '03' },
    { name: 'Abril', monthNumber: '04' },
    { name: 'Maio', monthNumber: '05' },
    { name: 'Junho', monthNumber: '06' },
    { name: 'Julho', monthNumber: '07' },
    { name: 'Agosto', monthNumber: '08' },
    { name: 'Setembro', monthNumber: '09' },
    { name: 'Outubro', monthNumber: '10' },
    { name: 'Novembro', monthNumber: '11' },
    { name: 'Dezembro', monthNumber: '12' },
];

export default function UsersRecordsIF(props) {
    const classes = useStyles();

    const {auth} = useContext(authContext);

    const [title, setTitle] = React.useState({});
    const [rows, setRows] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [searchText, setSearchText] = useState('');
    const [spreadsheetCells, setSpreadsheetCells] = React.useState([]);
    const [unfilledIFUsersData, setUnfilledIFUsersData] = React.useState([]);

    let actualDate = new Date().toISOString();
    actualDate = actualDate.split('-');
    let actualMonth = parseInt(actualDate[1]);
    let actualYear = actualMonth === 1 ? new Date().getFullYear() - 1: new Date().getFullYear();
    actualMonth = actualMonth === 1 ? 12: actualMonth - 1;

    useEffect(() => {
        const callFindIfYears = async () => {
            const response = await findIfYears(auth, auth.data.user.userId, props?.globalCompany?.companyId || auth.data.user.companyId);
            let { message, payload } = await response.json();
            if (response.status !== 200) throw Error(message);
            payload = payload.map((curr) => String(curr.year));

            setYears(payload);
        };

        try {
            callFindIfYears();
        } catch (err) {
            console.log(err);
        }
    }, [auth, props.globalCompany]);

    const [year, setYear] = useState(actualYear.toString());
    const [years, setYears] = useState([]);
    const [month, setMonth] = React.useState(optionsMonths[actualMonth - 1]);
    const [fetchOpen, setFetchOpen] = useState(true);

    const headCells = [
        {
            id: 'userId',
            numeric: false,
            disablePadding: false,
            label: 'Código',
        },
        {
            id: 'userName',
            numeric: false,
            disablePadding: false,
            label: 'Nome',
        },
        {
            id: 'startedAt',
            numeric: false,
            disablePadding: false,
            label: 'Data de Admissão',
        },
        {
            id: 'departmentName',
            numeric: false,
            disablePadding: false,
            label: 'Setor',
        },
    ];

    // handle change event of search input
    const handleChange = value => {
        setSearchText(value);
        filterData(value);
    };

    // exclude column list from filter
    let excludeColumns = [];

    const filterData = (value) => {
        let aux = '';
        const lowercasedValue = value.toLowerCase().trim();

        if (lowercasedValue === '') setRows(data);
        else {
            const filteredData = data.filter((item) => {
                return Object.keys(item).some((key) =>
                    excludeColumns.includes(key)
                        ? false
                        : item[key]
                        ? item[key]
                              .toString()
                              .toLowerCase()
                              .includes(lowercasedValue)
                        : aux.toString().toLowerCase().includes(lowercasedValue)
                );
            });
            setRows(filteredData);
        }
    };
    
    useEffect(() => {
        let actualGlobalCompany;

        if(props.theGlobalCompanyHasChange) {
            setFetchOpen(true);
        }

        if (!isUndefined(props.globalCompany) && props.globalCompany !== null) {
            if (props.globalCompany.hasOwnProperty('companyId'))
                actualGlobalCompany = props.globalCompany.companyId;
            else
                actualGlobalCompany = auth.data.user.companyId;
        } else {
            actualGlobalCompany = auth.data.user.companyId;
        }

        const callGetActiveUnfilledIFUsers = async (globalCompany) => {
            setTitle({
                firstText: `Usuários que não enviaram o IF de `,
                warningText: `${month.name}`,
            });

            let date = {
                year: year,
                month: month.monthNumber,
            };

            const response = await getActiveUnfilledIFUsers(
                auth,
                date,
                globalCompany
            );
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            setRows(body.payload);
            setData(body.payload);
            setUnfilledIFUsersData(body.payload);
            setFetchOpen(false);
            props.resetChangeActualGlobalCompany(false);
        };

        const callGetActiveUnfilledIFUsersFromDepartment = async (globalCompany) => {
            setTitle({
                firstText: `Usuários que não enviaram o IF de `,
                warningText: `${month.name}`,
            });

            let date = {
                year: year,
                month: month.monthNumber,
            };

            const response = await getActiveUnfilledIFUsersFromDepartment(
                auth,
                date,
                globalCompany,
                auth.data.user.departmentId
            );
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            setRows(body.payload);
            setData(body.payload);
            setUnfilledIFUsersData(body.payload);
            setFetchOpen(false);
            props.resetChangeActualGlobalCompany(false);
        };

        const callGetExcelDataIF = async (actualGlobalCompany) => { 
            let date = {
                month: month.monthNumber,
                year: year,
            };

            const response = await getExcelDataAverageIF(
                auth,
                date,
                actualGlobalCompany
            );
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body.payload
        }

        callGetExcelDataIF(actualGlobalCompany)
            .then(res => {
                props.resetChangeActualGlobalCompany(false);
                setSpreadsheetCells(res);
            })

        try {
            if(auth.data.user.permissionRoleId === 10) {
                callGetActiveUnfilledIFUsersFromDepartment(actualGlobalCompany)
            } else {
                callGetActiveUnfilledIFUsers(actualGlobalCompany)
            }
        } catch (error) {
            console.log(error);
        }
    }, [year, month, props.globalCompany]);

    const defaultPropsYears = {
        options: years,
        value: year,
        getOptionLabel: (option) => {
            return option || '';
        },
        getOptionSelected: (option) => {
            return option || '';
        },
        onChange: (event, newValue) => {
            setYear(newValue);
        },
    };

    const defaultPropsMonths = {
        value: month,
        options: optionsMonths,
        defaultValue: optionsMonths[actualMonth - 2],
        getOptionLabel: (option) => {
            return option.name
        },
        getOptionSelected: (option) => {
            return option.name
        },
        onChange: (event, newValue) => {
            if(newValue !== null) {
                setFetchOpen(true);
            }
            setMonth(newValue);
        },
    };
    
    return (
        <div>
            {auth.data.user.permissionRoleId === 1 ?
                <StyledContainerButtonXlsx>
                    <ExcelFile filename="Médias IF" element={<ButtonStyles pdfButton>Gerar Média IF</ButtonStyles>} >
                        <ExcelSheet data={spreadsheetCells} name="Médias IF">
                            <ExcelColumn value="name" label="Nome"/>
                            <ExcelColumn value="avg" label="Média"/>
                        </ExcelSheet>
                    </ExcelFile>

                    <ExcelFile
                        filename={`Relatório Não Preenchimento IF - ${month.name}`}
                        element={
                            <ButtonStyles pdfButton>
                                Relatório Não Preenchimento IF
                            </ButtonStyles>
                        }
                    >
                        <ExcelSheet
                            data={unfilledIFUsersData}
                            name="Usuários que não preencheram o IF"
                        >
                            <ExcelColumn value="userName" label="Nome" />
                            <ExcelColumn value="startedAt" label="Data Admissão" />
                            <ExcelColumn value="departmentName" label="Setor" />
                        </ExcelSheet>
                    </ExcelFile>
                </StyledContainerButtonXlsx>
                : null}
            <Filters>
                <StyledInput
                    type="text"
                    placeholder="Digite para pesquisar..."
                    value={searchText}
                    onChange={e => handleChange(e.target.value)}
                />
                <Autocomplete
                    {...defaultPropsYears}
                    id="departments"
                    autoSelect
                    disableClearable
                    className={classes.formControl}
                    style={{ width: 150 }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Anos"
                            variant="outlined"
                        />
                    )}
                />
                <Autocomplete
                    {...defaultPropsMonths}
                    id="departments"
                    autoSelect
                    disableClearable
                    className={classes.formControl}
                    style={{width: 150}}
                    renderInput={(params) => <TextField {...params} label="Meses"
                                                        variant="outlined"/>}
                />
            </Filters>
            <EnhancedTable rows={rows} headCells={headCells} title={title}/>
            {fetchOpen ?
                <RenderPage open={fetchOpen}/> : null}
        </div>
    );
}
