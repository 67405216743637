import React, {useContext, useEffect, useState} from 'react';
import {isUndefined} from "svgsaver/src/utils";

/* Material UI */
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

/* Style */
import {
    Filters,
    Label,
    StyledSwitch
} from './history-attribute-table.styles';

/* Components */
import RenderPage from "../../../../../components/render-page/render-page.component";
import SwitchTwoLabels from '../../../../../components/switch/switch.component';

/* api */
import {getByAttributeDepartmentAnnual} from "../../../../../api/ApiCompany";
import {getAllDepartments, getByAttributeAndDepartmentUserAnnual} from "../../../../../api/ApiDepartment";
import permissions, {
    getSubmodulePermission,
} from '../../../../../utils/permission';
/* Context */
import {authContext} from "../../../../../contexts/AuthContext";

/* Utils */
import {yearsUntilNow} from "../../../../../utils/initialData";
import {cleanDataOfAttributeCall} from "../../../../../utils/clearDataFromApi/clearDataFromIF";

/* Functions */
import TableToShowValue from "../../../../../components/table-to-show-value/table-to-show-value.component";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    }
}));

const today = new Date();

const optionsYear = yearsUntilNow();

const yy = today.getFullYear();

export default function HistoryTable(props) {
    const classes = useStyles();

    const {auth} = useContext(authContext);

    let stop = null;
    for (let i = 0; i < optionsYear.length; i++)
        if (optionsYear[i] === yy.toString()) {
            stop = i;
            break
        }

    const [actualDepartment, setActualDepartment] = useState(null);
    const [department, setDepartment] = useState(null);
    const [year, setYear] = React.useState(optionsYear[stop]);
    const [rows, setRows] = React.useState(null);
    const [rowsName, setRowsName] = React.useState([]);
    const [user] = useState(null);
    const [actualButton, setActualButton] = useState("professional");
    const [actualButtonName, setActualButtonName] = useState("Profissional");
    const [optionsDepartments, setOptionsDepartments] = useState([]);
    const [showInactiveUsers, setShowInactiveUsers] = useState(false);

    let tableData = {
        componentTitleStart: `Mostrando dados de aspecto `,
        componentTitleFirstInfo: `${actualButtonName}`,
        componentTitleMiddle: (props.radioValue === "employee") ? `e ` : "",
        componentTitleSecondInfo: (props.radioValue === "employee") ? `${actualDepartment}` : "",
        componentTitleSecondMiddle: ` em `,
        componentTitleThirdInfo: `${year}`,
        tableTitle: `Aspecto ${year}`
    };

    useEffect(() => {
            let tempDepartment;

            let actualGlobalCompany;

            if (!isUndefined(props.globalCompany) && (props.globalCompany !== null)) {
                if (props.globalCompany.hasOwnProperty("companyId"))
                    actualGlobalCompany = props.globalCompany.companyId;
                else
                    actualGlobalCompany = auth.data.user.companyId;
            } else {
                actualGlobalCompany = auth.data.user.companyId;
            }

            const callApiDepartments = async (globalCompany) => {
                const response = await getAllDepartments(auth, globalCompany);
                const body = await response.json();

                if (response.status !== 200) throw Error(body.message);

                return body.payload
            };

            const callApiByAttribute = async (attribute, globalCompany) => {
                const response = await getByAttributeDepartmentAnnual(auth, year, attribute, globalCompany);
                const body = await response.json();

                if (response.status !== 200) throw Error(body.message);
                let tempData = cleanDataOfAttributeCall(body.payload, props.radioValue, year);

                for (let i = 0; i < tempData.auxName.length; i++) {
                    tempData.auxName[i] = {
                        name: tempData.auxName[i].name
                    };
                }
                setRowsName(tempData.auxName);

                return tempData.newResult;
            };

            const callApiAttributeAndDepartmentIF = async (attribute, department, globalCompany) => {
                const response = await getByAttributeAndDepartmentUserAnnual(auth, year, attribute, department, globalCompany);
                const body = await response.json();
                let filteredBody = body.payload;

                if (response.status !== 200) throw Error(body.message);

                if (!showInactiveUsers) {
                    filteredBody = filteredBody.filter(filteredBody => filteredBody.active === 1);
                }
      
                let tempData = cleanDataOfAttributeCall(filteredBody, props.radioValue, year);

                for (let i = 0; i < tempData.auxName.length; i++) {
                    tempData.auxName[i] = {
                        name: tempData.auxName[i].name,
                        active: tempData.auxName[i].active
                    };
                }
                setRowsName(tempData.auxName);

                return tempData.newResult;
            };

            callApiDepartments(actualGlobalCompany)
                .then(res => {
                    setOptionsDepartments(res.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))
                })
                .catch(err => console.log(err));

            if (props.radioValue === "department") {
                callApiByAttribute(actualButton, actualGlobalCompany)
                    .then(res => {
                        setRows(res);
                    })
                    .catch(err => console.log(err));
            } else if (props.radioValue === "employee") {
                if (Object.entries(props.department).length !== 0 && department === null) {
                    tempDepartment = {
                        departmentId: props.department.departmentId,
                        name: props.department.name,
                        companyId: props.department.companyId
                    };
                }

                if (department) {
                    setActualDepartment(department.name);
                } else if (tempDepartment) {
                    setActualDepartment(tempDepartment.name);
                    setDepartment(tempDepartment);
                }

                callApiAttributeAndDepartmentIF(actualButton, department ? department.departmentId : tempDepartment ? tempDepartment.departmentId : null, actualGlobalCompany)
                    .then(res => {
                        setRows(res);
                    })
                    .catch(err => console.log(err));
            }
            
        }, [year, user, props.radioValue, actualButton, props.department, department, props.globalCompany, showInactiveUsers]
    );

    const handleAttribute = (event) => {
        setActualButton(event.target.value);

        if (event.target.value === "professional") {
            setActualButtonName("Profissional");
        } else if (event.target.value === "personal") {
            setActualButtonName("Pessoal");
        } else if (event.target.value === "financial") {
            setActualButtonName("Financeiro");
        } else if (event.target.value === "motivational") {
            setActualButtonName("Motivacional");
        } else if (event.target.value === "health") {
            setActualButtonName("Saúde");
        } else {
            setActualButtonName("Ambiente");
        }

    };

    const defaultPropsDepartments = {
        options: optionsDepartments,
        defaultValue: department,
        getOptionLabel: (option) => {
            return option.name
        },
        getOptionSelected: (option) => {
            return option.name
        },
        onChange: (event, newValue) => {
            setDepartment(newValue);
        },
    };

    if (rows === null) {
        return <RenderPage open={true}/>;
    }

    const handleChangeSwitch = () => {
        setShowInactiveUsers(!showInactiveUsers);
    };

    return (
        <Container maxWidth='lg'>
            <Filters>
                <FormControl component="fieldset">
                    <Label>Selecionar por Aspecto</Label>
                    <RadioGroup aria-label="type" name="type" value={actualButton} onChange={handleAttribute}
                                style={{display: "flex", flexDirection: "row"}}>
                        <FormControlLabel value="professional" control={<Radio/>} label="Profissional"/>
                        <FormControlLabel value="personal" control={<Radio/>} label="Pessoal"/>
                        <FormControlLabel value="financial" control={<Radio/>} label="Financeiro"/>
                        <FormControlLabel value="motivational" control={<Radio/>} label="Motivacional"/>
                        <FormControlLabel value="health" control={<Radio/>} label="Saúde"/>
                        <FormControlLabel value="environment" control={<Radio/>} label="Ambiente"/>
                    </RadioGroup>
                </FormControl>
            </Filters>
            <Filters>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Ano</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={year}
                        onChange={e => {
                            setYear(e.target.value)
                        }}
                        label="Ano">
                        {optionsYear.map((year, index) => {
                            return (
                                <MenuItem key={index} value={year}>{year}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                {props.radioValue === "employee" &&
                getSubmodulePermission(
                    props.submodules,
                    permissions.DEPARTMENTS_VISUALIZATION_FILTER
                ) ?
                    <Autocomplete
                        {...defaultPropsDepartments}
                        id="departments"
                        autoSelect
                        disabled={
                            getSubmodulePermission(
                                props.submodules,
                                permissions.EMPLOYEES_VISUALIZATION_FILTER_DEPARTMENT
                            )
                        }
                        className={classes.formControl}
                        style={{width: 150}}
                        renderInput={(params) =>
                            <TextField {...params} 
                                label="Setor"
                                variant="outlined"
                            />}
                    /> : null}
            </Filters>
            <StyledSwitch>
                <SwitchTwoLabels checkAction={setShowInactiveUsers} leftWord="" rightWord="Visualizar desativados" onChange={handleChangeSwitch}/>
            </StyledSwitch>
            <TableToShowValue 
                rowsName={rowsName} 
                rows={rows} 
                year={year} 
                tableData={tableData} 
                showNotActived={showInactiveUsers}
            />
        </Container>
    );
}
