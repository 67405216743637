import React, { useEffect } from 'react';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';


import {StyledFlexAdjust, StyledLabel, StyledSelectedLabel} from './switch.styles'

export default function SwitchTwoLabels({checkAction, leftWord, rightWord}) {
  const [state, setState] = React.useState({
    checkedA: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  useEffect(() =>{
    checkAction(state.checkedA);
  },[state])

  return (
    <FormGroup row>
        <StyledFlexAdjust>
            {state.checkedA ? <StyledLabel>{leftWord}</StyledLabel> : <StyledSelectedLabel>{leftWord}</StyledSelectedLabel>}
            
              <FormControlLabel control={<Switch checked={state.checkedA} onChange={handleChange} name="checkedA"/>} />
            {state.checkedA ? <StyledSelectedLabel>{rightWord}</StyledSelectedLabel> : <StyledLabel>{rightWord}</StyledLabel>}
        </StyledFlexAdjust>
    </FormGroup>
  );
}
