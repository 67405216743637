import React from 'react';

/*Styles*/
import {
    //Container Principal
    Container,
    ContainerRow,
    Column1,
    Column2,
    Column3,
    SendButton,
    FirstColumn,
    SecondColumn,
    ThirdColumn,
    //Container de edição
    ContainerRowEdit,
    Column1Edit,
    Column2Edit,
} from './create-nps-tab.styles';

/* Material UI */
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { Button, InputLabel, makeStyles, Modal, TextField } from '@material-ui/core';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import EditIcon from '@material-ui/icons/Edit';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import ptBR from 'date-fns/locale/pt-BR';

import { authContext } from '../../../../contexts/AuthContext';
import { listNpsPrevious, updateNps } from '../../../../api/ApiNps';

/* API */
import { createNps } from '../../../../api/ApiNps';
import PopUp from '../../../../components/popup/popup.component';
import { isValidDate } from '../../../../utils/isValidDate';

const useStyles = makeStyles((theme) => ({
    table: {
        width: '50rem',
        border: '0px solid black',
        borderRadius: '8px',
        boxShadow:
            '0px 2.075px 4.15px rgba(0, 0, 0, 0.16), 0px 4.15px 8.3px rgba(0, 0, 0, 0.12), 0px 2.075px 16.6px rgba(0, 0, 0, 0.1)',
        '@media (max-width: 1200px)': {
            width: '100%',
        },
    },

    signalButton: {
        transform: 'translateX(15px) scale(0.7)',
        display: 'grid',
        paddingLeft: '8px',
        alignSelf: 'center',
    },

    tableDate: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '-0.02em',
        color: '#4F4F4F',
    },

    button: {
        fontFamily: 'Roboto, sans-serif',
        width: '12rem',
        margin: '10 0 10 0',
        fontWeight: '400',
        borderRadius: '15px',
        height: '4.5rem',
        padding: '15px',
        boxShadow:
            '0px 2.075px 4.15px rgba(0, 0, 0, 0.16), 0px 4.15px 8.3px rgba(0, 0, 0, 0.12), 0px 2.075px 16.6px rgba(0, 0, 0, 0.1)',
        color: '#fff',
        fontSize: '18px',
        textTransform: 'inherit',
        '@media (max-width: 680px)': {
            width: '15rem',
        },
    },

    typography: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 500,
        fontSize: '1.3rem',
        lineHeight: '72px',
        letterSpacing: '-0.02em',
        color: '#4F4F4F',
        '@media (max-width: 759px)': {
            marginBottom: '0.5rem',
            paddingLeft: 0,
        },
    },

    datePicker: {
        justifySelf: 'center',
        width: '60%',
        height: '80%',
        transform: 'translateY(-10px)',
        alignSelf: 'center',
    },

    typographyModal: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 600,
        fontSize: '1.5rem',
        lineHeight: '72px',
        letterSpacing: '-0.02em',
        color: '#4F4F4F',
        gridColumn: '1/3',
        gridRow: '1/2',
        '@media (max-width: 759px)': {
            marginBottom: '0.5rem',
            paddingLeft: 0,
        },
    },

    datePickerModalStart: {
        gridColum: '1/2',
        gridRow: '2/3',
        justifySelf: 'center',
        width: '70%',
        paddingRight: '20px',
        margin: '12px',
        marginBottom: '20px',
    },
    datePickerModalEnd: {
        gridColum: '1/2',
        gridRow: '3/4',
        justifySelf: 'center',
        width: '70%',
        paddingRight: '20px',
        margin: '12px',
        marginBottom: '20px',
    },

    paperModal: {
        display: 'grid',
        gridTemplateRows: '1.1fr 1fr 1fr 1fr',
        gridTemplateColumns: 'repeat(2, 1fr)',
        marginTop: '14.5%',
        marginLeft: '50%',
        padding: '2rem 2rem',
        transform: 'translateX(-50%)',
        width: 600,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid grey',
        borderRadius: '10px',
        boxShadow:
            '0px 2.075px 4.15px rgba(0, 0, 0, 0.16), 0px 4.15px 8.3px rgba(0, 0, 0, 0.12), 0px 2.075px 16.6px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Roboto',
        textAlign: 'center',
        '@media (max-width: 680px)': {
            marginTop: '80%',
            transform: 'translate(-50%, -50%) scale(0.7)',
        },
    },

    editalSwitch: { transform: 'translateY(2px)' },

    containerSwitchModal: {
        alignSelf: 'center',
        width: 219,
        gridColumn: '2/3',
        justifySelf: 'end',
        gridRow: '2/3',
    },

    buttonModal: {
        fontFamily: 'Roboto, sans-serif',
        width: '8rem',
        fontWeight: 'bolder',
        borderRadius: '15px',
        height: '3rem',
        padding: '10px',
        marginRight: '45px',
        transform: 'translateY(10px)',
        boxShadow:
            '0px 2.075px 4.15px rgba(0, 0, 0, 0.16), 0px 4.15px 8.3px rgba(0, 0, 0, 0.12), 0px 2.075px 16.6px rgba(0, 0, 0, 0.1)',
        color: '#fff',
        fontSize: '14px',
        textTransform: 'inherit',
        '@media (max-width: 680px)': {
            width: '15rem',
        },
        gridColumn: '2/3',
        justifySelf: 'center',
        gridRow: '3/4',
    },
    selectControl: {
        margin: '0 auto',
        width: '80%',
        marginBottom: '20px',
        '@media (max-width: 680px)': {
            width: '80%',
            padding: 10,
        },
    },
    selectControlModal: {
        gridColum: '1/2',
        gridRow: '4/5',
        justifySelf: 'center',
        width: '65%',
        margin: '12px',
        marginBottom: '20px',
        marginRight: '25px',
        '@media (max-width: 680px)': {
            width: '80%',
            padding: 10,
        },
    },
}));

export default function CreateNps() {
    const { auth } = React.useContext(authContext);

    const [popup, setPopup] = React.useState(false);
    const [popupText, setPopupText] = React.useState('');
    const [popupTitle, setPopupTitle] = React.useState('');
    const [success, setSuccess] = React.useState(1);
    const [checked, setChecked] = React.useState(false);
    const [openEditionModal, setOpenEditionModal] = React.useState(false);

    //Status do NPS
    // const [npsStatus, setNpsStatus] = React.useState(null);
    const [semester, setSemester] = React.useState('');
    const [editSemester, setEditSemester] = React.useState('');

    //states para filtro de data
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);

    const [previousNps, setPreviousNps] = React.useState([]);
    const [startDateEdition, setStartDateEdition] = React.useState(null);
    const [endDateEdition, setEndDateEdition] = React.useState(null);
    const [currentNpsId, setCurrentNpsId] = React.useState(null);

    const toggleChecked = () => {
        setChecked((prev) => !prev);
    };

    React.useEffect(() => {
        const callApiListNpsPrevious = async () => {
            const response = await listNpsPrevious(
                auth,
                auth.data.user.companyId
            );

            const { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            setPreviousNps(payload);
        };

        try {
            callApiListNpsPrevious();
        } catch (error) {
            console.log(error);
        }
    }, [auth]);

    const handleStartDateChangeEdition = (date) => {
        if (date && isValidDate(date)) {
            const dateChanged = new Date(date);
            setStartDateEdition(format(dateChanged, 'MM/dd/yyyy'));
        } else {
            setStartDateEdition('');
        }
    };

    const handleFinalDateChangeEdition = (date) => {
        if (date && isValidDate(date)) {
            const dateChanged = new Date(date);
            setEndDateEdition(format(dateChanged, 'MM/dd/yyyy'));
        } else {
            setEndDateEdition('');
        }
    };

    const handleStartDateChange = (date) => {
        if (date && isValidDate(date)) {
            const dateChanged = new Date(date);
            setStartDate(format(dateChanged, 'MM/dd/yyyy'));
        } else {
            setStartDate('');
        }
    };

    const handleFinalDateChange = (date) => {
        if (date && isValidDate(date)) {
            const dateChanged = new Date(date);
            setEndDate(format(dateChanged, 'MM/dd/yyyy'));
        } else {
            setEndDate('');
        }
    };

    const saveFormatDate = (date) => {
        if (!date) return null;
        const [month, day, year] = date.split('/');
        return `${year}/${month}/${day}`;
    };

    const editFormatDate = (date) => {
        if (!date) return null;
        const [month, day, year] = date.split('/');
        return `${year}/${month}/${day}`;
    };

    const editNps = async () => {
        try {
            const response = await updateNps(
                auth,
                auth.data.user.companyId,
                currentNpsId,
                editFormatDate(startDateEdition),
                editFormatDate(endDateEdition),
                editSemester,
                checked ? 1 : 0
            );
            const { message } = await response.json();

            if (response.status !== 200) throw Error(message);
            setPopupTitle('Sucesso');
            setPopupText('Nps atualizado com sucesso.');
            setPopup(true);
            setSuccess(1);
        } catch (error) {
            setPopupTitle('Erro');
            setPopupText(`Nps não pôde ser atualizado: ${error.message}`);
            setPopup(true);
            setSuccess(0);
        }
    };

    const saveNps = async () => {
        try {
            const response = await createNps(
                auth,
                auth.data.user.companyId,
                saveFormatDate(startDate),
                saveFormatDate(endDate),
                semester
            );
            const { message } = await response.json();
    
            if (response.status !== 201) throw Error(message);
            setPopupTitle('Sucesso');
            setPopupText('Nps criado com sucesso.');
            setPopup(true);
            setSuccess(1);
        } catch (error) {
            setPopupTitle('Erro');
            setPopupText(`Nps não pode ser criado: ${error.message}`);
            setPopup(true);
            setSuccess(0);
        }
    };

    const classes = useStyles();

    const handleOpenEdition = (
        startDate,
        endDate,
        active,
        npsId,
        semester
    ) => {
        const [startMonth, startDay, startYear] = startDate.split('/');
        const [endMonth, endDay, endYear] = endDate.split('/');

        setOpenEditionModal(true);
        setStartDateEdition(`${startDay}/${startMonth}/${startYear}`);
        setEndDateEdition(`${endDay}/${endMonth}/${endYear}`);
        setChecked(active);
        setCurrentNpsId(npsId);
        setEditSemester(semester);
    };
    const handleCloseEdition = () => {
        setOpenEditionModal(false);
    };

    const editionModalBody = () => (
        <div className={classes.paperModal}>
            <Typography className={classes.typographyModal} variant="h6">
                Editar NPS
            </Typography>{' '}
            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                <KeyboardDatePicker
                    variant="inline"
                    className={classes.datePickerModalStart}
                    format="dd/MM/yyyy"
                    margin="normal"
                    id={`edit-start-date-${currentNpsId}`}
                    label="Data de Inicio"
                    value={startDateEdition}
                    onChange={handleStartDateChangeEdition}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />

                <KeyboardDatePicker
                    variant="inline"
                    className={classes.datePickerModalEnd}
                    format="dd/MM/yyyy"
                    margin="normal"
                    id={`edit-end-date-${currentNpsId}`}
                    label="Data de Fim"
                    value={endDateEdition}
                    minDate={startDate}
                    onChange={handleFinalDateChangeEdition}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    minDateMessage="Data final deve ser menor que a data inicial"
                />
            </MuiPickersUtilsProvider>
            <TextField
                select
                value={editSemester}
                className={classes.selectControlModal}
                onChange={(event) => {
                    setEditSemester(event.target.value);
                }}
                label="Semestre"
            >
                <MenuItem value="1º Semestre">1º Semestre</MenuItem>
                <MenuItem value="2º Semestre">2º Semestre</MenuItem>
            </TextField>
            <FormControlLabel
                className={classes.containerSwitchModal}
                control={
                    <Switch
                        checked={checked}
                        className={classes.editalSwitch}
                        onChange={toggleChecked}
                    />
                }
                label={!checked ? 'NPS Inativo' : 'NPS Ativo'}
            />
            <Button
                variant="contained"
                color="primary"
                className={classes.buttonModal}
                onClick={() => {
                    editNps();
                }}
            >
                Editar NPS
            </Button>
        </div>
    );

    return (
        <Container>
            <ContainerRow>
                <Column1>
                    {' '}
                    <Typography className={classes.typography} variant="h6">
                        Criar novo NPS
                    </Typography>{' '}
                </Column1>
                <Column2>
                    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                        <FirstColumn>
                            <KeyboardDatePicker
                                variant="inline"
                                className={classes.datePicker}
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="start-date"
                                label="Inicial"
                                value={startDate}
                                onChange={handleStartDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </FirstColumn>
                        <SecondColumn>
                            <KeyboardDatePicker
                                variant="inline"
                                className={classes.datePicker}
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="end-date"
                                label="Final"
                                value={endDate}
                                minDate={startDate}
                                onChange={handleFinalDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                minDateMessaformControlge="Data final deve ser menor que a data inicial"
                            />
                        </SecondColumn>
                    </MuiPickersUtilsProvider>
                    <ThirdColumn>
                        <TextField
                            select
                            label="Semestre"
                            value={semester}
                            className={classes.selectControl}
                            onChange={(event) => {
                                setSemester(event.target.value);
                            }}
                        >
                            <MenuItem value="1º Semestre">
                                1º Semestre
                            </MenuItem>
                            <MenuItem value="2º Semestre">
                                2º Semestre
                            </MenuItem>
                        </TextField>
                    </ThirdColumn>
                </Column2>
                <Column3>
                    <SendButton>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            disabled={!startDate || !endDate ? true : false}
                            onClick={() => {
                                saveNps();
                            }}
                        >
                            Criar NPS
                        </Button>
                    </SendButton>
                </Column3>
            </ContainerRow>

            <ContainerRowEdit>
                <Column1Edit>
                    {' '}
                    <Typography className={classes.typography} variant="h6">
                        Edições ativas
                    </Typography>{' '}
                </Column1Edit>
                <Column2Edit>
                    <TableContainer className={classes.table} component={Paper}>
                        {previousNps.map((curr) => {
                            const newStartDate = new Date(
                                curr.startDate.split('/').reverse().join('/')
                            );

                            const newEndDate = new Date(
                                curr.endDate.split('/').reverse().join('/')
                            );

                            const today = new Date();
                            // get yesterday date - 00:00:00
                            const yesterday = new Date(
                                today.getFullYear(),
                                today.getMonth(),
                                today.getDate() - 1
                            );
                            return (
                                <Table size="normal" aria-label="a dense table">
                                    <TableRow key={curr.npsId}>
                                        <TableCell align="center">
                                            <Brightness1Icon
                                                style={
                                                    curr.active === 1 &&
                                                    newStartDate <= today &&
                                                    newEndDate > yesterday
                                                        ? {
                                                              color: '#4DD770',
                                                          }
                                                        : curr.active === 1 &&
                                                          newStartDate > today
                                                        ? {
                                                              color: '#FFD60A',
                                                          }
                                                        : {
                                                              color: '#FF3B30',
                                                          }
                                                }
                                                className={classes.signalButton}
                                            />
                                        </TableCell>

                                        <TableCell
                                            className={classes.tableDate}
                                            align="center"
                                        >
                                            {`Início: ${curr.startDate}`}
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableDate}
                                            align="center"
                                        >
                                            {' '}
                                            {`Fim: ${curr.endDate}`}
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableDate}
                                            align="center"
                                        >
                                            {' '}
                                            {`Semestre: ${curr.semester}`}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button
                                                disabled={
                                                    curr.active === 0 &&
                                                    newStartDate < new Date()
                                                }
                                                onClick={() =>
                                                    handleOpenEdition(
                                                        curr.startDate,
                                                        curr.endDate,
                                                        Boolean(curr.active),
                                                        curr.npsId,
                                                        curr.semester
                                                    )
                                                }
                                            >
                                                <EditIcon
                                                    disabled={
                                                        curr.active === 1 &&
                                                        newStartDate <
                                                            new Date()
                                                    }
                                                />{' '}
                                            </Button>
                                            <Modal
                                                open={openEditionModal}
                                                onClose={handleCloseEdition}
                                                aria-labelledby="simple-modal-title"
                                                aria-describedby="simple-modal-description"
                                            >
                                                {editionModalBody()}
                                            </Modal>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            );
                        })}
                    </TableContainer>
                </Column2Edit>
            </ContainerRowEdit>
            {popup ? (
                <PopUp
                    title={popupTitle}
                    string={popupText}
                    success={success}
                />
            ) : null}
        </Container>
    );
}
