import styled from 'styled-components/macro';
export const Container = styled.div`
    margin: 0px auto;
    font-family: Roboto;
    background-color: #fff;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px;
    max-width: 80vw;
    @media screen and (max-width: 700px) {
        max-width: 94vw;
    }
`;

export const ContainerRow = styled.div`
    border-bottom: 1px solid #e0e0e0;
    font-family: 'Roboto', 'sans-serif';
    grid-auto-columns: 2fr 2fr 1fr;
    padding: 2rem 5rem;
    display: grid;
    grid-template-areas:
        'column1 column1 column1'
        'column2 column2 column3';
    @media (max-width: 920px) {
        grid-template-areas:
            'column1 column1 column1'
            'column2 column2 column2'
            'column3 column3 column3';
        padding: 2rem 0rem;
    }
`;

export const Column1 = styled.div`
    font-family: 'Roboto', 'sans-serif';
    display: grid;
    grid-area: column1;
    font-family: 'Roboto';
    @media (max-width: 920px) {
        text-align: center;
    }
`;

export const Column2 = styled.div`
    display: grid;
    grid-area: column2;
    font-family: 'Roboto', 'sans-serif';
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 'firstDatePicker secondDatePicker thirdDatePicker';
    @media (max-width: 920px) {
        justify-items: center;
    }
    @media (max-width: 460px) {
        grid-template-areas:
            'firstDatePicker firstDatePicker'
            'secondDatePicker secondDatePicker';
    }
`;

export const FirstColumn = styled.div`
    font-family: 'Roboto', 'sans-serif';
    display: grid;
    grid-area: firstDatePicker;
    width: 90%;
    border: 0px solid black;
    border-radius: 8px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    @media (max-width: 920px) {
        width: 90%;
    }
    @media (max-width: 460px) {
        width: 100%;
        margin-bottom: 5px;
    }
`;

export const SecondColumn = styled.div`
    font-family: 'Roboto', 'sans-serif';
    display: grid;
    grid-area: secondDatePicker;
    width: 90%;
    border: 0px solid black;
    border-radius: 8px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    @media (max-width: 920px) {
        width: 90%;
    }
    @media (max-width: 460px) {
        width: 100%;
        margin-top: 5px;
    }
`;

export const ThirdColumn = styled.div`
    font-family: 'Roboto', 'sans-serif';
    display: grid;
    grid-area: thirdDatePicker;
    width: 55%;
    border: 0px solid black;
    border-radius: 8px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    @media (max-width: 920px) {
        width: 55%;
    }
    @media (max-width: 460px) {
        width: 100%;
        margin-top: 5px;
    }
`;

export const Column3 = styled.div`
    display: grid;
    grid-area: column3;
    align-self: center;
    @media (max-width: 920px) {
        margin-top: 20px;
    }
`;

export const SendButton = styled.div`
    display: grid;
    height: 80%;
    margin: 0 auto;
`;

//Edit NPS

export const ContainerRowEdit = styled.div`
    border-bottom: 1px solid #e0e0e0;
    font-family: 'Roboto', 'sans-serif';
    padding: 2rem 5rem;
    display: grid;
    grid-template-areas:
        'column1Edit'
        'column2Edit';
    @media (max-width: 920px) {
        padding: 2rem 0rem;
    }
`;

export const Column1Edit = styled.div`
    font-family: 'Roboto', 'sans-serif';
    display: grid;
    grid-area: column1Edit;
    font-family: 'Roboto';
    @media (max-width: 920px) {
        text-align: center;
    }
`;

export const Column2Edit = styled.div`
    display: grid;
    grid-area: column2Edit;
    font-family: 'Roboto', 'sans-serif';
`;
