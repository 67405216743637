import { apiUrl } from './Api';

export function addTask(data, auth, company) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },

        body: JSON.stringify({
            name: data.name,
            description: data.description,
            categoryId: data.categoryId,
        }),
    };

    return fetch(`${apiUrl}/companies/${company}/tasks`, requestOptions)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function editTask(data, auth, company) {
    const authorization = `Bearer ${auth.data.token}`;
    const task = data.taskId;

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },

        body: JSON.stringify({
            name: data.name,
            description: data.description,
            categoryId: data.categoryId,
        }),
    };

    return fetch(`${apiUrl}/companies/${company}/tasks/${task}`, requestOptions)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function updateTaskStatus(auth, company, taskId, status) { 
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
        body: JSON.stringify({
            active: status,
        }),
    };

    return fetch(
        `${apiUrl}/companies/${company}/tasks/${taskId}/updateStatus`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function addTaskToUser(data, auth) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = data.company;
    const user = data.user;
    const tasks = data.tasks;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
        body: JSON.stringify({
            tasks: tasks,
        }),
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/tasks/assign`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function deleteTasksFromUser(data, auth) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = data.company;
    const user = data.user;
    const tasks = data.tasks;

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
        body: JSON.stringify({
            tasks: tasks,
        }),
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/tasks/remove`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function findAllTasks(auth, company) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(`${apiUrl}/companies/${company}/tasks`, requestOptions)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function findDisabledTasks(auth, company) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(`${apiUrl}/companies/${company}/tasks/findListDisabledTasks`, requestOptions)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getMatchMonthTasks(auth, data, company) {
    const authorization = `Bearer ${auth.data.token}`;
    const year = data.year;
    const month = data.month;
    const user = data.user;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/tasks/rate?matchDate=${year}-${month}-08`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function findAllAvailableTasks(auth, company, user) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/tasks?notAssigned=true`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function findAllActiveUserTasks(auth, company, actualUser) {
    const authorization = `Bearer ${auth.data.token}`;

    const user = actualUser ? actualUser.userId : auth.data.user.userId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/tasks`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function findAllUserTasks(auth, company, actualUser) {
    const authorization = `Bearer ${auth.data.token}`;

    const user = actualUser ? actualUser.userId : auth.data.user.userId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/tasks?all=true`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function findAllCompanyTasks(auth, company) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/tasks?all=true`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getUserAnnualTasksGrade(auth, company, actualUser, year) {
    const authorization = `Bearer ${auth.data.token}`;

    const user = actualUser ? actualUser.userId : auth.data.user.userId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/tasks/history?startDate=${year}-01-01&endDate=${
            parseInt(year) + 1
        }-01-01`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function sendMonthlyTask(data, auth) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = data.company;
    const user = data.user;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
        body: JSON.stringify({
            tasks: [...data.tasks],
        }),
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/tasks/rate`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getActiveUnfilledAssingnmentUsers(auth, date, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany ? globalCompany : auth.data.user.companyId;
    const year = date.year;
    const month = date.month;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/ipa/unfilledAssignments?matchDate=${year}-${month}-01`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getAllTaskGradesInCompany(auth, company, actualTask) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/tasks/${actualTask}`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getUserSpecificTaskGrades(
    auth,
    company,
    actualUser,
    actualTask,
    year
) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${actualUser}/tasks/${actualTask}?startDate=${year}-01-01&endDate=${
            parseInt(year) + 1
        }-01-01`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getDepartmentSpecificTaskGrades(
    auth,
    company,
    departmentId,
    actualTask,
    year
) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/departments/${departmentId}/tasks/${actualTask}/getAvgTaskDepartment?startDate=${year}-01-01&endDate=${
            parseInt(year) + 1
        }-01-01`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getCompanySpecificTaskGrades(auth, company, actualTask, year) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/tasks/${actualTask}/getAvgTask?startDate=${year}-01-01&endDate=${
            parseInt(year) + 1
        }-01-01`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getAllDepartmentTasks(auth, company, departmentId) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/departments/${departmentId}/getAllTasks`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getAllUsersMonthAvgTask(auth, date, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany;
    const year = date.year;
    const month = date.month;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/getTasksAvg?matchDate=${year}-${month}-01`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getNumberOfUsersFromTaskDepartment(auth, company, departmentId, taskId) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/departments/${departmentId}/tasks/${taskId}/getNumberOfUsersFromTask`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getNumberOfUsersFromTask(auth, company, taskId) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/tasks/${taskId}/getNumberOfUsersFromTask`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}