import styled from "styled-components/macro";

export const InsideContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 4vw;
`;
