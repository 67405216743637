import {apiUrl} from "./Api";

export function getAnnualIpaCompanyTasks(auth, year, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany;
    year = parseInt(year);

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/companies/${company}/ipa/tasksScore?startDate=${year}-01-01&endDate=${year+1}-01-01`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}
export function getExcelDataIPA(auth, date, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany;
    const year = date.year;
    const month = date.month;
    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/companies/${company}/getExcelData?matchDate=${year}-${month}-01`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}

export function getCurrentUnfilledIPA(auth, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany;
    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/companies/${company}/getCurrentUnfilledIPA`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });
}

export function getUnfilledIPA(auth, date, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany;
    const year = date.year;
    const month = date.month;
    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/companies/${company}/getUnfilledIPA?matchDate=${year}-${month}-01`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}

export function getExcelDataAverageIF(auth, date, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany;
    const year = date.year;
    const month = date.month;
    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/companies/${company}/avgIf?matchDate=${year}-${month}-01`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}

export function getAnnualIpaCompanyAlignments(auth, year, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany;
    year = parseInt(year);

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/companies/${company}/ipa/alignmentsScore?startDate=${year}-01-01&endDate=${year+1}-01-01`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}

export function getByAttributeDepartmentAnnual(auth, year, attribute, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany ? globalCompany : auth.data.user.companyId;
    year = parseInt(year);

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/companies/${company}/ifAttribute?attribute=${attribute}&startDate=${year}-01-01&endDate=${year+1}-01-01`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}

export function getAllCompanies(auth) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/companies`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}

export function getOneCompany(auth, company) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/companies/${company}`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}

export function addCompany(auth, data) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        },

        body: JSON.stringify({
            name: data.name,
            cnpj: data.cnpj,
        })
    };

    return fetch(`${apiUrl}/companies`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        })
}

export function editCompany(auth, data, company) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        },

        body: JSON.stringify({
            name: data.name,
            cnpj: data.cnpj,
        })
    };

    return fetch(`${apiUrl}/companies/${company}`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        })
}

export function getLastNpsAvgFromCompany(auth, year, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany;
    year = parseInt(year);

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/companies/${company}/nps/getLastNpsAvgFromCompany?startDate=${year}-01-01&endDate=${year+1}-01-01`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}