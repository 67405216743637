import { apiUrl } from './Api';

export function findAllActiveUserAlignments(auth, company, actualUser) {
    const authorization = `Bearer ${auth.data.token}`;

    const user = actualUser ? actualUser.userId : auth.data.user.userId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/alignments`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function findAllOlderUserAlignments(auth, company, actualUser) {
    const authorization = `Bearer ${auth.data.token}`;

    const user = actualUser ? actualUser.userId : auth.data.user.userId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/alignments/findAllOlderAlignments`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function findAllUserAlignments(auth, company, actualUser) {
    const authorization = `Bearer ${auth.data.token}`;

    const user = actualUser ? actualUser.userId : auth.data.user.userId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/alignments?all=true`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function findUserAlignmentsAvailableToUser(auth, company, actualUser) {
    const authorization = `Bearer ${auth.data.token}`;

    const user = actualUser ? actualUser.userId : auth.data.user.userId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/alignments/available`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function addAlignmentBetweenUsers(auth, data) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = data.company;
    const users = data.users;
    const userId = data.userId;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
        body: JSON.stringify({
            users: users,
        }),
    };

    return fetch(`${apiUrl}/companies/${company}/users/${userId}/alignments`, requestOptions)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function deleteAlignmentFromUser(auth, data) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = data.company;
    const users = data.users;
    const userId = data.userId;

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
        body: JSON.stringify({
            users: users,
        }),
    };

    return fetch(`${apiUrl}/companies/${company}/users/${userId}/alignments`, requestOptions)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getMatchMonthAlignments(auth, data, company) {
    const authorization = `Bearer ${auth.data.token}`;
    const year = data.year;
    const month = data.month;
    const user = data.user;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/alignments/rate?matchDate=${year}-${month}-01`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getUserAnnualAlignmentsGrade(auth, company, actualUser, year) {
    const authorization = `Bearer ${auth.data.token}`;

    const user = actualUser ? actualUser.userId : auth.data.user.userId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/alignments/history?startDate=${year}-01-01&endDate=${
            parseInt(year) + 1
        }-01-01`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function sendMonthlyAlignment(data, auth) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = data.company;
    const user = data.user;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
        body: JSON.stringify({
            alignments: [...data.alignments],
        }),
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/alignments/rate`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getAlignmentMatches(auth, company, actualUser, year) {
    const authorization = `Bearer ${auth.data.token}`;

    const user = actualUser ? actualUser.userId : auth.data.user.userId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/alignments/matches?startDate=${year}-01-01&endDate=${
            parseInt(year) + 1
        }-01-01`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getAlignmentMatchesInfo(auth, company, actualUser, year) {
    const authorization = `Bearer ${auth.data.token}`;

    const user = actualUser ? actualUser.userId : auth.data.user.userId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/alignments/matches/info?startDate=${year}-01-01&endDate=${
            parseInt(year) + 1
        }-01-01`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getActiveUnfilledAlignmentUsers(auth, date, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany ? globalCompany : auth.data.user.companyId;
    const year = date.year;
    const month = date.month;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/ipa/unfilledAlignments?matchDate=${year}-${month}-01`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getAlignmentAllMatchesAndEpisodes(auth, company, year, month) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/alignments/matches/all?matchDate=${
            year + '-' + month + '-01'
        }`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function findMonthAverageFromUser(auth, company, actualUser) {
    const authorization = `Bearer ${auth.data.token}`;

    const user = actualUser ? actualUser.userId : auth.data.user.userId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/alignments/getMonthAvg`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}
export function findYearAverageFromUser(auth, company, actualUser) {
    const authorization = `Bearer ${auth.data.token}`;

    const user = actualUser ? actualUser.userId : auth.data.user.userId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/alignments/getYearAvg`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function findYearsAverageFromUser(auth, company, actualUser) {
    const authorization = `Bearer ${auth.data.token}`;

    const user = actualUser ? actualUser.userId : auth.data.user.userId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/alignments/getYearsAvg`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getAllUsersMonthAvg(auth, date, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany ? globalCompany : auth.data.user.companyId;
    const year = date.year;
    const month = date.month;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/alignments/getAllUsersMonthAvg?month=${month}&year=${year}`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function findAlignmentYears(auth, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany ? globalCompany.companyId : auth.data.user.companyId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/alignments/findAlignmentYears`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function findUserAlignmentYears(auth, userId, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany ? globalCompany : auth.data.user.companyId;
    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${userId}/alignments/findUserAlignmentYears`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function findUserAlignments(auth, globalCompany, userId, sameDepartment) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany ? globalCompany : auth.data.user.companyId;
    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    if(sameDepartment){
        return fetch(
            `${apiUrl}/companies/${company}/users/${userId}/alignments/findUserAlignments?sameDepartment=${sameDepartment}`,
            requestOptions
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    }else{
        return fetch(
            `${apiUrl}/companies/${company}/users/${userId}/alignments/findUserAlignments`,
            requestOptions
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    }
        
}
