import styled from 'styled-components/macro';

export const ContainerImage = styled.div`
    margin: 10px;
    display: flex;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-content: center;
`;

export const UserImage = styled.img`
    display: inline-block;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin-bottom: 10px;
    cursor: pointer;
`;
