const MIN_VAL = -100,
    MAX_VAL = 100;

// Width of the clock graph bars
const barWidth = 0.16;

// Width of the clock graph bars gaps
const barGap = 0;

export function getRgb(value) {
    let r, g, b;

    switch (true) {
        case value === null || isNaN(value): //no data
            r = 98;
            g = 98;
            b = 98;
            break;
        case value <= 100 && value >= 70: //3
            r = 61;
            g = 154;
            b = 21;
            break;
        case value <= 69 && value >= 30: //3
            r = 241;
            g = 241;
            b = 10;
            break;
        case value < 30: //3
            r = 241;
            g = 10;
            b = 10;
            break;
        // case (value <= 100 && value > 67): //3
        //     r = 56;
        //     g = 118;
        //     b = 29;
        //     break;
        // case (value <= 67 && value > 33)://2
        //     r = 61;
        //     g = 154;
        //     b = 21;
        //     break;
        // case (value <= 33 && value > 1): //1
        //     r = 0;
        //     g = 255;
        //     b = 0;
        //     break;
        // case (value <= 1 && value >= -33): //-1
        //     r = 255;
        //     g = 255;
        //     b = 0;
        //     break;
        // case (value < -33 && value >= -67): //-2
        //     r = 255;
        //     g = 120;
        //     b = 0;
        //     break;
        // case (value <= -67): //-3
        //     r = 255;
        //     g = 0;
        //     b = 0;
        //     break;
    }

    return { r, g, b };
}

// Returns the angle used to represent the value between the minValue and maxValue
export function getRadiusValue(value, minValue, maxValue) {
    let total = maxValue - minValue;
    return ((2 * value) / total) * (Math.PI * 2);
}

export function getCategory(category) {
    switch (category) {
        case "Saúde":
            return "Saude";
        case "Ambiente de Trabalho":
            return "Ambiente";
        default:
            return category;
    }
}

export function getColor(value, type) {
    let newValue = getRgb(parseInt(value));

    let r = newValue.r;
    let g = newValue.g;
    let b = newValue.b;

    if (type) return ["rgba(" + r + "," + g + "," + b + ", 0.8)"].join("");
    else return ["rgba(" + r + "," + g + "," + b + ", 1)"].join("");
}

export function getSmoothColor(value, type) {
    let newValue = getRgb(parseInt(value));

    let r = newValue.r;
    let g = newValue.g;
    let b = newValue.b;

    if (type === 1)
        //employee
        return ["rgba(" + r + "," + g + "," + b + ", 0.4)"].join("");
    else if (type === 0)
        //company
        return ["rgba(" + r + "," + g + "," + b + ", 0.4)"].join("");
    //icon color
    else return ["rgba(" + r + "," + g + "," + b + ", 0.8)"].join("");
}

export function circularBarBase(position, value) {
    if (value === null || isNaN(value)) {
        value = null;
    }
    let type;
    if (position === 1) type = 0;
    else if (position === 2) type = 1;
    else type = 3;
    return {
        if: getRadiusValue(100, MIN_VAL, MAX_VAL),
        radius0: position * barWidth + position * barGap + 1,
        radius: (position + 1) * barWidth + position * barGap + 1,
        color: getSmoothColor(value, type),
    };
}

export function circularBarValue(name, position, value, minValue, maxValue) {
    if (value === null || isNaN(value)) {
        value = null;
    }
    let type;
    if (name === "Individual") type = 0;
    else type = 1;
    return {
        if: getRadiusValue(value, minValue, maxValue),
        title: name,
        radius0: position * barWidth + position * barGap + 1,
        radius: (position + 1) * barWidth + position * barGap + 1,
        color: getColor(value, type),
    };
}

export function getMatchColor(episodes, matches) {
    if (episodes === undefined || matches === undefined) {
        return "#626262";
    }

    const percentage = (matches / episodes) * 100;

    if (percentage >= 80) {
        return "#0F0";
    } else if (percentage >= 60) {
        return "#FF0";
    } else if (percentage < 60) {
        return "#F00";
    } else {
        return "#626262";
    }
}
