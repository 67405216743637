import React, { useContext, useEffect, useState } from 'react';

/* Components */
import Header from '../../../components/header/header.component';
import UserIntro from './user-intro/user-intro.component';
import UserDepartment from './user-department/user-department.component';
import DataTab from './data-tab/data-tab.component';
import UserAbout from './user-about/user-about.component';

/* Styles */
import {
    DashboardContainer,
    DrawerAdjustment,
    DashboardContainerInside1,
    DashboardContainerInside2,
    DepartmentContainer,
    DataContainer,
} from './ipa-dashboard.styles';

/* Contexts */
import { authContext } from '../../../contexts/AuthContext';
import {
    getAllActiveUsersInCompany,
    getAllActiveUsersInDepartment,
    getOneUser,
} from '../../../api/ApiUser';
import permissions, { getSubmodulePermission } from '../../../utils/permission';
import { findIfSubmoduleIsActive } from '../../../api/ApiPemissions';
import { getSubordinatesUsersByDepartment } from '../../../api/ApiDepartment';

export default function IpaDashboardPage() {
    const { auth } = useContext(authContext);
    const [selectedUser, setSelectedUser] = useState({
        userId: auth.data.user.userId,
        name: auth.data.user.name,
        schooling: auth.data.user.schooling,
        allocation: {
            departmentId: auth.data.user.departmentId,
            departmentName: auth.data.user.departmentName,
            role: auth.data.user.role,
            actingCity: auth.data.user.actingCity,
        },
    });
    const [users, setUsers] = useState([]);
    const [submodules, setSubmodules] = useState({
        dashboard: [],
    });



    useEffect(() => {
        const callApiFindOneUser = async () => {
            const response = await getOneUser(
                auth, 
                auth.data.user.userId, 
                auth.data.user.companyId);
            const body = await response.json();
        
            if (response.status !== 200) throw Error(body.message);
        
            setSelectedUser(body.payload[0]);
        };
        try {
            callApiFindOneUser();
        } catch (err) {
            console.log(err);
        }
    }, [auth]);

    useEffect(() => {
        const callFindIfSubmoduleIsActive = async () => {
            const response = await findIfSubmoduleIsActive(
                auth,
                auth.data.user.permissionRoleId,
                permissions.IPA_DASHBOARD
            );
            const { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            setSubmodules({
                dashboard: payload,
            });
        };

        try {
            callFindIfSubmoduleIsActive();
        } catch (err) {
            console.log(err);
        }
    }, [auth]);

    useEffect(() => {
        const callApiFindAllUsersInCompany = async () => {
            const response = await getAllActiveUsersInCompany(auth, null);

            const { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            setUsers(payload);
        };

        const callApiFindAllUsersSubordinatesInDepartment = async () => {
            const response = await getSubordinatesUsersByDepartment(
                auth,
                auth.data.user.departmentId,
                null
            );

            const { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            setUsers(payload);
        };

        try {
            if (
                getSubmodulePermission(
                    submodules.dashboard,
                    permissions.EMPLOYEES_VISUALIZATION_FILTER
                )
            )
                callApiFindAllUsersInCompany();
            else if (
                getSubmodulePermission(
                    submodules.dashboard,
                    permissions.EMPLOYEES_VISUALIZATION_FILTER_DEPARTMENT
                )
            )
                callApiFindAllUsersSubordinatesInDepartment();
        } catch (error) {
            console.log(error);
        }
    }, [submodules]);

    return (
        <>
            <Header />
            <DrawerAdjustment>
                <DashboardContainer>
                    <DashboardContainerInside1>
                        <UserIntro
                            users={users}
                            selectedUser={selectedUser}
                            setSelectedUser={setSelectedUser}
                            submodules={submodules}
                        />
                        <UserAbout selectedUser={selectedUser} />
                    </DashboardContainerInside1>
                    <DashboardContainerInside2>
                        <DepartmentContainer>
                            {' '}
                            <UserDepartment selectedUser={selectedUser} />{' '}
                        </DepartmentContainer>
                        <DataContainer>
                            {' '}
                            <DataTab selectedUser={selectedUser} />{' '}
                        </DataContainer>
                    </DashboardContainerInside2>
                </DashboardContainer>
            </DrawerAdjustment>
        </>
    );
}
