/* Generic table to show textual infos, like "users management" tab */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/* Styles */
import { WarningTitle } from './table-to-show-data.styles';

/* Material UI */
import { createTheme, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import MyTheme from '../../MyTheme';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { lighten } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox"></TableCell>
                {props.headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <Typography style={{ fontWeight: 'bold' }}>
                                {headCell.label}
                            </Typography>
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { title } = props;

    return (
        <Toolbar>
            <Typography className={classes.title} variant="h6" id="tableTitle">
                {title.firstText}
                <WarningTitle>{title.warningText}</WarningTitle>
            </Typography>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    title: PropTypes.object,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 350,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

createTheme({
    typography: {
        fontSize: 20,
    },
});

export default function EnhancedTable(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [rows, setRows] = React.useState([]);
    const [headCells, setHeadCells] = React.useState([]);
    const [title, setTitle] = React.useState({
        firstText: '',
        warningText: '',
    });

    useEffect(() => {
        setRows(props.rows);
        setHeadCells(props.headCells);
        setTitle(props.title);
    }, [props.rows, props.headCells, props.title]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        setPage(0);
    }, [props.resetPage]);

    // const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <MuiThemeProvider theme={MyTheme}>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <EnhancedTableToolbar title={title} />
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size="medium"
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                                headCells={headCells}
                            />
                            <TableBody>
                                {stableSort(rows, getComparator(order, orderBy))
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                        const isItemSelected = props?.checkbox ? 
                                        props?.isSelected(row)
                                        : false;
                                        ;
                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) =>
                                                    props?.checkbox || props?.edit?.modal || props?.assignment?.modal ? props?.handleClick(event, row) : handleClick(event, row.name)
                                                }
                                                aria-checked={isItemSelected}
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                                selected={isItemSelected}
                                            >
                                                <TableCell padding="checkbox"></TableCell>
                                                {Object.keys(row).map(
                                                    (key, index) =>
                                                        key === 'dream' || key === 'purpose' ? ( 
                                                            <TableCell
                                                                style={{
                                                                    backgroundColor:
                                                                        row[
                                                                            'active'
                                                                        ] ===
                                                                        'Inativo'
                                                                            ? '#eeeeee'
                                                                            : null,
                                                                    maxWidth: 200,
                                                                    textOverflow: 'ellipsis',
                                                                    overflowX: 'scroll',
                                                                    whiteSpace: 'nowrap',
                                                                    scrollbarWidth: 'thin',
                                                                }}
                                                                key={index}
                                                                align="left"
                                                            >
                                                                <Typography>
                                                                    {key ===
                                                                    'percentege'
                                                                        ? row[
                                                                              key
                                                                          ].toFixed(
                                                                              1
                                                                          ) +
                                                                          '%'
                                                                        : row[
                                                                              key
                                                                          ]}
                                                                </Typography>
                                                            </TableCell>
                                                        ) : key !== 'action' ? ( //table cell without an action that link to other url
                                                            <TableCell
                                                                style={{
                                                                    backgroundColor:
                                                                        row[
                                                                            'active'
                                                                        ] ===
                                                                        'Inativo'
                                                                            ? '#eeeeee'
                                                                            : null
                                                                }}
                                                                key={index}
                                                                align="left"
                                                            >
                                                                <Typography>
                                                                    {key ===
                                                                    'percentege'
                                                                        ? row[
                                                                              key
                                                                          ].toFixed(
                                                                              1
                                                                          ) +
                                                                          '%'
                                                                        : row[
                                                                              key
                                                                          ]}
                                                                </Typography>
                                                            </TableCell>
                                                        ):(
                                                            <TableCell
                                                                style={{
                                                                    backgroundColor:
                                                                        row[
                                                                            'active'
                                                                        ] ===
                                                                        'Inativo'
                                                                            ? '#eeeeee'
                                                                            : null,
                                                                }}
                                                                key={index}
                                                                align="right"
                                                            >

                                                            {props
                                                                .assignment && (
                                                            
                                                                props.assignment.modal ? (
                                                                    <Tooltip
                                                                        title={
                                                                            props
                                                                            .assignment
                                                                            .buttonLabel
                                                                        }
                                                                    >
                                                                        <AssignmentIcon 
                                                                            style={{
                                                                                fill: '#0000008a',  
                                                                            }}
                                                                            onClick={props.assignment.modalHandle}
                                                                        />
                                                                    </Tooltip>
                                                                ) : (
                                                                    <Tooltip
                                                                        title={
                                                                            props
                                                                                .assignment
                                                                                .buttonLabel
                                                                        }
                                                                    >
                                                                        <Link
                                                                            to={`${
                                                                                props
                                                                                    .assignment
                                                                                    .start
                                                                            }${
                                                                                row[
                                                                                    props
                                                                                        .assignment
                                                                                        .firstInformation
                                                                                ]
                                                                            }${
                                                                                props
                                                                                    .assignment
                                                                                    .end
                                                                            }`}
                                                                        >
                                                                            <AssignmentIcon
                                                                                style={{
                                                                                    fill: '#0000008a',
                                                                                }}
                                                                            />
                                                                        </Link>
                                                                    </Tooltip>
                                                                )
                                                            )}

                                                            {props
                                                                .edit && ( //table cell with option to edit
                                                            
                                                                props.edit.modal ? (
                                                                    <Tooltip
                                                                        title={
                                                                            props
                                                                            .edit
                                                                            .buttonLabel
                                                                        }
                                                                    >
                                                                        <EditIcon 
                                                                            style={{
                                                                                fill: '#0000008a',  
                                                                            }}
                                                                            onClick={props.edit.modalHandle}
                                                                        />
                                                                    </Tooltip>
                                                                ) : (
                                                                    <Tooltip
                                                                        title={
                                                                            props
                                                                                .edit
                                                                                .buttonLabel
                                                                        }
                                                                    >
                                                                        <Link
                                                                            to={`${
                                                                                props
                                                                                    .edit
                                                                                    .start
                                                                            }${
                                                                                row[
                                                                                    props
                                                                                        .edit
                                                                                        .firstInformation
                                                                                ]
                                                                            }${
                                                                                props
                                                                                    .edit
                                                                                    .end
                                                                            }`}
                                                                        >
                                                                            <EditIcon
                                                                                style={{
                                                                                    fill: '#0000008a',
                                                                                }}
                                                                            />
                                                                        </Link>
                                                                    </Tooltip>
                                                                )
                                                            )}

                                                            {props.add && (
                                                                    <Tooltip
                                                                        title={
                                                                            props
                                                                                .add
                                                                                .buttonLabel
                                                                        }
                                                                    >
                                                                        <AddCircleIcon
                                                                            style={{
                                                                                fill: '#76CB8E',
                                                                            }}
                                                                            onClick={() =>
                                                                                props
                                                                                .actionAdd(
                                                                                    row
                                                                                )
                                                                            }
                                                                        />
                                                                    </Tooltip>
                                                            )}

                                                            {props.delete && (
                                                                <Tooltip
                                                                    title={
                                                                        props
                                                                            .delete
                                                                            .buttonLabel
                                                                        }
                                                                    >
                                                                        <DeleteIcon
                                                                            style={{
                                                                                fill: '#f16669',
                                                                                marginLeft: 5,
                                                                            }}
                                                                            onClick={() =>
                                                                                props
                                                                                .actionDelete(
                                                                                    row
                                                                                )
                                                                            }
                                                                        />
                                                                </Tooltip>
                                                            )}

                                                            {props.checkbox &&
                                                            (
                                                                <Checkbox
                                                                    checked={isItemSelected}
                                                                />
                                                            )}

                                                            </TableCell>
                                                        )
                                                    )
                                                }
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{ height: 53 * emptyRows }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
        </MuiThemeProvider>
    );
}
