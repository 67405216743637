/* Users */

export function ClearDataFromApiUserIF(aux, multiply) {
    let averagePerMonth = [];
    let auxDate;
    let empty;
    let userAverage = [null, null, null, null, null, null, null, null, null, null, null, null];

    empty = aux.length === 0;

    if (empty) { //no data in the year
        for (let i = 0; i < 12; i++) {
            if (empty) {
                averagePerMonth[i] = {
                    environment: null,
                    financial: null,
                    health: null,
                    motivational: null,
                    personal: null,
                    professional: null,
                };
            }
        }
        return {averagePerMonth, userAverage}
    }

    for (let i = 1; i <= 12; i++) {
        averagePerMonth[i - 1] = {
            environment: null,
            financial: null,
            health: null,
            motivational: null,
            personal: null,
            professional: null,
        };
        for (let j = 0; j < aux.length; j++) {
            auxDate = aux[j].date.split("-");
            auxDate = auxDate[1];

            if (parseInt(auxDate) === (i)) {
                averagePerMonth[i - 1] = aux[j];
            }
        }
    }
    for (let i = 0; i < 12; i++) { // to each month that is the "i" we sum all the fields and divide for the total of elements to get the average per month
        for (let key in averagePerMonth[i]) {
            if ((averagePerMonth[i][key] !== null) && averagePerMonth[i].hasOwnProperty(key) && key !== "userId" && key !== "monthName" && key !== "date" && key !== "createdAt") {
                if (multiply) { //dashboard need the values multiplied by 33.33
                    averagePerMonth[i][key] = Math.round(parseInt(averagePerMonth[i][key]) * 33.33);
                    userAverage[i] += averagePerMonth[i][key];
                } else { // history need the value without the multiplication
                    userAverage[i] += averagePerMonth[i][key] * 33.33;
                }
            }
        }

        if (userAverage[i] !== null) {
            userAverage[i] = userAverage[i] / 6;
            userAverage[i] = Math.round(userAverage[i])
        }
    }

    return {averagePerMonth, userAverage}
}


/* Company */

export function ClearDataFromApiCompanyIF(averagePerMonth) {
    let generalAverage = {};
    let size = 0, key;
    let average = 0;
    for (key in averagePerMonth) {
        if (averagePerMonth.hasOwnProperty(key)) {
            size++;
            for (let keyIFField in averagePerMonth[key]) {
                if (averagePerMonth[key].hasOwnProperty(keyIFField) && (averagePerMonth[key][keyIFField] !== null)) {
                    average += averagePerMonth[key][keyIFField];
                    averagePerMonth[key][keyIFField] = (parseFloat(averagePerMonth[key][keyIFField]) * 33.33).toFixed(0);
                }
            }
            if (generalAverage[key] !== null) {
                generalAverage[key] = (average / 6) * 33.33;
                generalAverage[key] = parseInt(generalAverage[key].toFixed(0));
            }
            average = 0;
        }

    }

    generalAverage = getAverageInMonth(generalAverage, null);

    averagePerMonth = getAverageInMonth(averagePerMonth, null);

    for (let i = 0; i < 12; i++) {
        if (averagePerMonth[i] === null) {
            averagePerMonth[i] = {
                environment: null,
                financial: null,
                health: null,
                motivational: null,
                personal: null,
                professional: null
            }

        }
    }

    for (let i = 0; i < 12; i++) {
        if (generalAverage[i] === 0) {
            generalAverage[i] = null;
        }
    }

    average = 0;
    for (key in generalAverage) {
        if (generalAverage.hasOwnProperty(key)) {
            average += generalAverage[key];
        }
    }
    average = average / size;

    return {averagePerMonth, generalAverage, average};
}

/* Department */

export function ClearDataFromApiDepartmentIF(averagePerMonth) {
    let size = 0, key;
    let generalAverage = {};
    let average = 0;

    for (key in averagePerMonth) {
        if (averagePerMonth.hasOwnProperty(key)) {
            for (let keyIFField in averagePerMonth[key]) {
                if (averagePerMonth[key].hasOwnProperty(keyIFField) && (averagePerMonth[key][keyIFField] !== null)) {
                    size++;
                    average += averagePerMonth[key][keyIFField];
                    averagePerMonth[key][keyIFField] = (parseFloat(averagePerMonth[key][keyIFField]) * 33.33).toFixed(0);
                }
            }
            if (generalAverage[key] !== null) {
                generalAverage[key] = (average / 6) * 33.33;
                generalAverage[key].toFixed(0);
            }
            average = 0;
        }

    }

    generalAverage = getAverageInMonth(generalAverage, null);

    averagePerMonth = getAverageInMonth(averagePerMonth, null);

    for (let i = 0; i < 12; i++) {
        if (averagePerMonth[i] === null) {
            averagePerMonth[i] = {
                environment: null,
                financial: null,
                health: null,
                motivational: null,
                personal: null,
                professional: null
            }

        }
    }

    return {generalAverage, averagePerMonth};
}

/* Generic */

export function getAverageInMonth(data, value) {
    let newData = [];

    if (data) {
        for (let i = 0; i < 12; i++) {
            switch (i) {
                case 0:
                    if (data.hasOwnProperty("January"))
                        newData[i] = data["January"];
                    else
                        newData[i] = value;
                    break;
                case 1:
                    if (data.hasOwnProperty("February"))
                        newData[i] = data["February"];
                    else
                        newData[i] = value;
                    break;
                case 2:
                    if (data.hasOwnProperty("March"))
                        newData[i] = data["March"];
                    else
                        newData[i] = value;
                    break;
                case 3:
                    if (data.hasOwnProperty("April"))
                        newData[i] = data["April"];
                    else
                        newData[i] = value;
                    break;
                case 4:
                    if (data.hasOwnProperty("May"))
                        newData[i] = data["May"];
                    else
                        newData[i] = value;
                    break;
                case 5:
                    if (data.hasOwnProperty("June"))
                        newData[i] = data["June"];
                    else
                        newData[i] = value;
                    break;
                case 6:
                    if (data.hasOwnProperty("July"))
                        newData[i] = data["July"];
                    else
                        newData[i] = value;
                    break;
                case 7:
                    if (data.hasOwnProperty("August"))
                        newData[i] = data["August"];
                    else
                        newData[i] = value;
                    break;
                case 8:
                    if (data.hasOwnProperty("September"))
                        newData[i] = data["September"];
                    else
                        newData[i] = value;
                    break;
                case 9:
                    if (data.hasOwnProperty("October"))
                        newData[i] = data["October"];
                    else
                        newData[i] = value;
                    break;
                case 10:
                    if (data.hasOwnProperty("November"))
                        newData[i] = data["November"];
                    else
                        newData[i] = value;
                    break;
                case 11:
                    if (data.hasOwnProperty("December"))
                        newData[i] = data["December"];
                    else
                        newData[i] = value;
                    break;
            }
        }
    } else {
        for (let i = 0; i < 12; i++) {
            newData[i] = value;
        }
    }

    return newData;
}

export function cleanDataOfAttributeCall(result, radioValue, year) {
    let auxName = [];
    let index;
    let newResult = [];
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let monthsPT = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
    let average = [];
    let tempCount = 0;
    let monthsQt = 0;

    result = result.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)); //sorting alphabetically by name

    for (let i = 0; i < result.length; i++) {
        auxName[i] = {
            name: result[i].name,
            active: result[i].active
        }
        tempCount = 0;
        for (let key in result[i].values[year.toString()]) {
            if (result[i].values[year.toString()].hasOwnProperty(key)) {
                index = months.indexOf(key);
                newResult[index] = {
                    name: monthsPT[index],
                    value: result[i].values[year.toString()][key]
                };
                tempCount = tempCount + result[i].values[year.toString()][key] * 33.33;
            }

        }
        monthsQt = Object.keys(result[i].values[year.toString()]).length;
        average[i] = {name: result[i].name, value: Math.round((tempCount / (monthsQt * 100)) * 100)};

        result[i].values[year.toString()] = newResult;
        newResult = [];

    }

    for (let i = 0; i < result.length; i++) {
        for (let j = 0; j < 12; j++) {
            if (result[i].values[year.toString()][j] === undefined) {
                result[i].values[year.toString()][j] = {
                    name: monthsPT[j],
                    value: null
                };
            }

        }

    }

    const names = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez',];
    newResult = [];
    for (let i = 0; i < 12; i++) {
        newResult[i] = {name: `${names[i]}`, data: []};
        for (let j = 0; j < result.length; j++) {
            if (radioValue === "department") {
                newResult[i].data[j] = {
                    name: result[j].name,
                    active: result[j].active,
                    value: result[j].values[year.toString()][i].value === 0 ? result[j].values[year.toString()][i].value : result[j].values[year.toString()][i].value ? parseFloat((result[j].values[year.toString()][i].value * 33.33).toFixed(0)) : null
                };
            } else if (radioValue === "employee") {
                newResult[i].data[j] = {
                    name: result[j].name,
                    active: result[j].active,
                    value: result[j].values[year.toString()][i].value
                };
            }
        }

    }

    newResult[12] = {name: `Média`, data: average}; //added the average calculated above to the last column of the table

    return {newResult, auxName};
}