import styled from 'styled-components/macro';

export const Department = styled.div`
    display: grid;
    grid-template-rows: 20% 80%;
    border-radius: 12px;
    background: #f6f4f4;
    padding: 12px;
    margin: 20px;
    @media screen and (max-width: 1530px) {
        height: 300px;
        grid-template-rows: 60% 40%;
    }
    @media screen and (max-width: 760px) {
        height: 600px;
        grid-template-rows: 30% 70%;
    }
`;

export const TitleInfo = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas:
        'titleDepartment  departmentIcon'
        'departmentName departmentIcon';
    padding: 12px;
`;

export const TitleInfoDepartment = styled.div`
    display: grid;
    grid-area: titleDepartment;
    font-family: Abril Fatface;
    font-size: 46px;
    font-weight: 700;
`;

export const DepartmentName = styled.div`
    display: grid;
    grid-area: departmentName;
    transform: translateY(-25px);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 100;
`;

export const DepartmentIcon = styled.img`
    display: grid;
    grid-area: departmentIcon;
    transform: translateY(5px);
    justify-self: center;
    background-color: #fff;
    height: 85px;
    width: 85px;
    border-radius: 50%;
    border: 1px solid #ff7517;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
`;

export const Alignment = styled.div`
    padding: 12px;
    margin-top: -50px;
    overflow-y: auto;
    max-height: 665px;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        background: #ff7517;
        border-radius: 12px;
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        background: #d9d9d9;
        border-radius: 12px;
    }
    &::-webkit-scrollbar-thumb {
        background: #ff7517;
        border-radius: 12px;
    }
    @media screen and (max-width: 1530px) {
        display: grid;
        grid-template-areas:
            'userAlignments'
            'otherDepartment';
    }
`;

export const ContainerTextBar = styled.div`
    display: grid;
    grid-template-columns: 5% 95%;
    align-content: start;
    margin: 30px 0;
`;

export const Bar = styled.div`
    width: 6px;
    height: 50px;
    border-radius: 12px;
`;

export const TextBar = styled.div``;

export const Name = styled.div`
    font-family: Abril Fatface;
    font-size: 18px;
    font-weight: 700;
`;

export const FortressName = styled.div`
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 100;
`;

export const UserAlignments = styled.div`
    display: grid;
    grid-area: userAlignments;
    @media screen and (max-width: 1530px) {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
`;

export const OtherDepartments = styled.div`
    display: grid;
    grid-area: otherDepartment;
    grid-template-areas:
        'departmentTitle'
        'departmentAlignment';
`;

export const OtherDepartmentTitle = styled.div`
    display: grid;
    font-family: Abril Fatface;
    font-size: 18px;
    font-weight: 700;
    margin: 50px 0;
    @media screen and (max-width: 1530px) {
        grid-area: departmentTitle;
    }
`;

export const DepartmentAlignment = styled.div`
    display: grid;
    @media screen and (max-width: 1530px) {
        grid-area: departmentAlignment;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
`;
