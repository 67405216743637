import styled from 'styled-components';

export const TableContainer = styled.div`
    height: 70%;
    background-color: white;
    border: 1px solid #cfd8dc;
    padding: 20px;
    margin-bottom: 80px;
   @media screen and (max-width: 650px) {
        margin-bottom: 20px;
    }
    @media screen and (max-height: 950px) {
        height: 75%;
    }
   @media screen and (max-height: 830px) {
        height: 85%;
        padding: 0;
    }
`;

export const Spacer = styled.div`
    height: 12vh;
    @media screen and (max-height: 600px) {
        height: 70px;
    }
`;

export const DrawerAdjustment = styled.div`
    padding-left: 72px;
    @media screen and (max-width: 800px) {
        padding-left: 0;
    }
`;
