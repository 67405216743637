import styled from 'styled-components';

export const StyledInput = styled.input`
    border: none;
    border: 1px solid #8492a6;
    font-size: 16px;
    height: 40px;
    max-width: 600px;
    margin: 5px;
    text-align: center;
    color: #757575;
    border-radius: 10px;
    font-family: 'Arial', sans-serif;
    &:focus {
        border: 2px solid #5c9089;
        outline: none;
    }
`;

export const StyledContainerButtonXlsx = styled.div`
    padding-left: 15px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 750px) {
        flex-direction: column;
        align-items: center;
        padding: 0;
    }
`;

export const ButtonStyles = styled.button`
    background-color: rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0.87);
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    padding: 5px 15px;
    font-weight: 600;
    line-height: 2.75;
    cursor: pointer;
`;

export const Filters = styled.div`
    margin: 10px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 750px) {
        flex-direction: column;
        align-items: center;
    }
`;
