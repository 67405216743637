import styled from 'styled-components/macro';

export const GraphicContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border: 1px solid #cfd8dc;
    padding: 20px;
    padding-bottom: 110px;
    @media screen and (max-width: 600px) , screen and (max-height: 750px) {
        width: 100%;
        background-color: #fff;
        margin-bottom: 30px;
        padding-bottom: 150px;
    }

`;

export const Spacer = styled.div`
    height: 12vh;
    @media screen and (max-height: 600px) {
        height: 70px;
    }
`;

export const ContainerTitle = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (max-width: 620px){
        width: 0;
        height: 0;
        visibility: hidden; 
    }
`;

export const StyledContainerTitle = styled.div`
    display: flex;
    flex-direction: row;
    width: 40%;
    @media screen and (max-width: 620px){
        width: 0;
        height: 0;
        visibility: hidden; 
    }
`;


export const Title = styled.h2`
    margin-bottom: 32px;
    @media screen and (max-width: 620px) {
        width: 0;
        height: 0;
        visibility: hidden; 
    }
`;

export const Stripe = styled.div`
    margin-top: 13px;
    margin-right: 13px;
    border-left:5px solid #4f4f4f;
    height: 65px;
    position: relative;
    @media screen and (max-width: 620px) {
        width: 0;
        height: 0;
        visibility: hidden; 
    }
`;

export const AlignContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 95%;
    @media screen and (max-width: 630px) , screen and (max-height: 600px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
`;

export const StyledFilter = styled.div`
    padding-bottom: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    @media screen and (max-width: 500px) {
        align-items: center;
        padding-bottom: 5px;
        flex-direction: column;
    }
`;

export const Filters = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: ${({around}) => around ? 'space-around' : 'center'};
    @media screen and (max-width: 800px) {
        margin-top: 15px;
        justify-content: center;
    }
`;

export const DrawerAdjustment = styled.div`
    padding-bottom: 100px;
    padding-left: 72px;
    @media screen and (max-width: 800px) {
        padding-bottom: 50px;
        padding-left: 0;
    }
`;

export const SpacerFilter = styled.div`
    width: 150px;
    visibility: hidden;
    @media screen and (max-width: 800px) {
        width: 0;
        height: 0;
    }
`;

export const GlobalFilterContainer = styled.div`
    padding-top: 12px;
`;

export const MainComponents = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 90%;
    padding-bottom: 30px;
    @media screen and (max-width: 650px) {
        justify-content: center;
    }
`;