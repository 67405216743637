import React, { useContext, useEffect, useState } from 'react';

/* Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

/* Styles */
import {
    StyledFilter,
    AlignContainer,
    Filters,
} from './ipa-graphs-general.styles';

/* Permissions */
import { getSubmodulePermission } from '../../../../../../utils/permission';

/* auth */
import { authContext } from '../../../../../../contexts/AuthContext';

/* api */
import {
    getAllActiveUsersInCompany,
    getAllActiveUsersInDepartment
} from '../../../../../../api/ApiUser';
import {
    getAllDepartments,
    getOneDepartment,
    getSubordinatesDepartments,
    getSubordinatesUsersByDepartment,
} from '../../../../../../api/ApiDepartment';

/* utils*/
import { yearsUntilNow } from '../../../../../../utils/initialData';

/* Graph */
import GeneralGraphDataCall from './general-graphs-dataCall/general-graphs-dataCall';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 140,
    },
    formControlName: {
        margin: theme.spacing(1),
        minWidth: 250,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    container: {
        '@media (max-width: 600px)': {
            padding: 0,
        },
    },
}));

const GeneralGraphFilters = (props) => {
    const classes = useStyles();
    const { auth } = useContext(authContext);

    const [actualUser, setActualUser] = useState(auth.data.user);
    const [actualDepartment, setActualDepartment] = useState([]);
    const [actualYear, setActualYear] = useState(
        yearsUntilNow()[yearsUntilNow().length - 1]
    );
    const [actualOtherYear, setActualOtherYear] = useState('');

    const [userOptions, setUserOptions] = useState([]);
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [departmentOption, setDepartmentOption] = useState([]);

    const [jumpFirstRender, setJumpFirstRender] = useState(false);

    useEffect(() => {
        //reset actual values if checkbox turned off

        if (!props.checksStatus[0].activated) setActualUser({});
        if (!props.checksStatus[1].activated) {
            setActualDepartment([]);
            props.setSelectedDeparmentCounter(0);
        }
        if (!props.checksStatus[3].activated) setActualOtherYear('');
    }, [
        props.checksStatus[0].activated,
        props.checksStatus[1].activated,
        props.checksStatus[2].activated,
        props.checksStatus[3].activated,
    ]);

    useEffect(() => {
        const callApiFindAllDepartment = async () => {
            const response = await getAllDepartments(
                auth,
                auth.data.user.companyId
            );

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);
            setDepartmentOptions(payload);
            setJumpFirstRender(true);
        };

        const callApiFindSubordinatesDepartments = async () => {
            const response = await getSubordinatesDepartments(
                auth,
                auth.data.user.departmentId,
                auth.data.user.companyId
            );

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);
            setDepartmentOptions(payload);
            setJumpFirstRender(true);
        }

        try {
            if(props.submodules && getSubmodulePermission(props.submodules.graphs, props.permissions.SUBORDINATES_DEPARTMENTS_VIZUALIZATION_FILTER)) {
                callApiFindSubordinatesDepartments();
            } else if (getSubmodulePermission(props.submodules.graphs, props.permissions.DEPARTMENTS_VISUALIZATION_FILTER)) {
                callApiFindAllDepartment();
            }
        } catch (err) {
            console.log(err);
        }
    }, [auth, props.submodules, props.globalCompany]);

    useEffect(() => {
        const callGetOneDepartment = async (company, departmentId) => {
            const response = await getOneDepartment(
                auth,
                departmentId,
                company
            );
            const body = await response.json();
            return body.payload;
        };

        callGetOneDepartment(
            props.globalCompany.companyId,
            auth.data.user.departmentId
        ).then((res) => {
            setDepartmentOption(res);
            setJumpFirstRender(true);
        });
    }, [props.globalCompany]);

    useEffect(() => {
        //user Filter
        const callGetAllActiveUsersInCompany = async (company) => {
            const response = await getAllActiveUsersInCompany(auth, company);
            const body = await response.json();
            return body.payload;
        };
        const callGetAllActiveUsersInDepartment = async (department, company) => {
            const response = await getAllActiveUsersInDepartment(
                auth,
                department.departmentId,
                company
            );
            const body = await response.json();
            return body.payload;
        };

        const callApiFindAllUsersSubordinatesInDepartment = async () => {
            const response = await getSubordinatesUsersByDepartment(
                auth,
                auth.data.user.departmentId,
                null
            );

            const { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            return payload;
        };

        if (getSubmodulePermission(props.submodules.graphs, props.permissions.SUBORDINATES_DEPARTMENTS_VIZUALIZATION_FILTER)) {
            if (
                props.checksStatus[1].activated &&
                actualDepartment.length !== 0
            ) {
                callGetAllActiveUsersInDepartment(
                    actualDepartment[0],
                    props.globalCompany.companyId
                ).then((res) => {
                    setUserOptions(res);
                    setJumpFirstRender(true);
                });
            } else {
                callApiFindAllUsersSubordinatesInDepartment().then((res) => {
                    setUserOptions(res);
                    setJumpFirstRender(true);
                });
            }
        } else {
            if (
                props.checksStatus[1].activated &&
                actualDepartment.length !== 0
            ) {
                callGetAllActiveUsersInDepartment(
                    actualDepartment[0],
                    props.globalCompany.companyId
                ).then((res) => {
                    setUserOptions(res);
                    setJumpFirstRender(true);
                });
            } else {
                callGetAllActiveUsersInCompany(props.globalCompany.companyId).then(
                    (res) => {
                        setUserOptions(res);
                        setJumpFirstRender(true);
                    }
                );
            }
        }
    }, [props.globalCompany, actualDepartment]);

    return (
        <>
            {jumpFirstRender ? (
                <>
                    <AlignContainer>
                        <StyledFilter>
                            <Filters>
                                {getSubmodulePermission(
                                    props.submodules.graphs,
                                    props.permissions
                                        .EMPLOYEES_VISUALIZATION_FILTER
                                ) && props.checksStatus[0].activated ? (
                                    <Autocomplete
                                        disableClearable
                                        key="autocompleteGeneralGraph0"
                                        className={classes.formControlName}
                                        //defaultValue={userOptions.find((user)=>user.userId === auth.data.user.userId)}
                                        value={actualUser}
                                        options={userOptions}
                                        getOptionLabel={(option) => option.name}
                                        getOptionSelected={(option) =>
                                            option.name
                                        }
                                        onChange={(e, newValue) =>
                                            setActualUser(newValue)
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Colaborador"
                                                variant="outlined"
                                            />
                                        )}
                                    ></Autocomplete>
                                ) : props.checksStatus[0].activated &&
                                  auth.data.user.permissionRoleId === 10 ? (
                                    <Autocomplete
                                        disableClearable
                                        key="autocompleteGeneralGraph0"
                                        className={classes.formControlName}
                                        //defaultValue={userOptions.find((user)=>user.userId === auth.data.user.userId)}
                                        value={actualUser}
                                        options={userOptions}
                                        getOptionLabel={(option) => option.name}
                                        getOptionSelected={(option) =>
                                            option.name
                                        }
                                        onChange={(e, newValue) =>
                                            setActualUser(newValue)
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Colaborador"
                                                variant="outlined"
                                            />
                                        )}
                                    ></Autocomplete>
                                ) : (
                                    <div className={classes.formControl} />
                                )}
                                {props.checksStatus[1].activated ? (
                                    <Autocomplete
                                        multiple
                                        key="autocompleteGeneralGraph1"
                                        disableClearable
                                        className={classes.formControl}
                                        options={
                                            actualDepartment.length === 2 ||
                                            (props.checksSelectedCounter ===
                                                2 &&
                                                actualDepartment.length === 1)
                                                ? actualDepartment
                                                : getSubmodulePermission(
                                                    props.submodules.graphs,
                                                    props.permissions
                                                        .DEPARTMENTS_VISUALIZATION_FILTER ||
                                                  getSubmodulePermission(
                                                    props.submodules.graphs, 
                                                    props.permissions.SUBORDINATES_DEPARTMENTS_VIZUALIZATION_FILTER
                                                  )
                                                ) ? departmentOptions : departmentOption
                                        }
                                        getOptionLabel={(option) => option.name}
                                        filterSelectedOptions
                                        onChange={(e, newValue) => {
                                            newValue.length <
                                            actualDepartment.length
                                                ? props.setSelectedDeparmentCounter(
                                                      props.selectedDepartmentCounter -
                                                          1
                                                  )
                                                : props.setSelectedDeparmentCounter(
                                                      props.selectedDepartmentCounter +
                                                          1
                                                  );
                                            setActualDepartment(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Setor"
                                                variant="outlined"
                                            />
                                        )}
                                    ></Autocomplete>
                                ) : (
                                    <div className={classes.formControl} />
                                )}

                                <Autocomplete
                                    autoSelect
                                    key="autocompleteGeneralGraph2"
                                    disableClearable
                                    defaultValue={
                                        yearsUntilNow()[
                                            yearsUntilNow().length - 1
                                        ]
                                    }
                                    className={classes.formControl}
                                    options={yearsUntilNow()}
                                    getOptionLabel={(option) => option}
                                    getOptionSelected={(option) => option}
                                    onChange={(e, newValue) =>
                                        setActualYear(newValue)
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Ano"
                                            variant="outlined"
                                        />
                                    )}
                                ></Autocomplete>
                                {props.checksStatus[3].activated ? (
                                    <Autocomplete
                                        autoSelect
                                        key="autocompleteGeneralGraph3"
                                        disableClearable
                                        className={classes.formControl}
                                        options={yearsUntilNow()}
                                        getOptionLabel={(option) => option}
                                        getOptionSelected={(option) => option}
                                        onChange={(e, newValue) =>
                                            setActualOtherYear(newValue)
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Outros Anos"
                                                variant="outlined"
                                            />
                                        )}
                                    ></Autocomplete>
                                ) : (
                                    <div className={classes.formControl} />
                                )}
                            </Filters>
                        </StyledFilter>
                    </AlignContainer>

                    <GeneralGraphDataCall
                        actualUser={actualUser}
                        actualDepartment={actualDepartment}
                        actualYear={actualYear}
                        actualOtherYear={actualOtherYear}
                        checksStatus={props.checksStatus}
                        globalCompany={props.globalCompany}
                        setSelectedDeparmentCounter={
                            props.setSelectedDeparmentCounter
                        }
                    />
                </>
            ) : null}
        </>
    );
};

export default GeneralGraphFilters;