/* Generic table to the user insert the data and send to de database, like "send alignment" tab */
import React from 'react';

/* Material UI */
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

/* Components */
import CustomButton from "../../components/custom-button/custom-button.component";

/* Style */
import {
    AlignContainer,
    StyledFilter,
    StyledButton,
    Legend,
    StyledSpan
} from './table-to-send-data.styles';

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#eeeeee',
        color: theme.palette.common.white,
        fontSize: '16px',
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 8,
        paddingRight: 8,
    },
    body: {
        padding: 8,
        fontSize: 16,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#eeeeee',
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    formControl: {
        padding: 0,
        minWidth: 80
    },
    table: {
        minWidth: 200,
        width: '30vw',
        '@media (max-width: 1450px)': {
            width: '45vw',
        },
        '@media (max-width: 1000px)': {
            width: '60vw',
        },
        '@media (max-width: 600px)': {
            width: '83vw',
        }
    },
    colWidth: {
        // width: 'calc(100%/2)',
        color: 'black'
    },
}));


const numberValues = ['3', '2', '1', '-1', '-2', '-3'];

const numberValuesReverse = ['-3', '-2', '-1', '1', '2', '3'];

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #9E9E9E',
    },
}))(Tooltip);

export default function TableToSendData(props) {
    const classes = useStyles();

    return (
        <div>
            <AlignContainer>
                <StyledFilter>
                    <div>
                        <StyledSpan>Digite um dos números abaixo</StyledSpan>
                        <Legend>
                            {numberValuesReverse.map((value, index) => (
                                <div key={index} style={{
                                    border: '1px solid #424242',
                                    width: '40px',
                                    textAlign: 'center',
                                    backgroundColor: value === '3' ? '#38761D' :
                                        value === '2' ? '#3d9a15' :
                                            value === '1' ? '#00FF00' :
                                                value === '-1' ? '#FFFF00' :
                                                    value === '-2' ? '#FF7800' :
                                                        value === '-3' ? '#FF0000' :
                                                            null
                                }}>{value}
                                </div>
                            ))}
                        </Legend>
                    </div>
                </StyledFilter>
                <form onSubmit={props.handleSubmit}>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    {props.sendTasks ? <StyledTableCell className={classes.colWidth} style={{ backgroundColor: '#9e9e9e', width: "10px" }}
                                        align="center">{props.textData.taskId}</StyledTableCell> : null}
                                    <StyledTableCell className={classes.colWidth} style={{ backgroundColor: '#9e9e9e', borderStyle: "solid", borderTopWidth: "0px", borderLeftWidth: "1px", borderRightWidth: "1px", borderColor: "#e0e0e0" }}
                                        align="center">{props.textData.tableTitle}</StyledTableCell>
                                    <StyledTableCell className={classes.colWidth} style={{ backgroundColor: '#9e9e9e' }}
                                        align="center">{props.value} / {props.year}</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.rows.map((row, index) => (
                                    <StyledTableRow key={index}>
                                        {props.sendTasks ? <StyledTableCell component="th" scope="row" align="center" style={{ width: "10px", backgroundColor: '#cccccc' }}>
                                            {row.taskId}
                                        </StyledTableCell> : null}
                                        { row.description !== undefined && row.description !== "" ?
                                            <HtmlTooltip
                                                enterTouchDelay={1}
                                                title={
                                                    <React.Fragment>
                                                        {`Código: ${row.taskId}`}
                                                        <Typography color="inherit"> {row.description} </Typography>
                                                    </React.Fragment>
                                                }
                                            >
                                                <StyledTableCell component="th" scope="row" align={props.sendTasks ? "left" : "center"}>
                                                    {row.name}
                                                </StyledTableCell>
                                            </HtmlTooltip>
                                            :
                                            <StyledTableCell component="th" scope="row" align={props.sendTasks ? "left" : "center"}>
                                                {row.name}
                                            </StyledTableCell>
                                        }
                                        <StyledTableCell align="center">
                                            <FormControl
                                                className={classes.formControl}>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={row.value}
                                                    onChange={e => props.handleChange(e, row, index)}
                                                    label=""
                                                    style={{ backgroundColor: row.color, padding: 0 }}>
                                                    {numberValues.map((value, index) => {
                                                        return (
                                                            <MenuItem key={index} index={value}
                                                                value={value}>{value}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <StyledButton>
                        <CustomButton type='submit' addButton> {props.textData.textButton} </CustomButton>
                    </StyledButton>
                </form>
            </AlignContainer>
        </div>
    );
}
