/* Bar component used in dashboard */
import React from 'react';

/* Style */
import {CategoryBar, Dot, TextCategoryBar, StyledCard, StyledSpan} from './category-bar.styles';

/* Icons */
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {ReactComponent as Professional} from "../../assets/portfolio.svg";
import {ReactComponent as Financial} from "../../assets/money.svg";
import {ReactComponent as Environment} from "../../assets/team.svg";
import {ReactComponent as All} from "../../assets/spreadsheet.svg";
import {ReactComponent as Personal} from "../../assets/personal.svg";
import {ReactComponent as Health} from "../../assets/health.svg";
import {ReactComponent as Motivational} from "../../assets/motivational.svg";
import {ReactComponent as Tasks} from "../../assets/tasks.svg";
import {ReactComponent as Alignment} from "../../assets/goal.svg";

const IconElement = (props) => {
    return (
        <div>
            {props.id === 0 ? <All style={{width: 47, height: 47, fill: '#212121'}}/> :
                props.id === 1 ?
                    <Professional style={{width: 47, height: 47, fill: '#212121'}}/> :
                    props.id === 2 ?
                        <Personal style={{width: 52, height: 52, fill: '#212121'}}/> :
                        props.id === 3 ?
                            <Financial style={{width: 42, height: 42, fill: '#212121'}}/> :
                            props.id === 4 ?
                                <Motivational style={{width: 52, height: 52, fill: '#212121'}}/> :
                                props.id === 5 ?
                                    <Health style={{width: 47, height: 47, fill: '#212121'}}/> :
                                    props.id === 6 ?
                                        <Environment style={{width: 47, height: 47, fill: '#212121'}}/> :
                                        props.id === 7 ?
                                            <Tasks style={{width: 47, height: 47, fill: '#212121'}}/> :
                                            props.id === 8 ?
                                                <Alignment style={{width: 47, height: 47, fill: '#212121'}}/> :
                                                null
            }
        </div>
    );
};

const CategoryBarComponent = (props) => (
    <CategoryBar>
        <IconButton onClick={props.onClickPrevious}>
            <ArrowBackIcon/>
        </IconButton>

        <StyledCard color={props.categoryList[0].id}>
            <Dot color={props.getColor(props.employee, 3)}>
                <IconElement id={props.categoryList[0].id}/>
            </Dot>

            <TextCategoryBar>
                <StyledSpan> {props.name} </StyledSpan>
                <StyledSpan> {props.employee} / {isNaN(props.company) ? null : props.company}</StyledSpan>
            </TextCategoryBar>
        </StyledCard>

        <IconButton onClick={props.onClickNext}>
            <ArrowForwardIcon/>
        </IconButton>
    </CategoryBar>
);

export default CategoryBarComponent;
