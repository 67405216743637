import { logout } from './ApiUser';
import config from '../config';

// import History from './History';
export const apiUrl = config.apiUrl;

export function handleResponse(response) {
    return response.text().then((text) => {
        let data = undefined;
        try {
            data = text && JSON.parse(text);
        } catch (error) {
            logout();
        }
        if (!response.ok) {
            if (response.status === 404 || response.status === 500 || response.status === 401) {
                // auto logout if 404 response returned from api
                logout();
                // History.push('/login');
                window.location.reload(false); // Refresh page
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
