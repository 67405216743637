import styled from 'styled-components/macro';

export const About = styled.div`
    display: grid;
    grid-template-rows: 20% 80%;
    grid-template-areas:
        'upContainer'
        'bottomContainer';
    border-radius: 12px;
    background: #f6f4f4;
    padding: 12px;
    margin: 20px;
    max-height: 300px;
    @media screen and (max-width: 610px) {
        max-height: 510px;
    }
`;

export const UpContainer = styled.div`
    display: grid;
    grid-area: upContainer;
    grid-template-columns: 50% 50%;
`;

export const BottomContainer = styled.div`
    display: grid;
    grid-area: bottomContainer;
    grid-template-columns: 50% 50%;
    @media screen and (max-width: 610px) {
        grid-template-columns: 100%;
        grid-template-rows: 50% 50%;
    }
`;

export const TitleTextAbout = styled.div`
    font-family: Abril Fatface;
    font-size: 46px;
    display: grid;
    font-weight: 700;
    padding: 12px;
`;

export const PhotoIcon = styled.div`
    display: grid;
    grid-template-rows: 80% 20%;
    justify-content: end;
    margin-right: 40px;
    padding: 12px;
    align-items: center;
`;

export const CompanyYears = styled.div`
    display: flex;
`;

export const NumberTimeCompany = styled.div`
    display: grid;
    font-family: Abril Fatface;
    font-size: 25px;
    font-weight: 700;
    text-shadow: -0.7px -0.7px 0 #fff, 0.7px -0.7px 0 #fff, -0.7px 0.7px 0 #fff, 0.7px 0.7px 0 #fff;
    color: #ff7517;
    margin-right: 3px;
    transform: translateY(-28px);
`;

export const TimeCompany = styled.div`
    display: grid;
    font-family: Abril Fatface;
    font-size: 18px;
    text-shadow: -0.7px -0.7px 0 #fff, 0.7px -0.7px 0 #fff, -0.7px 0.7px 0 #fff, 0.7px 0.7px 0 #fff;
    /* text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff; */
    font-weight: 100;
    color: #3e3939;
    transform: translateY(-20px);
`;

export const LeftContainer = styled.div`
    display: grid;
    padding: 12px;
    margin: 20px 0;
    grid-template-rows: 33% 33% 33%;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 100;
`;

export const Role = styled.div`
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 100;
`;

export const Manager = styled.div`
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 100;

    @media screen and (max-width: 610px) {
        margin-top: 20px;
    }
`;

export const Location = styled.div`
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 100;
    @media screen and (max-width: 610px) {
        margin-top: 20px;
    }
`;

export const Schooling = styled.div`
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 100;
    @media screen and (max-width: 610px) {
        margin-top: 40px;
    }
`;

export const IconContainer = styled.div`
    display: flex;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 700;
    align-self: center;
    align-items: center;
`;

export const TextDreamContainer = styled.div`
    display: grid;
    overflow-y: auto;
    max-height: 100%;
    text-align: left;
`;

export const RightContainer = styled.div`
    display: grid;
    padding: 12px;
    margin: 20px 0;
    align-items: center;
    border-left: 1px solid #3e3939;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 100;
    max-height: 200px;
    @media screen and (max-width: 610px) {
        border-left: 0px solid #3e3939;
        border-top: 1px solid #3e3939;
    }
`;

export const UserImage = styled.img`
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
`;

export const HelpButton = styled.div`
    position: absolute;
    display: flex;
    justify-self: end;
    cursor: pointer;
`;

//Modal HelpButton

export const TitleHelpButton = styled.div`
    font-family: Abril Fatface;
    font-size: 32px;
    font-weight: 700;
    border-bottom: 1px solid black;
`;

export const TextHelpButton = styled.div`
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 100;
    padding-top: 12px;
`;

export const AboutHelp = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
`;

export const BarHelp = styled.div`
    display: grid;
    grid-template-columns: 1fr 20fr;
    justify-items: start;
    margin: 12px 0;
`;

export const BarGreen = styled.div`
    display: grid;
    justify-content: center;
    align-self: center;
    background-color: #00ff00;
    width: 6px;
    height: 50px;
    border-radius: 12px;
`;

export const BarYellow = styled.div`
    display: grid;
    justify-content: center;
    align-self: center;
    background-color: #ffff00;
    width: 6px;
    height: 50px;
    border-radius: 12px;
`;

export const BarRed = styled.div`
    display: grid;
    justify-content: center;
    align-self: center;
    background-color: #ff0000;
    width: 6px;
    height: 50px;
    border-radius: 12px;
`;

export const BarAlert = styled.div`
    display: grid;
    justify-content: center;
    align-self: center;
    width: 6px;
    height: 50px;
    border-radius: 12px;
`;

export const BarText = styled.div`
    display: flex;
    justify-content: start;
    align-self: center;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 100;
`;
