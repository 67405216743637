export function getColor(grade, type) {
    let color;
    if ((parseInt(grade) === 3) || (parseInt(grade) > 67)) {
        color = '#38761D';
        if (type === 2)
            color = '2px solid #31761d';
    } else if ((parseInt(grade) === 2) || ((parseInt(grade) > 33) && (parseInt(grade) <= 67))) {
        color = '#3d9a15';
        if (type === 2)
            color = '2px solid #348412';
    } else if ((parseInt(grade) === 1) || ((parseInt(grade) > 3) && (parseInt(grade) <= 33))) {
        color = '#00FF00';
        if (type === 2)
            color = '2px solid #00e600';
    } else if ((parseInt(grade) === 0) || (parseInt(grade) === -1) || ((parseInt(grade) >= -33) && (parseInt(grade) < -3))) {
        color = '#FFFF00';
        if (type === 2)
            color = '2px solid #e6e600';
    } else if ((parseInt(grade) === -2) || ((parseInt(grade) >= -67) && (parseInt(grade) < -33))) {
        color = '#FF7800';
        if (type === 2)
            color = '2px solid #e66c00';
    } else if ((parseInt(grade) === -3) || ((parseInt(grade) < -67))) {
        color = '#FF0000';
        if (type === 2)
            color = '2px solid #e60000';
    } else {
        if (type === 2)
            color = '1px solid #E0E0E0';
    }

    return color;
}