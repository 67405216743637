import React, { useContext, useEffect, useState } from 'react';
import ReactExport from "react-export-excel";
import { useHistory } from 'react-router-dom';
import { isUndefined } from 'svgsaver/src/utils';

/* Styles */
import { StyledInput, ContainerButtons } from './manage-users-tab.styles';
import {
    DetailModal,
    DetailGender,
    DetailStartDate,
    DetailFortress,
    DetailDream,
    DetailNote,
    DetailBirthDate
} from './manage-users-tab.styles';

/* MaterialUI */
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';

/* Components */
import SwitchTwoLabels from '../../../components/switch/switch.component';
import EnhancedTable from '../../../components/table-to-show-data/table-to-show-data.component';
import {
    WarningTitleContainer,
    WarningWord,
} from '../../../components/form-list/form-list.styles';

/* Context */
import { authContext } from '../../../contexts/AuthContext';

/* Api*/
import { getAllUsersInCompany } from '../../../api/ApiUser';
import CustomButton from '../../../components/custom-button/custom-button.component';
import RenderPage from '../../../components/render-page/render-page.component';
import { getAllDepartments } from '../../../api/ApiDepartment';
import { getAllPermissions } from '../../../api/ApiPemissions';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    paperModal: {
        marginTop: '10%',
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        width: 950,
        maxHeight: 900,
        borderRadius: '10px',
        backgroundColor: '#fff',
        padding: '30px',
        fontFamily: 'Roboto',
        textAlign: 'center',
        '@media (max-width: 750px)': {
            height: 620,
            marginTop: '80%',
            transform: 'translate(-50%, -50%) scale(0.7)',
        },
    },
}));

export default function UsersTab(props) {
    const classes = useStyles();
    const [title] = React.useState({ firstText: '', warningText: '' });
    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState([]);
    const [partialData, setPartialData] = useState([]); // this data is filtered so it only contains what will show up in the users table
    const [fullData, setFullData] = useState([]);
    const [globalCompany, setGlobalCompany] = useState({});
    const [showInactivedUser, setShowInactivedUser] = useState(false);

    const { auth } = useContext(authContext);
    const [fetchOpen, setFetchOpen] = useState(true);
    let history = useHistory();

    const [optionsDepartments, setOptionsDepartments] = useState([]);
    const [departmentSelected, setDepartmentSelected] = useState(null);

    const [optionsPermissions, setOptionsPermissions] = useState([]);
    const [permissionSelected, setPermissionSelected] = useState(null);

    const [userSelected, setUserSelected] = useState(null);
    const [details, setDetails] = useState([]);
    const [openAssignment, setOpenAssignment] = useState(false);
    const [warningTitle, setWarningTitle] = useState(null);

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    
    class ExcelDownload extends React.Component {
        render() {
            var currDate = new Date();
            return (
                <ExcelFile 
                    filename={"Cadastro_de_colaboradores_" + ("0" + currDate.getDate()).slice(-2) + "_" + ("0" + (currDate.getMonth()+1)).slice(-2) + "_" + currDate.getFullYear()}
                    element={
                    <CustomButton type="button" addButton>
                    {' '}
                    Baixar excel{' '}
                </CustomButton>}
                >
                    <ExcelSheet data={fullData} name={'Colaboradores'}>
                        <ExcelColumn label={"Nome"} value={"name"}/>
                        <ExcelColumn label={"Gênero"} value={"gender"}/>
                        <ExcelColumn label={"Cor"} value={"ethnicity"}/>
                        <ExcelColumn label={"Data de nascimento"} value={"birthDate"}/>
                        <ExcelColumn label={"Força"} value={"fortress"}/>
                        <ExcelColumn label={"Sonho"} value={"dream"}/>
                        <ExcelColumn label={"Propósito"} value={"purpose"}/>
                        <ExcelColumn label={"Escolaridade"} value={"schooling"}/>
                        <ExcelColumn label={"Ano que terminou graduação"} value={"yearGraduated"}/>
                        <ExcelColumn label={"Gestor direto"} value={"directManagerName"}/>
                        <ExcelColumn label={"CNH"} value={"driverLicense"}/>
                        <ExcelColumn label={"Setor"} value={"departmentName"}/>
                        <ExcelColumn label={"Cidade de atuação"} value={"actingCity"}/>
                        <ExcelColumn label={"Cargo"} value={"role"}/>
                        <ExcelColumn label={"Data de admissão"} value={"startDate"}/>
                        <ExcelColumn label={"Observações"} value={"note"}/>
                        <ExcelColumn label={"Data de desligamento"} value={"terminationDate"}/>
                        <ExcelColumn label={"Motivo do desligamento"} value={"terminationReason"}/>
                        <ExcelColumn label={"Categoria do desligamento"} value={"terminationCategory"}/>
                        <ExcelColumn label={"Status"} value={"active"}/>
                        <ExcelColumn label={"Resumo da vida"} value={"lifeSummary"}/>
                    </ExcelSheet>
                </ExcelFile>
            )
        }
    }

    const handleSingleClick = (event, row) => {
        let index = details.findIndex((detail) => detail.name === row.name);
        setUserSelected(details[index]);
        setWarningTitle({
            phrase: `Detalhes do colaborador `,
            object: `${details[index].name}`,
            warning: globalCompany !== auth.data.user.companyId ? `(Você está detalhando um usuário de outra empresa)` : ''
        });
    };
    
    const handleOpenAssignment = () => {
        setOpenAssignment(!openAssignment);
    };

    let assignmentButtonInTable = {
        buttonLabel: 'Detalhar',
        modal: true,
        modalHandle: handleOpenAssignment,
    };

    const headCells = [
        { id: 'id', numeric: false, disablePadding: false, label: 'Id'},
        { id: 'name', numeric: false, disablePadding: false, label: 'Nome' },
        { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
        {
            id: 'department',
            numeric: false,
            disablePadding: false,
            label: 'Setor',
        },
        {
            id: 'permission',
            numeric: false,
            disablePadding: false,
            label: 'Permissão',
        },
        { id: 'role', numeric: false, disablePadding: false, label: 'Função' },
        {
            id: 'active',
            numeric: false,
            disablePadding: false,
            label: 'Status',
        },
        {
            id: 'photo',
            numeric: false,
            disablePadding: false,
            label: 'Foto',
        },
        { id: 'action', numeric: false, disablePadding: false, label: 'Ação' },
    ];

    let edit = {
        buttonLabel: 'Editar',
        start: `/management/company/${globalCompany}/users/`,
        end: `/edit`,
        firstInformation: 'userId',
    };

    const defaultPropsDepartments = {
        options: optionsDepartments,
        value: departmentSelected,
        getOptionLabel: (option) => {
            return option.name || '';
        },
        getOptionSelected: (option) => {
            return option.name || '';
        },
        onChange: (event, newValue) => {
            setDepartmentSelected(newValue);
        },
    };

    const defaultPropsPermissions = {
        options: optionsPermissions,
        value: permissionSelected,
        getOptionLabel: (option) => {
            return option.name || '';
        },
        getOptionSelected: (option) => {
            return option.name || '';
        },
        onChange: (event, newValue) => {
            setPermissionSelected(newValue);
        },
    };

    useEffect(() => {
        const callApiGetAllDepartments = async () => {
            const response = await getAllDepartments(auth, globalCompany);
            const { message, payload } = await response.json();
            
            if (response.status !== 200) throw Error(message);

            setOptionsDepartments(payload);
        };

        const callApiGetAllPermissions = async () => {
            const response = await getAllPermissions(auth, globalCompany);
            const { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            setOptionsPermissions(payload);
        };

        try {
            callApiGetAllDepartments();
            callApiGetAllPermissions();
        } catch (error) {
            console.log(error);
        }
    }, [auth, globalCompany]);

    useEffect(() => {
        let actualGlobalCompany;

        if (props.theGlobalCompanyHasChange) {
            setFetchOpen(true);
        }

        if (!isUndefined(props.globalCompany) && props.globalCompany !== null) {
            if (props.globalCompany.hasOwnProperty('companyId')) {
                actualGlobalCompany = props.globalCompany.companyId;
                setGlobalCompany(props.globalCompany.companyId);
            } else {
                actualGlobalCompany = auth.data.user.companyId;
                setGlobalCompany(auth.data.user.companyId);
            }
        } else {
            actualGlobalCompany = auth.data.user.companyId;
            setGlobalCompany(auth.data.user.companyId);
        }

        const formatData = (data) => {
            let s = data.split('-');

            let dd = s[2].split('T', 1);
            return dd + '/' + s[1] + '/' + s[0];
        };

        const callApi = async (globalCompany) => {
            const response = await getAllUsersInCompany(auth, globalCompany);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body.payload;
        };

        //certo
        callApi(actualGlobalCompany)
            .then((res) => {
                if (!showInactivedUser) {
                    res = res.filter((eachUser) => eachUser.allocation.active);
                }

                let auxValue;
                let newData = [];
                let newDataSort = [];
                let newDetailsSort = [];

                for (let i = 0; i < res.length; i++) {
                    auxValue = res[i].allocation;
                    newData[i] = { ...auxValue, ...res[i] };
                    delete newData[i].allocation;

                    newDataSort[i] = {
                        userId: newData[i]['userId'],
                        name: newData[i]['name'],
                        email: newData[i]['email'],
                        department: newData[i]['departmentName'],
                        permission: newData[i]['permissionRoleName'],
                        role: newData[i]['role'],
                        active: newData[i]['active'] ? 'Ativo' : 'Inativo',
                        photo: newData[i]['fileName'] ? 'Sim' : 'Não',
                        action: 1,
                    };
                    newDetailsSort[i] = {
                        name: newData[i]['name'],
                        startDate: formatData(newData[i]['startDate']),
                        gender: newData[i]['gender'],
                        birthDate: formatData(newData[i]['birthDate']),
                        dream: newData[i]['dream'],
                        fortress: newData[i]['fortress'],
                        note: newData[i]['note']
                    };

                }

                setRows(newDataSort);
                setPartialData(newDataSort);
                setFullData(newData);
                setDetails(newDetailsSort);
                props.resetChangeActualGlobalCompany(false);
                setFetchOpen(false);
            })
            .catch((err) => console.log(err));
    }, [props.globalCompany, showInactivedUser]);

    // handle change event of search input
    const handleChange = (value) => {
        setSearchText(value);
    };

    const filterSearchName = (array) => {
        return searchText ? 
            array.filter((item) => item.name.toLowerCase().indexOf(searchText.toLowerCase()) != -1) 
            : array;
    };

    const filterByDepartment = (array) => {
        return  departmentSelected ? 
            array.filter((item) => item.department.toLowerCase().indexOf(departmentSelected.name.toLowerCase()) != -1)
            : array;
    };

    const filterByPermission = (array) => {
        return permissionSelected ? 
            array.filter((item) => item.permission.toLowerCase().indexOf(permissionSelected.name.toLowerCase()) != -1)
            : array;
    };

    // manage filters
    useEffect (() => {
        let result = partialData;
        result = filterSearchName(result);
        result = filterByDepartment(result);
        result = filterByPermission(result);
        setRows(result);
    }, [partialData, searchText, departmentSelected, permissionSelected]);

    const addUser = () => {
        history.replace(`/management/company/${globalCompany}/users/add`);
    };

    const excel = () => {
        console.log(fullData)
    }

    return (
        <div>
            <ContainerButtons>
                <div>
                    <StyledInput
                        type="text"
                        placeholder="Digite para pesquisar..."
                        value={searchText}
                        onChange={(e) => handleChange(e.target.value)}
                    />
                </div>
                <Autocomplete
                    {...defaultPropsDepartments}
                    id="departments"
                    autoSelect
                    className={classes.formControl}
                    style={{ width: 150 }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Setores"
                            variant="outlined"
                        />
                    )}
                />
                <Autocomplete
                    {...defaultPropsPermissions}
                    id="permissions"
                    autoSelect
                    className={classes.formControl}
                    style={{ width: 150 }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Permissões"
                            variant="outlined"
                        />
                    )}
                />
                <CustomButton type="button" onClick={addUser} addButton>
                    {' '}
                    Adicionar{' '}
                </CustomButton>
            </ContainerButtons>
            <SwitchTwoLabels
                checkAction={setShowInactivedUser}
                rightWord="Mostrar usuários desativados"
            />
            <EnhancedTable
                rows={rows}
                headCells={headCells}
                title={title}
                assignment={assignmentButtonInTable}
                handleClick={handleSingleClick}
                edit={edit}
                showInactived={showInactivedUser}
            />
            <Modal
                open={openAssignment}
                onClose={handleOpenAssignment}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.paperModal}>
                    {warningTitle ? (
                        <WarningTitleContainer>
                            <Typography variant="h5">
                                {warningTitle.phrase}
                                <WarningWord>{warningTitle.object}</WarningWord>
                            </Typography>
                            <Typography variant="body2">
                                <WarningWord>{warningTitle.warning}</WarningWord>
                            </Typography>
                        </WarningTitleContainer>
                    ) : null}
                    <DetailModal>
                        <DetailGender>
                            <TextField
                                id="outlined-basic"
                                label="Gênero"
                                variant="outlined"
                                name="name"
                                className={classes.name}
                                value={userSelected ? userSelected.gender : ''}
                                type="text"
                                placeholder=""
                                maxLength="80"
                            />
                        </DetailGender>
                        <DetailBirthDate>
                            <TextField
                                id="outlined-basic"
                                label="Data de Nascimento"
                                variant="outlined"
                                name="name"
                                className={classes.name}
                                value={userSelected ? userSelected.birthDate : ''}
                                type="text"
                                placeholder=""
                                maxLength="80"
                            />
                        </DetailBirthDate>
                        <DetailFortress>
                            <TextField
                                id="outlined-basic"
                                label="Fortaleza"
                                variant="outlined"
                                name="description"
                                value={userSelected ? userSelected.fortress : ''}
                                type="text"
                                className={classes.description}
                                placeholder=""
                                maxLength="80"
                            />
                        </DetailFortress>
                        <DetailStartDate>
                            <TextField
                                id="outlined-basic"
                                label="Início na função"
                                variant="outlined"
                                name="description"
                                value={userSelected ? userSelected.startDate : ''}
                                type="text"
                                className={classes.description}
                                placeholder=""
                                maxLength="80"
                            />
                        </DetailStartDate>
                        <DetailDream>
                            <TextField
                                multiline
                                id="outlined-basic"
                                label="Sonho"
                                variant="outlined"
                                name="description"
                                value={userSelected ? userSelected.dream : ''}
                                type="text"
                                className={classes.description}
                                placeholder=""
                            />
                        </DetailDream>
                        <DetailNote>
                            <TextField
                                multiline
                                id="outlined-basic"
                                label="Observação"
                                variant="outlined"
                                name="description"
                                value={userSelected ? userSelected.note : ''}
                                type="text"
                                className={classes.description}
                                placeholder=""
                            />
                        </DetailNote>
                    </DetailModal>
                </div>
            </Modal>
            {fetchOpen ? <RenderPage open={fetchOpen} /> : null}
            <ExcelDownload></ExcelDownload>
        </div>
    );
}