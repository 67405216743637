import styled from 'styled-components';

export const TitleLogin = styled.div`
    align-self: center;
    padding-left: 72px;
    font-family: Abril Fatface;
    font-size: 38px;
    font-weight: 700;
    line-height: 32px;
    @media screen and (max-width: 1050px) {
        padding-left: 0px;
    }
`;

export const ForgetPasswordForm = styled.div`
    align-self: start;
    padding-left: 72px;
    @media screen and (max-width: 1050px) {
        padding-left: 0px;
    }
`;

export const ForgetPasswordButton = styled.div`
    font-family: Abril Fatface;
    display: grid;
    grid-template-rows: 70% 30%;
`;

export const SendButton = styled.div`
    font-family: Abril Fatface;
    align-self: start;
    padding-left: 72px;
    @media screen and (max-width: 1050px) {
        padding-left: 0px;
    }
`;

export const BackButton = styled.div`
    align-self: start;
    padding-left: 72px;
    @media screen and (max-width: 1050px) {
        padding-left: 0px;
    }
`;

export const ForgetTitle = styled.div`
    font-family: Abril Fatface;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    align-self: center;
`;
