import React, {useContext} from "react";

/*SubPages*/
import UsersRecordsIF from './manage-records-if/manage-records-if.component'
import UsersRecordsIPA from './manage-records-ipa/manage-records-ipa.component'

/* Context */
import {authContext} from "../../../contexts/AuthContext";


export default function UsersRecordsTab(props) {

    const {auth} = useContext(authContext);

    return (
        <div>
            {auth.data.system ? <UsersRecordsIPA resetChangeActualGlobalCompany={props.resetChangeActualGlobalCompany}
                                                 theGlobalCompanyHasChange={props.theGlobalCompanyHasChange}
                                                 globalCompany={props.globalCompany}/>
                : <UsersRecordsIF resetChangeActualGlobalCompany={props.resetChangeActualGlobalCompany}
                                  theGlobalCompanyHasChange={props.theGlobalCompanyHasChange}
                                  globalCompany={props.globalCompany}/>}
        </div>
    );
}
