import React, { useContext, useEffect, useState } from 'react';

/* Styles */
import {
    About,
    BottomContainer,
    CompanyYears,
    Manager,
    IconContainer,
    LeftContainer,
    NumberTimeCompany,
    Role,
    Location,
    Schooling,
    PhotoIcon,
    RightContainer,
    TextDreamContainer,
    TimeCompany,
    TitleTextAbout,
    UpContainer,
    UserImage,
    HelpButton,
    TitleHelpButton,
    TextHelpButton,
    AboutHelp,
    BarHelp,
    BarGreen,
    BarYellow,
    BarRed,
    BarAlert,
    BarText,
} from './user-about.styles';

/* Material UI */
import FaceIcon from '@material-ui/icons/Face';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import RoomIcon from '@material-ui/icons/Room';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import SchoolIcon from '@material-ui/icons/School';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';

/* backend routes for Department */
import {
    getManagerDepartment,
    getOneDepartment,
} from '../../../../api/ApiDepartment';

/* Contexts */
import { authContext } from '../../../../contexts/AuthContext';
import { getOneUser } from '../../../../api/ApiUser';
import RenderComponent from '../../../../components/render-component/render-component.component';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '85%',
        maxHeight: '80%',
        overflowY: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ff7517',
        borderRadius: '8px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function UserAbout({ selectedUser }) {
    const { auth } = useContext(authContext);
    const [loadingData, setLoadingData] = useState(true);
    const [purpose, setPurpose] = useState('');
    const [user, setUser] = useState({});
    const [image, setImage] = useState('');
    const [managerName, setManagerName] = useState('');
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const helpModal = (
        <div style={{ outline: 'none' }} className={classes.paper}>
            <TitleHelpButton id="simple-modal-title">
                Dashboard IPA
            </TitleHelpButton>
            <TextHelpButton id="simple-modal-description">
                Introdução: Nesta seção, destaca-se a importância do sistema por
                meio de um texto informativo, incentivando o uso para melhorar
                eficiência e eficácia. Um botão "Veja mais" direciona a um vídeo
                explicativo sobre a ferramenta. <br />
                <br />
                Sobre: A seção "Sobre" oferece informações essenciais dos
                colaboradores, divididas em categorias como foto, tempo na
                empresa, cargo, local e formação. Também apresenta o propósito
                do setor e o gerente responsável.
                <br />
                <br />
                <AboutHelp>
                    <div>
                        Setor: Aqui, visualiza-se o alinhamento entre membros do
                        setor, com símbolo identificador. Alinhamento é colorido
                        (verde para igual, amarelo para diferença de 1, vermelho
                        para diferença de 2+ pontos). Alerta destaca que
                        alinhamento não garante avaliações positivas.
                        <br /> <br />
                    </div>

                    <BarHelp>
                        <BarGreen />
                        <BarText>
                            A cor verde significa que você está alinhado com o
                            colaborador específico!
                        </BarText>
                    </BarHelp>
                    <BarHelp>
                        <BarYellow />
                        <BarText>
                            A cor amarela significa que você está parcialmente
                            alinhado com o colaborador específico!
                        </BarText>
                    </BarHelp>
                    <BarHelp>
                        <BarRed />
                        <BarText>
                            A cor vermelha significa que você está desalinhado
                            com o colaborador específico!
                        </BarText>
                    </BarHelp>
                    <BarHelp>
                        <WarningIcon
                            style={{ display: 'flex', alignSelf: 'center' }}
                        />
                        <BarText>
                            O sinal de alerta significa que você e o colaborador
                            específico estão alinhados mas com notas negativas!
                        </BarText>
                    </BarHelp>
                </AboutHelp>
                Dados: A última seção exibe dados pessoais, de setor e empresa
                relacionados a tarefas, alinhamentos e NPS. Somente o NPS mais
                recente é mostrado, proporcionando uma análise consolidada e
                comparativa do desempenho.
            </TextHelpButton>
        </div>
    );

    function getAllocationDate(startedAt) {
        if (!startedAt) return null;

        const currentDate = new Date();
        const startedAtDate = new Date(startedAt);
        const formattedStartedAt = new Date(
            startedAtDate.toISOString().slice(0, -1)
        );
        const months =
            (currentDate.getFullYear() - formattedStartedAt.getFullYear()) *
                12 +
            (currentDate.getMonth() - formattedStartedAt.getMonth());
        const years = Math.floor(months / 12);

        if (years > 0) {
            return (
                <>
                    <NumberTimeCompany>{years}</NumberTimeCompany>
                    <TimeCompany>{years === 1 ? 'ano' : 'anos'}</TimeCompany>
                </>
            );
        } else if (months > 0) {
            return (
                <>
                    <NumberTimeCompany>{months}</NumberTimeCompany>
                    <TimeCompany>{months > 1 ? 'meses' : 'mês'}</TimeCompany>
                </>
            );
        } else {
            return (<></>);
        }
    }

    useEffect(() => {
        const callApiFindOneDepartment = async () => {
            setLoadingData(true);
            const response = await getOneDepartment(
                auth,
                selectedUser.allocation.departmentId,
                auth.data.user.companyId
            );

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            setPurpose(payload[0].purpose);
            setLoadingData(false);
        };
        try {
            callApiFindOneDepartment();
        } catch (err) {
            console.log(err);
        }
    }, [auth, selectedUser]);

    useEffect(() => {
        const callApiFindManagerDepartment = async () => {
            setLoadingData(true);
            const response = await getManagerDepartment(
                auth,
                auth.data.user.companyId,
                selectedUser.allocation.departmentId
            );

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            setManagerName(payload[0]?.shortenedName);
            setLoadingData(false);
        };
        try {
            callApiFindManagerDepartment();
        } catch (err) {
            console.log(err);
        }
    }, [auth, selectedUser]);

    useEffect(() => {
        const callApiFindOneUser = async () => {
            setLoadingData(true);
            const response = await getOneUser(
                auth,
                selectedUser.userId,
                auth.data.user.companyId
            );

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            setImage(payload[0].fileUrl);
            setUser(payload[0]);
            setLoadingData(false);
        };
        try {
            callApiFindOneUser();
        } catch (err) {
            console.log(err);
        }
    }, [auth, selectedUser]);

    return (
        <>
            {loadingData ? (
                <RenderComponent />
            ) : (
                <About>
                    <UpContainer>
                        <TitleTextAbout>Sobre</TitleTextAbout>
                        <PhotoIcon>
                            <div>
                                {image ? (
                                    <UserImage
                                        style={{
                                            border: '1px solid #ff7517',
                                        }}
                                        src={image}
                                    />
                                ) : (
                                    <FaceIcon
                                        style={{
                                            fontSize: '46px',
                                        }}
                                    />
                                )}
                            </div>
                            <CompanyYears>
                                {getAllocationDate(user.allocation?.startDate)}
                            </CompanyYears>
                        </PhotoIcon>

                        <HelpButton>
                            <HelpIcon
                                type="button"
                                onClick={handleOpen}
                                style={{
                                    color: '#ff7517',
                                }}
                            />
                        </HelpButton>
                    </UpContainer>
                    <BottomContainer>
                        <LeftContainer>
                            <Role>
                                <Tooltip title="Cargo">
                                    <AssignmentIndIcon
                                        style={{
                                            fontSize: '46px',
                                            color: '#ff7517',
                                            marginRight: '10.5px',
                                        }}
                                    />
                                </Tooltip>
                                {selectedUser.allocation.role}
                            </Role>
                            <Location>
                                <Tooltip title="Local de Atuação">
                                    <RoomIcon
                                        style={{
                                            fontSize: '46px',
                                            color: '#ff7517',
                                            marginRight: '12px',
                                        }}
                                    />
                                </Tooltip>
                                {selectedUser.allocation.actingCity}
                            </Location>
                            <Schooling>
                                <Tooltip title="Formação">
                                    <SchoolIcon
                                        style={{
                                            fontSize: '46px',
                                            color: '#ff7517',
                                            marginRight: '12px',
                                        }}
                                    />
                                </Tooltip>
                                {selectedUser.schooling}
                            </Schooling>
                        </LeftContainer>
                        <RightContainer style={{
                            gridTemplateRows: managerName ? '25% 50% 25%' : '25% 75%'
                        }}>
                            <IconContainer>
                                <BeenhereIcon
                                    style={{
                                        fontSize: '40px',
                                        color: '#ff7517',
                                        marginRight: '12px',
                                    }}
                                />
                                Propósito do setor!
                            </IconContainer>
                            <TextDreamContainer> {purpose}</TextDreamContainer>
                            {managerName ? (
                                <Manager>
                                    <Tooltip title="Gerente">
                                        <SupervisorAccountIcon
                                            style={{
                                                fontSize: '46px',
                                                color: '#ff7517',
                                                marginRight: '12px',
                                            }}
                                        />
                                    </Tooltip>
                                    {managerName}
                                </Manager>
                            ) : null}
                        </RightContainer>
                    </BottomContainer>
                </About>
            )}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {helpModal}
            </Modal>
        </>
    );
}
