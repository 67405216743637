import React, { useMemo, useState } from 'react';

/* Material-UI */
import { Button, InputAdornment, ListSubheader, MenuItem, Select, makeStyles } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';

/* Image */
import ipaCharacter from '../../../../assets/Character.png';

/* Styled Components */
import {
    CharacterImage,
    ImportantText,
    IntroContainer,
    TextContainer,
    TitleContainer,
    ButtonUserIntro,
    TitleText,
    TitleText2,
} from './user-intro.styles';

import permissions, { getSubmodulePermission } from '../../../../utils/permission';

/* Styles */
const useStyles = makeStyles((theme) => ({
    image: {
        position: 'absolute',
        objectFit: 'cover',
        width: 120,
        zIndex: 999,
        transform: 'translateY(-25px)',
    },
    button: {
        width: '12rem',
        height: '60px',
        background: '#ff7517',
        borderRadius: '12px',
        padding: '12px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    },
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ff7517',
        borderRadius:'8px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },
    selectMenu: {
        maxHeight: '400px',
        borderRadius:'4px',
        border:'1px solid #ff7517',
    },
    textField: {
        backgroundColor: '#fff'
    }
}));

const containsText = (text, searchText) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export default function UserIntro({ users, selectedUser, setSelectedUser, submodules }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [searchText, setSearchText] = useState("");

    const displayedOptions = useMemo(
        () => users.filter((option) => containsText(option.name, searchText)),
        [searchText, users]
    );

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    

    const body = (
        <div className={classes.paper}>
            <h2 id="simple-modal-title">Em breve novidades!</h2>
            <p id="simple-modal-description">
                Aguarde!
            </p>
        </div>
    );

    return (
        <IntroContainer>
            <TextContainer>
                {' '}
                <TitleContainer>
                    <TitleText>Olá,</TitleText>
                        <TitleText2>
                        {getSubmodulePermission(submodules.dashboard, permissions.EMPLOYEES_VISUALIZATION_FILTER) || 
                        getSubmodulePermission(submodules.dashboard, permissions.EMPLOYEES_VISUALIZATION_FILTER_DEPARTMENT) ? (
                            <Select
                                MenuProps={{ 
                                    classes: {
                                        paper: classes.selectMenu
                                    }
                                }}
                                labelId="search-select-label"
                                id="search-select"
                                value={selectedUser}
                                label="Options"
                                onChange={(e) => {
                                    if(e.target.value) {
                                        setSelectedUser(e.target.value)
                                    }
                                }}
                                onClose={() => setSearchText("")}
                                renderValue={(user) => (
                                    <TitleText >{Object.keys(user).length > 0 ? ` ${user.name.split(' ')[0]}` : ``}</TitleText>
                                )}
                            >
                                <ListSubheader>
                                    <TextField
                                        size="small"
                                        autoFocus
                                        placeholder="Busca por nome..."
                                        fullWidth
                                        InputProps={{
                                            className: classes.textField,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            )
                                        }}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key !== "Escape") {
                                                e.stopPropagation();
                                            }
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    />
                                </ListSubheader>
                                {displayedOptions.map((option, i) => (
                                    <MenuItem key={i} value={option}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>   
                        ):(
                        `${selectedUser.name.split(' ')[0]}`
                        )}
                    </TitleText2>
                </TitleContainer>
                <ImportantText>
                    A Drakkar Agricultura de Precisão reconhece a importância de
                    utilizar o Índice de Performance de Alinhamento para avaliar
                    nosso desempenho. Ao mensurarmos os aspectos em que estamos
                    excelentes e aqueles que precisam de melhorias, essa prática fortalece a coesão da equipe,
                    estimula o aprendizado conjunto e impulsiona o alcance de
                    metas comuns.
                </ImportantText>
                <ButtonUserIntro>
                    {' '}
                    <Button
                        variant="contained"
                        className={classes.button}
                        size="large"
                        color="primary"
                        type="button"
                        onClick={handleOpen}
                    >
                        Veja Mais
                    </Button>
                </ButtonUserIntro>
            </TextContainer>
            <CharacterImage src={ipaCharacter} alt="Personagem IPA Dashboard" />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </IntroContainer>
    );
}