import React, { useContext, useEffect, useState } from 'react';

import {
    WarningTitleContainer,
    WarningWord,
} from '../../../../.././components/form-list/form-list.styles';

/* Components */
import PopUp from '../../../../../components/popup/popup.component';
import CustomButton from '../../../../../components/./custom-button/custom-button.component';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

/* Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

/* api */
import { addTask } from '../../../../../api/ApiTasks';
import { getOneCompany } from '../../../../../api/ApiCompany';
import { findAllCategories } from '../../../../../api/ApiCategory';

/* Context */
import { authContext } from '../../../../../contexts/AuthContext';

/* Styles */

import {
    AddTaskModal,
    AddName,
    AddCategory,
    AddDescription,
    ButtonContainer,
} from './add-task.styles';

const useStyles = makeStyles((theme) => ({
    paperModal: {
        marginTop: '10%',
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        width: 950,
        maxHeight: 900,
        borderRadius: '10px',
        backgroundColor: '#fff',
        padding: '10px',
        fontFamily: 'Roboto',
        textAlign: 'center',
        '@media (max-width: 750px)': {
            height: 620,
            marginTop: '80%',
            transform: 'translate(-50%, -50%) scale(0.7)',
        },
    },
    category: {
        width: '100%',
    },
}));

export default function AddTask(props) {
    const { auth } = useContext(authContext);

    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [category, setCategory] = React.useState(null);
    const [categoriesOptions, setCategoriesOptions] = useState([]);

    const [warningTitle, setWarningTitle] = React.useState({});
    const [popup, setPopup] = React.useState(false);
    const [popupText, setPopupText] = React.useState('');
    const [popupTitle, setPopupTitle] = React.useState('');
    const [success, setSuccess] = React.useState(1);
    const [fetchOpen, setFetchOpen] = useState(false);

    const classes = useStyles();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setPopup(false);
        setFetchOpen(true);
        if (!category) {
            setPopupTitle('Erro');
            setPopupText('Insira a categoria');
            setPopup(true);
            setSuccess(3);
            return;
        }

        let data = {
            name: name,
            description: description,
            categoryId: category.categoryId,
        };

        try {
            let response = await addTask(data, auth, props.companyId);
            setFetchOpen(false);
            if (response.status === 201) {
                setPopupTitle('Sucesso');
                setPopupText('Tarefa criada.');
                setPopup(true);
                setSuccess(3);
            } else if (response.status === 400) {
                setPopupTitle('Erro');
                setPopupText('Tarefa já existe.');
                setPopup(true);
                setSuccess(0);
            } else {
                setPopupTitle('Erro');
                setPopupText('Tarefa não pode ser criada.');
                setPopup(true);
                setSuccess(0);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = (event) => {
        const { value, name } = event.target;
        if (name === 'name') setName(value);
        else setDescription(value);
    };

    const setNewFatherContext = () => {
        props.setReloadTable(!props.reloadTable);
        setName('');
        setDescription('');
    };

    useEffect(() => {
        const callApiFindOneCompany = async () => {
            const response = await getOneCompany(auth, props.companyId);
            const body = await response.json();

            return body.payload[0];
        };

        const callApiFindAllCategories = async () => {
            const response = await findAllCategories(auth);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body.payload;
        };

        callApiFindAllCategories()
            .then((categoriesRes) => {
                setCategoriesOptions(categoriesRes);
            })
            .catch((err) => console.log(err));

        callApiFindOneCompany()
            .then((companyRes) => {
                setWarningTitle({
                    phrase: `Adicionando tarefa em `,
                    object: `${companyRes.name}`,
                    warning:
                        parseInt(props.companyId) !==
                        parseInt(auth.data.user.companyId)
                            ? `(Cuidado! Você está adicionando uma tarefa em outra empresa)`
                            : '',
                });
            })
            .catch((err) => console.log(err));
    }, []);

    return (
        <div className={classes.paperModal}>
            {warningTitle ? (
                <WarningTitleContainer>
                    <Typography variant="h5">
                        {warningTitle.phrase}
                        <WarningWord>{warningTitle.object}</WarningWord>
                    </Typography>
                    <Typography variant="body2">
                        <WarningWord>{warningTitle.warning}</WarningWord>
                    </Typography>
                </WarningTitleContainer>
            ) : null}

            <AddTaskModal>
                <AddName>
                    <TextField
                        id="outlined-basic"
                        label="Nome"
                        variant="outlined"
                        name="name"
                        className={classes.name}
                        value={name}
                        type="text"
                        onChange={handleChange}
                        placeholder=""
                        maxLength="80"
                    />
                </AddName>
                <AddCategory>
                    <Autocomplete
                        onChange={(event, newValue) => setCategory(newValue)}
                        getOptionSelected={(option, value) =>
                            option.name === value.name
                        }
                        className={classes.category}
                        id="categories"
                        options={categoriesOptions}
                        getOptionLabel={(options) => options.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={'Categoria'}
                                variant="outlined"
                            />
                        )}
                    />
                </AddCategory>
                <AddDescription>
                    <TextField
                        id="outlined-basic"
                        label="Descrição"
                        variant="outlined"
                        name="description"
                        value={description}
                        type="text"
                        className={classes.description}
                        onChange={handleChange}
                        placeholder=""
                        maxLength="80"
                    />
                </AddDescription>
                <ButtonContainer>
                    <CustomButton
                        type="submit"
                        addButton="true"
                        editButton="false"
                        onClick={handleSubmit}
                    >
                        Adicionar
                    </CustomButton>
                </ButtonContainer>
            </AddTaskModal>
            {popup ? (
                <PopUp
                    title={popupTitle}
                    string={popupText}
                    success={success}
                    setNewFatherContext={setNewFatherContext}
                />
            ) : null}
        </div>
    );
}
