import styled from 'styled-components';

export const AlignContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 640px) {
        width: 100%;
    }
    @media screen and (max-height: 780px) {
        width: 100%;
        height: 100%;
    }
`;

export const StyledSwitchTwoLabels = styled.div `
    margin-bottom: 15px;
    margin-top: 15px;
`;

export const Filters = styled.div `
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 30px;
    @media screen and (max-width: 650px) {
        display: block;
        flex-direction: colum;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        margin-bottom: 30px;
    } 
`;

export const StyledFilter = styled.div`
    width: 25vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 20px;
    margin-top: 15px;
    @media screen and (max-width: 1450px) {
        width: 40vw;
    }
    @media screen and (max-width: 1000px) {
        width: 50vw;
    }
    @media screen and (max-width: 830px) {
        justify-content: space-between;
    }
    @media screen and (max-width: 780px) {
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
`;

export const StyledSpan = styled.div`
    align-self: center;
    margin-right: 10px;
    @media screen and (max-width: 450px) {
        margin-top: 10px;
    }
`;

export const StyledButton = styled.div`
    display: flex;
    justify-content: center;
    padding: 20px 0px 10px 0px;
`;

export const StyledInput = styled.input`
    border: none;
    border-bottom: 1px solid #aaaaaa;
    font-size: 16px;
    height: 30px;
    width: 80px;
    text-align: center;
    background-color: ${({color}) => color===3 ? '#38761D' :
                                    color===2 ? '#3d9a15':
                                    color===1 ? '#00FF00':
                                    color===-1 ? '#FFFF00':
                                    color===-2 ? '#FF7800':
                                    color===-3 ? '#FF0000':
                                    null
    };
    &:focus {
        border: 1px solid #aaaaaa;
        outline: none;
    }
`;

export const Legend = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 15px;
`;

export const StyledContainerTitle = styled.div`
    display: flex;
    flex-direction: row;
 
`;

export const ContainerTitle = styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 780px) {
        margin-bottom: 20px;    
        align-items: center;
    }
    @media screen and (max-width: 410px) {
        font-size: 14px;
    }
    @media screen and (max-width: 330px) {
        font-size: 12px;
    }

`;
export const Title = styled.h2`
    margin-bottom: 8px;
    @media screen and (max-width: 580px) {
        font-size: 20px;
    }
    @media screen and (max-width: 410px) {
        font-size: 16px;
    }
    @media screen and (max-width: 340px) {
        font-size: 14px;
    }
`;

export const Stripe = styled.div`
    margin-top: 13px;
    margin-right: 13px;
    border-left:5px solid #4f4f4f;
    height: 65px;
    @media screen and (max-width: 780px){
        width: 0;
        height: 0;
        visibility: hidden;  
    }
`;

export const MonthBox = styled.div`
    border: 1px solid #bdbdbd;
    border-radius: 3px;
    font-size: 16px;
    padding: 15px;
`;

export const GlobalFilterContainer = styled.div `
    display: flex;
    justify-content: flex-end;
    padding-top: 12px;
    padding-right: 12px;
    @media screen and (max-width: 580px){
        margin: auto;
        justify-content: center;
    }
`;


export const TableCellData = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;
