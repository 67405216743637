/*React*/
import React from 'react';

/*Material UI*/
import HelpOutline from '@material-ui/icons/HelpOutline';
import { IconButton } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { CardMedia } from '@material-ui/core/';

import { makeStyles } from '@material-ui/core/styles';

/*Images*/
import AspectsIF from "../../assets/aspects-explanation-IF.svg";
import AlignmentsExplanation from "../../assets/send-alignments-explanation.svg"
import TasksExplanation from "../../assets/send-tasks-explanation.svg"
import ColorsAverage from '../../assets/colors-average.svg';

import { HelpStyled } from './help.styles';

const useStyles = makeStyles({
    media: {
      height: 270,
      width: 550
    },
  });

export default function Help(props) {
    const classes = useStyles();

    return (
        <HelpStyled>
            <IconButton aria-label="help" onClick={props.onClick}>
                <HelpOutline style={{width:30}}/>
            </IconButton>
            {props.help?
            <>
                <Dialog onClick={props.onClick} aria-labelledby="customized-dialog-title" open={true}>
                    <DialogTitle id="customized-dialog-title" onClick={props.onClick}>
                        {props.helpPopUp.title}
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            {props.helpPopUp.paragraph1}
                        </Typography>
                        {props.helpPopUp.paragraph2 ? 
                            <Typography gutterBottom>
                                {props.helpPopUp.paragraph2}
                            </Typography>
                        :null}
                        {props.helpPopUp.paragraph3 ? 
                            <Typography gutterBottom>
                                {props.helpPopUp.paragraph3}
                            </Typography>
                        :null}
                        {props.helpPopUp.paragraph4 ? 
                            <Typography gutterBottom>
                                {props.helpPopUp.paragraph4}
                            </Typography>
                        :null}
                        {props.helpPopUp.img ?
                            props.helpPopUp.img === "sendIF" ? 
                                <CardMedia
                                    className={classes.media}
                                    image={AspectsIF}
                                />
                            :
                            props.helpPopUp.img === "sendAlignments" ? 
                                <CardMedia
                                    className={classes.media}
                                    image={AlignmentsExplanation}
                                />
                            :
                            props.helpPopUp.img === "sendTasks" ? 
                                <CardMedia
                                    className={classes.media}
                                    image={TasksExplanation}
                                />
                            : 
                            props.helpPopUp.img === "averageDashboard" ? 
                            <CardMedia
                                className={classes.media}
                                image={ColorsAverage}
                            />
                            :null
                        :null}
                    </DialogContent>
                    <DialogActions>
                    <Button autoFocus onClick={props.onClick} color="primary">
                        Ok
                    </Button>
                    </DialogActions>
                </Dialog>
            </>
            : null}
        </HelpStyled>
    );
};
