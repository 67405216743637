import styled from 'styled-components/macro';
export const Container = styled.div`
    margin: 0px auto;
    font-family: Roboto;
    background-color: #fff;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px;
    max-width: 80vw;
    @media screen and (max-width: 700px) {
        max-width: 94vw;
    }
`;

export const ContainerRow = styled.div`
    border-bottom: 1px solid #e0e0e0;
    font-family: 'Roboto', 'sans-serif';
`;

export const Row1 = styled.div`
    font-family: 'Roboto', 'sans-serif';
    display: grid;
    font-family: 'Roboto';
    text-align: center;
    grid-template-areas: 'FirstColumn SecondColumn';
    grid-area: row1;
    padding-top: 5rem;
    @media (max-width: 1360px) {
        grid-template-areas:
            'FirstColumn'
            'SecondColumn';
    }
`;

export const FirstColumn = styled.div`
    display: grid;
    grid-area: FirstColumn;
    font-family: 'Roboto', 'sans-serif';
    align-self: left;
    @media (max-width: 470px) {
    }
`;
export const SecondColumn = styled.div`
    display: grid;
    grid-area: SecondColumn;
    font-family: 'Roboto', 'sans-serif';
    border-top: none;
    border-right: none;
    border-bottom: none;
`;

export const Unavailable = styled.div`
    font-family: 'Roboto', 'sans-serif';
    font-family: 'Roboto';
    font-style: normal;
    position: relative;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.03em;
    color: #f58989;
`;

export const Filled = styled.div`
    font-family: 'Roboto', 'sans-serif';
    font-family: 'Roboto';
    font-style: normal;
    position: relative;
    font-weight: 400;
    font-size: 24px;
    line-height: 21px;
    letter-spacing: 0.03em;
    color: #4f4f4f;
`;

export const Availability = styled.div`
    font-family: 'Roboto', 'sans-serif';
    font-family: 'Roboto';
    font-style: normal;
    position: relative;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.03em;
    color: rgb(79 175 103);
    background-color: rgb(214 214 214);
    transform: translateX(35%);
    width: max-content;
    padding: 5px;
    border-radius: 4px;
    @media (max-width: 1360px) {
        transform: translateX(0);
        margin: 0 auto;
    }
    @media (max-width: 470px) {
        transform: translateX(0);
        padding: 0px;
        font-size: 10px;
    }
`;

export const Observation = styled.div`
    font-family: 'Roboto', 'sans-serif';
    border-left: 4px solid #f58989;
    width: 382px;
    padding-left: 13px;
    font-family: 'Roboto';
    text-align: left;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    color: #4f4f4f;

    @media (max-width: 1360px) {
        margin: 0 auto;
        margin-top: 12px;
        text-align: center;
        border-left: 0px solid #f58989;
        border-top: 4px solid #f58989;
    }

    @media (max-width: 470px) {
        width: auto;
    }
`;

export const Row2 = styled.div`
    font-family: 'Roboto', 'sans-serif';
    text-align: center;
    margin-top: 70px;
    margin-bottom: 20px;
`;

export const Row2Closed = styled.div`
    font-family: 'Roboto', 'sans-serif';
    text-align: center;
    margin-bottom: 20px;
    padding-top: 20px;
`;

export const Row3 = styled.div`
    display: grid;
    grid-area: row3;
    grid-template-areas:
        'page page'
        'positivo negativo'
        'button button';
    grid-auto-columns: 30%;
    grid-gap: 20px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin-bottom: 2rem;
    @media (max-width: 980px) {
        grid-template-areas:
            'page page'
            'positivo negativo'
            'button button';
    }
    @media (max-width: 980px) {
        grid-template-areas:
            'page page'
            'positivo positivo'
            'negativo negativo'
            'button button';
    }
`;

export const SendButton = styled.div`
    display: grid;
    grid-area: button;
`;

export const PositiveButton = styled.div`
    display: grid;
    grid-area: positivo;
    transform: translateY(40px);
`;

export const NegativeButton = styled.div`
    display: grid;
    grid-area: negativo;
    transform: translateY(40px);
`;

export const Pagination = styled.div`
    display: flex;
    flex-direction: row;
    grid-area: page;
    font-size: 18px;
    width: 110%;
`;

export const PaginationGrid = styled.div`
    display: grid;
    grid-area: page;
`;

// Modal Novos Colaboradores

export const ContainerModal = styled.div`
    display: grid;
    justify-content: center;
    text-align: center;
    grid-template-areas:
        'Title'
        'Filters'
        'Colabs'
        'ButtonADD';
`;

export const Row1Modal = styled.div`
    display: grid;
    grid-area: Title;
`;

export const subtitle = styled.div`
    display: grid;
    grid-area: Title;
`;

export const Row2Modal = styled.div`
    display: grid;
    grid-area: Filters;
`;

export const Row3Modal = styled.div`
    display: grid;
    grid-area: Colabs;
    grid-template-columns: repeat(auto-fit, minmax(100px, auto));
`;

export const Row4Modal = styled.div`
    display: grid;
    justify-content: center;
    grid-area: ButtonADD;
`;
