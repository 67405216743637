import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Brightness1Icon from '@material-ui/icons/Brightness1';

/* Style */
import {
    StyledInput,
    ContainerButtons,
    Average,
    Switch,
} from './history-table.styles';

/* Components */
import SwitchTwoLabels from '../../../../components/switch/switch.component';

import {
    getNpsAvgFromDepartmentAttribute,
    getNpsAvgFromCompanyAttribute,
    getNpsAvgFromDepartment,
    getAllNpsAverageFromCompany,
} from '../../../../api/ApiNps';

/* Context */
import { authContext } from '../../../../contexts/AuthContext';
import { TableSortLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    paperContainer: {
        width: '80%',
        maxHeight: 300,
        margin: 'auto',
        overflow: 'auto',
    },
    paperModal: {
        '& .MuiFormLabel-root': {
            marginLeft: '2rem',
            fontSize: '1.5rem',
            fontWeight: '700',
        },
        position: 'relative',
        marginTop: '52px',
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        width: '50%',
        maxHeight: 650,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid grey',
        borderRadius: '10px',
        boxShadow: theme.shadows[5],
        padding: '1rem',
        fontFamily: 'Roboto',
        fontSize: '1rem',
        textAlign: 'center',
        '@media (max-width: 680px)': {
            transform: 'translate(-50%, -50%) scale(0.8)',
        },
    },
    titleEdit: {
        fontSize: '24px',
        fontWeight: 900,
        padding: '10px',
        color: '#444446',
        fontFamily: 'Roboto',
    },
    titleEdit2: {
        fontSize: '18px',
        fontWeight: 600,
        padding: '10px',
        color: '#444446',
        fontFamily: 'Roboto',
    },
    averageAttribute: {
        display: 'grid',
        alignContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        width: '4rem',
        color: 'white',
        height: '4rem',
        alignSelf: 'center',
        margin: 'auto',
        borderRadius: '8px',
    },
    button: {
        cursor: 'pointer',
        width: '10rem',
        fontWeight: 'bolder',
        borderRadius: '15px',
        padding: '15px',
        marginTop: '2rem',
        boxShadow: '0 5px 20px rgba(0, 0, 0, 0.4)',
        color: '#f01212',
        fontSize: '16px',
        textTransform: 'inherit',
        '@media (max-width: 680px)': {
            width: '15rem',
        },
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
      },
}));

const headCells = [
    {
        id: 'userStatus',
        label: 'Status',
        align: 'center',
        minWidth: 280,
    },
    {
        id: 'userName',
        label: 'Nome',
        align: 'center',
        minWidth: 280,
    },
    {
        id: 'userDepartment',
        label: 'Setor',
        align: 'center',
        minWidth: 280,
    },
    {
        id: 'score',
        label: 'Nota',
        align: 'right',
        minWidth: 220,
    },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort, keyWord } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            keyWord.mode === 'company' || headCell.id !== 'userDepartment' ?
            <TableCell
              key={headCell.id}
            //   align={headCell.numeric ? 'right' : 'left'}
            //   padding={headCell.disablePadding ? 'none' : 'normal'}
              align={headCell.align}
              sortDirection={orderBy === headCell.id ? order : false}
            >
                {headCell.id === 'score' ? (
                    <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                    >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                        ) : null}
                    </TableSortLabel>
                ): (
                  headCell.label
                )}
            </TableCell>:
            <></>
          ))}
        </TableRow>
      </TableHead>
    );
}

export default function HistTable({
    keyWord,
    setClose,
    company,
}) {
    const classes = useStyles();
    const [rows, setRows] = React.useState([]);
    const [overallAverage, setOverallAverage] = React.useState([]);
    const [searchText, setSearchText] = useState('');
    const [showInactiveUsers, setShowInactiveUsers] = useState(false);
    const [data, setData] = React.useState(rows);
    const [open, setOpen] = React.useState(true);
    const { auth } = useContext(authContext);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');

    const { department, year, nps } = keyWord;

    // handle change event of search input
    const handleChange = (value) => {
        setSearchText(value);
    };

    // exclude column list from filter
    let excludeColumns = [];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // filter records by search text
    useEffect(() => {
        let aux = '';
        const lowercasedValue = searchText.toLowerCase().trim();
        if (lowercasedValue === '') setRows(data);
        else {
            const filteredData = data.filter((item) => {
                return Object.keys(item).some((key) =>
                    excludeColumns.includes(key)
                        ? false
                        : item[key]
                        ? item[key]
                              .toString()
                              .toLowerCase()
                              .includes(lowercasedValue)
                        : aux.toString().toLowerCase().includes(lowercasedValue)
                );
            });
            setRows(filteredData);
        }
    }, [data, searchText])

    const handleClose = () => {
        setClose(false);
        setOpen(false);
    };

    useEffect(() => {
        const callApiGetNpsAverage = async () => {
            let response;
            if (keyWord.mode === 'department') {
                response = await getNpsAvgFromDepartment(
                    auth,
                    department.departmentId,
                    company.companyId,
                    nps?.npsId,
                    year
                );
            } else if (keyWord.mode === 'company') {
                response = await getAllNpsAverageFromCompany(
                    auth,
                    nps?.npsId,
                    year
                );
            }
            let { message, payload } = await response.json();
            if (response.status !== 200) Error(message);
            setOverallAverage(payload[0]);
        };
        try {
            callApiGetNpsAverage();
        } catch (err) {
            console.log(err);
        }
    }, [
        auth,
        company.companyId,
        department?.departmentId,
        keyWord.englishName,
        nps,
        year,
        keyWord.mode,
    ]);

    useEffect(() => {
        const callApiNpsAvgAttribute = async () => {
            let response;
            if (keyWord.mode === 'department') {
                response = await getNpsAvgFromDepartmentAttribute(
                    auth,
                    department.departmentId,
                    company.companyId,
                    nps?.npsId,
                    keyWord.englishName,
                    year,
                    showInactiveUsers?0:1,
                );
            } else if (keyWord.mode === 'company') {
                response = await getNpsAvgFromCompanyAttribute(
                    auth,
                    department?.departmentId,
                    company.companyId,
                    nps?.npsId,
                    keyWord.englishName,
                    year,
                    showInactiveUsers?0:1,
                );
            }
            let { message, payload } = await response.json();

            if (response.status !== 200) Error(message);

            setRows(payload);
            setData(payload);
            if(searchText !== '') handleChange(searchText);
        };
        try {
            callApiNpsAvgAttribute();
        } catch (err) {
            console.log(err);
        }
    }, [
        auth,
        company.companyId,
        department?.departmentId,
        keyWord.englishName,
        nps,
        year,
        keyWord.mode,
        showInactiveUsers,
    ]);

    let title;

    if (keyWord.mode === 'department') {
        title = 'Setor ' + department.name;
    } else if (keyWord.mode === 'company') {
        title = 'Drakkar Agricultura de Precisão';
    }

    const body = (
        <div className={classes.paperModal}>
            <h1 id="simple-modal-title" className={classes.titleEdit}>
                {title}
            </h1>
            <h2 id="simple-modal-title" className={classes.titleEdit2}>
                {/* {nps?.semester ?  } */}
                {nps === null || Object.keys(nps).length === 0 ? 
                    `${keyWord.name} ${year}`
                : `${keyWord.name} ${nps.semester} ${year}`
                }
            </h2>
            <ContainerButtons>
                <StyledInput
                    type="text"
                    placeholder="Digite para pesquisar..."
                    value={searchText}
                    onChange={(e) => handleChange(e.target.value)}
                />
                <Average
                    className={classes.averageAttribute}
                    style={{
                        backgroundColor:
                            overallAverage[keyWord.name] >= 75 &&
                            overallAverage[keyWord.name] <= 100
                                ? '#006400'
                                : overallAverage[keyWord.name] >= 50 &&
                                  overallAverage[keyWord.name] < 75
                                ? '#32CD32'
                                : overallAverage[keyWord.name] >= 0 &&
                                  overallAverage[keyWord.name] < 50
                                ? '#FFFF00'
                                : overallAverage[keyWord.name] >= -100 &&
                                  overallAverage[keyWord.name] < 0
                                ? '#FF0000'
                                : '',
                    }}
                >
                    {overallAverage[keyWord.name]}
                </Average>
                <Switch>
                    <SwitchTwoLabels
                        checkAction={setShowInactiveUsers}
                        rightWord="Mostrar usuários desativados"
                    />
                </Switch>
            </ContainerButtons>

            <p id="simple-modal-description"></p>
            <TableContainer
                className={classes.paperContainer}
                component={Paper}
            >
                <Table aria-label="collapsible table">
                    <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        keyWord={keyWord}
                    />
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                        .map((Row, index) => {
                            let statusColor;
                            if (Row.score >= 75 && Row.score <= 100) {
                                statusColor = 'greenDark';
                            } else if (Row.score >= 50 && Row.score < 75) {
                                statusColor = 'greenLight';
                            } else if (Row.score >= 0 && Row.score < 50) {
                                statusColor = 'yellow';
                            } else if (Row.score >= -100 && Row.score < 0) {
                                statusColor = 'red';
                            } else {
                                statusColor = '';
                            }
                            return (
                                <TableRow key={Row.name}>
                                    <TableCell
                                        className={classes.userStatus}
                                        align="center"
                                    >
                                        <Brightness1Icon
                                            style={{
                                                color:
                                                    statusColor === 'greenDark'
                                                        ? '#006400'
                                                        : statusColor ===
                                                          'greenLight'
                                                        ? '#32CD32'
                                                        : statusColor ===
                                                          'yellow'
                                                        ? '#FFFF00'
                                                        : statusColor === 'red'
                                                        ? '#FF0000'
                                                        : '',
                                            }}
                                        />
                                        {Row.status}
                                    </TableCell>
                                    <TableCell align="center">
                                        {Row.name}
                                    </TableCell>
                                    {keyWord.mode === 'company' &&
                                    <TableCell align="center">
                                        {Row.department}
                                    </TableCell>}
                                    <TableCell align="right">
                                        {Row.score}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <p>Colaboradores: {rows.length}</p>
        </div>
    );

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </>
    );
}
