import styled from 'styled-components/macro';

export const HelpStyled = styled.div `
    display: flex;
    margin: 0;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
`;
