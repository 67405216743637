import styled from 'styled-components';

export const Spacer = styled.div`
    height: 12vh;
    width: 12vh;
    @media screen and (max-height: 600px) {
        height: 70px;
    }
`;

export const DrawerAdjustment = styled.div`
    padding-left: 72px;
    @media screen and (max-width: 800px) {
        padding-left: 0;
    }
`;

export const TableContainer = styled.div`
    padding: 0px;
    background-color: white;
    border-bottom: 1px solid #cfd8dc;
    border-right: 1px solid #cfd8dc;
    border-left: 1px solid #cfd8dc;
    @media screen and (max-width: 650px) {
        padding: 18px;
    }
`;
