import { apiUrl } from './Api';

export function sendIF(data, auth) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = data.company;
    const user = data.user;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },

        body: JSON.stringify({
            date: data.date,
            professional: parseInt(data.professional),
            financial: parseInt(data.financial),
            personal: parseInt(data.personal),
            motivational: parseInt(data.motivational),
            health: parseInt(data.health),
            workEnviroment: parseInt(data.workEnviroment),
        }),
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/if`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getMatchDateIF(auth, dataUser, year, month, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany ? globalCompany : auth.data.user.companyId;
    const user =
        dataUser.userId == null ? auth.data.user.userId : dataUser.userId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/if?matchDate=${year}-${month.monthNumber}-01`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getUserAnnualIF(auth, year, actualUser, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany ? globalCompany : auth.data.user.companyId;
    year = parseInt(year);

    const user = actualUser ? actualUser.userId : auth.data.user.userId;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/if?startDate=${year}-01-01&endDate=${
            year + 1
        }-01-01`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getCompanyAnnualIF(auth, year, globalCompany) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany ? globalCompany : auth.data.user.companyId;
    year = parseInt(year);

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/if?startDate=${year}-01-01&endDate=${
            year + 1
        }-01-01`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function getDepartmentAnnualIF(
    auth,
    year,
    actualDepartment,
    globalCompany
) {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany ? globalCompany : auth.data.user.companyId;
    const department = actualDepartment
        ? actualDepartment.departmentId
        : auth.data.user.departmentId;
    year = parseInt(year);

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return fetch(
        `${apiUrl}/companies/${company}/departments/${department}/if?startDate=${year}-01-01&endDate=${
            year + 1
        }-01-01`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getActiveUnfilledIFUsers = async (auth, date, globalCompany) => {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany ? globalCompany : auth.data.user.companyId;
    const year = date.year;
    const month = date.month;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return await fetch(
        `${apiUrl}/companies/${company}/activeUnfilledUsers?matchDate=${year}-${month}-01`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getActiveUnfilledIFUsersFromDepartment = async (auth, date, globalCompany, departmentId) => {
    const authorization = `Bearer ${auth.data.token}`;
    const company = globalCompany ? globalCompany : auth.data.user.companyId;
    const year = date.year;
    const month = date.month;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };

    return await fetch(
        `${apiUrl}/companies/${company}/departments/${departmentId}/activeUnfilledUsers?matchDate=${year}-${month}-01`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function ifStatus(auth, user, company) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };
    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/if/ifStatus`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function findIfYears(auth, user, company) {
    const authorization = `Bearer ${auth.data.token}`;
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
        },
    };
    return fetch(
        `${apiUrl}/companies/${company}/users/${user}/if/findIfYears`,
        requestOptions
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
}
