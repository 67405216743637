import styled from 'styled-components/macro';

export const Data = styled.div`
    border-radius: 12px;
    background: #f6f4f4;
    padding: 12px;
    margin: 20px;
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-areas:
        'titledata datedata'
        'graph1 informations '
        'graph2 informations'
        'graph3 informations ';
    @media screen and (max-width: 1110px) {
        grid-template-columns: 20% 80%;
        grid-template-areas:
            'titledata datedata'
            'informations informations '
            'informations informations'
            'informations informations ';
    }
    @media screen and (max-width: 600px) {
        grid-template-columns: 20% 80%;
        grid-template-areas:
            'titledata titledata'
            'datedata datedata'
            'informations informations '
            'informations informations'
            'informations informations ';
    }
`;

export const Informations = styled.div`
    display: grid;
    grid-area: informations;
    grid-template-areas:
        'task'
        'alignmentData '
        'NPSData ';
`;

export const TitleData = styled.div`
    display: grid;
    grid-area: titledata;
    font-family: Abril Fatface;
    font-size: 46px;
    font-weight: 700;
    margin: 12px;
`;

export const DateStyle = styled.div`
    display: flex;
    grid-area: datedata;
    justify-content: end;
    margin-right: 40px;
    align-self: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 100;
    @media screen and (max-width: 600px) {
        justify-content: center;
        margin-right: 0px;
        padding: 20px;
    }
`;

export const TaskData = styled.div`
    display: grid;
    grid-area: task;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 100%;
    border-top: 1px solid #3e3939;
    justify-items: center;
    align-items: center;
    text-align: center;
    font-size: 24px;
    @media screen and (max-width: 850px) {
        grid-template-columns: 100%;
        grid-template-rows: 10% 30% 30% 30%;
    }
`;

export const GraphTask = styled.div`
    display: grid;
    grid-area: graph1;
    justify-items: center;
    align-items: center;
    height: 250px;
`;

export const TaskTitle = styled.div`
    position: absolute;
    z-index: 999;
    margin-top: -265px;
    width: 300px;
    background-color: #f6f4f4;
    font-family: Abril Fatface;
    font-size: 30px;
    font-weight: 700;
    @media screen and (max-width: 850px) {
        position: relative;
        margin-top: 20px;
    }
`;

export const TaskAverage1 = styled.div`
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 100;
    @media screen and (max-width: 850px) {
        padding: 80px;
    }
`;

export const TaskAverage2 = styled.div`
    display: flex;
    flex-direction: column;
    border-left: 1px solid #3e3939;
    border-right: 1px solid #3e3939;
    padding: 80px;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 100;
    @media screen and (max-width: 850px) {
        border-left: 0px solid #3e3939;
        border-right: 0px solid #3e3939;
        border-top: 1px solid #3e3939;
        border-bottom: 1px solid #3e3939;
        padding: 40px 80px;
    }
`;

export const TaskAverage3 = styled.div`
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 100;
    @media screen and (max-width: 850px) {
        padding: 80px;
    }
`;

export const AlingmentData = styled.div`
    display: grid;
    grid-area: alignmentData;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 100%;
    border-top: 1px solid #3e3939;
    border-bottom: 1px solid #3e3939;
    justify-items: center;
    align-items: center;
    text-align: center;
    font-size: 24px;
    @media screen and (max-width: 850px) {
        grid-template-columns: 100%;
        grid-template-rows: 10% 30% 30% 30%;
    }
`;

export const GraphAlignment = styled.div`
    display: grid;
    grid-area: graph2;
    justify-items: center;
    align-items: center;
    height: 250px;
`;

export const AlignmentAverage1 = styled.div`
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 100;
    @media screen and (max-width: 850px) {
        padding: 80px;
    }
`;

export const AlignmentAverage2 = styled.div`
    border-left: 1px solid #3e3939;
    border-right: 1px solid #3e3939;
    padding: 80px;
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 100;
    @media screen and (max-width: 850px) {
        border-left: 0px solid #3e3939;
        border-right: 0px solid #3e3939;
        border-top: 1px solid #3e3939;
        border-bottom: 1px solid #3e3939;
        padding: 40px 80px;
    }
`;

export const AlignmentTitle = styled.div`
    position: absolute;
    z-index: 999;
    margin-top: -260px;
    width: 300px;
    background-color: #f6f4f4;
    background-color: #f6f4f4;
    font-family: Abril Fatface;
    font-size: 30px;
    font-weight: 700;
    @media screen and (max-width: 850px) {
        position: relative;
        margin-top: 20px;
    }
`;

export const AlignmentAverage3 = styled.div`
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 100;
    @media screen and (max-width: 850px) {
        padding: 80px;
    }
`;

export const NpsData = styled.div`
    display: grid;
    grid-area: NPSData;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 100%;
    justify-items: center;
    align-items: center;
    text-align: center;
    font-size: 24px;
    @media screen and (max-width: 850px) {
        grid-template-columns: 100%;
        grid-template-rows: 10% 30% 30% 30%;
    }
`;

export const GraphNPS = styled.div`
    display: grid;
    grid-area: graph3;
    justify-items: center;
    align-items: center;
    height: 250px;
`;

export const NPSAverage1 = styled.div`
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 100;
    @media screen and (max-width: 850px) {
        padding: 80px;
    }
`;

export const NPSAverage2 = styled.div`
    border-left: 1px solid #3e3939;
    border-right: 1px solid #3e3939;
    padding: 80px;
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 100;
    @media screen and (max-width: 850px) {
        border-left: 0px solid #3e3939;
        border-right: 0px solid #3e3939;
        border-top: 1px solid #3e3939;
        border-bottom: 1px solid #3e3939;
        padding: 40px 80px;
    }
`;

export const NPSAverage3 = styled.div`
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 100;
    @media screen and (max-width: 850px) {
        padding: 80px;
    }
`;

export const NPSTitle = styled.div`
    position: absolute;
    z-index: 999;
    margin-top: -250px;
    width: 300px;
    background-color: #f6f4f4;
    background-color: #f6f4f4;
    font-family: Abril Fatface;
    font-size: 30px;
    font-weight: 700;
    @media screen and (max-width: 850px) {
        position: relative;
        margin-top: 20px;
    }
`;
