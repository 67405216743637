import React, {useContext, useEffect, useState} from "react";

/* Components */
import PopUp from "../../../../../components/popup/popup.component";

/* Material UI */
import EnhancedTable from "../../../../../components/table-to-show-data/table-to-show-data.component";
import CustomButton from "../../../../../components/custom-button/custom-button.component";

/* Context */
import {authContext} from "../../../../../contexts/AuthContext";

/* Api*/
import {
    deleteTasksFromUser,
    findAllActiveUserTasks
} from "../../../../../api/ApiTasks";

export default function DeleteTaskFromUser({
    user,
    companyId,
    category,
    tasksByUserRows,
    setTaskByUserRows,
    setTasksByUserData,
    toggleButton,
    selectedTasks,
    setSelectedTasks,
    handleClick,
    isSelected
}) {
    const {auth} = useContext(authContext);

    // const [tasksByUserRows, setTaskByUserRows] = useState([]);
    const [reloadTable, setReloadTable] = useState(false);
    const [buttonPopUp, setButtonPopUp] = useState({
        popUp: false,
        popUpTitle: '',
        popUpText: '',
        success: 1,
    });
    const [tableTitle, setTableTitle] = useState({});

    const tasksByUserHeadCells = [
        {
            id: 'taskId',
            numeric: false,
            disablePadding: false,
            label: 'Código',
        },
        { id: 'name', numeric: false, disablePadding: false, label: 'Nome' },
        {
            id: 'description',
            numeric: false,
            disablePadding: false,
            label: 'Descrição',
        },
        {
            id: 'categoryName',
            numeric: false,
            disablePadding: false,
            label: 'Categoria',
        },
        { id: 'action', numeric: true, disablePadding: false, label: 'Ação' },
    ];

    useEffect(() => {
        const callApiFindOneUserTasks = async () => {
            const response = await findAllActiveUserTasks(
                auth,
                companyId,
                user
            );
            let { payload } = await response.json();

            if(category) {
                payload = payload.filter(task => task.categoryId === category.categoryId);
            }

            payload.forEach(task => {
                delete task.companyId;
                delete task.categoryId;
                delete task.active
                task.action = 1;
            });
            setTaskByUserRows(payload);
            setTasksByUserData(payload);
        };

        try {
            if(user)
                callApiFindOneUserTasks();
        } catch (error) {   
            console.log(error);
        }
    }, [user, companyId, category, reloadTable, toggleButton]);

    useEffect(() => {
        setTableTitle({
            firstText: 'Deletando tarefas de ',
            warningText: user ? user.name : '',
        })
    }, [user]);

    const handleDeleteTasks = () => {
        if(user) {
            setButtonPopUp({
                popUp: true,
                popUpTitle: 'Deletando tarefas',
                popUpText: `Deseja mesmo deletar as tarefas selecionadas de ${user ? user.name : ''}?`,
                success: 3,
                acceptable: 1,
                first: true,
                second: false,
            });
        } else {
            setButtonPopUp({
                popUp: true,
                popUpTitle: 'Erro',
                popUpText: 'Por favor, selecione um usuário',
                success: 3,
            });
        }
    };

    const deleteTasks = async () => {

        let data = {
            user: user.userId,
            company: companyId,
            tasks: selectedTasks,
        };

        try {
            let response = await deleteTasksFromUser(data, auth);
            
            setSelectedTasks([]);

            if (response.status === 204) {
                setButtonPopUp({
                    popUp: true,
                    popUpTitle: 'Sucesso',
                    popUpText: `Tarefa(s) deletada(s) de ${user ? user.name : ''}`,
                    success: 3,
                });
            } else {
                setButtonPopUp({
                    popUp: true,
                    popUpTitle: 'Erro',
                    popUpText: `Não foi possível deletar as tarefa de ${
                        user ? user.name : ''
                    }.`,
                    success: 3,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleClose = () => {
        setButtonPopUp({
            popUp: false,
            popUpTitle: '',
            popUpText: '',
            success: 0,
        });
    };

    const setNewFatherContext = () => {
        setReloadTable(!reloadTable)
    };

    return (
        <>
            <div>
                <EnhancedTable
                    rows={tasksByUserRows}
                    headCells={tasksByUserHeadCells}
                    title={tableTitle}
                    checkbox={true}
                    handleClick={handleClick}
                    isSelected={isSelected}
                />
            </div>
            <div style={{display: 'flex', justifyContent: 'right'}}>
                <CustomButton
                    type="button"
                    onClick={handleDeleteTasks}
                    deleteButton
                    style={
                        selectedTasks.length === 0 || 
                        !user ? 
                        { marginLeft: 10, opacity: 0.2 }:
                        { marginLeft: 10, opacity: 1 }
                    }
                    disabled={
                        selectedTasks.length === 0 || 
                        !user ? true: false}
                >
                    {' '}
                    Deletar Tarefas{' '}
                </CustomButton>
            </div>
            { buttonPopUp.popUp ?
                <PopUp
                    title={buttonPopUp.popUpTitle}
                    string={buttonPopUp.popUpText}
                    success={buttonPopUp.success}
                    acceptable={buttonPopUp.acceptable}
                    acceptFunction={deleteTasks}
                    handleClose={handleClose}
                    route={buttonPopUp.route}
                    setNewFatherContext={setNewFatherContext}
                />
            : null}
        </>
    );
}