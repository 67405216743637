/* Generic table to show int values, with the respective color, like "tasks history" tab */
import React from 'react';

/* Material UI */
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

/* Style */
import { AlignContainer, TableData } from './table-to-show-value.styles';

/* Functions */
import { getColor } from '../../services/historyFunctions';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#eeeeee',
        color: theme.palette.common.white,
        fontSize: '16px',
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 0,
        paddingRight: 0,
        '@media (max-width: 1250px)': {
            //padding: 16,
        },
    },
    body: {
        padding: 8,
        fontSize: 16,
    },
}))(TableCell);

const StyledTableMonthCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#eeeeee',
        color: theme.palette.common.white,
        fontSize: '16px',
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 8,
        paddingRight: 8,
        '@media (max-width: 1250px)': {
            paddingLeft: 6,
            paddingRight: 6,
        },
    },
    body: {
        padding: 8,
        fontSize: 16,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    table: {
        minWidth: 200,
        overflow: 'hidden',
    },
    colWidth: {
        color: 'black',
    },
}));

export default function TableToShowValue(props) {
    const classes = useStyles();

    return (
        <Container maxWidth="lg">
            <AlignContainer>
                <TableData>
                    {props.tableData.componentTitleStart}
                    <b>{props.tableData.componentTitleFirstInfo}</b>{' '}
                    {props.tableData.componentTitleMiddle}
                    <b>{props.tableData.componentTitleSecondInfo}</b>{' '}
                    {props.tableData.componentTitleSecondMiddle}
                    <b>{props.tableData.componentTitleThirdInfo}</b>
                </TableData>

                <TableContainer component={Paper}>
                    <Table
                        className={classes.table}
                        aria-label="customized table"
                    >
                        <TableHead>
                            <TableRow>
                                {props.tasksHistory ? (
                                    <StyledTableCell
                                        className={classes.colWidth}
                                        style={{
                                            backgroundColor: '#9e9e9e',
                                            borderStyle: 'solid',
                                            borderWidth: '0px',
                                            borderRightWidth: '1px',
                                            borderColor: '#e0e0e0',
                                        }}
                                        align="center"
                                    >
                                        {props.tableData.taskId}
                                    </StyledTableCell>
                                ) : null}

                                <StyledTableCell
                                    className={classes.colWidth}
                                    style={{ backgroundColor: '#9e9e9e' }}
                                    align="center"
                                >
                                    {props.tableData.tableTitle}
                                </StyledTableCell>

                                {props.rows.map((row, index) => (
                                    <StyledTableMonthCell
                                        key={index}
                                        style={{
                                            borderBottom: '1px solid #c0c0c0',
                                        }}
                                        className={classes.colWidth}
                                        align="center"
                                    >
                                        {row.name}
                                    </StyledTableMonthCell>
                                ))}

                                {props.tasksHistory ||
                                (props.alignHistory &&
                                    (props.userPermission === 1 ||
                                        props.userPermission === 10)) ? (
                                    <StyledTableCell
                                        className={classes.colWidth}
                                        style={{
                                            backgroundColor: '#9e9e9e',
                                            borderStyle: 'solid',
                                            borderWidth: '0px',
                                            borderRightWidth: '1px',
                                            borderColor: '#e0e0e0',
                                        }}
                                        align="center"
                                    >
                                        Anual
                                    </StyledTableCell>
                                ) : null}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {props.rowsName.map((row, indexFirst) =>
                                // if prop not passed                  if switch is turned on         if switch is turned off
                                props.showNotActived === undefined ||
                                row.active === undefined ||
                                !!props.showNotActived ||
                                (row.active && !props.showNotActived) ? (
                                    <StyledTableRow key={indexFirst}>
                                        {props.tasksHistory ? (
                                            <StyledTableCell
                                                style={{
                                                    backgroundColor: '#cccccc',
                                                }}
                                                component="th"
                                                align="center"
                                                scope="row"
                                            >
                                                {props.rowsNameId.filter(
                                                    (rowNameId) =>
                                                        rowNameId.name ===
                                                        row.name
                                                )[0]
                                                    ? props.rowsNameId.filter(
                                                          (rowNameId) =>
                                                              rowNameId.name ===
                                                              row.name
                                                      )[0].taskId
                                                    : null}
                                            </StyledTableCell>
                                        ) : null}

                                        {row.name === 'Média da Empresa' &&
                                        indexFirst === 7 &&
                                        props.radioValue ===
                                            'company' ? null : row.name ===
                                              'Média Individual' ||
                                          row.name === 'Média da Empresa' ||
                                          row.name === 'Média do Setor' ? (
                                            <StyledTableCell
                                                style={{
                                                    backgroundColor: '#9e9e9e',
                                                    border: 'solid 1px #8a8a8a',
                                                }}
                                                component="th"
                                                scope="row"
                                            >
                                                {row.name}
                                            </StyledTableCell>
                                        ) : (
                                            <StyledTableCell
                                                style={{
                                                    backgroundColor:
                                                        row.active === 0
                                                            ? '#e3e3e3'
                                                            : null,
                                                }}
                                                component="th"
                                                scope="row"
                                            >
                                                {row.name}
                                            </StyledTableCell>
                                        )}

                                        {props.rows.map((rows, index) =>
                                            row.name === 'Média da Empresa' &&
                                            indexFirst === 7 &&
                                            props.radioValue ===
                                                'company' ? null : row.name ===
                                                  'Média Individual' ||
                                              row.name === 'Média da Empresa' ||
                                              row.name === 'Média do Setor' ? (
                                                <StyledTableCell
                                                    style={{
                                                        border: 'solid 1px #8a8a8a',
                                                        backgroundColor:
                                                            '#9e9e9e',
                                                    }}
                                                    key={index}
                                                    align="center"
                                                >
                                                    {row.color}
                                                    <div
                                                        style={{
                                                            fontSize: '18px',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {rows.data[indexFirst]
                                                            ?.value === null ||
                                                        isNaN(
                                                            rows.data[
                                                                indexFirst
                                                            ]?.value
                                                        )
                                                            ? ''
                                                            : rows.data[
                                                                  indexFirst
                                                              ]?.value}
                                                    </div>
                                                </StyledTableCell>
                                            ) : rows.data[indexFirst] !==
                                                  undefined &&
                                              rows.data[indexFirst]?.color ? (
                                                <StyledTableCell
                                                    style={{
                                                        border: 'solid 1px #b0acac',
                                                        backgroundColor:
                                                            rows.data[
                                                                indexFirst
                                                            ]?.color,
                                                    }}
                                                    key={index}
                                                    align="center"
                                                >
                                                    <div
                                                        style={{
                                                            fontSize: '18px',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {rows.data[indexFirst]
                                                            .value === null ||
                                                        isNaN(
                                                            rows.data[
                                                                indexFirst
                                                            ].value
                                                        )
                                                            ? ''
                                                            : rows.data[
                                                                  indexFirst
                                                              ].value}
                                                    </div>
                                                </StyledTableCell>
                                            ) : (
                                                <StyledTableCell
                                                    style={{
                                                        borderBottom:
                                                            rows.data[
                                                                indexFirst
                                                            ]?.active === 0 &&
                                                            rows.data[
                                                                indexFirst
                                                            ].value === null
                                                                ? '#bdbdbd'
                                                                : !props.justTheColor
                                                                ? getColor(
                                                                      rows.data[
                                                                          indexFirst
                                                                      ]
                                                                          ? rows
                                                                                .data[
                                                                                indexFirst
                                                                            ]
                                                                                .value
                                                                          : null,
                                                                      2
                                                                  )
                                                                : props.justTheColor(
                                                                      rows.data[
                                                                          indexFirst
                                                                      ]
                                                                          ? rows
                                                                                .data[
                                                                                indexFirst
                                                                            ]
                                                                                .value
                                                                          : null,
                                                                      2
                                                                  ),
                                                        borderTop:
                                                            !props.justTheColor
                                                                ? getColor(
                                                                      rows.data[
                                                                          indexFirst
                                                                      ]
                                                                          ? rows
                                                                                .data[
                                                                                indexFirst
                                                                            ]
                                                                                .value
                                                                          : null,
                                                                      2
                                                                  )
                                                                : props.justTheColor(
                                                                      rows.data[
                                                                          indexFirst
                                                                      ]
                                                                          ? rows
                                                                                .data[
                                                                                indexFirst
                                                                            ]
                                                                                .value
                                                                          : null,
                                                                      2
                                                                  ),
                                                        backgroundColor:
                                                            rows.data[
                                                                indexFirst
                                                            ]?.active === 0 &&
                                                            rows.data[
                                                                indexFirst
                                                            ].value === null
                                                                ? '#e3e3e3'
                                                                : !props.justTheColor
                                                                ? getColor(
                                                                      rows.data[
                                                                          indexFirst
                                                                      ]
                                                                          ? rows
                                                                                .data[
                                                                                indexFirst
                                                                            ]
                                                                                .value
                                                                          : null,
                                                                      1
                                                                  )
                                                                : props.justTheColor(
                                                                      rows.data[
                                                                          indexFirst
                                                                      ]
                                                                          ? rows
                                                                                .data[
                                                                                indexFirst
                                                                            ]
                                                                                .value
                                                                          : null,
                                                                      1
                                                                  ),
                                                    }}
                                                    key={index}
                                                    align="center"
                                                >
                                                    <div
                                                        style={{
                                                            fontSize: '18px',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {props.justTheColor &&
                                                        rows.data[indexFirst] &&
                                                        rows.data[
                                                            indexFirst
                                                        ] !== null &&
                                                        !isNaN(
                                                            rows.data[
                                                                indexFirst
                                                            ].value
                                                        ) &&
                                                        rows.data[indexFirst]
                                                            .value === 0
                                                            ? rows.data[
                                                                  indexFirst
                                                              ].grades[0]
                                                            : !props.justTheColor &&
                                                              rows.data[
                                                                  indexFirst
                                                              ] &&
                                                              rows.data[
                                                                  indexFirst
                                                              ] !== null &&
                                                              !isNaN(
                                                                  rows.data[
                                                                      indexFirst
                                                                  ].value
                                                              )
                                                            ? rows.data[
                                                                  indexFirst
                                                              ].value
                                                            : null}
                                                    </div>
                                                </StyledTableCell>
                                            )
                                        )}

                                        {props.tasksHistory ? (
                                            <StyledTableCell
                                                className={classes.colWidth}
                                                align="center"
                                                style={{
                                                    backgroundColor: props
                                                        .yearAverage[indexFirst]
                                                        ? getColor(
                                                              props.yearAverage[
                                                                  indexFirst
                                                              ]
                                                                  ? props
                                                                        .yearAverage[
                                                                        indexFirst
                                                                    ]
                                                                  : null,
                                                              1
                                                          )
                                                        : '#9e9e9e',
                                                    borderBottom: getColor(
                                                        props.yearAverage[
                                                            indexFirst
                                                        ]
                                                            ? props.yearAverage[
                                                                  indexFirst
                                                              ]
                                                            : null,
                                                        2
                                                    ),
                                                    borderTop: getColor(
                                                        props.yearAverage[
                                                            indexFirst
                                                        ]
                                                            ? props.yearAverage[
                                                                  indexFirst
                                                              ]
                                                            : null,
                                                        2
                                                    ),
                                                    fontSize: '18px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {props.yearAverage[indexFirst]}
                                            </StyledTableCell>
                                        ) : props.alignHistory &&
                                          (props.userPermission === 1 ||
                                              props.userPermission === 10) &&
                                          props.allYearAverage[0]?.average &&
                                          props.allYearAverage[0].name ? (
                                            <StyledTableCell
                                                className={classes.colWidth}
                                                align="center"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {row
                                                    ? props.allYearAverage.map(
                                                          (current) =>
                                                              current.name ===
                                                              row.name
                                                                  ? current.average
                                                                  : null
                                                      )
                                                    : null}
                                            </StyledTableCell>
                                        ) : null}
                                    </StyledTableRow>
                                ) : null
                            )}
                            <StyledTableRow>
                                {props.tasksHistory ? (
                                    <>
                                        <StyledTableCell
                                            className={classes.colWidth}
                                            style={{
                                                backgroundColor: '#9e9e9e',
                                            }}
                                            align="center"
                                        />
                                        <StyledTableCell
                                            //Média Mensal das tarefas
                                            className={classes.colWidth}
                                            style={{
                                                backgroundColor: '#9e9e9e',
                                            }}
                                            align="center"
                                        >
                                            {' '}
                                            Média Mensal{' '}
                                        </StyledTableCell>
                                    </>
                                ) : props.alignHistory &&
                                  (props.userPermission === 1 ||
                                      props.userPermission === 10) ? (
                                    <>
                                        <StyledTableCell
                                            //Média Mensal dos alinhamentos
                                            className={classes.colWidth}
                                            style={{
                                                backgroundColor: '#9e9e9e',
                                            }}
                                            align="center"
                                        >
                                            {' '}
                                            Média Mensal ( -3 à 3 ){' '}
                                        </StyledTableCell>

                                        {props.alignHistory
                                            ? props.monthAverage.map(
                                                  (
                                                      month,
                                                      indexMonthAverage
                                                  ) => {
                                                      return (
                                                          <>
                                                              <StyledTableCell
                                                                  className={
                                                                      classes.colWidth
                                                                  }
                                                                  key={
                                                                      'lastLine' +
                                                                      indexMonthAverage
                                                                  }
                                                                  align="center"
                                                                  style={{
                                                                      backgroundColor:
                                                                          month
                                                                              ? getColor(
                                                                                    month.average !==
                                                                                        null
                                                                                        ? month.average
                                                                                        : null,
                                                                                    1
                                                                                )
                                                                              : '#9e9e9e',
                                                                      fontSize:
                                                                          '18px',
                                                                      fontWeight:
                                                                          'bold',
                                                                  }}
                                                              >
                                                                  {month.average !==
                                                                  null
                                                                      ? month.average.toFixed(
                                                                            0
                                                                        )
                                                                      : null}
                                                              </StyledTableCell>
                                                          </>
                                                      );
                                                  }
                                              )
                                            : null}
                                    </>
                                ) : null}

                                {props.tasksHistory
                                    ? props.monthAverage.map(
                                          (
                                              monthAverageGrade,
                                              indexMonthAverage
                                          ) => {
                                              return (
                                                  <StyledTableCell
                                                      className={
                                                          classes.colWidth
                                                      }
                                                      key={
                                                          'lastLine' +
                                                          indexMonthAverage
                                                      }
                                                      align="center"
                                                      style={{
                                                          backgroundColor:
                                                              monthAverageGrade
                                                                  ? getColor(
                                                                        monthAverageGrade
                                                                            ? monthAverageGrade
                                                                            : null,
                                                                        1
                                                                    )
                                                                  : '#9e9e9e',
                                                          fontSize: '18px',
                                                          fontWeight: 'bold',
                                                      }}
                                                  >
                                                      {monthAverageGrade !== 0
                                                          ? monthAverageGrade.toFixed(
                                                                0
                                                            )
                                                          : null}
                                                  </StyledTableCell>
                                              );
                                          }
                                      )
                                    : null}

                                {props.tasksHistory ? (
                                    <StyledTableCell
                                        className={classes.colWidth}
                                        align="center"
                                        style={{
                                            backgroundColor: '#111',
                                            color: '#EEE',
                                            fontSize: '18px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {props.allYearAverage}
                                    </StyledTableCell>
                                ) : props.alignHistory &&
                                  (props.userPermission === 1 ||
                                      props.userPermission === 10) ? (
                                    <StyledTableCell
                                        className={classes.colWidth}
                                        align="center"
                                        style={{
                                            backgroundColor: '#111',
                                            color: '#EEE',
                                            fontSize: '18px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {props.allYearsAverage}
                                    </StyledTableCell>
                                ) : null}
                            </StyledTableRow>

                            <StyledTableRow>
                                {props.tasksHistory ? (
                                    <>
                                        <StyledTableCell
                                            className={classes.colWidth}
                                            style={{
                                                backgroundColor: '#9e9e9e',
                                            }}
                                            align="center"
                                        />
                                        <StyledTableCell
                                            //Média Mensal das tarefas
                                            className={classes.colWidth}
                                            style={{
                                                backgroundColor: '#9e9e9e',
                                            }}
                                            align="center"
                                        >
                                            {' '}
                                            Média Mensal ( -3 à 3 ){' '}
                                        </StyledTableCell>
                                    </>
                                ) : props.alignHistory &&
                                  (props.userPermission === 1 ||
                                      props.userPermission === 10) ? (
                                    <>
                                        <StyledTableCell
                                            //Média Mensal dos alinhamentos
                                            className={classes.colWidth}
                                            style={{
                                                backgroundColor: '#9e9e9e',
                                            }}
                                            align="center"
                                        >
                                            {' '}
                                            Média Mensal{' '}
                                        </StyledTableCell>

                                        {props.alignHistory
                                            ? props.monthAverage.map(
                                                  (
                                                      month,
                                                      indexMonthAverage
                                                  ) => {
                                                      return (
                                                          <>
                                                              <StyledTableCell
                                                                  className={
                                                                      classes.colWidth
                                                                  }
                                                                  key={
                                                                      'lastLine' +
                                                                      indexMonthAverage
                                                                  }
                                                                  align="center"
                                                                  style={{
                                                                      backgroundColor:
                                                                          month
                                                                              ? getColor(
                                                                                    month.average !==
                                                                                        null
                                                                                        ? month.average
                                                                                        : null,
                                                                                    1
                                                                                )
                                                                              : '#9e9e9e',
                                                                      fontSize:
                                                                          '18px',
                                                                      fontWeight:
                                                                          'bold',
                                                                  }}
                                                              >
                                                                  {month.average !==
                                                                  null
                                                                      ? (
                                                                            month.average *
                                                                            33.33
                                                                        ).toFixed(
                                                                            0
                                                                        )
                                                                      : null}
                                                              </StyledTableCell>
                                                          </>
                                                      );
                                                  }
                                              )
                                            : null}
                                    </>
                                ) : null}

                                {props.tasksHistory
                                    ? props.monthAverage.map(
                                          (
                                              monthAverageGrade,
                                              indexMonthAverage
                                          ) => {
                                              return (
                                                  <StyledTableCell
                                                      className={
                                                          classes.colWidth
                                                      }
                                                      key={
                                                          'lastLine' +
                                                          indexMonthAverage
                                                      }
                                                      align="center"
                                                      style={{
                                                          backgroundColor:
                                                              monthAverageGrade
                                                                  ? getColor(
                                                                        monthAverageGrade
                                                                            ? monthAverageGrade
                                                                            : null,
                                                                        1
                                                                    )
                                                                  : '#9e9e9e',
                                                          fontSize: '18px',
                                                          fontWeight: 'bold',
                                                      }}
                                                  >
                                                      {monthAverageGrade !== 0
                                                          ? (
                                                                monthAverageGrade /
                                                                33.33
                                                            ).toFixed(0)
                                                          : null}
                                                  </StyledTableCell>
                                              );
                                          }
                                      )
                                    : null}
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </AlignContainer>
        </Container>
    );
}
