import React, { useEffect } from 'react';
import {
    LoginContainer,
    LeftContainer,
    RightContainer,
    CharacterImage,
    CompanyTitle,
    LogoDrakkar,
} from './sign-in.styles';
import IpaIfCharacter from '../../assets/CharacterIPA-IF.png';
import Logo from '../../assets/logo.png';
import SignInForm from './signInForm/sign-in-form.component';
import Container from '@material-ui/core/Container';

export default function NewSignInPage() {
    useEffect(() => {
        document.body.style.background =
            'linear-gradient(90deg, rgba(255,117,23,0.2) 1%, rgba(255,242,0,0.2) 100%)';

        // Specify how to clean up after this effect:
        return function cleanup() {
            document.body.style.backgroundImage = 'none';
        };
    }, []);

    return (
        <Container>
            <LoginContainer>
                <LeftContainer>
                    <CompanyTitle>
                        <LogoDrakkar src={Logo} alt="Logo Drakkar" />
                        Drakkar Agricultura de Precisão
                    </CompanyTitle>
                    <CharacterImage
                        src={IpaIfCharacter}
                        alt="Personagens IPA IF Login"
                    />
                </LeftContainer>
                <RightContainer>
                    <SignInForm systemType={true} />
                </RightContainer>
            </LoginContainer>
        </Container>
    );
}
