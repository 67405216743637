import React from 'react';
import domtoimage from "dom-to-image";
import jsPDF from "jspdf";
import drakkarHeader from '../../assets/drakkar-header.png'
import {AlignButton} from '../../pages/ipa/ipa-graphs/ipa-graphs-general/ipa-graphs-general.styles';
import CustomButton from '../custom-button/custom-button.component';
import { ReactComponent as PDF } from '../../assets/pdf.svg';

const makePDF = (node, name, coords) =>// this function gets a html node "node", a string "name" for the pdf file, an array "coords" for plotting the element on the page, and downloads it as pdf
{
    
    domtoimage.toPng(node).then(function (dataUrl) {
        let header = new Image();
        header.src = drakkarHeader
        

        let body = new Image();
        body.src = dataUrl;
        
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4',
        });
        pdf.addImage(header, 'PNG', 5, 5, 200, 35, '');//195x150mm
        pdf.addImage(body, 'PNG', ...coords, '');
        
        pdf.save(name + '.pdf');
    });
}

const GenPDF = ({node, name, coords}) => (
    <AlignButton>
        <CustomButton onClick={() => {makePDF(node,name,coords)}} pdfButton>
            <PDF
                style={{
                    width: 25,
                    height: 25,
                    marginRight: '.7em',
                }}
            />
            <span style={{whiteSpace:"nowrap"}}>Gerar PDF</span>
        </CustomButton>
    </AlignButton>
);

export default GenPDF;