import React, { useContext, useEffect, useState } from 'react';

/* Material UI */
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

/* Components */
import CustomButton from '../../../../components/custom-button/custom-button.component';
import PopUp from '../../../../components/popup/popup.component';
import RenderPage from '../../../../components/render-page/render-page.component';
import SwitchTwoLabels from '../../../../components/switch/switch.component';
import GlobalCompanyFilter from '../../../../components/globalFilter/globalFilter.component';
import Help from '../../../../components/help/help.component';
import { helpSendIf } from '../../../../components/help/help.texts';

/* Style */
import {
    AlignContainer,
    StyledFilter,
    StyledButton,
    Legend,
    StyledSpan,
    Title,
    ContainerTitle,
    StyledContainerTitle,
    Stripe,
    MonthBox,
    TableCellData,
    Filters,
    StyledSwitchTwoLabels,
    GlobalFilterContainer,
} from './if-monthly-table.styles';

/* API */
import { getMatchDateIF, sendIF } from '../../../../api/ApiIF';
import { getOneUser } from '../../../../api/ApiUser';
import { getAllUsersInCompany } from '../../../../api/ApiUser';
import { getOneCompany } from '../../../../api/ApiCompany';

/* Context */
import { authContext } from '../../../../contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import { getInternetError } from '../../../../utils/genericFunctions';

import { yearsUntilNow } from '../../../../utils/initialData';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#eeeeee',
        color: theme.palette.common.white,
        fontSize: '16px',
    },
    body: {
        fontSize: 16,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 100
    },
    table: {
        minWidth: 200,
        width: '25vw',
        '@media (max-width: 1450px)': {
            width: '40vw',
        },
        '@media (max-width: 1000px)': {
            width: '50vw',
        },
    },
    colWidth: {
        width: 'calc(100%/2)',
        color: 'black',
    },
    autocomplete: {
        margin: theme.spacing(1),
        minWidth: 170,
    },
}));

const today = new Date();

const options = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
];

const numberValues = ['3', '2', '1', '-1', '-2', '-3'];

const numberValuesReverse = ['-3', '-2', '-1', '1', '2', '3'];

const optionsMonths = [
    { name: 'Janeiro', monthNumber: '01' },
    { name: 'Fevereiro', monthNumber: '02' },
    { name: 'Março', monthNumber: '03' },
    { name: 'Abril', monthNumber: '04' },
    { name: 'Maio', monthNumber: '05' },
    { name: 'Junho', monthNumber: '06' },
    { name: 'Julho', monthNumber: '07' },
    { name: 'Agosto', monthNumber: '08' },
    { name: 'Setembro', monthNumber: '09' },
    { name: 'Outubro', monthNumber: '10' },
    { name: 'Novembro', monthNumber: '11' },
    { name: 'Dezembro', monthNumber: '12' },
];
const optionsYear = yearsUntilNow();

export default function CustomizedTables(props) {

    const classes = useStyles();

    let datePosition;
    if(today.getMonth() === 0) {
        datePosition = 11;
    } else {
        datePosition = (today.getMonth()-1);
    }

    let yearOfLastMonth;

    if(today.getMonth() === 0) {
        yearOfLastMonth = new Date().toISOString();
        yearOfLastMonth = yearOfLastMonth.split('-');
        yearOfLastMonth = yearOfLastMonth[0];
        yearOfLastMonth = (parseInt(yearOfLastMonth) - 1);

    } else {
        yearOfLastMonth = new Date().toISOString();
        yearOfLastMonth = yearOfLastMonth.split('-');
        yearOfLastMonth = yearOfLastMonth[0];
    }

    const [value, setValue] = React.useState(options[datePosition]); //last month
    const [rows, setRows] = useState([]);
    const [alreadyExists, setAlreadyExists] = useState(false);
    const [needToFill, setNeedToFill] = useState(true);
    const [help, setHelp] = useState(false);

   // const [year] = React.useState(yearOfLastMonth); //year of last month


    const {auth} = useContext(authContext);

    const [popup, setPopup] = React.useState(false);
    const [popupText, setPopupText] = React.useState('');
    const [popupTitle, setPopupTitle] = React.useState('');
    const [success, setSuccess] = React.useState(1);
    const [route, setRoute] = React.useState('');

    const [month, setMonth] = React.useState(
        today.getMonth() === 0
            ? optionsMonths[today.getMonth() + 11]
            : optionsMonths[today.getMonth() - 1]
    );

    const [year, setYear] = React.useState(
        today.getMonth() === 0 ? today.getFullYear() - 1 : today.getFullYear()
    );
    const [usersInCompany, setUsersInCompany] = useState([]);
    const [globalCompany, setGlobalCompany] = useState(
        auth.data.user.companyId
    );
    const [filterGlobalCompany, setFilterGlobalCompany] = useState();

    const [user, setUser] = useState({
        userId: auth.data.user.userId,
        name: auth.data.user.name,
        companyId: auth.data.user.companyId,
    });

    const [fillOtherMonth, setFillOtherMonth] = useState(false);

    const [fetchOpen, setFetchOpen] = useState(true);

    let history = useHistory();

    const getColor = (value) => {
        let color;
        value = parseInt(value);
        switch (value) { //select the color accord with the number
            case 3:
                color = '#38761D';
                break;
            case 2:
                color = '#3d9a15';
                break;
            case 1:
                color = '#00FF00';
                break;
            case -1:
                color = '#FFFF00';
                break;
            case -2:
                color = '#FF7800';
                break;
            case -3:
                color = '#FF0000';
                break;
            default:
                color = '#fff';
                break;
        }
        return color;
    };

    useEffect(() => {
        const callApiFindOneCompany = async () => {
            const response = await getOneCompany(
                auth,
                auth.data.user.companyId
            );

            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body.payload[0];
        };

        callApiFindOneCompany()
            .then(actualCompany => {
                setFilterGlobalCompany(actualCompany);
            })
            .catch(err => console.log(err));

    }, []);


    useEffect(() => {
        const loadData = (res) => {
            if (res.length !== 0) {
                setRows([
                    {
                        name: props.rows.professional,
                        grade: res[res.length - 1].professional,
                        color: getColor(res[res.length - 1].professional),
                    },
                    {
                        name: props.rows.personal,
                        grade: res[res.length - 1].personal,
                        color: getColor(res[res.length - 1].personal),
                    },
                    {
                        name: props.rows.financial,
                        grade: res[res.length - 1].financial,
                        color: getColor(res[res.length - 1].financial),
                    },
                    {
                        name: props.rows.health,
                        grade: res[res.length - 1].health,
                        color: getColor(res[res.length - 1].health),
                    },
                    {
                        name: props.rows.motivational,
                        grade: res[0].motivational,
                        color: getColor(res[res.length - 1].motivational),
                    },
                    {
                        name: props.rows.environment,
                        grade: res[res.length - 1].environment,
                        color: getColor(res[res.length - 1].environment),
                    },
                ]);
                setAlreadyExists(true);                
                !fillOtherMonth ? setPopup(true) : setPopup(false);
                setPopupTitle('Aviso');
                setPopupText(
                    'Você já preencheu os dados deste mês, não é possível editá-los'
                );
                setSuccess(0);
            } else {
                setPopup(false);
                setAlreadyExists(false);
                setRows([
                    { name: props.rows.professional, grade: '', color: '' },
                    { name: props.rows.personal, grade: '', color: '' },
                    { name: props.rows.financial, grade: '', color: '' },
                    { name: props.rows.health, grade: '', color: '' },
                    { name: props.rows.motivational, grade: '', color: '' },
                    { name: props.rows.environment, grade: '', color: '' },
                ]);
            }
        };


        const callApi = async (dataUser, globalCompany) => {
            //editar year month e day aqui
            const data = fillOtherMonth ? dataUser : auth.data.user;
            const year = fillOtherMonth
                ? dataUser.year
                : today.getMonth() === 0
                ? today.getFullYear() - 1
                : today.getFullYear();
            const month = fillOtherMonth
                ? dataUser.month
                : today.getMonth() === 0
                ? optionsMonths[today.getMonth() + 11]
                : optionsMonths[today.getMonth() - 1];
            const response = await getMatchDateIF(
                auth,
                data,
                year,
                month,
                globalCompany
            );
            const body = await response.json();
            if (response.status !== 200) throw Error(body.message);
            return body.payload;
        };

        /*const callApi = async (valueMonth) => {
            let actualYear;
            if(today.getMonth() === 0) {
                actualYear = new Date().toISOString();
                actualYear = actualYear.split("-");
                actualYear = actualYear[0];
                actualYear = (parseInt(actualYear) - 1);

            } else {
                actualYear = new Date().toISOString();
                actualYear = actualYear.split("-");
                actualYear = actualYear[0];
            }

            let month;

            for (let i = 0; i < 12; i++) {
                if (options[i] === valueMonth) {
                    month = i + 1;
                }
            }*/


        const callApiGetUserData = async (user) => {
            const response = await getOneUser(auth, user.userId, globalCompany);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body.payload;
        };

        const callApiGetUsersInCompany = async (globalCompany) => { 
            const response = await getAllUsersInCompany(auth, globalCompany);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body.payload;
        };

        callApiGetUsersInCompany(globalCompany)
            .then((res) => {
                setUsersInCompany(res);
                setFetchOpen(false);
            })
            .catch((err) => console.log(err));

        let internetError;

        internetError = getInternetError();
        if (internetError) {
            history.replace('/error/500');
            return;
        }
            
        let dataUser = {
            userId: user.userId,
            month: month.monthNumber,
            year: year,
        }
    
        callApi(dataUser, globalCompany)
            .then(res => {
                callApiGetUserData(user)
                    .then(userRes => {
                        let date = new Date().toISOString();
                        let allocationDate = userRes[0].allocation.startDate;
                        date = date.split('-');
                        allocationDate = allocationDate.split('-');

                        if((date[1] === allocationDate[1]) && (date[0] === allocationDate[0])) {//if allocation month is equal to the month that the employee start working in the company
                            setNeedToFill(false); //the employee don't need send the IF
                            setPopupTitle('Aviso');
                            setPopupText(
                                'Você entrou neste mês na empresa, então não precisa preencher o mês passado.'
                            );
                            setPopup(true);
                            setSuccess(0);
                        }
                        setFetchOpen(false);
                    })
                    .catch(err => console.log(err));
                loadData(res);
            })
            .catch(err => console.log(err));

    }, [auth, year, user, month, fillOtherMonth, globalCompany]);

    const handleSubmit = async event => {
        event.preventDefault();
        setFetchOpen(true);

        let date = year + '-' + month.monthNumber + '-' + '01';

        //TODO is working this way but the formatt is diffent from the others, fix this
        /*let date = new Date().toISOString();
        let s = date.split("-");
        let dd = s[2].split("T", 1);
        let lastMonth; //the data will be save as the last month
        let yearOfLastMonth;

        if(s[1] === "01"){
            lastMonth = "12";
            yearOfLastMonth = (parseInt(s[0]) - 1);
        } else {
            lastMonth = s[1] - 1;
            yearOfLastMonth = s[0];
        }
        lastMonth = String(lastMonth);

        if(lastMonth.length === 1){
            lastMonth = "0" + lastMonth
        }
        date = yearOfLastMonth + '-' + lastMonth + '-' + "01";*/
        let data = {
            date: date,
            user: user.userId,
            company: globalCompany,
            professional: parseInt(rows[0].grade),
            financial: parseInt(rows[2].grade),
            personal: parseInt(rows[1].grade),
            motivational: parseInt(rows[4].grade),
            health: parseInt(rows[3].grade),
            workEnviroment: parseInt(rows[5].grade)
        };

        try {
            let response = await sendIF(data, auth);
            setFetchOpen(false);
            if (response.status === 201) {
                setPopupTitle('Sucesso');
                setPopupText('IF Enviado.');
                setPopup(true);
                setSuccess(1);
            } else {
                setPopupTitle('Erro');
                setPopupText('IF não pode ser enviado.');
                setPopup(true);
                setSuccess(1);
            }

        } catch (error) {
            console.log(error);
        }

    };

    function handleChange(e, row, index) {
        let newArr = [...rows]; // copying the old datas array
        let color;

        color = getColor(e.target.value);

        let allow;

        if (e.target.value.match(/-/gi) && (e.target.value.length === 1))
            allow = true;
        else if (
            e.target.value.match(/-/gi) &&
            ['-1', '-2', '-3'].indexOf(e.target.value) > -1
        )
            allow = true;
        else if (
            e.target.value.length === 1 &&
            ['1', '2', '3'].indexOf(e.target.value) > -1
        )
            allow = true;
        else
            allow = false;

        if (allow) {
            newArr[index].grade = e.target.value;
            newArr[index].color = color;
        } else {
            newArr[index].grade = '';
            newArr[index].color = '';
        }

        setRows(newArr);
    }
    const defaultPropsUsers = {
        options: usersInCompany,
        value: user,
        getOptionLabel: (option) => {
            return option.name
        },
        getOptionSelected: (option) => {
            return option.name

        },
        onChange: (event, newValue) => {
            setFetchOpen(true);
            setUser(newValue);
        },
    }

    const defaultPropsMonths = {
        options: optionsMonths,
        defaultValue: optionsMonths[(today.getMonth())-1],
        getOptionLabel: (option) => {
            return option.name
        },
        getOptionSelected: (option) => {
            return option.name
        },
        onChange: (event, newValue) => {
            setFetchOpen(true);
            setMonth(newValue);
            setValue(newValue.name);
        },
    };

    const handleChangeSystem = () => {
        setFillOtherMonth(!fillOtherMonth);
    };

    const changeActualGlobalCompany = (company) => {
        if(company !== null) {
            setGlobalCompany(company.companyId);
            setFilterGlobalCompany(company);
            setUser({
                userId: 0,
                name: '',
                companyId: 0,
            });
        }
    };

    const onChangeHelp = () => {
        setHelp(!help);
    }

    return (
        <div>
            <Help onClick={onChangeHelp} helpPopUp={helpSendIf} help={help}/>
            {auth.data.user.permissionRoleId === 1 && fillOtherMonth ?
                <GlobalFilterContainer>
                    <GlobalCompanyFilter changeActualGlobalCompany={changeActualGlobalCompany}
                                    globalCompany={filterGlobalCompany}/>
                </GlobalFilterContainer>
            : null}
            <AlignContainer>
                <StyledFilter>
                    <StyledContainerTitle>
                        <Stripe id='stripe'/>
                        <ContainerTitle>
                            <Title>
                                Índice de Felicidade
                            </Title>
                            <span>Preencha o IF Mensal</span>
                        </ContainerTitle>
                    </StyledContainerTitle>
                    <div>
                        <span style={{ color: '#757575' }}>Mês</span>
                        <MonthBox>
                            {value} {year}
                        </MonthBox>
                    </div>
            </StyledFilter>
            <>  
                {auth.data.user.permissionRoleId === 1 ?
                    <>
                    <StyledSwitchTwoLabels>
                        <SwitchTwoLabels checkAction={setFillOtherMonth} leftWord="" rightWord="Preencher Outros" onChange={handleChangeSystem}/>
                    </StyledSwitchTwoLabels>
                    {fillOtherMonth === true ?
                        <Filters>
                            <Autocomplete
                                    {...defaultPropsUsers}
                                    id="users"
                                    autoSelect
                                    className={classes.autocomplete}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="Colaborador"
                                                                        variant="outlined"/>}
                                />
                            <Autocomplete
                                {...defaultPropsMonths}
                                id="users"
                                autoSelect
                                className={classes.autocomplete}
                                disableClearable
                                renderInput={(params) => <TextField {...params} label="Mês"
                                                                    variant="outlined"/>}
                            />
                            <FormControl variant="outlined" className={classes.autocomplete}>
                                <InputLabel id="demo-simple-select-outlined-label">Ano</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={year}
                                    onChange={e => {
                                        setFetchOpen(true);
                                        setYear(e.target.value)}}
                                    label="Ano">
                                    {optionsYear.map((year, index) => {
                                        return (
                                            <MenuItem key={index} value={year}>{year}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Filters>
                    : null}
                    </>
                : null}
            </> 
                <StyledSpan>Digite um dos números abaixo</StyledSpan>
                <Legend>
                    {numberValuesReverse.map((value, index) => (
                        <div
                            key={index}
                            style={{
                                border: '1px solid #424242',
                                width: '40px',
                                textAlign: 'center',
                                backgroundColor:
                                    value === '3'
                                        ? '#38761D'
                                        : value === '2'
                                        ? '#3d9a15'
                                        : value === '1'
                                        ? '#00FF00'
                                        : value === '-1'
                                        ? '#FFFF00'
                                        : value === '-2'
                                        ? '#FF7800'
                                        : value === '-3'
                                        ? '#FF0000'
                                        : null,
                            }}
                        >
                            {value}
                        </div>
                    ))}
                </Legend>
                <form onSubmit={handleSubmit}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell
                                        className={classes.colWidth}
                                        style={{ backgroundColor: '#9e9e9e' }}
                                        align="center"
                                    >
                                        Aspecto
                                    </StyledTableCell>
                                    <StyledTableCell
                                        className={classes.colWidth}
                                        align="center"
                                    >
                                        {value}
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    <StyledTableRow key={row.name}>
                                        <StyledTableCell component="th" scope="row">
                                            {row.name}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <FormControl
                                                className={classes.formControl}
                                            >
                                                {!alreadyExists &&
                                                needToFill ? (
                                                    <InputLabel id="demo-simple-select-outlined-label">
                                                        Valor{' '}
                                                        {/*palavra valor quando ainda a  ser preenchido*/}
                                                    </InputLabel>
                                                ) : null}
                                                {!needToFill ? null : alreadyExists ? (
                                                    <TableCellData>
                                                        <span
                                                            style={{
                                                                color: '#757575',
                                                            }}
                                                        >
                                                            {' '}
                                                            Valor{' '}
                                                            {/*Palavra Valor quando já preenchido */}
                                                        </span>
                                                        <div
                                                            style={{
                                                                backgroundColor:
                                                                    row.color,
                                                                width: 80,
                                                                height: 30,
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'center',
                                                                alignItems:
                                                                    'center',
                                                            }}
                                                        >
                                                            {row.grade}
                                                        </div>
                                                    </TableCellData>
                                                ) : (
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={row.grade}
                                                        onChange={(e) =>
                                                            handleChange(
                                                                e,
                                                                row,
                                                                index
                                                            )
                                                        }
                                                        label="Valor"
                                                        style={{
                                                            backgroundColor:
                                                                row.color,
                                                        }}
                                                    >
                                                        {numberValues.map(
                                                            (value, index) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={
                                                                            index
                                                                        }
                                                                        index={
                                                                            value
                                                                        }
                                                                        value={
                                                                            value
                                                                        }
                                                                    >
                                                                        {value}
                                                                    </MenuItem>
                                                                );
                                                            }
                                                        )}
                                                    </Select>
                                                )}
                                            </FormControl>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {!alreadyExists && needToFill ? (
                        <StyledButton>
                            <CustomButton type="submit" addButton>
                                {' '}
                                Salvar{' '}
                            </CustomButton>
                        </StyledButton>
                    ) : null}
                    {popup ? (
                        <PopUp
                            title={popupTitle}
                            string={popupText}
                            success={success}
                            route={route}
                        />
                    ) : null}
                    {fetchOpen ? <RenderPage open={fetchOpen} /> : null}
                </form>
            </AlignContainer>
        </div>
    );
}
