export function getColor(value) {
    let color;
    value = parseInt(value);
    switch (value) { //select the color accord with the number
        case 3:
            color = '#38761D';
            break;
        case 2:
            color = '#3d9a15';
            break;
        case 1:
            color = '#00FF00';
            break;
        case -1:
            color = '#FFFF00';
            break;
        case -2:
            color = '#FF7800';
            break;
        case -3:
            color = '#FF0000';
            break;
        default:
            color = '#fff';
            break;
    }
    return color;
}

export function getInternetError() {
    window.addEventListener('offline', () => console.log('Became offline'));
    window.addEventListener('offline', () => console.log('Became offline'));
    return !window.navigator.onLine;
}