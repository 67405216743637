import styled from "styled-components";

export const DashboardContainer = styled.div`
    background-color: white;
    border: 1px solid #cfd8dc;
    padding: 0;
    padding-bottom: 50px;
    margin-bottom: 80px;
    @media screen and (max-width: 600px) , screen and (max-height: 660px) {
        width: 100%;
        background-color: #fff;
        padding: 0px;
        margin-bottom: 20px;
    }
    @media screen and (max-height: 1000px) {
        height: 68%;
    }
    @media screen and (max-height: 800px) {
        width: 100%;
        height: 85%;
    }
`;

export const Spacer = styled.div`
    height: 12vh;
    width: 12vh;
    @media screen and (max-height: 600px) {
        height: 70px;
    }
`;

export const DrawerAdjustment = styled.div`
    padding-left: 72px;
    @media screen and (max-width: 800px) {
        padding-left: 0;
    }
`;

export const MainTitle = styled.h1`
    text-align: center;
    margin: 0;
    margin-bottom: 10px;
`;

