import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { isUndefined } from 'svgsaver/src/utils';
/*Styles*/
import {
    Container,
    ContainerRow,
    Column1,
    Column2,
    Column3,
    Column4,
    GlobalFilterContainer,
    SpacerFilter,
    ContainerRow1,
    StyledContainerText,
} from './history-nps-tab.styles';

/* recharts is a graphics library*/
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from 'recharts';

/* image that shows the color gradient of the nps scores */
import npsScore from '../../../../assets/npsScore.png';

/* backend routes for NPS */
import {
    findAllNps,
    findAllYears,
    getNpsAvgFromUser,
    getAllNpsAverageFromCompany,
    getNpsAvgFromDepartment,
    getNpsEvolutionFromCompany,
    getNpsEvolutionFromDepartment,
    getNpsEvolutionFromUser,
    getNpsAnswerers,
} from '../../../../api/ApiNps';

/* backend routes for Company */
import { getOneCompany } from '../../../../api/ApiCompany';

/* backend routes for User */
import {
    getOneUser,
    getAllActiveUsersInDepartment,
    getAllActiveUsersInCompany,
} from '../../../../api/ApiUser';

/* backend routes for Department */
import {
    getAllDepartments,
    getOneDepartment,
    getSubordinatesDepartments,
} from '../../../../api/ApiDepartment';

/* Components */

/* */
import GlobalCompanyFilter from '../../../../components/globalFilter/globalFilter.component';
/* button that makes a html node into PDF */
import GenPDF from '../../../../components/make-pdf/make-pdf';
/* modal that shows which users are affect the department average */
import HistTable from './history-table';

/* Context */
/* */
import { authContext } from '../../../../contexts/AuthContext';

/* Permissions */
/* */
import permissions, {
    getPermission,
    getSubmodulePermission,
} from '../../../../utils/permission';
/* */
import { findIfSubmoduleIsActive } from '../../../../api/ApiPemissions';

/* Material UI */
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

/* styles */
const useStyles = makeStyles((theme) => ({
    typography: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 500,
        margin: 'auto',
        fontSize: '1.3rem',
        lineHeight: '72px',
        letterSpacing: '-0.02em',
        color: '#4F4F4F',
        '@media (max-width: 759px)': {
            marginBottom: '0.5rem',
            paddingLeft: 0,
        },
    },

    image: {
        display: 'flex',
        marginTop: '20px',
        margin: 'auto',
        maxWidth: '100%',
    },

    formControl: {
        width: '10rem',
        margin: '12px auto',
        '@media (max-width: 680px)': {
            width: '80%',
            padding: 10,
        },
    },
}));

const barLabel = () => {
    return {
        position: 'top',
        fontSize: 18,
    };
};

const evolutionGraphColors = ['#f1a1a1', '#e08080', '#d96666', '#cc4d4d'];

export default function HistoryNps() {
    /* styles */
    const classes = useStyles();

    const { auth } = useContext(authContext);

    /* (string) keyword is used to get the name of the graphic's bar that was clicked, in order to make the history-table */
    const [keyword, setKeyword] = useState(null);

    /* number of users who answered the selected nps*/
    const [answerers, setAnswerers] = useState()

    //Filtros
    const [departmentList, setDepartmentList] = useState([]);
    const [departmentValue, setDepartmentValue] = useState({
        departmentId: auth?.data.user.departmentId,
        name: auth?.data.user.departmentName,
        companyId: auth?.data.user.companyId,
    });
    const [otherDepartmentValue, setOtherDepartmentValue] = useState();

    const [evolution, setEvolution] = useState();

    const [year, setYear] = useState();
    const [yearLoad, setYearLoad] = useState(false);
    const [yearsOptions, setYearsOptions] = useState([]);
    const [npsOptions, setNpsOptions] = useState([]);
    const [otherNpsOptions, setOtherNpsOptions] = useState([]);
    const [nps, setNps] = useState({});
    const [otherYear, setOtherYear] = useState();
    const [otherNpsAverage, setOtherNpsAverage] = useState([]);
    const [otherNps, setOtherNps] = useState({});

    const [submodules, setSubmodules] = useState({
        graphs: [],
    });

    const [jumpFirstRender, setJumpFirstRender] = useState(false);
    const [user, setUser] = useState(auth.data.user);
    const [otherUser, setOtherUser] = useState(auth.data.user);
    const [checksSelectedCounter, setChecksSelectedCounter] = useState(1);
    const [optionsUsers, setOptionsUsers] = useState([]);
    const [otherOptionsUsers, setOtherOptionsUsers] = useState([]);

    const [globalCompany, setGlobalCompany] = useState(
        auth.data.user.permissionLevel !== 3 ? undefined : { name: '' }
    );

    const [fetchOpen, setFetchOpen] = useState(true);

    const [seriesAux, setSeriesAux] = useState([
        { name: 'Média Tarefas', activated: false },
        { name: 'Média alinhamentos', activated: true },
    ]);

    /* these reference the checkboxes that appear on top of the Nps graphic*/
    const [checksStatus] = useState([
        { name: 'Colaborador', activated: true },
        { name: 'Setores', activated: true },
        { name: 'Empresa', activated: false },
        { name: 'Comparações', activated: false },
        { name: 'Evolução', activated: false },
    ]);

    const validUserKeys = ['avgUser0', 'avgUser1', 'avgUser2', 'avgUser3'];

    const validDepartmentKeys = [
        'avgDepartment0',
        'avgDepartment1',
        'avgDepartment2',
        'avgDepartment3',
    ];

    const validCompanyKeys = [
        'avgCompany0',
        'avgCompany1',
        'avgCompany2',
        'avgCompany3',
    ];

    /* data is the const that stores all the information that can be showed on the graphic */
    const [data, setData] = useState([
        {
            name: 'Feedback',
            englishName: 'feedback',
        },
        {
            name: 'Proatividade',
            englishName: 'proactivity',
        },
        {
            name: 'Organização',
            englishName: 'organization',
        },
        {
            name: 'Relacionamento',
            englishName: 'relationship',
        },
        {
            name: 'Acessibilidade',
            englishName: 'accessibility',
        },
        {
            name: 'Média',
            englishName: 'average'
        },
    ]);

     /* gets the list of valid years */
     useEffect(() => {
        const callApiFindAllYears = async () => {
            const response = await findAllYears(auth, auth.data.user.companyId);

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            payload = payload.map((curr) => String(curr.year));

            setYearsOptions(payload);
            setYear(payload[payload.length - 1]);
            setYearLoad(true);
            setOtherYear(payload[payload.length - 1]);
        };
        try {
            callApiFindAllYears();
        } catch (err) {
            console.log(err);
        }
    }, [auth]);

    /* */
    useEffect(() => {
        const callFindIfSubmoduleIsActive = async () => {
            const response = await findIfSubmoduleIsActive(
                auth,
                auth.data.user.permissionRoleId,
                permissions.NPS_GRAPHS
            );
            const { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            setSubmodules({
                graphs: payload,
            });
        };

        try {
            callFindIfSubmoduleIsActive();
        } catch (err) {
            console.log(err);
        }
    }, [auth]);

    /* gets the list of departments */
    useEffect(() => {
        const callApiFindAllDepartment = async () => {
            const response = await getAllDepartments(
                auth,
                auth.data.user.companyId
            );

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);
            setDepartmentList(payload);
        };

        const callApiFindSubordinatesDepartments = async () => {
            const response = await getSubordinatesDepartments(
                auth,
                auth.data.user.departmentId,
                auth.data.user.companyId
            );

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            setDepartmentList(payload);
        }

        const callApiFindOneDepartment = async () => {
            const response = await getOneDepartment(
                auth,
                auth.data.user.departmentId,
                auth.data.user.companyId
            );

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            setDepartmentValue(payload[0]);
        };
        try {
            if(submodules && getSubmodulePermission(submodules.graphs, permissions.SUBORDINATES_DEPARTMENTS_VIZUALIZATION_FILTER)) {
                callApiFindSubordinatesDepartments();
            } else if (getSubmodulePermission(submodules.graphs, permissions.DEPARTMENTS_VISUALIZATION_FILTER)) {
                callApiFindAllDepartment();
            }
            callApiFindOneDepartment();
        } catch (err) {
            console.log(err);
        }
    }, [auth, submodules]);

    /* updates data with main department nps average, dinamically checking if it should be year average or semester average */
    useEffect(() => {
        const callApiGetNpsAverageFromDepartment = async (currDepartment) => {
            const response = await getNpsAvgFromDepartment(
                auth,
                currDepartment?.departmentId,
                globalCompany?.companyId,
                nps?.npsId,
                year
            );

            let { message, payload } = await response.json();
            if (response.status !== 200) throw Error(message);

            if (payload.length > 0) {
                setData(
                    data.map((curr) => {
                        curr.avgDepartment = payload[0][curr.name];
                        return curr;
                    })
                );
            } else {
                if (data[0].avgDepartment) {
                    setData(
                        data.map((curr) => {
                            delete curr.avgDepartment;
                            return curr;
                        })
                    );
                }
            }
        };
        try {
            callApiGetNpsAverageFromDepartment(departmentValue);
        } catch (err) {
            console.log(err);
        }
    }, [auth, departmentValue, nps, globalCompany, year]);

    /* updates data with second department nps average, dinamically checking if it should be year average or semester average */
    useEffect(() => {
        const callApiGetOtherNpsAverageFromDepartment = async () => {
            const response = await getNpsAvgFromDepartment(
                auth,
                otherDepartmentValue?.departmentId,
                globalCompany?.companyId,
                otherNps?.npsId,
                otherYear
            );

            let { message, payload } = await response.json();
            if (response.status !== 200) throw Error(message);

            if (payload.length > 0) {
                setData(
                    data.map((curr) => {
                        curr.avgOtherDepartment = payload[0][curr.name];
                        return curr;
                    })
                );
            } else {
                if (data[0].avgOtherDepartment) {
                    setData(
                        data.map((curr) => {
                            delete curr.avgOtherDepartment;
                            return curr;
                        })
                    );
                }
            }
        };
        try {
            callApiGetOtherNpsAverageFromDepartment(otherDepartmentValue);
        } catch (err) {
            console.log(err);
        }
    }, [auth, otherDepartmentValue, otherNps, otherYear, globalCompany]);

    /* updates data with all deparment nps averages from a year */
    useEffect(() => {
        const callApiGetNpsEvolutionFromDepartment = async () => {
            const response = await getNpsEvolutionFromDepartment(
                auth,
                globalCompany?.companyId,
                departmentValue?.departmentId || user.departmentId,
                year
            );
            const { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            if (payload.length > 0) {
                setData(
                    data.map((curr) => {
                        payload.map((curr2, index) => {
                            curr[`avgDepartment${index}`] = curr2[curr.name];
                        });
                        return curr;
                    })
                );
            }
            if (
                checksStatus[0].activated ||
                (checksStatus[1].activated && checksStatus[4].activated)
            )
                setEvolution(payload);
        };
        try {
            callApiGetNpsEvolutionFromDepartment();
        } catch (err) {
            console.log(err);
        }
    }, [auth, departmentValue, year]);

    /* updates data with main user nps average, dinamically checking if it should be year average or semester average */
    useEffect(() => {
        const callApiGetNpsAverageFromUser = async () => {
            if (user === null) {
                return;
            }
            const response = await getNpsAvgFromUser(
                auth,
                user.userId,
                user.departmentId || user.allocation.departmentId,
                globalCompany,
                nps?.npsId,
                year
            );

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            if (payload.length > 0) {
                setData(
                    data.map((curr) => {
                        curr.avgUser = payload[0][curr.name];
                        return curr;
                    })
                );
            } else {
                if (data[0].avgUser) {
                    setData(
                        data.map((curr) => {
                            delete curr.avgUser;
                            return curr;
                        })
                    );
                }
            }
        };
        try {
            callApiGetNpsAverageFromUser();
        } catch (err) {
            console.log(err);
        }
    }, [user, year, nps, globalCompany]);

    /* updates data with second user nps average, dinamically checking if it should be year average or semester average */
    useEffect(() => {
        const callApiGetNpsAverageFromUser = async () => {
            if (otherUser === null) {
                return;
            }
            const response = await getNpsAvgFromUser(
                auth,
                otherUser.userId,
                otherUser.departmentId || otherUser.allocation.departmentId,
                globalCompany,
                otherNps?.npsId,
                otherYear
            );

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            if (payload.length > 0) {
                setData(
                    data.map((curr) => {
                        curr.avgOtherUser = payload[0][curr.name];
                        return curr;
                    })
                );
            } else {
                if (data[0].avgOtherUser) {
                    setData(
                        data.map((curr) => {
                            delete curr.avgOtherUser;
                            return curr;
                        })
                    );
                }
            }
        };
        try {
            callApiGetNpsAverageFromUser();
        } catch (err) {
            console.log(err);
        }
    }, [otherUser, otherNps, otherYear, globalCompany]);

    /* updates graphic data with user's evolution */
    useEffect(() => {
        const callApiGetNpsEvolutionFromUser = async () => {
            const response = await getNpsEvolutionFromUser(
                auth,
                globalCompany?.companyId,
                user?.departmentId || user?.allocation.departmentId,
                user?.userId,
                year
            );
            const { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            if (payload.length > 0) {
                setData(
                    data.map((curr) => {
                        payload.map((curr2, index) => {
                            curr[`avgUser${index}`] = curr2[curr.name];
                        });
                        return curr;
                    })
                );
            }
            if (
                checksStatus[0].activated &&
                !checksStatus[1].activated &&
                checksStatus[4].activated
            )
                setEvolution(payload);
        };
        try {
            callApiGetNpsEvolutionFromUser();
        } catch (err) {
            console.log(err);
        }
    }, [user, year]);

    /* updates graphic data with selected year company average */
    useEffect(() => {
        const callApiGetNpsAverageFromCompany = async () => {
            const response = await getAllNpsAverageFromCompany(
                auth,
                nps?.npsId,
                year
            );
            const { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            if (payload.length > 0) {
                setData(
                    data.map((curr) => {
                        curr.avgCompany = payload[0][curr.name];
                        return curr;
                    })
                );
            } else {
                if (data[0].avgCompany) {
                    setData(
                        data.map((curr) => {
                            delete curr.avgCompany;
                            return curr;
                        })
                    );
                }
            }
        };
        try {
            callApiGetNpsAverageFromCompany();
        } catch (err) {
            console.log(err);
        }
    }, [auth, nps, year]);

    /* updates graphic data with selected nps company average */
    useEffect(() => {
        const callApiGetNpsAverageFromCompany = async () => {
            const response = await getAllNpsAverageFromCompany(
                auth,
                otherNps?.npsId,
                otherYear
            );

            const { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            if (payload.length > 0) {
                setData(
                    data.map((curr) => {
                        curr.avgOtherYear = payload[0][curr.name];
                        return curr;
                    })
                );
            } else {
                if (data[0].avgOtherYear) {
                    setData(
                        data.map((curr) => {
                            delete curr.avgOtherYear;
                            return curr;
                        })
                    );
                }
            }

            setOtherNpsAverage(payload);
        };
        try {
            callApiGetNpsAverageFromCompany();
        } catch (err) {
            console.log(err);
        }
    }, [auth, otherNps, otherYear]);

    /* updates graphic data with selected year company evolution */
    useEffect(() => {
        const callApiGetNpsEvolutionFromCompany = async () => {
            const response = await getNpsEvolutionFromCompany(auth, year);
            const { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            if (payload.length > 0) {
                setData(
                    data.map((curr) => {
                        payload.map((curr2, index) => {
                            curr[`avgCompany${index}`] = curr2[curr.name];
                        });
                        return curr;
                    })
                );
            }
            if (
                !checksStatus[0].activated &&
                !checksStatus[1].activated &&
                checksStatus[4].activated
            )
                setEvolution(payload);
        };
        try {
            callApiGetNpsEvolutionFromCompany();
        } catch (err) {
            console.log(err);
        }
    }, [auth, year]);

    /* */
    const updateSeriesChild = (series) => {
        let aux = seriesAux;

        for (let i = 0; i < series.length; i++)
            aux[i].activated = series[i].activated;

        setSeriesAux(aux);
    };

    /* */
    const callApiFindDepartment = async (department, globalCompany) => {
        const response = await getOneDepartment(
            auth,
            department,
            globalCompany
        );
        const body = await response.json();

        if (response.status !== 200) throw Error(body.message);

        return body.payload;
    };

    /* */
    const callApiFindOneUser = async (actualUser, globalCompany) => {
        const response = await getOneUser(auth, actualUser, globalCompany);
        const body = await response.json();

        if (response.status !== 200) throw Error(body.message);

        return body.payload;
    };

    /* changes current selected company */
    const changeActualGlobalCompany = (company) => {
        setFetchOpen(true);
        if (company !== null) {
            setGlobalCompany(company);
        }
    };

    /* gets all valid nps from selected year */
    useEffect(() => {
        const callApiFindAllNps = async () => {
            const response = await findAllNps(
                auth,
                auth.data.user.companyId,
                year
            );

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            //payload = payload.map((curr) => String(curr.year));

            setNpsOptions(payload);
        };
        try {
            callApiFindAllNps();
        } catch (err) {
            console.log(err);
        }
    }, [auth, year]);

    /* gets all valid nps for selected otherYear */
    useEffect(() => {
        const callApiFindAllNps = async () => {
            const response = await findAllNps(
                auth,
                auth.data.user.companyId,
                otherYear
            );

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            //payload = payload.map((curr) => String(curr.year));

            setOtherNpsOptions(payload);
        };
        try {
            callApiFindAllNps();
        } catch (err) {
            console.log(err);
        }
    }, [auth, otherYear]);

    /* */
    useEffect(() => {
        //get all the companies
        const callGetOneCompany = async (company) => {
            const response = await getOneCompany(auth, company);
            const body = await response.json();
            return body.payload[0];
        };

        callGetOneCompany(auth.data.user.companyId).then((res) => {
            setGlobalCompany(res);
            setJumpFirstRender(true);
        });
    }, []);

    useEffect(() => {
        let actualGlobalCompany;

        /* The actualGlobalCompany is here and in graphic because both components have
        information that was related to the specific company defined in the global filter */
        if (!isUndefined(globalCompany)) {
            if (globalCompany.hasOwnProperty('companyId'))
                actualGlobalCompany = globalCompany.companyId;
            else actualGlobalCompany = auth.data.user.companyId;
        } else {
            actualGlobalCompany = auth.data.user.companyId;
        }
    }, [user, globalCompany]);

    /* gets all users, if department is selected, get only users from that department */
    useEffect(() => {
        const callApiGetAllActiveUsersInDepartment = async () => {
            const response = await getAllActiveUsersInDepartment(
                auth,
                departmentValue?.departmentId,
                auth.data.user.companyId
            );

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            setOptionsUsers(payload);
        };

        const callApiGetAllActiveUsersInCompany = async () => {
            const response = await getAllActiveUsersInCompany(
                auth,
                auth.data.user.companyId
            );

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            setOptionsUsers(payload);
        };

        try {
            if (departmentValue !== null && checksStatus[1].activated)
                callApiGetAllActiveUsersInDepartment();

            if (departmentValue === null || departmentValue === undefined)
                callApiGetAllActiveUsersInCompany();
        } catch (err) {
            console.log(err);
        }
    }, [departmentValue, checksStatus[1].activated, auth]);

    /* gets all users, if department is selected, get only users from that department */
    useEffect(() => {
        const callApiGetAllActiveUsersInDepartment = async () => {
            const response = await getAllActiveUsersInDepartment(
                auth,
                otherDepartmentValue?.departmentId,
                auth.data.user.companyId
            );

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            setOtherOptionsUsers(payload);
        };

        const callApiGetAllActiveUsersInCompany = async () => {
            const response = await getAllActiveUsersInCompany(
                auth,
                auth.data.user.companyId
            );

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            setOtherOptionsUsers(payload);
        };

        try {
            if (otherDepartmentValue !== null && checksStatus[1].activated)
                callApiGetAllActiveUsersInDepartment();

            if (
                otherDepartmentValue === null ||
                otherDepartmentValue === undefined
            )
                callApiGetAllActiveUsersInCompany();
        } catch (err) {
            console.log(err);
        }
    }, [otherDepartmentValue, checksStatus[1].activated, auth]);

    /* updates the number of answerers for the selected nps, if a department is selected, shows only users from that department */
    useEffect(() => {
        const callApiGetAnswerers = async () => {
            
            const response = await getNpsAnswerers(
                auth,
                nps?.npsId,
                checksStatus[1].activated?departmentValue?.departmentId:null,
                auth.data.user.companyId,
                year
            );
            
            let { message, payload } = await response.json();
            
            if (response.status !== 200) throw Error(message);
            
            setAnswerers(payload[0].numberOfUsers)
                        
        }
        try {
            callApiGetAnswerers();
        } catch (err) {
            console.log(err);
        }

    }, [auth, nps, year, departmentValue, checksStatus[1]]);

    /* Default props */
    const defaultPropsDepartment = {
        options: departmentList,
        value: departmentValue,
        getOptionLabel: (option) => {
            return option.name || '';
        },
        getOptionSelected: (option) => {
            return option.name || '';
        },
        onChange: (event, newValue) => {
            setDepartmentValue(newValue);
        },
    };

    const defaultPropsOtherDepartment = {
        options: departmentList,
        value: otherDepartmentValue,
        getOptionLabel: (option) => {
            return option.name || '';
        },
        getOptionSelected: (option) => {
            return option.name || '';
        },
        onChange: (event, newValue) => {
            setOtherDepartmentValue(newValue);
        },
    };

    const defaultPropsOtherYear = {
        options: yearsOptions,
        value: otherYear,
        getOptionLabel: (option) => {
            return option || '';
        },
        getOptionSelected: (option) => {
            return option || '';
        },
        onChange: (event, newValue) => {
            setOtherYear(newValue);
        },
    };

    const defaultPropsYear = {
        options: yearsOptions,
        value: year,
        getOptionLabel: (option) => {
            return option || '';
        },
        getOptionSelected: (option) => {
            return option || '';
        },
        onChange: (event, newValue) => {
            setYear(newValue);
        },
    };

    const defaultPropsNps = {
        options: npsOptions,
        value: nps,
        getOptionLabel: (option) => {
            return (option && option.semester) || '';
        },
        getOptionSelected: (option) => {
            return (option && option.semester) || '';
        },
        onChange: (event, newValue) => {
            setNps(newValue);
        },
    };

    const defaultPropsOtherNps = {
        options: otherNpsOptions,
        value: otherNps,
        getOptionLabel: (option) => {
            return (option && option.semester) || '';
        },
        getOptionSelected: (option) => {
            return (option && option.semester) || '';
        },
        onChange: (event, newValue) => {
            setOtherNps(newValue);
        },
    };

    const defaultPropsUsers = {
        options: optionsUsers,
        value: user,
        getOptionLabel: (option) => {
            return option.name;
        },
        getOptionSelected: (option) => {
            return option.name;
        },
        onChange: (event, newValue) => {
            setFetchOpen(true);
            setUser(newValue);
        },
    };

    const defaultPropsOtherUsers = {
        options: otherOptionsUsers,
        value: otherUser,
        getOptionLabel: (option) => {
            return option.name;
        },
        getOptionSelected: (option) => {
            return option.name;
        },
        onChange: (event, newValue) => {
            setFetchOpen(true);
            setOtherUser(newValue);
        },
    };

    const updateCounter = (e, num) => {
        if (e.target.checked) {
            setChecksSelectedCounter(checksSelectedCounter + 1);
        } else {
            setChecksSelectedCounter(checksSelectedCounter - 1);
        }
        checksStatus[num].activated = !checksStatus[num].activated;
    };

    const handleCheckDepartment = (e, num) => {
        updateCounter(e, num);
        if (!checksStatus[num].activated) {
            setUser(auth.data.user);
            setDepartmentValue(null);
        } else {
            setUser(auth.data.user);
            setDepartmentValue(
                departmentList.find(
                    (department) =>
                        department.departmentId === auth.data.user.departmentId
                )
            );
        }
    };

    const HandleCheckComparisons = (e, num) => {
        updateCounter(e, num);
        if (!checksStatus[num].activated) {
            setOtherDepartmentValue(null);
        } else {
            setOtherDepartmentValue(
                departmentList.find(
                    (department) =>
                        department.departmentId === auth.data.user.departmentId
                )
            );
        }
    };

    return (
        <>
            {!getPermission(auth.data.permissions, permissions.IPA_GRAPHS) ? (
                <Redirect to={permissions.IPA_DEFAULT_ROUTE} />
            ) : null}
            
            {/* This bracket stores the entirety of the NPS graphics */}
            {jumpFirstRender ? (
                <>
                    <Container>
                        <ContainerRow>
                            {/* Column1 has the title and a combobox for changing the selected company */}
                            <Column1>
                                <Typography className={classes.typography}>
                                    Histórico - NPS
                                </Typography>

                                {getSubmodulePermission(
                                    submodules.graphs,
                                    permissions.SYSTEM_GLOBAL_COMPANY_FILTER
                                ) ? (
                                    <GlobalFilterContainer>
                                        <GlobalCompanyFilter
                                            changeActualGlobalCompany={
                                                changeActualGlobalCompany
                                            }
                                            globalCompany={globalCompany}
                                        />
                                    </GlobalFilterContainer>
                                ) : null}
                            </Column1>
                        </ContainerRow>
                        <ContainerRow>
                            {/* Column2 has the checkboxes [Colaborador, Setores, Comparações, Evolução] */}
                            <Column2
                                around={auth.data.user.permissionLevel !== 1}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            style={{ color: '#424242' }}
                                            onChange={(e) => {
                                                updateCounter(e, 0);
                                            }}
                                            checked={
                                                checksStatus[0].activated
                                            }
                                        />
                                    }
                                    label={'Colaborador'}
                                />
                                
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            style={{ color: '#424242' }}
                                            onChange={(e) => {
                                                handleCheckDepartment(e, 1);
                                            }}
                                            checked={
                                                checksStatus[1].activated
                                            }
                                        />
                                    }
                                    label={'Setores'}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            style={{ color: '#424242' }}
                                            onChange={(e) => {
                                                HandleCheckComparisons(e, 3);
                                            }}
                                            checked={checksStatus[3].activated}
                                            disabled={checksStatus[4].activated}
                                        />
                                    }
                                    label={'Comparações'}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            style={{ color: '#424242' }}
                                            onChange={(e) => {
                                                updateCounter(e, 4);
                                            }}
                                            checked={checksStatus[4].activated}
                                            disabled={checksStatus[3].activated}
                                        />
                                    }
                                    label={'Evolução'}
                                />
                            </Column2>
                            {/* Column3 has the comboboxes for changing user, year, nps, department and the comparisons */}
                            <Column3>
                                {checksStatus[0].activated ? (
                                    <Autocomplete
                                        {...defaultPropsUsers}
                                        id="users"
                                        autoSelect
                                        disabled={
                                            getSubmodulePermission(
                                                submodules.graphs,
                                                permissions.EMPLOYEES_VISUALIZATION_FILTER
                                            ) ||
                                            (getSubmodulePermission(
                                                submodules.graphs,
                                                permissions.EMPLOYEES_VISUALIZATION_FILTER_DEPARTMENT
                                            ) &&
                                                checksStatus[1].activated)
                                                ? false
                                                : true
                                        }
                                        className={classes.formControl}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Colaborador"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                ) : (
                                    <SpacerFilter />
                                )}

                                {checksStatus[1].activated ? (
                                    <Autocomplete
                                        {...defaultPropsDepartment}
                                        id="department-selector"
                                        autoSelect
                                        disabled={
                                            !getSubmodulePermission(
                                                submodules.graphs,
                                                permissions.DEPARTMENTS_VISUALIZATION_FILTER
                                            ) && !getSubmodulePermission(
                                                submodules.graphs,
                                                permissions.SUBORDINATES_DEPARTMENTS_VIZUALIZATION_FILTER
                                            )
                                        }
                                        className={classes.formControl}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Setor"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                ) : (
                                    <SpacerFilter />
                                )}
                                {yearLoad && 
                                <Autocomplete
                                    {...defaultPropsYear}
                                    id="years"
                                    autoSelect
                                    className={classes.formControl}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Ano"
                                            variant="outlined"
                                        />
                                    )}
                                />
                                }
                            
                                {!checksStatus[4].activated && (
                                    <Autocomplete
                                        {...defaultPropsNps}
                                        id="nps"
                                        autoSelect
                                        className={classes.formControl}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Nps"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                )}
                                {checksStatus[3].activated ? (
                                    <Autocomplete
                                        {...defaultPropsOtherYear}
                                        id="otherYears"
                                        autoSelect
                                        className={classes.formControl}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Outro Ano"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                ) : (
                                    <SpacerFilter />
                                )}
                                {checksStatus[3].activated ? (
                                    <Autocomplete
                                        {...defaultPropsOtherNps}
                                        id="otherNps"
                                        autoSelect
                                        className={classes.formControl}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Outro Nps"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                ) : (
                                    <SpacerFilter />
                                )}

                                {checksStatus[0].activated &&
                                checksStatus[3].activated ? (
                                    <Autocomplete
                                        {...defaultPropsOtherUsers}
                                        id="otherUsers"
                                        autoSelect
                                        disabled={
                                            getSubmodulePermission(
                                                submodules.graphs,
                                                permissions.EMPLOYEES_VISUALIZATION_FILTER
                                            ) ||
                                            (getSubmodulePermission(
                                                submodules.graphs,
                                                permissions.EMPLOYEES_VISUALIZATION_FILTER_DEPARTMENT
                                            ) &&
                                                checksStatus[1].activated)
                                                ? false
                                                : true
                                        }
                                        className={classes.formControl}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Outro Colaborador"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                ) : (
                                    <SpacerFilter />
                                )}

                                {checksStatus[1].activated &&
                                checksStatus[3].activated &&
                                (getSubmodulePermission(
                                    submodules.graphs,
                                    permissions.DEPARTMENTS_VISUALIZATION_FILTER
                                ) ||
                                    getSubmodulePermission(
                                        submodules.graphs,
                                        permissions.EMPLOYEES_VISUALIZATION_FILTER_DEPARTMENT
                                    )) ? (
                                    <Autocomplete
                                        {...defaultPropsOtherDepartment}
                                        id="otherNps"
                                        autoSelect
                                        disabled={
                                            !getSubmodulePermission(
                                                submodules.graphs,
                                                permissions.DEPARTMENTS_VISUALIZATION_FILTER
                                            )
                                        }
                                        className={classes.formControl}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Outro setor"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                ) : (
                                    <SpacerFilter />
                                )}
                            </Column3>
                        </ContainerRow>
                        {
                            !checksStatus[3].activated &&
                            !checksStatus[4].activated &&
                            (getSubmodulePermission(
                                submodules.graphs,
                                permissions.DEPARTMENTS_VISUALIZATION_FILTER
                            ) || getSubmodulePermission(
                                submodules.graphs,
                                permissions.EMPLOYEES_VISUALIZATION_FILTER_DEPARTMENT
                            )) && (
                                <StyledContainerText>NPS {nps?.semester} {year} - {answerers} Colaboradores</StyledContainerText>
                            )
                        }
                        <ContainerRow>
                            {/* Column4 has the graphics bars and color image */}
                            <Column4>
                                <BarChart
                                    width={1000}
                                    height={400}
                                    style={{ cursor: 'pointer' }}
                                    data={data}
                                    margin={{
                                        top: 20,
                                        left: 0,
                                        bottom: 5,
                                    }}
                                    barGap={12}
                                >
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <CartesianGrid />
                                    <Legend
                                        width={900}
                                        wrapperStyle={{
                                            left: 20,
                                            transform: 'translateY(25px)',
                                            backgroundColor: '#f5f5f5',
                                            border: '1px solid #d5d5d5',
                                            borderRadius: 3,
                                            padding: 20,
                                        }}
                                    />
                                    {otherNps?.semester &&
                                    otherDepartmentValue?.name &&
                                    otherDepartmentValue !== null &&
                                    checksStatus[1].activated &&
                                    !checksStatus[4].activated ? (
                                        <Bar
                                            isAnimationActive={false}
                                            onClick={(a = this) => {
                                                if (departmentValue !== null)
                                                    setKeyword({
                                                        englishName:
                                                            a.englishName,
                                                        name: a.name,
                                                        mode: "department",
                                                        department: otherDepartmentValue,
                                                        nps: otherNps,
                                                        year: otherYear
                                                    });
                                            }}
                                            name={
                                                'Média ' +
                                                otherDepartmentValue.name +
                                                ' ' +
                                                otherNps.semester +
                                                ' nps ' +
                                                otherNps.startDate.slice(-4)
                                            }
                                            dataKey="avgOtherDepartment"
                                            fill="#0f1eac"
                                            label={barLabel()}
                                        />
                                    ) : (
                                        otherDepartmentValue?.name &&
                                        otherDepartmentValue !== null &&
                                        checksStatus[1].activated &&
                                        !checksStatus[4].activated && (
                                            <Bar
                                                isAnimationActive={false}
                                                onClick={(a = this) => {
                                                    if (
                                                        departmentValue !== null
                                                    )
                                                        setKeyword({
                                                            englishName:
                                                                a.englishName,
                                                            name: a.name,
                                                            mode: "department",
                                                            department: otherDepartmentValue,
                                                            nps: otherNps,
                                                            year: otherYear
                                                        });
                                                }}
                                                name={
                                                    'Média ' +
                                                    otherDepartmentValue.name +
                                                    ' ' +
                                                    otherYear
                                                }
                                                dataKey="avgOtherDepartment"
                                                fill="#0f1eac"
                                                label={barLabel()}
                                            />
                                        )
                                    )}

                                    {otherNps?.semester &&
                                    checksStatus[0].activated &&
                                    checksStatus[3].activated &&
                                    !checksStatus[4].activated &&
                                    otherUser !== null ? (
                                        <Bar
                                            isAnimationActive={false}
                                            name={
                                                'Média ' +
                                                otherUser.name +
                                                ' ' +
                                                otherNps.semester +
                                                ' ' +
                                                otherNps.startDate.slice(-4)
                                            }
                                            dataKey="avgOtherUser"
                                            fill="#483D8B"
                                            label={barLabel()}
                                        />
                                    ) : (
                                        checksStatus[0].activated &&
                                        checksStatus[3].activated &&
                                        !checksStatus[4].activated &&
                                        otherYear &&
                                        otherUser !== null && (
                                            <Bar
                                                isAnimationActive={false}
                                                name={
                                                    'Média ' +
                                                    otherUser.name +
                                                    ' ' +
                                                    otherYear
                                                }
                                                dataKey="avgOtherUser"
                                                fill="#483D8B"
                                                label={barLabel()}
                                            />
                                        )
                                    )}

                                    {checksStatus[4].activated &&
                                    checksStatus[1].activated &&
                                    year !== null &&
                                    departmentValue !== null &&
                                    (user === null || !checksStatus[0].activated)
                                        ? Object.keys(data[0])
                                              .filter((key) =>
                                                  validDepartmentKeys.includes(
                                                      key
                                                  )
                                              )
                                              .map((key, index) => (
                                                evolution[index] ?
                                                  <Bar
                                                      isAnimationActive={false}
                                                      onClick={(a = this) => {
                                                          if (
                                                              departmentValue !==
                                                              null
                                                          )
                                                              setKeyword({
                                                                  englishName:
                                                                      a.englishName,
                                                                  name: a.name,
                                                                  mode: "department",
                                                                  department: departmentValue,
                                                                  nps: nps,
                                                                  year: year
                                                              });
                                                      }}
                                                      name={
                                                          'Evolução ' +
                                                          departmentValue.name +
                                                          ' ' +
                                                          evolution[index]
                                                              ?.semester
                                                      }
                                                      key={key}
                                                      dataKey={key}
                                                      fill={
                                                          evolutionGraphColors[
                                                              index
                                                          ]
                                                      }
                                                      label={barLabel()}
                                                  />
                                                  :
                                                  <></>
                                              ))
                                        : checksStatus[4].activated &&
                                          checksStatus[1].activated &&
                                          year === null &&
                                          departmentValue !== null &&
                                          (user === null || !checksStatus[0].activated) &&
                                          Object.keys(data[0])
                                              .filter((key) =>
                                                  validDepartmentKeys.includes(
                                                      key
                                                  )
                                              )
                                              .map(
                                                  (key, index) =>
                                                      evolution[index]?.year !==
                                                          undefined && (
                                                          <Bar
                                                              isAnimationActive={
                                                                  false
                                                              }
                                                              onClick={(
                                                                  a = this
                                                              ) => {
                                                                  if (
                                                                      departmentValue !==
                                                                      null
                                                                  )
                                                                      setKeyword(
                                                                          {
                                                                              englishName:
                                                                                  a.englishName,
                                                                              name: a.name,
                                                                              mode: "department",
                                                                              department: departmentValue,
                                                                              nps: nps,
                                                                              year: year
                                                                          }
                                                                      );
                                                              }}
                                                              name={
                                                                  'Evolução ' +
                                                                  departmentValue.name +
                                                                  ' ' +
                                                                  evolution[
                                                                      index
                                                                  ]?.year
                                                              }
                                                              key={key}
                                                              dataKey={key}
                                                              fill={
                                                                  evolutionGraphColors[
                                                                      index
                                                                  ]
                                                              }
                                                              label={barLabel()}
                                                          />
                                                      )
                                              )}

                                    {checksStatus[4].activated &&
                                    !checksStatus[0].activated &&
                                    !checksStatus[1].activated &&
                                    year !== null
                                        ? Object.keys(data[0])
                                              .filter((key) =>
                                                  validCompanyKeys.includes(key)
                                              )
                                              .map((key, index) => (
                                                  <Bar
                                                      isAnimationActive={false}
                                                      name={
                                                          'Evolução Drakkar ' +
                                                          evolution[index]
                                                              ?.semester
                                                      }
                                                      key={key}
                                                      dataKey={key}
                                                      fill={
                                                          evolutionGraphColors[
                                                              index
                                                          ]
                                                      }
                                                      label={barLabel()}
                                                  />
                                              ))
                                        : checksStatus[4].activated &&
                                          !checksStatus[0].activated &&
                                          !checksStatus[1].activated &&
                                          year === null &&
                                          Object.keys(data[0])
                                              .filter((key) =>
                                                  validCompanyKeys.includes(key)
                                              )
                                              .map(
                                                  (key, index) =>
                                                      evolution[index]?.year !==
                                                          undefined && (
                                                          <Bar
                                                              isAnimationActive={
                                                                  false
                                                              }
                                                              name={
                                                                  'Evolução Drakkar ' +
                                                                  evolution[
                                                                      index
                                                                  ]?.year
                                                              }
                                                              key={key}
                                                              dataKey={key}
                                                              fill={
                                                                  evolutionGraphColors[
                                                                      index
                                                                  ]
                                                              }
                                                              label={barLabel()}
                                                          />
                                                      )
                                              )}

                                    {checksStatus[4].activated &&
                                        checksStatus[0].activated &&
                                        year !== null &&
                                        user !== null ? 
                                        (
                                            Object.keys(data[0])
                                            .filter((key) =>
                                                validUserKeys.includes(key)
                                            )
                                            .map((key, index) => (
                                                evolution[index]?.semester !== undefined &&
                                                (
                                                <Bar
                                                    name={
                                                        'Evolução ' +
                                                        user?.name +
                                                        ' ' +
                                                        evolution[index]
                                                            ?.semester
                                                    }
                                                    key={key}
                                                    dataKey={key}
                                                    fill={
                                                        evolutionGraphColors[
                                                            index
                                                        ]
                                                    }
                                                    label={{
                                                        position: 'top',
                                                        fontSize: 18,
                                                    }}
                                                />
                                                )
                                            ))
                                        ) : (
                                            checksStatus[4].activated &&
                                            checksStatus[0].activated &&
                                            year === null &&
                                            user !== null &&
                                            Object.keys(data[0])
                                            .filter((key) =>
                                                validUserKeys.includes(key)
                                            )
                                            .map((key, index) => (
                                                evolution[index]?.year !== undefined && 
                                                (
                                                <Bar
                                                    name={
                                                        'Evolução ' +
                                                        user?.name +
                                                        ' ' +
                                                        evolution[index]
                                                            ?.year
                                                    }
                                                    key={key}
                                                    dataKey={key}
                                                    fill={
                                                        evolutionGraphColors[
                                                            index
                                                        ]
                                                    }
                                                    label={{
                                                        position: 'top',
                                                        fontSize: 18,
                                                    }}
                                                />
                                                )
                                            ))
                                        )
                                    }

                                    {nps?.semester &&
                                    checksStatus[0].activated &&
                                    !checksStatus[4].activated &&
                                    user !== null ? (
                                        <Bar
                                            isAnimationActive={false}
                                            name={
                                                'Média ' +
                                                user.name +
                                                ' ' +
                                                nps.semester +
                                                ' ' +
                                                nps.startDate.slice(-4)
                                            }
                                            dataKey="avgUser"
                                            fill="#FF9900"
                                            label={barLabel()}
                                        />
                                    ) : (
                                        checksStatus[0].activated &&
                                        year &&
                                        user !== null && (
                                            <Bar
                                                isAnimationActive={false}
                                                name={
                                                    'Média ' +
                                                    user.name +
                                                    ' ' +
                                                    year
                                                }
                                                dataKey="avgUser"
                                                fill="#FF9900"
                                                label={barLabel()}
                                            />
                                        )
                                    )}

                                    {nps?.semester &&
                                    departmentValue?.name &&
                                    departmentValue !== null &&
                                    checksStatus[1].activated &&
                                    !checksStatus[4].activated ? (
                                        <Bar
                                            isAnimationActive={false}
                                            onClick={(a = this) => {
                                                if (departmentValue !== null)
                                                    getSubmodulePermission(
                                                        submodules.graphs,
                                                        permissions.EMPLOYEES_VISUALIZATION_FILTER_DEPARTMENT
                                                    ) && (
                                                    setKeyword({
                                                        englishName:
                                                            a.englishName,
                                                        name: a.name,
                                                        mode: "department",
                                                        department: departmentValue,
                                                        nps: nps,
                                                        year: year
                                                    }));
                                            }}
                                            name={
                                                'Média ' +
                                                departmentValue.name +
                                                ' ' +
                                                nps.semester +
                                                ' nps ' +
                                                nps.startDate.slice(-4)
                                            }
                                            dataKey="avgDepartment"
                                            fill="#00FF00"
                                            label={barLabel()}
                                        />
                                    ) : (
                                        year &&
                                        departmentValue?.name &&
                                        departmentValue !== null &&
                                        checksStatus[1].activated && (
                                            <Bar
                                                isAnimationActive={false}
                                                onClick={(a = this) => {
                                                    if (departmentValue !== null)
                                                        getSubmodulePermission(
                                                            submodules.graphs,
                                                            permissions.EMPLOYEES_VISUALIZATION_FILTER_DEPARTMENT
                                                        )  && (
                                                        setKeyword({
                                                                englishName:    
                                                                    a.englishName,
                                                                name: a.name,
                                                                mode: "department",
                                                                department: departmentValue,
                                                                nps: nps,
                                                                year: year
                                                        }));
                                                }}
                                                name={
                                                    'Média ' +
                                                    departmentValue.name +
                                                    ' ' +
                                                    year
                                                }
                                                dataKey="avgDepartment"
                                                fill="#00FF00"
                                                label={barLabel()}
                                            />
                                        )
                                    )}

                                    {nps?.semester ? (
                                        <Bar
                                            isAnimationActive={false}
                                            name={
                                                'Média Drakkar ' +
                                                nps.semester +
                                                ' ' +
                                                nps.startDate.slice(-4)
                                            }
                                            dataKey="avgCompany"
                                            fill="#FF0000"
                                            label={barLabel()}
                                            onClick={(a = this) => {
                                                getSubmodulePermission(
                                                    submodules.graphs,
                                                    permissions.EMPLOYEES_VISUALIZATION_FILTER
                                                ) && (
                                                    setKeyword({
                                                        englishName:
                                                            a.englishName,
                                                        name: a.name,
                                                        mode: "company",
                                                        nps: nps,
                                                        year: year
                                                    })
                                                )
                                            }}
                                        />
                                    ) : (
                                        year && (
                                            <Bar
                                                isAnimationActive={false}
                                                name={'Média Drakkar ' + year}
                                                dataKey="avgCompany"
                                                fill="#FF0000"
                                                label={barLabel()}
                                                onClick={(a = this) => {
                                                    getSubmodulePermission(
                                                        submodules.graphs,
                                                        permissions.EMPLOYEES_VISUALIZATION_FILTER
                                                    ) && (
                                                        setKeyword({
                                                            englishName:
                                                                a.englishName,
                                                            name: a.name,
                                                            mode: "company",
                                                            nps: nps,
                                                            year: year
                                                        })
                                                    )
                                                }}
                                            />
                                        )
                                    )}

                                    {otherNps?.semester &&
                                    checksStatus[3].activated ? (
                                        <Bar
                                            isAnimationActive={false}
                                            name={
                                                'Média Drakkar ' +
                                                otherNps.semester +
                                                ' ' +
                                                otherNps.startDate.slice(-4)
                                            }
                                            dataKey="avgOtherYear"
                                            fill="#FC5404"
                                            label={barLabel()}
                                            onClick={(a = this) => {
                                                getSubmodulePermission(
                                                    submodules.graphs,
                                                    permissions.EMPLOYEES_VISUALIZATION_FILTER
                                                ) && (
                                                    setKeyword({
                                                        englishName:
                                                            a.englishName,
                                                        name: a.name,
                                                        mode: "company",
                                                        nps: otherNps,
                                                        year: otherYear
                                                    })
                                                )
                                            }}
                                        />
                                    ) : (
                                        checksStatus[3].activated &&
                                        otherYear && (
                                            <Bar
                                                isAnimationActive={false}
                                                name={
                                                    'Média Drakkar ' + otherYear
                                                }
                                                dataKey="avgOtherYear"
                                                fill="#FC5404"
                                                label={barLabel()}
                                                onClick={(a = this) => {
                                                    getSubmodulePermission(
                                                        submodules.graphs,
                                                        permissions.EMPLOYEES_VISUALIZATION_FILTER
                                                    ) && (
                                                        setKeyword({
                                                            englishName:
                                                                a.englishName,
                                                            name: a.name,
                                                            mode: "company",
                                                            nps: otherNps,
                                                            year: otherYear
                                                        })
                                                    )
                                                }}
                                            />
                                        )
                                    )}
                                </BarChart>
                                <div>
                                    <img
                                        src={npsScore}
                                        alt="Imagem NPS"
                                        className={classes.image}
                                    />
                                </div>
                            </Column4>
                        </ContainerRow>
                    </Container>
                </>
            ) : null}
            {/* HistTable is activated when a department bar in the graphic is clicked, it shows a table with the users affecting their department average */}
            {keyword && (
                    <HistTable
                        keyWord={keyword}
                        company={globalCompany}
                        setClose={() => {
                            setKeyword(null);
                        }}
                    ></HistTable>
            )}
            {/* GenPDF is the generic PDF Button, it gets the node that will be printed with the filename and coords of the image */}
            <GenPDF
                node={document.getElementById('wrapped-tabpanel-1')}
                name={'nps'}
                coords={[-10, 50, 230, 235]}
            />
        </>
    );
}
