import React, {useContext, useEffect} from "react";
import {Redirect} from "react-router-dom";

/* Components */
import Header from "../../../../../components/header/header.component";
import FormList from "../../../../../components/form-list/form-list.component";
import PopUp from "../../../../../components/popup/popup.component";
import RenderPage from "../../../../../components/render-page/render-page.component";

/* Material UI */
import Container from "@material-ui/core/Container";

/* Styles */
import {DrawerAdjustment, Spacer} from "./edit-category.styles";

/* api */
import {editCategory, findOneCategory} from "../../../../../api/ApiCategory";

/* Context */
import {authContext} from "../../../../../contexts/AuthContext";
import permissions, {getPermission} from "../../../../../utils/permission";

export default function EditCategory(props) {
    const [name, setName] = React.useState('');

    const [popup, setPopup] = React.useState(false);
    const [popupText, setPopupText] = React.useState('');
    const [popupTitle, setPopupTitle] = React.useState('');
    const [success, setSuccess] = React.useState(1);
    const [route, setRoute] = React.useState('');

    const [fetchOpen, setFetchOpen] = React.useState(true);

    const {auth} = useContext(authContext);

    let rowsInput = [{name: "name", type: "text", value: name, label: "Nome da categoria", placeholder: "", mask: false, required: true, maxLength: 40}];

    let mainTitle = {
        title: "Editar Categoria",
        subtitle: "Preencha os campos"
    };

    let buttonInfo = {
        name: "Editar",
        addButton: false,
        editButton: true,
    };

    useEffect(() => {
        const callApiFindOneCategory = async () => {
            const response = await findOneCategory(auth, props.match.params.categoryId);
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body.payload[0];
        };

        callApiFindOneCategory()
            .then(res => {
                setFetchOpen(false);
                setName(res.name ? res.name : '');
            })
            .catch(err => console.log(err));

    }, []);

    const handleSubmit = async event => {
        event.preventDefault();
        setFetchOpen(true);
        let data = {
            name: name,
        };

        try {
            let response = await editCategory(data, auth, props.match.params.categoryId);
            setFetchOpen(false);
            if (response.status === 201) {
                setPopupTitle('Sucesso');
                setPopupText('Categoria editada.');
                setPopup(true);
                setSuccess(2);
                setRoute('/management/users');
            } else {
                setPopupTitle('Erro');
                setPopupText('Categoria não pode ser editada.');
                setPopup(true);
                setSuccess(1);
            }

        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = event => {
        const {value, name} = event.target;
        if (name === 'name') {
            setName(value);
        }
    };

    return (
        <div>
            {!getPermission(auth.data.permissions, permissions.IPA_MANAGE_CATEGORIES) ?
                <Redirect to={permissions.IPA_DEFAULT_ROUTE}/>
                : null}
            <Header/>
            <DrawerAdjustment>
                <Container>
                    <Spacer/>
                    <FormList rowsInput={rowsInput} handleSubmit={handleSubmit} handleChange={handleChange}
                              buttonInfo={buttonInfo} mainTitle={mainTitle}/>
                </Container>
                <Spacer/>
            </DrawerAdjustment>
            {popup ?
                <PopUp title={popupTitle} string={popupText} success={success} route={route}/> : null}
            {fetchOpen ?
                <RenderPage open={fetchOpen}/> : null}
        </div>
    );
}