import styled from 'styled-components/macro'

export const AlignContainer = styled.div`
    @media screen and (max-width: 830px) {
        margin-top: 15px;
    }
    @media screen and (max-width: 600px) , screen and (max-height: 600px) {
        left: 0%;
        top: 0%;
        position: static;
        transform: none;
        width: 100%;
    }
`;

export const TableData = styled.div`
    margin-bottom: 10px;
    margin-top: 10px;
`;
