import React, { useContext, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

/* Styles */
import { TableContainer, Spacer, DrawerAdjustment } from './if-monthly.styles';

/* Components */
import Table from './if-monthly-table/if-monthly-table.component';
import Header from '../../../components/header/header.component';

/* Material UI */
import Container from '@material-ui/core/Container';
import { authContext } from '../../../contexts/AuthContext';

/* Permissions */
import permissions, { getPermission } from '../../../utils/permission';

let rows = {
    professional: 'Profissional',
    personal: 'Pessoal',
    financial: 'Financeiro',
    motivational: 'Motivacional',
    health: 'Saúde',
    environment: 'Ambiente',
};

export default function MonthlyIf() {
    const { auth } = useContext(authContext);
    const history = useHistory();
    useEffect(() => {
        const currentDate = new Date();
        const currentDay = currentDate.getDate();

        if (currentDay >= 6) {
            history.push('/dashboard');
        }
    }, []);
    return (
        <div>
            {!getPermission(auth.data.permissions, permissions.IF_SEND) ? (
                <Redirect to={permissions.IF_DEFAULT_ROUTE} />
            ) : null}
            <Header />
            <DrawerAdjustment>
                <Container>
                    <Spacer />
                    <TableContainer>
                        <Table rows={rows} />
                    </TableContainer>
                </Container>
            </DrawerAdjustment>
        </div>
    );
}
