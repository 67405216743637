import React, { useState, useEffect } from 'react';

import Graph from '../../../../../../../../components/newGraph/graph';

/* component */
import GenPDF from '../../../../../../../../components/make-pdf/make-pdf';

/* icon */

/* util */

/* styles */
import {GraphicStyle} from '../../../../../ipa-graphs-general/ipa-graphs-general.styles';


const GeneralGraphDataTreatment = (props) => {
    const [treatedUserAlignmentsData, setTreatedUserAlignmentsData] =
        useState(props.newUserAlignmentsData);
    const [treatedUserTasksData, setTreatedUserTasksData] = useState();

    const [
        treatedFirstDepartmentAlignmentsData,
        setTreatedFirstDepartmentAlignmentsData,
    ] = useState();
    const [
        treatedFirstDepartmentTasksData,
        setTreatedFirstDepartmentTasksData,
    ] = useState();
    const [
        treatedSecondDepartmentAlignmentsData,
        setTreatedSecondDepartmentAlignmentsData,
    ] = useState();
    const [
        treatedSecondDepartmentTasksData,
        setTreatedSecondDepartmentTasksData,
    ] = useState();

    const [treatedCompanyAlignmentsData, setTreatedCompanyAlignmentsData] =
        useState();
    const [treatedCompanyTasksData, setTreatedCompanyTasksData] = useState();

    const [
        treatedUserOtherYearAlignmentsData,
        setTreatedUserOtherYearAlignmentsData,
    ] = useState();
    const [treatedUserOtherYearTasksData, setTreatedUserOtherYearTasksData] =
        useState();
    const [
        treatedDepartmentOtherYearAlignmentsData,
        setTreatedDepartmentOtherYearAlignmentsData,
    ] = useState();
    const [
        treatedDepartmentOtherYearTasksData,
        setTreatedDepartmentOtherYearTasksData,
    ] = useState();
    const [
        treatedCompanyOtherYearAlignmentsData,
        setTreatedCompanyOtherYearAlignmentsData,
    ] = useState();
    const [
        treatedCompanyOtherYearTasksData,
        setTreatedCompanyOtherYearTasksData,
    ] = useState();

    /* Sended to the Graph Compenent */
    const [sendData1Alignments, setSendData1Alignments] = useState();
    const [sendData1Tasks, setSendData1Tasks] = useState();
    const [sendData2Alignments, setSendData2Alignments] = useState();
    const [sendData2Tasks, setSendData2Tasks] = useState();

    const [sendName1, setSendName1] = useState('');
    const [sendName2, setSendName2] = useState('');

    const monthKeys = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const emptyTreatedData = [
        { y: null, x: 'January' },
        { y: null, x: 'February' },
        { y: null, x: 'March' },
        { y: null, x: 'April' },
        { y: null, x: 'May' },
        { y: null, x: 'June' },
        { y: null, x: 'July' },
        { y: null, x: 'August' },
        { y: null, x: 'September' },
        { y: null, x: 'October' },
        { y: null, x: 'November' },
        { y: null, x: 'December' },
    ];

    const [jumpFirstRender, setJumpFirstRender] = useState(false);

    useEffect(() => {
        //userDataTreatment
        if (props.userAlignmentsData && props.userTasksData) {
            if (
                Object.keys(props.userAlignmentsData).length !== 0 ||
                Object.keys(props.userTasksData).length !== 0
            ) {
                const treatUTD = () => {
                    if (props.userTasksData) {
                        // UTD -> User Alignment Data
                        const [yearUTD] = Object.keys(props.userTasksData);

                        const allNotesInMonthUTD = [];

                        // allNotesInMonthUTD = [{month: "January", grades: null},{month: "February", grades: [2,3,4]}...]
                        monthKeys.forEach((month) => {
                            if (props.userTasksData[yearUTD][month]) {
                                const gradesInMonth = props.userTasksData[
                                    yearUTD
                                ][month].map((gradeData) => {
                                    return gradeData.grade;
                                });
                                allNotesInMonthUTD.push({
                                    month: month,
                                    grades: gradesInMonth,
                                });
                            } else {
                                allNotesInMonthUTD.push({
                                    month: month,
                                    grades: null,
                                });
                            }
                        });

                        const treatedUTD = allNotesInMonthUTD.map(
                            (monthAndItsNotes) => {
                                if (monthAndItsNotes.grades) {
                                    const gradeAverage =
                                        monthAndItsNotes.grades.reduce(
                                            (acc, val) => acc + val
                                        ) / monthAndItsNotes.grades.length;
                                    return {
                                        x: monthAndItsNotes.month,
                                        y: gradeAverage,
                                    };
                                } else {
                                    return {
                                        x: monthAndItsNotes.month,
                                        y: null,
                                    };
                                }
                            }
                        );

                        setTreatedUserTasksData(treatedUTD);
                    }
                };

                const treatUserData = async () => {

                    (await Object.keys(props.userTasksData).length) !== 0
                        ? treatUTD()
                        : setTreatedUserTasksData(emptyTreatedData);

                    await setJumpFirstRender(true);
                };

                treatUserData();
            }
        }
    }, [props.checksStatus, props.userAlignmentsData, props.userTasksData]);

    useEffect(() => {
        //bounthDepartmentDataTreatment
        if (
            props.firstDepartmentAlignmentsData &&
            props.firstDepartmentTasksData
        ) {
            if (
                Object.keys(props.firstDepartmentAlignmentsData).length !== 0 ||
                Object.keys(props.firstDepartmentTasksData).length !== 0
            ) {
                const treatFDAD = () => {
                    if (props.firstDepartmentAlignmentsData) {
                        // FDAD -> First Department Alignment Data
                        const [yearFDAD] = Object.keys(
                            props.firstDepartmentAlignmentsData
                        );

                        const allNotesInMonthFDAD = [];

                        // allNotesInMonthFDAD = [{month: "January", grades: null},{month: "February", grades: [2,3,4]}...]
                        monthKeys.forEach((month) => {
                            if (props.firstDepartmentAlignmentsData[yearFDAD][month]) {
                                allNotesInMonthFDAD.push({
                                    x: month,
                                    y: props.firstDepartmentAlignmentsData[yearFDAD][month][0].grade,
                                });
                            } else {
                                allNotesInMonthFDAD.push({
                                    x: month,
                                    y: null,
                                });
                            }
                        });

                        setTreatedFirstDepartmentAlignmentsData(allNotesInMonthFDAD);
                    }
                };

                const treatFDTD = () => {
                    if (props.firstDepartmentTasksData) {
                        // FDTD -> First Department Tasks Data
                        const [yearFDTD] = Object.keys(
                            props.firstDepartmentTasksData
                        );

                        const allNotesInMonthFDTD = [];

                        // allNotesInMonthFDTD = [{month: "January", grades: null},{month: "February", grades: [2,3,4]}...]
                        monthKeys.forEach((month) => {
                            if (
                                props.firstDepartmentTasksData[yearFDTD][month]
                            ) {
                                allNotesInMonthFDTD.push({
                                    x: month,
                                    y: props.firstDepartmentTasksData[yearFDTD][month][0].grade,
                                });
                            } else {
                                allNotesInMonthFDTD.push({
                                    x: month,
                                    y: null,
                                });
                            }
                        });

                        setTreatedFirstDepartmentTasksData(allNotesInMonthFDTD);
                    }
                };
                const treatFirstDepartmentData = async () => {
                    (await Object.keys(props.firstDepartmentAlignmentsData)
                        .length) !== 0
                        ? treatFDAD()
                        : setTreatedFirstDepartmentAlignmentsData(
                              emptyTreatedData
                          );
                    (await Object.keys(props.firstDepartmentTasksData)
                        .length) !== 0
                        ? treatFDTD()
                        : setTreatedFirstDepartmentTasksData(emptyTreatedData);

                    await setJumpFirstRender(true);
                };
                treatFirstDepartmentData();
            }
        }
        if (
            props.secondDepartmentAlignmentsData &&
            props.secondDepartmentTasksData
        ) {
            if (
                Object.keys(props.secondDepartmentAlignmentsData).length !==
                    0 &&
                Object.keys(props.secondDepartmentTasksData).length !== 0
            ) {
                const treatSDAD = () => {
                    if (props.secondDepartmentAlignmentsData) {
                        // SDAD -> Second Department Alignment Data
                        const [yearSDAD] = Object.keys(
                            props.secondDepartmentAlignmentsData
                        );

                        const allNotesInMonthSDAD = [];

                        // allNotesInMonthSDAD = [{month: "January", grades: null},{month: "February", grades: [2,3,4]}...]
                        monthKeys.forEach((month) => {
                            if (props.secondDepartmentAlignmentsData[yearSDAD][month]) {
                                allNotesInMonthSDAD.push({
                                    x: month,
                                    y: props.secondDepartmentAlignmentsData[yearSDAD][month][0].grade,
                                });
                            } else {
                                allNotesInMonthSDAD.push({
                                    x: month,
                                    y: null,
                                });
                            }
                        });

                        setTreatedSecondDepartmentAlignmentsData(allNotesInMonthSDAD);
                    }
                };

                const treatSDTD = () => {
                    if (props.secondDepartmentTasksData) {
                        // SDTD -> Second Department Tasks Data
                        const [yearSDTD] = Object.keys(
                            props.secondDepartmentTasksData
                        );

                        const allNotesInMonthSDTD = [];

                        // allNotesInMonthSDTD = [{month: "January", grades: null},{month: "February", grades: [2,3,4]}...]
                        monthKeys.forEach((month) => {
                            if (props.secondDepartmentTasksData[yearSDTD][month]) {
                                allNotesInMonthSDTD.push({
                                    x: month,
                                    y: props.secondDepartmentTasksData[yearSDTD][month][0].grade,
                                });
                            } else {
                                allNotesInMonthSDTD.push({
                                    x: month,
                                    y: null,
                                });
                            }
                        });

                        setTreatedSecondDepartmentTasksData(allNotesInMonthSDTD);
                    }
                };

                const treatSecondDepartmentData = async () => {
                    (await Object.keys(props.secondDepartmentAlignmentsData)
                        .length) !== 0
                        ? treatSDAD()
                        : setTreatedSecondDepartmentAlignmentsData(
                              emptyTreatedData
                          );
                    (await Object.keys(props.secondDepartmentTasksData)
                        .length) !== 0
                        ? treatSDTD()
                        : setTreatedSecondDepartmentTasksData(emptyTreatedData);

                    await setJumpFirstRender(true);
                };

                treatSecondDepartmentData();
            }
        }
    }, [
        props.checksStatus[1].activated,
        props.firstDeparmentAlignmentsData,
        props.firstDepartmentTasksData,
        props.secondDeparmentAlignmentsData,
        props.secondDepartmentTasksData,
    ]);

    useEffect(() => {
        //companyDataTreatment
        if (props.companyAlignmentsData && props.companyTasksData) {
            if (
                Object.keys(props.companyAlignmentsData).length !== 0 ||
                Object.keys(props.companyTasksData).length !== 0
            ) {
                const treatCAD = () => {
                    if (props.companyAlignmentsData) {
                        // CAD -> Company Alignment Data
                        const [yearCAD] = Object.keys(
                            props.companyAlignmentsData
                        );

                        const allNotesInMonthCAD = [];

                        // allNotesInMonthCAD = [{month: "January", grades: null},{month: "February", grades: [2,3,4]}...]
                        monthKeys.forEach((month) => {
                            if (props.companyAlignmentsData[yearCAD][month]) {
                                const gradesInMonth =
                                    props.companyAlignmentsData[yearCAD][
                                        month
                                    ].map((gradeData) => {
                                        return gradeData.grade;
                                    });
                                allNotesInMonthCAD.push({
                                    month: month,
                                    grades: gradesInMonth,
                                });
                            } else {
                                allNotesInMonthCAD.push({
                                    month: month,
                                    grades: null,
                                });
                            }
                        });

                        const treatedCAD = allNotesInMonthCAD.map(
                            (monthAndItsNotes) => {
                                if (monthAndItsNotes.grades) {
                                    const gradeAverage =
                                        monthAndItsNotes.grades.reduce(
                                            (acc, val) => acc + val
                                        ) / monthAndItsNotes.grades.length;
                                    return {
                                        x: monthAndItsNotes.month,
                                        y: gradeAverage,
                                    };
                                } else {
                                    return {
                                        x: monthAndItsNotes.month,
                                        y: null,
                                    };
                                }
                            }
                        );

                        setTreatedCompanyAlignmentsData(treatedCAD);
                    }
                };

                const treatCTD = () => {
                    if (props.companyTasksData) {
                        // CTD -> Company Alignment Data
                        const [yearCTD] = Object.keys(props.companyTasksData);

                        const allNotesInMonthCTD = [];

                        // allNotesInMonthCTD = [{month: "January", grades: null},{month: "February", grades: [2,3,4]}...]
                        monthKeys.forEach((month) => {
                            if (props.companyTasksData[yearCTD][month]) {
                                const gradesInMonth = props.companyTasksData[
                                    yearCTD
                                ][month].map((gradeData) => {
                                    return gradeData.grade;
                                });
                                allNotesInMonthCTD.push({
                                    month: month,
                                    grades: gradesInMonth,
                                });
                            } else {
                                allNotesInMonthCTD.push({
                                    month: month,
                                    grades: null,
                                });
                            }
                        });

                        const treatedCTD = allNotesInMonthCTD.map(
                            (monthAndItsNotes) => {
                                if (monthAndItsNotes.grades) {
                                    const gradeAverage =
                                        monthAndItsNotes.grades.reduce(
                                            (acc, val) => acc + val
                                        ) / monthAndItsNotes.grades.length;
                                    return {
                                        x: monthAndItsNotes.month,
                                        y: gradeAverage,
                                    };
                                } else {
                                    return {
                                        x: monthAndItsNotes.month,
                                        y: null,
                                    };
                                }
                            }
                        );
                        setTreatedCompanyTasksData(treatedCTD);
                    }
                };

                const treatCompanyData = async () => {
                    (await Object.keys(props.companyAlignmentsData).length) !==
                    0
                        ? treatCAD()
                        : setTreatedCompanyAlignmentsData(emptyTreatedData);
                    (await Object.keys(props.companyTasksData).length) !== 0
                        ? treatCTD()
                        : setTreatedCompanyTasksData(emptyTreatedData);

                    await setJumpFirstRender(true);
                };

                treatCompanyData();
            }
        }
    }, [
        props.checksStatus[2].activated,
        props.companyAlignmentsData,
        props.companyTasksData,
    ]);

    useEffect(() => {
        //otherYearsDataTreatment

        if (props.checksStatus[3].activated) {
            // user other year
            if (
                props.checksStatus[0].activated &&
                props.userOtherYearAlignmentsData &&
                props.userOtherYearTasksData
            ) {
                if (
                    Object.keys(props.userOtherYearAlignmentsData).length !==
                        0 ||
                    Object.keys(props.userOtherYearTasksData).length !== 0
                ) {
                    const treatUOYAD = () => {
                        if (props.userOtherYearAlignmentsData) {
                            // UOYAD -> User Other Year Alignment Data
                            const [yearUOYAD] = Object.keys(
                                props.userOtherYearAlignmentsData
                            );

                            const allNotesInMonthUOYAD = [];

                            // allNotesInMonthUOYAD = [{month: "January", grades: null},{month: "February", grades: [2,3,4]}...]
                            monthKeys.forEach((month) => {
                                if (
                                    props.userOtherYearAlignmentsData[
                                        yearUOYAD
                                    ][month]
                                ) {
                                    const gradesInMonth =
                                        props.userOtherYearAlignmentsData[
                                            yearUOYAD
                                        ][month].map((gradeData) => {
                                            return gradeData.grade;
                                        });
                                    allNotesInMonthUOYAD.push({
                                        month: month,
                                        grades: gradesInMonth,
                                    });
                                } else {
                                    allNotesInMonthUOYAD.push({
                                        month: month,
                                        grades: null,
                                    });
                                }
                            });

                            const treatedUOYAD = allNotesInMonthUOYAD.map(
                                (monthAndItsNotes) => {
                                    if (monthAndItsNotes.grades) {
                                        const gradeAverage =
                                            monthAndItsNotes.grades.reduce(
                                                (acc, val) => acc + val
                                            ) / monthAndItsNotes.grades.length;
                                        return {
                                            x: monthAndItsNotes.month,
                                            y: gradeAverage,
                                        };
                                    } else {
                                        return {
                                            x: monthAndItsNotes.month,
                                            y: null,
                                        };
                                    }
                                }
                            );

                            setTreatedUserOtherYearAlignmentsData(treatedUOYAD);
                        }
                    };

                    const treatUOYTD = () => {
                        if (props.userOtherYearTasksData) {
                            // UOYTD -> User Other Year Alignment Data
                            const [yearUOYTD] = Object.keys(
                                props.userOtherYearTasksData
                            );

                            const allNotesInMonthUOYTD = [];

                            // allNotesInMonthUOYTD = [{month: "January", grades: null},{month: "February", grades: [2,3,4]}...]
                            monthKeys.forEach((month) => {
                                if (
                                    props.userOtherYearTasksData[yearUOYTD][
                                        month
                                    ]
                                ) {
                                    const gradesInMonth =
                                        props.userOtherYearTasksData[yearUOYTD][
                                            month
                                        ].map((gradeData) => {
                                            return gradeData.grade;
                                        });
                                    allNotesInMonthUOYTD.push({
                                        month: month,
                                        grades: gradesInMonth,
                                    });
                                } else {
                                    allNotesInMonthUOYTD.push({
                                        month: month,
                                        grades: null,
                                    });
                                }
                            });

                            const treatedUOYTD = allNotesInMonthUOYTD.map(
                                (monthAndItsNotes) => {
                                    if (monthAndItsNotes.grades) {
                                        const gradeAverage =
                                            monthAndItsNotes.grades.reduce(
                                                (acc, val) => acc + val
                                            ) / monthAndItsNotes.grades.length;
                                        return {
                                            x: monthAndItsNotes.month,
                                            y: gradeAverage,
                                        };
                                    } else {
                                        return {
                                            x: monthAndItsNotes.month,
                                            y: null,
                                        };
                                    }
                                }
                            );
                            setTreatedUserOtherYearTasksData(treatedUOYTD);
                        }
                    };

                    const treatUserOtherYearData = async () => {
                        (await Object.keys(props.userOtherYearAlignmentsData)
                            .length) !== 0
                            ? treatUOYAD()
                            : setTreatedUserOtherYearAlignmentsData(
                                  emptyTreatedData
                              );
                        (await Object.keys(props.userOtherYearTasksData)
                            .length) !== 0
                            ? treatUOYTD()
                            : setTreatedUserOtherYearTasksData(
                                  emptyTreatedData
                              );

                        await setJumpFirstRender(true);
                    };

                    treatUserOtherYearData();
                }
            }
            // department other year
            else if (
                props.checksStatus[1].activated &&
                props.departmentOtherYearAlignmentsData &&
                props.departmentOtherYearTasksData
            ) {
                if (
                    Object.keys(props.departmentOtherYearAlignmentsData)
                        .length !== 0 ||
                    Object.keys(props.departmentOtherYearTasksData).length !== 0
                ) {
                    const treatDOYAD = () => {
                        if (props.departmentOtherYearAlignmentsData) {
                            // DOYAD -> department Other Year Alignment Data
                            const [yearDOYAD] = Object.keys(
                                props.departmentOtherYearAlignmentsData
                            );

                            const allNotesInMonthDOYAD = [];

                            // allNotesInMonthDOYAD = [{month: "January", grades: null},{month: "February", grades: [2,3,4]}...]
                            monthKeys.forEach((month) => {
                                if (
                                    props.departmentOtherYearAlignmentsData[
                                        yearDOYAD
                                    ][month]
                                ) {
                                    const gradesInMonth =
                                        props.departmentOtherYearAlignmentsData[
                                            yearDOYAD
                                        ][month].map((gradeData) => {
                                            return gradeData.grade;
                                        });
                                    allNotesInMonthDOYAD.push({
                                        month: month,
                                        grades: gradesInMonth,
                                    });
                                } else {
                                    allNotesInMonthDOYAD.push({
                                        month: month,
                                        grades: null,
                                    });
                                }
                            });

                            const treatedDOYAD = allNotesInMonthDOYAD.map(
                                (monthAndItsNotes) => {
                                    if (monthAndItsNotes.grades) {
                                        const gradeAverage =
                                            monthAndItsNotes.grades.reduce(
                                                (acc, val) => acc + val
                                            ) / monthAndItsNotes.grades.length;
                                        return {
                                            x: monthAndItsNotes.month,
                                            y: gradeAverage,
                                        };
                                    } else {
                                        return {
                                            x: monthAndItsNotes.month,
                                            y: null,
                                        };
                                    }
                                }
                            );

                            setTreatedDepartmentOtherYearAlignmentsData(
                                treatedDOYAD
                            );
                        }
                    };

                    const treatDOYTD = () => {
                        if (props.departmentOtherYearTasksData) {
                            // DOYTD -> Department Other Year Alignment Data
                            const [yearDOYTD] = Object.keys(
                                props.departmentOtherYearTasksData
                            );

                            const allNotesInMonthDOYTD = [];

                            // allNotesInMonthDOYTD = [{month: "January", grades: null},{month: "February", grades: [2,3,4]}...]
                            monthKeys.forEach((month) => {
                                if (
                                    props.departmentOtherYearTasksData[
                                        yearDOYTD
                                    ][month]
                                ) {
                                    const gradesInMonth =
                                        props.departmentOtherYearTasksData[
                                            yearDOYTD
                                        ][month].map((gradeData) => {
                                            return gradeData.grade;
                                        });
                                    allNotesInMonthDOYTD.push({
                                        month: month,
                                        grades: gradesInMonth,
                                    });
                                } else {
                                    allNotesInMonthDOYTD.push({
                                        month: month,
                                        grades: null,
                                    });
                                }
                            });

                            const treatedDOYTD = allNotesInMonthDOYTD.map(
                                (monthAndItsNotes) => {
                                    if (monthAndItsNotes.grades) {
                                        const gradeAverage =
                                            monthAndItsNotes.grades.reduce(
                                                (acc, val) => acc + val
                                            ) / monthAndItsNotes.grades.length;
                                        return {
                                            x: monthAndItsNotes.month,
                                            y: gradeAverage,
                                        };
                                    } else {
                                        return {
                                            x: monthAndItsNotes.month,
                                            y: null,
                                        };
                                    }
                                }
                            );
                            setTreatedDepartmentOtherYearTasksData(
                                treatedDOYTD
                            );
                        }
                    };

                    const treatDepartmentOtherYearData = async () => {
                        (await Object.keys(
                            props.departmentOtherYearAlignmentsData
                        ).length) !== 0
                            ? treatDOYAD()
                            : setTreatedDepartmentOtherYearAlignmentsData(
                                  emptyTreatedData
                              );
                        (await Object.keys(props.departmentOtherYearTasksData)
                            .length) !== 0
                            ? treatDOYTD()
                            : setTreatedDepartmentOtherYearTasksData(
                                  emptyTreatedData
                              );

                        await setJumpFirstRender(true);
                    };

                    treatDepartmentOtherYearData();
                }
            }
            // company other year
            else if (
                props.checksStatus[2].activated &&
                props.companyOtherYearAlignmentsData &&
                props.companyOtherYearTasksData
            ) {
                if (
                    Object.keys(props.companyOtherYearAlignmentsData).length !==
                        0 ||
                    Object.keys(props.companyOtherYearTasksData).length !== 0
                ) {
                    const treatCOYAD = () => {
                        if (props.companyOtherYearAlignmentsData) {
                            // COYAD -> company Other Year Alignment Data
                            const [yearCOYAD] = Object.keys(
                                props.companyOtherYearAlignmentsData
                            );

                            const allNotesInMonthCOYAD = [];

                            // allNotesInMonthCOYAD = [{month: "January", grades: null},{month: "February", grades: [2,3,4]}...]
                            monthKeys.forEach((month) => {
                                if (
                                    props.companyOtherYearAlignmentsData[
                                        yearCOYAD
                                    ][month]
                                ) {
                                    const gradesInMonth =
                                        props.companyOtherYearAlignmentsData[
                                            yearCOYAD
                                        ][month].map((gradeData) => {
                                            return gradeData.grade;
                                        });
                                    allNotesInMonthCOYAD.push({
                                        month: month,
                                        grades: gradesInMonth,
                                    });
                                } else {
                                    allNotesInMonthCOYAD.push({
                                        month: month,
                                        grades: null,
                                    });
                                }
                            });

                            const treatedCOYAD = allNotesInMonthCOYAD.map(
                                (monthAndItsNotes) => {
                                    if (monthAndItsNotes.grades) {
                                        const gradeAverage =
                                            monthAndItsNotes.grades.reduce(
                                                (acc, val) => acc + val
                                            ) / monthAndItsNotes.grades.length;
                                        return {
                                            x: monthAndItsNotes.month,
                                            y: gradeAverage,
                                        };
                                    } else {
                                        return {
                                            x: monthAndItsNotes.month,
                                            y: null,
                                        };
                                    }
                                }
                            );

                            setTreatedCompanyOtherYearAlignmentsData(
                                treatedCOYAD
                            );
                        }
                    };

                    const treatCOYTD = () => {
                        if (props.companyOtherYearTasksData) {
                            // COYTD -> Company Other Year Alignment Data
                            const [yearCOYTD] = Object.keys(
                                props.companyOtherYearTasksData
                            );

                            const allNotesInMonthCOYTD = [];

                            // allNotesInMonthCOYTD = [{month: "January", grades: null},{month: "February", grades: [2,3,4]}...]
                            monthKeys.forEach((month) => {
                                if (
                                    props.companyOtherYearTasksData[yearCOYTD][
                                        month
                                    ]
                                ) {
                                    const gradesInMonth =
                                        props.companyOtherYearTasksData[
                                            yearCOYTD
                                        ][month].map((gradeData) => {
                                            return gradeData.grade;
                                        });
                                    allNotesInMonthCOYTD.push({
                                        month: month,
                                        grades: gradesInMonth,
                                    });
                                } else {
                                    allNotesInMonthCOYTD.push({
                                        month: month,
                                        grades: null,
                                    });
                                }
                            });

                            const treatedCOYTD = allNotesInMonthCOYTD.map(
                                (monthAndItsNotes) => {
                                    if (monthAndItsNotes.grades) {
                                        const gradeAverage =
                                            monthAndItsNotes.grades.reduce(
                                                (acc, val) => acc + val
                                            ) / monthAndItsNotes.grades.length;
                                        return {
                                            x: monthAndItsNotes.month,
                                            y: gradeAverage,
                                        };
                                    } else {
                                        return {
                                            x: monthAndItsNotes.month,
                                            y: null,
                                        };
                                    }
                                }
                            );
                            setTreatedCompanyOtherYearTasksData(treatedCOYTD);
                        }
                    };

                    const treatCompanyOtherYearData = async () => {
                        (await Object.keys(props.companyOtherYearAlignmentsData)
                            .length) !== 0
                            ? treatCOYAD()
                            : setTreatedCompanyOtherYearAlignmentsData(
                                  emptyTreatedData
                              );
                        (await Object.keys(props.companyOtherYearTasksData)
                            .length) !== 0
                            ? treatCOYTD()
                            : setTreatedCompanyOtherYearTasksData(
                                  emptyTreatedData
                              );

                        await setJumpFirstRender(true);
                    };

                    treatCompanyOtherYearData();
                }
            }
        }
    }, [
        props.checksStatus[3].activated,
        props.userOtherYearAlignmentsData,
        props.userOtherYearTasksData,
        props.departmentOtherYearAlignmentsData,
        props.departmentOtherYearTasksData,
        props.companyOtherYearAlignmentsData,
        props.companyOtherYearTasksData,
    ]);

    useEffect(() => {
        //SelectData sended to the graph

        const [userCheck, departmentCheck, companyCheck, otherYearCheck] = [
            props.checksStatus[0].activated,
            props.checksStatus[1].activated,
            props.checksStatus[2].activated,
            props.checksStatus[3].activated,
        ];
        
        let otherYear = ''
        if(props.actualOtherYear !== undefined)
        {
            otherYear = props.actualOtherYear  
            
        }
        
        
        if (userCheck && !departmentCheck && !companyCheck && !otherYearCheck) {
            //Only User Selected
            setSendData1Alignments(treatedUserAlignmentsData);
            setSendData1Tasks(treatedUserTasksData);
            setSendData2Alignments(emptyTreatedData);
            setSendData2Tasks(emptyTreatedData);
            setSendName1('Colaborador');
            setSendName2('');
        } else if (
            !userCheck &&
            departmentCheck &&
            !companyCheck &&
            !otherYearCheck &&
            props.actualDepartment.length === 1
        ) {
            //Only One Department
            setSendData1Alignments(treatedFirstDepartmentAlignmentsData);
            setSendData1Tasks(treatedFirstDepartmentTasksData);
            setSendData2Alignments(emptyTreatedData);
            setSendData2Tasks(emptyTreatedData);
            setSendName1('Departamento');
            setSendName2('');
        } else if (
            !userCheck &&
            !departmentCheck &&
            companyCheck &&
            !otherYearCheck
        ) {
            //Only Company
            setSendData1Alignments(treatedCompanyAlignmentsData);
            setSendData1Tasks(treatedCompanyTasksData);
            setSendData2Alignments(emptyTreatedData);
            setSendData2Tasks(emptyTreatedData);
            setSendName1('Empresa');
            setSendName2('');
        } else if (
            userCheck &&
            departmentCheck &&
            !companyCheck &&
            !otherYearCheck &&
            props.actualDepartment.length === 1
        ) {
            //One User and One Department
            setSendData1Alignments(treatedUserAlignmentsData);
            setSendData1Tasks(treatedUserTasksData);
            setSendData2Alignments(treatedFirstDepartmentAlignmentsData);
            setSendData2Tasks(treatedFirstDepartmentTasksData);
            setSendName1('Colaborador');
            setSendName2('Departamento');
        } else if (
            userCheck &&
            !departmentCheck &&
            companyCheck &&
            !otherYearCheck
        ) {
            // One User and One Company
            setSendData1Alignments(treatedUserAlignmentsData);
            setSendData1Tasks(treatedUserTasksData);
            setSendData2Alignments(treatedCompanyAlignmentsData);
            setSendData2Tasks(treatedCompanyTasksData);
            setSendName1('Colaborador');
            setSendName2('Empresa');
        } else if (
            !userCheck &&
            departmentCheck &&
            companyCheck &&
            !otherYearCheck &&
            props.actualDepartment.length === 1
        ) {
            // One Department and One Company
            setSendData1Alignments(treatedFirstDepartmentAlignmentsData);
            setSendData1Tasks(treatedFirstDepartmentTasksData);
            setSendData2Alignments(treatedCompanyAlignmentsData);
            setSendData2Tasks(treatedCompanyTasksData);
            setSendName1('Departamento');
            setSendName2('Empresa');
        } else if (
            userCheck &&
            !departmentCheck &&
            !companyCheck &&
            otherYearCheck
        ) {
            // user and other year
            setSendData1Alignments(treatedUserAlignmentsData);
            setSendData1Tasks(treatedUserTasksData);
            setSendData2Alignments(treatedUserOtherYearAlignmentsData);
            setSendData2Tasks(treatedUserOtherYearTasksData);
            setSendName1('Colaborador');
            setSendName2('Colaborador - Outro Ano (' + otherYear + ')');
        } else if (
            !userCheck &&
            departmentCheck &&
            !companyCheck &&
            otherYearCheck &&
            props.actualDepartment.length === 1
        ) {
            // department and other year
            setSendData1Alignments(treatedFirstDepartmentAlignmentsData);
            setSendData1Tasks(treatedFirstDepartmentTasksData);
            setSendData2Alignments(treatedDepartmentOtherYearAlignmentsData);
            setSendData2Tasks(treatedDepartmentOtherYearTasksData);
            setSendName1('Departamento');
            setSendName2('Departamento - Outro Ano (' + otherYear + ')');
        } else if (
            !userCheck &&
            !departmentCheck &&
            companyCheck &&
            otherYearCheck
        ) {
            // company and other year
            setSendData1Alignments(treatedCompanyAlignmentsData);
            setSendData1Tasks(treatedCompanyTasksData);
            setSendData2Alignments(treatedCompanyOtherYearAlignmentsData);
            setSendData2Tasks(treatedCompanyOtherYearTasksData);
            setSendName1('Empresa');
            setSendName2('Empresa - Outro Ano (' + otherYear + ')');
            
        } else if (
            !userCheck &&
            departmentCheck &&
            !companyCheck &&
            !otherYearCheck &&
            props.actualDepartment.length === 2
        ) {
            // Two departments
            setSendData1Alignments(treatedFirstDepartmentAlignmentsData);
            setSendData1Tasks(treatedFirstDepartmentTasksData);
            setSendData2Alignments(treatedSecondDepartmentAlignmentsData);
            setSendData2Tasks(treatedSecondDepartmentTasksData);
            setSendName1('1º Departamento');
            setSendName2('2º Departamento');
        } else if (
            userCheck &&
            departmentCheck &&
            !companyCheck &&
            !otherYearCheck &&
            props.actualDepartment.length === 0
        ) {
            //User Selected While Department isnt seleted
            setSendData1Alignments(treatedUserAlignmentsData);
            setSendData1Tasks(treatedUserTasksData);
            setSendData2Alignments(emptyTreatedData);
            setSendData2Tasks(emptyTreatedData);
            setSendName1('Colaborador');
            setSendName2('');
        } else if (
            !userCheck &&
            departmentCheck &&
            companyCheck &&
            !otherYearCheck &&
            props.actualDepartment.length === 0
        ) {
            //Company Selected While Department isnt seleted
            setSendData1Alignments(treatedCompanyAlignmentsData);
            setSendData1Tasks(treatedCompanyTasksData);
            setSendData2Alignments(emptyTreatedData);
            setSendData2Tasks(emptyTreatedData);
            setSendName1('Empresa');
            setSendName2('');
        } else if (
            !userCheck &&
            departmentCheck &&
            !companyCheck &&
            otherYearCheck &&
            props.actualDepartment.length === 0
        ) {
            //Other Years Selected While Department isnt seleted
            setSendData1Alignments(emptyTreatedData);
            setSendData1Tasks(emptyTreatedData);
            setSendData2Alignments(emptyTreatedData);
            setSendData2Tasks(emptyTreatedData);
            setSendName1('');
            setSendName2('');
        } else if (
            !userCheck &&
            departmentCheck &&
            !companyCheck &&
            !otherYearCheck &&
            props.actualDepartment.length === 0
        ) {
            //Department Check Selected While Department isnt seleted
            setSendData1Alignments(emptyTreatedData);
            setSendData1Tasks(emptyTreatedData);
            setSendData2Alignments(emptyTreatedData);
            setSendData2Tasks(emptyTreatedData);
            setSendName1('');
            setSendName2('');
        } else if (
            !userCheck &&
            !departmentCheck &&
            !companyCheck &&
            otherYearCheck
        ) {
            //Only Other Year Selected
            setSendData1Alignments(emptyTreatedData);
            setSendData1Tasks(emptyTreatedData);
            setSendData2Alignments(emptyTreatedData);
            setSendData2Tasks(emptyTreatedData);
            setSendName1('');
            setSendName2('');
        } else if (
            !userCheck &&
            !departmentCheck &&
            !companyCheck &&
            !otherYearCheck
        ) {
            setSendData1Alignments(emptyTreatedData);
            setSendData1Tasks(emptyTreatedData);
            setSendData2Alignments(emptyTreatedData);
            setSendData2Tasks(emptyTreatedData);
            setSendName1('');
            setSendName2('');
        }

        // empresa X setor
    }, [
        treatedUserAlignmentsData,
        treatedUserTasksData,
        treatedFirstDepartmentAlignmentsData,
        treatedFirstDepartmentTasksData,
        treatedSecondDepartmentAlignmentsData,
        treatedSecondDepartmentTasksData,
        treatedCompanyAlignmentsData,
        treatedCompanyTasksData,
        treatedUserOtherYearAlignmentsData,
        treatedUserOtherYearTasksData,
        treatedDepartmentOtherYearAlignmentsData,
        treatedDepartmentOtherYearTasksData,
        treatedCompanyOtherYearAlignmentsData,
        treatedCompanyOtherYearTasksData,
        props.checksStatus[0].activated,
        props.checksStatus[1].activated,
        props.checksStatus[2].activated,
        props.checksStatus[3].activated,
        props.actualDepartment.length,
        props.setSelectedDeparmentCounter,
    ]);

    return (
        <>
            {jumpFirstRender ? (
                <>
                    <GraphicStyle id="canvas" >
                        <Graph
                            data1Task={sendData1Tasks}
                            data1Alignment={sendData1Alignments}
                            data2Task={sendData2Tasks}
                            data2Alignment={sendData2Alignments}
                            sendName1={sendName1}
                            sendName2={sendName2}
                        />
                    </GraphicStyle>
                </>
            ) : null}
            <br />
            <br />
            <GenPDF node={document.getElementById("wrapped-tabpanel-0")} name={"ipa-geral"} coords={[5, 50, 215, 245]}/>
        </>
    );
};

export default GeneralGraphDataTreatment;
