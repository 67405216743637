
export function formatCpfAndCnpj(v) {
    /* Removes everything that is not a digit */
    v=v.replace(/\D/g,"");

    if (v.length < 14) { //CPF

        /* Place a dot between the third and fourth digits */
        v=v.replace(/(\d{3})(\d)/,"$1.$2");

        /* Place a dot between the third and fourth digits
        again (for the second block of numbers) */
        v=v.replace(/(\d{3})(\d)/,"$1.$2");

        /* Place a hyphen between the third and fourth digits */
        v=v.replace(/(\d{3})(\d{1,2})$/,"$1-$2");

    } else { //CNPJ


        /* Place a dot between the second and third digits */
        v=v.replace(/^(\d{2})(\d)/,"$1.$2");

        /* Place a dot between the fifth and sixth digits */
        v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3");

        /* Place a slash between the eighth and ninth digits */
        v=v.replace(/\.(\d{3})(\d)/,".$1/$2");

        /* Place a hyphen after the four-digit block */
        v=v.replace(/(\d{4})(\d)/,"$1-$2");

    }
    return v
}


export function IPANoteToPercentage(n){

    return (n * 0.33).toFixed(2)

}