import React, {useContext, useEffect, useState} from "react";
import {isUndefined} from "svgsaver/src/utils";

/* Styles */
import {ContainerButtons, StyledInput} from "./manage-alignment-tab.styles";

/* Components */
import EnhancedTable from "../../../../components/table-to-show-data/table-to-show-data.component";
import PopUp from "../../../../components/popup/popup.component";
import RenderPage from "../../../../components/render-page/render-page.component";
import CustomButton from "../../../../components/custom-button/custom-button.component";

/* Material UI */
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

/* Context */
import {authContext} from "../../../../contexts/AuthContext";

/* Api*/
import {getOneCompany} from "../../../../api/ApiCompany";
import {
    addAlignmentBetweenUsers, deleteAlignmentFromUser,
    findAllActiveUserAlignments,
    findUserAlignmentsAvailableToUser
} from "../../../../api/ApiAlignment";
import {getAllActiveUsersInCompany} from "../../../../api/ApiUser";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 220,
        width: 240,
        backgroundColor: "#fff3e0",
        borderRadius: 10,
        padding: 3
    },
}));

export default function ManageIpaAlignmentTab(props) {
    const {auth} = useContext(authContext);
    const [globalCompany, setGlobalCompany] = useState({});

    const [optionsUsers, setOptionsUsers] = React.useState([]);
    const [user, setUser] = React.useState(auth.data.user);

    const [buttonPopUp, setButtonPopUp] = React.useState({
        popUp: false,
        popUpTitle: "",
        popUpText: "",
        success: 1,
        acceptable: true
    });
    const [userAlignmentAddTitle, setUserAlignmentAddTitle] = React.useState({
        firstText: "Adicionar alinhamentos para",
        warningText: ` ${auth.data.user.name}`
    });
    const [userAlignmentDeleteTitle, setUserAlignmentDeleteTitle] = React.useState({
        firstText: "Alinhamentos vinculados à",
        warningText: ` ${auth.data.user.name}`
    });

    const [searchUsersAlignmentText, setSearchUsersAlignmentText] = useState('');
    const [userAlignmentRowInTable, setUserAlignmentRowInTable] = useState({});
    const [usersAlignmentRows, setUsersAlignmentRows] = useState([]);
    const [usersAlignmentData, setUsersAlignmentData] = useState([]);
    

    const [fetchOpen, setFetchOpen] = useState(true);
    const [reloadTable, setReloadTable] = useState(false);

    const classes = useStyles();

    const userHeadCells = [
        {id: 'id', numeric: false, disablePadding: false, label: 'Código'},
        {id: 'name', numeric: false, disablePadding: false, label: 'Nome'},
        {id: 'department', numeric: false, disablePadding: false, label: 'Setor'},
        {id: 'action', numeric: true, disablePadding: false, label: 'Ação'}
    ];
    
    const [toggleAlignmentButton, setToggleAlignmentButton] = React.useState('enabledAlignment');
    const [selectedAlignments, setSelectedAlignments] = React.useState([]);
    const [resetPage, setResetPage] = React.useState(false);

    const handleChangeToggleAlignmentButton = (event, newValue) => {
        setToggleAlignmentButton(newValue);
        setResetPage(!resetPage);
    };

    useEffect(() => {
        setSelectedAlignments([]);
    }, [ user]);

    const isSelected = ({ userId }) => selectedAlignments.indexOf(userId) !== -1;

    const handleClick = (event, row) => {
        const selectedIndex = selectedAlignments.indexOf(row.userId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedAlignments, row.userId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedAlignments.slice(1));
        } else if (selectedIndex === selectedAlignments.length - 1) {
            newSelected = newSelected.concat(selectedAlignments.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedAlignments.slice(0, selectedIndex),
                selectedAlignments.slice(selectedIndex + 1)
            );
        }

        setSelectedAlignments(newSelected);
    };

    useEffect(() => {
        if(props.theGlobalCompanyHasChange) {
            setFetchOpen(true);
        }

        let actualGlobalCompany;

        if (!isUndefined(props.globalCompany) && (props.globalCompany !== null)) {
            if (props.globalCompany.hasOwnProperty("companyId")) {
                actualGlobalCompany = props.globalCompany.companyId;
                setGlobalCompany(props.globalCompany.companyId);
            } else {
                actualGlobalCompany = auth.data.user.companyId;
                setGlobalCompany(auth.data.user.companyId);
            }
        } else {
            actualGlobalCompany = auth.data.user.companyId;
            setGlobalCompany(auth.data.user.companyId);
        }

        const callApiFindOneCompany = async (company) => {
            const response = await getOneCompany(auth, company);
            const body = await response.json();

            return body.payload[0]
        };

        const callApiFindAllUsers = async (globalCompany) => {
            const response = await getAllActiveUsersInCompany(auth, globalCompany);
            const body = await response.json();
            if (response.status !== 200) throw Error(body.message);

            return body.payload
        };

        const callApiUserAlignments = async (globalCompany, user) => {
            const response = await findAllActiveUserAlignments(auth, globalCompany, user);

            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body.payload
        };

        const callApiFindAllUsersAvailableToAlignment = async (globalCompany, user) => {
            const response = await findUserAlignmentsAvailableToUser(auth, globalCompany, user);

            const body = await response.json();

            if (response.status !== 201) throw Error(body.message); //TODO the correct is 200

            return body.payload
        };

        callApiFindOneCompany(actualGlobalCompany)
            .then(companyRes => {
                setGlobalCompany({companyId: companyRes.companyId,
                    name: companyRes.name})
            })
            .catch(err => console.log(err));

        callApiFindAllUsers(actualGlobalCompany)
            .then(usersRes => {
                usersRes = usersRes.filter((eachUser)=>{return eachUser.allocation.active === 1}); 

                if(props.theGlobalCompanyHasChange) {
                    setUser(usersRes[0])

                    setUserAlignmentAddTitle({
                        firstText: "Adicionar alinhamentos de",
                        warningText: ` ${usersRes[0].name}`
                    })
                    setUserAlignmentDeleteTitle({
                        firstText: "Alinhamentos vinculados à",
                        warningText: ` ${usersRes[0].name}`
                    })

                    props.resetChangeActualGlobalCompany(false);
                }
                setOptionsUsers(usersRes);
                setFetchOpen(false);
            })
            .catch(err => console.log(err));
        
            if(toggleAlignmentButton === 'enabledAlignment') {
                callApiUserAlignments(actualGlobalCompany, user)
                .then(alignmentsRes => {
                    let newData = [];
                    let newDataSortDelete = [];

                    for (let i = 0; i < alignmentsRes.length; i++) {
                        newData[i] = alignmentsRes[i];
                        newDataSortDelete[i] = {
                            userId: newData[i]["userId"],
                            name: newData[i]["name"],
                            departmentName: newData[i]["departmentName"],
                            action: 1
                        };
                    }

                    setUsersAlignmentRows(newDataSortDelete);
                    setUsersAlignmentData(newDataSortDelete);
                })
                .catch(err => console.log(err));
            } else {
                callApiFindAllUsersAvailableToAlignment(actualGlobalCompany, user)
                .then(usersRes => {
                    let newData = [];
                    let newDataSort = [];
    
                    for (let i = 0; i < usersRes.length; i++) {
                        newData[i] = usersRes[i];
                        newDataSort[i] = {
                            userId: newData[i]["userId"],
                            name: newData[i]["name"],
                            departmentName: newData[i]["departmentName"],
                            action: 1
                        };
                    }
                    setUsersAlignmentRows(newDataSort);
                    setUsersAlignmentData(newDataSort);
    
                })
                .catch(err => console.log(err));
            }


    }, [props.globalCompany, user, reloadTable, userAlignmentAddTitle, toggleAlignmentButton]);

    // exclude column list from filter
    let excludeColumns = ["email", "permission", "role"];

    // handle change event of search input
    const handleChangeUsers = value => {
        setSearchUsersAlignmentText(value);
        filterData(value);
    };

    const filterData = (value) => {
        let aux = '';
        const lowercasedValue = value.toLowerCase().trim();

        if (lowercasedValue === '') setUsersAlignmentRows(usersAlignmentData);
        else {
            const filteredData = usersAlignmentData.filter(item => {
                return Object.keys(item).some(key =>
                    excludeColumns.includes(key) ? false : item[key] ? item[key].toString().toLowerCase().includes(lowercasedValue) : aux.toString().toLowerCase().includes(lowercasedValue)
                );
            });
            setUsersAlignmentRows(filteredData);
        }
    };

    const defaultPropsUsers = {
        options: optionsUsers,
        value: user,
        getOptionLabel: (option) => {
            return option.name
        },
        getOptionSelected: (option) => {
            return option.name
        },
        onChange: (event, newValue) => {
            setUser(newValue);
            setFetchOpen(true);
            /* Set the actual user in the filter to the titles */
            setUserAlignmentAddTitle({
                firstText: "Adicionar alinhamentos de",
                warningText: ` ${newValue ? newValue.name : auth.data.user.name}`
            })
            setUserAlignmentDeleteTitle({
                firstText: "Alinhamentos vinculados à",
                warningText: ` ${newValue ? newValue.name : auth.data.user.name}`
            })
        },
    };

    const actionDeleteButton = (row) => { /* this function is called inside the table,  active the popup and keep the row that wat called, in this case the row correspond the user that was selected*/
        setUserAlignmentRowInTable(row);
        setButtonPopUp({
            popUp: true,
            popUpTitle: globalCompany.companyId !== auth.data.user.companyId ? `Cuidado! Você esta deletando um alinhamento na ${globalCompany.name}` : "Deletando alinhamento",
            popUpText: `Deseja deletar os alinhamentos selecionados para ${user.name}?`,
            success: 0,
            acceptable: 1,
            first: true,
            second: false
        });
    };

    const actionAddButton = (row) => {
        handleClose()
        setUserAlignmentRowInTable(row);
        setButtonPopUp({
            popUp: true,
            popUpTitle: globalCompany.companyId !== auth.data.user.companyId ? `Cuidado! Você esta adicionando um alinhamento na ${globalCompany.name}` : "Adicionando alinhamento",
            popUpText: `Deseja adicionar os alinhamentos selecionados para ${user.name}?`,
            success: 0,
            acceptable: true,
            first: false,
            second: true
        });
    };

    const handleClose = () => {
        setButtonPopUp({
            popUp: false,
            popUpTitle: "",
            popUpText: "",
            success: 0,
            acceptable: false
        });
    };

    const setNewFatherContext = () => {
        setReloadTable(!reloadTable)
    };

    const addAlignment = async () => {
        setFetchOpen(true);
        let data = {
            userId: user.userId,
            company: globalCompany.companyId,
            users: selectedAlignments
        }

        const response = await addAlignmentBetweenUsers(auth, data);
        const body = await response.json();

        if (response.status !== 201) throw Error(body.message);
        setFetchOpen(false);
        if (response.status === 201) {
            setButtonPopUp({
                popUp: true,
                popUpTitle: "Aviso",
                popUpText: `Alinhamentos selecionados foram adicionados com sucesso!`,
                success: 3,
                acceptable: false,
                first: false,
                second: false
            });
            setSelectedAlignments([]);
        } else {
            setButtonPopUp({
                popUp: true,
                popUpTitle: "Erro",
                popUpText: `Alinhamentos não podem ser criados.`,
                success: 3,
                acceptable: false,
                first: false,
                second: false
            });
        }
    };

    const deleteAlignment = async () => {
        setFetchOpen(true);
        let data = {
            userId: user.userId,
            company: globalCompany.companyId,
            users: selectedAlignments
        }

        const response = await deleteAlignmentFromUser(auth, data);
        const body = await response.json();

        if (response.status !== 201) throw Error(body.message);
        setFetchOpen(false);
        if (response.status === 201) {
            setButtonPopUp({
                popUp: true,
                popUpTitle: "Aviso",
                popUpText: `Alinhamentos deletados com sucesso.`,
                success: 3,
                acceptable: false,
                first: false,
                second: false
            });
            setSelectedAlignments([]);
        } else {
            setButtonPopUp({
                popUp: true,
                popUpTitle: "Erro",
                popUpText: `Alinhamentos não podem ser deletados.`,
                success: 3,
                acceptable: false,
                first: false,
                second: false
            });
        }
    };

    return (
        <>
            <div>
                <ContainerButtons>
                    <div>
                        <StyledInput
                            type="text"
                            placeholder="Digite para pesquisar..."
                            value={searchUsersAlignmentText}
                            onChange={e => handleChangeUsers(e.target.value)}
                        />
                    </div>
                    
                    <Autocomplete
                        {...defaultPropsUsers}
                        id="users"
                        autoSelect
                        className={classes.formControl}
                        renderInput={(params) => <TextField {...params} label="Usuário"
                                                            variant="outlined"/>}
                    />
                    <ToggleButtonGroup 
                        size="medium" 
                        style={{ height: 55, marginLeft: 10 }}
                        value={toggleAlignmentButton} 
                        exclusive 
                        onChange={handleChangeToggleAlignmentButton}
                    >
                        <ToggleButton 
                            value="disabledAlignment" 
                            style={{ fontFamily: 'Roboto', color:'#87cebf'}}
                        >
                            Adicionar
                        </ToggleButton>
                        <ToggleButton 
                            value="enabledAlignment" 
                            style={{ fontFamily: 'Roboto', color:'#f16669'}}
                        >
                            Deletar
                        </ToggleButton>
                    </ToggleButtonGroup>
                </ContainerButtons>

                <EnhancedTable rows={usersAlignmentRows} headCells={userHeadCells}
                               title={toggleAlignmentButton === 'enabledAlignment' ? userAlignmentDeleteTitle : userAlignmentAddTitle}
                               checkbox={true}
                               handleClick={handleClick}
                               isSelected={isSelected}
                               resetPage={resetPage}
                />
                <div style={{display: 'flex', justifyContent: 'right'}}>
                    {toggleAlignmentButton === 'enabledAlignment' ? <CustomButton
                        type="button"
                        deleteButton
                        onClick={actionDeleteButton}
                        style={
                            selectedAlignments.length === 0 || 
                            !user ? 
                            { marginLeft: 10, opacity: 0.2 }:
                            { marginLeft: 10, opacity: 1 }
                        }
                        disabled={
                            selectedAlignments.length === 0 || 
                            !user ? true: false}
                    >
                        {' '}
                        Deletar{' '}
                    </CustomButton>
                    :
                    <CustomButton
                        type="button"
                        addButton
                        onClick={actionAddButton}
                        style={
                            selectedAlignments.length === 0 || 
                            !user ? 
                            { marginLeft: 10, opacity: 0.2 }:
                            { marginLeft: 10, opacity: 1 }
                        }
                        disabled={
                            selectedAlignments.length === 0 || 
                            !user ? true: false}
                    >
                        {' '}
                        Adicionar{' '}
                    </CustomButton>
                    }
                </div>
                {buttonPopUp.popUp && buttonPopUp.first && !buttonPopUp.second ? /* Delete is the first button */
                    <PopUp title={buttonPopUp.popUpTitle} string={buttonPopUp.popUpText} success={buttonPopUp.success}
                           acceptable={buttonPopUp.acceptable} acceptFunction={deleteAlignment} setNewFatherContext={setNewFatherContext}
                           handleClose={handleClose} route={buttonPopUp.route}/>
                    : buttonPopUp.popUp && !buttonPopUp.first && buttonPopUp.second ? /* Add is the second button */
                        <PopUp title={buttonPopUp.popUpTitle} string={buttonPopUp.popUpText} setNewFatherContext={setNewFatherContext}
                               success={buttonPopUp.success} acceptable={buttonPopUp.acceptable}
                               acceptFunction={addAlignment} handleClose={handleClose} route={buttonPopUp.route}/>
                        : buttonPopUp.popUp ? /* Message of success or error */
                            <PopUp title={buttonPopUp.popUpTitle} string={buttonPopUp.popUpText} setNewFatherContext={setNewFatherContext}
                                   success={buttonPopUp.success} handleClose={handleClose}/>

                            : null}
                {fetchOpen ?
                    <RenderPage open={fetchOpen}/> : null}
            </div>
        </>

    );
}
