import styled from 'styled-components';

export const ContainerButtons = styled.div`
    display: grid;
    grid-template-areas: 'search average activeInactive';
    grid-template-columns: 33% 20% 33%;
    justify-content: center;
    margin: auto;
    width: 100%;
    height: 80px;
    align-items: center;
`;

export const Average = styled.div`
    display: grid;
    grid-area: average;
`;

export const Switch = styled.div`
    display: grid;
    justify-self: start;
`;

export const StyledInput = styled.input`
    display: grid;
    grid-area: search;
    border: none;
    border: 1px solid #8492a6;
    font-size: 16px;
    height: 40px;
    width: 80%;
    margin: auto;
    text-align: center;
    color: #757575;
    border-radius: 10px;
    font-family: 'Arial', sans-serif;
    &:focus {
        border: 2px solid #5c9089;
        outline: none;
    }
`;
