import styled from 'styled-components/macro';

export const GraphicStyle = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 400px;   
    height: ${({isPDF}) => isPDF ? '600px' : '60vh'};
    width: ${({isPDF}) => isPDF ? '750px' : '90%'};
    
    // Solve error: (Unable to preventDefault inside passive event listener due to 
    // target being treated as passive)
    // The error was present while 
    // touching the graph in mobile mode
    touch-action: pan-y; 

    @media screen and (max-width: 600px){
        width: 80vw;
        height: 600px;
    }
`;

export const Legend = styled.div`
    flex-wrap: wrap;
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const TableData = styled.div`
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    @media screen and (max-width: 750px){
         flex-direction: column;
         align-items: center;
    }
`;

export const AlignButton = styled.div`
    align-self: center;
    @media screen and (max-width: 750px){
         margin: 0;
         display: flex;
         justify-content: center;
    }
`;

export const GraphicConfigContainer = styled.div`
    display: flex;
    justify-content: space-around;
    width: 420px;
    @media screen and (max-width: 800px){
         flex-direction: column;
         justify-content: space-around;
    }
`;