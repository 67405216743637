import React, { useState, useEffect, useContext } from 'react';

import TaskGraphDataTreatment from './task-graphs-dataTreatment/task-graphs-dataTreatment';

/* auth */
import { authContext } from '../../../../../../../contexts/AuthContext';

/* api */
import {
    getUserSpecificTaskGrades,
    getDepartmentSpecificTaskGrades,
    getCompanySpecificTaskGrades,
} from '../../../../../../../api/ApiTasks';

const TaskGraphDataCall = (props) => {
    const { auth } = useContext(authContext);

    const [userTasksData, setUserTasksData] = useState();
    const [otherUserTasksData, setOtherUserTasksData] = useState();
    const [userOtherTasksData, setUserOtherTasksData] = useState();

    const [firstDepartmentTasksData, setFirstDepartmentTasksData] = useState();
    const [secondDepartmentTasksData, setSecondDepartmentTasksData] =
        useState();
    const [departmentOtherTasksData, setDepartmentOtherTasksData] = useState();

    const [companyTasksData, setCompanyTasksData] = useState();
    const [companyOtherTasksData, setCompanyOtherTasksData] = useState();

    const [userOtherYearTasksData, setUserOtherYearTasksData] = useState();
    const [departmentOtherYearTasksData, setDepartmentOtherYearTasksData] =
        useState();
    const [companyOtherYearTasksData, setCompanyOtherYearTasksData] =
        useState();

    const [departmentsNames, setDepartmentsNames] = useState([]);

    const [jumpFirstRender, setJumpFirstRender] = useState(false);

    useEffect(() => {
        //taks calls from user

        if (
            props.checksStatus[0].activated &&
            Object.keys(props.actualUser).length !== 0 &&
            props.actualTask
        ) {
            const callGetUserAnnualTaskGradeFromUser = async (
                company,
                user,
                taskId,
                actualYear
            ) => {
                const response = await getUserSpecificTaskGrades(
                    auth,
                    company.companyId,
                    user,
                    taskId,
                    actualYear
                );
                const body = await response.json();

                return body.payload[0];
            };

            const callUserData = async () => {
                const objIfEmpty = {};
                objIfEmpty[props.actualYear] = {};

                if (props.actualTask) {
                    const resUserTasks =
                        await callGetUserAnnualTaskGradeFromUser(
                            props.globalCompany,
                            props.actualUser.userId,
                            props.actualTask.taskId,
                            props.actualYear
                        );

                    if (Object.keys(resUserTasks).length !== 0)
                        await setUserTasksData(resUserTasks);
                    else await setUserTasksData(objIfEmpty);
                } else {
                    await setUserTasksData(objIfEmpty);
                }

                await setJumpFirstRender(true);
            };

            callUserData();
        }
    }, [props.actualUser, props.actualYear, props.actualTask]);

    useEffect(() => {
        //taks calls from OTHER user

        if (
            props.checksStatus[0].activated &&
            props.checksStatus[5].activated &&
            Object.keys(props.actualOtherUser).length !== 0 &&
            props.actualTask
        ) {
            const callGetUserAnnualTaskGradeFromUser = async (
                company,
                user,
                taskId,
                actualYear
            ) => {
                const response = await getUserSpecificTaskGrades(
                    auth,
                    company.companyId,
                    user,
                    taskId,
                    actualYear
                );
                const body = await response.json();
                return body.payload[0];
            };

            const callUserData = async () => {
                const objIfEmpty = {};
                objIfEmpty[props.actualYear] = {};

                if (props.actualTask) {
                    const resUserTasks =
                        await callGetUserAnnualTaskGradeFromUser(
                            props.globalCompany,
                            props.actualOtherUser.userId,
                            props.actualTask.taskId,
                            props.actualYear
                        );

                    if (Object.keys(resUserTasks).length !== 0)
                        await setOtherUserTasksData(resUserTasks);
                    else await setOtherUserTasksData(objIfEmpty);
                } else {
                    await setOtherUserTasksData(objIfEmpty);
                }

                await setJumpFirstRender(true);
            };

            callUserData();
        }
    }, [props.actualOtherUser, props.actualYear, props.actualTask]);

    useEffect(() => {
        //other taks calls

        if (props.checksStatus[1].activated) {
            const callGetUserAnnualTaskGrade = async (
                company,
                user,
                taskId,
                actualYear
            ) => {
                const response = await getUserSpecificTaskGrades(
                    auth,
                    company.companyId,
                    user,
                    taskId,
                    actualYear
                );
                const body = await response.json();

                return body.payload[0];
            };

            const callUserData = async () => {
                const objIfEmpty = {};
                objIfEmpty[props.actualYear] = {};

                if (props.actualOtherTask) {
                    const resUserTasks = await callGetUserAnnualTaskGrade(
                        props.globalCompany,
                        props.actualUser.userId,
                        props.actualOtherTask.taskId,
                        props.actualYear
                    );

                    if (Object.keys(resUserTasks).length !== 0)
                        await setUserOtherTasksData(resUserTasks);
                    else await setUserTasksData(objIfEmpty);
                } else {
                    await setUserOtherTasksData(objIfEmpty);
                }

                await setJumpFirstRender(true);
            };

            callUserData();
        }

        if (props.checksStatus[3].activated) {
            const callGetAnnualIpaCompanyTasks = async (
                company,
                taskId,
                year
            ) => {
                const response = await getCompanySpecificTaskGrades(
                    auth,
                    company,
                    taskId,
                    year
                );
                const body = await response.json();

                return body.payload[0];
            };

            const callCompanyData = async () => {
                const objIfEmpty = {};
                objIfEmpty[props.actualYear] = {};

                if (props.actualTask) {
                    const resCompanyTasks = await callGetAnnualIpaCompanyTasks(
                        props.globalCompany.companyId,
                        props.actualOtherTask.taskId,
                        props.actualYear
                    );
                    if (Object.keys(resCompanyTasks).length !== 0)
                        await setCompanyOtherTasksData(resCompanyTasks);
                    else await setCompanyOtherTasksData(objIfEmpty);
                } else {
                    await setCompanyOtherTasksData(objIfEmpty);
                }

                await setJumpFirstRender(true);
            };

            callCompanyData();
        }

        if (props.checksStatus[2].activated) {
            if (props.actualDepartment.length === 0) {
                setDepartmentsNames([]);
            }

            if (props.actualDepartment.length !== 0) {
                setDepartmentsNames(
                    props.actualDepartment.map((dept) => dept.name)
                );

                const callGetAnnualIpaDepartmentsTasks = async (
                    companyId,
                    departmentId,
                    taskId,
                    year
                ) => {
                    const response = await getDepartmentSpecificTaskGrades(
                        auth,
                        companyId,
                        departmentId,
                        taskId,
                        year
                    );
                    const body = await response.json();
                    return body.payload[0];
                };

                const callFirstDepartmentData = async () => {
                    const objIfEmpty = {};
                    objIfEmpty[props.actualYear] = {};

                    if (props.actualTask) {
                        const resFirstDepartmentTasks =
                            await callGetAnnualIpaDepartmentsTasks(
                                props.globalCompany.companyId,
                                props.actualDepartment[0].departmentId,
                                props.actualOtherTask.taskId,
                                props.actualYear
                            );

                        if (Object.keys(resFirstDepartmentTasks).length !== 0)
                            await setDepartmentOtherTasksData(
                                resFirstDepartmentTasks
                            );
                        else await setDepartmentOtherTasksData(objIfEmpty);
                    } else {
                        await setDepartmentOtherTasksData(objIfEmpty);
                    }

                    await setJumpFirstRender(true);
                };
                callFirstDepartmentData();
            }
        }
    }, [props.actualUser, props.actualYear, props.actualOtherTask]);

    useEffect(() => {
        //department calls

        if (props.actualDepartment.length === 0) {
            setDepartmentsNames([]);
        }

        if (props.actualDepartment.length !== 0) {
            setDepartmentsNames(
                props.actualDepartment.map((dept) => dept.name)
            );

            const callGetAnnualIpaDepartmentsTasks = async (
                companyId,
                departmentId,
                taskId,
                year
            ) => {
                const response = await getDepartmentSpecificTaskGrades(
                    auth,
                    companyId,
                    departmentId,
                    taskId,
                    year
                );
                const body = await response.json();
                return body.payload[0];
            };

            const callFirstDepartmentData = async () => {
                const objIfEmpty = {};
                objIfEmpty[props.actualYear] = {};

                if (props.actualTask) {
                    const resFirstDepartmentTasks =
                        await callGetAnnualIpaDepartmentsTasks(
                            props.globalCompany.companyId,
                            props.actualDepartment[0].departmentId,
                            props.actualTask.taskId,
                            props.actualYear
                        );

                    if (Object.keys(resFirstDepartmentTasks).length !== 0)
                        await setFirstDepartmentTasksData(
                            resFirstDepartmentTasks
                        );
                    else await setFirstDepartmentTasksData(objIfEmpty);
                } else {
                    await setFirstDepartmentTasksData(objIfEmpty);
                }

                await setJumpFirstRender(true);
            };

            callFirstDepartmentData();

            if (props.actualDepartment.length === 2) {
                const callSecondDepartmentData = async () => {
                    const objIfEmpty = {};
                    objIfEmpty[props.actualYear] = {};

                    const resSecondDepartmentTasks =
                        await callGetAnnualIpaDepartmentsTasks(
                            props.globalCompany.companyId,
                            props.actualDepartment[1].departmentId,
                            props.actualTask.taskId,
                            props.actualYear
                        );

                    if (Object.keys(resSecondDepartmentTasks).length !== 0)
                        await setSecondDepartmentTasksData(
                            resSecondDepartmentTasks
                        );
                    else await setSecondDepartmentTasksData(objIfEmpty);

                    await setJumpFirstRender(true);
                };

                callSecondDepartmentData();
            }
        }
    }, [props.actualDepartment, props.actualYear, props.actualTask]);

    useEffect(() => {
        //company calls

        if (props.checksStatus[3].activated) {
            const callGetAnnualIpaCompanyTasks = async (
                company,
                taskId,
                year
            ) => {
                const response = await getCompanySpecificTaskGrades(
                    auth,
                    company,
                    taskId,
                    year
                );
                const body = await response.json();
                return body.payload[0];
            };

            const callCompanyData = async () => {
                const objIfEmpty = {};
                objIfEmpty[props.actualYear] = {};

                if (props.actualTask) {
                    const resCompanyTasks = await callGetAnnualIpaCompanyTasks(
                        props.globalCompany.companyId,
                        props.actualTask.taskId,
                        props.actualYear
                    );
                    if (Object.keys(resCompanyTasks).length !== 0)
                        await setCompanyTasksData(resCompanyTasks);
                    else await setCompanyTasksData(objIfEmpty);
                } else {
                    await setCompanyTasksData(objIfEmpty);
                }

                await setJumpFirstRender(true);
            };

            callCompanyData();
        }
    }, [
        props.globalCompany,
        props.actualYear,
        props.actualTask,
        props.checksStatus[3].activated,
    ]);

    useEffect(() => {
        //other year calls

        if (props.checksStatus[4].activated) {
            if (props.checksStatus[0].activated) {
                //user other year
                const callGetUserAnnualTaskGradeFromUser = async (
                    company,
                    user,
                    taskId,
                    year
                ) => {
                    const response = await getUserSpecificTaskGrades(
                        auth,
                        company.companyId,
                        user,
                        taskId,
                        year
                    );
                    const body = await response.json();
                    return body.payload[0];
                };

                const callUserOtherYearData = async () => {
                    const objIfEmpty = {};
                    objIfEmpty[props.actualYear] = {};

                    if (props.actualTask) {
                        const resUserTasks =
                            await callGetUserAnnualTaskGradeFromUser(
                                props.globalCompany,
                                props.actualUser.userId,
                                props.actualTask.taskId,
                                props.actualOtherYear
                            );

                        if (Object.keys(resUserTasks).length !== 0)
                            await setUserOtherYearTasksData(resUserTasks);
                        else await setUserOtherYearTasksData(objIfEmpty);
                    } else {
                        await setUserOtherYearTasksData(objIfEmpty);
                    }

                    await setJumpFirstRender(true);
                };

                callUserOtherYearData();
            }

            if (props.checksStatus[3].activated) {
                //company other year
                const callGetAnnualIpaCompanyTasks = async (
                    company,
                    taskId,
                    year
                ) => {
                    const response = await getCompanySpecificTaskGrades(
                        auth,
                        company,
                        taskId,
                        year
                    );
                    const body = await response.json();
                    return body.payload[0];
                };

                const callCompanyData = async () => {
                    const objIfEmpty = {};
                    objIfEmpty[props.actualOtherYear] = {};

                    if (props.actualTask) {
                        const resCompanyTasks =
                            await callGetAnnualIpaCompanyTasks(
                                props.globalCompany.companyId,
                                props.actualTask.taskId,
                                props.actualOtherYear
                            );
                        if (Object.keys(resCompanyTasks).length !== 0)
                            await setCompanyOtherYearTasksData(resCompanyTasks);
                        else await setCompanyOtherYearTasksData(objIfEmpty);
                    } else {
                        await setCompanyOtherYearTasksData(objIfEmpty);
                    }

                    await setJumpFirstRender(true);
                };

                callCompanyData();
            }

            if (props.checksStatus[2].activated) {
                //department other year
                const callGetAnnualIpaDepartmentsTasks = async (
                    companyId,
                    departmentId,
                    taskId,
                    year
                ) => {
                    const response = await getDepartmentSpecificTaskGrades(
                        auth,
                        companyId,
                        departmentId,
                        taskId,
                        year
                    );
                    const body = await response.json();
                    return body.payload[0];
                };
                const callFirstDepartmentData = async () => {
                    const objIfEmpty = {};
                    objIfEmpty[props.actualYear] = {};

                    if (props.actualTask && props.actualDepartment[0]) {
                        const resFirstDepartmentTasks =
                            await callGetAnnualIpaDepartmentsTasks(
                                props.globalCompany.companyId,
                                props.actualDepartment[0].departmentId,
                                props.actualTask.taskId,
                                props.actualOtherYear
                            );

                        if (Object.keys(resFirstDepartmentTasks).length !== 0)
                            await setDepartmentOtherYearTasksData(
                                resFirstDepartmentTasks
                            );
                        else await setDepartmentOtherYearTasksData(objIfEmpty);
                    } else {
                        await setDepartmentOtherYearTasksData(objIfEmpty);
                    }

                    await setJumpFirstRender(true);
                };

                callFirstDepartmentData();
            }
        }
    }, [
        props.actualDepartment,
        props.checksStatus[3].activated,
        props.checksStatus[4].activated,
        props.actualUser,
        props.actualYear,
        props.globalCompany,
        props.actualOtherYear,
        props.actualTask,
    ]);

    return (
        <>
            {jumpFirstRender ? (
                <>
                    <TaskGraphDataTreatment
                        /* Alignments and Tasks Data */
                        userTasksData={userTasksData}
                        userOtherTasksData={userOtherTasksData}
                        otherUserTasksData={otherUserTasksData}
                        firstDepartmentTasksData={firstDepartmentTasksData}
                        secondDepartmentTasksData={secondDepartmentTasksData}
                        companyTasksData={companyTasksData}
                        departmentOtherTasksData={departmentOtherTasksData}
                        companyOtherTasksData={companyOtherTasksData}
                        /* Other Years Alignments and Tasks Data */
                        userOtherYearTasksData={userOtherYearTasksData}
                        departmentOtherYearTasksData={
                            departmentOtherYearTasksData
                        }
                        companyOtherYearTasksData={companyOtherYearTasksData}
                        /* Actual - Filters Information */
                        actualDepartment={props.actualDepartment}
                        departmentsNames={departmentsNames}
                        actualOtherYear={props.actualOtherYear}
                        /* Status */
                        checksStatus={props.checksStatus}
                        setSelectedDeparmentCounter={
                            props.setSelectedDeparmentCounter
                        }
                    />
                </>
            ) : null}
        </>
    );
};

export default TaskGraphDataCall;
