import {apiUrl} from './Api';

export function findOneCategory(auth, category) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/categories/${category}`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}

export function findAllCategories(auth) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/categories`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}

export function addCategory(data, auth) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        },

        body: JSON.stringify({
            name: data.name,
        })
    };

    return fetch(`${apiUrl}/categories`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        })
}

export function editCategory(data, auth, category) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        },

        body: JSON.stringify({
            name: data.name,
        })
    };

    return fetch(`${apiUrl}/categories/${category}`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        })
}

export function findCompanyCategories(auth, companyId) {
    const authorization = `Bearer ${auth.data.token}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authorization
        }
    };

    return fetch(`${apiUrl}/companies/${companyId}/getCompanyCategories`, requestOptions)
        .then(response => {
            return response;
        }).catch(error => {
            console.log(error)
        });

}