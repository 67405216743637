import React from 'react';
import PropTypes from 'prop-types';

/* Styles */
import { DrawerAdjustment, Spacer, TableContainer } from './ipa-nps.styles';

/* Material UI */
import Container from '@material-ui/core/Container';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

/* Components */
import Header from '../../../components/header/header.component';
import SendNps from './send-nps-tab/send-nps-tab.component';
import CreateNps from './create-nps-tab/create-nps-tab.component';
import HistoryNps from './history-nps-tab/history-nps-tab.component';

/* Contetx */
import { authContext } from '../../../contexts/AuthContext';

/* Permission */
import permissions, { setTabsOrder } from '../../../utils/permission';

//Api
import { findNpsStatus } from '../../../api/ApiNps';
import RenderPage from '../../../components/render-page/render-page.component';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box style={{ padding: 0 }} p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const SendTab = (props) => {
    return (
        <TabPanel value={props.value} index={props.SEND}>
            <SendNps />
        </TabPanel>
    );
};

const HistoryTab = (props) => {
    return (
        <TabPanel value={props.value} index={props.HISTORY}>
            <HistoryNps />
        </TabPanel>
    );
};

const CreateTab = (props) => {
    return (
        <TabPanel value={props.value} index={props.MANAGE}>
            <CreateNps />
        </TabPanel>
    );
};

const getModulePermissionsAndTabs = (userPermissions, permissions) => {
    let resSend = userPermissions.find(
        (elem) => elem.moduleId === permissions.IPA_SEND_ALIGNMENT
    );
    let resHistory = userPermissions.find(
        (elem) => elem.moduleId === permissions.IPA_ALIGNMENT_HISTORY
    );
    let resManage = userPermissions.find(
        (elem) => elem.moduleId === permissions.IPA_MANAGE_ALIGNMENTS
    );

    let tempTabs = {
        SEND: !!resSend.active /* !! convert 1 to true or 0 to false*/,
        HISTORY: !!resHistory.active,
        MANAGE: !!resManage.active,
    };

    setTabsOrder(tempTabs);

    return {
        tabs: tempTabs,
        modules: {
            send: resSend.active,
            history: resHistory.active,
            manage: resManage.active,
        },
    };
};

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function NpsPage() {
    const { auth } = React.useContext(authContext);
    const [fetchOpen, setFetchOpen] = React.useState(true);
    const [value, setValue] = React.useState(null);

    const userPermissions = getModulePermissionsAndTabs(
        auth.data.permissions,
        permissions
    );
    const [tabs] = React.useState(userPermissions.tabs);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const classes = useStyles();
    const outerTheme = createTheme({
        palette: {
            primary: {
                main: auth.data.system ? '#F58989' : '#ffeb3b',
                dark: auth.data.system ? '#ff5722' : '#ffc107',
                contrastText: '#212121',
            },
        },
    });

    React.useEffect(() => {
        const callApiFindNpsStatus = async () => {
            const response = await findNpsStatus(
                auth,
                auth.data.user.userId,
                auth.data.user.companyId
            );

            const { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            setValue(payload[0].status === 'Fechado' ? 1 : 0);
            setFetchOpen(false);
        };

        try {
            callApiFindNpsStatus();
        } catch (error) {
            console.log(error);
        }
    }, [auth]);

    return (
        <>
            <Container>
                <Header />
                <DrawerAdjustment>
                    <Spacer />
                    <div className={classes.root}>
                        <MuiThemeProvider theme={outerTheme}>
                            <AppBar
                                position="static"
                                color="default"
                                style={{
                                    backgroundColor: auth.data.system
                                        ? '#fff3e0'
                                        : '#fffde7',
                                    paddingBottom: 0.2,
                                    borderLeft: '1px solid #cfd8dc',
                                    borderRight: '1px solid #cfd8dc',
                                    borderTop: '1px solid #cfd8dc',
                                }}
                            >
                                {value !== null ? (

                                    <Tabs
                                        TabIndicatorProps={{
                                            style: {
                                                height: '4px',
                                            },
                                        }}
                                        value={value}
                                        onChange={handleChange}
                                        indicatorColor="primary"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="scrollable auto tabs example"
                                    >
                                        <Tab
                                            style={{ fontWeight: 'bold' }}
                                            label="Enviar"
                                            {...a11yProps(tabs.SEND)}
                                        />
                                        <Tab
                                            style={{ fontWeight: 'bold' }}
                                            label="Histórico"
                                            {...a11yProps(tabs.SEND)}
                                        />
                                        <Tab
                                            disabled={
                                                auth.data.user.permissionRoleId !==
                                                2
                                                    ? false
                                                    : true
                                            }
                                            style={{ fontWeight: 'bold' }}
                                            label="NPS"
                                            {...a11yProps(tabs.SEND)}
                                        />
                                    </Tabs>
                                ): <RenderPage open={fetchOpen}/>}
                            </AppBar>
                        </MuiThemeProvider>
                    </div>
                    {value !== null ? (
                        <TableContainer>
                            <SendTab value={value} SEND={tabs.SEND} />
                            <HistoryTab value={value} HISTORY={tabs.HISTORY} />
                            <CreateTab value={value} MANAGE={tabs.MANAGE} />
                        </TableContainer>
                    ):null}
                </DrawerAdjustment>
            </Container>
        </>
    );
}
