import React, { useContext, useEffect, useState } from 'react';
import { isUndefined } from 'svgsaver/src/utils';

/* Styles */
import {
    Filters,
    TableContainer,
    FiltersAndSwitch,
} from './history-tab.styles';

/* Components */
import TableToShowValue from '../../../../components/table-to-show-value/table-to-show-value.component';
import RenderPage from '../../../../components/render-page/render-page.component';

/* Material UI */
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SwitchTwoLabels from '../../../../components/switch/switch.component';

/* Context */
import { authContext } from '../../../../contexts/AuthContext';

/* Api*/
import { getOneCompany } from '../../../../api/ApiCompany';
import {
    findAllUserTasks,
    getUserAnnualTasksGrade,
} from '../../../../api/ApiTasks';
import { 
    getAllActiveUsersInDepartment,
    getAllActiveUsersInCompany
} from '../../../../api/ApiUser';

/* Utils */
import { yearsUntilNow } from '../../../../utils/initialData';

/* Permission */
import permissions, {
    getSubmodulePermission,
} from '../../../../utils/permission';
import { getAllDepartments, getOneDepartment, getSubordinatesDepartments } from '../../../../api/ApiDepartment';

const today = new Date();

const optionsYear = yearsUntilNow();

const yy = today.getFullYear();

const GERENTE = 10;

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
        width: 240,
        borderRadius: 10,
        padding: 3,
    },
    formControlYear: {
        margin: theme.spacing(1),
        minWidth: 100,
        width: 140,
        borderRadius: 10,
        padding: 3,
    },
}));

export default function HistoryIpaTasksTab(props) {
    const { auth } = useContext(authContext);
    const [globalCompany, setGlobalCompany] = useState({});

    const [user, setUser] = useState({
        userId: auth.data.user.userId,
        name: auth.data.user.name,
        departmentId: auth.data.user.departmentId,
        companyId: auth.data.user.companyId,
        active: 1,
    });
    const [optionsUsers, setOptionsUsers] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [departmentValue, setDepartmentValue] = useState({
        departmentId: auth?.data.user.departmentId,
        name: auth?.data.user.departmentName,
        companyId: auth?.data.user.companyId,
    });

    const [rowsNameId, setRowsNameId] = useState([]);
    const [rowsName, setRowsName] = useState([]);
    const [rows, setRows] = useState([]);
    const [yearAverage, setYearAverage] = useState([]);
    const [monthAverage, setMonthAverage] = useState([]);
    const [allYearAverage, setAllYearAverage] = useState();

    const [showNotActivedTasks, setShowNotActivedTasks] = useState(false);
    const [fetchOpen, setFetchOpen] = useState(true);

    const classes = useStyles();

    let stop = null;
    for (let i = 0; i < optionsYear.length; i++)
        if (optionsYear[i] === yy.toString()) {
            stop = i;
            break;
        }

    const [year, setYear] = useState(optionsYear[stop]);

    let tableData = {
        componentTitleStart: `Tarefas de `,
        componentTitleFirstInfo: `${user ? user.name : ''}`,
        componentTitleMiddle: ` em `,
        componentTitleSecondInfo: `${year}`,
        componentTitleSecondMiddle: '',
        componentTitleThirdInfo: '',
        tableTitle: `Tarefas ${year}`,
        taskId: 'Código',
    };

    useEffect(() => {
        const callApiFindAllDepartment = async () => {
            const response = await getAllDepartments(
                auth,
                auth.data.user.companyId
            );

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);
            setDepartmentList(payload);
        };

        const callApiFindSubordinatesDepartments = async () => {
            const response = await getSubordinatesDepartments(
                auth,
                auth.data.user.departmentId,
                auth.data.user.companyId
            );

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            setDepartmentList(payload);
        }

        const callApiFindOneDepartment = async () => {
            const response = await getOneDepartment(
                auth,
                auth.data.user.departmentId,
                auth.data.user.companyId
            );

            let { message, payload } = await response.json();

            if (response.status !== 200) throw Error(message);

            setDepartmentValue(payload[0]);
        };
        try {
            if(props.submodules && getSubmodulePermission(props.submodules, permissions.SUBORDINATES_DEPARTMENTS_VIZUALIZATION_FILTER)) {
                callApiFindSubordinatesDepartments();
            } else if (getSubmodulePermission(props.submodules, permissions.DEPARTMENTS_VISUALIZATION_FILTER)) {
                callApiFindAllDepartment();
            }
            callApiFindOneDepartment();
        } catch (err) {
            console.log(err);
        }
    }, [auth, props.submodules]);

    useEffect(() => {
        let actualGlobalCompany;

        if (props.theGlobalCompanyHasChange) {
            setFetchOpen(true);
        }

        if (!isUndefined(props.globalCompany) && props.globalCompany !== null) {
            if (props.globalCompany.hasOwnProperty('companyId')) {
                actualGlobalCompany = props.globalCompany.companyId;
                setGlobalCompany(props.globalCompany.companyId);
            } else {
                actualGlobalCompany = auth.data.user.companyId;
                setGlobalCompany(auth.data.user.companyId);
            }
        } else {
            actualGlobalCompany = auth.data.user.companyId;
            setGlobalCompany(auth.data.user.companyId);
        }
         
        const callApiFindAllUsers = async (globalCompany) => {
            const response = await getAllActiveUsersInCompany(auth, globalCompany);
            const body = await response.json();
            
            if (response.status !== 200) throw Error(body.message);

            if (props.theGlobalCompanyHasChange) {
                setUser(body.payload[0]);
                setYear(yy);
                props.resetChangeActualGlobalCompany(false);
            }
            setOptionsUsers(body.payload);  
        };

        const callApiFindAllUserInDepartment = async (globalCompany) => {
            const response = await getAllActiveUsersInDepartment(
                auth,
                departmentValue.departmentId,
                globalCompany
            );
            const body = await response.json();
            if (response.status !== 200) throw Error(body.message);

            if (props.theGlobalCompanyHasChange) {
                setUser(body.payload[0]);
                setYear(yy);
                props.resetChangeActualGlobalCompany(false);
            }

            setOptionsUsers(body.payload);
        };

        try {
            if(!departmentValue && getSubmodulePermission(props.submodules, permissions.DEPARTMENTS_VISUALIZATION_FILTER)) {
                callApiFindAllUsers(actualGlobalCompany);
            } else if(departmentValue) {
                callApiFindAllUserInDepartment(actualGlobalCompany);
            }
        } catch (error) {
            console.log(error);
        }
    }, [departmentValue, auth, props.globalCompany, props.submodules]);


    useEffect(() => {
        let actualGlobalCompany;

        if (props.theGlobalCompanyHasChange) {
            setFetchOpen(true);
        }

        if (!isUndefined(props.globalCompany) && props.globalCompany !== null) {
            if (props.globalCompany.hasOwnProperty('companyId')) {
                actualGlobalCompany = props.globalCompany.companyId;
                setGlobalCompany(props.globalCompany.companyId);
            } else {
                actualGlobalCompany = auth.data.user.companyId;
                setGlobalCompany(auth.data.user.companyId);
            }
        } else {
            actualGlobalCompany = auth.data.user.companyId;
            setGlobalCompany(auth.data.user.companyId);
        }

        const createTableMonths = (data, arrayOfTasksIds, year) => {
            const names = [
                'Jan',
                'Fev',
                'Mar',
                'Abr',
                'Mai',
                'Jun',
                'Jul',
                'Ago',
                'Set',
                'Out',
                'Nov',
                'Dez',
            ];
            const monthKeys = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ];

            let newData = [];
            let elementExists;

            /* Adding the values of months that doesnt exist as null */
            for (let i = 0; i < monthKeys.length; i++) {
                if (Object.entries(data).length === 0) {
                    /* If the user has no data */
                    if (newData[i] === undefined) {
                        newData[i] = {
                            name: names[i],
                            data: [],
                        };
                        for (let j = 0; j < arrayOfTasksIds.length; j++) {
                            newData[i].data.push({
                                name: arrayOfTasksIds[j].name,
                                taskId: arrayOfTasksIds[j].taskId,
                                active: arrayOfTasksIds[j].active,
                                grade: null,
                            });
                        }
                    }
                } else {
                    Object.keys(data[year]).forEach(function (item) {
                        if (data[year].hasOwnProperty(item)) {
                            if (monthKeys[i] === item) {
                                newData[i] = {
                                    name: names[i],
                                    data: [],
                                };

                                for (
                                    let j = 0;
                                    j < arrayOfTasksIds.length;
                                    j++
                                ) {
                                    elementExists = [];
                                    elementExists = data[year][item].filter(
                                        (obj) =>
                                            obj.taskId ===
                                            arrayOfTasksIds[j].taskId
                                    );
                                    if (elementExists.length === 0) {
                                        newData[i].data.push({
                                            name: arrayOfTasksIds[j].name,
                                            taskId: arrayOfTasksIds[j].taskId,
                                            active: arrayOfTasksIds[j].active,
                                            grade: null,
                                        });
                                    } else {
                                        newData[i].data.push({
                                            name: arrayOfTasksIds[j].name,
                                            taskId: arrayOfTasksIds[j].taskId,
                                            active: arrayOfTasksIds[j].active,
                                            grade: elementExists[0].grade,
                                        });
                                    }
                                }
                            } else {
                                if (newData[i] === undefined) {
                                    /* this if is to not replace with a null value if is created with the last condition*/
                                    newData[i] = {
                                        name: names[i],
                                        data: [],
                                    };
                                    for (
                                        let j = 0;
                                        j < arrayOfTasksIds.length;
                                        j++
                                    ) {
                                        newData[i].data.push({
                                            name: arrayOfTasksIds[j].name,
                                            taskId: arrayOfTasksIds[j].taskId,
                                            active: arrayOfTasksIds[j].active,
                                            grade: null,
                                        });
                                    }
                                }
                            }
                        }
                    });
                }
            }

            /* Changing the key name to value and name because the generic component need key names */
            for (let i = 0; i < newData.length; i++) {
                for (let j = 0; j < newData[i].data.length; j++) {
                    /* First Table */
                    newData[i].data[j] = {
                        ...newData[i].data[j],
                        name: newData[i].data[j].name,
                        value: newData[i].data[j].grade,
                        taskId: newData[i].data[j].taskId,
                    };

                    delete newData[i].data[j].grade;
                    delete newData[i].data[j].name;
                }
            }

            /* this sort was use because without it there is no
             defined order (each time it comes in an order) and
             this generated inconsistency in the table data*/
            for (let i = 0; i < newData.length; i++) {
                newData[i].data = newData[i].data.sort((a, b) =>
                    a.taskId > b.taskId ? 1 : b.taskId > a.taskId ? -1 : 0
                );
            }

            return { newData: newData };
        };

        const callApiFindOneCompany = async (company) => {
            const response = await getOneCompany(auth, company);
            const body = await response.json();

            return body.payload[0];
        };

        const callApiFindAllUserTasks = async (company, user) => {
            const response = await findAllUserTasks(auth, company, user);
            const body = await response.json();

            return body.payload;
        };

        const callApiFindUserTasksHistory = async (company, user, year) => {
            const response = await getUserAnnualTasksGrade(
                auth,
                company,
                user,
                parseInt(year)
            );
            const body = await response.json();

            return body.payload[0];
        };

        callApiFindOneCompany(actualGlobalCompany)
            .then((companyRes) => {
                setGlobalCompany({
                    companyId: companyRes.companyId,
                    name: companyRes.name,
                });
            })
            .catch((err) => console.log(err));

        callApiFindAllUserTasks(actualGlobalCompany, user)
            .then((tasksRes) => {
                callApiFindUserTasksHistory(actualGlobalCompany, user, year)
                    .then((tasksHistoryRes) => {
                        let names = [];
                        let tasks = [];

                        /* this sort was use because without it each time it comes with a different order*/
                        tasksRes = tasksRes.sort((a, b) =>
                            a.taskId > b.taskId
                                ? 1
                                : b.taskId > a.taskId
                                ? -1
                                : 0
                        );

                        for (let i = 0; i < tasksRes.length; i++) {
                            tasks[i] = {
                                taskId: tasksRes[i].taskId,
                                name: tasksRes[i].name,
                                active: tasksRes[i].active,
                            };
                            names[i] = {
                                name: tasks[i].name,
                                active: tasks[i].active,
                            };
                        }

                        let result = createTableMonths(
                            tasksHistoryRes,
                            tasks,
                            year
                        );

                        setRowsNameId(tasks);
                        setRowsName(names);
                        setRows(result.newData);

                        if (Object.keys(tasksHistoryRes).length !== 0) {
                            let percentageNotes = result.newData.map((a) => {
                                return a.data.map((b) => {
                                    return b.value * 33.33;
                                });
                            });

                            percentageNotes = percentageNotes.map((a) => a.filter((f) => f !== 0)).map((a) => a.length === 0 ? a = [0] : a)
                            const monthAverageData = percentageNotes.map(
                                (monthNotes) => {
                                    return parseInt(
                                        monthNotes.reduce((a, b) => a + b) /
                                            monthNotes.length
                                    );
                                }
                            );

                            const userTasks = result.newData[0].data.map(
                                (a) => {
                                    return { taskId: a.taskId, notes: [] };
                                }
                            );

                            result.newData.forEach((month, indx) => {
                                month.data.forEach((task, indx2) => {
                                    if (task.value)
                                        userTasks[indx2].notes.push(
                                            task.value * 33.33
                                        );
                                });
                            });

                            const yearAverageByTask = userTasks.map((task) => {
                                if (task.notes.length > 0)
                                    return parseInt(
                                        task.notes.reduce((a, b) => a + b) /
                                            task.notes.length
                                    );
                                else return null;
                            });

                            const yearAverageByTaskAux = yearAverageByTask.filter((f) => f !== null) // this aux is necessary because the user can see unactive tasks that have no values, but they cant count towards the average

                            const allYearAverageData = parseInt(
                                yearAverageByTaskAux.reduce((a, b) => a + b) /
                                    yearAverageByTaskAux.length
                            );

                            setAllYearAverage(allYearAverageData);
                            setYearAverage(yearAverageByTask);
                            setMonthAverage(monthAverageData);

                            // /* monthly average */
                            // let tempAccumulator = 0, hasData = false;

                            // for (let i = 0; i < result.newData.length; i++) {
                            //     hasData = false;
                            //     tempAccumulator = null;
                            //     for (let j = 0; j < result.newData[i].data.length; j++) {
                            //         if(result.newData[i].data[j].value !== null) {
                            //             hasData = true;
                            //             tempAccumulator = tempAccumulator + result.newData[i].data[j].value;
                            //         }
                            //     }

                            //     if(hasData) {
                            //         result.newData[i].data[result.newData[i].data.length] = {value: ((tempAccumulator/result.newData[i].data.length)*33.33)};
                            //         result.newData[i].data[result.newData[i].data.length-1].value = parseInt(result.newData[i].data[result.newData[i].data.length-1].value);
                            //         result.newData[i].data[result.newData[i].data.length-1]["color"] = "#bdbdbd";
                            //     }
                            // }

                            // /* Annual average */
                            // debugger
                            // let accumulatedOfYear = {name: "Anual", data: []}, totalOfMonths = 0;

                            // for (let j = 0; j < result.newData[0].data.length; j++) {
                            //     accumulatedOfYear.data[j] = {taskId: result.newData[0].data[j].taskId,
                            //     active: result.newData[0].data[j].active,
                            //     value: 0};
                            // }

                            // for (let i = 0; i < result.newData.length; i++) {
                            //     hasData = false;
                            //     for (let j = 0; j < result.newData[i].data.length; j++) {
                            //         if(result.newData[i].data[j].value !== null) {
                            //             accumulatedOfYear.data[j].value = accumulatedOfYear.data[j].value + result.newData[i].data[j].value;
                            //             hasData = true;
                            //         }
                            //     }

                            //     if(hasData) {
                            //         totalOfMonths++;
                            //     }
                            // }

                            // for (let j = 0; j < accumulatedOfYear.data.length; j++) {
                            //     if(j !== accumulatedOfYear.data.length - 1) {
                            //         accumulatedOfYear.data[j].value = (accumulatedOfYear.data[j].value / totalOfMonths) *33.33;
                            //     } else {
                            //         accumulatedOfYear.data[j].value = (accumulatedOfYear.data[j].value / totalOfMonths);
                            //     }
                            //     accumulatedOfYear.data[j].value = parseInt(accumulatedOfYear.data[j].value);
                            //     // accumulatedOfYear.data[j]["color"] = "#bdbdbd"
                            // }

                            // result.newData[12] = accumulatedOfYear;

                            // setRows(result.newData);
                            // setFetchOpen(false)
                        } else {
                            setAllYearAverage([]);
                            setYearAverage([]);
                            setMonthAverage([]);
                        }
                    })
                    .catch((err) => console.log(err))
                    .finally(() => setFetchOpen(false));
            })
            .catch((err) => console.log(err));
    }, [auth, year, user, props.globalCompany]);

    const defaultPropsUsers = {
        options: optionsUsers,
        value: user,
        getOptionLabel: (option) => {
            return option.name;
        },
        getOptionSelected: (option) => {
            return option.name;
        },
        onChange: (event, newValue) => {
            setFetchOpen(true);
            setUser(newValue);
        },
    };

    const defaultPropsDepartment = {
        options: departmentList,
        value: departmentValue,
        getOptionLabel: (option) => {
            return option.name || '';
        },
        getOptionSelected: (option) => {
            return option.name || '';
        },
        onChange: (event, newValue) => {
            setDepartmentValue(newValue);
        },
    };

    return (
        <>
            <FiltersAndSwitch>
                <Filters>
                    {getSubmodulePermission(
                        props.submodules,
                        permissions.EMPLOYEES_VISUALIZATION_FILTER
                    ) ? (
                        <Autocomplete
                            {... defaultPropsUsers}
                            id="users"
                            autoSelect
                            className={classes.formControl}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Usuário"
                                    variant="outlined"
                                />
                            )}
                        />
                    ) : null}
                    {getSubmodulePermission(
                        props.submodules,
                        permissions.DEPARTMENTS_VISUALIZATION_FILTER
                    ) ||
                    getSubmodulePermission(
                        props.submodules,
                        permissions.SUBORDINATES_DEPARTMENTS_VIZUALIZATION_FILTER
                    ) ? (
                        <Autocomplete
                            {...defaultPropsDepartment}
                            id="department-selector"
                            autoSelect
                            className={classes.formControl}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Setor"
                                    variant="outlined"
                                />
                            )}
                        />
                    ):null}
                    <FormControl
                        variant="outlined"
                        className={classes.formControlYear}
                    >
                        <InputLabel id="demo-simple-select-outlined-label">
                            Ano
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={year}
                            onChange={(e) => {
                                setFetchOpen(true);
                                setYear(e.target.value);
                            }}
                            label="Ano"
                        >
                            {optionsYear.map((year, index) => {
                                return (
                                    <MenuItem key={index} value={year}>
                                        {year}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Filters>
                <SwitchTwoLabels
                    checkAction={setShowNotActivedTasks}
                    leftWord=""
                    rightWord="Mostrar Tarefas Desativadas"
                />
            </FiltersAndSwitch>
            <TableContainer>
                <TableToShowValue
                    rowsName={rowsName}
                    rows={rows}
                    year={year}
                    tableData={tableData}
                    tasksHistory={true}
                    rowsNameId={rowsNameId}
                    showNotActived={showNotActivedTasks}
                    yearAverage={yearAverage}
                    monthAverage={monthAverage}
                    allYearAverage={allYearAverage}
                />
            </TableContainer>
            {fetchOpen ? <RenderPage open={fetchOpen} /> : null}
        </>
    );
}
